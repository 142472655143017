import React, { Component } from 'react';
import FloatingLabelInput from 'react-floating-label-input';
import $ from 'jquery';
import DatePicker from 'react-date-picker'

export default class PdkAccessRequest extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (


            <div>
                <div id="page-wrapper">
                    <div id="page-inner" style={{ background: '#fff' }}>
                        <div className="page_head col-xl-8 mx-auto" style={{ marginLeft: 17 + '%' }}>
                            <h1 className="page-header">PDK Access Request</h1>
                            {
                                <div>
                                    <div className="account">
                                        <div className="accoutn_form">
                                            <form action="" method="">
                                                <div className="dashboard_form">
                                                    <div className="inner-dashoard_form">
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="brd_section">
                                                                    <div className="row new_pos">
                                                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                                                            <div className="inner_left">
                                                                                <label>Please note PDKs become available for Designs after receipt by The MOSIS Service of the Customer Purchase Order and verification of valid NDA</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="brd_section">
                                                                    <div className="row new_pos" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>Is the PDK request for an existing Design Number</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <div className="custom_select">
                                                                                        <select onChange={this.props.HandleChange} name="isExistingDesignNumber" id="isExistingDesignNumber" value={this.props.state.isExistingDesignNumber} >
                                                                                            <option value=''>Select</option>
                                                                                            <option value='Yes'>Yes</option>
                                                                                            <option value='No'>No</option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            }
                            {   this.props.state.showInitialSelectBox === false && this.props.state.showInputForm === false?
                                <div>
                                    <div className="account">
                                        {/* <div className="header">
                                            <label>Please note PDKs become available for Designs after receipt by The MOSIS Service of the Customer Purchase Order and verification of valid a NDA</label>
                                        </div> */}

                                        <div className="accoutn_form">
                                            <form action="" method="">

                                                <div className="dashboard_form">
                                                    <div className="inner-dashoard_form">
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="brd_section">
                                                                    <div className="row new_pos">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>Identify Design number</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <div className="custom_select">
                                                                                        <select onChange={this.props.HandleChange} name="selectedDesignNumber" id="selectedDesignNumber" value={this.props.state.selectedDesignNumber} >
                                                                                            <option value=''>Select</option>
                                                                                            {(JSON.stringify(this.props.state.foundryAndTechClass) !== '{}' && this.props.state.foundryAndTechClass.length > 0) ? this.props.state.foundryAndTechClass.map(item =>
                                                                                                <option value={item["designNumber"]}>{item["designNumber"]}</option>
                                                                                            ) : ''}
                                                                                        </select>
                                                                                    </div>
                                                                                    {this.props.state.designNumberListDisabled == true?
                                                                                        <div><label style={{fontStyle: "italic", fontSize: "10px", position: "absolute", bottom: "-10px", color: "red"}}>No Design Numbers Found</label></div>:''
                                                                                    }
                                                                                </div>

                                                                            </div>

                                                                        </div>

                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>

                            <div id="globalDesignNumberNote" style={{display:"none", fontSize: "15px"}}>
                            <div className="row">
                                <div className="col-lg-12">
                                <div className="brd_section border-none" >
                                <div className="row" style={{paddingLeft: "13%", fontWeight: "bold"}}>
                                <div className="col-lg-1 col-md-1 col-sm-6">
                                <div className="inner_left">
                                Note:
                            </div>
                            </div>
                                <div className="col-lg-10 col-md-10 col-sm-12">
                            <div className="inner_left">
                                <div>Global Foundries has updated their process to provide Foundry IP/PDK files.</div>
                            <div>The MOSIS Service is no longer providing Global Foundry PDK files.</div>
                            <div>All requests should now be made through their portal at https://www.global-foundryview.com.</div>
                                <div>Global Foundries will provision necessary accounts once a Global Foundries 3 way NDA has been executed.</div>
                            </div>
                            </div>

                            </div>
                            </div>
                            </div>
                            </div>
                            </div>

                                                        <div id="designNumberDetails">
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <div className="brd_section border-none">
                                                                        <div className="row">
                                                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                                                <div className="inner_left">
                                                                                    <label>Foundry</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-8 col-md-8 col-sm-6">
                                                                                <div className="row">
                                                                                    <div className="col-lg-8 col-md-8 col-sm-12">
                                                                                        <div className="inner_right">
                                                                                            <div className="form-group">
                                                                                                <input type="text" id="txtTotalPrice" name="txtTotalPrice" value={this.props.state.foundry != null? this.props.state.foundry["codeValue"]:''}
                                                                                                       onChange={this.props.HandleChange} className="form-control bg-darkgray"/>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <div className="brd_section border-none">
                                                                        <div className="row">
                                                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                                                <div className="inner_left">
                                                                                    <label>Technology Class</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-8 col-md-8 col-sm-6">
                                                                                <div className="row">
                                                                                    <div className="col-lg-8 col-md-8 col-sm-12">
                                                                                        <div className="inner_right">
                                                                                            <div className="form-group">
                                                                                                <input type="text" id="txtTotalPrice" name="txtTotalPrice" value={this.props.state.techClass != null? this.props.state.techClass["codeValue"]:''}
                                                                                                       onChange={this.props.HandleChange} className="form-control bg-darkgray"/>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <div className="brd_section">
                                                                        <div className="row new_pos">
                                                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                                                <div className="inner_left">
                                                                                    <label>Requested file type</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-8 col-md-8 col-sm-6">
                                                                                <div className="row">
                                                                                    <div className="col-lg-8 col-md-8 col-sm-12">
                                                                                        <div className="inner_right">
                                                                                            <div className="form-group">
                                                                                        <div className="custom_select">
                                                                                            <select onChange={this.props.HandleChange} name="ddlRequestedFileTypes" id="ddlRequestedFileTypes" value={this.props.state.ddlRequestedFileTypes}
                                                                                            disabled={this.props.state.selectedDesignNumber == ''? true:false}>
                                                                                                <option value=''>Select</option>
                                                                                                {(JSON.stringify(this.props.requestedFileTypes) !== '{}' && this.props.requestedFileTypes.length > 0) ? this.props.requestedFileTypes.map(item =>
                                                                                                    <option value={item.codeId}>{item.codeName}</option>
                                                                                                ) : ''}
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </form>

                                        </div>

                                    </div>
                                    <div className="text-center">
                                        <button type="button" onClick={this.props.SubmitOldDesign} id="submitButton" className="dash_submit_btn">Submit</button>
                                    </div>
                                </div>:''
                            }
                            {   this.props.state.showInitialSelectBox === false && this.props.state.showInputForm === true?
                            <div>
                                <div className="account">
                                    {/* <div className="header">
                                            <label>Please note PDKs become available for Designs after receipt by The MOSIS Service of the Customer Purchase Order and verification of valid a NDA</label>
                                        </div> */}

                                    <div className="accoutn_form">
                                        <form action="" method="">

                                            <div className="dashboard_form">
                                                <div className="inner-dashoard_form">




                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="brd_section">
                                                                <div className="row new_pos">
                                                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                                                        <div className="inner_left">
                                                                            <label>If you are requesting access to a PDK prior to the normal processs please complete the information below</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="brd_section">
                                                                <div className="row new_pos">
                                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                                        <div className="inner_left">
                                                                            <label>Select Foundry</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-8 col-md-8 col-sm-6">
                                                                        <div className="inner_right">
                                                                            <div className="form-group">
                                                                                <div className="custom_select">
                                                                                    <select onChange={this.props.HandleChange} name="ddlFoundry" id="ddlFoundry" value={this.props.state.ddlFoundry} >
                                                                                        <option value=''>Select</option>
                                                                                        {(JSON.stringify(this.props.foundries) !== '{}' && this.props.foundries.length > 0) ? this.props.foundries.map(item =>
                                                                                            <option value={item.codeId}>{item.codeName}</option>
                                                                                        ) : ''}
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                
                            <div id="globalDesignNumberNoteForNewDesign" style={{display:"none", fontSize: "15px"}}>
                            <div className="row">
                                <div className="col-lg-12">
                                <div className="brd_section border-none" >
                                <div className="row" style={{paddingLeft: "13%", fontWeight: "bold"}}>
                            <div className="col-lg-1 col-md-1 col-sm-6">
                                <div className="inner_left">
                                Note:
                            </div>
                            </div>
                            <div className="col-lg-10 col-md-10 col-sm-12">
                                <div className="inner_left">
                                <div>Global Foundries has updated their process to provide Foundry IP/PDK files.</div>
                            <div>The MOSIS Service is no longer providing Global Foundry PDK files.</div>
                            <div>All requests should now be made through their portal at https://www.global-foundryview.com.</div>
                                <div>Global Foundries will provision necessary accounts once a Global Foundries 3 way NDA has been executed.</div>
                            </div>
                            </div>

                            </div>
                            </div>
                            </div>
                            </div>
                            </div>

                            <div id="newDesignNumberForm">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="brd_section">
                                                                <div className="row new_pos">
                                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                                        <div className="inner_left">
                                                                            <label>Select Technology Node</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-8 col-md-8 col-sm-6">
                                                                        <div className="inner_right">
                                                                            <div className="form-group">
                                                                                <div className="custom_select">
                                                                                    <select onChange={this.props.HandleChange} name="ddlTechNode" id="ddlTechNode" value={this.props.state.ddlTechNode} >
                                                                                        <option value=''>Select</option>
                                                                                        {(JSON.stringify(this.props.techNodes) !== '{}' && this.props.techNodes.length > 0) ? this.props.techNodes.map(item =>
                                                                                            <option value={item.codeId}>{item.codeName}</option>
                                                                                        ) : ''}
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="brd_section">
                                                                <div className="row new_pos">
                                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                                        <div className="inner_left">
                                                                            <label>Requested file type</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-8 col-md-8 col-sm-6">
                                                                        <div className="inner_right">
                                                                            <div className="form-group">
                                                                                <div className="custom_select">
                                                                                    <select onChange={this.props.HandleChange} name="ddlRequestedFileTypes" id="ddlRequestedFileTypes" value={this.props.state.ddlRequestedFileTypes} >
                                                                                        <option value=''>Select</option>
                                                                                        {(JSON.stringify(this.props.requestedFileTypes) !== '{}' && this.props.requestedFileTypes.length > 0) ? this.props.requestedFileTypes.map(item =>
                                                                                            <option value={item.codeId}>{item.codeName}</option>
                                                                                        ) : ''}
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="brd_section">
                                                                <div className="row new_pos">
                                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                                        <div className="inner_left">
                                                                            <label>Will early access be used directly for fabricating a design with The MOSIS Service</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-8 col-md-8 col-sm-6">
                                                                        <div className="inner_right">
                                                                            <div className="form-group">
                                                                                <div className="custom_select">
                                                                                    <select onChange={this.props.HandleChange} name="ddlUserForFabricationDesignCheck" id="ddlUserForFabricationDesignCheck" value={this.props.state.ddlUserForFabricationDesignCheck} >
                                                                                        <option value=''>Select</option>
                                                                                        <option value='1'>Yes</option>
                                                                                        <option value='0'>No</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="brd_section">
                                                                <div className="row new_pos">
                                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                                        <div className="inner_left">
                                                                            <label>When do you expect to submit the Design File?</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-8 col-md-8 col-sm-6">
                                                                        <div className="form-group">
                                                                            <div className="dateParentDiv" id="expectedDesignSubmissionDate">
                                                                                <DatePicker name="expectedDesignSubmissionDate"
                                                                                            format="MM/dd/y"
                                                                                            onChange={this.props.HandleDesignSubmitDateChange}
                                                                                            onBlur={() => this.props.HandleOnBlur('ForceDate', 'expectedDesignSubmissionDate', this.props.state.expectedDesignSubmissionDate, 'Eexpected Design Submission Date')}
                                                                                            value={this.props.state.expectedDesignSubmissionDate} clearIcon={null}
                                                                                            minDate={new Date()}
                                                                                            calendarIcon={<img src={require('../../../assets/images/calendar.svg')} alt="calendar" width={14} />}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="brd_section">
                                                                <div className="row new_pos">
                                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                                        <div className="inner_left">
                                                                            <label>How many Die you expect to order?</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-8 col-md-8 col-sm-6">
                                                                        <div className="inner_right">
                                                                            <div className="form-group">
                                                                                <input type="text" id="txtExpectedDieQuantity" name="txtExpectedDieQuantity" value={this.props.state.txtExpectedDieQuantity} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceRNumber', 'txtExpectedDieQuantity', this.props.state.txtExpectedDieQuantity, '')} className="form-control" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="brd_section border-none">
                                                                <div className="row new_pos" style={{height: 170}}>
                                                                    <div className="col-lg-4 col-md-4 col-sm-6">
                                                                        <div className="inner_left">
                                                                            <label>Reason and purpose for early to PDK</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-8 col-md-8 col-sm-6">
                                                                        <div className="inner_right">
                                                                            <div className="form-group">
                                                                                <textarea maxLength="3995" id="txtEarlyPdkReason" name="txtEarlyPdkReason" onChange={this.props.HandleChange} value={this.props.state.txtEarlyPdkReason} onBlur={() => this.props.HandleOnBlur('ForceTextArea', 'txtEarlyPdkReason', this.props.state.txtEarlyPdkReason, 'Reason and purpose for early to PDK')} className="form-control">

                                                                                </textarea>
                                                                                {/* <input type="text" id="txtProjectedNoOfDesign" name="txtProjectedNoOfDesign" value={this.props.state.txtProjectedNoOfDesign} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceRNumber', 'txtProjectedNoOfDesign', this.props.state.txtProjectedNoOfDesign, '')} className="form-control" /> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                            </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <button type="button" onClick={this.props.SubmitDesign} id="submitButtonExisting" className="dash_submit_btn">Submit</button>
                                </div>
                            </div>
                                :''}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}