import React, { Component } from 'react';
import DesignUpload from './designupload';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import PrValidate from '../../../library/pr-controls/pr-validation-full';
import closeImg from '../../../assets/images/close.svg'

import { PrConnect } from '../../../library/pr-miniredux';
import { TableDesignCtrl } from '../../TableDesign';
export class designUploadCtrl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetchFoundries: true,
            foundriesList: {},
            fetchTechNode: false,
            techNodesList: {},
            fetchRunType: true,
            runTypeList: {},
            fetchPackageType: true,
            packageTypeList: {},
            postDesignData: false,


            showFirstStep: 'block',
            showSecondStep: 'none',
            showThirdStep: 'none',

            chipsQty: '',

            txtDesignNumber: '',
            txtFoundry: '',
            txtTechNode: '',
            txtDesignProjectName: '',
            txtDesignSize: '',
            txtQtyOrdered: '',
            txtChipsQty: '',

            ddlPackageType: '',
            txtPreferredPackageVendor: '',
            ddlDieRotation: '',
            ddlBondingSrc: '',
            ddlDownloadLocations: '',

            txtNoOfPads: '',

            txtTopcellDesignName: '',
            ddlRequestDieThicknessMils: '',
            ddlBondPadOrBumpDesign: '',
            ddlProcessModeOfDesign: '',
            ddlProcessCoreOfDesign: '',
            ddlVoltageDeviceOfDesign: '',
            ddlTopMetalInDesign: '',
            ddlMetalStack: '',
            txtThicknesUMMetalDesign: '',
            txtDeviceVTsDesign: '',

            ddlAPThicknessUMofDesign: '',
            ddlMIMCapsUsedInDesign: '',
            ddlMOMIpForDesign: '',
            ddlDesignUseRingOscillators: '',
            ddlDesignUseOTPip: '',
            ddlDesignUseSRAM: '',
            ddlPolyTypeFuseDesign: '',
            txtOperationalFrequencyDesign: ''


        }
        this.design_data = {}
    }

    HandleChange = (e) => {
        if (e.target.name === 'txtDesignNumber' || e.target.name === 'txtChipsQty' || e.target.name === 'txtNoOfPads' || e.target.name === 'txtQtyOrdered' || e.target.name === 'txtDesignSize') {
            const re = /^[0-9\b]+$/;
            // if value is not blank, then test the regex
            // let val = e.target.value;
            let val = e.target.value.replace(/[^0-9]/g, '');
            if (val === '' || re.test(val)) {
                if (e.target.name === 'txtChipsQty') {
                    this.setState({
                        chipsQty: e.target.value.replace(/[^0-9]/g, '')
                    });
                }
                val = val.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
                this.setState({
                    [e.target.name]: val
                })
            }
        }
        else {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
        if (e.target.name === 'ddlTechNode')
            PrValidate.ForceSelect(e.target.value, 'ddlTechNode', 'a technology node');
        else if (e.target.name === 'ddlFoundry')
            PrValidate.ForceSelect(e.target.value, 'ddlFoundry', 'a foundry');
        else if (e.target.name === 'ddlRunType')
            PrValidate.ForceSelect(e.target.value, 'ddlRunType', 'a run type');
        else if (e.target.name === 'ddlPackageType')
            PrValidate.ForceSelect(e.target.value, 'ddlPackageType', 'a package type');
    }

    ShowHideModal = () => {
        this.setState({
            showModal: !this.state.showModal
        });
    }

    HandleOnBlur = (type, field, value, msg) => {
        if (type === 'ForceSelect')
            PrValidate.ForceSelect(value, field, msg);
        else if (type === 'ForceRNumber')
            PrValidate.ForceRNumber(value.replace(/[^0-9]/g, ''), field, msg);
        if (type === 'ForceDate')
            PrValidate.ForceDate(value, field, msg);
        if (type === 'ForceEntry')
            PrValidate.ForceEntry(value, field, msg);
    }

    ShowHideSteps = (pos) => {
        let that = this;
        if (pos === 'first' || pos === 'showsecond') {
            // let txtDesignNumber = PrValidate.ForceRNumber((that.state.txtDesignNumber).replace(/[^0-9]/g, ''), 'txtDesignNumber');
            // let txtFoundry = PrValidate.ForceEntry(that.state.txtFoundry, 'txtFoundry', 'foundry');
            // let txtTechNode = PrValidate.ForceEntry(that.state.txtTechNode, 'txtTechNode', 'tech node');
            // let txtDesignProjectName = PrValidate.ForceEntry(that.state.txtDesignProjectName, 'txtDesignProjectName', 'Design Project Name');
            // let txtDesignSize = PrValidate.ForceRNumber((that.state.txtDesignSize).replace(/[^0-9]/g, ''), 'txtDesignSize');
            // let txtQtyOrdered = PrValidate.ForceRNumber((that.state.txtQtyOrdered).replace(/[^0-9]/g, ''), 'txtQtyOrdered');
            // let txtChipsQty = PrValidate.ForceRNumber((that.state.txtChipsQty).replace(/[^0-9]/g, ''), 'txtChipsQty');
            // let txtNoOfPads = PrValidate.ForceRNumber((that.state.txtNoOfPads).replace(/[^0-9]/g, ''), 'txtNoOfPads');

            // let err_arr = [txtDesignNumber, txtFoundry, txtTechNode, txtDesignProjectName, txtDesignSize, txtQtyOrdered, txtChipsQty, txtNoOfPads];
            // let chk_false = 0;
            // err_arr.forEach(function (element, index) {
            //     if (!element)
            //         chk_false++;
            //     if ((err_arr.length - 1) === index) {
            //         if (chk_false === 0) {
            that.setState({
                showFirstStep: 'none',
                showSecondStep: 'block',
                showThirdStep: 'none'
            });
            //         }
            //     }
            // });
        }
        else if (pos === 'showthird') {
            // let txtTopcellDesignName = PrValidate.ForceEntry(that.state.txtTopcellDesignName, 'txtTopcellDesignName', 'Topcell Design Name');
            // let ddlRequestDieThicknessMils = PrValidate.ForceSelect(this.state.ddlRequestDieThicknessMils, 'ddlRequestDieThicknessMils', 'Request Die Thickness');
            // let ddlBondPadOrBumpDesign = PrValidate.ForceSelect(this.state.ddlBondPadOrBumpDesign, 'ddlBondPadOrBumpDesign', 'Bond Pad Or Bump Design');
            // let ddlProcessModeOfDesign = PrValidate.ForceSelect(this.state.ddlProcessModeOfDesign, 'ddlProcessModeOfDesign', 'Process Mode Of Design');
            // let ddlProcessCoreOfDesign = PrValidate.ForceSelect(this.state.ddlProcessCoreOfDesign, 'ddlProcessCoreOfDesign', 'Process Core Of Design');
            // let ddlVoltageDeviceOfDesign = PrValidate.ForceSelect(this.state.ddlVoltageDeviceOfDesign, 'ddlVoltageDeviceOfDesign', 'Voltage Device Of Design');
            // let ddlTopMetalInDesign = PrValidate.ForceSelect(this.state.ddlTopMetalInDesign, 'ddlTopMetalInDesign', 'Top Metal In Design');
            // let ddlMetalStack = PrValidate.ForceSelect(this.state.ddlMetalStack, 'ddlMetalStack', 'Metal Stack');
            // let txtThicknesUMMetalDesign = PrValidate.ForceEntry(that.state.txtThicknesUMMetalDesign, 'txtThicknesUMMetalDesign', 'Thicknes Metal Design');
            // let txtDeviceVTsDesign = PrValidate.ForceEntry(that.state.txtDeviceVTsDesign, 'txtDeviceVTsDesign', 'Device VTs Design');

            // let err_arr = [txtTopcellDesignName, ddlRequestDieThicknessMils, ddlBondPadOrBumpDesign, ddlProcessModeOfDesign, ddlProcessCoreOfDesign, ddlVoltageDeviceOfDesign, ddlTopMetalInDesign, ddlMetalStack, txtThicknesUMMetalDesign, txtDeviceVTsDesign];
            // let chk_false = 0;
            // err_arr.forEach(function (element, index) {
            //     if (!element)
            //         chk_false++;
            //     if ((err_arr.length - 1) === index) {
            //         if (chk_false === 0) {
            that.setState({
                showFirstStep: 'none',
                showSecondStep: 'none',
                showThirdStep: 'block'
            })
            //         }
            //     }
            // });
        }
        else if (pos === 'showfirst') {
            that.setState({
                showFirstStep: 'block',
                showSecondStep: 'none',
                showThirdStep: 'none'
            })
        }
    }

    HandleSuccessGetFoundriesResponse = (res) => {
        this.setState({
            fetchFoundries: false,
            foundriesList: res.data
        })
    }
    HandleSuccessGetTechNodesResponse = (res) => {
        this.setState({
            fetchTechNode: false,
            techNodesList: res.data
        })
    }
    HandleSuccessGetRunTypeResponse = (res) => {
        this.setState({
            fetchRunType: false,
            runTypeList: res.data
        })
    }
    HandleSuccessGetPackageTypeResponse = (res) => {
        this.setState({
            fetchPackageType: false,
            packageTypeList: res.data
        })
    }

    SubmitDesign = () => {
        let ddlAPThicknessUMofDesign = PrValidate.ForceSelect(this.state.ddlAPThicknessUMofDesign, 'ddlAPThicknessUMofDesign', 'AP Thickness Of Design');
        let ddlMIMCapsUsedInDesign = PrValidate.ForceSelect(this.state.ddlMIMCapsUsedInDesign, 'ddlMIMCapsUsedInDesign', 'MIM Caps Used In Design');
        let ddlMOMIpForDesign = PrValidate.ForceSelect(this.state.ddlMOMIpForDesign, 'ddlMOMIpForDesign', 'MOM IP For Design');
        let ddlDesignUseRingOscillators = PrValidate.ForceSelect(this.state.ddlDesignUseRingOscillators, 'ddlDesignUseRingOscillators', 'Design Use Ring Oscillators');
        let ddlDesignUseOTPip = PrValidate.ForceSelect(this.state.ddlDesignUseOTPip, 'ddlDesignUseOTPip', 'Design Use OTP ip');
        let ddlDesignUseSRAM = PrValidate.ForceSelect(this.state.ddlDesignUseSRAM, 'ddlDesignUseSRAM', 'Design Use SRAM');
        let ddlPolyTypeFuseDesign = PrValidate.ForceSelect(this.state.ddlPolyTypeFuseDesign, 'ddlPolyTypeFuseDesign', 'Poly Type Fuse Design');
        let txtOperationalFrequencyDesign = PrValidate.ForceEntry(that.state.txtOperationalFrequencyDesign, 'txtOperationalFrequencyDesign', 'Operational Frequency Design');

        let err_arr = [ddlAPThicknessUMofDesign, ddlMIMCapsUsedInDesign, ddlMOMIpForDesign, ddlDesignUseRingOscillators, ddlDesignUseOTPip, ddlDesignUseSRAM, ddlPolyTypeFuseDesign, txtOperationalFrequencyDesign];
        let chk_false = 0;

        let that = this;

        err_arr.forEach(function (element, index) {
            if (!element)
                chk_false++;
            if ((err_arr.length - 1) === index) {
                if (chk_false === 0) {
                    alert('Call POST API');
                    // that.design_data = {
                    //     techProcess: that.state.ddlTechNode,
                    //     foundry: that.state.ddlFoundry,
                    //     runType: that.state.ddlRunType,
                    //     projectedDatePreliminaryUpload: that.state.preliminaryDesignUploadDate,
                    //     projectedDateFinalUpload: that.state.projectedFinalDesignUploadDate,
                    //     numberofDesignUploads: (that.state.txtProjectedNoOfDesign).replace(/[^0-9]/g, ''),
                    //     dieSizeX: (that.state.txtXDimension).replace(/[^0-9]/g, ''),
                    //     dieSizeY: (that.state.txtYDimension).replace(/[^0-9]/g, ''),
                    //     dieSizeXY: (that.state.txtDieSize).replace(/[^0-9]/g, ''),
                    //     numberOfDieToFabricate: (that.state.txtNoDieToFabricated).replace(/[^0-9]/g, ''),
                    //     numberOfDieToPackage: (that.state.txtNoDieToPackaged).replace(/[^0-9]/g, ''),
                    //     packagingType: that.state.ddlPackageType,
                    // }

                    // that.setState({
                    //     postDesignData: true
                    // })
                }
            }
        });
    }

    HandleSuccessPostDesignResponse = (res) => {
        //ToastsStore.success("Successfully Added.");
        this.setState({
            result: res.data,
            postDesignData: false,
            ddlTechNode: '',
            ddlFoundry: '',
            ddlRunType: '',
            preliminaryDesignUploadDate: new Date(),
            projectedFinalDesignUploadDate: new Date(),
            txtProjectedNoOfDesign: '',
            txtXDimension: '',
            txtYDimension: '',
            txtDieSize: '',
            txtNoDieToFabricated: '',
            txtNoDieToPackaged: '',
            ddlPackageType: ''
        })
    }

    render() {
        return (
            <div>
                {this.state.showModal ?
                    <div className="popupTop">
                        <div className="popup_close">
                            <button onClick={this.ShowHideModal} className="btn">
                                <img src={closeImg} alt="logo" />
                            </button>
                        </div>
                        <div className='popup'>
                            <div className='popup_inner' onDoubleClick={this.ShowHideModal} style={{cursor: 'pointer'}}>
                                <TableDesignCtrl />
                            </div>
                        </div>
                    </div>
                    : ''
                }
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_CENTER} />
                <DesignUpload {...this.props} state={this.state} HandleChange={this.HandleChange}
                    HandleOnBlur={this.HandleOnBlur} ShowHideSteps={this.ShowHideSteps}
                    ShowHideModal={this.ShowHideModal} />

                {/* {this.state.fetchFoundries === true ? GetFoundriesList(this.props.PrStore, this.HandleSuccessGetFoundriesResponse) : ''}
                {this.state.fetchTechNode === true ? GetTechListByFoundry(this.props.PrStore, this.state.ddlFoundry, this.HandleSuccessGetTechNodesResponse) : ''}
                {this.state.fetchRunType === true ? GetRunTypeList(this.props.PrStore, this.HandleSuccessGetRunTypeResponse) : ''}
                {this.state.fetchPackageType === true ? GetPackageTypeList(this.props.PrStore, this.HandleSuccessGetPackageTypeResponse) : ''}
                {this.state.postDesignData === true ? PostDesignData(this.props.PrStore, this.design_data, this.HandleSuccessPostDesignResponse) : ''} */}
            </div>
        )
    }
}
export const DesignUploadCtrl = PrConnect(designUploadCtrl, '');