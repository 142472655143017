import { PrAjax } from '../library/ajax/pr-ajax';

export function GetCustomerData(store, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('exportControl/findByCustomerId/254').AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function GetAllQuestions(store, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('exportControl/getAllQuestions').AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function GetHTSNCodes(store, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('codes/codesByCodeType/M_HTSN_Codes').AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function GetITARCat(store, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('codes/codesByCodeType/M_ITAR_Categories').AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function GetAllQuestionTypes(store, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('exportControl/getAllQuestionTypes').AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function GetQuestionByTypeId(store, typeId, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('exportControl/getQuestionsByQuestionTypeId/'+typeId).AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function SaveExportControlData(store, data, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    let headerContent={'Accept': 'application/json'}
    //headerContent['contentType'] = "application/json";
    return PrAjax(store).AddHeader(headerContent).Post('exportControl/save').ExtraData(data).AttachLoader(loader).OnSuccess(successFunc).Do();
}



// export function PostContactData(store, userObj, successFunc) {
//     //code for further validations
//     let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
//     return PrAjax(store).Post('customersupport/save').ExtraData(userObj).AttachLoader(loader).OnSuccess(successFunc).Do();
// }
