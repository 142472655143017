import React, { Component } from 'react';

import $ from 'jquery';

export default class HeaderComponent extends Component {
    constructor(props) {
        super(props);
    }

    ToogleMenu = () => {
        $('.site-mobile-menu').addClass('ggggg');
        $('.icon-menu_tp').css('display', 'none');
        $('#page-wrapper').animate({ 'margin-left': '300px' });
    }

    CloseMenu = () => {
        $('.site-mobile-menu').removeClass('ggggg');
        $('.icon-menu_tp').css('display', 'block');
        $('#page-wrapper').animate({ 'margin-left': '0px' });
    }

    HandleClick = (route) => {
        this.props.history.push("/" + route);
        $('.site-mobile-menu').removeClass('ggggg');
        $('.icon-menu_tp').css('display', 'block');
        $('#page-wrapper').animate({ 'margin-left': '0px' });
        // if(route === '' || route === '/'){
        //     alert('kk');
        // }
    }

    componentDidMount = () => {
        console.log("Inside componentDidMount HeaderComponent: ")
        console.log("window.location.href: "+window.location.href)
        let pages = ["learn-more", "press-release", "contact-us", "university-support", "about", "foundry-services", "registration"]
        let that = this;
        pages.map(function (item) {
            if (window.location.href.indexOf(item) > -1) {
                $('#page-wrapper').css({ 'margin-left': '0px' });
            }

            var url = window.location.href;
            url = url.split("/")[2]; // Get the hostname
            /*if(url == 'localhost:4000' || url == 'devaws.mosis.com' || url == 'themosisservice.com' || url == 'mosis.com'){
            //if(url == 'themosisservice.com' || url == 'mosis.com'){
                console.log("inside matched");
                that.props.history.push("/holiday-notice");
            }*/

            if(window.location.href === "http://localhost:4000/" || window.location.href === "https://test4.mosis.com/" || window.location.href === "https://devaws.mosis.com/" ||
                window.location.href === "https://themosisservice.com/" || window.location.href === "http://localhost:4000/holiday-notice"
                || window.location.href === "https://devaws.mosis.com/holiday-notice" || window.location.href === "https://themosisservice.com/holiday-notice"
                || window.location.href === "https://www.themosisservice.com/holiday-notice"
                || window.location.href === "https://mosis.com/holiday-notice")  {
                $('#page-wrapper').css({ 'margin-left': '0px' });
            }


            //var parsed = psl.parse(url); // Parse the domain
            //document.getElementById("output").textContent = parsed.domain;

        })
    }

    render() {
        return (
            <div>
                <div className="site-mobile-menu site-navbar-target animated">
                    <div className="site-mobile-menu-header">
                        <div className="site-mobile-menu-close mt-3" onClick={this.CloseMenu}>
                            <span className="icon-close2 js_menu_close">
                            <i className="fa fa-times" aria-hidden="true"></i>
                            </span>
                        </div>

                    </div>
                    <div className="site-mobile-menu-body">
                        <ul className="site-nav-wrap">
                            <li className="active">
                                <a className="nav-link" onClick={() => this.HandleClick('')}>
                                    <span><img src={require('../../assets/images/home.png')} /></span> Home
                                </a>
                            </li>
                            <li>
                                <a className="nav-link" onClick={() => this.HandleClick('foundry-services')}>
                                    <span><img src={require('../../assets/images/Foundary-service.png')} /></span> Foundry Services
                                </a>
                            </li>
                            <li>
                                <a className="nav-link" onClick={() => this.HandleClick('registration')}>
                                    <span><img src={require('../../assets/images/new-customer-registration.png')} /></span> New Customer Registration
                                </a>
                            </li>
                            {/* <li>
                                <a className="nav-link" onClick={() => this.HandleClick('')}>
                                    <span><img src={require('../../assets/images/news.png')} /></span> News
                                </a>
                            </li> */}
                            <li>
                                <a className="nav-link" onClick={() => this.HandleClick('about')}>
                                    <span><img src={require('../../assets/images/about-us.png')} /></span> About The MOSIS Service
                                </a>
                            </li>
                            <li>
                                <a className="nav-link" onClick={() => this.HandleClick('university-support')}>
                                    <span><img src={require('../../assets/images/press-release.png')} /></span> University Support
                                </a>
                            </li>
                            <li>
                                <a className="nav-link" onClick={() => this.HandleClick('press-release')}>
                                    <span><img src={require('../../assets/images/press-release.png')} /></span> Press Release
                                </a>
                            </li>
                            <li>
                                <a className="nav-link" onClick={() => this.HandleClick('contact-us')}>
                                    <span><img src={require('../../assets/images/conatct-us.png')} /></span> Contact Us
                                </a>
                            </li>
                            {/*<li>
                                <a className="nav-link" onClick={() => this.HandleClick('dashboard/design-upload')}>
                                    <span><img src={require('../../assets/images/conatct-us.png')} /></span> Design Upload
                                </a>
                            </li>*/}
                        </ul>
                    </div>
                </div>
                <div className="icon-bar">
                    <span className="side_logo" style={{cursor: 'pointer'}}  onClick={() => this.HandleClick('contact-us')}>
                        <h6>Quick Contact</h6>
                    </span> 
                </div>

                <header className="site-navbar site-navbar-target" role="banner">
                    <div className="container-fluid">
                        <div className="row position-relative">
                            <div className="col-4">
                                <span className="tp_logo">
                                    <a className="text-white site-menu-toggle js-menu-toggle" onClick={this.ToogleMenu}>
                                        {this.props.history.location.pathname !== '/' ?
                                            <img className="reg_menu" src={require('../../assets/images/logo_2.png')} alt="logo" />
                                            : ''}
                                        <span className="icon-menu_tp">
                                            {this.props.history.location.pathname !== '/' ?
                                                <img src={require('../../assets/images/Menu_reg.png')} />
                                                :
                                                <img src={require('../../assets/images/Menu.png')} />
                                            }
                                            <span id="tp_menu">Menu</span>
                                        </span></a>

                                </span>
                                {/* <nav className="site-navigation text-right ml-auto d-none d-lg-none" role="navigation"> */}
                                <nav className="site-navigation text-right ml-auto d-none">
                                    <ul className="site-menu main-menu js-clone-nav ml-auto ">
                                        <li className="active">
                                            <a href="index.html" className="nav-link">
                                                <span>
                                                    <img src={require('../../assets/images/home.png')} alt="logo" />
                                                </span>
                                                Home
                                            </a>
                                        </li>

                                        <li>
                                            <a href="javavscript:void(0)" className="nav-link">
                                                <span>
                                                    <img src={require('../../assets/images/Foundary-service.png')} alt="logo" />
                                                </span>
                                                Foundry Services</a>
                                        </li>
                                        <li>
                                            <a href="javavscript:void(0)" className="nav-link">
                                                <span>
                                                    <img src={require('../../assets/images/design-service.png')} alt="logo" />
                                                </span>
                                                Design Services
                                            </a>
                                        </li>
                                        <li>
                                            <a href="registration.html" className="nav-link">
                                                <span>
                                                    <img src={require('../../assets/images/new-customer-registration.png')} alt="logo" />
                                                </span>
                                                New Customer Registration
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javavscript:void(0)" className="nav-link">
                                                <span>
                                                    <img src={require('../../assets/images/news.png')} alt="logo" />
                                                </span>
                                                News
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javavscript:void(0)" className="nav-link">
                                                <span>
                                                    <img src={require('../../assets/images/about-us.png')} alt="logo" />
                                                </span>
                                                About Mosis
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javavscript:void(0)" className="nav-link">
                                                <span>
                                                    <img src={require('../../assets/images/press-release.png')} alt="logo" />
                                                </span>
                                                Press Release
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javavscript:void(0)" className="nav-link">
                                                <span>
                                                    <img src={require('../../assets/images/conatct-us.png')} alt="logo" />
                                                </span>
                                                Contact Us
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            
                            {this.props.history.location.pathname === '/' ?
                                <div className="col-4 ml-auto">
                                    <div className="right_login">
                                        {/* <a href="login.html"> */}
                                        <a onClick={() => this.HandleClick('login')}>
                                            <span>
                                                <img style={{width: "25px"}} src={require('../../assets/images/login.png')} alt="login_logo" />
                                            </span>
                                            <span className="lg" style={{fontWeight: "bold"}}>Login</span>
                                        </a>
                                    </div>
                                </div>
                                : ''}
                        </div>
                    </div>
                </header>
            </div>
        )
    }
}