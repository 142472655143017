import { PrAjax } from '../library/ajax/pr-ajax';



export function updateS3Details(store, successFunc, s3Object) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Post('customerQuestionAnswer/updateUploadDetails').ExtraData(s3Object).AttachLoader(loader).OnSuccess(successFunc).Do();
}


