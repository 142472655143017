import React, { Component } from 'react';
import QuoteTSMCComponent from './quotetsmc';
import { GetQuoteNumber, GetTechNodes, PostTechData, GetFoundryServicePricing, GetFoundryDieWaferQuantity, GetTncList, saveQuoteData } from '../../../services/quotetsmc';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import PrValidate from "../../../library/pr-controls/pr-validation-full";
import $ from 'jquery';
import {getCustomerData} from "../../../services/users";
import QuoteMPWComponent from "../Quote_MPW/quotempw";
import DashboardCtrl from "../Home";
import {PrConnect} from "../../../library/pr-miniredux";
import quoteMPWCtrl from "../Quote_MPW";

export default class quoteTSMCCtrl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetchQuoteNumber: true,
            fetchNodeList: true,
            techNodeData: [],
            additionalDies: [],
            additionalWafers: [],

            fetchFoundryServicePricing:true,
            fetchFoundryDieWaferQuantity:true,
            fetchTncList: true,

            foundryServicePricingList:{},
            foundryDieWaferQuantity:{},


            SHLForN22N65:'',
            bumpingLeadFree:'',
            bumpingCopper:'',
            quoteData:{},

            pricingOptions:{},

            optionsAddersWaferSize8: {"options": [], "adders": []},//save options and adders for size 8
            optionsAddersWaferSize12: {"options": [], "adders": []},//save options and adders for size 12

            trWaferSize8: {"options": [], "adders": []},
            trWaferSize12: {"options": [], "adders": []},

            showOptions: false,
            showOptionType:{},
            custData: true,
            customerData: {},
            tsmpwQuotationServiceList:[],
            foundryServicePricingId:'',
            redirectToMainPage: false,
            dieWaferOptions: [],
            initialQuoteNumber:'',
            mpwTsmcPricingIdMap:{},
            quotationTncList:{},
            quotationTncListMap:{},
            mosisMarkup:'',
            selectedPricingObj:{},

            postData: {
                txtCustName: "",
                txtQuoteNumber: "",
                txtEmail: JSON.parse(localStorage.getItem('okta-token-storage')).idToken.claims.email,
                txtDesignNumber: "",
                technologyProcessId: "",
                txtInputDieSize: "",
                txtFoundryMinDesignArea: "",
                baseNumberOfDie: "",
                additionalDieQuantity: "",
                additionalWaferQuantity: "",
                txtRetailPrice: "",
                txtTotalPrice: "",
                additionalDiePrice: "",
                additionalDesignUploads: ""
            },
           /* pricingOptions: {
                "Additional Design Uploads": {
                    'foundryServicePricingId': "526",
                    'foundryServiceId': "26",
                    'isPercent': "No",
                    'unit': "",
                    'unitPrice': "3333.0",
                    'mosisMarkup': Number("50.0"),
                    'serviceName': "Additional Design Uploads",
                    'displayLabel': "",
                    'techNode': "350nm"

                },
                "Extra Die Saw": {
                    "foundryServicePricingId": "536",
                    "foundryServiceId": "10",
                    "isPercent": "No",
                    "unit": "Die",
                    "unitPrice": "5.0",
                    "mosisMarkup": 0,
                    "serviceName": "Extra Die Saw",
                    "displayLabel": "",
                    "techNode": ""
                },
                "Whole Sample Wafer Charge - 8 inch": {
                    "foundryServicePricingId": "537",
                    "foundryServiceId": "11",
                    "isPercent": "No",
                    "unit": "Wafer",
                    "unitPrice": "800.0",
                    "mosisMarkup": 0,
                    "serviceName": "Whole Sample Wafer Charge - 8 inch",
                    "displayLabel": "",
                    "techNode": ""
                },
                "Whole Sample Wafer Charge - 12 inch": {
                    "foundryServicePricingId": "538",
                    "foundryServiceId": "14",
                    "isPercent": "No",
                    "unit": "Wafer",
                    "unitPrice": "1200.0",
                    "mosisMarkup": 0,
                    "serviceName": "Whole Sample Wafer Charge - 12 inch",
                    "displayLabel": "",
                    "techNode": ""
                },
                "Bumping Copper - 12 inch": {
                    "foundryServicePricingId": "540",
                    "foundryServiceId": "20",
                    "isPercent": "No",
                    "unit": "Wafer",
                    "unitPrice": "170.0",
                    "mosisMarkup": 0,
                    "serviceName": "Bumping Copper - 12 inch",
                    "displayLabel": "",
                    "techNode": "",
                    "serviceNre": {
                        "serviceName": "Bumping NRE - Copper - 12 inch",
                        "foundryServiceId": "19"
                    }
                },
                "Bumping Lead-free - 12 inch": {
                    "foundryServicePricingId": "542",
                    "foundryServiceId": "22",
                    "isPercent": "No",
                    "unit": "Wafer",
                    "unitPrice": "170.0",
                    "mosisMarkup": 0,
                    "serviceName": "Bumping Lead-free - 12 inch",
                    "displayLabel": "",
                    "techNode": "",
                    "serviceNre": {
                        "serviceName": "Bumping NRE - Lead-free - 12 inch",
                        "foundryServiceId": "21"
                    }
                },
                "SHL for N12  - N20- 12 inch - per lot": {
                    "foundryServicePricingId": "543",
                    "foundryServiceId": "31",
                    "isPercent": "No",
                    "unit": "",
                    "unitPrice": "90000.0",
                    "mosisMarkup": 0,
                    "serviceName": "SHL for N12  - N20- 12 inch - per lot",
                    "displayLabel": "Lot Expediting Service per Tech Class",
                    "techNode": ""
                },
                "SHL for N22 - N65 - 12 inch - per lot": {
                    "foundryServicePricingId": "544",
                    "foundryServiceId": "32",
                    "isPercent": "No",
                    "unit": "",
                    "unitPrice": "48000.0",
                    "mosisMarkup": 0,
                    "serviceName": "SHL for N22 - N65 - 12 inch - per lot",
                    "displayLabel": "Lot Expediting Service per Tech Class",
                    "techNode": ""
                }
            },*/
            /*nreOptionsAndAddersArr: {
                "Bumping NRE - Copper - 12 inch": {
                    foundryServicePricingId: "539",
                    foundryServiceId: "19",
                    isPercent: "No",
                    unit: "Each",
                    unitPrice: "6500.0",
                    waferSize: "12",
                    mosisMarkup: 0,
                    serviceName: "Bumping NRE - Copper - 12 inch",
                    processName: ""
                },
                "Bumping NRE - Lead-free - 12 inch": {
                    foundryServicePricingId: "541",
                    foundryServiceId: "21",
                    isPercent: "No",
                    unit: "Each",
                    unitPrice: "6500.0",
                    waferSize: "12",
                    mosisMarkup: 0,
                    serviceName: "Bumping NRE - Lead-free - 12 inch",
                    processName: ""
                }
            }*/
        };
    }

    optionAllowed = (serviceName, selectedTechNode) => {
        /*var returnVal = serviceName !== CONSTANT_OPTION_ADDITIONAL_WAFER
            && serviceName !== "Additional Design Uploads"
            && serviceName !== "Extra Die Saw"
            && serviceName.trim().toLowerCase().lastIndexOf("whole sample wafer charge", 0) !== 0 ;*/

        var returnVal = serviceName !== "Total number of die to be delivered as sample wafer rather than as die"
            && serviceName !== "Additional Design Uploads"
            && serviceName !== "Extra Die Saw"
            && serviceName.trim().toLowerCase().lastIndexOf("whole sample wafer charge", 0) !== 0 ;

        if(serviceName == "SHL for N12  - N20- 12 inch - per lot"){
            returnVal = returnVal && selectedTechNode >= 12 && selectedTechNode <= 20;
        } else if(serviceName == "SHL for N22 - N65 - 12 inch - per lot"){
            returnVal = returnVal && selectedTechNode >= 22 && selectedTechNode <= 65;
        }

        return returnVal
    }

    //options and adders

    addOptionsAdders = (waferOptionsAdders, waferSize, isOptions, selectedTechNode) => {
        var trArray = [];
        // var trArray = waferSize === 8 ? trWaferSize8 : trWaferSize12;
        // trArray = isOptions ? trArray.options : trArray.adders;

        var trClassName = isOptions ? "options" : "adders";

        //only make tr's if they aren't already there
        console.log("waferOptionsAdders: "+waferOptionsAdders)
        if( trArray.length === 0){

            for(var i = 0; i < waferOptionsAdders.length; i++){
                var waferOption = waferOptionsAdders[i];
                console.log("waferOption: "+waferOption)


                let that = this
                if(this.optionAllowed(waferOption.serviceName, selectedTechNode)) {
                    console.log("INSIDE ADDOPTIONS: "+waferOption.serviceName)

                    let div1 = document.createElement("div")
                    div1.className = "col-lg-12 "+trClassName+"DynamicWafer";
                    let div2 = document.createElement("div")
                    div2.className = "brd_section";
                    let div3 = document.createElement("div")
                    div3.className = "row new_pos";
                    let labelDiv1 = document.createElement("div")
                    labelDiv1.className = "col-lg-5 col-md-5 col-sm-6";
                    let labelDiv2 = document.createElement("div")
                    labelDiv2.className = "inner_left";
                    let label = document.createElement("label")

                    let displayLabel = waferOption.displayLabel;
                    console.log("Display Label :"+displayLabel)
                    displayLabel = displayLabel!=null && displayLabel.length > 0 ? displayLabel : waferOption.serviceName;
                    console.log("Display Label :"+displayLabel)
                    let optionNameToDisplay = displayLabel ? displayLabel.replace(/\s+/g, ' ').replace(/- 8 inch|- 12 inch/g, '') : displayLabel;
                    label.innerText = optionNameToDisplay
                    labelDiv2.appendChild(label)
                    labelDiv1.appendChild(labelDiv2)
                    div3.appendChild(labelDiv1)


                    let selectdiv1 = document.createElement("div")
                    selectdiv1.className = "col-lg-7 col-md-7 col-sm-6";
                    let selectdiv2 = document.createElement("div")
                    selectdiv2.className = "row";
                    let selectdiv3 = document.createElement("div")
                    selectdiv3.className = "col-lg-6 col-md-6 col-sm-12";
                    let selectdiv4 = document.createElement("div")
                    selectdiv4.className = "inner_right";
                    let selectdiv5 = document.createElement("div")
                    selectdiv5.className = "form-group";
                    let selectdiv6 = document.createElement("div")
                    selectdiv6.className = "custom_select";

                    var select = document.createElement("select");
                    select.id = "id-" + waferOption.foundryServiceId;
                    select.className = trClassName + "Selection priceChangeCl";
                    select.setAttribute("serviceName", String(waferOption.serviceName));
                    select.setAttribute("foundryServiceId", String(waferOption.foundryServiceId));
                    select.onchange = this.calculatePrices.bind(this);

                    var option = document.createElement("option");
                    option.text = "Select";
                    option.value = "";
                    select.appendChild(option);

                    option = document.createElement("option");
                    option.text = "Yes";
                    option.value = option.text;
                    select.appendChild(option);

                    selectdiv6.appendChild(select)
                    selectdiv5.appendChild(selectdiv6)
                    selectdiv4.appendChild(selectdiv5)
                    selectdiv3.appendChild(selectdiv4)
                    selectdiv2.appendChild(selectdiv3)
                    selectdiv1.appendChild(selectdiv2)

                    div3.appendChild(selectdiv1)
                    div2.appendChild(div3)
                    div1.appendChild(div2)

                    trArray.push(div1);
                }
            }
        }

        console.log("trArray: "+JSON.stringify(trArray))

        //remove the older tr before adding new
        this.removeElements(trClassName + "DynamicWafer");

        //add the new elements
        this.appendElements(document.getElementById(trClassName + 'Row'), trArray);
        trArray = [];//clear it
    }


    //append element array to given parent
    appendElements = (parent, elementsToAdd) => {
        if (!parent || !elementsToAdd || elementsToAdd.length === 0)
            return;

        for (var i = 0; i < elementsToAdd.length; i++) {
            parent.appendChild(elementsToAdd[i]);
        }
    }
//remove elements for giving class name
    removeElements = (className) => {
        var elementsToRemove = document.getElementsByClassName(className);
        if (elementsToRemove && elementsToRemove.length > 0) {
            while (elementsToRemove.length > 0) elementsToRemove[0].parentNode.removeChild(elementsToRemove[0]);
        }
    }

    //additional die/wafer options

    addDieWaferQuantity = (waferSize, isDie) => {
        var parentId = isDie ? "additionalDieQuantity" : "additionalWaferQuantity";
        var optionsClass = parentId + "-cl";

        var arrayKey = (isDie ? "die" : "wafer") + "-" + waferSize;

        //generate options if not already generated
        if(!this.state.dieWaferOptions[arrayKey]){
            console.log("Generate options!");
            var dieObj = this.state.foundryDieWaferQuantity[waferSize].die;
            var numOfDie = dieObj.endingQuantity / dieObj.increment;

            var optionsArr = [];
            for(var i = 0; i < numOfDie; i++){
                var option = document.createElement("option");
                option.text = String(dieObj.increment * (i + 1));
                option.value = option.text;
                option.className = optionsClass;

                optionsArr.push(option);
            }

            //backup options array
            this.state.dieWaferOptions[arrayKey] = optionsArr;
        }

        //remove the older tr before adding new
        this.removeElements(optionsClass);

        //add new options
        this.appendElements(document.getElementById(parentId), this.state.dieWaferOptions[arrayKey])
    }



    selectTechnologyNode = (selectObj) => {
        selectObj = !selectObj ? document.getElementById("quotationRevision.technologyNode") : selectObj;
        console.log("selectObj: "+JSON.stringify(selectObj.target.value))
        var technolodyNodeData = this.getSelectedNodeData()

        console.log("technolodyNodeData.technologyProcess: "+JSON.stringify(technolodyNodeData))
        var selectedTechnologyName = technolodyNodeData.technologyProcess["processName"]
        console.log("selectedTechnologyName: "+JSON.stringify(selectedTechnologyName))

        if(selectedTechnologyName){
            var isWaferSize8 = selectedTechnologyName.indexOf("8 inch", selectObj.target.value.length - "8 inch".length) !== -1;
            var waferSize = isWaferSize8 ? 8 : 12;
            var optionsToShow  = isWaferSize8 ? this.state.optionsAddersWaferSize8.options : this.state.optionsAddersWaferSize12.options;
            var addersToShow  = isWaferSize8 ? this.state.optionsAddersWaferSize8.adders : this.state.optionsAddersWaferSize12.adders;



            console.log("optionsToShow: "+JSON.stringify(optionsToShow))
            this.state.selectedPricingObj = this.state.pricingOptions["Additional Design Uploads"][selectedTechnologyName]

            var pricingObj = this.state.pricingOptions["Additional Design Uploads"][selectedTechnologyName]
            console.log("pricingObj: "+JSON.stringify(pricingObj))
            console.log("pricingObj[selectObj.target.value].technologyProcess: "+JSON.stringify(pricingObj["technologyProcess"]))
            console.log("pricingObj[\"technologyProcess\"][\"technologyNode\"]: "+pricingObj["technologyProcess"]["technologyClassObject"])
            var techNode = pricingObj["technologyProcess"]["technologyClassObject"]
            var selectedTechNode = techNode["codeValue"].match(/\d+/)[0];

            //console.log("MARKUP: "+this.state.pricingOptions["Additional Design Uploads"][selectedTechnologyName].technologyProcess["mosisMarkup"])
            //this.state.mosisMarkup = 50

            this.state.mosisMarkup = this.state.pricingOptions["Additional Design Uploads"][selectedTechnologyName].technologyProcess["mosisMarkup"]
            console.log("this.state.mosisMarkup: "+this.state.mosisMarkup)

            this.addOptionsAdders(optionsToShow, waferSize, true, selectedTechNode);//adding options
            this.addOptionsAdders(addersToShow, waferSize, false, selectedTechNode);//adding adders

            this.addDieWaferQuantity(waferSize, true);//adding die quantity
            this.addDieWaferQuantity(waferSize, false);//adding wafer quantity


            let that = this


            var numbsize_12 = [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900, 2000];
            var numbsize_8 = [40, 80, 120,160,200,240,280,320,360,400,440,480,520,560,600,640,680,720,760,800];

            /*var numbsize = isWaferSize8? numbsize_8: numbsize_12
            this.setState({
                additionalDies: numbsize,
                additionalWafers: numbsize
            })*/



            //this.addOptionsAdders(optionsToShow, waferSize, true, selectedTechNode);//adding options
            //this.addOptionsAdders(addersToShow, waferSize, false, selectedTechNode);//adding adders

           /* addDieWaferQuantity(waferSize, true);//adding die quantity
            addDieWaferQuantity(waferSize, false);//adding wafer quantity

            setFormEleVal('foundryMinimumArea', roundOff(getTsmcPricingProperty('blockSize'), 1));
            setFormEleVal('baseNumberOfDie', getTsmcPricingProperty('bareDieQuantity'));

            setSpanText('foundryMinimumArea-span', roundOff(getTsmcPricingProperty('blockSize'), 1));
            setSpanText('baseNumberOfDie-span', roundOff(getTsmcPricingProperty('bareDieQuantity'), 1));

            //set pricing expiry
            setFormEleVal("mpwTsmcPricingId", tsmcPricingArr[selectObj.value].mpwTsmcPricingId);
            document.getElementById("mosisPriceFactor").value = roundOff(tsmcPricingArr[selectObj.value].mosisMarkup, 1);

            verifyAdditionalWaferQuantity();*/
        }

        //calculatePrices();
    }

    HandleChange = (e) => {
        var postData = this.state.postData;
        console.log("postData=========================: "+JSON.stringify(postData))
        console.log("e.target.name: "+e.target.id)
        if (e.target.name === "technologyProcessId") {
            let ddlTechNode = PrValidate.ForceSelect(e.target.value, 'technologyProcessId', 'a technology node');
            postData[e.target.name] = e.target.value;
            if (e.target.value) {
                this.selectTechnologyNode(e)
                console.log("pricingOptions: "+JSON.stringify(this.state.pricingOptions))

                //document.getElementById("SHLForN22N65").setAttribute("serviceName", String(pricing.foundryService["serviceName"]));
                var technologyProcessData = this.getSelectedNodeData();
                console.log("technologyProcessData: "+JSON.stringify(technologyProcessData))
                let pricing = this.state.pricingOptions["Additional Design Uploads"][technologyProcessData.technologyProcess["processName"]]

                this.state.foundryServicePricingId = pricing["foundryServicePricingId"]
                console.log("foundryServicePricingId: "+this.state.foundryServicePricingId)


                postData.txtFoundryMinDesignArea = this.roundOff(technologyProcessData.blockSize, 1);
                postData.baseNumberOfDie = this.normalizeNumber(technologyProcessData.bareDieQuantity);

                this.setState({ postData: postData});
                // postData.txtRetailPrice = this.numberFormatWithPrefix(1231221213, "$");
                // postData.txtTotalPrice = this.numberFormatWithPrefix(1231221213, "$");

                /*var ele = document.getElementById("SHLForN22N65");
                console.log(JSON.stringify(ele))*/
                //ele.setAttribute("serviceName", String(pricing.foundryService["serviceName"]));
                this.setState({ postData: postData });
                this.calculatePrices();
            }
            else {
                postData.txtFoundryMinDesignArea = '';
                postData.baseNumberOfDie = '';
                postData.txtInputDieSize ='';
                postData.additionalDieQuantity ='';
                postData.txtRetailPrice ='';
                postData.txtAdditionalDie ='';
                postData.additionalWaferQuantity ='';
                postData.txtOptions ='';
                postData.additionalDesignUploads ='';
                this.setState({ additionalDies: [], additionalWafers: [], postData: postData,txtTotalPrice: '' })
                this.calculatePrices();
                this.calculateAdditionalDesignUploadAndDrcFees()
            }
        }
        else if (e.target.name === "txtInputDieSize") {

                console.log('decimalcheck got called', e.target.value);
                //if(e.target.value.match(/^\d*\.?\d\d?$/g) || e.target.value == "") {
                if(e.target.value.match(/^(?:\d{1,6}|(?!.{7})\d+\.\d+)$/g) || e.target.value.match(/^(?:\d{1,6}|(?!.{7})\d+\.)$/g) || e.target.value == "") {

                    console.log("inside IF======")
                    postData.txtInputDieSize = e.target.value;
                    this.setState({
                        [e.target.name]: e.target.value,
                        postData: postData
                    })
                }

            console.log("INSIDE txtInputDieSize")
            console.log("die size before: "+(e.target.value).replace(/[^0-9]/g, ''))
            //let txtInputDieSize = PrValidate.ForceRNumber((e.target.value).replace(/[^0-9]/g, ''), 'txtInputDieSize');
            let txtInputDieSize = PrValidate.ForceEntry((e.target.value).replace(/[^0-9]/g, ''), 'txtInputDieSize', 'a die size');
            console.log("die size after: "+(e.target.value).replace(/[^0-9]/g, ''))
            //postData.txtInputDieSize = this.numberFormatWithPrefix(e.target.value);
        }
        else if (e.target.name === "additionalDesignUploads" || e.target.name === "bumpingCopper" || e.target.name === 'bumpingLeadFree' || e.target.name === 'SHLForN22N65') {
            console.log("Inside bumpingCopper")
            postData[e.target.name] = e.target.value;
            this.setState({
                [e.target.name]: e.target.value
            })
            // postData.additionalDesignUploads = e.target.value;
            this.calculatePrices();
        }
        else {
            if(e.target.name === "txtDesignNumber"){
                let txtDesignNumber = PrValidate.ForceRNumber((e.target.value).replace(/[^0-9]/g, ''), 'txtDesignNumber');
            }else if(e.target.name === "txtCustName"){
                let txtCustName = PrValidate.ForceEntry(e.target.value, 'txtCustName', 'customer name');
            }

            console.log("Inside ELSE ==========")
            postData[e.target.name] = e.target.value;
            this.setState({ postData: postData });
        }

    }

    createServiceObject = () =>{

        console.log("INSIDE createServiceObject")

        var optionsArr = document.getElementsByClassName("optionsSelection");
        console.log(optionsArr)
        for (var i = 0; i < optionsArr.length; i++) {
                var selectedVal = optionsArr[i].value;
                var serviceName = optionsArr[i].getAttribute("serviceName");

                if (selectedVal && selectedVal.length > 0) {
                    console.log("serviceName --> " + serviceName);

                    console.log("PRICING OPTION: "+this.state.pricingOptions[serviceName])
                    let serviceObject = {
                        foundryServiceId:this.state.pricingOptions[serviceName].foundryService.foundryServiceId,
                        serviceValue:selectedVal
                    }
                    this.state.tsmpwQuotationServiceList.push(serviceObject)
                }
            }

        var optionsArr = document.getElementsByClassName("optionsSelection2");
        console.log(optionsArr)
        for (var i = 0; i < optionsArr.length; i++) {
            var selectedVal = optionsArr[i].value;
            var serviceName = optionsArr[i].getAttribute("serviceName");

            if (selectedVal && selectedVal.length > 0) {
                console.log("serviceName --> " + serviceName);

                console.log("PRICING OPTION: "+JSON.stringify(this.state.pricingOptions[serviceName]))
                let serviceObject = {
                    foundryServiceId:this.state.selectedPricingObj.foundryService.foundryServiceId,
                    serviceValue:selectedVal
                }
                this.state.tsmpwQuotationServiceList.push(serviceObject)
            }
        }


    }

    getTsmcPricingProperty(propertyName) {
        var result = 0;

        var technologyProcessData = this.getSelectedNodeData();

        if (technologyProcessData)
            result = technologyProcessData[propertyName];

        return this.normalizeNumber(result);
    }

    getMosisPriceFactor() {
        //return this.getFormEleVal("#mosisPriceFactor") / 100;
        return this.state.mosisMarkup / 100;
    }



    onChangeInputDieSize = (e) => {
        console.log("Inside onChangeInputDieSize")
        let val = "";
        if(e.target.value.length > 0 && this._isNumber(e.target.value)){
            val = e.target.value;
            val = this.roundOff(val, 1);
            this.state.postData.txtInputDieSize = val
            this.setState({
                [e.target.name]: val
            })
        }

        //e.target.value = val;
        this.calculatePrices();
    }

    calculateReticleSpaceAndBaseDiePrice = () => {
        // =IF(Input Die size in sq mm (pre shrink) < Foundry minimum area for a design (sq mm),
        //      Block Fee * (1 + Mosis price factor)
        //      ,
        //      (Input Die size in sq mm (pre shrink) * (Block Fee/"Block Size mm²) * (1 + Mosis price factor)))
        var technologyProcessData = this.getSelectedNodeData();
        var result;

        var inputDieSize = this.getFormEleVal("#txtInputDieSize");
        var foundryMinimumArea = this.state.postData.txtFoundryMinDesignArea;


        /*var inputDieSize = this.state.postData.txtFoundryMinDesignArea
        var foundryMinimumArea = this.state.postData.baseNumberOfDie*/

        var mosisPriceFactor = this.getMosisPriceFactor();

        var blockFee = technologyProcessData.blockFee;//this.getTsmcPricingProperty('blockFee');
        var blockSize = technologyProcessData.blockSize;//this.getTsmcPricingProperty('blockSize');

        console.log("txtFoundryMinDesignArea: "+this.state.postData.txtFoundryMinDesignArea);
        console.log("baseNumberOfDie: "+this.state.postData.baseNumberOfDie);

        console.log("inputDieSize: "+inputDieSize)
        console.log("foundryMinimumArea: "+foundryMinimumArea)
        console.log("foundryMinimumArea: "+this.state.postData.txtFoundryMinDesignArea)

        console.log("blockFee: "+blockFee)
        console.log("blockSize: "+blockSize)
        console.log("mosisPriceFactor: "+mosisPriceFactor)
        result = inputDieSize < foundryMinimumArea ? blockFee * (1 + mosisPriceFactor)
            : inputDieSize * (blockFee / blockSize) * (1 + mosisPriceFactor);
        console.log("result: "+result)
        result = this.normalizeAndRoundOff(result);

        console.log("result: "+result)
        // setSpanText('reticleSpaceAndBaseDie', result);
        // setFormEleVal('reticleSpacePrice', result);
        var postData = this.state.postData;
        postData.txtRetailPrice = this.numberFormatWithPrefix(result, "$");
        //postData.txtTotalPrice = this.numberFormatWithPrefix(result, "$");
        this.setState({ postData: postData });

        return result;
    }

    getSelectedNodeData = () => {
        var ele = document.querySelector("[name='technologyProcessId']");
        var nodeindex = ele.options[ele.selectedIndex].dataset.index;
        return this.state.techNodeData[nodeindex];
    }

    getFormEleVal = (elementName) => {
        var ele = document.querySelector(elementName);
        return ele ? this.normalizeNumber(ele.value) : 0;
    }

    // additionalWaferQuantity should be lesser than then sum of baseNumberOfDie and additionalDieQuantity
    verifyAdditionalWaferQuantity = (e) => {
        console.log("NAME: "+e.target.name)
        /*this.state.postData.additionalWaferQuantity= e.target.value
        this.setState({
            [e.target.name]:e.target.value,
            postData: this.state.postData
        })*/
        var ele = document.getElementById("additionalWaferQuantity");

        var additionalDieQuantity = this.getFormEleVal("#additionalDieQuantity");
        var baseNumberOfDie = this.getFormEleVal("#baseNumberOfDie");

        console.log("additionalDieQuantity: "+additionalDieQuantity)
        console.log("baseNumberOfDie: "+baseNumberOfDie)

        if (this._isNumber(ele.value) && ele.value > (additionalDieQuantity + baseNumberOfDie)) {
            alert("Total number of die to be delivered as sample wafer can't be greater than the sum of 'Base number of Die' and 'Additional Die Quantity'");

            ele.value = "";
            ele.focus();
        }

        var postData = this.state.postData;

        if(e.target.name == "additionalDieQuantity"){
            postData[e.target.name] = additionalDieQuantity;
        }else{
            postData[e.target.name] = ele.value;
        }

        this.setState({ postData: postData });
        this.calculatePrices();
    }

    setFormEleVal = (elementName, value) => {
        document.querySelector("#txtInputDieSize").value = value;
    }

    numberFormatWithPrefix = (value, prefix = "") => {
        const re = /^[0-9\b]+$/;
        // if value is not blank, then test the regex
        let val = String(value).replace(/[^0-9]/g, "");
        if ((val !== undefined && val !== "") || re.test(val)) {
            val = val.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
            if (prefix) {
                if (val !== "") {
                    val = prefix + "" + val;
                }
            }
            return val;
        }
        return prefix + "0";
    }



    getEleVal(elementId) {
        var element = document.getElementById(elementId);
        return element ? element.value.replace(/[^0-9]/g, "") : 0;
    }

    calculateAdditionalDiePrice = () => {
        /*
        * Additional Die =
            IF(B75="select",0,D100)
    
            Additional Die =
            IF(Additional Die Quantity = 0,
                    0,
                    D100)
                    #D100 =(((B75/B73)*G4)+D76)*(1+B64)
                    #D100 =(((Additional Die Quantity / Base number of Die) * Extra Wafer Fee) + D76)*(1+Mosis price factor)
                        #D76 = IF(F75>0,F75,0)
                            #F75 = =IF(B75="select",0,B75*D49)+F76 			-->   		=IF(Additional Die="0,0,Additional Die * unit price{Extra Die Saw} ) + F76
                                #F76 =IF(B79="select",0,(B79-(B75-B73))*-D49)			-->		=IF(B79="0",0,(B79-(B75-Base number of Die))*-unit price{Extra Die Saw})
        *
        * */

        var technologyProcessData = this.getSelectedNodeData();
        var postData = this.state.postData;

        var mosisPriceFactor = this.getMosisPriceFactor();
        var additionalWaferQuantity = this.state.postData.additionalWaferQuantity;

        var additionalDieQuantity = postData.additionalDieQuantity;//getFormEleVal('additionalDieQuantity');
        var baseNumberOfDie = postData.baseNumberOfDie;//getFormEleVal('baseNumberOfDie');

        var extraWaferFee = technologyProcessData.extraWaferFee;//getTsmcPricingProperty('extraWaferFee');

        var extraDieSaw = this.state.pricingOptions['Extra Die Saw'].unitPrice;//unit price{Extra Die Saw}) //d49
        //var extraDieSaw = 0
        var f76 = !additionalWaferQuantity || additionalWaferQuantity < 1 ? 0
            : (additionalWaferQuantity - (additionalDieQuantity - baseNumberOfDie)) * -extraDieSaw;

        var f75 = (!additionalDieQuantity || additionalDieQuantity < 1 ? 0
            : additionalDieQuantity * extraDieSaw) + f76;

        var d76 = f75 > 0 ? f75 : 0;

        var d100 = (((additionalDieQuantity / baseNumberOfDie) * extraWaferFee) + d76) * (1 + mosisPriceFactor);

        var result = !additionalDieQuantity || additionalDieQuantity < 1 ? 0 : d100;
        result = this.normalizeAndRoundOff(result);

        postData.txtAdditionalDie = this.numberFormatWithPrefix(result, "$");
        postData.additionalDiePrice = this.numberFormatWithPrefix(result, "$");
        this.setState({ postData: postData });
        // setSpanText('additionalDie', result);
        // setFormEleVal('additionalDiePrice', result);

        return result;
    }


    calculatePrices() {

        //only calculate prices if tech node is selected
        var postData = this.state.postData;

        if (postData.technologyProcessId === "")
            return false;

        var reticleSpaceAndBaseDiePrice = this.calculateReticleSpaceAndBaseDiePrice();
        var additionalDiePrice = this.calculateAdditionalDiePrice();
        var optionsPrice = this.calculateOptionsPrice();
        var additionalDesignUploadAndDrcFees = this.calculateAdditionalDesignUploadAndDrcFees();

        console.log("reticleSpaceAndBaseDiePrice: "+Number(reticleSpaceAndBaseDiePrice))
        console.log("additionalDiePrice: "+Number(additionalDiePrice))
        console.log("optionsPrice: "+Number(optionsPrice))
        console.log("additionalDesignUploadAndDrcFees: "+Number(additionalDesignUploadAndDrcFees))

        var totalPrice = Number(reticleSpaceAndBaseDiePrice) + Number(additionalDiePrice) + Number(optionsPrice)
            + Number(additionalDesignUploadAndDrcFees);

        totalPrice = Math.round(Number(totalPrice));

        var finalPrice = this.numberFormatWithPrefix(totalPrice, "$")
        console.log("Total Price: "+totalPrice)
        console.log("Final Price: "+finalPrice)

        /*setSpanText('totalPrice', totalPrice);
        setFormEleVal('totalPrice', totalPrice);*/
        this.setState({
            txtTotalPrice: finalPrice
        })

        // setSpanText('totalPrice', totalPrice);
        // setFormEleVal('totalPrice', totalPrice);

        return totalPrice;
    }

    calculateOptionsPrice() {
        console.log("=============================INSIDE calculateOptionsPrice=============================")
        /*
        *   Options = D92*(1+B64)
            Options = D92*(1+Mosis price factor)
                        #D92 =SUM(D79:D91)
                            #D79 =Total number of die to be delivered as sample wafer rather than as die
                            #D79 =IF(B79="select",0,(B79/B73)*D53)
                                #D53 = Unit Price(Whole Sample Wafer Charge - 12 inch)


                            #D81 =IF(B81="select",0,D54+H75*D55)
                                #D54 = unit price (Bumping NRE Eutectic - 12 inch)
                                #H75 = *
                                #D55 = Unit Price(Bumping Eutectic -  12 inch

                            #D83 =IF(B83="select",0,D56+H75*D57)
                                #D56  = unit price (Bumping NRE Eutectic with BGM2 - 12 inch
                                #H75 = *
                                #D57 = unit price ((Bumping Eutectic with BGM2 - 12 inch)
                        #D93 =IF(B91="select",0,B91*(H4))       --> result of Quantity of additional design uploads
                                B91 = Quantity of additional design uploads
                                */
        var technologyProcessData = this.getSelectedNodeData();
        var postData = this.state.postData;
        var baseNumberOfDie = postData.baseNumberOfDie;//getFormEleVal('baseNumberOfDie');
        console.log("baseNumberOfDie: "+baseNumberOfDie)
        var additionalDieQuantity = postData.additionalDieQuantity;//getFormEleVal('additionalDieQuantity');
        console.log("additionalDieQuantity: "+additionalDieQuantity)

        var mosisPriceFactor = this.getMosisPriceFactor();
        console.log("mosisPriceFactor: "+mosisPriceFactor)

        var additionalWaferQuantity = postData.additionalWaferQuantity;//document.getElementById("additionalWaferQuantity").value;//Total number of die to be delivered as sample wafer rather than as die
        console.log("additionalWaferQuantity: "+additionalWaferQuantity)

        var d53 = 0;
        if (technologyProcessData.waferSize > 0)
            d53 = this.state.pricingOptions["Whole Sample Wafer Charge - " + technologyProcessData.waferSize + " inch"].unitPrice;

        d53 = this.normalizeNumber(d53);
        console.log("d53: "+d53)

        var d79 = !this.isPositiveNumber(additionalWaferQuantity) ? 0
            : (additionalWaferQuantity / baseNumberOfDie) * d53;//=IF(B79="select",0,(B79/B73)*D53)
        d79 = this.normalizeNumber(d79);
        console.log("d79: "+d79)


        var h75 = !this.isPositiveNumber(additionalDieQuantity) ? 1
            : (additionalDieQuantity / baseNumberOfDie) + 1; //=IF(B75="select",1,(B75/B73)+1);

        h75 = this.normalizeNumber(h75);
        console.log("h75: "+h75)

        //loop through all options and adders
        var optionsSum = 0;
        var optionsArr = document.getElementsByClassName("optionsSelection");
        console.log(optionsArr)
        for (var i = 0; i < optionsArr.length; i++) {
            var selectedVal = optionsArr[i].value;
            var serviceName = optionsArr[i].getAttribute("serviceName");

            if (selectedVal && selectedVal.length > 0) {
                console.log("serviceName --> " + serviceName);

                var srvcNamePricingObj = this.state.pricingOptions[serviceName];
                //console.log("srvcNamePricingObj: "+JSON.stringify(srvcNamePricingObj))
                //console.log("srvcNamePricingObj.serviceNre: "+JSON.stringify(srvcNamePricingObj["foundryService"]["serviceNre"]))
                //console.log("srvcNamePricingObj.unitPrice: "+srvcNamePricingObj.unitPrice)
                console.log("srvcNamePricingObj: "+JSON.stringify(srvcNamePricingObj))

                let serviceNre = srvcNamePricingObj["foundryService"]["serviceNre"]
                //console.log("serviceName: "+serviceNre["serviceName"])

                if (srvcNamePricingObj) {
                    if (serviceNre && this.state.pricingOptions[serviceNre["serviceName"]]) {
                        var nrePrice = this.normalizeNumber(this.state.pricingOptions[serviceNre["serviceName"]].unitPrice);
                        console.log("nrePrice: "+nrePrice)
                        var calcResult = this.normalizeNumber(srvcNamePricingObj.unitPrice) * h75 + nrePrice;
                        optionsSum += this.normalizeNumber(calcResult);
                    } else {
                        optionsSum += this.normalizeNumber(srvcNamePricingObj.unitPrice ? srvcNamePricingObj.unitPrice : 0);
                    }
                }
            }
        }

        console.log("optionSum: "+optionsSum)
        //TODO: ADD adders
        var addersSum = 0;
        var addersArr = document.getElementsByClassName("addersSelection");
        console.log("addersArr: "+addersArr)

        /*var additionalDesignUploads = getEleVal("additionalDesignUploads");//b91
        var additionalUploadFee = getTsmcPricingOption("Additional Design Uploads");//Addt'l upload Fee // h4
        var d93 = !this.isPositiveNumber(additionalDesignUploads) ? 0
            : additionalDesignUploads * additionalUploadFee;//Quantity of additional design uploads  =IF(B91="select",0,B91*(H4))
        d93 = this.normalizeNumber(d93);*/
        console.log("mosisPriceFactor: "+mosisPriceFactor)
        var result = (d79 + optionsSum + addersSum) * (1 + mosisPriceFactor);//sum all options into mosisPriceFactor

        result = this.normalizeAndRoundOff(result);

        postData.txtOptions = this.numberFormatWithPrefix(result, "$");//setSpanText('optionsPriceSummary', result);
        // setFormEleVal('optionsPrice', result);
        this.setState({ postData: postData });

        console.log("RESULT: "+result)
        return result;
    }

    calculateAdditionalDesignUploadAndDrcFees = () => {
        console.log("INSIDE calculateAdditionalDesignUploadAndDrcFees")
        /*
        * Additional Design Upload and DRC fees
        =D93*(1+B64)
            #D93 =IF(B91="select",0,B91*(H4))
                #B91 =(Quantity of) Additional design uploads
                #H4 = Addt'l upload Fee*/
        var postData = this.state.postData;
        var additionalDesignUploads = postData.additionalDesignUploads;//getEleVal("additionalDesignUploads");
        console.log("additionalDesignUploads: "+additionalDesignUploads)
        var additionalUploadFee = this.getTsmcPricingOption("Additional Design Uploads", 'unitPrice');//Addt'l upload Fee // h4
        console.log("additionalUploadFee: "+additionalUploadFee)
        var result = this.isPositiveNumber(additionalDesignUploads) ? (additionalDesignUploads * additionalUploadFee * (1 + (this.getFormEleVal("#mosisPriceFactor") / 100))) : 0;
        console.log("result: "+result)
        result = this.normalizeAndRoundOff(result);

        postData.txtAdditionalDesignUploadFees = this.numberFormatWithPrefix(result, "$");//setSpanText('additionalDesignUploadAndDRCfees', result);
        // setFormEleVal('designUploadDrcFees', result);
        this.setState({ postData: postData });
        return result;
    }

    //get tsmc pricing for given service for selected technoloy process/node
    getTsmcPricingOption = (serviceName, propertyName) => {
        var result = 0;
        var postData = this.state.postData;

        let selectedNodeData = this.getSelectedNodeData()
        if(selectedNodeData != undefined) {
            let techNode = selectedNodeData.technologyProcess["processName"];
            if (techNode != null) {


                console.log("techNode: " + techNode)
                console.log("this.state.pricingOptions: " + JSON.stringify(this.state.pricingOptions))
                console.log("this.state.pricingOptions[serviceName][techNode]: " + JSON.stringify(this.state.pricingOptions[serviceName][techNode]))

                if (this.state.pricingOptions[serviceName] && this.state.pricingOptions[serviceName][techNode] && this.state.pricingOptions[serviceName][techNode][propertyName]) {
                    result = this.state.pricingOptions[serviceName][techNode][propertyName];
                } else {
                    result = this.state.pricingOptions[serviceName][propertyName];
                }

            }
        }

        return Number(result);
    }

    isPositiveNumber = (val) => {
        let res = parseInt(val) > 0 ? 1 : -1;
        if(res > 0){
            return(true);
        }
        else{
            return(false)
        }
    }

    GetSortOrder = (prop) => {
        return function(a, b) {
            if (a.technologyProcess.technologyClassObject[prop] > b.technologyProcess[prop]) {
                return 1;
            } else if (a.technologyProcess[prop] < b.technologyProcess[prop]) {
                return -1;
            }
            return 0;
        }
    }

    successHandlerTech = (res) => {
        console.log("res.data tech: "+JSON.stringify(res.data))
        let techNodeResponse = res.data
        console.log("techNodeResponse: "+JSON.stringify(techNodeResponse))
        techNodeResponse.sort(this.GetSortOrder("processName"));
        console.log("techNodeResponse sorted: "+JSON.stringify(techNodeResponse))
        this.setState({ techNodeData: techNodeResponse, fetchNodeList: false });

        let that = this
        res.data.map(function(item) {
                that.state.mpwTsmcPricingIdMap[item.technologyProcess["technologyProcessId"]] = item["mpwTsmcPricingId"]
        })

        console.log("successHandlerTech: "+JSON.stringify(this.state.techNodeData))
        console.log("successHandlerTech LENGTH: "+this.state.techNodeData.length)

    }
    successHandleQuoteNumber = (res) => {
        var postData = this.state.postData;
        this.state.initialQuoteNumber =  res.data
        postData.txtQuoteNumber = res.data+"-"+0;
        this.setState({ postData: postData, fetchQuoteNumber: false });
    }

    successHandlerGetFoundryServicePricing = (res) =>{
        console.log("successHandlerGetFoundryServicePricing: "+JSON.stringify(res.data))
        this.setState({
            fetchFoundryServicePricing:false,
            foundryServicePricingList: res.data
        })
        console.log("this.state.fetchFoundryServicePricing: "+JSON.stringify(this.state.fetchFoundryServicePricing))
        console.log("foundryServicePricingList: "+JSON.stringify(this.state.foundryServicePricingList))


        console.log("=========================processName===========================")
        let that = this

        let tempItemList = {}
        this.state.foundryServicePricingList["optionsAndAdders"].map(function(item) {

            if(item["technologyProcess"] != null){
                if (item.technologyProcess["processName"] && item.technologyProcess["processName"].length > 0) {

                    let tempItem = {}
                    tempItem[item.technologyProcess["processName"]] = item
                    console.log("tempItem: "+JSON.stringify(tempItem))

                    tempItemList[item.technologyProcess["processName"]] = item
                    console.log("tempItemList: "+JSON.stringify(tempItemList))
                    that.state.pricingOptions[item.foundryService["serviceName"]] = tempItemList
                }
            }else {
                that.state.pricingOptions[item.foundryService["serviceName"]] = item;
            }

            var arrayToPush = [];
            var nreOptionsAndAddersArr = [];
            if(item.foundryService["serviceType"] == "Options"){
                if(item.foundryService["waferSize"] != null){
                    arrayToPush = (8 == item.foundryService["waferSize"]) ? that.state.optionsAddersWaferSize8.options : that.state.optionsAddersWaferSize12.options;
                }
            }else if(item.foundryService["serviceType"] == "Adders") {
                if (item.foundryService["waferSize"] != null) {
                    arrayToPush = (8 == item.foundryService["waferSize"]) ? that.state.optionsAddersWaferSize8.adders : that.state.optionsAddersWaferSize12.adders;
                }
            }else if(item.foundryService["isSingle"] == null) {
                if (item.foundryService["waferSize"] != null) {
                    nreOptionsAndAddersArr[item.foundryService["serviceName"]] = {
                        serviceName:item.foundryService["serviceName"],
                        serviceId:item.foundryService["foundryServiceId"]
                    };
                }
            }

            let itemToPush = []
            if(item.foundryService["isSingle"] != null){
                itemToPush = {
                    serviceName:item.foundryService["serviceName"],
                    foundryServiceId:item.foundryService["foundryServiceId"],
                    displayOrder: item.foundryService["displayOrder"],
                    displayLabel: item.foundryService["displayLabel"],
                    serviceType: item.foundryService["serviceType"],
                    status: item.foundryService["status"],
                    waferSize: item.foundryService["waferSize"],
                    foundry: item.foundryService["foundry"]
                    //techNode: item["technologyProcess"] != null? item.technologyProcess.technologyClassObj["codeValue"]:null
                }

                arrayToPush.push(itemToPush)
            }


            console.log("arrayToPush: "+JSON.stringify(arrayToPush))

           /* if(item["technologyProcess"] != null){
                console.log(item.technologyProcess["processName"])
                console.log(item.foundryService["serviceName"])

                that.state.pricingOptions[item.technologyProcess["processName"]] = item
            }*/
        })

        console.log("this.state.optionsAddersWaferSize8.options: "+JSON.stringify(this.state.optionsAddersWaferSize8.options))
        console.log("this.state.optionsAddersWaferSize12.options: "+JSON.stringify(this.state.optionsAddersWaferSize12.options))
        console.log("this.state.optionsAddersWaferSize8.adders: "+JSON.stringify(this.state.optionsAddersWaferSize8.adders))
        console.log("this.state.optionsAddersWaferSize12.adders: "+JSON.stringify(this.state.optionsAddersWaferSize12.adders))




        console.log("this.state.pricingOptions: "+JSON.stringify(this.state.pricingOptions))
    }

    successHandlerGetFoundryDieWaferQuantity = (res) =>{
        console.log("successHandlerGetFoundryDieWaferQuantity: "+JSON.stringify(res.data))
        this.setState({
            fetchFoundryDieWaferQuantity:false,
        })


        let dieWaferQuantity = {}
        let that = this
        res.data.map(function(item) {
            dieWaferQuantity = {
                'size': item.waferDieSize,
                'wafer': {
                    'startingQuantity': item.waferStartingQuantity,
                    'endingQuantity': item.waferEndingQuantity,
                    'increment': item.waferIncrement
                },

                'die': {
                    'startingQuantity': item.dieStartingQuantity,
                    'endingQuantity': item.dieEndingQuantity,
                    'increment': item.dieIncrement
                }
            };
            that.state.foundryDieWaferQuantity[dieWaferQuantity.size] = dieWaferQuantity;
        })

        console.log("this.state.foundryDieWaferQuantity[dieWaferQuantity.size]: "+JSON.stringify(this.state.foundryDieWaferQuantity))

    }

    successHandlerGetTncList = (res) => {
        console.log("successHandlerGetTncList: "+JSON.stringify(res.data))
        this.setState({
            fetchTncList: false,
            quotationTncList: res.data
        })

        console.log("quotationTncList: "+JSON.stringify(this.state.quotationTncList))
        console.log("quotationTncList length: "+this.state.quotationTncList.length)
        this.createTncMap()
    }

    createTncMap = () => {
        let that = this
        this.state.quotationTncList.map(function(item) {
            that.state.quotationTncListMap[item["displayOrder"]] = item
            let arrayPoints = item["tcDetails"].split("\r-\t")
            if(arrayPoints.length>1) {
                let liElement = ''
                liElement+=arrayPoints[0];
                for(let i=1; i<arrayPoints.length; i++){
                    liElement+= '<li>'+arrayPoints[i]+'</li>';
                }
                that.state.quotationTncListMap[item["displayOrder"]].tcDetails = liElement
            }else{
                let arrayPoints = item["tcDetails"].split("\t-\t")
                if(arrayPoints.length>1) {
                    let liElement = ''
                    liElement += arrayPoints[0];
                    for (let i = 1; i < arrayPoints.length; i++) {
                        liElement += '<li>' + arrayPoints[i] + '</li>';
                    }
                    that.state.quotationTncListMap[item["displayOrder"]].tcDetails = liElement
                }
            }
            console.log("displayOrder: "+JSON.stringify(that.state.quotationTncListMap[item["displayOrder"]]))
        })


        console.log("quotationTncList: "+JSON.stringify(this.state.quotationTncList))
        console.log("quotationTncList length: "+this.state.quotationTncList.length)
        console.log("quotationTncListMap: "+JSON.stringify(this.state.quotationTncListMap))
    }


    successHandlerSaveQuoteData = (res) => {
        ToastsStore.success("You will receive the quote by email shortly.");
        console.log("INSIDE successHandlerSaveQuoteData")
        console.log("Response: "+JSON.stringify(res.data))
        this.setState({
            saveQuoteData: false,
            redirectToMainPage: true
        })

        setTimeout(function() { //Start the timer
            this.props.componentprops.history.push("/dashboard");
        }.bind(this), 2000)
    }

    roundOff(num, toDecimals) {
        if (!this._isNumber(num))
            throw num;

        return parseFloat(String(num)).toFixed(this.normalizeNumber(toDecimals));
    }
    normalizeNumber(num) {
        return this._isNumber(num) ? Number(num) : 0;
    }

    normalizeAndRoundOff(input) {
        return this.roundOff(this.normalizeNumber(input));
    }

    _isNumber(input) {
        return !(typeof input == 'undefined' || isNaN(input));
    }

    HandleSuccessGetCustomer = (res) => {
        this.setState({
            customerData: res.data
        })
        this.state.postData.txtCustName =  res.data.customerName
        console.log("customerData: "+JSON.stringify(res.data.customerName))

    }

    highlightText = (e) =>{
        $('#'+e.target.name).select();
    }


    onSubmit = (saveType) => {
        console.log("Inisde On Submit")

        let todayDate = new Date()
console.log("(this.state.postData.txtInputDieSize).replace(/[^0-9]/g, ''): "+(this.state.postData.txtInputDieSize).replace(/[^0-9]/g, ''))
        console.log("typeof(:" +typeof(this.state.postData.txtInputDieSize).replace(/[^0-9]/g, ''))
        let ddlTechNode = PrValidate.ForceSelect(this.state.postData.technologyProcessId, 'technologyProcessId', 'a technology node');
        let txtDesignNumber = PrValidate.ForceRNumber((this.state.postData.txtDesignNumber).replace(/[^0-9]/g, ''), 'txtDesignNumber');
        //let txtInputDieSize = PrValidate.ForceRNumber((this.state.postData.txtInputDieSize).replace(/[^0-9]/g, ''), 'txtInputDieSize', "a die size");
        let txtInputDieSize = PrValidate.ForceEntry((this.state.postData.txtInputDieSize).replace(/[^0-9]/g, ''), 'txtInputDieSize', 'Die Size');
        if(txtInputDieSize === true){
            txtInputDieSize = this.state.txtInputDieSize == 0? PrValidate.ForceEntry('', 'txtInputDieSize', "a die size greater than 0")
                :PrValidate.ForceEntry(this.state.txtInputDieSize, 'txtInputDieSize', "a die size");
        }
        let txtCustName = PrValidate.ForceEntry(this.state.postData.txtCustName, 'txtCustName', 'customer name');
        let txtEmail = PrValidate.ForceEntry(this.state.postData.txtEmail, 'txtEmail', 'valid email address');



        let err_arr = [txtCustName, txtEmail, txtDesignNumber, ddlTechNode, txtInputDieSize];
        let itemToScroll = ["txtCustName", "txtEmail", "txtDesignNumber", "technologyProcessId", "txtInputDieSize"];
        let chk_false = 0;

        let that = this;
        let breakCondition = false;

        err_arr.forEach(function (element, index) {
            if (!element && !breakCondition){
                chk_false++;
                console.log("itemToScrollIndex: " + itemToScroll[index])
                $('html, body').animate({
                    scrollTop: $("#" + itemToScroll[index]).offset().top - 100
                }, 1000);
                breakCondition = true
            }

            if ((err_arr.length - 1) === index) {
                if (chk_false === 0) {

                    let selectedNodeData = that.getSelectedNodeData()
                    var techNode = selectedNodeData.technologyProcess["processName"];
                    console.log("technologyNode: "+techNode)
                    let finalData = {
                        tsmpwRevisionId: '',
                        additionalDiePrice: parseFloat(that.state.postData.txtAdditionalDie.split("$")[1].replace(/,/g, '')),
                        additionalDieQuantity: that.state.postData.additionalDieQuantity?that.state.postData.additionalDieQuantity:0,
                        baseNumberOfDie: that.state.postData.baseNumberOfDie,
                        createdBy: '',
                        createdDate: todayDate,
                        customElementPrice: '',
                        designNumber: that.state.postData.txtDesignNumber,
                        designUploadDrcFees: parseFloat(that.state.postData.txtAdditionalDesignUploadFees.split("$")[1].replace(/,/g, '')),
                        emailAddress: that.state.postData.txtEmail,
                        emailSentTime: todayDate,
                        expediteFee: '',
                        foundryMinimumArea: that.state.postData.txtFoundryMinDesignArea,
                        inputDieSize: that.state.postData.txtInputDieSize,

                        modifiedBy: '',
                        modifiedDate: todayDate,
                        mosisMarkup: Number(that.getMosisPriceFactor())*100,
                        optionsPrice: parseFloat(that.state.postData.txtOptions.split("$")[1].replace(/,/g, '')),
                        quotationDate: todayDate,

                        reticleSpacePrice: parseFloat(that.state.postData.txtRetailPrice.split("$")[1].replace(/,/g, '')),
                        revisionNumber: 0,
                        status: '',
                        technologyNode: techNode,
                        totalPrice: parseFloat(that.state.txtTotalPrice.split("$")[1].replace(/,/g, '')),
                        //mpwTsmcPricingId: that.state.foundryServicePricingId,
                        mpwTsmcPricingId: that.state.mpwTsmcPricingIdMap[that.state.postData.technologyProcessId],

                        quotation:{
                            quotationNumber: that.state.initialQuoteNumber

                        }
                    }

                    let finalDataValue = {}
                    if(saveType == "saveEmail"){
                        console.log("INSIDE EMAIL IF")
                        finalDataValue["sendEmail"] = true
                    }

                    console.log("that.state.tsmpwQuotationServiceList: "+JSON.stringify(that.state.tsmpwQuotationServiceList))
                    that.createServiceObject()
                    console.log("that.state.tsmpwQuotationServiceList: "+JSON.stringify(that.state.tsmpwQuotationServiceList))
                    finalDataValue["tsmpwQuotationServiceList"] = that.state.tsmpwQuotationServiceList

                    finalDataValue["quotationRevision"] = finalData;
                    finalDataValue["additionalWaferQuantity"] = that.state.postData.additionalWaferQuantity;
                    finalDataValue["additionalDesignUploads"] = that.state.postData.additionalDesignUploads;
                    //finalDataValue["quotationTncList"] = this.state.quotationTncList

                    console.log("finalData: "+JSON.stringify(finalDataValue))
                    that.setState({
                        quoteData: finalDataValue,
                        saveQuoteData: true
                    })


                }
            }
        });






    }

    render() {
        return (
            <div>
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_CENTER} />
                {this.state.fetchQuoteNumber === true ? GetQuoteNumber(this.props.PrStore, this.successHandleQuoteNumber) : ''}
                {this.state.fetchNodeList === true ? GetTechNodes(this.props.PrStore, this.successHandlerTech) : ''}
                {this.state.fetchFoundryServicePricing === true ? GetFoundryServicePricing(this.props.PrStore, this.successHandlerGetFoundryServicePricing) : ''}
                {this.state.fetchFoundryDieWaferQuantity === true ? GetFoundryDieWaferQuantity(this.props.PrStore, this.successHandlerGetFoundryDieWaferQuantity) : ''}
                {this.state.fetchTncList === true ? GetTncList(this.props.PrStore, this.successHandlerGetTncList) : ''}
                {this.state.saveQuoteData === true ? saveQuoteData(this.props.PrStore, this.state.quoteData, this.successHandlerSaveQuoteData) : ''}
                {this.state.custData === true ? getCustomerData(this.props.PrStore, this.HandleSuccessGetCustomer, 254) : ''}



               {/* {this.state.redirectToMainPage ?
                    <DashboardCtrl {...this.props} state={this.state}/>:''
                }*/}
                {this.state.fetchFoundryServicePricing == false && this.state.redirectToMainPage == false?
                <QuoteTSMCComponent {...this.props} state={this.state} HandleChange={this.HandleChange}
                verifyAdditionalWaferQuantity={this.verifyAdditionalWaferQuantity} onSubmit={this.onSubmit}
                                    onChangeInputDieSize={this.onChangeInputDieSize} highlightText={this.highlightText}/>:''
                }
            </div>
        )
    }
}

export const QuoteTSMCCtrl = PrConnect(quoteTSMCCtrl, '');