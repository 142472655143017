import React, { Component } from 'react';
import { withAuth } from '@okta/okta-react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


export default withAuth(class DashboardHomeComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { authenticated: null };
        this.checkAuthentication = this.checkAuthentication.bind(this);

    }


    async checkAuthentication() {
        const authenticated = await this.props.auth.isAuthenticated();
        let val ='';
        if (authenticated !== this.state.authenticated) {
            this.setState({ authenticated });
            val = this.props.auth.getAccessToken();
        }
    }

    render() {
        if (this.state.authenticated === null)
            console.log(this.props.auth.getAccessToken())
        return (
            <div id="page-wrapper">
                <div id="page-inner" class="dashboard">

                </div>
            </div>
        )
    }
});