import React, { Component } from 'react';
import $ from "jquery";
import DatePicker from "react-date-picker";
import PrValidate from "../../../library/pr-controls/pr-validation-full";

export default class ExportControlQuestionnareComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showHeading: true,

        }



    }
    ClearMouse = () => {
        $('.inner_table').css('display', 'none');
    }

    componentDidMount() {
        $('.questionCl').css('display', 'none');
        $('.form-group').css('paddingBottom', '2%');

        $('label').css('white-space', 'pre-wrap');
        $('label').css('word-wrap', 'break-word');
        //$('label').text().replace("br","pre");



        setTimeout(() => {
            this.initAllQuestions();
        }, 0);

        let that = this
        Array.from(document.getElementsByClassName("questionDropDownSel")).forEach(
            function(element, index, array) {
                console.log("ELEMENT: "+index)
                console.log(element)
                that.wrapSelectText( element.options, 150);
            }
        );
    }

    getClassName = (questionItem, isChild) => {
        let className = "row"
        if(isChild){
            let questionParentCondition = questionItem.questionParentConditionObj!=null?questionItem.questionParentConditionObj.codeValue:''
            className = "row question_"+questionItem.parentQuestionId+"Cl question_"+questionItem.parentQuestionId+"Cl_"+questionParentCondition+" question_"+questionItem.parentQuestionId+"Child questionCl";
        }
        return className;
    }

    getDropDownAnswerChildClassName = (questionItem, questionItemChild) => {
        console.log("inside getDropDownAnswerChildClassName");

{/*        <c:if test='${earChildQuestionDropDownAnswerChild.parentAnswerId != null}'>
            <tr className="trBg question_${earChildQuestionDropDownAnswerChild.parentAnswerId}Cl question_${earChildQuestionDropDownAnswer.questionId}Cl questionCl"
                id="question_${earChildQuestionDropDownAnswerChild.questionId}-tr">
        </c:if>
        <c:if test='${earChildQuestionDropDownAnswerChild.parentAnswerId == null}'>
            <tr className="trBg question_${earChildQuestionDropDownAnswerChild.parentQuestionId}Cl questionCl" id="question_${earChildQuestionDropDownAnswerChild.questionId}-tr">
        </c:if>*/}

        let className = "row"
        if(questionItemChild.parentAnswerId != null){
            className = "row question_"+questionItemChild.parentAnswerId+"dd_Cl question_"+questionItem.questionId+"dd_Cl questionCl";
        }

        if(questionItemChild.parentAnswerId == null){
            className = "row question_"+questionItemChild.parentAnswerId+"dd_Cl questionCl";
        }
        return className;
    }

    updateStyleDisplay = (cssCl, val) => {
        if(val == "block"){
            val = ''
        }
        Array.from(document.getElementsByClassName(cssCl)).forEach(
            function(element, index, array) {
                element.style.display = val;
            }
        );
    }

    createAddressQuestion = (questionObj, addressType) => {
        let classNameToAdd = ""
        if(this.props.state.savedQuestionAnswersMap[questionObj["questionId"]] != null){
            //console.log("INSIDE IF ================savedQuestionAnswersMap")
            let tempQuestionAnswers = {}
            tempQuestionAnswers["questionId"] = questionObj["questionId"]
            tempQuestionAnswers["answerValue"] = this.props.state.savedQuestionAnswersMap[questionObj["questionId"]]

            if(addressType == "ship"){
                this.props.state.questionsList["shippingAddressAnswer"] = tempQuestionAnswers
            }else if(addressType == "design"){
                this.props.state.questionsList["designerCorporationAnswer"] = tempQuestionAnswers
            }else if(addressType == "paying"){
                this.props.state.questionsList["payingOrganizationAnswer"] = tempQuestionAnswers
            }else if(addressType == "endUser"){
                this.props.state.questionsList["multipleIndeterminateAnswer"] = tempQuestionAnswers
                classNameToAdd = " multipleIndeterminateQuestion"
            }
            //console.log("this.props.state.questionsList: "+this.props.state.questionsList["shippingAddressAnswer"])
            //console.log("this.props.state.questionsList: "+this.props.state.questionsList["designerCorporationAnswer"])
            //console.log("this.props.state.questionsList: "+this.props.state.questionsList["payingOrganizationAnswer"])
            //console.log("this.props.state.questionsList: "+this.props.state.questionsList["multipleIndeterminateAnswer"])
        }
        if(addressType == "endUser"){
            classNameToAdd = " multipleIndeterminateQuestion"
        }

        return(
            <div className="accoutn_form">
                <form action method>
                    <div className="dashboard_form">
                        <div className="inner-dashoard_form">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="brd_section border-none">
                                        <div className="row">
                                            <div className="col-lg-5 col-md-5 col-sm-6 mt-1">
                                                <div className="inner_left">
                                                    {/*<label>Is the shipping address different than the mailing address listed above ?</label>*/}
                                                    <label>{questionObj["question"]}</label>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-6 mt-1">
                                                <div className="inner_right">
                                                    <div className="form-group">
                                                        <div className="custom_select">

                                                            {questionObj["question"] != null?
                                                                <select id={"question_"+questionObj.questionId}
                                                                        class={"questionSel reqValidationSel"+classNameToAdd}
                                                                        value={this.props.state.savedQuestionAnswersMap[questionObj["questionId"]]} onChange={(e) => this.selectAnswer(e, questionObj, false, addressType)}
                                                                        /*onBlur={() => this.props.HandleOnBlur('ForceSelect', "ddlShippingAddr", this.props.state.ddlShippingAddr, 'Input')}*/>
                                                                    <option value="">Select</option>
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>
                                                                </select>:''
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }

    createMailingAddressTable = () => {
        return(
        <div className="account">
            <div className="header">
                <h3>Mailing Address</h3>
            </div>
            <div className="accoutn_form">
                <form action method>
                    <table className="adding_table_row" style={{ width: '100%' }}>
                        <tbody><tr>
                            <th>Address Line 1</th>
                            <th>Address Line 2</th>
                            <th>City</th>
                            <th>State/Province</th>
                            <th>Zip Code</th>
                            <th>Country</th>
                        </tr>
                        <tr>
                            <td className="bg-darkgray" style={{ paddingLeft: '10px' }}>
                                <div className="form-group">
                                    <input type="text" className="form-control" id="txtAddr1" name="txtAddr1" value={this.props.state.mailingAddress.addressLine1} onChange={this.props.HandleChange} disabled={true}/>
                                </div>
                            </td>
                            <td className="bg-darkgray">
                                <div className="form-group">
                                    <input type="text" className="form-control" id="txtAddr2" name="txtAddr2" value={this.props.state.mailingAddress.addressLine2} onChange={this.props.HandleChange} disabled={true}/>
                                </div>
                            </td>
                            <td className="bg-darkgray">
                                <div className="form-group">
                                    <input type="text" className="form-control" id="txtAddrCity" name="txtAddrCity" value={this.props.state.mailingAddress.city} onChange={this.props.HandleChange} disabled={true}/>
                                </div>
                            </td>
                            <td className="bg-darkgray">
                                <div className="form-group">
                                    <input type="text" className="form-control" id="txtAddrState" name="txtAddrState" value={this.props.state.mailingAddress.state} onChange={this.props.HandleChange} disabled={true}/>
                                </div>
                            </td>
                            <td className="bg-darkgray">
                                <div className="form-group">
                                    <input type="text" className="form-control" id="txtAddrZip" name="txtAddrZip" value={this.props.state.mailingAddress.zip} onChange={this.props.HandleChange} disabled={true}/>
                                </div>
                            </td>
                            <td className="bg-darkgray">
                                <div className="form-group">
                                    <select
                                        className="form-control"
                                        id="txtAddrCountry" name="txtAddrCountry"
                                        value={this.props.state.mailingAddress.country}
                                        onChange={this.props.HandleChange}
                                        disabled={true}
                                    >
                                        <option value="">Select</option>
                                        {this.props.state.questionsList["countryList"].length > 0 && this.props.state.questionsList["countryList"].map((sel, selindex) => (
                                                <option value={sel.codeId} >{sel.codeName}</option>
                                            )
                                        )}
                                    </select>
                                    {/*<input type="text" className="form-control" id="txtAddrCountry" name="txtAddrCountry" value={this.props.state.mailingAddress.country} onChange={this.props.HandleChange} />*/}
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </form>
            </div>
        </div>
        );
    }

    CopyFromFirstRow = (fieldName, productionIdTable) => {

        //console.log("this.state.mailingAddress: "+JSON.stringify(this.props.state.mailingAddress))
        //console.log("INSIDE CopyFromFirstRow: ")
        let that = this
        if (fieldName === 'paying') {
            if(productionIdTable === false) {
                (this.props.state.payingCorporationList).forEach((element, index) => {
                    //console.log("inside paying loop: " + JSON.stringify(element))
                    let data = this.props.state.payingCorporationList[index];
                    data["ecDesignFabId"] =  this.props.state.selectedDesignObj.designs[index].ecDesignFabId;
                    data.corporateName = that.props.state.questionsList.exportControlNew.customer["customerName"];
                    data.addressLine1 = that.props.state.mailingAddress.addressLine1;
                    data.addressLine2 = that.props.state.mailingAddress.addressLine2;
                    data.city = that.props.state.mailingAddress.city;
                    data.stateProvince = that.props.state.mailingAddress.state;
                    data.zipCode = that.props.state.mailingAddress.zip;
                    data.country = that.props.state.mailingAddress.country;
                    data.corporateTypeId = that.props.state.corporateTypelistMap["Paying Corporation"].codeId;
                    data.ecDesignsNew.designNumberInfoId = this.props.state.selectedDesignObj.designs[index].designNumberInfoId;
                });
                this.props.state.payingRows= this.props.state.payingCorporationList
                this.setState({
                    payingCorporationList: this.props.state.payingCorporationList
                })
                this.setState({payingCorporationList: this.props.state.payingCorporationList})
            }else{
                (this.props.state.payingCorporationListProdId).forEach((element, index) => {
                    //console.log("inside paying loop: " + JSON.stringify(element))
                    let data = this.props.state.payingCorporationListProdId[index];
                    data["ecProductionId"] =  that.props.state.selectedDesignObj.productionIdList[index].ecProductionId;
                    data.corporateName = that.props.state.questionsList.exportControlNew.customer["customerName"];
                    data.addressLine1 = that.props.state.mailingAddress.addressLine1;
                    data.addressLine2 = that.props.state.mailingAddress.addressLine2;
                    data.city = that.props.state.mailingAddress.city;
                    data.stateProvince = that.props.state.mailingAddress.state;
                    data.zipCode = that.props.state.mailingAddress.zip;
                    data.country = that.props.state.mailingAddress.country;
                    data.corporateTypeId = that.props.state.corporateTypelistMap["Paying Corporation"].codeId;
                    data.ecProductionIdNew.productionIdInfoId = this.props.state.selectedDesignObj.productionIdList[index].productionIdInfoId;
                });
                this.props.state.payingRowsProdId= this.props.state.payingCorporationListProdId
                this.setState({
                    payingCorporationListProdId: this.props.state.payingCorporationListProdId
                })
                this.setState({payingCorporationListProdId: this.props.state.payingCorporationListProdId})
            }


        }else if (fieldName === 'endUser') {
            if(productionIdTable === false) {
                (this.props.state.endUserList).forEach((element, index) => {
                    //console.log("inside endUser loop: " + JSON.stringify(element))
                    let data = this.props.state.endUserList[index];
                    data["ecDesignFabId"] =  this.props.state.selectedDesignObj.designs[index].ecDesignFabId;
                    data.corporateName = that.props.state.questionsList.exportControlNew.customer["customerName"];
                    data.addressLine1 = that.props.state.mailingAddress.addressLine1;
                    data.addressLine2 = that.props.state.mailingAddress.addressLine2;
                    data.city = that.props.state.mailingAddress.city;
                    data.stateProvince = that.props.state.mailingAddress.state;
                    data.zipCode = that.props.state.mailingAddress.zip;
                    data.country = that.props.state.mailingAddress.country;
                    data.corporateTypeId = that.props.state.corporateTypelistMap["End User Corporation"].codeId;
                    data.ecDesignsNew.designNumberInfoId = this.props.state.selectedDesignObj.designs[index].designNumberInfoId;
                });
                this.props.state.endUserRows= this.props.state.endUserList
                this.setState({
                    endUserList: this.props.state.endUserList
                })
            }else{
                (this.props.state.endUserListProdId).forEach((element, index) => {
                    //console.log("inside endUser loop: " + JSON.stringify(element))
                    let data = this.props.state.endUserListProdId[index];
                    data["ecProductionId"] =  that.props.state.selectedDesignObj.productionIdList[index].ecProductionId;
                    data.corporateName = that.props.state.questionsList.exportControlNew.customer["customerName"];
                    data.addressLine1 = that.props.state.mailingAddress.addressLine1;
                    data.addressLine2 = that.props.state.mailingAddress.addressLine2;
                    data.city = that.props.state.mailingAddress.city;
                    data.stateProvince = that.props.state.mailingAddress.state;
                    data.zipCode = that.props.state.mailingAddress.zip;
                    data.country = that.props.state.mailingAddress.country;
                    data.corporateTypeId = that.props.state.corporateTypelistMap["End User Corporation"].codeId;
                    data.ecProductionIdNew.productionIdInfoId = this.props.state.selectedDesignObj.productionIdList[index].productionIdInfoId;
                });
                this.props.state.endUserRowsProdId= this.props.state.endUserListProdId
                this.setState({
                    endUserListProdId: this.props.state.endUserListProdId
                })
            }
        }

        this.setState({})
    }

//this.props.state.selectedDesignObj.productionIdList[index].productionIdInfo.productionId
    createAdressTable = (addressList, addressType, productionIdTable) => {


        //console.log("productionIdTable: "+productionIdTable)
        //console.log("addressList: "+JSON.stringify(addressList))
        let idNamePrefix = '';
        if(addressType == "ship"){
            idNamePrefix = productionIdTable === true?"txtProdIdShip":"txtShip"
        }else if(addressType == "design"){
            idNamePrefix = productionIdTable === true?"txtProdIdDesignComp":"txtDesignComp"
        }else if(addressType == "paying"){
            idNamePrefix = productionIdTable === true?"txtProdIdPayingComp":"txtPayingComp"
        }else if(addressType == "endUser"){
            idNamePrefix = productionIdTable === true?"txtProdIdEndUser":"txtEndUser"
        }
        return(


                <form action method>
                    <div>
                        <table className="adding_table_row" style={{ width: '100%' }}>
                            <tbody>
                            <tr className="row mt-1">
                                {productionIdTable === false?
                                <th className="col">Design Number</th>:<th className="col">Production Id</th>}
                                <th className="col">Corporate Name</th>
                                <th className="col">Address Line 1</th>
                                <th className="col">Address Line 2</th>
                                <th className="col">City </th>
                                <th className="col">State / Province</th>
                                <th className="col"> Zip Code / Postal Code</th>
                                <th className="col">Country</th>
                            </tr>
                            {addressList.map((data, index) => (
                                /*this.props.state.selectedDesignObj.productionIdList[index] != undefined?*/
                                    <tr className="row mt-1" id={"shipAddrr_"+index}>
                                        <td className="bg-darkgray col">
                                            <div className="form-group new_pos">
                                                {productionIdTable === true?
                                                    <input
                                                        type="text"
                                                        className="form-control reqValidation"

                                                        id={idNamePrefix+"DesignNumber_"+index}
                                                        name={idNamePrefix+"DesignNumber_"+index}
                                                        value={this.props.state.selectedDesignObj.productionIdList[index] != undefined?
                                                            this.props.state.selectedDesignObj.productionIdList[index].productionIdInfoId != null?
                                                                this.props.state.selectedDesignObj.productionIdList[index].productionIdInfo.productionId:''
                                                            :''
                                                        }
                                                        /*value={this.props.state.selectedDesignObj.designs[index].hasOwnProperty('designNumber')?this.props.state.selectedDesignObj.designs[index]["designNumber"]:
                                                            this.props.state.selectedDesignObj.designs[index].designNumberInfo.designNumber}*/
                                                        /*placeholder={this.props.state.designRows[index]===undefined?"Design Number":"Other Items"}*/
                                                        onChange={e =>
                                                            this.props.HandleAddrRowsChange(
                                                                e,
                                                                index,
                                                                "designNumber",
                                                                addressType,
                                                                productionIdTable
                                                            )
                                                        }

                                                        disabled={true}
                                                        readonly
                                                    />:
                                                    <input
                                                        type="text"
                                                        className="form-control reqValidation"

                                                        id={idNamePrefix+"DesignNumber_"+index}
                                                        name={idNamePrefix+"DesignNumber_"+index}
                                                        value={this.props.state.selectedDesignObj.designs[index] != undefined?
                                                            this.props.state.selectedDesignObj.designs[index].designNumberInfoId != null?
                                                                this.props.state.selectedDesignObj.designs[index].designNumberInfo.designNumber:''
                                                            :''
                                                        }
                                                        /*value={this.props.state.selectedDesignObj.designs[index].hasOwnProperty('designNumber')?this.props.state.selectedDesignObj.designs[index]["designNumber"]:
                                                            this.props.state.selectedDesignObj.designs[index].designNumberInfo.designNumber}*/
                                                        /*placeholder={this.props.state.designRows[index]===undefined?"Design Number":"Other Items"}*/
                                                        onChange={e =>
                                                            this.props.HandleAddrRowsChange(
                                                                e,
                                                                index,
                                                                "designNumber",
                                                                addressType,
                                                                productionIdTable
                                                            )
                                                        }

                                                        disabled={true}
                                                        readonly
                                                    />
                                                }

                                            </div>
                                        </td>
                                        <td className="col">
                                            <div className="form-group new_pos">
                                                <input
                                                    type="text"
                                                    className="form-control reqValidation"

                                                    id={idNamePrefix+"CorpName_"+index}
                                                    name={idNamePrefix+"CorpName_"+index}
                                                    value={data.corporateName}
                                                    placeholder="Corporate Name"
                                                    onChange={e =>
                                                        this.props.HandleAddrRowsChange(
                                                            e,
                                                            index,
                                                            "corporateName",
                                                            addressType,
                                                            productionIdTable
                                                        )
                                                    }
                                                />
                                            </div>
                                        </td>
                                        <td className="col">
                                            <div className="form-group new_pos">
                                                <input
                                                    type="text"
                                                    className="form-control reqValidation"

                                                    id={idNamePrefix+"Addr1_"+index}
                                                    name={idNamePrefix+"Addr1_"+index}
                                                    value={data.addressLine1}
                                                    placeholder="Address Line 1"
                                                    onChange={e =>
                                                        this.props.HandleAddrRowsChange(
                                                            e,
                                                            index,
                                                            "addressLine1",
                                                            addressType,
                                                            productionIdTable
                                                        )
                                                    }
                                                />
                                            </div>
                                        </td>
                                        <td className="col">
                                            <div className="form-group new_pos">
                                                <input
                                                    type="text"
                                                    className="form-control"

                                                    id={idNamePrefix+"Addr2_"+index}
                                                    name={idNamePrefix+"Addr2_"+index}
                                                    value={data.addressLine2}
                                                    placeholder="Address Line 2"
                                                    onChange={e =>
                                                        this.props.HandleAddrRowsChange(
                                                            e,
                                                            index,
                                                            "addressLine2",
                                                            addressType,
                                                            productionIdTable
                                                        )
                                                    }
                                                />
                                            </div>
                                        </td>
                                        <td className="col">
                                            <div className="form-group new_pos">
                                                <input
                                                    type="text"
                                                    className="form-control reqValidation"

                                                    id={idNamePrefix+"City_"+index}
                                                    name={idNamePrefix+"City_"+index}
                                                    value={data.city}
                                                    placeholder="City"
                                                    onChange={e =>
                                                        this.props.HandleAddrRowsChange(
                                                            e,
                                                            index,
                                                            "city",
                                                            addressType,
                                                            productionIdTable
                                                        )
                                                    }
                                                />
                                            </div>
                                        </td>
                                        <td className="col">
                                            <div className="form-group new_pos">
                                                <input
                                                    type="text"
                                                    className="form-control reqValidation"

                                                    id={idNamePrefix+"State_"+index}
                                                    name={idNamePrefix+"State_"+index}
                                                    value={data.stateProvince}
                                                    placeholder="State"
                                                    onChange={e =>
                                                        this.props.HandleAddrRowsChange(
                                                            e,
                                                            index,
                                                            "stateProvince",
                                                            addressType,
                                                            productionIdTable
                                                        )
                                                    }
                                                />
                                            </div>
                                        </td>
                                        <td className="col">
                                            <div className="form-group new_pos">
                                                <input
                                                    type="text"
                                                    className="form-control reqValidation"

                                                    id={idNamePrefix+"Zip_"+index}
                                                    name={idNamePrefix+"Zip_"+index}
                                                    value={data.zipCode}
                                                    placeholder="Zip"
                                                    onChange={e =>
                                                        this.props.HandleAddrRowsChange(
                                                            e,
                                                            index,
                                                            "zipCode",
                                                            addressType,
                                                            productionIdTable
                                                        )
                                                    }
                                                />
                                            </div>
                                        </td>
                                        <td className="col">
                                            <div className="form-group new_pos">
                                                <div className="custom_select">
                                                    <select
                                                        className="reqValidation"
                                                        id={idNamePrefix+"Country_"+index}
                                                        name={idNamePrefix+"Country_"+index}
                                                        value={data.country}
                                                        onChange={e =>
                                                            this.props.HandleAddrRowsChange(
                                                                e,
                                                                index,
                                                                "country",
                                                                addressType,
                                                                productionIdTable
                                                            )
                                                        }
                                                    >
                                                        <option value="">Select</option>
                                                        {this.props.state.questionsList["countryList"].length > 0 && this.props.state.questionsList["countryList"].map((sel, selindex) => (
                                                                <option key={`selShip-${index}-${selindex}`} value={sel.codeId} >{sel.codeName}</option>
                                                            )
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                )

                            )
                            }

                            {/*addressType == "paying" ||*/}
                            {(addressType == "endUser")?
                                <tr className="row mt-1">


                                    <td className="col text-left mx-auto">
                                        <button className="btn_copy_from_customer"
                                                type="button"   onClick={() => this.CopyFromFirstRow(addressType, productionIdTable)}>Copy address from Customer</button>
                                    </td>
                                    {/*{addressList.length > 1 ?
                                    <td className="col text-right mx-auto">
                                        onClick={() => this.props.CopyFromFirstRow('ship')}
                                        <button className="btn_copy_from_first_row" type="button" >Copy address from first row</button>
                                    </td>
                                    : ''}*/}
                                </tr>:''
                            }

                            </tbody>
                        </table>
                    </div>
                </form>


        )
    }

    fillQuestionAnswerObject = () => {
        //console.log("FILEOBJ: "+JSON.stringify(this.props.state.uploadedFileObj));

      {/*  <c:forEach items="${exportControlNewForm.fileObjList}" var="fileObjItemMy" varStatus="loop">
            console.log("Inside File Obj")
        var fileName = "<c:out value='${fileObjItemMy.fileName}'/>"
        var fileType = "<c:out value='${fileObjItemMy.fileType}'/>"
        var fileLink = "<c:out value='${fileObjItemMy.fileLink}'/>"
        console.log("fileName: "+fileName)
        console.log("fileType: "+fileType)
        console.log("fileLink: "+fileLink)
        //var fileLinkTag = "viewFile('<c:out value='fileLink'/>')"

        if(document.getElementById("question_"+fileType+"_fileName")!=null){
            document.getElementById("question_"+fileType+"_fileName").textContent = fileName;
            document.getElementById("question_"+fileType+"_span").style.display = "block";
            document.getElementById("question_"+fileType+"_fileLink").onclick = function() {viewFile('<c:out value='${fileObjItemMy.fileLink}'/>');};
            document.getElementById("question_"+fileType).style.display = "none";
        }

    </c:forEach>


    var questionAnswersList = [];
    <c:forEach items="${exportControlNewForm.exportControlNew.questionsAnswers}" var="questionAnswers" varStatus="loop">
    console.log(${questionAnswers.questionId})

    var questionId = "<c:out value='${questionAnswers.questionId}'/>"
    var answerId = "<c:out value='${questionAnswers.answerId}'/>"
    var answerValue = "<c:out value='${questionAnswers.answerValue}'/>"

    //questionAnswers = setQuestionAnswers("question_", questionId, answerId, answerValue);
    setQuestionAnswers("question_", questionId, answerId, answerValue);
    //generalQuestionAnswersList.push(questionAnswers)
    </c:forEach>*/}
    //console.log("generalQuestionAnswersList: "+JSON.stringify(generalQuestionAnswersList))



    }

    selectDropDownAnswer = (selObj, questionItem, isChild, inputValue) => {
        //console.log("Inside selectDropDownAnswer")
        //console.log("exportControlViewValue: "+exportControlViewValue)


        if(questionItem != undefined){
            this.props.HandleInputChange(selObj, questionItem, isChild, inputValue)
        }

        console.log(selObj);
        var isDropDown = false;
        if(questionItem != undefined){
            if(questionItem.answerTypeObj != undefined){
                if(questionItem.answerTypeObj.codeName == "Dropdown"){
                    isDropDown = true;
                }
            }
        }


        if(questionItem != undefined){
            console.log("inside if")
            console.log(selObj)
            console.log(questionItem)


            if(selObj.target.value != undefined){
                var eleValue = selObj.target.value;
                var eleId = selObj.target.id;
                var selectedClassName = selObj.target.className;
                var className = eleId + "Cl";
                var childClassName = eleValue + "Cl";
                if(isDropDown == true){
                    className = eleId + "dd_Cl";
                    childClassName = eleValue + "dd_Cl";
                }
                var val = selObj.target.value;
                //console.log("eleValue:" + eleValue);
                //console.log("val:" + val);
                //console.log("className:" + className);
                //console.log("className:" + childClassName);
                //console.log("selectedClassName:" + selectedClassName);

                if(eleValue > 0){
                    //console.log("Inside IF========================")
                    this.updateStyleDisplay(className, "none");
                    this.updateStyleDisplay("question_"+childClassName, "block");
                } else {
                    this.updateStyleDisplay(className, "none");
                    this.updateStyleDisplay("question_"+childClassName, "none");
                }

                //change background on select
                var tr = document.getElementById(eleId + "-tr");
                if(tr && selObj.target.value){
                    tr.style.background = "#EFECE7";
                }
            }
        }else{
            console.log("inside else")
            console.log(selObj)
            if(selObj.value != undefined){
                var eleValue = selObj.value;
                var eleId = selObj.id;
                var selectedClassName = selObj.className;
                var className = eleId + "Cl";
                var childClassName = eleValue + "Cl";

                if(selectedClassName == "questionDropDownSel"){
                    className = eleId + "dd_Cl";
                    childClassName = eleValue + "dd_Cl";
                }
                var val = selObj.value;
                //console.log("eleValue:" + eleValue);
                //console.log("val:" + val);
                //console.log("className:" + className);
                //console.log("className:" + childClassName);
                //console.log("selectedClassName:" + selectedClassName);

                if(eleValue > 0){
                    //console.log("Inside IF========================")
                    this.updateStyleDisplay(className, "none");
                    this.updateStyleDisplay("question_"+childClassName, "block");
                } else {
                    this.updateStyleDisplay(className, "none");
                    this.updateStyleDisplay("question_"+childClassName, "none");
                }

                //change background on select
                var tr = document.getElementById(eleId + "-tr");
                if(tr && selObj.value){
                    tr.style.background = "#EFECE7";
                }
            }
        }
    }


    selectAnswer = (e, questionItem, isChild, addressType) => {
        //console.log("questionItem: "+questionItem.questionId)

        if(questionItem != null){
            this.props.HandleInputOnBlur(e, questionItem, isChild, "input", addressType)
        }



        //console.log("exportControlViewValue: "+exportControlViewValue)
        if(questionItem != null){
            var eleId = e.target.id;
            var selectedClassName = e.target.className;
            var className = eleId + "Cl";
            var val = e.target.value;
        }else{
            var eleId = e.id;
            var selectedClassName = e.className;
            var className = eleId + "Cl";
            var val = e.value;
        }

        //console.log("eleId:" + eleId);
        //console.log("val:" + val);
        //console.log("className:" + className);
        //console.log("selectedClassName:" + selectedClassName);

        if(selectedClassName.includes("multipleIndeterminateQuestion") && val == "No"){
            this.updateStyleDisplay(className+"_No", "block");
        }else{
            this.updateStyleDisplay(className+"_No", "none");
        }

        if(!selectedClassName.includes("multipleIndeterminateQuestion") && (val == "Yes" || val == "No" || val == "")){
            var classToShow = "";
            var classToHide = "";
            if(val == "Yes"){
                classToShow = className+"_Yes";
                classToHide = className+"_No";
            }else{
                classToShow = className+"_No";
                classToHide = className+"_Yes";
            }
            this.updateStyleDisplay(classToShow, "block");

            var childElement = document.getElementsByClassName(classToHide);
            //console.log("childElement[0]: "+childElement[0])
            var childElementId;

            let that = this;
            Array.from(document.getElementsByClassName(classToHide)).forEach(
                function(childElement, index, array) {
                    console.log("Inside function ======")
                    if(childElement != null && childElement != undefined){
                        childElementId = childElement!=null?childElement.id:null;
                    }

                    that.updateStyleDisplay(classToHide, "none");

                    console.log("childElement: "+childElement);



                    //console.log("childElementId: "+childElementId)
                    while(childElementId != null || childElementId != undefined){



                   /*     if(document.getElementById(childElementId.split("-")[0]) != null) {
                            document.getElementById(childElementId.split("-")[0]).value = ""

                            updateStyleDisplay(childElementId.split("-")[0] + "Cl_Yes", "none");
                            updateStyleDisplay(childElementId.split("-")[0] + "Cl_No", "none");
                            console.log("childELement id: " + childElementId);
                            childElement = document.getElementsByClassName(childElementId.split("-")[0] + "Cl");
                            console.log("childElement: "+childElement)
                            if(childElement[0] != null){
                                childElementId = childElement[0].id;
                            }else{
                                childElementId = null
                            }

                        }else{
                            childElementId = null
                        }*/


                        console.log("childElementId: "+childElementId)

                        var x = document.getElementById(childElementId).querySelector(".fileType");
                        var x_but = document.getElementById(childElementId).querySelector("#del_button");

                        console.log("x: "+x);
                        if(x != null){
                            console.log("x.id: "+x.id);
                            //deleteFile(x, x.id);
                            //that.deleteFileJS(x, x.id);
                            //document.getElementById(x.id).value = ""
                        }

                        if(document.getElementById(childElementId.split("-")[0]) != null){
                            console.log("split id: "+childElementId.split("-")[0]);
                            let splitId = childElementId.split("-")[0];
                            console.log("splitId: "+splitId);
                            console.log("value: "+document.getElementById(splitId).value);
                            //document.getElementById(splitId).value = ""

                            console.log("Element Type: "+document.getElementById(splitId).value+" : "+document.getElementById(splitId).className);

                            if(document.getElementById(splitId).className == "dateParentDiv"){
                                that.props.state.savedQuestionAnswersMap[splitId.split("_")[1]] = null;
                            }else{
                                that.props.state.savedQuestionAnswersMap[splitId.split("_")[1]] = ""
                            }

                            that.updateStyleDisplay(childElementId.split("-")[0]+"Cl_Yes", "none");
                            that.updateStyleDisplay(childElementId.split("-")[0]+"Cl_No", "none");

                            if(val == "No"){
                                if(document.getElementsByClassName(childElementId.split("-")[0]+ "dd_Cl") != undefined){
                                    that.updateStyleDisplay(childElementId.split("-")[0]+ "dd_Cl", "none");
                                }
                            }

                            //console.log("childELement id: "+childElementId);
                            childElement = document.getElementsByClassName(childElementId.split("-")[0]+"Cl");
                            if(childElement[0] != undefined){
                                childElementId = childElement[0].id;
                            }else{
                                childElementId = null;
                            }
                        }else{
                            childElementId = null
                        }


                    }

                }
            );





            //console.log("childELement id: "+childElementId);
        }
        //change background on select
        var tr = document.getElementById(eleId + "-tr");

        if(questionItem != null){
            if(tr && e.target.value){
                tr.style.background = "#EFECE7";
            }
        }else{
            if(tr && e.value){
                tr.style.background = "#EFECE7";
            }
        }

    }


    doEvent = ( obj, event ) =>{
        /* Created by David@Refoua.me */
        var event = new Event( event, {target: obj, bubbles: true} );
        return obj ? obj.dispatchEvent(event) : false;
    }


    getValueProperty = (questionItem, isChild) => {
        return this.props.state.questionAnsersMap[questionItem.questionId];
    }

    checkQuestionToShow = (questionItem) => {
        let showQuestion = true;
        if(questionItem.questionForObj != null){
            if(questionItem.questionForObj.codeValue != "Common"){
                showQuestion = false;
                if(this.props.state.questionsTypesToShow.length == 0){
                    showQuestion = true;
                }
                this.props.state.questionsTypesToShow.map(function (item) {
                    if(item.includes(questionItem.questionForObj.codeValue)){
                        showQuestion = true;
                    }
                })
            }
        }

        //console.log("questionItem.questionId: "+questionItem.questionId)
        //console.log("showQuestion: "+showQuestion)
        return showQuestion;
    }

    createFillIn = (questionItem, isChild, questionItemChild) => {
        let className;
        if(questionItemChild != null){
            className = this.getDropDownAnswerChildClassName(questionItemChild, questionItem);
        }else{
            className = this.getClassName(questionItem, isChild);
        }



        let value = this.getValueProperty(questionItem, isChild);

        let showQuestion = this.checkQuestionToShow(questionItem);

        let reqValidation
        if(questionItem.answerDataTypeObj.codeValue != null){
            reqValidation = (questionItem.answerDataTypeObj.codeValue == 'Integer_AllowBlank' || questionItem.answerDataTypeObj.codeValue == 'String_AllowBlank')?'':'reqValidation'
        }


        if(showQuestion){
            return(
                /*id="question_${earChildQuestion.questionId}-tr">:*/

                <div>
                    <div className={className} id={"question_"+questionItem.questionId+"-tr"}>
                        <div className="col-lg-8 col-md-8 col-sm-6 mt-1">
                            <div className="inner_left">
                                <label>{questionItem.question}</label>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 mt-1">
                            <div className="inner_right">
                                <div className="form-group new_pos">
                                    {/*<input type="text" className="form-control" name="txtECCNForDesignTechnology" id="txtECCNForDesignTechnology" value={this.props.state.txtECCNForDesignTechnology} onChange={this.props.HandleChange} />*/}
                                    <input type="text" className={"form-control "+reqValidation} name="txtECCNForDesignTechnology" id={"question_"+questionItem.questionId}  value={this.props.state.savedQuestionAnswersMap[questionItem.questionId]}
                                           onChange={(e) => this.props.HandleInputChange(e,questionItem,isChild, "input")} onBlur={(e) => this.props.HandleInputOnBlur(e,questionItem,isChild, "input", "FillIn")}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {questionItem.childQuestions.length > 0 && isChild!=true?
                        this.createChildQuestions(questionItem.childQuestions):''}
                </div>

            )
        }



    }

    wrapSelectText = (selectOptions, maxLen) => {

        for(var i=0; i<selectOptions.length; i++){
            var optionText = selectOptions[i].innerText;
            if(optionText){
                selectOptions[i].title = optionText;
                selectOptions[i].innerText = optionText.length > maxLen ? (optionText.substring(0, maxLen) + "...") : optionText;
            }
        }
    }

    createDropDown = (questionItem, isChild, questionItemChild) => {
        let className;
        if(questionItemChild != null){
            className = this.getDropDownAnswerChildClassName(questionItemChild, questionItem);
        }else{
            className = this.getClassName(questionItem, isChild);
        }

        let value = this.getValueProperty(questionItem, isChild);
        {/*<div className="row align-items-center">*/}

        let showQuestion = this.checkQuestionToShow(questionItem);

        if(showQuestion) {
            return (

                <div>

                    <div className={className} id={"question_" + questionItem.questionId + "-tr"}>
                        <div className="col-lg-8 col-md-8 col-sm-6 mt-1">
                            <div className="inner_left">
                                <label>{questionItem.question}</label>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 mt-1">
                            <div className="inner_right">
                                <div className="form-group">
                                    <div className="custom_select">
                                        {/*<select id="ddlITARCategory" name="ddlITARCategory" value={this.props.state.ddlITARCategory} onChange={this.props.HandleITARChange}>*/}
                                        <select id={"question_" + questionItem.questionId} class="questionDropDownSel" name="ddlITARCategory" value={this.props.state.savedQuestionAnswersMap[questionItem.questionId]}
                                                onChange={(e) => this.selectDropDownAnswer(e, questionItem, isChild, "value")}
                                                /*onChange={(e) => this.props.HandleInputChange(e, questionItem, isChild, "value")}*/
                                                onBlur={(e) => this.props.HandleInputOnBlur(e, questionItem, isChild, "select", null)}>
                                            <option value="">Select</option>
                                            {questionItem.answers != undefined ? questionItem.answers.map(elem =>
                                                    <option value={elem.answerId}>{elem.answerValue}</option>
                                                ) : ''}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {questionItem.childQuestions.length > 0 && isChild!=true?
                        this.createChildQuestions(questionItem.childQuestions) : ''}
                </div>
            )
        }



    }

    strip = (html) => {
        let doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    }

    createYesNo = (questionItem, isChild, questionItemChild) => {

        //var questionString = $("<div>").text(questionItem.question).html();
        let className;
        if(questionItemChild != null){
            className = this.getDropDownAnswerChildClassName(questionItemChild, questionItem);
        }else{
            className = this.getClassName(questionItem, isChild);
        }

        let value = this.getValueProperty(questionItem, isChild);

        let showQuestion = this.checkQuestionToShow(questionItem);


        if(showQuestion) {
            return (
                <div>
                    <div className={className} id={"question_" + questionItem.questionId + "-tr"}>
                        <div className="col-lg-8 col-md-8 col-sm-6 mt-1">
                            <div className="inner_left">
                                <label id={"labelTextAsHtml_"+questionItem.questionId}>{questionItem.question.split('<br>').map((item, i) => <p key={i} style={{fontSize: "12px", marginBottom: "0px",paddingTop: "0"}}>{item}</p>)}</label>{isChild}
                                {/*<label id={"labelTextAsHtml_"+questionItem.questionId}>{this.strip(questionItem.question)}</label>{isChild}*/}
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 mt-1">
                            <div className="inner_right">
                                <div className="form-group">
                                    <div className="custom_select">

                                        <select id={"question_" + questionItem.questionId} name="ddlEndItemsIntendedExclusivelyCanadian" class="questionSel"
                                                value={this.props.state.savedQuestionAnswersMap[questionItem.questionId]}
                                                onChange={(e) => this.selectAnswer(e, questionItem, isChild)}>
                                            <option value="">Select</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    {questionItem.childQuestions.length > 0 && isChild!=true?
                        this.createChildQuestions(questionItem.childQuestions) : ''}
                </div>
            )
        }

        //this.props.state.htmlTextArr.push(questionItem.questionId);

    }

    createNote = (questionItem, isChild, questionItemChild) => {
        let className;
        if(questionItemChild != null){
            className = this.getDropDownAnswerChildClassName(questionItemChild, questionItem);
        }else{
            className = this.getClassName(questionItem, isChild);
        }

        let value = this.getValueProperty(questionItem, isChild);

        let showQuestion = this.checkQuestionToShow(questionItem);

        if(showQuestion) {
            return (
                <div>
                    <div className={className} id={"question_" + questionItem.questionId + "-tr"}>
                        <div className="col-lg-8 col-md-8 col-sm-6 mt-1">
                            <div className="inner_left">
                                <label>Note: {questionItem.question}</label>{isChild}
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 mt-1">
                            <div className="inner_right">
                                <div className="form-group">
                                    {/*<div className="custom_select">
                                        <select id={"question_" + questionItem.questionId} name="ddlEndItemsIntendedExclusivelyCanadian" class="questionSel"
                                                value={this.props.state.savedQuestionAnswersMap[questionItem.questionId]}
                                                onChange={(e) => this.selectAnswer(e, questionItem, isChild)}>
                                            <option value="">Select</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </div>*/}
                                </div>
                            </div>
                        </div>

                    </div>
{/*                    {questionItem.childQuestions.length > 0 && isChild!=true?
                        this.createChildQuestions(questionItem.childQuestions) : ''}*/}
                </div>


            )
        }
    }

    createTextArea = (questionItem, isChild, questionItemChild) => {
        let className;
        if(questionItemChild != null){
            className = this.getDropDownAnswerChildClassName(questionItemChild, questionItem);
        }else{
            className = this.getClassName(questionItem, isChild);
        }

        let value = this.getValueProperty(questionItem, isChild);

        let showQuestion = this.checkQuestionToShow(questionItem);

        let reqValidation
        console.log("questionItem.answerDataTypeObj: "+JSON.stringify(questionItem.answerDataTypeObj));
        if(questionItem.answerDataTypeObj.codeValue != null){
            reqValidation = (questionItem.answerDataTypeObj.codeValue.includes('_AllowBlank'))?'':'reqValidation'
        }

        if(showQuestion) {
            return (

                <div>
                    <div className={className} id={"question_" + questionItem.questionId + "-tr"}>
                        <div className="col-lg-5 col-md-5 col-sm-6 mt-1">
                            <div className="inner_left">
                                <label>{questionItem.question}</label>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-7 col-sm-6 mt-1">
                            <div className="inner_right">
                                <div className="form-group" style={{paddingBottom: "2%"}}>
                                    {/*<textarea type="text" className="form-control" name="txtManufacturedIntendedProduct" id="txtManufacturedIntendedProduct" value={this.props.state.txtManufacturedIntendedProduct} onChange={this.props.HandleChange} />*/}
                                    <textarea maxLength="1995" type="text" className={"form-control "+reqValidation} name="txtManufacturedIntendedProduct" id={"question_" + questionItem.questionId}
                                              value={this.props.state.savedQuestionAnswersMap[questionItem.questionId]}
                                              onChange={(e) => this.props.HandleInputChange(e, questionItem, isChild, "input")}
                                              onBlur={(e) => this.props.HandleInputOnBlur(e, questionItem, isChild, "input", "TextArea")}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {questionItem.childQuestions.length > 0 && isChild!=true?
                        this.createChildQuestions(questionItem.childQuestions) : ''}
                </div>

            )
        }

    }

    createDate = (questionItem, isChild, questionItemChild) => {
        let className;
        if(questionItemChild != null){
            className = this.getDropDownAnswerChildClassName(questionItemChild, questionItem);
        }else{
            className = this.getClassName(questionItem, isChild);
        }

        let value = this.getValueProperty(questionItem, isChild);

        let showQuestion = this.checkQuestionToShow(questionItem);

        let reqValidation
        console.log("questionItem.answerDataTypeObj: "+JSON.stringify(questionItem.answerDataTypeObj));
        if(questionItem.answerDataTypeObj != null){
            if(questionItem.answerDataTypeObj.codeValue != null){
                reqValidation = (questionItem.answerDataTypeObj.codeValue.includes('_AllowBlank'))?'':'reqValidation'
            }
        }else{
            reqValidation = 'reqValidation';
        }


        if(showQuestion) {
            return (
                <div>
                    <div className={className} id={"question_" + questionItem.questionId + "-tr"}>
                        <div className="col-8 col-md-8 col-sm-6 mt-1">
                            <div className="inner_left">
                                <label>US Government Review Date</label>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 mt-1">
                            <div className="form-group new_pos">
                                <div className="dateParentDiv" id={"question_"+questionItem.questionId} name={"question_"+questionItem.questionId}>

                                    <DatePicker format="MM/dd/y" calendarIcon={<img src={require('../../../assets/images/calendar.svg')} alt="calendar" width={14}/>}
                                                id={"question_"+questionItem.questionId} name={"question_"+questionItem.questionId}
                                                className={reqValidation}
                                                //value={this.props.state.savedQuestionAnswersMap[questionItem.questionId]}
                                                value={this.props.state.savedQuestionAnswersMap[questionItem.questionId]!=null?
                                                    new Date(this.props.state.savedQuestionAnswersMap[questionItem.questionId]):null}
                                                onChange={(e) => this.props.HandleInputChange(e, questionItem, isChild, "date", reqValidation)}
                                                data-date-format="MM/dd/yyyy"
                                                /*onChange={this.props.HandleDateChange}*/
                                    />


                                  {/*  <html:hidden name="exportControlNewForm" property="questionAnswersItem[${size}].questionId"
                                                 value="${generalQuestion.questionId}"/>
                                    <myportal:date mode="<%=access%>" styleClass="${(generalQuestion.answerDataTypeObj.codeValue == 'Integer_AllowBlank' || generalQuestion.answerDataTypeObj.codeValue == 'String_AllowBlank')?'':'reqValidation'}"
                                                   size="10" maxlength="10" ctrlId="question_${generalQuestion.questionId}" name="exportControlNewForm" styleId="question_${generalQuestion.questionId}"
                                                   property="questionAnswersItem[${size}].answerValue" dateformat="MM/dd/yyyy" readonly="true"/>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

    }

    createFile = (questionItem, isChild, questionItemChild) => {
        let className;
        if(questionItemChild != null){
            className = this.getDropDownAnswerChildClassName(questionItemChild, questionItem);
        }else{
            className = this.getClassName(questionItem, isChild);
        }

        let value = this.getValueProperty(questionItem, isChild);

        let showQuestion = this.checkQuestionToShow(questionItem);

        //console.log("questionItem.questionId : "+questionItem.questionId )

        if(questionItem.additionalMappingObj != undefined){
            if(showQuestion) {
                return (
                    <div>
                        <div className={className} id={"question_" + questionItem.questionId + "-tr"}>
                            <div className="col-8 col-md-8 col-sm-6 mt-1" id={"question_" + questionItem.questionId}>
                                <div className="inner_left">
                                    <label>{questionItem.question}</label>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6 mt-1">
                                <div className="inner_right">
                                    <div className="form-group" style={{paddingBottom: "2%"}}>
                                        <input type="file" id={"question_"+questionItem.additionalMappingObj.codeValue}
                                               class="fileType"
                                               name={this.props.state.fileObjMap[questionItem.questionId]}
                                               onChange={(e) => this.props.HandleFileChange(e, questionItem)} style={{fontSize: '12px'}}/>

                                        <span id={"question_"+questionItem.additionalMappingObj.codeValue+"_span"} style={{display: 'none'}}>
                                            <a href="#" id={"question_"+questionItem.additionalMappingObj.codeValue+"_fileLink"} style={{paddingRight:"2%"}}>
                                                <span id={"question_"+questionItem.additionalMappingObj.codeValue+"_documentId"} style={{display: "none"}}></span>
                                                <span id={"question_"+questionItem.additionalMappingObj.codeValue+"_fileName"}></span>
                                            </a>

                                            <input type="button" id="del_button" value="Delete" onClick={(e) => this.deleteFile(e, 'question_'+questionItem.additionalMappingObj.codeValue)}/>
                                        </span>
                                        {/*<textarea type="text" className="form-control" name="txtManufacturedIntendedProduct" id="txtManufacturedIntendedProduct" value={this.props.state.txtManufacturedIntendedProduct} onChange={this.props.HandleChange} />*/}
                                        {/*<textarea type="text" className="form-control" name="txtManufacturedIntendedProduct" id={"question_"+questionItem.questionId}  value={this.props.state.savedQuestionAnswersMap[questionItem.questionId]}
                                          onChange={(e) => this.props.HandleInputChange(e, questionItem, isChild, "input")} onBlur={(e) => this.props.HandleInputOnBlur(e,questionItem,isChild, "input")}/>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {questionItem.childQuestions.length > 0 && isChild!=true?
                            this.createChildQuestions(questionItem.childQuestions) : ''}
                    </div>
                )
            }
        }


    }

    deleteFile = (deleteBtn, eleId) => {
        //console.log("eleID: "+eleId)
        console.log("eleID inside deleteFile: "+eleId)
        var documentId = document.getElementById(eleId+"_documentId").innerHTML;
        console.log("documentId: "+documentId)
        deleteBtn.target.style.display = "none";
        //document.getElementById("span-"+eleId).style.display = "none";
        document.getElementById(eleId).style.display = "block";
        document.getElementById(eleId+"_span").style.display = "none";

        this.props.state.deletedFileItemsDocumentNumbers.push(documentId);
    }

    deleteFileJS = (deleteBtn, eleId) => {
        console.log("eleID inside deleteFileJS: "+eleId)
        var documentId = document.getElementById(eleId+"_documentId").innerHTML;
        console.log("documentId: "+documentId)
        deleteBtn.style.display = "none";
        //document.getElementById("span-"+eleId).style.display = "none";
        document.getElementById(eleId).style.display = "block";
        document.getElementById(eleId+"_span").style.display = "none";

        this.props.state.deletedFileItemsDocumentNumbers.push(documentId);
    }

    createChildQuestions(questionItem){
        //console.log("INSIDE createChildQuestions")
        return(
            questionItem.length > 0?
                questionItem.map((childQuestionItem, childQuestionIndex) =>
                    JSON.stringify(childQuestionItem.answerTypeObj) !== '{}'?
                        childQuestionItem.answerTypeObj != null?
                            childQuestionItem.answerTypeObj["codeValue"] == "Yes/No"?
                                this.createYesNo(childQuestionItem, true):
                                childQuestionItem.answerTypeObj["codeValue"] == "Fill In"?
                                    this.createFillIn(childQuestionItem, true):
                                    childQuestionItem.answerTypeObj["codeValue"] == "File"?
                                        this.createFile(childQuestionItem, true):
                                        childQuestionItem.answerTypeObj["codeValue"] == "Dropdown"?
                                            this.createDropDown(childQuestionItem, true):
                                            childQuestionItem.answerTypeObj["codeValue"] == "Textarea"?
                                                this.createTextArea(childQuestionItem, true):
                                                childQuestionItem.answerTypeObj["codeValue"] == "date"?
                                                    this.createDate(childQuestionItem, true)
                                                    :<span></span>
                            :<span></span>
                        :<span></span>
                )
                :<span></span>


        )
    }

createHeading = (headingName) => {
    return (
            <div className="header">
                <h3>{headingName}</h3>
            </div>
        )
}



    initAllQuestions = () => {
        //console.log("Inside initAllQuestions");
        let that = this
        Array.from(document.getElementsByClassName("questionSel")).forEach(
            function(element, index, array) {
                //console.log(element.id)
                that.selectAnswer(element);

            }
        );

        this.fillQuestionAnswerObject()
        Array.from(document.getElementsByClassName("questionDropDownSel")).forEach(
            function(element, index, array) {
                that.selectDropDownAnswer(element);
            }
        );
    }

    checkDropDownAnswersChildQuestion = (docChildItem, isChild) => {

        if(isChild){return (
            this.props.state.questionsList.questions.map((questionItem, questionIndex) =>
                questionItem.questionSubTypeObj.nodeId == docChildItem.nodeId?
                    questionItem.childQuestions.length > 0?
                        questionItem.childQuestions.map((childQuestionItem, childQuestionIndex) =>
                            JSON.stringify(childQuestionItem.answerTypeObj) !== '{}'?
                                childQuestionItem.answerTypeObj != null?
                                    childQuestionItem.answerTypeObj["codeValue"] == "Dropdown"?
                                        childQuestionItem.answers.length > 0?
                                            childQuestionItem.answers.map((questionItemDropDownAnswer, questionIndex) =>
                                                questionItemDropDownAnswer.childQuestions.length > 0?
                                                    questionItemDropDownAnswer.childQuestions.map((questionItemDropDownAnswerChild, questionIndex) =>
                                                        questionItemDropDownAnswerChild.questionSubTypeObj.nodeId == docChildItem.nodeId?
                                                            questionItemDropDownAnswerChild.answerTypeObj != undefined?
                                                                JSON.stringify(questionItemDropDownAnswerChild.answerTypeObj) !== '{}'?
                                                                    questionItemDropDownAnswerChild.answerTypeObj != null?
                                                                        questionItemDropDownAnswerChild.answerTypeObj["codeValue"] == "Yes/No"?
                                                                            this.createYesNo(questionItemDropDownAnswerChild, false, questionItemDropDownAnswer):
                                                                            questionItemDropDownAnswerChild.answerTypeObj["codeValue"] == "Fill In"?
                                                                                this.createFillIn(questionItemDropDownAnswerChild, false, questionItemDropDownAnswer):
                                                                                questionItemDropDownAnswerChild.answerTypeObj["codeValue"] == "File"?
                                                                                    this.createFile(questionItemDropDownAnswerChild, false, questionItemDropDownAnswer):
                                                                                    questionItemDropDownAnswerChild.answerTypeObj["codeValue"] == "Dropdown"?
                                                                                        this.createDropDown(questionItemDropDownAnswerChild, false, questionItemDropDownAnswer):
                                                                                        questionItemDropDownAnswerChild.answerTypeObj["codeValue"] == "Textarea"?
                                                                                            this.createTextArea(questionItemDropDownAnswerChild, false, questionItemDropDownAnswer):
                                                                                            questionItemDropDownAnswerChild.answerTypeObj["codeValue"] == "date"?
                                                                                                this.createDate(questionItemDropDownAnswerChild, false, questionItemDropDownAnswer)
                                                                                                :<span></span>
                                                                        :<span></span>
                                                                    :<span></span>
                                                                :this.createNote(questionItemDropDownAnswerChild, false, questionItemDropDownAnswer)
                                                            :<span></span>
                                                    )
                                                    :<span></span>
                                            )
                                            :<span></span>

                                        :<span></span>
                                    :<span></span>
                                :<span></span>
                        )
                        :<span></span>
                    :<span></span>)
        )
        }else{

            return (
                this.props.state.questionsList.questions.map((questionItem, questionIndex) =>
                    questionItem.questionSubTypeObj.nodeId == docChildItem.nodeId?
                                JSON.stringify(questionItem.answerTypeObj) !== '{}'?
                                    questionItem.answerTypeObj != null?
                                        questionItem.answerTypeObj["codeValue"] == "Dropdown"?
                                            questionItem.answers.length > 0?
                                                questionItem.answers.map((questionItemDropDownAnswer, questionIndex) =>
                                                    questionItemDropDownAnswer.childQuestions.length > 0?
                                                        questionItemDropDownAnswer.childQuestions.map((questionItemDropDownAnswerChild, questionIndex) =>
                                                            questionItemDropDownAnswerChild.questionSubTypeObj.nodeId == docChildItem.nodeId?
                                                                questionItemDropDownAnswerChild.answerTypeObj != undefined?
                                                                    JSON.stringify(questionItemDropDownAnswerChild.answerTypeObj) !== '{}'?
                                                                        questionItemDropDownAnswerChild.answerTypeObj != null?
                                                                            questionItemDropDownAnswerChild.answerTypeObj["codeValue"] == "Yes/No"?
                                                                                this.createYesNo(questionItemDropDownAnswer, false, questionItemDropDownAnswerChild):
                                                                                questionItemDropDownAnswerChild.answerTypeObj["codeValue"] == "Fill In"?
                                                                                    this.createFillIn(questionItemDropDownAnswer, false, questionItemDropDownAnswerChild):
                                                                                    questionItemDropDownAnswerChild.answerTypeObj["codeValue"] == "File"?
                                                                                        this.createFile(questionItemDropDownAnswer, false, questionItemDropDownAnswerChild):
                                                                                        questionItemDropDownAnswerChild.answerTypeObj["codeValue"] == "Dropdown"?
                                                                                            this.createDropDown(questionItemDropDownAnswer, false, questionItemDropDownAnswerChild):
                                                                                            questionItemDropDownAnswerChild.answerTypeObj["codeValue"] == "Textarea"?
                                                                                                this.createTextArea(questionItemDropDownAnswer, false, questionItemDropDownAnswerChild):
                                                                                                questionItemDropDownAnswerChild.answerTypeObj["codeValue"] == "date"?
                                                                                                    this.createDate(questionItemDropDownAnswer, false, questionItemDropDownAnswerChild)
                                                                                                    :<span></span>
                                                                            :<span></span>
                                                                        :<span></span>
                                                                    :this.createNote(questionItemDropDownAnswer, false, questionItemDropDownAnswerChild)
                                                                :<span></span>
                                                        )
                                                        :<span></span>
                                                )
                                                :<span></span>

                                            :<span></span>
                                        :<span></span>
                                    :<span></span>
                        :<span></span>)
            )

        }


            /*questionItem.answerTypeObj != undefined?
                JSON.stringify(questionItem.answerTypeObj) !== '{}'?
                    questionItem.answerTypeObj != null?
                        questionItem.answerTypeObj["codeValue"] == "Dropdown"?
                            <span>YES DROPDOWN</span>:''*/
                                /*questionItem.answers.length > 0?
                                    questionItem.childQuestions.length > 0?
                                        questionItem.childQuestions.map((questionItemChild, questionIndex) =>
                                            questionItemChild.questionSubTypeObj.nodeId == docChildItem.nodeId?
                                                questionItemChild.answerTypeObj != undefined?
                                                    JSON.stringify(questionItemChild.answerTypeObj) !== '{}'?
                                                        questionItemChild.answerTypeObj != null?
                                                            questionItemChild.answerTypeObj["codeValue"] == "Yes/No"?
                                                                this.createYesNo(questionItemChild):
                                                                questionItemChild.answerTypeObj["codeValue"] == "Fill In"?
                                                                    this.createFillIn(questionItemChild):
                                                                    questionItemChild.answerTypeObj["codeValue"] == "File"?
                                                                        this.createFile(questionItemChild):
                                                                        questionItemChild.answerTypeObj["codeValue"] == "Dropdown"?
                                                                            this.createDropDown(questionItemChild):
                                                                            questionItemChild.answerTypeObj["codeValue"] == "Textarea"?
                                                                                this.createTextArea(questionItemChild):
                                                                                questionItemChild.answerTypeObj["codeValue"] == "date"?
                                                                                    this.createDate(questionItemChild)
                                                                                    :<span></span>
                                                            :<span></span>
                                                        :<span></span>
                                                    :this.createNote(questionItemChild)
                                                :<span></span>
                                        )
                                        :<span></span>
                                    :<span></span>
                                :<span></span>*/
                            /*:<span></span>
                        :<span></span>
                    :this.createNote(questionItem)
                :<span></span>*/

    }




    render() {

        return (

            <div>
            <div id="page-wrapper">
                <div id="page-inner" style={{ background: "white" }}>
                    <div className="page_head" style={{width: "80%", marginLeft: "auto", marginRight: "auto"}}>
                        <h1 className="page-header"> Export Control Questionnaire</h1>


                        {/*<div className="account">
                            {this.createHeading("Maling Address")}
                            {this.createAddressQuestion(this.props.state.questionsList["shippingAddressQuestion"])}

                            <div className={"accoutn_form question_"+this.props.state.questionsList.shippingAddressQuestion.questionId+"Cl_Yes questionCl"} id="show_hide_div">
                            {this.createAdressTable(this.props.state.questionsList["shipToCorporationList"])}
                            </div>

                        </div>*/}


                        {this.createMailingAddressTable()}

                        <div className="account">
                            <div className="accoutn_form">
                                <form action method>
                            {this.createHeading("Shipping Address")}
                            {this.createAddressQuestion(this.props.state.questionsList["shippingAddressQuestion"], "ship")}

                            <div className={"accoutn_form question_"+this.props.state.questionsList.shippingAddressQuestion.questionId+"Cl_Yes questionCl"} id="show_hide_div">
                                {/*{this.props.state.selectedDesignObj.designs.length>0?
                                    this.createAdressTable(this.props.state.questionsList["shipToCorporationList"], "ship", false):''}
                                {this.props.state.selectedDesignObj.productionIdList.length>0?
                                    this.createAdressTable(this.props.state.questionsList["shipToCorporationList"], "ship", true):''}*/}
                                {this.props.state.selectedDesignObj.designs.length>0?
                                    this.createAdressTable(this.props.state.shipToCorporationList, "ship", false):''}
                                {this.props.state.selectedDesignObj.productionIdList.length>0?
                                    this.createAdressTable(this.props.state.shipToCorporationListProdId, "ship", true):''}
                            </div>
                                </form>
                            </div>
                        </div>

                        <div className="account">
                            <div className="accoutn_form">
                                <form action method>
                            {this.createHeading("Design Company Identification")}
                            {this.createAddressQuestion(this.props.state.questionsList["designerCorporationQuestion"],"design")}

                            <div className={"accoutn_form question_"+this.props.state.questionsList.designerCorporationQuestion.questionId+"Cl_Yes questionCl"} id="show_hide_div">
                                {/*{this.props.state.selectedDesignObj.designs.length>0?
                                    this.createAdressTable(this.props.state.questionsList["designerCorporationList"], "design", false):''}
                                {this.props.state.selectedDesignObj.productionIdList.length>0?
                                    this.createAdressTable(this.props.state.questionsList["designerCorporationList"], "design", true):''}*/}
                                {this.props.state.selectedDesignObj.designs.length>0?
                                    this.createAdressTable(this.props.state.designerCorporationList, "design", false):''}
                                {this.props.state.selectedDesignObj.productionIdList.length>0?
                                    this.createAdressTable(this.props.state.designerCorporationListProdId, "design", true):''}
                            </div>
                                </form>
                            </div>
                        </div>

                        <div className="account">
                            <div className="accoutn_form">
                                <form action method>
                            {this.createHeading("Paying Corporation")}
                            {this.createAddressQuestion(this.props.state.questionsList["payingOrganizationQuestion"],"paying")}

                            <div className={"accoutn_form question_"+this.props.state.questionsList.payingOrganizationQuestion.questionId+"Cl_Yes questionCl"} id="show_hide_div">
                                {/*{this.props.state.selectedDesignObj.designs.length>0?
                                    this.createAdressTable(this.props.state.questionsList["payingCorporationList"],"paying", false):''}
                                {this.props.state.selectedDesignObj.productionIdList.length>0?
                                    this.createAdressTable(this.props.state.questionsList["payingCorporationList"],"paying", true):''}*/}
                                {this.props.state.selectedDesignObj.designs.length>0?
                                    this.createAdressTable(this.props.state.payingCorporationList,"paying", false):''}
                                {this.props.state.selectedDesignObj.productionIdList.length>0?
                                    this.createAdressTable(this.props.state.payingCorporationListProdId,"paying", true):''}
                            </div>
                                </form>
                            </div>
                        </div>

                        <div className="account">
                            <div className="accoutn_form">
                                <form action method>
                            {this.createHeading("End User")}
                            {this.createAddressQuestion(this.props.state.questionsList["multipleIndeterminateQuestion"],"endUser")}

                            <div className={"accoutn_form question_"+this.props.state.questionsList.multipleIndeterminateQuestion.questionId+"Cl_No questionCl"} id="show_hide_div">
                                {/*{this.props.state.selectedDesignObj.designs.length>0?
                                    this.createAdressTable(this.props.state.questionsList["endUserList"],"endUser", false):''}
                                {this.props.state.selectedDesignObj.productionIdList.length>0?
                                    this.createAdressTable(this.props.state.questionsList["endUserList"],"endUser", true):''}*/}
                                {this.props.state.selectedDesignObj.designs.length>0?
                                    this.createAdressTable(this.props.state.endUserList,"endUser", false):''}
                                {this.props.state.selectedDesignObj.productionIdList.length>0?
                                    this.createAdressTable(this.props.state.endUserListProdId,"endUser", true):''}
                            </div>
                                </form>
                            </div>
                        </div>


                                {/*<tr>
                                    <td colSpan="2" width="100%"></td>
                                </tr>
                                <c:set var="hideHeading" value="false"/>
                                <c:if test='${not empty documentTree._docTemplateDetailss}'>
                                    <c:forEach items="${documentTree._docTemplateDetailss}" var="documentTreeTemplate" varStatus="templateLoop">
                                        <c:if test='${documentTreeTemplate.fieldLabel == "hideHeadings"}'>
                                            <c:set var="hideHeading" value="true"/>
                                        </c:if>
                                    </c:forEach>
                                </c:if>

                                <c:if test='${hideHeading == "false"}'>
                                    <tr>
                                        <td colSpan="2" width="100%" className="thin">
                                            <c:out value="${documentTree.name}"/>
                                        </td>
                                    </tr>
                                </c:if>*/}

                                {JSON.stringify(this.props.state.questionsList) !== '{}'?
                                    this.props.state.questionsList["documentTreeExportControlChildrenList"].length > 0?
                                        this.props.state.questionsList["documentTreeExportControlChildrenList"].map((docChildItem, docChildIndex) =>
                                            (docChildItem.name != "Intel Questions" && docChildItem.name != "Samsung Questions" && docChildItem.name != "Address Questions")?

                                                    <div className="account">
                                                        {docChildItem.templateDetails.length > 0 && docChildItem.templateDetails[0].fieldLabel == "hideHeadings"?
                                                            '':this.createHeading(docChildItem.name)
                                                        }
                                                            <div className="accoutn_form">
                                                                <form action method>
                                                                    <div className="dashboard_form">
                                                                        <div className="inner-dashoard_form">
                                                                            <div className="row">
                                                                                <div className="col-lg-12">
                                                                                    <div className="brd_section">
                                                                            {this.props.state.questionsList.questions.map((questionItem, questionIndex) =>
                                                                                questionItem.questionSubTypeObj.nodeId == docChildItem.nodeId?
                                                                                    questionItem.answerTypeObj != undefined?
                                                                                    JSON.stringify(questionItem.answerTypeObj) !== '{}'?
                                                                                        questionItem.answerTypeObj != null?
                                                                                            questionItem.answerTypeObj["codeValue"] == "Yes/No"?
                                                                                                this.createYesNo(questionItem):
                                                                                                    questionItem.answerTypeObj["codeValue"] == "Fill In"?
                                                                                                        this.createFillIn(questionItem):
                                                                                                            questionItem.answerTypeObj["codeValue"] == "File"?
                                                                                                                this.createFile(questionItem):
                                                                                                                questionItem.answerTypeObj["codeValue"] == "Dropdown"?
                                                                                                                    this.createDropDown(questionItem):
                                                                                                                    questionItem.answerTypeObj["codeValue"] == "Textarea"?
                                                                                                                        this.createTextArea(questionItem):
                                                                                                                        questionItem.answerTypeObj["codeValue"] == "date"?
                                                                                                                            this.createDate(questionItem)
                                                                                                                            :<span></span>
                                                                                            :<span></span>
                                                                                        :<span></span>
                                                                                    :this.createNote(questionItem)
                                                                                    :<span></span>

                                                                            )}
                                                                                        {this.checkDropDownAnswersChildQuestion(docChildItem, false)}
                                                                            {this.checkDropDownAnswersChildQuestion(docChildItem, true)}




                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                :'')
                                        :''
                                    :''
                                }



                        <div className="account">
                            <div className="header">
                                <h3>Authorized Official</h3>
                            </div>
                            <div className="accoutn_form">
                                <form action method>
                                    <div className="dashboard_form">
                                        <div className="inner-dashoard_form">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="brd_section">
                                                        <div className="row">
                                                            <div className="col-lg-5 col-md-5 col-sm-6">
                                                                <div className="inner_left">
                                                                    <label>First Name / Last Name</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-7 col-md-7 col-sm-6">
                                                                <div className="inner_right new_pos">
                                                                    <div className="form-group">
                                                                        <input type="text" className="form-control reqValidation" name="txtAuthName" id="txtAuthName" value={this.props.state.txtAuthName} onChange={this.props.HandleChange}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="brd_section">
                                                        <div className="row">
                                                            <div className="col-lg-5 col-md-5 col-sm-6">
                                                                <div className="inner_left">
                                                                    <label>Title</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-7 col-md-7 col-sm-6">
                                                                <div className="inner_right new_pos">
                                                                    <div className="form-group">
                                                                        <input type="text" className="form-control reqValidation" name="txtAuthTitle" id="txtAuthTitle" value={this.props.state.txtAuthTitle} onChange={this.props.HandleChange}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="brd_section">
                                                        <div className="row">
                                                            <div className="col-lg-5 col-md-5 col-sm-6">
                                                                <div className="inner_left">
                                                                    <label>Email</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-7 col-md-7 col-sm-6">
                                                                <div className="inner_right new_pos">
                                                                    <div className="form-group">
                                                                        <input type="email" className="form-control reqValidation" name="txtAuthEmail" id="txtAuthEmail" value={this.props.state.txtAuthEmail} onChange={this.props.HandleChange}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="brd_section border-none">
                                                        <div className="row">
                                                            <div className="col-lg-5 col-md-5 col-sm-6">
                                                                <div className="inner_left">
                                                                    <label>Telephone Number (ex: 123-456-7890)</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-7 col-md-7 col-sm-6">
                                                                <div className="inner_right new_pos">
                                                                    <div className="form-group">
                                                                        <input className="form-control reqValidation" name="txtAuthTelNumber" id="txtAuthTelNumber" value={this.props.state.txtAuthTelNumber} onChange={this.props.HandleChange}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>




                    </div>
                </div>
                <div className="text-center">
                    <button type="button" className="dash_submit_btn" onClick={this.props.SubmitExport}>Submit</button>
                </div>
            </div>
            </div>
        )
    }


}