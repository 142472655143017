import React, { Component } from 'react';
// import { prstore } from '../../../library/stores/pr-store';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import { PrConnect } from '../../../library/pr-miniredux';
import DownloadCenterComponent from './DownloadCenter';
import { GetDocumentCategoryListByCustomerId, GetAllDocumentListDocumentIdOrNodetId, GetAllDocumentCategoryList} from '../../../services/downloadcenter';
import $ from 'jquery';
import tncFile from '../../../assets/docs/MOSIS Terms and Conditions 01 17 19  Rev 0.pdf'


export class DownloadCenter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            apiUrl: props.PrStore.GetData('api').baseurl,
            customersList: [],
            selected_customer_id: 0,
            fetchDocumentCategory: true,
            documentCategory: [],
            selectedDocument: {},
            fetchDocumentList: false,
            documentLists: [],
            sorting: { column: '', order: 'asc' },
            fetchCustomerDocumentList:false,
            groupsToTemplateMap:{},
            showDocumentTable: false,
            selectedNodeId: '',
            documentDetailsMap: [],
            sortingArray:{},
            teamplateHeadersList:{},
            errorValue:''
        }
    }

    componentDidMount() {
        this.state.fetchCustomerDocumentList = true
    }

    makeDynamicArray = (array) => {
        console.log("array: "+JSON.stringify(array))
        var result = [];
        if (array.length > 0) {
            for (let index = 0; index < array.length; index++) {
                const element = array[index].dmDocumentDetails;
                var result1 = [];
                if (element.length > 0) {
                    for (let index1 = 0; index1 < element.length; index1++) {
                        const element1 = element[index1];
                        console.log("templateDetailId: "+element[index1].templateDetailId)
                        console.log("documentDetailId: "+element[index1].documentDetailId)
                        //result1.push({ fieldLabel: element1.dmDocTemplateDetail.fieldLabel, fieldValue: element1.fieldValue });
                        result1.push({ fieldValue: element1.fieldValue, templateDetailId: element1.templateDetailId, documentDetailId: element1.documentDetailId });
                    }
                }

                result.push({
                    documentId: array[index].documentId, data: result1.sort(function (a, b) {

                        console.log("a: "+JSON.stringify(a))
                        console.log("b: "+JSON.stringify(b))
                        var valueA, valueB;

                        valueA = a.fieldValue; // Where 1 is your index, from your example
                        valueB = b.fieldValue;
                        console.log("valueA: "+valueA)
                        console.log("valueB: "+valueB)
                        if (valueA < valueB) {
                            return -1;
                        }
                        else if (valueA > valueB) {
                            return 1;
                        } else {
                            return 0;
                        }
                    })
                });
            }
        }
        return result;
    }

    handleDocumentCategoryResponse = (res) => {

        res.data = res.data.sort(this.GetSortOrder("heading"));
        if (res.data !== null && Array.isArray(res.data)) {
            this.setState({ documentCategory: res.data, fetchDocumentCategory: false });
        } else if (typeof res.data === 'object' && res.data !== null) {
            this.setState({ documentCategory: [res.data], fetchDocumentCategory: false });
        }



        console.log("documentCategory: "+JSON.stringify(this.state.documentCategory))
        this.mapGroupsToTemplate()


    }

    GetSortOrder = (prop) => {
        return function(a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        }
    }

    mapGroupsToTemplate = () => {
        let that = this
        console.log("this.state.documentCategory: "+JSON.stringify(this.state.documentCategory))
        this.state.documentCategory.map(function(item) {
            item.downloadCenterListDtos.map(function(item2) {
                if(item2 != null){
                    that.state.groupsToTemplateMap[item2.templateDetailList[0]["nodeId"]] = item2["templateDetailList"]
                }
            })
            /*if(item.downloadCenterListDtos[0] != null){
                that.state.groupsToTemplateMap[item.downloadCenterListDtos[0].templateDetailList[0]["nodeId"]] = item.downloadCenterListDtos[0]["templateDetailList"]
            }*/
        })
        console.log("that.state.groupsToTemplateMap: "+JSON.stringify(that.state.groupsToTemplateMap))
    }

    handleGetAllDocumentCategoryList = (res) => {
        console.log("res.data: "+JSON.stringify(res.data))
        if (res.data !== null && Array.isArray(res.data)) {
            this.setState({ documentCategory: res.data, fetchCustomerDocumentList: false });
        } else if (typeof res.data === 'object' && res.data !== null) {
            this.setState({ documentCategory: [res.data], fetchCustomerDocumentList: false });
        }
    }

    handleDocumentResponse = (res) => {

        this.state.documentDetailsMap = []
        console.log("res.data: "+JSON.stringify(res.data))

        console.log(Array.isArray(res.data))

        var dt = this.makeDynamicArray(res.data);
        console.log("DT: "+JSON.stringify(dt))

        let that = this
        let documentDetail = {}
        if(Array.isArray(res.data)){
            res.data.map(function(documentItem) {
                documentDetail = {}
                documentDetail["documentId"] = documentItem["documentId"]
                documentItem["dmDocumentDetails"].map(function(item) {
                    documentDetail[item["templateDetailId"]] = item
                })
                that.state.documentDetailsMap.push(documentDetail)
            })
        }else{
            documentDetail["documentId"] = res.data["documentId"]
            res.data.dmDocumentDetails.map(function(item) {
                documentDetail[item["templateDetailId"]] = item

            })
            that.state.documentDetailsMap.push(documentDetail)
        }

        console.log("this.state.documentDetailsMap: "+JSON.stringify(this.state.documentDetailsMap))
        this.state.documentLists = this.makeSortedDocumentsListHashMap(dt, this.state.documentDetailsMap)

        dt = []
        console.log("this.state.teamplateHeadersList: "+JSON.stringify(this.state.teamplateHeadersList))
        this.state.documentDetailsMap.map(function(item) {
            let tempArr = []
            that.state.teamplateHeadersList.map(function(item2) {
                tempArr.push(item[item2["templateDetailId"]])
            })
            dt.push({"data":tempArr})
        })
        console.log("dt: "+JSON.stringify(dt))

        this.setState({
            documentLists: this.state.documentLists,
            sortingArray: dt,
            showDocumentTable: true,
            fetchDocumentList: false
        })

        console.log("documentLists========: "+JSON.stringify(this.state.documentLists))

    }

    HandleChange = (e) => {
        console.log("e.target.name: "+e.target.name)
        console.log("e.target.getAttribute('data-item'): "+e.target.getAttribute('data-item'))
        if (e.target.name === "customer") {
            console.log("Inside Customer")
            var id = e.target.value;
            this.setState({ documentCategory: [], selected_customer_id: 0, documentLists: [] });
            if (id) {
                this.setState({ selected_customer_id: id, fetchDocumentCategory: true });
            }
        } else if (e.target.getAttribute('data-item') === "document_category") {
            if(e.target.value == "tnc"){
                console.log("Inside TNC")
                const link = document.createElement('a');
                link.href = tncFile;
                link.download = 'MOSIS Terms and Conditions 01 17 19  Rev 0.pdf'
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                //this.downloadFile('../../../assets/docs/MOSIS Terms and Conditions 01 17 19  Rev 0.pdf','MOSIS Terms and Conditions 01 17 19  Rev 0.pdf');
            }else{
                if (e.target.value != '{}') {
                    this.state.documentLists = []
                    console.log("e.target.value: " + e.target.value)
                    let documentCategoryObj = JSON.parse(e.target.value)
                    //console.log("documentCategoryObj: "+JSON.stringify(documentCategoryObj))
                    //console.log("Inside document_category: "+documentCategoryObj.templateDetailList[0]["nodeId"])


                    this.state.selectedNodeId = documentCategoryObj.templateDetailList[0]["nodeId"]
                    console.log("this.state.groupsToTemplateMap[e.target.value[\"nodeId\"]]: " + JSON.stringify(this.state.groupsToTemplateMap[documentCategoryObj.templateDetailList[0]["nodeId"]]))


                    this.setState({
                        teamplateHeadersList: this.state.groupsToTemplateMap[documentCategoryObj.templateDetailList[0]["nodeId"]]
                    })
                    var row = e.target.closest(".row");
                    row.querySelectorAll("select:not([name='" + e.target.name + "'])").forEach((sel) => {
                        sel.value = "{}";
                });
                    this.setState({selectedDocument: {}});
                    var data = JSON.parse(e.target.value);
                    console.log("data: " + JSON.stringify(data))
                    if (Object.keys(data).length > 0) {
                        console.log("inside IF")
                        this.setState({selectedDocument: data, fetchDocumentList: true});
                    }
                }
            }
        }

    }

    handleSort = (event) => {
        // event.persist();
        const { sorting } = this.state;
        var column = event.target.getAttribute('column');

        console.log("column: "+column)
        if (sorting.column === column) {
            console.log("inside if")
            sorting.order = (sorting.order === 'asc') ? 'desc' : 'asc';
        } else {
            console.log("inside else")
            sorting.order = 'desc';
        }

        sorting.column = column;
        this.setState({ sorting: sorting });
        // this.state.table_data.sort(compareValues(sorting.column, sorting.order));
        this.state.sortingArray.sort(function (column, order) {
            return function (a, b) {
                console.log("index: "+column)
                console.log("value: "+JSON.stringify(a))
                console.log("value: "+JSON.stringify(b))
                var valueA, valueB;

                valueA = a.data[column]['fieldValue']; // Where 1 is your index, from your example
                valueB = b.data[column]['fieldValue'];
                console.log("valueA: "+valueA)
                console.log("valueB: "+valueB)
                let comparison = 0;
                if (valueA < valueB) {
                    comparison = -1;
                }
                else if (valueA > valueB) {
                    comparison = 1;
                }
                return (
                    (order === 'desc') ? (comparison * -1) : comparison
                );
            };
        }(sorting.column, sorting.order));
        console.log("sortingArray: "+JSON.stringify(this.state.sortingArray))


        this.state.documentLists = this.makeSortedDocumentsListHashMap(this.state.sortingArray, this.state.documentLists)

        console.log("that.state.documentLists: "+JSON.stringify(this.state.documentLists))
    }

    makeSortedDocumentsListHashMap = (sortedArray, documentsList) => {
        let that = this
        sortedArray.map(function(item1, index) {
            item1.data.map(function(item2) {
                console.log("Item2: "+JSON.stringify(item2))
                documentsList[index][item2.templateDetailId] = item2
            })
        })
        return documentsList
    }

    viewButtonClicked = (e) => {
        e.preventDefault();
        console.log("Inside viewButtonClicked")
        console.log("Value: "+e.target.value)
        console.log("documentCategory: "+JSON.stringify(this.state.documentCategory))
        let that = this
        this.state.documentCategory.map(function(item) {
            if(item.heading == e.target.value){
                console.log("Found: "+item.heading)
                console.log("item: "+JSON.stringify(item.downloadCenterListDtos[0]))

                that.state.documentLists = []

                if(item.downloadCenterListDtos[0] != null) {
                    console.log("inside if")
                    let documentCategoryObj = JSON.parse(JSON.stringify(item.downloadCenterListDtos[0]))
                    console.log("documentCategoryObj: " + JSON.stringify(documentCategoryObj))
                    console.log("Inside document_category: " + documentCategoryObj.templateDetailList[0]["nodeId"])


                    that.state.selectedNodeId = documentCategoryObj.templateDetailList[0]["nodeId"]
                    console.log("that.state.groupsToTemplateMap[e.target.value[\"nodeId\"]]: " + JSON.stringify(that.state.groupsToTemplateMap[documentCategoryObj.templateDetailList[0]["nodeId"]]))


                    that.setState({
                        teamplateHeadersList: that.state.groupsToTemplateMap[documentCategoryObj.templateDetailList[0]["nodeId"]]
                    })
                    var row = e.target.closest(".row");
                    row.querySelectorAll("select:not([name='" + e.target.name + "'])").forEach((sel) => {
                        sel.value = "{}";
                    });
                }

                that.setState({ selectedDocument: {} });
                if(item.downloadCenterListDtos[0] != null) {
                    console.log("inside if")
                    var data = JSON.parse(JSON.stringify(item.downloadCenterListDtos[0]));
                }else{
                    var data = {}
                }

                console.log("data: "+JSON.stringify(data))
                if (Object.keys(data).length > 0) {
                    console.log("inside IF")
                    that.setState({ selectedDocument: data, fetchDocumentList: true });
                }

            }
        })
        console.log("selectedDocument: "+JSON.stringify(this.state.selectedDocument))
        console.log("documentLists========: "+JSON.stringify(this.state.documentLists))
        console.log("viewButtonClicked: "+e.target.value);
        this.state.errorValue = e.target.value
    }

    downloadFile = (url, fileName) => {
        console.log("downloadFile: "+url)
        console.log("fileName: "+fileName)
        let anchor = document.createElement("a");
        document.body.appendChild(anchor);
        let file = url

        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + JSON.parse(localStorage.getItem('okta-token-storage')).accessToken.accessToken);

        fetch(file, { headers })
            .then(response => response.blob())
            .then(blobby => {
                let objectUrl = window.URL.createObjectURL(blobby);

                anchor.href = objectUrl;
                anchor.download = fileName;
                anchor.click();

                window.URL.revokeObjectURL(objectUrl);
            });
    }

    render() {
        return (
            <div>
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_CENTER} />
                {this.state.fetchDocumentCategory === true ? GetDocumentCategoryListByCustomerId(this.props.PrStore, this.state.selected_customer_id, this.handleDocumentCategoryResponse) : ''}
                {this.state.fetchDocumentList === true ? GetAllDocumentListDocumentIdOrNodetId(this.props.PrStore, this.state.selected_customer_id, this.state.selectedDocument, this.handleDocumentResponse) : ''}
                {/*{this.state.fetchCustomerDocumentList === true ? GetAllDocumentCategoryList(this.props.PrStore, this.handleGetAllDocumentCategoryList) : ''}*/}

                <DownloadCenterComponent {...this.props} state={this.state} handleChange={this.HandleChange} handleSort={this.handleSort} downloadFile={this.downloadFile}
                                         viewButtonClicked={this.viewButtonClicked}/>
            </div>
        )
    }
}

export const DownloadCenterCtrl = PrConnect(DownloadCenter, '');