import React, { Component } from 'react';

import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
//import { GetTechProcessFoundry } from '../../../services/foundrypdk';

import { getDesignUploadQuestionGroup, getQuestionsByTechClass, getFoundryAndTechClass, getFoundryAndTechClassByCustomerId } from '../../../../services/designUploadQuestion';
import { PrConnect } from '../../../../library/pr-miniredux';
import {ServicesDesignFileUploadComponentCtrl} from '../../../ServicesDesignFileUpload/ServicesDesignFileUpload'
import UploadPopup from './uploadPopup'
import AWS from "aws-sdk";
import $ from "jquery";
import axios from "axios";



export class UploadPopupCtrl extends Component {
    constructor(props) {
        super(props);
        console.log("props");
        console.log(props);

        this.state = {
            showSuccessMessage: "none",
            showUploadSection: "block",
            showWaitForFileUpload: "block",
            filesToUpload: this.props.componentprops.filesToUpload,
            currentCount:'',
            totalCount:'',
            currentFileName:''
        };
        //this.HandleFileUploadCompletion = this.HandleFileUploadCompletion.bind(this);
    }


    UNSAFE_componentWillMount = () => {
        console.log("this.props.componentprops.filesToUpload: "+this.props.componentprops.filesToUpload)
        console.log("this.state.fileToUpload: "+this.state.filesToUpload)
        let totalCount = this.state.filesToUpload.length
        this.setState({
            currentCount: 1,
            totalCount: totalCount
        })

        this.UploadFiles()


    };

    /*handleRadioBoxChange = (e) => {
        console.log("inside radio change: "+e.target.value);
  
        if(e.target.value == "CONFIRM"){
            console.log("INSIDE CONFIRM")
            this.props.componentprops.HandleConfirmationSelection(true);
        }else{
            console.log("INSIDE CANCEL")
            this.props.componentprops.HandleConfirmationSelection(false);
        }
        


    }*/


    UploadFiles = () => {

        this.state.showUploadPopup = true;

        this.FileUploadToS3(this.state.filesToUpload[0], 0);
        //this.FileUploadToS3New(this.state.filesToUpload[0], 0);
    };


    getSignedURL = () => {
        return new Promise((resolve, reject) => {
            axios
                .get("http://localhost:8081/get-signed-url")
                .then(data => {
                    console.log(data);
                    console.log("data: "+JSON.stringify(data));
                    resolve(data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    };



/*    FileUploadToS3New = (item, uploadedFilesCount) => {


       /!* var uppy = new Uppy()
            .use(AwsS3, {host: 'localhost:5000',
                /!*companionUrl: 'https://companion.uppy.io'*!/}).run();

        uppy.on('complete', (result) => {
            console.log('Upload complete! We’ve uploaded these files:', result.successful)
        })*!/

        console.log("type: "+item["type"])
        const config = {
            onUploadProgress: function(progressEvent) {
                var percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                console.log(percentCompleted);
            },
            //mode: 'no-cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                //'Access-Control-Allow-Credentials':true,
                'Access-Control-Allow-Headers':'Origin, X-Requested-With, Content-Type, Accept, Authorization',
                'ACL': 'private',
                'Key': item["modifiedFileName"],
            },
            'Access-Control-Allow-Origin': '*',
            'withCredentials':'true'
            //withCredentials: true,
            //credentials: 'same-origin',
        };

        let fd = new FormData();
        fd.append("file", item["file"]);

        /!*axios.put('http://localhost:80/test', fd, config)
            .then(res => console.log("Upload Completed", res))
            .catch(err => console.log("Upload Interrupted", err));*!/
        let that = this

        var amzDate = this.getAmzDate(new Date().toISOString());
        //axios.put('https://devaws.mosis.com/test/'+amzDate, fd, config)
        //axios.put('http://localhost:80/test/?date='+amzDate, fd, config)
        //axios.put('http://localhost:80/test/'+data["data"].urls[0], fd, config)
        axios.put('http://localhost:80/test', fd, config)
        //axios.put('https://devaws.mosis.com/test/'+item["modifiedFileName"], fd, config)
            .then(res => console.log("Upload Completed", res))
            .catch(err => console.log("Upload Interrupted", err));

        /!*this.getSignedURL().then(data => {
            console.log("data[\"data\"].urls[0]: "+data["data"].urls[0])
            console.log("AFTER FUNC CALL")
            console.log(data);
            console.log("data: "+JSON.stringify(data["data"].urls[0]));

            var amzDate = that.getAmzDate(new Date().toISOString());
            console.log("amzDate: "+amzDate)

// this function converts the generic JS ISO8601 date format to the specific format the AWS API wants

            //axios.put('https://devaws.mosis.com/test/'+amzDate, fd, config)
            //axios.put('http://localhost:80/test/?date='+amzDate, fd, config)
            axios.put('http://localhost:80/test/'+data["data"].urls[0], fd, config)
            //axios.put('http://localhost:80/test/myFile.docx', fd, config)
                .then(res => console.log("Upload Completed", res))
                .catch(err => console.log("Upload Interrupted", err));
        });*!/
    }*/

    getAmzDate = (dateStr) => {
        var chars = [":","-"];
        for (var i=0;i<chars.length;i++) {
            while (dateStr.indexOf(chars[i]) != -1) {
                dateStr = dateStr.replace(chars[i],"");
            }
        }
        dateStr = dateStr.split(".")[0] + "Z";
        return dateStr;
    }

/*    FileUploadToS3New = (item, uploadedFilesCount) => {
        const file = {
            // `uri` can also be a file system path (i.e. file://)
            uri: '../../../../assets/docs/PressReleaseDocs/116140J.pdf',
            name: item["modifiedFileName"],
            type: item["type"]
        }
        require('dotenv').config();
        const options = {
            keyPrefix: item["modifiedFileName"],
            bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
            region: process.env.REACT_APP_AWS_REGION,
            accessKey: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
            secretKey: process.env.REACT_APP_AWS_SECRET_KEY,
            successActionStatus: 201,
            acl: 'private',
        }

        RNS3.put(file, options)
            .progress(e => {
                console.log("INSIDE PROGRESS");
                console.log(e.loaded / e.total)
            });
            /!*.then(response => {
            if (response.status !== 201){
                console.log("INSIDE FAILED");
                console.log(response.body);
                throw new Error("Failed to upload image to S3");
            }else{
                console.log("INSIDE SUCCESS");
                console.log(response.body);
            }


            /!**
             * {
             *   postResponse: {
             *     bucket: "your-bucket",
             *     etag : "9f620878e06d28774406017480a59fd4",
             *     key: "uploads/image.png",
             *     location: "https://your-bucket.s3.amazonaws.com/uploads%2Fimage.png"
             *   }
             * }
             *!/
        });*!/
    }*/



    FileUploadToS3 = (item, uploadedFilesCount) => {

        require('dotenv').config();

        AWS.config.update({
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY
        })

        //AWS.config.httpOptions.timeout= 120000
        //AWS.config.httpOptions.configxhrWithCredentials = true;

        const myBucket = new AWS.S3({
            params: { Bucket: process.env.REACT_APP_AWS_BUCKET_NAME},
            region: process.env.REACT_APP_AWS_REGION,
        })

        let that = this
        //let id = e.target.id
        let fileName = item["fileName"];
        this.setState({
            currentFileName: fileName,
            currentCount: uploadedFilesCount+1
        });

        console.log("fileName: "+fileName)
        console.log("that.state.currentCount: "+that.state.currentCount)
        that.state.currentFileName = fileName;
        that.state.showWaitForFileUpload = "block";

        const params = {
            //ACL: 'public-read',
            ACL: 'private',
            Key: item["modifiedFileName"],
            ContentType: item["type"],
            ContentLength: item["size"],
            Body: item["file"],
            //Body: arrayBuffer,
            //ContentDisposition: contentDeposition
        }

        //window["s3upload"](e.target.files);

        //var options = {partSize: 100 * 1024 * 1024, queueSize: 1};
        AWS.config.httpOptions.timeout = 0;
        //AWS.config.httpOptions.async = true;

        $("#progress").css("display", "block");



        myBucket.putObject(params)
            //.on('httpUploadProgress', ({ loaded, total }) => {
            .on('httpUploadProgress', ({ loaded, total }) => {
                //console.log(evt);
                console.log('Progress:', loaded, '/', total, `${Math.round(100 * loaded / total)}%`);
                let uploaded = Math.round(100 * loaded / total)

                var progressBar     = document.querySelector(".progress-bar");
                progressBar.style.width = uploaded + "%";
                progressBar.textContent = uploaded + "%";
            }).on("success", (evt) => {
                uploadedFilesCount = uploadedFilesCount+1
                console.log("success")
                $("#nextPageBtn").prop("disabled",false);
                ToastsStore.success("File Successfully Uploaded.");
                //document.getElementById("progress-bar-label_"+id).style.display = "none";//TO_DO
                console.log(evt)

                var progressBar     = document.querySelector(".progress-bar");
                progressBar.style.width = 0 + "%";
                progressBar.textContent = 0

                if(Number(that.state.filesToUpload.length) == uploadedFilesCount){
                    console.log("=======================Inside if (success) =====================")
                    that.state.showWaitForFileUpload = "none";
                    that.props.componentprops.HandleFileUploadCompletion(true);
                }else{
                    console.log("=======================Inside else (success) =====================")
                    that.state.currentCount = Number(that.state.currentCount)+1;
                    that.FileUploadToS3(this.state.filesToUpload[uploadedFilesCount], uploadedFilesCount)
                }


                //this.onFileUploadSuccess(questionItem, item, fileName)
            }).send((err) => {
                if (err) {
                    console.log("error: "+err)
                    // handle the error here
                }
            })
    }


    render() {

        return (


            <div>
                    <UploadPopup {...this.props} state={this.state}
                                 HandleFileUploadCompletion={this.HandleFileUploadCompletion}
                           />
                
            </div>

        )
    }
}
export default UploadPopupCtrl = PrConnect(UploadPopupCtrl, '');