import React, { Component } from 'react';
import $ from 'jquery';
export default class TableDesignStatus extends Component {
    ClearMouse = () => {
        $('.inner_table').css('display', 'none');
    }
    render() {
        return (

            <div id="page-wrapper1">
                <div id="page-inner1" style={{ background: '#fff' }}>
                    {/* <div className="page_head col-lg-12" style={{ marginLeft: 17 + '%' }}> */}
                    <div className="page_head col-lg-20">
                        {/*{JSON.stringify(this.props.state.foundryAndTechClass) !== '{}' && this.props.state.foundryAndTechClass.length > 0 ?*/}
                            <div class="row">
                                <div class="col-lg-25 col-md-25 mx-auto">
                                    <span style={{display: "table", margin: "0 auto", fontSize: "15px"}}>Production Id Status: <span style={{fontWeight:"bold", color:"#990100"}}>{this.props.state.codeStatus}</span></span>
                                    <span style={{display: "table", margin: "0 auto", fontSize: "15px"}}>Foundry Run Id: <span style={{fontWeight:"bold", color:"#990100"}}>{this.props.state.runId!=''?this.props.state.runId:"TBD"}</span></span>
                                    <table className="table table-sm table_sm_default">
                                        <thead>
                                            <tr style={{ textAlign: "center" }}>
                                                <th style={{cursor: 'pointer'}} scope="col" onClick={() => this.props.SortData('designNumber')}>Milestone (Due Date)
                                                    {this.props.state.sort_by === 'designNumber' ?
                                                        <span className="fa fa-caret-down" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                                        :
                                                        this.props.state.sort_by_1 === 'designNumber' ?
                                                            <span className="fa fa-caret-up" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                                            : ''}
                                                </th>
                                                <th style={{cursor: 'pointer'}} scope="col" onClick={() => this.props.SortData('revision')}>MOSIS Plan
                                                    {this.props.state.sort_by === 'revision' ?
                                                        <span className="fa fa-caret-down" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                                        :
                                                        this.props.state.sort_by_1 === 'revision' ?
                                                            <span className="fa fa-caret-up" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                                            : ''}
                                                </th>
                                                <th style={{cursor: 'pointer'}} scope="col" onClick={() => this.props.SortData('date')}>Foundry Plan
                                                    {this.props.state.sort_by === 'date' ?
                                                        <span className="fa fa-caret-down" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                                        :
                                                        this.props.state.sort_by_1 === 'date' ?
                                                            <span className="fa fa-caret-up" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                                            : ''}
                                                </th>
                                                <th style={{cursor: 'pointer'}} scope="col" onClick={() => this.props.SortData('date')}>Actual Date
                                                    {this.props.state.sort_by === 'date' ?
                                                        <span className="fa fa-caret-down" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                                        :
                                                        this.props.state.sort_by_1 === 'date' ?
                                                            <span className="fa fa-caret-up" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                                            : ''}
                                                </th>
                                            </tr>
                                        </thead>

                                        {this.props.state.optionClicked != 'pid'?
                                            <tbody>
                                            {this.props.state.designMilestones.map(item =>
                                                <tr>
                                                    <td>{item.milestone["milestoneName"]}</td>
                                                    {item["mosisDate"] ?
                                                        <td>{(item["mosisDate"]).slice(5, 7) + "/" + (item["mosisDate"]).slice(8, 10) + "/" + (item["mosisDate"]).slice(0, 4)}</td>
                                                        : <td></td>}
                                                    {item["foundryDate"] ?
                                                        <td>{(item["foundryDate"]).slice(5, 7) + "/" + (item["foundryDate"]).slice(8, 10) + "/" + (item["foundryDate"]).slice(0, 4)}</td>
                                                        : <td></td>}
                                                    {item["actualDate"] ?
                                                        <td>{(item["actualDate"]).slice(5, 7) + "/" + (item["actualDate"]).slice(8, 10) + "/" + (item["actualDate"]).slice(0, 4)}</td>
                                                        : <td>TBD</td>}
                                                </tr>
                                        )}</tbody>:''}
                                        {this.props.state.optionClicked === 'pid'?
                                            <tbody>
                                            <tr>
                                                <td>Work Order Release Date</td>
                                                {this.props.state.productionIdItem["mosisWoReleaseDate"]?
                                                    <td>{(this.props.state.productionIdItem["mosisWoReleaseDate"]).slice(5, 7) + "/" + (this.props.state.productionIdItem["mosisWoReleaseDate"]).slice(8, 10) + "/" + (this.props.state.productionIdItem["mosisWoReleaseDate"]).slice(0, 4)}</td>
                                                    : <td></td>}
                                                {<td></td>}
                                                {this.props.state.productionIdItem["actualWoReleaseDate"]?
                                                    <td>{(this.props.state.productionIdItem["actualWoReleaseDate"]).slice(5, 7) + "/" + (this.props.state.productionIdItem["actualWoReleaseDate"]).slice(8, 10) + "/" + (this.props.state.productionIdItem["actualWoReleaseDate"]).slice(0, 4)}</td>
                                                    : <td>TBD</td>}
                                            </tr>
                                            <tr>
                                                <td>Tapeout Date</td>
                                                {<td></td>}
                                                    {this.props.state.productionIdItem.foundryFabSchedule?
                                                        <td>{(this.props.state.productionIdItem.foundryFabSchedule["tapeoutDate"]).slice(5, 7) + "/" + (this.props.state.productionIdItem.foundryFabSchedule["tapeoutDate"]).slice(8, 10) + "/" + (this.props.state.productionIdItem.foundryFabSchedule["tapeoutDate"]).slice(0, 4)}</td>
                                                        : <td></td>}
                                                        {this.props.state.productionIdItem["actualTapeoutDate"]?
                                                            <td>{(this.props.state.productionIdItem["actualTapeoutDate"]).slice(5, 7) + "/" + (this.props.state.productionIdItem["actualTapeoutDate"]).slice(8, 10) + "/" + (this.props.state.productionIdItem["actualTapeoutDate"]).slice(0, 4)}</td>
                                                            : <td>TBD</td>}
                                            </tr>

                                            <tr>
                                            <td>Ship to MOSIS Date</td>
                                            {this.props.state.productionIdItem["mosisShipToDate"]?
                                            <td>{(this.props.state.productionIdItem["mosisShipToDate"]).slice(5, 7) + "/" + (this.props.state.productionIdItem["mosisShipToDate"]).slice(8, 10) + "/" + (this.props.state.productionIdItem["mosisShipToDate"]).slice(0, 4)}</td>
                                            : <td></td>}
                                            {this.props.state.productionIdItem.foundryFabSchedule?
                                            <td>{(this.props.state.productionIdItem.foundryFabSchedule["shipToMosisDate"]).slice(5, 7) + "/" + (this.props.state.productionIdItem.foundryFabSchedule["shipToMosisDate"]).slice(8, 10) + "/" + (this.props.state.productionIdItem.foundryFabSchedule["shipToMosisDate"]).slice(0, 4)}</td>
                                            : <td></td>}
                                            {this.props.state.productionIdItem["actualShipToDate"]?
                                            <td>{(this.props.state.productionIdItem["actualShipToDate"]).slice(5, 7) + "/" + (this.props.state.productionIdItem["actualShipToDate"]).slice(8, 10) + "/" + (this.props.state.productionIdItem["actualShipToDate"]).slice(0, 4)}</td>
                                            : <td>TBD</td>}
                                            </tr>
                                            </tbody>

                                            :''
                                            }


                                    </table>
                                </div>
                            </div>

                    </div>
                </div>
            </div>
        )
    }
}