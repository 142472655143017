import React, { Component } from 'react';
import { PrConnect } from '../../library/pr-miniredux/';
import { PrHttp } from './pr-http';
import { PrLoader } from "../loader/pr-loader";

class PrA {
    constructor(context) {
        //  alert('in constructor');
        this.context = context;
        this.loader = null;
        this.format = "json";
        this.headers = {};
        this.method = "get";
        this.wetherPrResponse = false;
    }
    WetherPrResponse = (wetherPrResponse) => {
        this.wetherPrResponse = wetherPrResponse;
        return this;
    }
    AddContext = () => {
    }
    PassToHandleResponseFunc = (parameters) => {
    }
    As = (format) => {
        this.format = format;
        return this;
    }
    AddHeader = (headers) => {
        for (var key in headers) {
            this.headers[key] = headers[key];
        }
        // this.headers = {'X-CallBack-Type': 'AJAXGET'};
        // this.headers['content_type'] = 'application/json';
        return this;
    }
    AttachLoader = (loader) => {
        if (loader) {
            this.loader = loader;
        }
        else
            this.isLoader = null;
        return this;
    }
    AddRequiredParams = () => {
        return this;
    }
    Get = (url) => {
        this.method = "get";
        this.url = url;
        return this;
    }
    Post = (url) => {
        this.method = "post";
        this.url = url;
        return this;
    }
    Put = (url) => {
        this.method = "put";
        this.url = url;
        return this;
    }
    Delete = (url) => {
        this.method = "delete";
        this.url = url;
        return this;
    }
    TransferBackResult = (res) => {
        this.props.GetResult(res);
    }
    ExtraData = (data) => {
        this.body = data;
        return this;
    }
    GetResult = (res) => {
        //  alert("result obtained"+ JSON.stringify(res));
        this.succuessFunc(res, this.context);
    }
    OnSuccess(func) {
        this.succuessFunc = func;
        return this;
    }
    Do = () => {

        //alert('in do');
        //   if (this.props.loader == true)
        // {

        // this.setState({isLoader:true});

        //if (this.props.requiredParams != "")

        //let params = this.props.requiredParams; 
        //  alert("calling Do");
        //return <HelloWorld name={'shiva'} />
        // alert(JSON.stringify(this.context));
        return <PrHttp PrStore={this.context} GetResult={this.GetResult} url={this.url} method={this.method}
            ExtraData={''} AddParams={''} body={this.body} Loader={this.loader} As={this.format} 
            wetherPrResponse={this.wetherPrResponse} Headers={this.headers} />
    }
}
export const PrAjax = function (context) {
    const prA = new PrA(context);
    return prA;
}