import React, { Component } from 'react';
import NewTableDesign from './NewTableDesign';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import { PrConnect } from '../../library/pr-miniredux';

import { GetDataForTable } from '../../services/general';

export class tableDesignCtrl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetchTableData: false,
            tableDataList: {},
            data: ''
        }
    }

    UNSAFE_componentWillMount = () => {
        this.setState({
            fetchTableData: true,
            data: this.props.componentprops.data
        });
    }

    componentDidUpdate = () => {
        console.log(this.props.componentprops.data+"---")
        if (this.state.fetchTableData === false && (this.props.componentprops.data !== this.state.data))
            this.setState({
                fetchTableData: true,
                data: this.props.componentprops.data
            });
    }

    HandleSuccessGetTableDataResponse = (res) => {
        this.setState({
            fetchTableData: false,
            tableDataList: res.data
        })
    }

    SortData = (sortby) => {
        let data_arr = this.state.tableDataList;
        let asc = '', dsc = '';
        if (sortby === 'accountNumber') {
            if (this.state.sort_by === '' || this.state.sort_by !== sortby) {
                asc = 1;
                data_arr.sort((a, b) => (a.accountNumber > b.accountNumber) ? 1 : ((b.accountNumber > a.accountNumber) ? -1 : 0));
            }
            else {
                dsc = 1;
                data_arr.sort((a, b) => (a.accountNumber < b.accountNumber) ? 1 : ((b.accountNumber < a.accountNumber) ? -1 : 0));
            }
        }
        else if (sortby === 'accountStatus') {
            if (this.state.sort_by === '' || this.state.sort_by !== sortby) {
                asc = 1;
                data_arr.sort((a, b) => (a.accountStatus > b.accountStatus) ? 1 : ((b.accountStatus > a.accountStatus) ? -1 : 0));
            }
            else {
                dsc = 1;
                data_arr.sort((a, b) => (a.accountStatus < b.accountStatus) ? 1 : ((b.accountStatus < a.accountStatus) ? -1 : 0));
            }
        }
        else if (sortby === 'createdDate') {
            if (this.state.sort_by === '' || this.state.sort_by !== sortby) {
                asc = 1;
                data_arr.sort((a, b) => (a.createdDate > b.createdDate) ? 1 : ((b.createdDate > a.createdDate) ? -1 : 0));
            }
            else {
                dsc = 1;
                data_arr.sort((a, b) => (a.createdDate < b.createdDate) ? 1 : ((b.createdDate < a.createdDate) ? -1 : 0));
            }
        }
        else if (sortby === 'customerId') {
            if (this.state.sort_by === '' || this.state.sort_by !== sortby) {
                asc = 1;
                data_arr.sort((a, b) => (a.customerId > b.customerId) ? 1 : ((b.customerId > a.customerId) ? -1 : 0));
            }
            else {
                dsc = 1;
                data_arr.sort((a, b) => (a.customerId < b.customerId) ? 1 : ((b.customerId < a.customerId) ? -1 : 0));
            }
        }
        else if (sortby === 'accountType') {
            if (this.state.sort_by === '' || this.state.sort_by !== sortby) {
                asc = 1;
                data_arr.sort((a, b) => (a.accountType > b.accountType) ? 1 : ((b.accountType > a.accountType) ? -1 : 0));
            }
            else {
                dsc = 1;
                data_arr.sort((a, b) => (a.accountType < b.accountType) ? 1 : ((b.accountType < a.accountType) ? -1 : 0));
            }
        }
        if (asc === 1) {
            this.setState({
                tableDataList: data_arr,
                sort_by: sortby,
                sort_by_1: ''
            })
        }
        else if (dsc === 1) {
            this.setState({
                tableDataList: data_arr,
                sort_by_1: sortby,
                sort_by: ''
            })
        }
    }

    render() {
        console.log(this.props.PrStore.GetData('generalPurposeSearchData'));
        return (
            <div>
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_CENTER} />
                <NewTableDesign state={this.state} {...this.props} SortData={this.SortData} TableDataList={this.state.tableDataList} />

                {this.state.fetchTableData === true ? GetDataForTable(this.props.PrStore, 't',this.HandleSuccessGetTableDataResponse) : ''}
                {/* {this.state.fetchTableData === true ? GetDataForTable(this.props.PrStore, this.state.data,this.HandleSuccessGetTableDataResponse) : ''} */}
            </div>
        )
    }
}
export const TableDesignCtrlNew = PrConnect(tableDesignCtrl, 'textUpload', 'generalPurposeSearchData');