import React, { Component } from "react";
import GeneralPurposeComponent from "./generalPurpose";
import DynamicRowComponent from "./dynamicRow";
import { PrConnect } from '../../library/pr-miniredux';

export class generalPurposeCtrl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      txtUserName: "",
      showPopup: false,
      selectData: [
        { id: 1, name: "Select1" },
        { id: 2, name: "Select2" },
        { id: 3, name: "Select3" }
      ],
      rows: [
        {
          disabled: "",
          textarea: "",
          file: "",
          select: "",
          date: "",
          link: "#",
          number: "",
          numberWithSign: "",
          search: ""
        }
      ]
    };
  }

  HandleChange = (e, i, key = "") => {
    var rows = Array.from(this.state.rows);
    if (key === "number" || key === "numberWithSign") {
      const re = /^[0-9\b]+$/;
      // if value is not blank, then test the regex
      let val = e.target.value.replace(/[^0-9]/g, "");
      if (val === "" || re.test(val)) {
        val = val.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        if (key === "numberWithSign") {
          if (val !== "") {
            val = "$" + val;
          }
        }
        rows[i][key] = val;
      }
    }
    else if (key === "date") {
      rows[i][key] = e;
    }
    else if (key === "search") {
      this.props.PrStore.SetData('generalPurposeSearchData', e.target.value)
      rows[i][key] = e.target.value;
    }
    else {
      rows[i][key] = e.target.value;
    }
    this.setState({ rows: rows });
  };

  handleClick = (e, data) => {
    console.log(data);
  }

  addRow = e => {
    if (this.state.rows.length <= 10) {
      var rows = this.state.rows.concat({
        disabled: "",
        textarea: "",
        file: "",
        select: "",
        link: "#",
        number: "",
        numberWithSign: "",
        search: ""
      });
      this.setState({ rows: rows });
    }
  };
  deleteRow = (e, i) => {
    if (this.state.rows.length > 1) {
      var rows = this.state.rows;
      rows.splice(i, 1);
      this.setState({ rows: rows });
    }
  };

  render() {
    // console.log(this.props.PrStore.GetData('generalPurposeSearchData'));
    return (
      <div>
        <div
          id="page-inner"
          className="row align-items-center"
          style={{ background: "#fff" }}
        >
          <div className="col-12">
            {/* <GeneralPurposeComponent
              {...this.props}
              state={this.state}
              HandleChange={this.HandleChange}
            /> */}
            <DynamicRowComponent
              {...this.props}
              state={this.state}
              HandleChange={this.HandleChange}
              addRow={this.addRow}
              deleteRow={this.deleteRow}
            />
          </div>
        </div>
      </div>
    );
  }
}

export const GeneralPurposeCtrl = PrConnect(generalPurposeCtrl, 'generalPurposeSearchData');