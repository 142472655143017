import React, { Component } from 'react';
import ContactComponent from './contactus';
import { PrConnect } from '../../library/pr-miniredux';
import PrValidate from '../../library/pr-controls/pr-validation';
import { PrModalDialog } from '../../library/pr-modal/pr-modal-dialog';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';

import { GetCountryList, GetInquiryReasonList, PostContactData } from '../../services/contactus';

export class contactUsCtrl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetchCountries: false,
            fetchInquiryReason: true,
            inquiryReasonList: {},
            postContact: false,

            showHideSubmitBtn: false,

            ddlInquiryReason: '',

            txtFirstName: '',
            txtLastName: '',
            txtCompanyName: '',
            txtContactEmail: '',
            txtContactState: '',
            ddlCountry: '276',
            txtEmpPhone: '',
            txtDescription: '',
            showSuccessModal: false
        }
        this.contact_data = {};
    }

    UNSAFE_componentWillMount = () => {
        if (JSON.stringify(this.props.PrStore.GetData('countrieslist')) === '{}')
            this.setState({
                fetchCountries: true
            });
    }

    onCaptchaChange = (value) => {
        console.log("Captcha value:", value);
        this.setState({
            showHideSubmitBtn: true
        })
    }

    HandleSuccessGetCountriesResponse = (res) => {
        let list = res.data;
        this.props.PrStore.SetData('countrieslist', list);
        this.props.PrStore.BroadCast();
        this.setState({
            fetchCountries: false
        })
    }

    HandleSuccessGetInquiryReasonResponse = (res) => {
        this.setState({
            fetchInquiryReason: false,
            inquiryReasonList: res.data
        })
    }

    HandleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });

        if (e.target.name === 'ddlCountry')
            PrValidate.ForceContactSelect(e.target.value, 'ddlCountry', 'a country');
        else if (e.target.name === 'ddlInquiryReason') {
            PrValidate.ForceContactSelect(e.target.value, 'ddlInquiryReason', 'an Inquiry Reason');
        }/*else if(e.target.name === 'txtEmpPhone'){
            console.log(this.phoneNumberValidation(e.target.value))

        }*/

    }

    phoneNumberValidation = (inputtxt) => {
        console.log("inputtxt: "+inputtxt)
        //if(inputtxt.match(/^\+?\d{1,16}$/g)) {
        if(inputtxt.match(/^[\+\d]?(?:[\d-.\s()]*)$/g)) {
            return true;
        }else{
            alert("message");
            return false;
        }
    }

    HandleOnBlur = (type, field, value, msg) => {
        if (type === 'ForceSelect')
            PrValidate.ForceSelect(value, field, msg);
        else if (type === 'ForceRNumber')
            PrValidate.ForceRNumber(value.replace(/[^0-9]/g, ''), field, msg);
        else if (type === 'ForceDate')
            PrValidate.ForceDate(value, field, msg);
        else if (type === 'ForceEntry')
            PrValidate.ForceEntry(value, field, msg);
        else if (type === 'ForceRWebsite')
            PrValidate.ForceRWebsite(value, field, msg);
        else if (type === 'ForceEmail')
            PrValidate.ForceEmail(value, field, msg);
        else if (type === 'ForceRPhone')
            PrValidate.ForceRPhone(value, field, msg);
        else if (type === 'ForceTextArea')
            PrValidate.ForceTextArea(value, field, msg);
    }

    ContactPost = () => {
        let that = this;
        let txtFirstName = PrValidate.ForceEntry(that.state.txtFirstName, 'txtFirstName', 'first name');
        let txtLastName = PrValidate.ForceEntry(that.state.txtLastName, 'txtLastName', 'last name');
        let txtCompanyName = PrValidate.ForceEntry(that.state.txtCompanyName, 'txtCompanyName', 'company name');
        let txtContactEmail = PrValidate.ForceEmail(that.state.txtContactEmail, 'txtContactEmail');
        let txtContactState = PrValidate.ForceEntry(that.state.txtContactState, 'txtContactState', 'state');
        let txtEmpPhone = PrValidate.ForceRPhone(that.state.txtEmpPhone, 'txtEmpPhone', 'phone');
        let ddlCountry = PrValidate.ForceContactSelect(that.state.ddlCountry, 'ddlCountry', 'a country');
        let ddlInquiryReason = PrValidate.ForceContactSelect(that.state.ddlInquiryReason, 'ddlInquiryReason', 'an inquiry reason');
        let txtDescription = PrValidate.ForceTextArea(that.state.txtDescription, 'txtDescription', 'contact description');

        let err_arr = [txtFirstName, txtLastName, txtCompanyName, txtContactEmail, txtContactState, txtEmpPhone, ddlCountry, ddlInquiryReason, txtDescription];
        let chk_false = 0;
        err_arr.forEach(function (element, index) {
            if (!element)
                chk_false++;
            if ((err_arr.length - 1) === index) {
                if (chk_false === 0) {
                    that.contact_data = {
                        firstName: that.state.txtFirstName,
                        lastName: that.state.txtLastName,
                        companyName: that.state.txtCompanyName,
                        emailAddress: that.state.txtContactEmail,

                        state: that.state.txtContactState,
                        phoneNumber: that.state.txtEmpPhone,
                        country: that.state.ddlCountry,
                        enquiryReason: that.state.ddlInquiryReason,
                        supportDesc: that.state.txtDescription
                    }


                    that.setState({
                        postContact: true
                    })
                }
            }
        });
    }

    HandleSuccessContactResponse = (res) => {
        // ToastsStore.success("Contact sent successfully.");
        this.setState({
            txtFirstName: '',
            txtLastName: '',
            txtCompanyName: '',
            txtContactEmail: '',
            txtContactState: '',
            txtEmpPhone: '',
            ddlCountry: '276',
            ddlInquiryReason: '',
            txtDescription: '',

            postContact: false,
            showSuccessModal: true
        })
    }

    HandleSuccess = () => {
        this.setState({
            showSuccessModal: false
        });
        this.props.componentprops.history.push("/");
    }

    CloseModal = (modalId) => {
        document.getElementById(modalId).style.display = 'none';
        this.setState({
            showSuccessModal: false
        });
    }

    render() {
        return (
            <div>
                {this.state.showSuccessModal ?
                    <PrModalDialog CallBackOk={this.HandleSuccess} prModalData={''}
                        prHeading=''
                        prTitle=''
                        prMessege='Thank you for your enquiry, we will respond as soon as possible.'
                        prettyMessage='Thank you for your enquiry, we will respond as soon as possible.'
                        prShowModal="prConfirmModal"
                        prAjaxResponse={''} />
                    : ''}
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_CENTER} />
                <ContactComponent {...this.props} state={this.state} ContactPost={this.ContactPost}
                    HandleChange={this.HandleChange}
                    countries={this.props.PrStore.GetData('countrieslist')}
                    inquiryReason={this.state.inquiryReasonList}
                    HandleOnBlur={this.HandleOnBlur} onCaptchaChange={this.onCaptchaChange} />
                {this.state.fetchCountries === true ? GetCountryList(this.props.PrStore, this.HandleSuccessGetCountriesResponse) : ''}
                {this.state.fetchInquiryReason === true ? GetInquiryReasonList(this.props.PrStore, this.HandleSuccessGetInquiryReasonResponse) : ''}
                {this.state.postContact === true ? PostContactData(this.props.PrStore, this.contact_data, this.HandleSuccessContactResponse) : ''}
            </div>
        )
    }
}
export const ContactUsCtrl = PrConnect(contactUsCtrl, '');