import React, { Component } from 'react';
import FloatingLabelInput from 'react-floating-label-input';
import $ from 'jquery';
import DatePicker from 'react-date-picker'
export default class DesignUpload extends Component {
    constructor(props) {
        super(props);

    }

    ClearMouse = () => {
        $('.inner_table').css('display', 'none');
    }

    render() {
        return (
            <div>
                <div id="page-wrapper">
                    <div id="page-inner" style={{ background: '#fff' }}>
                        <div className="page_head col-lg-10 col-md-11 mx-auto col-xl-8 ">
                            <h1 class="page-header">Design Upload Request</h1>
                            <div class="step-1" style={{ display: this.props.state.showFirstStep }}>
                                <div class="account">
                                    <div class="accoutn_form">
                                        <form action="" method="">

                                            <div class="dashboard_form">
                                                <div class="inner-dashoard_form">
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <div class="brd_section">
                                                                <div class="row new_pos">
                                                                    <div class="col-lg-4 col-md-4 col-sm-6">
                                                                        <div class="inner_left">
                                                                            <ul className="nav">
                                                                                <li className="nav-item">
                                                                                    <label class="">Design Number</label>
                                                                                </li>
                                                                                <li className="nav-item">
                                                                                    <button type="button" className="ml-1 btn btn-sm btn_sm_red" onClick={this.props.ShowHideModal}>Design Number List</button>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-8 col-md-8 col-sm-6">
                                                                        <div class="inner_right">
                                                                            <div class="form-group">
                                                                                <input type="text" id="txtDesignNumber" name="txtDesignNumber" value={this.props.state.txtDesignNumber} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceRNumber', 'txtDesignNumber', this.props.state.txtDesignNumber, '')} className="form-control" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="brd_section">
                                                                <div class="row new_pos">
                                                                    <div class="col-lg-4 col-md-4 col-sm-6">
                                                                        <div class="inner_left">
                                                                            <label>Foundry</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-8 col-md-8 col-sm-6">
                                                                        <div class="inner_right">
                                                                            <div class="form-group">
                                                                                <input type="text" id="txtFoundry" name="txtFoundry" value={this.props.state.txtFoundry} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtFoundry', this.props.state.txtFoundry, 'foundry')} className="form-control" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="brd_section">
                                                                <div class="row new_pos">
                                                                    <div class="col-lg-4 col-md-4 col-sm-6">
                                                                        <div class="inner_left">
                                                                            <label>Technode</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-8 col-md-8 col-sm-6">
                                                                        <div class="inner_right">
                                                                            <div class="form-group">
                                                                                <input type="text" id="txtTechNode" name="txtTechNode" value={this.props.state.txtTechNode} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtTechNode', this.props.state.txtTechNode, 'technode')} className="form-control" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="header">
                                                                <h3>Design Info </h3>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="brd_section">
                                                                <div class="row new_pos">
                                                                    <div class="col-lg-4 col-md-4 col-sm-6">
                                                                        <div class="inner_left">
                                                                            <label>What is the project name for the design?</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-8 col-md-8 col-sm-6">
                                                                        <div class="inner_right">
                                                                            <div class="form-group">
                                                                                <input type="text" id="txtDesignProjectName" name="txtDesignProjectName" value={this.props.state.txtDesignProjectName} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtDesignProjectName', this.props.state.txtDesignProjectName, 'project name')} className="form-control" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="brd_section">
                                                                <div class="row new_pos">
                                                                    <div class="col-lg-4 col-md-4 col-sm-6">
                                                                        <div class="inner_left">
                                                                            <label>What is the design (chip) size? "X" by "Y"</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-8 col-md-8 col-sm-6">
                                                                        <div class="inner_right">
                                                                            <div class="form-group">
                                                                                <input type="text" id="txtDesignSize" name="txtDesignSize" value={this.props.state.txtDesignSize} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceRNumber', 'txtDesignSize', this.props.state.txtDesignSize, '')} className="form-control" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="brd_section">
                                                                <div class="row new_pos">
                                                                    <div class="col-lg-4 col-md-4 col-sm-6">
                                                                        <div class="inner_left">
                                                                            <label>Quantity Ordered</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-8 col-md-8 col-sm-6">
                                                                        <div class="inner_right">
                                                                            <div class="form-group">
                                                                                <input type="text" id="txtQtyOrdered" name="txtQtyOrdered" value={this.props.state.txtQtyOrdered} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceRNumber', 'txtQtyOrdered', this.props.state.txtQtyOrdered, '')} className="form-control" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="header">
                                                                <h3>Packaging </h3>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="brd_section">
                                                                <div class="row new_pos">
                                                                    <div class="col-lg-4 col-md-4 col-sm-6">
                                                                        <div class="inner_left">
                                                                            <label>Quantity of packaged chips</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-8 col-md-8 col-sm-6">
                                                                        <div class="inner_right">
                                                                            <div class="form-group">
                                                                                <input type="text" id="txtChipsQty" name="txtChipsQty" value={this.props.state.txtChipsQty} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceRNumber', 'txtChipsQty', this.props.state.txtChipsQty, '')} className="form-control" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {this.props.state.chipsQty > 0 ?
                                                            <div class="col-lg-12">
                                                                <div class="brd_section">
                                                                    <div class="row new_pos">
                                                                        <div class="col-lg-4 col-md-4 col-sm-6">
                                                                            <div class="inner_left">
                                                                                <label className="left-padding">Package Type</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-8 col-md-8 col-sm-6">
                                                                            <div class="inner_right">
                                                                                <div class="form-group">
                                                                                    <div className="custom_select">
                                                                                        <select onChange={this.props.HandleChange} name="ddlPackageType" id="ddlPackageType" value={this.props.state.ddlPackageType} >
                                                                                            <option value=''>Select</option>
                                                                                            {/* {(JSON.stringify(this.props.foundries) !== '{}' && this.props.foundries.length > 0) ? this.props.foundries.map(item =>
                                                                                                <option value={item.codeId}>{item.codeName}</option>
                                                                                            ) : ''} */}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : ''}
                                                        {this.props.state.chipsQty > 0 ?
                                                            <div class="col-lg-12">
                                                                <div class="brd_section">
                                                                    <div class="row new_pos">
                                                                        <div class="col-lg-4 col-md-4 col-sm-6">
                                                                            <div class="inner_left">
                                                                                <label className="left-padding">If there is a preferred package vendor and package ID please specify(leave blank if no preferenenca)</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-8 col-md-8 col-sm-6">
                                                                            <div class="inner_right">
                                                                                <div class="form-group">
                                                                                    <input type="text" id="txtPreferredPackageVendor" name="txtPreferredPackageVendor" value={this.props.state.txtPreferredPackageVendor} onChange={this.props.HandleChange} className="form-control" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : ''}
                                                        {this.props.state.chipsQty > 0 ?
                                                            <div class="col-lg-12">
                                                                <div class="brd_section">
                                                                    <div class="row new_pos">
                                                                        <div class="col-lg-4 col-md-4 col-sm-6">
                                                                            <div class="inner_left">
                                                                                <label className="left-padding">Select die rotationn in the package</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-8 col-md-8 col-sm-6">
                                                                            <div class="inner_right">
                                                                                <div class="form-group">
                                                                                    <div className="custom_select">
                                                                                        <select onChange={this.props.HandleChange} name="ddlDieRotation" id="ddlDieRotation" value={this.props.state.ddlDieRotation} >
                                                                                            <option value=''>Select</option>
                                                                                            {/* {(JSON.stringify(this.props.foundries) !== '{}' && this.props.foundries.length > 0) ? this.props.foundries.map(item =>
                                                                                                <option value={item.codeId}>{item.codeName}</option>
                                                                                            ) : ''} */}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : ''}
                                                        {this.props.state.chipsQty > 0 ?
                                                            <div class="col-lg-12">
                                                                <div class="brd_section">
                                                                    <div class="row new_pos">
                                                                        <div class="col-lg-4 col-md-4 col-sm-6">
                                                                            <div class="inner_left">
                                                                                <label className="left-padding">Specify source of the bonding diagram?</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-8 col-md-8 col-sm-6">
                                                                            <div class="inner_right">
                                                                                <div class="form-group">
                                                                                    <div className="custom_select">
                                                                                        <select onChange={this.props.HandleChange} name="ddlBondingSrc" id="ddlBondingSrc" value={this.props.state.ddlBondingSrc} >
                                                                                            <option value=''>Select</option>
                                                                                            {/* {(JSON.stringify(this.props.foundries) !== '{}' && this.props.foundries.length > 0) ? this.props.foundries.map(item =>
                                                                                                <option value={item.codeId}>{item.codeName}</option>
                                                                                            ) : ''} */}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : ''}
                                                        {this.props.state.chipsQty > 0 ?
                                                            <div class="col-lg-12">
                                                                <div class="brd_section">
                                                                    <div class="row new_pos">
                                                                        <div class="col-lg-4 col-md-4 col-sm-6">
                                                                            <div class="inner_left">
                                                                                <label className="left-padding">Specify the downbond loactions?</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-8 col-md-8 col-sm-6">
                                                                            <div class="inner_right">
                                                                                <div class="form-group">
                                                                                    <div className="custom_select">
                                                                                        <select onChange={this.props.HandleChange} name="ddlDownloadLocations" id="ddlDownloadLocations" value={this.props.state.ddlDownloadLocations} >
                                                                                            <option value=''>Select</option>
                                                                                            {/* {(JSON.stringify(this.props.foundries) !== '{}' && this.props.foundries.length > 0) ? this.props.foundries.map(item =>
                                                                                                <option value={item.codeId}>{item.codeName}</option>
                                                                                            ) : ''} */}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : ''}

                                                        <div class="col-lg-12">
                                                            <div class="brd_section border-none">
                                                                <div class="row new_pos">
                                                                    <div class="col-lg-4 col-md-4 col-sm-6">
                                                                        <div class="inner_left">
                                                                            <label>How many Pads are in the design?</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-8 col-md-8 col-sm-6">
                                                                        <div class="inner_right">
                                                                            <div class="form-group">
                                                                                <input type="text" id="txtNoOfPads" name="txtNoOfPads" value={this.props.state.txtNoOfPads} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceRNumber', 'txtNoOfPads', this.props.state.txtNoOfPads, '')} className="form-control" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="text-center">
                                    <button type="button" class="dash_submit_btn" id="next-btn-1" onClick={()=>this.props.ShowHideSteps('showsecond')}>Next</button>
                                </div>
                            </div>

                            <div class="step-2" style={{ display: this.props.state.showSecondStep }}>
                                <div class="account">
                                    <div class="header">
                                        <h3>Design Data  </h3>
                                    </div>
                                    <div class="accoutn_form">
                                        <form action="" method="">
                                            <div class="dashboard_form">
                                                <div class="inner-dashoard_form">
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <div class="brd_section">
                                                                <div class="row new_pos">
                                                                    <div class="col-lg-4 col-md-4 col-sm-6">
                                                                        <div class="inner_left">
                                                                            <label>What is the TOPCELL Name of the design?</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-8 col-md-8 col-sm-6">
                                                                        <div class="inner_right">
                                                                            <div class="form-group">
                                                                                <input type="text" id="txtTopcellDesignName" name="txtTopcellDesignName" value={this.props.state.txtTopcellDesignName} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtTopcellDesignName', this.props.state.txtTopcellDesignName, 'TOPCELL Name of the design')} className="form-control" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="brd_section">
                                                                <div class="row new_pos">
                                                                    <div class="col-lg-4 col-md-4 col-sm-6">
                                                                        <div class="inner_left">
                                                                            <label>Requested Die Thickness (mils)</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-8 col-md-8 col-sm-6">
                                                                        <div class="inner_right">
                                                                            <div class="form-group">
                                                                                <div className="custom_select">
                                                                                    <select onChange={this.props.HandleChange} name="ddlRequestDieThicknessMils" id="ddlRequestDieThicknessMils" value={this.props.state.ddlRequestDieThicknessMils} >
                                                                                        <option value=''>Select</option>
                                                                                        {/* {(JSON.stringify(this.props.foundries) !== '{}' && this.props.foundries.length > 0) ? this.props.foundries.map(item =>
                                                                                            <option value={item.codeId}>{item.codeName}</option>
                                                                                        ) : ''} */}
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="brd_section">
                                                                <div class="row new_pos">
                                                                    <div class="col-lg-4 col-md-4 col-sm-6">
                                                                        <div class="inner_left">
                                                                            <label>What Bond Pad or Bump type is in the design?</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-8 col-md-8 col-sm-6">
                                                                        <div class="inner_right">
                                                                            <div class="form-group">
                                                                                <div className="custom_select">
                                                                                    <select onChange={this.props.HandleChange} name="ddlBondPadOrBumpDesign" id="ddlBondPadOrBumpDesign" value={this.props.state.ddlBondPadOrBumpDesign} >
                                                                                        <option value=''>Select</option>
                                                                                        {/* {(JSON.stringify(this.props.foundries) !== '{}' && this.props.foundries.length > 0) ? this.props.foundries.map(item =>
                                                                                            <option value={item.codeId}>{item.codeName}</option>
                                                                                        ) : ''} */}
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="brd_section">
                                                                <div class="row new_pos">
                                                                    <div class="col-lg-4 col-md-4 col-sm-6">
                                                                        <div class="inner_left">
                                                                            <label>Specify the Process Mode of the design?</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-8 col-md-8 col-sm-6">
                                                                        <div class="inner_right">
                                                                            <div class="form-group">
                                                                                <div className="custom_select">
                                                                                    <select onChange={this.props.HandleChange} name="ddlProcessModeOfDesign" id="ddlProcessModeOfDesign" value={this.props.state.ddlProcessModeOfDesign} >
                                                                                        <option value=''>Select</option>
                                                                                        {/* {(JSON.stringify(this.props.foundries) !== '{}' && this.props.foundries.length > 0) ? this.props.foundries.map(item =>
                                                                                            <option value={item.codeId}>{item.codeName}</option>
                                                                                        ) : ''} */}
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="brd_section">
                                                                <div class="row new_pos">
                                                                    <div class="col-lg-4 col-md-4 col-sm-6">
                                                                        <div class="inner_left">
                                                                            <label>Specify the Process Core of the design?</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-8 col-md-8 col-sm-6">
                                                                        <div class="inner_right">
                                                                            <div class="form-group">
                                                                                <div className="custom_select">
                                                                                    <select onChange={this.props.HandleChange} name="ddlProcessCoreOfDesign" id="ddlProcessCoreOfDesign" value={this.props.state.ddlProcessCoreOfDesign} >
                                                                                        <option value=''>Select</option>
                                                                                        {/* {(JSON.stringify(this.props.foundries) !== '{}' && this.props.foundries.length > 0) ? this.props.foundries.map(item =>
                                                                                            <option value={item.codeId}>{item.codeName}</option>
                                                                                        ) : ''} */}
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="brd_section">
                                                                <div class="row new_pos">
                                                                    <div class="col-lg-4 col-md-4 col-sm-6">
                                                                        <div class="inner_left">
                                                                            <label>Specify the I/O Voltage device of the design?</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-8 col-md-8 col-sm-6">
                                                                        <div class="inner_right">
                                                                            <div class="form-group">
                                                                                <div className="custom_select">
                                                                                    <select onChange={this.props.HandleChange} name="ddlVoltageDeviceOfDesign" id="ddlVoltageDeviceOfDesign" value={this.props.state.ddlVoltageDeviceOfDesign} >
                                                                                        <option value=''>Select</option>
                                                                                        {/* {(JSON.stringify(this.props.foundries) !== '{}' && this.props.foundries.length > 0) ? this.props.foundries.map(item =>
                                                                                            <option value={item.codeId}>{item.codeName}</option>
                                                                                        ) : ''} */}
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="brd_section">
                                                                <div class="row new_pos">
                                                                    <div class="col-lg-4 col-md-4 col-sm-6">
                                                                        <div class="inner_left">
                                                                            <label>Specify the Top Metal in the design?</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-8 col-md-8 col-sm-6">
                                                                        <div class="inner_right">
                                                                            <div class="form-group">
                                                                                <div className="custom_select">
                                                                                    <select onChange={this.props.HandleChange} name="ddlTopMetalInDesign" id="ddlTopMetalInDesign" value={this.props.state.ddlTopMetalInDesign} >
                                                                                        <option value=''>Select</option>
                                                                                        {/* {(JSON.stringify(this.props.foundries) !== '{}' && this.props.foundries.length > 0) ? this.props.foundries.map(item =>
                                                                                            <option value={item.codeId}>{item.codeName}</option>
                                                                                        ) : ''} */}
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="brd_section">
                                                                <div class="row new_pos">
                                                                    <div class="col-lg-4 col-md-4 col-sm-6">
                                                                        <div class="inner_left">
                                                                            <label class="inner_left">Specify the Metal Stack?</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-8 col-md-8 col-sm-6">
                                                                        <div class="inner_right">
                                                                            <div class="form-group">
                                                                                <div className="custom_select">
                                                                                    <select onChange={this.props.HandleChange} name="ddlMetalStack" id="ddlMetalStack" value={this.props.state.ddlMetalStack} >
                                                                                        <option value=''>Select</option>
                                                                                        {/* {(JSON.stringify(this.props.foundries) !== '{}' && this.props.foundries.length > 0) ? this.props.foundries.map(item =>
                                                                                            <option value={item.codeId}>{item.codeName}</option>
                                                                                        ) : ''} */}
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="brd_section">
                                                                <div class="row new_pos">
                                                                    <div class="col-lg-4 col-md-4 col-sm-6">
                                                                        <div class="inner_left">
                                                                            <label>What is the Thicknes (um) Top Metal in the desgin</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-8 col-md-8 col-sm-6">
                                                                        <div class="inner_right">
                                                                            <div class="form-group">
                                                                                <input type="text" id="txtThicknesUMMetalDesign" name="txtThicknesUMMetalDesign" value={this.props.state.txtThicknesUMMetalDesign} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtThicknesUMMetalDesign', this.props.state.txtThicknesUMMetalDesign, 'Thicknes Top Metal in the design')} className="form-control" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="brd_section">
                                                                <div class="row new_pos">
                                                                    <div class="col-lg-4 col-md-4 col-sm-6">
                                                                        <div class="inner_left">
                                                                            <label>What device VTs are in the design?</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-8 col-md-8 col-sm-6">
                                                                        <div class="inner_right">
                                                                            <div class="form-group">
                                                                                <input type="text" id="txtDeviceVTsDesign" name="txtDeviceVTsDesign" value={this.props.state.txtDeviceVTsDesign} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtDeviceVTsDesign', this.props.state.txtDeviceVTsDesign, 'device VTs design')} className="form-control" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="text-center">
                                    <button type="button" class="dash_submit_btn" id="back-btn-1" onClick={()=>this.props.ShowHideSteps('showfirst')} style={{ marginLeft: 10, backgroundColor: '#dfdfdf', color: '#990000' }}>Back</button>
                                    <button type="button" class="dash_submit_btn" id="next-btn-2" onClick={()=>this.props.ShowHideSteps('showthird')}>Next</button>

                                </div>
                            </div>

                            <div class="step-3" style={{ display: this.props.state.showThirdStep }}>
                                <div class="account">
                                    <div class="header">
                                        <h3>Design Data  </h3>
                                    </div>
                                    <div class="accoutn_form">
                                        <form action="" method="">
                                            <div class="dashboard_form">
                                                <div class="inner-dashoard_form">
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <div class="brd_section">
                                                                <div class="row new_pos">
                                                                    <div class="col-lg-4 col-md-4 col-sm-6">
                                                                        <div class="inner_left">
                                                                            <label>What is the AP Thickness (um) of the design?</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-8 col-md-8 col-sm-6">
                                                                        <div class="inner_right">
                                                                            <div class="form-group">
                                                                                <div className="custom_select">
                                                                                    <select onChange={this.props.HandleChange} name="ddlAPThicknessUMofDesign" id="ddlAPThicknessUMofDesign" value={this.props.state.ddlAPThicknessUMofDesign} >
                                                                                        <option value=''>Select</option>
                                                                                        {/* {(JSON.stringify(this.props.foundries) !== '{}' && this.props.foundries.length > 0) ? this.props.foundries.map(item =>
                                                                                            <option value={item.codeId}>{item.codeName}</option>
                                                                                        ) : ''} */}
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="brd_section">
                                                                <div class="row new_pos">
                                                                    <div class="col-lg-4 col-md-4 col-sm-6">
                                                                        <div class="inner_left">
                                                                            <label>Are MIM caps used in the design?</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-8 col-md-8 col-sm-6">
                                                                        <div class="inner_right">
                                                                            <div class="form-group">
                                                                                <div className="custom_select">
                                                                                    <select onChange={this.props.HandleChange} name="ddlMIMCapsUsedInDesign" id="ddlMIMCapsUsedInDesign" value={this.props.state.ddlMIMCapsUsedInDesign} >
                                                                                        <option value=''>Select</option>
                                                                                        <option value=''>Yes</option>
                                                                                        <option value=''>No</option>
                                                                                        {/* {(JSON.stringify(this.props.foundries) !== '{}' && this.props.foundries.length > 0) ? this.props.foundries.map(item =>
                                                                                            <option value={item.codeId}>{item.codeName}</option>
                                                                                        ) : ''} */}
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="brd_section">
                                                                <div class="row new_pos">
                                                                    <div class="col-lg-4 col-md-4 col-sm-6">
                                                                        <div class="inner_left">
                                                                            <label>Do you use MOM IP for the design?</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-8 col-md-8 col-sm-6">
                                                                        <div class="inner_right">
                                                                            <div class="form-group">
                                                                                <div className="custom_select">
                                                                                    <select onChange={this.props.HandleChange} name="ddlMOMIpForDesign" id="ddlMOMIpForDesign" value={this.props.state.ddlMOMIpForDesign} >
                                                                                        <option value=''>Select</option>
                                                                                        <option value='1'>Yes</option>
                                                                                        <option value='0'>No</option>
                                                                                        {/* {(JSON.stringify(this.props.foundries) !== '{}' && this.props.foundries.length > 0) ? this.props.foundries.map(item =>
                                                                                            <option value={item.codeId}>{item.codeName}</option>
                                                                                        ) : ''} */}
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="brd_section">
                                                                <div class="row new_pos">
                                                                    <div class="col-lg-4 col-md-4 col-sm-6">
                                                                        <div class="inner_left">
                                                                            <label>Does the design use Ring Oscillators?</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-8 col-md-8 col-sm-6">
                                                                        <div class="inner_right">
                                                                            <div class="form-group">
                                                                                <div className="custom_select">
                                                                                    <select onChange={this.props.HandleChange} name="ddlDesignUseRingOscillators" id="ddlDesignUseRingOscillators" value={this.props.state.ddlDesignUseRingOscillators} >
                                                                                        <option value=''>Select</option>
                                                                                        <option value='1'>Yes</option>
                                                                                        <option value='0'>No</option>
                                                                                        {/* {(JSON.stringify(this.props.foundries) !== '{}' && this.props.foundries.length > 0) ? this.props.foundries.map(item =>
                                                                                            <option value={item.codeId}>{item.codeName}</option>
                                                                                        ) : ''} */}
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="brd_section">
                                                                <div class="row new_pos">
                                                                    <div class="col-lg-4 col-md-4 col-sm-6">
                                                                        <div class="inner_left">
                                                                            <label>Does the design use One Time Programmable (OTP) IP?</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-8 col-md-8 col-sm-6">
                                                                        <div class="inner_right">
                                                                            <div class="form-group">
                                                                                <div className="custom_select">
                                                                                    <select onChange={this.props.HandleChange} name="ddlDesignUseOTPip" id="ddlDesignUseOTPip" value={this.props.state.ddlDesignUseOTPip} >
                                                                                        <option value=''>Select</option>
                                                                                        <option value='1'>Yes</option>
                                                                                        <option value='0'>No</option>
                                                                                        {/* {(JSON.stringify(this.props.foundries) !== '{}' && this.props.foundries.length > 0) ? this.props.foundries.map(item =>
                                                                                            <option value={item.codeId}>{item.codeName}</option>
                                                                                        ) : ''} */}
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="brd_section">
                                                                <div class="row new_pos">
                                                                    <div class="col-lg-4 col-md-4 col-sm-6">
                                                                        <div class="inner_left">
                                                                            <label>Does the design use SRAM?</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-8 col-md-8 col-sm-6">
                                                                        <div class="inner_right">
                                                                            <div class="form-group">
                                                                                <div className="custom_select">
                                                                                    <select onChange={this.props.HandleChange} name="ddlDesignUseSRAM" id="ddlDesignUseSRAM" value={this.props.state.ddlDesignUseSRAM} >
                                                                                        <option value=''>Select</option>
                                                                                        <option value='1'>Yes</option>
                                                                                        <option value='0'>No</option>
                                                                                        {/* {(JSON.stringify(this.props.foundries) !== '{}' && this.props.foundries.length > 0) ? this.props.foundries.map(item =>
                                                                                            <option value={item.codeId}>{item.codeName}</option>
                                                                                        ) : ''} */}
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="brd_section">
                                                                <div class="row new_pos">
                                                                    <div class="col-lg-4 col-md-4 col-sm-6">
                                                                        <div class="inner_left">
                                                                            <label>Select type of Poly Fuse used in the design?</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-8 col-md-8 col-sm-6">
                                                                        <div class="inner_right">
                                                                            <div class="form-group">
                                                                                <div className="custom_select">
                                                                                    <select onChange={this.props.HandleChange} name="ddlPolyTypeFuseDesign" id="ddlPolyTypeFuseDesign" value={this.props.state.ddlPolyTypeFuseDesign} >
                                                                                        <option value=''>Select</option>
                                                                                        {/* {(JSON.stringify(this.props.foundries) !== '{}' && this.props.foundries.length > 0) ? this.props.foundries.map(item =>
                                                                                            <option value={item.codeId}>{item.codeName}</option>
                                                                                        ) : ''} */}
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="brd_section">
                                                                <div class="row new_pos">
                                                                    <div class="col-lg-4 col-md-4 col-sm-6">
                                                                        <div class="inner_left">
                                                                            <label>What is the Operational Frequency for the design? (Include unit of measure)</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-8 col-md-8 col-sm-6">
                                                                        <div class="inner_right">
                                                                            <div class="form-group">
                                                                                <input type="text" id="txtOperationalFrequencyDesign" name="txtOperationalFrequencyDesign" value={this.props.state.txtOperationalFrequencyDesign} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtOperationalFrequencyDesign', this.props.state.txtOperationalFrequencyDesign, 'Operational Frequency for the design')} className="form-control" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="text-center">
                                    <button type="button" class="dash_submit_btn" id="back-btn-1" onClick={()=>this.props.ShowHideSteps('showsecond')} style={{ marginLeft: 10, backgroundColor: '#dfdfdf', color: '#990000' }} >Back</button>
                                    <button type="button" class="dash_submit_btn">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}