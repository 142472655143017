import React, { Component } from 'react';
import FloatingLabelInput from 'react-floating-label-input';

export default class QuoteDedicatedComponent extends Component {

    render() {
        return (
            <div>
                <div id="page-wrapper">
                    <div id="page-inner" style={{ background: '#fff' }}>
                        <div className="page_head col-xl-8 mx-auto">
                            <h1 className="page-header"> Global Foundries Dedicated Quote</h1>
                            <div className="account">
                                <div className="accoutn_form">
                                    <form action="" method="">

                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section border-none">
                                                            <div className="row new_pos" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                                                                <div className="col-lg-3 col-md-3 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Customer Name</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-9 col-md-9 col-sm-6">
                                                                    <div className="inner_right">
                                                                        <div className="form-group">
                                                                            <input type="text" id="txtCustName" name="txtCustName" value={this.props.state.txtCustName} onChange={this.props.HandleChange} className="form-control bg-darkgray" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="account">
                                <div className="accoutn_form">
                                    <form action="" method="">
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row new_pos" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Quote Number - Revision Number</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="inner_right">
                                                                        <div className="form-group">
                                                                            <input type="text" id="txtQuoteNumber" name="txtQuoteNumber" value={this.props.state.txtQuoteNumber} onChange={this.props.HandleChange} className="form-control bg-darkgray form-control-xs" placeholder="1138-0" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section border-none">
                                                            <div className="row new_pos" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Email Addresses(es) (separate by comma)</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="inner_right">
                                                                        <div className="form-group">
                                                                            <input type="text" id="txtEmail" name="txtEmail" value={this.props.state.txtEmail} onChange={this.props.HandleChange} className="form-control" placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>


                            <div className="account">
                                <div className="header">
                                    <h3>Quote Details</h3>
                                </div>
                                <div className="accoutn_form">
                                    <form action="" method="">

                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">

                                                    <div className="col-lg-12" style={{display:"none"}}>
                                                        <div className="brd_section">
                                                            <div className="row new_pos" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>MOSIS Price factor</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 col-md-2 col-sm-6">
                                                                    {/* <div className="row">
                                                                        <div className="col-lg-2 col-md-3 col-sm-12"> */}
                                                                    <div className="inner_right">
                                                                            <div className="form-group">
                                                                                <input type="text" id="mpriceFactor" name="mpriceFactor" value={this.props.state.mpriceFactor} onChange={(e) =>this.props.inputValueChanged(e)} onBlur={(e) =>this.props.onMosisMarkupValueChanged(e)} className="form-control" placeholder="%" />
                                                                            </div>
                                                                    </div>
                                                                    {/* </div>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row new_pos" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Selected Technology</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    {/* <div className="row">
                                                                        <div className="col-lg-2 col-md-3 col-sm-12"> */}
                                                                    <div className="inner_right">
                                                                        <div className="form-group">
                                                                            <div className="custom_select">
                                                                                <select id="ddlSelectedTech" name="ddlSelectedTech" value={this.props.state.ddlSelectedTech} onChange={this.props.HandleTechnologyChange}>
                                                                                    <option value=''>Select</option>
                                                                                    {JSON.stringify(this.props.state.dedicatedQuoteData) != '{}'?
                                                                                        this.props.state.dedicatedQuoteData["technologyProcessList"].map((item, index) => (
                                                                                        <option value={item["processName"]}>
                                                                                            {item["processName"]}
                                                                                        </option>
                                                                                    )):''
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* </div>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row new_pos" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Design Number</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="inner_right">
                                                                        <div className="form-group">
                                                                            <input type="text" id="txtDesignNumber" name="txtDesignNumber" value={this.props.state.txtDesignNumber} onChange={this.props.HandleChange} className="form-control" placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label id="forcastQuantity">Is wafer forecast greater than 150 wafers per year?</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">

                                                                    <div className="row new_pos">
                                                                        <div className="col-lg-5 col-md-5 col-sm-12">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <div className="custom_select">
                                                                                        <select id="ddlWaferForecastPerYear" name="ddlWaferForecastPerYear" value={this.props.state.ddlWaferForecastPerYear} onChange={this.props.onWaferForecastSelectionChanged}>
                                                                                            <option value=''>Select</option>
                                                                                            <option value='Yes'>Yes</option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row new_pos" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Number of EUH, Preproduction, Prototype Wafers (standard minimum is 3)</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="inner_right">
                                                                        <div className="form-group">
                                                                            <input type="text" id="txtEUHNumber" name="txtEUHNumber" onClick={this.props.highlightText} value={this.props.state.txtEUHNumber} onBlur={(e) =>this.props.onNumberOfEUHWaferChange(e)}  onChange={this.props.HandleChange} className="form-control" placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Is this a MASK RESPIN order?</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="row new_pos">
                                                                        <div className="col-lg-5 col-md-5 col-sm-12">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <div className="custom_select">
                                                                                        <select id="ddlMaskRespinOrder" name="ddlMaskRespinOrder" value={this.props.state.ddlMaskRespinOrder} onChange={this.props.onMaskRespinOrderSelectionChanged}>
                                                                                            <option value=''>Select</option>
                                                                                            <option value='Yes'>Yes</option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row new_pos" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Number of additional masks</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    {/* <div className="row">
                                                                        <div className="col-sm-12 col-md-7 col-lg-7"> */}
                                                                    <div className="inner_right">
                                                                        <div className="form-group">
                                                                            <input type="text" id="txtAddMaskNumber" name="txtAddMaskNumber" onClick={this.props.highlightText} value={this.props.state.txtAddMaskNumber} onChange={this.props.HandleChange} onBlur={this.props.onNumberOfAdditionMaskChange} className="form-control" placeholder="" readonly="readonly"/>
                                                                        </div>
                                                                    </div>
                                                                    {/* </div>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row new_pos" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Expedite Order</label>
                                                                    </div>
                                                                </div>
                                                                {/*<div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="inner_right">
                                                                        <div className="form-group">
                                                                            <input type="text" id="txtExpediteOrder" name="txtExpediteOrder" value={this.props.state.txtExpediteOrder} onChange={this.props.HandleChange} className="form-control bg-darkgray" placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                </div>*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row new_pos" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Do you want Rit AB Expedite?</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="row">
                                                                        <div className="col-lg-5 col-md-5 col-sm-12">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <div className="custom_select">
                                                                                        <select id="ddlRitAbExpedite" name="ddlRitAbExpedite" value={this.props.state.ddlRitAbExpedite} onChange={this.props.onExpiditeABSelectionChanged}>
                                                                                            <option>Select</option>
                                                                                            <option>Yes</option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row new_pos" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Do you want Rit B Expedite?</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="row">
                                                                        <div className="col-lg-5 col-md-5 col-sm-12">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <div className="custom_select">
                                                                                        <select id="ddlRitBExpedite" name="ddlRitBExpedite" value={this.props.state.ddlRitBExpedite} onChange={this.props.onExpiditeBSelectionChanged}>
                                                                                            <option>Select</option>
                                                                                            <option>Yes</option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section border-none">
                                                            <div className="row new_pos" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Number of Production Wafers ordered (standard is multiples of 25)</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    {/* <div className="row">
                                                                        <div className="col-sm-12 col-md-7 col-lg-7"> */}
                                                                    <div className="inner_right">
                                                                        <div className="form-group">
                                                                            <input type="text" id="txtProdWafersNumber" name="txtProdWafersNumber" onClick={this.props.highlightText} value={this.props.state.txtProdWafersNumber} onChange={this.props.HandleChange} onBlur={this.props.numberOfProductionWaferChange} className="form-control" placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                    {/* </div>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="account">
                                <div className="header">
                                    <h3 className="border-none">Features Summary</h3>
                                </div>
                                {/* <div className="accoutn_form">
                                <h3>Features Summary</h3>
                                </div> */}
                                <table className="table">
                                    <tbody>
                                <tr className="trBg">
                                    <td colSpan="2" width="100%" className="dynamicTableContainer" align="center" hidden={this.props.state.ddlSelectedTech!=''?false:true}>
                                        <table className="dynamic-table table table-sm table_sm_default" width="100%" id="defaultFeatureSummaryTable" style={{textAlign:"center"}}>
                                            <thead>
                                            <tr >
                                                <td className="dynamic-table-thead-first" style={{cursor: "default", width: "150px", borderLeft:"1px solid #ccc", borderRight: "1px solid #ccc", color:"white", fontWeight:"bold", backgroundColor:"#990000"}}>Select</td>
                                                <td className="dynamic-table-thead" style={{cursor: "default", width: "150px", borderLeft:"1px solid #ccc", borderRight: "1px solid #ccc", color:"white", fontWeight:"bold", backgroundColor:"#990000"}}>Portion</td>
                                                <td className="dynamic-table-thead" style={{cursor: "default", width: "150px", borderLeft:"1px solid #ccc", borderRight: "1px solid #ccc", color:"white", fontWeight:"bold", backgroundColor:"#990000"}}>Feature</td>
                                                <td className="dynamic-table-thead" style={{cursor: "default", width: "150px", borderLeft:"1px solid #ccc", borderRight: "1px solid #ccc", color:"white", fontWeight:"bold", backgroundColor:"#990000"}}>Wafer Charge</td>
                                                <td className="dynamic-table-thead" style={{cursor: "default", width: "150px", borderLeft:"1px solid #ccc", borderRight: "1px solid #ccc", color:"white", fontWeight:"bold", backgroundColor:"#990000"}}>Quote Mask Qty</td>
                                                <td className="dynamic-table-thead" style={{cursor: "default", width: "150px", borderLeft:"1px solid #ccc", borderRight: "1px solid #ccc", color:"white", fontWeight:"bold", backgroundColor:"#990000"}}>Part Number</td>
                                                <td className="dynamic-table-thead-last" style={{cursor: "default", width: "150px", borderLeft:"1px solid #ccc", borderRight: "1px solid #ccc", color:"white", fontWeight:"bold", backgroundColor:"#990000"}}>Req'd Mask</td>
                                            </tr>
                                            </thead>

                                            <tbody id="featureSumaryTBody">


                                            {this.props.state.featureTableContent.length > 0?
                                                    this.props.state.featureTableContent.map((item, index) => (
                                                    <tr>
                                                        <td className="dynamic-table-thead-first" style={{cursor: "default", width: "150px", minWidth: "80px"
                                                            ,borderLeft:"1px solid black", borderRight: "1px solid black"}} >
                                                            {/*{item["waferCharge"]>0?
                                                                <select id={"featureRowSelect-"+item["dedWaferAddonPricingId"]}
                                                                        onChange={(e) => this.props.onFeatureItemSelectionChanged(e,item)}
                                                                        value={this.props.state.featureRowSelectValue[item["dedWaferAddonPricingId"]]}>
                                                                    <option value=''>Select</option>
                                                                    <option value='Yes'>Yes</option>
                                                                </select>:''
                                                            }*/}

                                                            {item["waferCharge"]>0?
                                                                <div class="inner_right">
                                                                    <div class="form-group mb-0">
                                                                        <div class="custom_select" id="featureSummaryOptions">
                                                                            <select id={"featureRowSelect-"+item["dedWaferAddonPricingId"]}
                                                                                    className="featureSummaryOptions"
                                                                                    onChange={(e) => this.props.onFeatureItemSelectionChanged(e,item)}
                                                                                    value={this.props.state.featureRowSelectValue[item["dedWaferAddonPricingId"]]}>
                                                                                <option value=''>Select</option>
                                                                                <option value='Yes'>Yes</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>:''
                                                            }

                                                        </td>
                                                        <td className="dynamic-table-thead" style={{cursor: "default", width: "150px", borderLeft:"1px solid black", borderRight: "1px solid black"}}>{item["portionName"]}</td>
                                                        <td className="dynamic-table-thead" style={{cursor: "default", width: "150px", borderLeft:"1px solid black", borderRight: "1px solid black"}}>{item["feature"]}</td>
                                                        <td className="dynamic-table-thead" style={{cursor: "default", width: "150px", borderLeft:"1px solid black", borderRight: "1px solid black"}}>{item["waferCharge"] != null?"$"+item["waferCharge"]:item["waferCharge"]}</td>
                                                        <td className="dynamic-table-thead" style={{cursor: "default", width: "150px", borderLeft:"1px solid black", borderRight: "1px solid black"}}>{item["quoteMaskQuantity"]}</td>
                                                        <td className="dynamic-table-thead" style={{cursor: "default", width: "150px", borderLeft:"1px solid black", borderRight: "1px solid black"}}>{item["partNumber"]}</td>
                                                        <td className="dynamic-table-thead-last" style={{cursor: "default", width: "150px", borderLeft:"1px solid black", borderRight: "1px solid black"}}>{item["requiredMask"]}</td>
                                                    </tr>
                                                )):''}





                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                    </tbody>
                                </table>
                            </div>



                            <div className="account">
                                <div className="header">
                                    <h3>{this.props.state.ddlSelectedTech!=''?this.props.state.ddlSelectedTech:"Technology Process"}</h3>
                                </div>
                                <div className="accoutn_form">
                                    <form action="" method="">

                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>NRE</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="row">
                                                                        <div className="col-lg-5 col-md-5 col-sm-12">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <input type="text" id="txtNre" name="txtNre" value={this.props.state.txtNre} onChange={this.props.HandleChange} className="form-control bg-darkgray" placeholder="$0" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Feature Mask Price</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="row">
                                                                        <div className="col-lg-5 col-md-5 col-sm-12">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <input type="text" id="txtFeatureMaskPrice" name="txtFeatureMaskPrice" value={this.props.state.txtFeatureMaskPrice} onChange={this.props.HandleChange} className="form-control bg-darkgray" placeholder="$0" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>NRE Subtotal</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="row">
                                                                        <div className="col-lg-5 col-md-5 col-sm-12">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <input type="text" id="txtNreSubtotalVal" name="txtNreSubtotalVal" value={this.props.state.txtNreSubtotalVal} onChange={this.props.HandleChange} className="form-control bg-darkgray" placeholder="$0" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section" style={{ borderBottom: 0, padding: 0 }}>
                                                            <div className="header">
                                                                <h3 style={{ borderBottomWidth: 1, borderColor: '#b38f8f' }}>Engineering</h3>
                                                                {/* <h3  style="border-bottom: 1px solid #b38f8f;">Engineering</h3> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Base Wafer Price</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="row">
                                                                        <div className="col-lg-5 col-md-5 col-sm-12">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <input type="text" id="txtBaseWaterPrice" name="txtBaseWaterPrice" value={this.props.state.txtBaseWaterPrice} onChange={this.props.HandleChange} className="form-control bg-darkgray" placeholder="$0" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Feature Wafer Price</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="row">
                                                                        <div className="col-lg-5 col-md-5 col-sm-12">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <input type="text" id="txtBaseWaferPrice" name="txtBaseWaferPrice" value={this.props.state.txtEnggFeatureWaferPrice} onChange={this.props.HandleChange} className="form-control bg-darkgray" placeholder="$0" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Engineering Wafer Price</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="row">
                                                                        <div className="col-lg-5 col-md-5 col-sm-12">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <input type="text" id="txtEnggWaferPrice" name="txtEnggWaferPrice" value={this.props.state.txtEnggWaferPrice} onChange={this.props.HandleChange} className="form-control bg-darkgray" placeholder="$0" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Total Engineering Wafer Price</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="row">
                                                                        <div className="col-lg-5 col-md-5 col-sm-12">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <input type="text" id="txtTotalEnggWaferPrice" name="txtTotalEnggWaferPrice" value={this.props.state.txtTotalEnggWaferPrice} onChange={this.props.HandleChange} className="form-control bg-darkgray" placeholder="$0" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section" style={{ borderBottom: 0, padding: 0 }}>
                                                            <div className="header">
                                                                <h3 style={{ borderBottomWidth: 1, borderColor: '#b38f8f' }}>Mask Respin</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Respin NRE</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="row">
                                                                        <div className="col-lg-5 col-md-5 col-sm-12">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <input type="text" id="txtRespinNre" name="txtRespinNre" value={this.props.state.txtRespinNre} onChange={this.props.HandleChange} className="form-control bg-darkgray" placeholder="$0" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Mask Price</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="row">
                                                                        <div className="col-lg-5 col-md-5 col-sm-12">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <input type="text" id="txtMaskPrice" name="txtMaskPrice" value={this.props.state.txtMaskPrice} onChange={this.props.HandleChange} className="form-control bg-darkgray" placeholder="$0" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section" style={{ borderBottom: 0, padding: 0 }}>
                                                            <div className="header">
                                                                <h3>Expedite Fees</h3>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Rit AB Expedite Fees</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="row">
                                                                        <div className="col-lg-5 col-md-5 col-sm-12">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <input type="text" id="txtRitABExpediteFee" name="txtRitABExpediteFee" value={this.props.state.txtRitABExpediteFee} onChange={this.props.HandleChange} className="form-control bg-darkgray" placeholder="$0" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section border-none">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Rit B Expedite Fees</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="row">
                                                                        <div className="col-lg-5 col-md-5 col-sm-12">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <input type="text" id="txtRitBExpediteFee" name="txtRitBExpediteFee" value={this.props.state.txtRitBExpediteFee} onChange={this.props.HandleChange} className="form-control bg-darkgray" placeholder="$0" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>


                            <div className="account">
                                <div className="accoutn_form">
                                    <form action="" method="">
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label><b>Production Base Wafer Price</b></label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="row">
                                                                        <div className="col-lg-5 col-md-5 col-sm-12">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <input type="text" id="txtProdBaseWaferPrice" name="txtProdBaseWaferPrice" value={this.props.state.txtProdBaseWaferPrice} onChange={this.props.HandleChange} className="form-control bg-darkgray" placeholder="$0" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label><b>Feature Wafer Price</b></label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="row">
                                                                        <div className="col-lg-5 col-md-5 col-sm-12">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <input type="text" id="txtFeatureWaferPrice" name="txtFeatureWaferPrice" value={this.props.state.txtFeatureWaferPrice} onChange={this.props.HandleChange} className="form-control bg-darkgray" placeholder="$0" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section border-none">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label><b>Production Price per Wafer</b></label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="row">
                                                                        <div className="col-lg-5 col-md-5 col-sm-12">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <input type="text" id="txtProdPricePerWafer" name="txtProdPricePerWafer" value={this.props.state.txtProdPricePerWafer} onChange={this.props.HandleChange} className="form-control bg-darkgray" placeholder="$0" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="account">
                                <div className="accoutn_form">
                                    <form action="" method="">
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section border-none">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label><b>Total Production Wafer Price</b></label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="row">
                                                                        <div className="col-lg-5 col-md-5 col-sm-12">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <input type="text" id="txtTotalProdWaferPrice" name="txtTotalProdWaferPrice" value={this.props.state.txtTotalProdWaferPrice} onChange={this.props.HandleChange} className="form-control bg-darkgray" placeholder="$0" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="account">
                                <div className="accoutn_form">
                                    <form action="" method="">
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section border-none">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label><b>Total Price</b></label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="row">
                                                                        <div className="col-lg-5 col-md-5 col-sm-12">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <input type="text" id="txtTotalPrice" name="txtTotalPrice" value={this.props.state.txtTotalPrice} onChange={this.props.HandleChange} className="form-control bg-darkgray" placeholder="$0" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="account">
                                <div className="header">
                                    <h3 style={{ fontSize: 14, borderBottom: 0 }}>Note: If the shipping address is within California then California Sales Tax will be added to the Invoice.</h3>
                                </div>
                            </div>


                            {JSON.stringify(this.props.state.quotationTncListMap) != '{}'?
                                [...Array(this.props.state.quotationTncList.length)].map((i, index) =>

                                //this.props.state.quotationTncList.map((item, index) => (
                                    <div className="account">
                                        <div className="header">
                                            <h3 id="tncHeader-1">{this.props.state.quotationTncListMap[index+1].tcType}</h3>
                                        </div>
                                        <div className="accoutn_form">
                                            <form action="" method="">
                                                <div className="dashboard_form">
                                                    <div className="inner-dashoard_form">
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="brd_section border-none">
                                                                    <div className="row">
                                                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                                                            <div className="inner_left" id="tncLabel-1">
                                                                                <label>
                                                                                    {this.props.state.quotationTncListMap[index+1].tcDetails.split("<li>")[0]}
                                                                                    {this.props.state.quotationTncListMap[index+1].tcDetails.split("<li>").length>1?
                                                                                        <ul>
                                                                                        {this.props.state.quotationTncListMap[index+1].tcDetails.split("<li>").map((splitItem, index) =>
                                                                                                index != 0?
                                                                                                    <li>{splitItem.replace('</li>','')}</li>:''
                                                                                            )}</ul>
                                                                                        :''}
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                ):''
                            }

                            {/*<div className="account">
                                <div className="header">
                                    <h3 id="tncHeader-1">Acceptance</h3>
                                </div>
                                <div className="accoutn_form">
                                    <form action="" method="">
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section border-none">
                                                            <div className="row">
                                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="inner_left" id="tncLabel-1">
                                                                        <label>The MOSIS Service issues this quotation on the basis of the MOSIS Terms and Conditions posted on The MOSIS Service website</label>
                                                                        <label>The MOSIS Terms and Conditions shall apply to the Customer's Purchase Order and shall prevail over any conflicting or additional terms in Customer's Purchase Order or other documents or forms issued by the Customer, unless Customer has entered into a written agreement signed by The MOSIS Service.</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="account">
                                <div className="header">
                                    <h3 id="tncHeader-2">Delivery / Shipping</h3>
                                </div>
                                <div className="accoutn_form">
                                    <form action="" method="">
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section border-none">
                                                            <div className="row">
                                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="inner_left">
                                                                        <label id="tncLabel-2">Orders will be shipped to the shipping address on file with The MOSIS Service. If an alternate address is to be used it needs to be adequately specified on the Customer Purchase Order.</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="account">
                                <div className="header">
                                    <h3 id="tncHeader-3">California Sales Tax</h3>
                                </div>
                                <div className="accoutn_form">
                                    <form action="" method="">
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section border-none">
                                                            <div className="row">
                                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="inner_left">
                                                                        <label id="tncLabel-3">As required by state law, the University of Southern California collects sales tax from our customers for orders shipped within the state of California. To preclude the application of California Sales Tax the customer needs to provide a valid California Resale Certificate, CDTFA-230 rev. 1 (8-17) with their Purchase Order.</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="account">
                                <div className="header">
                                    <h3 id="tncHeader-4">Invoicing and Payment Terms</h3>
                                </div>
                                <div className="accoutn_form">
                                    <form action="" method="">
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section border-none">
                                                            <div className="row">
                                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="inner_left">
                                                                        <label id="tncLabel-4">The quoted prices are in US DOLLARS and include domestic Federal Express 2 day shipping; delivery is best efforts.
                                                            The customer will be invoiced in full upon receipt of their Purchase Order. Payment in full must be received prior to the order being processed with the foundry.</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="account">
                                <div className="header">
                                    <h3 id="tncHeader-5">Cancellation Policy</h3>
                                </div>
                                <div className="accoutn_form">
                                    <form action="" method="">
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section border-none">
                                                            <div className="row">
                                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="inner_left">
                                                                        <label id="tncLabel-5">
                                                                            TIf the Customer cancels:
                                                                    <ul>
                                                                                <li>After a design has been uploaded and before DRC checks are run there is no cancellation fee.</li>
                                                                                <li>If cancelled after DRC checks and before Tapeout- Cancellation fee is 50% of the lower of standard or quoted Price.</li>
                                                                                <li>If cancelled after Tapeout- Cancellation fee is 100% of the value of the Purchase Order.</li>
                                                                                <li>If The MOSIS Service is unable to complete the order due to US Government Export actions then the order will be treated as a customer cancellation.</li>
                                                                            </ul>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="account">
                                <div className="header">
                                    <h3 id="tncHeader-6">Design uploads (Design iterations)</h3>
                                </div>
                                <div className="accoutn_form">
                                    <form action="" method="">
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section border-none">
                                                            <div className="row">
                                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="inner_left">
                                                                        <label id="tncLabel-6">
                                                                            The price quoted provides for two design submissions, ie one preliminary and one final design file. If the customer wishes to increase the number of design submissions, ie design iterations, there will be a charge per submission.
                                                                </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>


                            <div className="account">
                                <div className="header">
                                    <h3 id="tncHeader-7">Cancellation Policy</h3>
                                </div>
                                <div className="accoutn_form">
                                    <form action="" method="">
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section border-none">
                                                            <div className="row">
                                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="inner_left">
                                                                        <label id="tncLabel-7">
                                                                            The MOSIS Service must receive a valid purchase order before any order can be accepted and processed.<br />
                                                                            Purchase orders should be issued to "University of Southern California, The MOSIS Service." Send your purchase order as an attachment to mosis-po@mosis.com. Adobe PDF is the preferred file format.<br />
                                                                            <span style={{ paddingLeft: 10 }}>The following information is required on customer purchase orders:</span>
                                                                            <ul style={{ paddingLeft: 30 }}>

                                                                                <li>The MOSIS Service Design number(s) [if known]</li>
                                                                                <li>MOSIS Quote ID.</li>
                                                                                <li>Customer Purchase Contact Name, email and telephone number.</li>
                                                                                <li>Shipping Address Contact Name, email and telephone number.</li>
                                                                            </ul>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>


                            <div className="account">
                                <div className="header" id="tncHeader">
                                    <h3 id="tncHeader-8">Wafers at CWI</h3>
                                </div>
                                <div className="accoutn_form">
                                    <form action="" method="">
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section border-none">
                                                            <div className="row">
                                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="inner_left" id="tncLabel-8">
                                                                        <label>
                                                                            The minimum number of wafers to be held at CWI is three. Wafers may be held for not more than 180 days.
                                                                </label>
                                                                        <label>Wafers held at CWI will be invoiced at order placement and no refund or credit will be made to customers who cancel or abandon wafers at CWI.</label>
                                                                        <label>If wafers remain at CWI at 180 days MOSIS will instruct the foundry to release the wafers for completion.</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>*/}

                            <div className="text-center">
                                {/*<button type="button" className="dash_submit_btn" onClick={(e) => this.props.onSubmit(e, "save")} disabled={this.props.state.saveDedicatedQuoteData}>Save</button>*/}
                                <button type="button" className="dash_submit_btn" onClick={(e) => this.props.onSubmit(e, "saveEmail")} disabled={this.props.state.saveDedicatedQuoteData}>Email Quote</button>
                            </div>

                        </div>
                        {/* <div className="text-center">
                            <button type="button" className="dash_submit_btn">Email</button>
                        </div> */}
                    </div>
                </div>
            </div>
        )
    }
}