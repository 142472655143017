import React, { Component } from 'react';
import FloatingLabelInput from 'react-floating-label-input';
import DatePicker from 'react-date-picker';
import $ from 'jquery';

export default class AccManagementComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            values: [],
            AddNewDesignRow: false,
            NoOfDesignRow: 0,
            val: this.props.state.txtDesignMosisAccNo1
        }
        this.NewInputText = () => '';
    }

    AddNewRows = () => {
        let num_rows = this.state.NoOfDesignRow + 1;

        // this.NewInputText = () => this.makeTextInput(num_rows);
        // // this.NewInputText = () => this.makeTextInput(num_rows);
        // this.setState({
        //     NoOfDesignRow: num_rows
        // })
        // let NewInputText = () => this.makeTextInput();
        // alert(JSON.stringify(<NewInputText />))
        // $('#idDesignMgr').append(<NewInputText/>);

        // this.makeTextInput();
        // let num_rows = this.state.NoOfDesignRow+1;
        // this.setState({
        //     AddNewDesignRow: true,
        //     NoOfDesignRow: num_rows
        // });
        // let input = '<tr>';
        // input += '<td>';
        // input += '<td>';
        // input += '</td>';
        // input += '<tr>';

        let fName = 'txtDesignFirstName_' + num_rows;
        let lName = 'txtDesignLastName_' + num_rows;
        let title = 'txtDesignTitle_' + num_rows;
        let email = 'txtDesignEmail_' + num_rows;
        let tel = 'txtDesignTelNo_' + num_rows;
        // this.props.SetDefaultValue(fName);
        let change = this.props.HandleChange;

        let new_row = '<tr>';
        new_row += '<td>';
        new_row += '<div class="form-group">';
        new_row += '<input type="text" id="' + fName + '" name="' + fName + '" value=' + this.props.state[fName] + ' onkeypress="{this.props.HandleChange}" class="form-control form-control-xs" placeholder="" />';
        new_row += '</div>';
        new_row += '</td>';

        // new_row += '<td>';
        // new_row += '<div class="form-group">';
        // new_row += '<input type="text" id="'+lName+'" name="'+lName+'" value='+this.props.state[lName]+' class="form-control form-control-xs" placeholder="" />';
        // new_row += '</div>';
        // new_row += '</td>';

        // new_row += '<td>';
        // new_row += '<div class="form-group">';
        // new_row += '<input type="text" id="'+title+'" name="'+title+'" value='+this.props.state[title]+' class="form-control form-control-xs" placeholder="" />';
        // new_row += '</div>';
        // new_row += '</td>';

        // new_row += '<td>';
        // new_row += '<div class="form-group">';
        // new_row += '<input type="text" id="'+email+'" name="'+email+'" value='+this.props.state[email]+' class="form-control form-control-xs" placeholder="" />';
        // new_row += '</div>';
        // new_row += '</td>';

        // new_row += '<td>';
        // new_row += '<div class="form-group">';
        // new_row += '<input type="text" id="'+tel+'" name="'+tel+'" value='+this.props.state[tel]+' class="form-control form-control-xs" placeholder="" />';
        // new_row += '</div>';
        // new_row += '</td>';

        // new_row += '<td>';
        // new_row += '<div class="form-group">';
        // new_row += '<span>';
        // new_row += '<img src='+require("../../../assets/img/delete.svg")+' alt="delete logo" />';
        // new_row += '</span>';
        // new_row += '</div>';
        // new_row += '</td>';
        new_row += '</tr>';

        $('#idDesignMgr').append(new_row);

        // this.props.SetDefaultValue(fName);

        this.setState({
            NoOfDesignRow: num_rows,
        })
    }
    makeTextInput = (num) => {
        // alert('jjjj'+num)
        let fName = 'txtDesignFirstName_' + num;
        let lName = 'txtDesignLastName_' + num;
        let val = this.props.state.fName;
        return (
            <tr>
                <td>
                    <div className="form-group">
                        {/* <input type="text" id="txtDesignMosisAccNo1" name="txtDesignMosisAccNo1" value={this.state.txtDesignMosisAccNo1} onChange={event => this.setState({[this.state.txtDesignMosisAccNo1]: event.target.value})} className="form-control bg-darkgray form-control-xs" placeholder="" /> */}
                        <input type="text" id={fName} name={fName} onChange={this.props.HandleChange} value={this.props.state.txtDesignFirstName_1} className="form-control bg-darkgray form-control-xs" placeholder="" />
                    </div>
                </td>
                <td>
                    <div className="form-group">
                        <input type="text" id="txtDesignLastName_1" name="txtDesignLastName_1" value={this.props.state.txtDesignLastName_1} onChange={this.props.HandleChange} className="form-control bg-darkgray form-control-xs" placeholder="" />
                    </div>
                </td>
                <td>
                    <div className="form-group">
                        <input type="text" id="txtDesignTitle_1" name="txtDesignTitle_1" value={this.props.state.txtDesignTitle_1} onChange={this.props.HandleChange} className="form-control bg-darkgray form-control-xs" placeholder="" />
                    </div>
                </td>
                <td>
                    <div className="form-group">
                        <input type="text" id="txtDesignEmail_1" name="txtDesignEmail_1" value={this.props.state.txtDesignEmail_1} onChange={this.props.HandleChange} className="form-control bg-darkgray form-control-xs" placeholder="" />
                    </div>
                </td>
                <td>
                    <div className="form-group">
                        <input type="text" id="txtDesignTelNo_1" name="txtDesignTelNo_1" value={this.props.state.txtDesignTelNo_1} onChange={this.props.HandleChange} className="form-control bg-darkgray form-control-xs" placeholder="" />
                    </div>
                </td>
                <td>
                    <div className="form-group">
                        <span>
                            <img src={require('../../../assets/img/delete.svg')} alt="delete logo" />
                        </span>
                    </div>
                </td>
            </tr>
        );
    };


    createUI = () => {
        return this.state.values.map((el, i) =>
            <div key={i}>
                <input type="text" value={el || ''} onChange={this.handleChange.bind(this, i)} />
                <input type='button' value='remove' onClick={this.removeClick.bind(this, i)} />
            </div>
        )
    }


    handleChange = (i, event) => {
        let values = [...this.state.values];
        values[i] = event.target.value;
        this.setState({ values });
    }

    addClick = () => {
        this.setState(prevState => ({ values: [...prevState.values, ''] }))
    }

    removeClick = (i) => {
        console.log("I: "+i)
        /*let values = [...this.state.values];
        values.splice(i, 1);
        this.setState({ values });*/

        this.props.state.designEngineerDataToShow.splice(i,1)
        this.setState({designEngineerDataToShow: this.props.state.designEngineerDataToShow})


    }

    handleSubmit = (event) => {
        alert('A name was submitted: ' + this.state.values.join(', '));
        event.preventDefault();
    }



    render() {
        // alert(this.props.state.txtDesignFirstName_2)
        // let NewInputText = () => '';
        // if (this.state.AddNewDesignRow) {
        //     NewInputText = () => this.makeTextInput(this.state.NoOfDesignRow);
        // }
        return (
            <div>
                <div id="page-wrapper">
                    <div id="page-inner" style={{ background: '#fff' }}>
                        <div className="page_head col-xl-8 mx-auto">
                            <h1 className="page-header">Account Management</h1>
                            <div className="account">
                                <div className="header">
                                    <h3>Organizational Information</h3>
                                </div>
                                <div className="accoutn_form">
                                    <form action="" method="">

                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-3 col-md-3 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Organization Name (Legal)</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-9 col-md-9 col-sm-6">
                                                                    <div className="inner_right">
                                                                        <div className="form-group new_pos">
                                                                            <input type="text" id="txtOrgName" name="txtOrgName" value={this.props.state.txtOrgName} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtOrgName', this.props.state.txtOrgName, 'an Organization name')} className="form-control form-control-xs" placeholder="" disabled={true} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Website (URL)</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="inner_right">
                                                                        <div className="form-group new_pos">
                                                                            <input type="text" id="txtWebUrl" name="txtWebUrl" value={this.props.state.txtWebUrl} onChange={this.props.HandleChange} className="form-control form-control-xs" placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Customer Account Number</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="inner_right">
                                                                        <div className="form-group new_pos">
                                                                            <input type="text" id="txtCustAccNo" name="txtCustAccNo" defaultValue={this.props.state.txtCustAccNo} disabled className="form-control bg-darkgray form-control-xs" placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className="inner_right">
                                                                        <div className="form-group">
                                                                            <div className="custom_select">
                                                                                <select id="ddlCustAccNo" name="ddlCustAccNo" value={this.props.state.ddlCustAccNo} onChange={this.props.HandleChange}>
                                                                                    <option>Select</option>
                                                                                    <option>10682</option>
                                                                                    <option>10683</option>
                                                                                    <option>10684</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Federal Taxpayer ID Number (TIN)</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="inner_right">
                                                                        <div className="form-group new_pos">
                                                                            <input type="text" id="txtFederalTaxIdNo" name="txtFederalTaxIdNo" value={this.props.state.txtFederalTaxIdNo} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtFederalTaxIdNo', this.props.state.txtFederalTaxIdNo, 'TIN')} className="form-control form-control-xs" placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row new_pos">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Account Type</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="row">
                                                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <div className="custom_select">
                                                                                        <select id="ddlCustAccType" name="ddlCustAccType" value={this.props.state.ddlCustAccType} onChange={this.props.HandleChange}>
                                                                                            <option defaultValue hidden>Select</option>
                                                                                            {(JSON.stringify(this.props.accounttypes) !== '{}' && this.props.accounttypes.length > 0) ? this.props.accounttypes.map((item, index) =>
                                                                                                <option key={index} value={item.codeValue}>{item.codeName}</option>
                                                                                            ) : ''}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}

                                                    {/* <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row new_pos">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Priority</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="row">
                                                                        <div className="col-lg-4 col-md-3 col-sm-12">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <div className="custom_select">
                                                                                        <select id="ddlPriprity" name="ddlPriprity" value={this.props.state.ddlPriprity} onChange={this.props.HandleChange}>
                                                                                            <option defaultValue hidden>Select</option>

                                                                                            {(JSON.stringify(this.props.priority) !== '{}' && this.props.priority.length > 0) ? this.props.priority.map((item, index) =>
                                                                                                <option key={index} value={item.codeValue}>{item.codeName}</option>
                                                                                            ) : ''}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}

                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Account Start Date</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="form-group new_pos">
                                                                            <div className="form-group" id="startDate">
                                                                                <input type="text" id="txtAccStartDate" name="txtAccStartDate" className="form-control bg-darkgray form-control-xs" value={this.props.state.txtAccStartDate} placeholder="" disabled={true}/>
                                                                            </div>
                                                                            {/*<DatePicker name="txtAccStartDate"
                                                                                        id="txtAccStartDate"
                                                                                format="MM/dd/y"
                                                                                onChange={this.props.HandleAccStartDateChange}
                                                                                onBlur={() => this.props.HandleOnBlur('ForceDate', 'txtAccStartDate', this.props.state.txtAccStartDate, 'Account Start Date')}
                                                                                value={this.props.state.txtAccStartDate} clearIcon={null}
                                                                                calendarIcon={<img src={require('../../../assets/images/calendar.svg')} alt="calendar" width={14} />}
                                                                                        enabled={true}
                                                                                />*/}

                                                                        {/* <div className="input-group date form_date" data-date="" data-date-format="dd MM yyyy" data-link-field="dtp_input2" data-link-format="yyyy-mm-dd">
                                                                            <input className="form-control dt" type="text" id="txtAccStartDate" name="txtAccStartDate" value={this.props.state.txtAccStartDate} onChange={this.props.HandleChange} placeholder="Expected Design submission date" />
                                                                            <span className="input-group-addon calendar_icon"><span className="glyphicon-calendar">
                                                                                <img src={require('../../../assets/img/calendar.svg')} alt="logo" />
                                                                            </span></span>
                                                                        </div>
                                                                        <input type="hidden" id="dtp_input2" value="" /> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="account">
                                <div className="header">
                                    <h3>Account Manager</h3>
                                </div>
                                <div className="accoutn_form">
                                    <form action="" method="">
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>First Name</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="inner_right">
                                                                        <div className="form-group new_pos" id="manager">
                                                                            <input type="text" id="txtFirstName" name="txtFirstName" value={this.props.state.txtFirstName} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtFirstName', this.props.state.txtFirstName, 'first name')} className="form-control form-control-xs" placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Last Name</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="inner_right">
                                                                        <div className="form-group new_pos" id="manager">
                                                                            <input type="text" id="txtLastName" name="txtLastName" value={this.props.state.txtLastName} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtLastName', this.props.state.txtLastName, 'last name')} className="form-control form-control-xs" placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Title</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="inner_right">
                                                                        <div className="form-group new_pos" id="manager">
                                                                            <input type="text" id="txtTitle" name="txtTitle" value={this.props.state.txtTitle} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtTitle', this.props.state.txtTitle, 'title')} className="form-control form-control-xs" placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Email (Fully qualified)</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="inner_right">
                                                                        <div className="form-group new_pos" id="manager">
                                                                            <input type="email" id="txtEmail" name="txtEmail" value={this.props.state.txtEmail} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceEmail', 'txtEmail', this.props.state.txtEmail, 'email')} className="form-control form-control-xs" placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Telephone Number</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="inner_right">
                                                                        <div className="form-group new_pos" id="manager">
                                                                            <input type="text" id="txtTelNo" name="txtTelNo" value={this.props.state.txtTelNo} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtTelNo', this.props.state.txtTelNo, 'telephone number')} className="form-control form-control-xs" placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="account">
                                <div className="header">
                                    <h3>Authorized Official for Signing Legal Documents</h3>
                                </div>
                                <div className="accoutn_form">
                                    <form action="" method="">
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row" id="official">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>First Name</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="inner_right">
                                                                        <div className="form-group new_pos" id="official">
                                                                            <input type="text" id="txtOfficialFirstName" name="txtOfficialFirstName" value={this.props.state.txtOfficialFirstName} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtOfficialFirstName', this.props.state.txtOfficialFirstName, 'first name')} className="form-control form-control-xs" placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Last Name</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="inner_right">
                                                                        <div className="form-group new_pos" id="official">
                                                                            <input type="text" id="txtOfficialLastName" name="txtOfficialLastName" value={this.props.state.txtOfficialLastName} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtOfficialLastName', this.props.state.txtOfficialLastName, 'last name')} className="form-control form-control-xs" placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Title</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="inner_right new_pos">
                                                                        <div className="form-group" id="official">
                                                                            <input type="text" id="txtOfficialTitle" name="txtOfficialTitle" value={this.props.state.txtOfficialTitle} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtOfficialTitle', this.props.state.txtOfficialTitle, 'title')} className="form-control form-control-xs" placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Email (Fully qualified)</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="inner_right">
                                                                        <div className="form-group new_pos" id="official">
                                                                            <input type="email" id="txtOfficialEmail" name="txtOfficialEmail" value={this.props.state.txtOfficialEmail} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceEmail', 'txtOfficialEmail', this.props.state.txtOfficialEmail, 'email')} className="form-control form-control-xs" placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Telephone Number</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="inner_right">
                                                                        <div className="form-group new_pos" id="official">
                                                                            <input type="text" id="txtOfficialTelNo" name="txtOfficialTelNo" value={this.props.state.txtOfficialTelNo} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtOfficialTelNo', this.props.state.txtOfficialTelNo, 'telephone number')} className="form-control form-control-xs" placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="account">
                                <div className="header">
                                    <h3>Authorized Official for Certifying Export Documents</h3>
                                </div>
                                <div className="accoutn_form">
                                    <form action="" method="">
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>First Name</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="inner_right">
                                                                        <div className="form-group new_pos" id="OfficialCert">
                                                                            <input type="text" id="txtOfficialCertFirstName" name="txtOfficialCertFirstName" value={this.props.state.txtOfficialCertFirstName} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtOfficialCertFirstName', this.props.state.txtOfficialCertFirstName, 'first name')} className="form-control form-control-xs" placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Last Name</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="inner_right">
                                                                        <div className="form-group new_pos" id="OfficialCert">
                                                                            <input type="text" id="txtOfficialCertLastName" name="txtOfficialCertLastName" value={this.props.state.txtOfficialCertLastName} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtOfficialCertLastName', this.props.state.txtOfficialCertLastName, 'last name')} className="form-control form-control-xs" placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Title</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="inner_right">
                                                                        <div className="form-group new_pos" id="OfficialCert">
                                                                            <input type="text" id="txtOfficialCertTitle" name="txtOfficialCertTitle" value={this.props.state.txtOfficialCertTitle} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtOfficialCertTitle', this.props.state.txtOfficialCertTitle, 'title')} className="form-control form-control-xs" placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Email (Fully qualified)</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="inner_right">
                                                                        <div className="form-group new_pos" id="OfficialCert">
                                                                            <input type="email" id="txtOfficialCertEmail" name="txtOfficialCertEmail" value={this.props.state.txtOfficialCertEmail} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceEmail', 'txtOfficialCertEmail', this.props.state.txtOfficialCertEmail, 'email')} className="form-control form-control-xs" placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Telephone Number</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="inner_right">
                                                                        <div className="form-group new_pos" id="OfficialCert">
                                                                            <input type="text" id="txtOfficialCertTelNo" name="txtOfficialCertTelNo" value={this.props.state.txtOfficialCertTelNo} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtOfficialCertTelNo', this.props.state.txtOfficialCertTelNo, 'telephone number')} className="form-control form-control-xs" placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="account">
                                <div className="header">
                                    <h3>Design Manager &nbsp;
                                        <span style={{ float: 'right' }} onClick={() => this.props.AddNewRows(this)}>
                                            {/* <span style={{ float: 'right' }} onClick={()=>this.props.AddNewRows(this)}> */}
                                            <img src={require('../../../assets/img/plus.svg')} alt="plus logo" />
                                        </span>
                                    </h3>
                                </div>
                                <div className="accoutn_form">
                                    <form action="" method="">
                                        <div style={{ overflowX: 'scroll' }}>
                                            <table width="100%" id="idDesignMgr">
                                                <tbody>
                                                    <tr>
                                                        {/* <th>MOSIS Account Number</th> */}
                                                        <th>First Name</th>
                                                        <th>Last Name</th>
                                                        <th>Title</th>
                                                        <th>Email (Fully qualified) </th>
                                                        <th>Telephone Number</th>
                                                        {/* <th>Status</th> */}
                                                        <th></th>
                                                    </tr>


                                                    {this.props.state.designEngineerDataToShow.length > 0?
                                                        this.props.state.designEngineerDataToShow.map((el, i) =>(
                                                        <tr key={i} id={'trDesignManager_default_' + i}>
                                                            {/* {alert(JSON.stringify(el))} */}
                                                            {/* {alert(JSON.stringify(el['txtDesignFirstName_'+i]))} */}
                                                            <td>
                                                                <div className="form-group" id="designManager">
                                                                    {/* <input type="text" id={'txtDesignFirstName_'+i} name={'txtDesignFirstName_'+i} value={el||''} onChange={this.HandleMultiChange.bind(this, i)} className="form-control form-control-xs" /> */}
                                                                    <input type="text" id={'txtDesignFirstName_default_' + i} name={'txtDesignFirstName_default_' + i} value={el["firstName"]} onChange={this.props.HandleRowValChange} className="form-control form-control-xs" />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="form-group" id="designManager">
                                                                    {/* <input type="text" id={'txtDesignLastName_'+i} name={'txtDesignLastName_'+i} onChange={this.HandleMultiChange.bind(this, i)} className="form-control form-control-xs" /> */}
                                                                    <input type="text" id={'txtDesignLastName_default_' + i} name={'txtDesignLastName_default_' + i} value={el["lastName"]} onChange={this.props.HandleRowValChange} className="form-control form-control-xs" />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="form-group" id="designManager">
                                                                    {/* <input type="text" id={'txtDesignLastName_'+i} name={'txtDesignLastName_'+i} onChange={this.HandleMultiChange.bind(this, i)} className="form-control form-control-xs" /> */}
                                                                    <input type="text" id={'txtDesignTitle_default_' + i} name={'txtDesignTitle_default_' + i} value={el["title"]} onChange={this.props.HandleRowValChange} className="form-control form-control-xs" />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="form-group" id="designManager">
                                                                    {/* <input type="text" id={'txtDesignLastName_'+i} name={'txtDesignLastName_'+i} onChange={this.HandleMultiChange.bind(this, i)} className="form-control form-control-xs" /> */}
                                                                    <input type="text" id={'txtDesignEmail_default_' + i} name={'txtDesignEmail_default_' + i} value={el["email"]} onChange={(e) => this.props.HandleRowValChange(e)}
                                                                           onBlur={() => this.props.HandleOnBlur('ForceEmail', 'txtDesignEmail_default_' + i, el["email"], 'email')} className="form-control form-control-xs" />
                                                                </div>
                                                                {this.props.state.validationMessgae["txtDesignEmail_default_"+i] == true?
                                                                    <label id={"designErrorDefault_"+i} style={{fontStyle: "italic", color: "red", fontSize: "12px"}}>Please Enter Correct Email</label>:''
                                                                }
                                                            </td>
                                                            <td>
                                                                <div className="form-group" id="designManager">
                                                                    {/* <input type="text" id={'txtDesignLastName_'+i} name={'txtDesignLastName_'+i} onChange={this.HandleMultiChange.bind(this, i)} className="form-control form-control-xs" /> */}
                                                                    <input type="text" id={'txtDesignTelNo_default_' + i} name={'txtDesignTelNo_default_' + i} value={el["phoneNumber"]} onChange={(e) => this.props.HandleRowValChange(e)} className="form-control form-control-xs" />
                                                                </div>
                                                                {this.props.state.validationMessgae["txtDesignTelNo_default_"+i] == true?
                                                                <label id={"designErrorDefault_"+i} style={{fontStyle: "italic", color: "red", fontSize: "12px"}}>Please Enter Correct Phone</label>:''
                                                                }
                                                            </td>
                                                            <td>
                                                                <div className="form-group">
                                                                <span>
                                                                    <img src={require("../../../assets/img/delete.svg")} alt="delete logo" onClick={this.removeClick.bind(this, i)} />
                                                                </span>
                                                                    {/* <input type='button' value='remove' onClick={this.removeClick.bind(this, i)}/> */}
                                                                </div>
                                                            </td>

                                                        </tr>

                                                        )):''}

                                                    {this.props.createUI()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="account">
                                <div className="header">
                                    <h3>Legal Organization Physical Address</h3>
                                </div>
                                <div className="accoutn_form">
                                    <form action="" method="">
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">

                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Address Line 1</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="inner_right">
                                                                        <div className="form-group new_pos" id="physicalAddress">
                                                                            <input type="text" id="txtPhysicalAddr1" name="txtPhysicalAddr1" value={this.props.state.txtPhysicalAddr1} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtPhysicalAddr1', this.props.state.txtPhysicalAddr1, 'address')} className="form-control form-control-xs" placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Address Line 2</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="inner_right">
                                                                        <div className="form-group new_pos" id="physicalAddress">
                                                                            <input type="text" id="txtPhysicalAddr2" name="txtPhysicalAddr2" value={this.props.state.txtPhysicalAddr2} onChange={this.props.HandleChange} className="form-control form-control-xs" placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>City</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="inner_right">
                                                                        <div className="form-group new_pos" id="physicalAddress">
                                                                            <input type="text" id="txtPhysicalCity" name="txtPhysicalCity" value={this.props.state.txtPhysicalCity} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtPhysicalCity', this.props.state.txtPhysicalCity, 'city')} className="form-control form-control-xs" placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>State / Province</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="inner_right">
                                                                        <div className="form-group new_pos" id="physicalAddress">
                                                                            <input type="text" id="txtPhysicalState" name="txtPhysicalState" value={this.props.state.txtPhysicalState} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtPhysicalState', this.props.state.txtPhysicalState, 'state')} className="form-control form-control-xs" placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Zip Code / Postal Code</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="inner_right">
                                                                        <div className="form-group new_pos" id="physicalAddress">
                                                                            <input type="text" id="txtPhysicalZip" name="txtPhysicalZip" value={this.props.state.txtPhysicalZip} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtPhysicalZip', this.props.state.txtPhysicalZip, 'zip')} className="form-control form-control-xs" placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <div className="brd_section border-none">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Country</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="row">
                                                                        <div className="col-lg-7 col-md-7 col-sm-12">
                                                                            <div className="inner_right">
                                                                                <div className="form-group new_pos">
                                                                                    <div className="custom_select" id="physicalAddress">
                                                                                        <select id="ddlPhysicalCountry" name="ddlPhysicalCountry" value={this.props.state.ddlPhysicalCountry} onChange={this.props.HandleChange}>

                                                                                            <option defaultValue value=''>Select</option>
                                                                                            {(JSON.stringify(this.props.country) !== '{}' && this.props.countries.length > 0) ? this.props.countries.map((item, index) =>
                                                                                                <option key={index} value={item.codeId}>{item.codeName}</option>
                                                                                            ) : ''}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="account">
                                <div className="header">
                                    <h3>Legal Organization Mailing Address</h3>
                                </div>
                                <div className="accoutn_form">
                                    <form action="" method="">
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Is Mailing Address same as Physical Address?</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="row">
                                                                    <div className="col-lg-5 col-md-5 col-sm-12">
                                                                            <div className="inner_right">
                                                                                <div className="form-group new_pos">
                                                                                    <div className="custom_select">
                                                                                        <select id="ddlMailingAsPhysical" name="ddlMailingAsPhysical" value={this.props.state.ddlMailingAsPhysical} onChange={this.props.HandleChange}>
                                                                                            <option defaultValue hidden>Select</option>
                                                                                            <option value="Y">Yes</option>
                                                                                            <option value="N">No</option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {this.props.state.ddlMailingAsPhysical === 'N' ?
                                                        <div style={{ width: '100%' }}>
                                                            <div className="col-lg-12">
                                                                <div className="brd_section">
                                                                    <div className="row">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>Address Line 1</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="inner_right">
                                                                                <div className="form-group new_pos" id="mailingAddress">
                                                                                    <input type="text" id="txtMailingAddr1" name="txtMailingAddr1" value={this.props.state.txtMailingAddr1} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtMailingAddr1', this.props.state.txtMailingAddr1, 'address')} onChange={this.props.HandleChange} className="form-control form-control-xs" placeholder="" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12">
                                                                <div className="brd_section">
                                                                    <div className="row">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>Address Line 2</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="inner_right">
                                                                                <div className="form-group new_pos" id="mailingAddress">
                                                                                    <input type="text" id="txtMailingAddr2" name="txtMailingAddr2" value={this.props.state.txtMailingAddr2} onChange={this.props.HandleChange} className="form-control form-control-xs" placeholder="" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12">
                                                                <div className="brd_section">
                                                                    <div className="row">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>City</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="inner_right">
                                                                                <div className="form-group new_pos" id="mailingAddress">
                                                                                    <input type="text" id="txtMailingCity" name="txtMailingCity" value={this.props.state.txtMailingCity} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtMailingCity', this.props.state.txtMailingCity, 'city')} className="form-control form-control-xs" placeholder="" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12">
                                                                <div className="brd_section">
                                                                    <div className="row">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>State / Province</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="inner_right">
                                                                                <div className="form-group new_pos" id="mailingAddress">
                                                                                    <input type="text" id="txtMailingState" name="txtMailingState" value={this.props.state.txtMailingState} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtMailingState', this.props.state.txtMailingState, 'state')} className="form-control form-control-xs" placeholder="" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12">
                                                                <div className="brd_section">
                                                                    <div className="row">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>Zip Code / Postal Code</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="inner_right">
                                                                                <div className="form-group new_pos" id="mailingAddress">
                                                                                    <input type="text" id="txtMailingZip" name="txtMailingZip" value={this.props.state.txtMailingZip} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtMailingZip', this.props.state.txtMailingZip, 'zip')} className="form-control form-control-xs" placeholder="" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12">
                                                                <div className="brd_section border-none">
                                                                    <div className="row">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>Country</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="row">
                                                                                <div className="col-lg-7 col-md-7 col-sm-12">
                                                                                    <div className="inner_right">
                                                                                        <div className="form-group new_pos">
                                                                                            <div className="custom_select" id="mailingAddress">
                                                                                                <select id="ddlMailingCountry" name="ddlMailingCountry" value={this.props.state.ddlMailingCountry} onChange={this.props.HandleChange}>
                                                                                                    <option defaultValue value=''>Select</option>
                                                                                                    {(JSON.stringify(this.props.countries) !== '{}' && this.props.countries.length > 0) ? this.props.countries.map((item, index) =>
                                                                                                        <option key={index} value={item.codeId}>{item.codeName}</option>
                                                                                                    ) : ''}
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="account">
                                <div className="header">
                                    <h3>Legal Organization Shipping Address</h3>
                                </div>
                                <div className="accoutn_form">
                                    <form action="" method="">
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Is Shipping Address same as Physical Address?</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="row">
                                                                    <div className="col-lg-5 col-md-5 col-sm-12">
                                                                            <div className="inner_right">
                                                                                <div className="form-group new_pos">
                                                                                    <div className="custom_select">
                                                                                        <select id="ddlShippingAsPhysical" name="ddlShippingAsPhysical" value={this.props.state.ddlShippingAsPhysical} onChange={this.props.HandleChange}>
                                                                                            <option defaultValue hidden>Select</option>
                                                                                            <option value="Y">Yes</option>
                                                                                            <option value="N">No</option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {this.props.state.ddlShippingAsPhysical === 'N' ?
                                                        <div style={{ width: '100%' }}>
                                                            <div className="col-lg-12">
                                                                <div className="brd_section">
                                                                    <div className="row">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>Address Line 1</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="inner_right">
                                                                                <div className="form-group new_pos" id="shippingAddress">
                                                                                    <input type="text" id="txtShippingAddr1" name="txtShippingAddr1" value={this.props.state.txtShippingAddr1} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtShippingAddr1', this.props.state.txtShippingAddr1, 'address')} onChange={this.props.HandleChange} className="form-control form-control-xs" placeholder="" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12">
                                                                <div className="brd_section">
                                                                    <div className="row">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>Address Line 2</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="inner_right">
                                                                                <div className="form-group new_pos" id="shippingAddress">
                                                                                    <input type="text" id="txtShippingAddr2" name="txtShippingAddr2" value={this.props.state.txtShippingAddr2} onChange={this.props.HandleChange} className="form-control form-control-xs" placeholder="" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12">
                                                                <div className="brd_section">
                                                                    <div className="row">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>City</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="inner_right">
                                                                                <div className="form-group new_pos" id="shippingAddress">
                                                                                    <input type="text" id="txtShippingCity" name="txtShippingCity" value={this.props.state.txtShippingCity} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtShippingCity', this.props.state.txtShippingCity, 'city')} className="form-control form-control-xs" placeholder="" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12">
                                                                <div className="brd_section">
                                                                    <div className="row">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>State / Province</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="inner_right">
                                                                                <div className="form-group new_pos" id="shippingAddress">
                                                                                    <input type="text" id="txtShippingState" name="txtShippingState" value={this.props.state.txtShippingState} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtShippingState', this.props.state.txtShippingState, 'state')} className="form-control form-control-xs" placeholder="" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12">
                                                                <div className="brd_section">
                                                                    <div className="row">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>Zip Code / Postal Code</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="inner_right">
                                                                                <div className="form-group new_pos" id="shippingAddress">
                                                                                    <input type="text" id="txtShippingZip" name="txtShippingZip" value={this.props.state.txtShippingZip} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtShippingZip', this.props.state.txtShippingZip, 'zip')} className="form-control form-control-xs" placeholder="" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12">
                                                                <div className="brd_section border-none">
                                                                    <div className="row">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>Country</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="row">
                                                                                <div className="col-lg-7 col-md-7 col-sm-12">
                                                                                    <div className="inner_right">
                                                                                        <div className="form-group new_pos">
                                                                                            <div className="custom_select" id="shippingAddress">
                                                                                                <select id="ddlShippingCountry" name="ddlShippingCountry" value={this.props.state.ddlShippingCountry} onChange={this.props.HandleChange}>
                                                                                                    <option defaultValue value=''>Select</option>
                                                                                                    {(JSON.stringify(this.props.countries) !== '{}' && this.props.countries.length > 0) ? this.props.countries.map(item =>
                                                                                                        <option value={item.codeId}>{item.codeName}</option>
                                                                                                    ) : ''}
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="account">
                                <div className="header">
                                    <h3>Paying Organization</h3>
                                </div>
                                <div className="accoutn_form">
                                    <form action="" method="">
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Will Payments be made by another Legal entity (Please exclude bank and financial instruction)</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="row">
                                                                    <div className="col-lg-5 col-md-5 col-sm-12">
                                                                            <div className="inner_right">
                                                                                <div className="form-group new_pos">
                                                                                    <div className="custom_select">
                                                                                        <select id="ddlPayingOrgOption" name="ddlPayingOrgOption" value={this.props.state.ddlPayingOrgOption} onChange={this.props.HandleChange}>
                                                                                            <option value="Yes">Yes</option>
                                                                                            <option value="No">No</option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {this.props.state.ddlPayingOrgOption === 'Yes' ?
                                                        <div style={{ width: '100%' }}>
                                                            <div className="col-lg-12">
                                                                <div className="brd_section">
                                                                    <div className="row">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>Organization Name (Legal)</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="inner_right">
                                                                                <div className="form-group new_pos">
                                                                                    <input type="text" id="txtPayingOrgName" name="txtPayingOrgName" value={this.props.state.txtPayingOrgName} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtPayingOrgName', this.props.state.txtPayingOrgName, 'an Organization name')} onChange={this.props.HandleChange} className="form-control form-control-xs" placeholder="" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12">
                                                                <div className="brd_section">
                                                                    <div className="row">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>Website Url</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="inner_right">
                                                                                <div className="form-group new_pos">
                                                                                    {/*onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtPayingWebUrl', this.props.state.txtPayingWebUrl, 'url')}*/}
                                                                                    <input type="text" id="txtPayingWebUrl" name="txtPayingWebUrl" value={this.props.state.txtPayingWebUrl}  onChange={this.props.HandleChange} className="form-control form-control-xs" placeholder="" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 header">
                                                                <h4>Physical Address - Paying Organzation</h4>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="brd_section">
                                                                    <div className="row">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>Address Line 1</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="inner_right">
                                                                                <div className="form-group new_pos">
                                                                                    <input type="text" id="txtPayingAddr1" name="txtPayingAddr1" value={this.props.state.txtPayingAddr1} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtPayingAddr1', this.props.state.txtPayingAddr1, 'address')} className="form-control form-control-xs" placeholder="" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12">
                                                                <div className="brd_section">
                                                                    <div className="row">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>Address Line 2</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="inner_right">
                                                                                <div className="form-group new_pos">
                                                                                    <input type="text" id="txtPayingAddr2" name="txtPayingAddr2" value={this.props.state.txtPayingAddr2} onChange={this.props.HandleChange} className="form-control form-control-xs" placeholder="" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12">
                                                                <div className="brd_section">
                                                                    <div className="row">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>City</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="inner_right">
                                                                                <div className="form-group new_pos">
                                                                                    <input type="text" id="txtPayingCity" name="txtPayingCity" value={this.props.state.txtPayingCity} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtPayingCity', this.props.state.txtPayingCity, 'city')} className="form-control form-control-xs" placeholder="" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12">
                                                                <div className="brd_section">
                                                                    <div className="row">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>State/Province</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="inner_right">
                                                                                <div className="form-group new_pos">
                                                                                    <input type="text" id="txtPayingState" name="txtPayingState" value={this.props.state.txtPayingState} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtPayingState', this.props.state.txtPayingState, 'state')} className="form-control form-control-xs" placeholder="" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12">
                                                                <div className="brd_section">
                                                                    <div className="row">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>Zip Code/Postal Code</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="inner_right">
                                                                                <div className="form-group new_pos">
                                                                                    <input type="text" id="txtPayingZip" name="txtPayingZip" value={this.props.state.txtPayingZip} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtPayingZip', this.props.state.txtPayingZip, 'zip')} className="form-control form-control-xs" placeholder="" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12">
                                                                <div className="brd_section border-none">
                                                                    <div className="row">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>Country</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="row">
                                                                                <div className="col-lg-7 col-md-7 col-sm-12">
                                                                                    <div className="inner_right">
                                                                                        <div className="form-group new_pos">
                                                                                            <div className="custom_select">
                                                                                                <select id="ddlPayingCountry" name="ddlPayingCountry" value={this.props.state.ddlPayingCountry} onChange={this.props.HandleChange}>
                                                                                                    <option defaultValue value=''>Select</option>
                                                                                                    {(JSON.stringify(this.props.countries) !== '{}' && this.props.countries.length > 0) ? this.props.countries.map((item, index) =>
                                                                                                        <option key={index} value={item.codeId}>{item.codeName}</option>
                                                                                                    ) : ''}
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="account">

                                <div className="header">
                                    <h3>Invoicing Method &nbsp;
                                    {this.props.state.showPlusButton ? <span style={{ float: 'right' }} onClick={this.props.HandlePlusButton}>
                                            <img src={require('../../../assets/img/plus.svg')} alt="plus logo" />
                                        </span> : ''}
                                    </h3>

                                </div>
                                <div className="accoutn_form">
                                    <form action="" method="">
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    {/* {this.props.state.ddlInvoicingMethodSelected.includes('Mail') ?
                                                        <div style={{ width: '100%' }}>
                                                            <div className="col-lg-12">
                                                                <div className="brd_section">
                                                                    <div className="row new_pos">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>Address Line 1</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <input type="text" id="txtInvAddr1" name="txtInvAddr1" value={this.props.state.txtInvAddr1} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtInvAddr1', this.props.state.txtInvAddr1, 'an address')} onChange={this.props.HandleChange} className="form-control form-control-xs" placeholder="" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12">
                                                                <div className="brd_section">
                                                                    <div className="row new_pos">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>Address Line 2</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <input type="text" id="txtInvAddr2" name="txtInvAddr2" value={this.props.state.txtInvAddr2} onChange={this.props.HandleChange} className="form-control form-control-xs" placeholder="" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12">
                                                                <div className="brd_section">
                                                                    <div className="row new_pos">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>City</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <input type="text" id="txtInvCity" name="txtInvCity" value={this.props.state.txtInvCity} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtInvCity', this.props.state.txtInvCity, 'a city')} onChange={this.props.HandleChange} className="form-control form-control-xs" placeholder="" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12">
                                                                <div className="brd_section">
                                                                    <div className="row new_pos">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>State/Province</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <input type="text" id="txtInvState" name="txtInvState" value={this.props.state.txtInvState} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtInvState', this.props.state.txtInvState, 'state')} onChange={this.props.HandleChange} className="form-control form-control-xs" placeholder="" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12">
                                                                <div className="brd_section">
                                                                    <div className="row new_pos">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>Zip Code/Postal Code</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <input type="text" id="txtInvZip" name="txtInvZip" value={this.props.state.txtInvZip} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtInvZip', this.props.state.txtInvZip, 'zip')} onChange={this.props.HandleChange} className="form-control form-control-xs" placeholder="" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12">
                                                                <div className="brd_section border-none">
                                                                    <div className="row new_pos">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>Country</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="row">
                                                                                <div className="col-lg-3 col-md-4 col-sm-12">
                                                                                    <div className="inner_right">
                                                                                        <div className="form-group">
                                                                                            <div className="custom_select">
                                                                                                <select id="ddlInvCountry" name="ddlInvCountry" value={this.props.state.ddlInvCountry} onChange={this.props.HandleChange}>
                                                                                                    {(JSON.stringify(this.props.countries) !== '{}' && this.props.countries.length > 0) ? this.props.countries.map(item =>
                                                                                                        <option value={item.codeId}>{item.codeName}</option>
                                                                                                    ) : ''}
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : ''}

                                                    {this.props.state.ddlInvoicingMethodSelected.includes('Email') ?

                                                        <div>
                                                        <div style={{ width: '100%' }}>
                                                            <div className="col-lg-12">
                                                                <div className="brd_section">
                                                                    <div className="row new_pos">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>Email Address</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <input type="email" id="txtInvEmail" name="txtInvEmail" value={this.props.state.txtInvEmail} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtInvEmail', this.props.state.txtInvEmail, 'email')} onChange={this.props.HandleChange} className="form-control form-control-xs" placeholder="" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                        : ''}
                                                    {this.props.state.ddlInvoicingMethodSelected.includes('Fax') ?

                                                        <div style={{ width: '100%' }}>
                                                            <div className="col-lg-12">
                                                                <div className="brd_section">
                                                                    <div className="row new_pos">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>Fax Number</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <input type="email" id="txtInvFaxNumber" name="txtInvFaxNumber" value={this.props.state.txtInvFaxNumber} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtInvFaxNumber', this.props.state.txtInvFaxNumber, 'fax')} onChange={this.props.HandleChange} className="form-control form-control-xs" placeholder="" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="brd_section">
                                                                    <div className="row new_pos">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>Fax Attention Name</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <input type="email" id="txtInvFaxAttentionName" name="txtInvFaxAttentionName" value={this.props.state.txtInvFaxAttentionName} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtInvFaxAttentionName', this.props.state.txtInvFaxAttentionName, 'fax attention name')} onChange={this.props.HandleChange} className="form-control form-control-xs" placeholder="" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : ''}
                                                    {this.props.state.ddlInvoicingMethodSelected.includes('System') ?


                                                        <div style={{ width: '100%' }}>
                                                            <div className="col-lg-12">
                                                                <div className="brd_section">
                                                                    <div className="row new_pos">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>System Name</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <input type="email" id="txtInvSystemName" name="txtInvSystemName" value={this.props.state.txtInvSystemName} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtInvSystemName', this.props.state.txtInvSystemName, 'system name')} onChange={this.props.HandleChange} className="form-control form-control-xs" placeholder="" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="brd_section">
                                                                    <div className="row new_pos">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>System Url</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <input type="email" id="txtInvSystemUrl" name="txtInvSystemUrl" value={this.props.state.txtInvSystemUrl} onChange={this.props.HandleChange} className="form-control form-control-xs" placeholder="" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : ''} */}
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row new_pos" style={{ overflowY: 'auto', height: 'auto' }}>
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Invoicing Method </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12 col-md-12 col-sm-12" style={{marginTop: '-35px'}}>
                                                                    <div className="row">
                                                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    {/* <div className="form-group clearfix"> */}
                                                                                    {/* <div className="custom_select" style={{ width: '90% !important', float: 'left', position: 'relative' }}> */}
                                                                                    <div>
                                                                                        {/* {this.props.state.buttonClickCount == 0 ?
                                                                                            <select id="ddlInvoicingMethod" name="ddlInvoicingMethod" value={this.props.state.ddlInvoicingMethod} onChange={this.props.HandleChange}>
                                                                                                <option selected hidden>Select</option>
                                                                                                {(JSON.stringify(this.props.invoicingMethod) !== '{}' && this.props.invoicingMethod.length > 0) ? this.props.invoicingMethod.map(eachOption =>
                                                                                                    <option key={eachOption.codeId} value={eachOption.codeValue}>{eachOption.codeValue}</option>
                                                                                                ) : ''}
                                                                                            </select>
                                                                                            :
                                                                                            <select id="ddlInvoicingMethod" name="ddlInvoicingMethod" value={this.props.state.ddlInvoicingMethod} onChange={this.props.HandleChange}>
                                                                                                <option selected hidden>Select</option>
                                                                                                {(JSON.stringify(this.props.invoicingMethod) !== '{}' && this.props.invoicingMethod.length > 0) ? this.props.invoicingMethod.filter(optionNotToShow => this.props.state.ddlInvoicingMethodSelected.indexOf(optionNotToShow['codeValue']) == -1).map(eachOption =>
                                                                                                    <option key={eachOption.codeId} value={eachOption.codeValue}>{eachOption.codeValue}</option>
                                                                                                ) : ''}
                                                                                            </select>
                                                                                        } */}


                                                                                        {this.props.createUI1()}
                                                                                    </div>
                                                                                    {/* <span className="invoice">
                                                                                <img src={require('../../../assets/img/delete-white.svg')} alt="logo" />
                                                                            </span> */}
                                                                                    {/* If Email
                                                                            then
                                                                            Email Addr */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="text-center">
                            <button type="button" onClick={this.props.SubmitDesign} className="dash_submit_btn">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}