import React from 'react';
import { withRouter } from 'react-router-dom';
/*import { GA_TRACKING_ID } from '../config';*/
import ReactGA from 'react-ga';

//const trackingId = "UA-171521658-1"; // Replace with your Google Analytics tracking ID
const trackingId = "UA-171578297-1";
/*export const initGA = () =>{
    console.log("GA init")
    ReactGA.initialize('UA-171521658-1');
}*/
class GoogleAnalytics extends React.Component {
    componentWillUpdate ({ location, history }) {
        console.log("INSIDE GA ================================")
        //const gtag = window.gtag;
        ReactGA.initialize(trackingId);

        if (location.pathname === this.props.location.pathname) {
            // don't log identical link clicks (nav links likely)
            return;
        }

        if (history.action === 'PUSH') {
            console.log("INSIDE GA IF================================")
            ReactGA.set({page: window.location.pathname})
            ReactGA.pageview(window.location.pathname)

        }
    }

    render () {
        return null;
    }
}

export default withRouter(GoogleAnalytics);