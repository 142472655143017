import React, { Component } from 'react';
import FloatingLabelInput from 'react-floating-label-input';
import $ from 'jquery';
import DatePicker from 'react-date-picker'
import Multiselect from 'multiselect-react-dropdown';
import {MultiSelectComponent} from '../../HtmlComponents/MultiSelectComponent/MultiSelectComponent'

export default class DesignUpload extends Component {
    constructor(props) {
        super(props);

    }

    ClearMouse = () => {
        $('.inner_table').css('display', 'none');
    }

    render() {
        return (
            <div>

                <div id="page-wrapper">
                    <div id="page-inner" style={{ background: '#fff' }}>
                        <div className="page_head col-xl-10 col-md-11 mx-auto">
                            <h1 class="page-header">Design Upload Request</h1>
                            {/*<button onClick={this.props.checkValidationResponse}>afafasfas</button>*/}
                            <div class="step-1" style={{ display: this.props.state.showFirstStep }}>
                                <div class="account">
                                    <div class="accoutn_form">
                                        <form action="" method="">

                                            <div class="dashboard_form">
                                                <div class="inner-dashoard_form">
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <div class="brd_section">
                                                                <div class="row new_pos" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                                                                    <div class="col-lg-5 col-md-5 col-sm-6">
                                                                        <div class="inner_left">
                                                                            <ul className="nav">
                                                                                <li className="nav-item">
                                                                                    <label class="">Design Number</label>
                                                                                </li>


                                                                                <li className="nav-item" style={{marginLeft:"5%"}}>
                                                                                    {/*onClick={this.props.designNumberClick}*/}
                                                                                    {/*onClick={this.props.ShowHideModal}*/}
                                                                                    <button className="ml-1 btn btn-sm btn_sm_red" type="button"
                                                                                            onClick={this.props.ShowHideModal} disabled={this.props.state.designNumberListDisabled}>Design Number List</button>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-7 col-md-7 col-sm-6">
                                                                        <div class="inner_right">
                                                                            <div class="form-group">
                                                                                <input type="text" id="txtDesignNumber" name="txtDesignNumber" value={this.props.state.designNumberValue} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceRNumber', 'txtDesignNumber', this.props.state.txtDesignNumber, '')} className="form-control"
                                                                                       disabled = {(this.props.state.disabledBasicDetailsInput)? "disabled" : "disabled"}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="brd_section">
                                                                <div class="row new_pos" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                                                                    <div class="col-lg-5 col-md-5 col-sm-6">
                                                                        <div class="inner_left">
                                                                            <label>Foundry</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-7 col-md-7 col-sm-6">
                                                                        <div class="inner_right">
                                                                            <div class="form-group">
                                                                                <input type="text" id="txtFoundry" name="txtFoundry" value={this.props.state.txtFoundry} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtFoundry', this.props.state.txtFoundry, 'foundry')} className="form-control"
                                                                                       disabled = {(this.props.state.disabledBasicDetailsInput)? "disabled" : "disabled"}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="brd_section">
                                                                <div class="row new_pos" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                                                                    <div class="col-lg-5 col-md-5 col-sm-6">
                                                                        <div class="inner_left">
                                                                            <label>Technode</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-7 col-md-7 col-sm-6">
                                                                        <div class="inner_right">
                                                                            <div class="form-group">
                                                                                <input type="text" id="txtTechNode" name="txtTechNode" value={this.props.state.txtTechNode} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtTechNode', this.props.state.txtTechNode, 'technode')} className="form-control"
                                                                                       disabled = {(this.props.state.disabledBasicDetailsInput)? "disabled" : "disabled"}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        {/*======================================================DESIGN INFO - START==================================================*/}



                                                        <div class="col-lg-12">
                                                            <div class="header">
                                                                <h3>Design Info </h3>
                                                            </div>
                                                        </div>
                                                        {
                                                            this.props.state.parentArrValuesList.length > 0 && this.props.state.showQuestionAnswers?
                                                            this.props.state.parentArrValuesList.map((item, index) => (
                                                                item[0].designUploadQuestionSet.questionTypeObject["codeValue"] == "Design Info" ?

                                                                    <div className="col-lg-12">
                                                                        <div className="brd_section">
                                                                            <div className="row new_pos" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                                                                                <div className="col-lg-5 col-md-5 col-sm-6">
                                                                                    <div className="inner_left" value={item[0]["technologyQuestionId"]}>
                                                                                        <label>{item[0].designUploadQuestionSet["question"]}</label>
                                                                                    </div>
                                                                                </div>
                                                                                {item[0].designUploadQuestionSet.subQuestionConditionObject["codeValue"] == "Dropdown" ?
                                                                                    <div className="col-lg-7 col-md-7 col-sm-6">
                                                                                        <div className="inner_right">
                                                                                            <div className="form-group">
                                                                                                <div className="custom_select">
                                                                                                    <span>
                                                                                                        <select id={item[0]["technologyQuestionId"]}
                                                                                                            onChange={(e) => {this.props.HandleSelectChange(e, item, '669')}}
                                                                                                            value={this.props.state.selectValue[item[0]["technologyQuestionId"]]?this.props.state.selectValue[item[0]["technologyQuestionId"]]:''}
                                                                                                            disabled = {(this.props.state.disabledInput)? "disabled" : ""}>
                                                                                                        <option value=''>Select</option>
                                                                                                        {item[0].technologyAnswerSets.map((item, index) => (
                                                                                                            <option value={item["technologyAnswerId"]} disabled={(item.statusObject!=null && item.statusObject.codeValue == "Inactive")?"disabled":""}>
                                                                                                                {item.designUploadAnswerSet["answerValue"]}
                                                                                                            </option>
                                                                                                        ))
                                                                                                        }
                                                                                                        </select>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    item[0].designUploadQuestionSet.subQuestionConditionObject["codeValue"] == "Dropdown_Multiselect" ?
                                                                                        <div className="col-lg-7 col-md-7 col-sm-6">
                                                                                            <div className="inner_right">
                                                                                                <div className="form-group">
                                                                                                        <MultiSelectComponent
                                                                                                            id={item[0]["technologyQuestionId"]}
                                                                                                            onChange={this.props.onMultiSelectChange}
                                                                                                            questionId={item[0]["technologyQuestionId"]}
                                                                                                            type={'669'}
                                                                                                            selectedValue={this.props.state.selectValue[item[0]["technologyQuestionId"]]?this.props.state.selectValue[item[0]["technologyQuestionId"]]:''}

                                                                                                            isRequired={true}
                                                                                                            multiSelectOptions={item[0]}/>
                                                                                                 </div>

                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                    item[0].designUploadQuestionSet.subQuestionConditionObject["codeValue"] == "Other" ?
                                                                                    <div className="col-lg-7 col-md-7 col-sm-6">
                                                                                        <div className="inner_right">
                                                                                            <div className="form-group">
                                                                                                <div className="custom_select">
                                                                                            <span>
                                                                                                <select id={item[0]["technologyQuestionId"]} onChange={(e) => {this.props.HandleSelectChange(e, item, '669')}}
                                                                                                        name="ddlPackageType" id="ddlPackageType"
                                                                                                        value={this.props.state.selectValue[item[0]["technologyQuestionId"]]? this.props.state.selectValue[item[0]["technologyQuestionId"]] : ''}
                                                                                                        disabled = {(this.props.state.disabledInput)? "disabled" : ""}>
                                                                                            <option value='' >Select</option>
                                                                                                    {item[0].technologyAnswerSets.map((item, index) => (
                                                                                                        <option value={item["technologyAnswerId"]}>
                                                                                                            {item.designUploadAnswerSet["answerValue"]}
                                                                                                        </option>
                                                                                                    ))
                                                                                                    }
                                                                                                </select>
                                                                                            </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>:
                                                                                    item[0].designUploadQuestionSet.subQuestionConditionObject["codeValue"] == 'Fill in'?
                                                                                        <div className="col-lg-7 col-md-7 col-sm-6">
                                                                                            <div className="inner_right">
                                                                                                <div className="form-group">
                                                                                                    <input type={item[0].designUploadQuestionSet["questionDataTypeObject"]!=null?
                                                                                                        item[0].designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Integer" || item[0].designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Float"? "text":'text' :'text'}
                                                                                                           id={item[0]["technologyQuestionId"]} name="txtDesignNumber"
                                                                                                           value={this.props.state.selectValue[item[0]["technologyQuestionId"]]?this.props.state.selectValue[item[0]["technologyQuestionId"]]:''} onChange={(e) => this.props.HandleInputChange(e, item)}
                                                                                                           onBlur={(e) => this.props.HandleOnBlur('669', 'txtDesignNumber', this.props.state.selectValue[item[0]["technologyQuestionId"]], item[0]["technologyQuestionId"], item[0], e)}
                                                                                                           className="form-control" disabled = {(this.props.state.disabledInput)? "disabled" : ""}
                                                                                                           required = {item[0].designUploadQuestionSet["questionDataTypeObject"]!=null?
                                                                                                               item[0].designUploadQuestionSet.questionDataTypeObject["codeValue"] == "String_AllowBlank" || item[0].designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Integer_AllowBlank"
                                                                                                               || item[0].designUploadQuestionSet.questionDataTypeObject["codeValue"] == "ReadOnly"? "" : "required":"required"}
                                                                                                           disabled={item[0].designUploadQuestionSet["questionDataTypeObject"]!=null?item[0].designUploadQuestionSet.questionDataTypeObject["codeValue"] == "ReadOnly"?true:false:false}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>:
                                                                                        item[0].designUploadQuestionSet.subQuestionConditionObject["codeValue"] == '>0'?
                                                                                            <div className="col-lg-7 col-md-7 col-sm-6">
                                                                                                <div className="inner_right">
                                                                                                    <div className="form-group">
                                                                                                        <input type={item[0].designUploadQuestionSet["questionDataTypeObject"]!=null?
                                                                                                            item[0].designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Integer" || item[0].designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Float"? "text":'text' :'text'}
                                                                                                               id={item[0]["technologyQuestionId"]} name="txtDesignNumber"
                                                                                                               value={this.props.state.selectValue[item[0]["technologyQuestionId"]]?this.props.state.selectValue[item[0]["technologyQuestionId"]]:''} onChange={(e) => this.props.HandleInputChange(e, item)}
                                                                                                               onBlur={(e) => this.props.HandleOnBlur('669', 'txtDesignNumber', this.props.state.selectValue[item[0]["technologyQuestionId"]], item[0]["technologyQuestionId"], item[0], e)}
                                                                                                               className="form-control" disabled = {(this.props.state.disabledInput)? "disabled" : ""}
                                                                                                               required = {item[0].designUploadQuestionSet["questionDataTypeObject"]!=null?
                                                                                                                   item[0].designUploadQuestionSet.questionDataTypeObject["codeValue"] == "String_AllowBlank" || item[0].designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Integer_AllowBlank"? "" : "required":"required"}/>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>:
                                                                                            item[0].designUploadQuestionSet.subQuestionConditionObject["codeValue"] == "Yes/No" ?
                                                                                                <div className="col-lg-7 col-md-7 col-sm-6">
                                                                                                    <div className="inner_right">
                                                                                                        <div className="form-group">
                                                                                                            <div className="custom_select">
                                                                                                                    <span>
                                                                                                                        <select id={item[0]["technologyQuestionId"]}
                                                                                                                            onChange={(e) => {this.props.HandleSelectChange(e, item, '671')}}
                                                                                                                            value={this.props.state.selectValue[item[0]["technologyQuestionId"]]? this.props.state.selectValue[item[0]["technologyQuestionId"]] : ''}
                                                                                                                            disabled = {(this.props.state.disabledInput)? "disabled" : ""}>
                                                                                                                                <option value=''>Select</option>
                                                                                                                            {item[0].technologyAnswerSets.map((item, index) => (
                                                                                                                                <option value={item["technologyAnswerId"]}>
                                                                                                                                    {item.designUploadAnswerSet["answerValue"]}
                                                                                                                                </option>
                                                                                                                            ))
                                                                                                                            }
                                                                                                                        </select>
                                                                                                                    </span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>:
                                                                                                item[0].designUploadQuestionSet.subQuestionConditionObject["codeValue"] == "File" ?
                                                                                                <div className="col-lg-7 col-md-7 col-sm-6">

                                                                                                    <div className="inner_right">
                                                                                                        <div className="form-group" id={"question_"+item[0].designUploadQuestionSet.additionalMappingObject.codeValue+"_group"}
                                                                                                             style={{display:this.props.state.showSecondStep == "none"?"block":"none"}}>
                                                                                                            {/*id={itemChild["technologyQuestionId"]}*/}
                                                                                                            <input type="file"

                                                                                                                   id={"question_"+item[0].designUploadQuestionSet.additionalMappingObject.codeValue}
                                                                                                                   className="fileType"
                                                                                                                   style={{fontSize: '12px'}}
                                                                                                                   onChange={(e) => this.props.HandleFileChange(e, item[0].designUploadQuestionSet, item[0])}
                                                                                                                   disabled = {(this.props.state.disabledInput)? "disabled" : ""}
                                                                                                            />
                                                                                                            <span id={'question_'+item[0].designUploadQuestionSet.additionalMappingObject.codeValue.split(" ")[0]} style={{display:"none;"}}></span>

                                                                                                            <span id={"question_"+item[0].designUploadQuestionSet.additionalMappingObject.codeValue+"_span"} style={{display: 'none'}}>
                                                                                                                    <a id={"question_"+item[0].designUploadQuestionSet.additionalMappingObject.codeValue+"_fileLink"} style={{paddingRight:"2%", cursor: "auto"}}>
                                                                                                                        <span id={"question_"+item[0].designUploadQuestionSet.additionalMappingObject.codeValue+"_documentId"} style={{display: "none"}}></span>
                                                                                                                        <span id={"question_"+item[0].designUploadQuestionSet.additionalMappingObject.codeValue+"_fileName"}></span>
                                                                                                                    </a>

                                                                                                                    <input type="button" id="del_button" className="del_button" value="Delete" onClick={(e) => this.props.deleteFile(e, 'question_'+item[0].designUploadQuestionSet.additionalMappingObject.codeValue, item[0].designUploadQuestionSet.additionalMappingObject)}/>


                                                                                                                </span>
                                                                                                            {this.props.state.enterInputError["question_"+item[0].designUploadQuestionSet.additionalMappingObject.codeValue] == true?
                                                                                                                    <div className="InputFieldError" style={{height: 4}}>
                                                                                                                        <label style={{fontStyle: "italic", fontSize: "10px", position: "relative", bottom: 15, color: "red", marginLeft: "-100px"}}>* Please Select Input</label>
                                                                                                                    </div>:''
                                                                                                                }

                                                                                                            {/*<div id={"progress-bar-span_"+"question_"+item[0].designUploadQuestionSet.additionalMappingObject.codeValue} className="progress1" style={{display:"block", fontStyle: "italic", fontSize: "10px", position: "relative", bottom: 15, color: "red", marginLeft: "250px",height: "30px"}}>
                                                                                                                <div id={"progress-bar_"+"question_"+item[0].designUploadQuestionSet.additionalMappingObject.codeValue} className="progress-bar progress-bar-striped" style={{width:'0%',backgroundColor:'green'}}></div>
                                                                                                                <label id={"progress-bar-label_"+"question_"+item[0].designUploadQuestionSet.additionalMappingObject.codeValue} style={{display:"none", color:"red"}}>Please stay on this page until the file upload is in progress.</label>
                                                                                                                <div id={"percent_"+"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue} className="percent" style={{display:"none"}}>0%</div>
                                                                                                            </div>*/}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>:''
                                                                                }
                                                                            </div>
                                                                            {this.props.state.enterInputError[item[0]["technologyQuestionId"]] == true?
                                                                                <div className="InputFieldError">
                                                                                    <label style={{fontStyle: "italic", fontSize: "10px", position: "absolute", bottom: "-6px", color: "red"}}>* Please Enter Input</label>
                                                                                </div>:''
                                                                            }
                                                                        </div>

                                                                        {
                                                                            item[0].technologyAnswerSets.length > 0 && (JSON.stringify(item[0].technologyAnswerSets)) !== '{}'?
                                                                                item[0].technologyAnswerSets.map((item, index) => (
                                                                                    (item.childAnswers.length>0 && item.designUploadAnswerSet["childQuestionLabel"] != null)?
                                                                                        item != null?

                                                                                            <div className="col-lg-7 col-md-7 col-sm-6" style={(this.props.state.showQuestionOnSelect[item["technologyAnswerId"]]===true )? {} : { display: 'none' }}>
                                                                                                <div className="col-lg-5 col-md-5 col-sm-6">
                                                                                                    <div className="inner_left" value={item["technologyQuestionId"]}>
                                                                                                        <label>{item.designUploadAnswerSet["childQuestionLabel"]}</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="inner_right">
                                                                                                    <div className="form-group">
                                                                                                        <div className="custom_select">
                                                                                                            <select id={"child-"+item["technologyQuestionId"]+"-"+index} onChange={(e) => this.props.HandleChildChange(e, item, '669', "select")}
                                                                                                                    value={this.props.state.selectChildValue[item["technologyQuestionId"]]?this.props.state.selectChildValue[item["technologyQuestionId"]]:''}
                                                                                                                    className={"selectChild"+item.designUploadAnswerSet["answerId"]} disabled = {(this.props.state.disabledInput)? "disabled" : ""}>
                                                                                                                <option value=''>Select</option>

                                                                                                                {
                                                                                                                    item.childAnswers.map((item, index)=>(
                                                                                                                        <option value={item["technologyAnswerId"]}>
                                                                                                                            {item.designUploadAnswerSet["answerValue"]}
                                                                                                                        </option>
                                                                                                                    ))

                                                                                                                }


                                                                                                            </select>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {this.props.state.enterInputError["child-"+item["technologyQuestionId"]+"-"+index] == true?
                                                                                                    <div className="InputFieldError">
                                                                                                        <label style={{fontStyle: "italic", fontSize: "10px", position: "absolute", bottom: "-6px", color: "red"}}>* Please Enter Input</label>
                                                                                                    </div>:''
                                                                                                }
                                                                                            </div>
                                                                                            :''
                                                                                        :''


                                                                                )):''
                                                                        }

                                                                        {item["child"] ?
                                                                            item["child"].map((itemChild, index) => (
                                                                                <div class="col-lg-13" style={(this.props.state.showMyChildComponent[itemChild.designUploadQuestionSet["parentQuestionId"]] == itemChild.designUploadQuestionSet["parentQuestionId"] && this.props.state.showChildConditionalQuestion[itemChild.designUploadQuestionSet["parentQuestionId"]][itemChild["technologyQuestionId"]]=="true")? {} : { display: 'none' }}>
                                                                                    <div class="brd_section">
                                                                                        <div class="row new_pos" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                                                                                            <div class="col-lg-5 col-md-5 col-sm-6" >
                                                                                                <div class="inner_left">

                                                                                                    <label className="left-padding" value={itemChild["technologyQuestionId"]}>
                                                                                                        {itemChild["technologyQuestionId"]}
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                            {itemChild.designUploadQuestionSet.subQuestionConditionObject["codeValue"] == "Dropdown" ?
                                                                                                <div className="col-lg-7 col-md-7 col-sm-6">
                                                                                                    <div className="inner_right">
                                                                                                        <div className="form-group">
                                                                                                            <div className="custom_select">
                                                                                                    <span>
                                                                                                        <select id={itemChild["technologyQuestionId"]} onChange={(e) => this.props.HandleChildChange(e, itemChild, '669',"select")} name="ddlPackageType" id="ddlPackageType"
                                                                                                                value={this.props.state.selectChildValue[itemChild["technologyQuestionId"]]?this.props.state.selectChildValue[itemChild["technologyQuestionId"]]:''}
                                                                                                                disabled = {(this.props.state.disabledInput)? "disabled" : ""}>
                                                                                                            <option value=''>Select</option>
                                                                                                            {itemChild.technologyAnswerSets.map((itemChild, index) => (
                                                                                                                <option value={itemChild["technologyAnswerId"]} disabled={(itemChild.statusObject!=null && itemChild.statusObject.codeValue == "Inactive")?"disabled":""}>
                                                                                                                    {itemChild.designUploadAnswerSet["answerValue"]}
                                                                                                                </option>
                                                                                                            ))
                                                                                                            }
                                                                                                        </select>
                                                                                                    </span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                :
                                                                                                itemChild.designUploadQuestionSet.subQuestionConditionObject["codeValue"] == "Fill in"?
                                                                                                    <div className="col-lg-7 col-md-7 col-sm-6">
                                                                                                        <div className="inner_right">
                                                                                                            <div className="form-group">
                                                                                                                <input type={itemChild.designUploadQuestionSet["questionDataTypeObject"]!=null?
                                                                                                                    itemChild.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Integer" || itemChild.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Float"? "text":'text' :'text'}
                                                                                                                       id={itemChild["technologyQuestionId"]} name="txtDesignNumber"
                                                                                                                       value={this.props.state.selectChildValue[itemChild["technologyQuestionId"]]?this.props.state.selectChildValue[itemChild["technologyQuestionId"]]:''}
                                                                                                                       onChange={(e) => this.props.HandleInputChildChange(e, itemChild)}
                                                                                                                       onBlur={(e) => this.props.HandleChildChange(e, itemChild, '669', "input")}
                                                                                                                       className="form-control"
                                                                                                                       disabled = {(this.props.state.disabledInput)? "disabled" : ""}
                                                                                                                       required = {itemChild.designUploadQuestionSet["questionDataTypeObject"]!=null?
                                                                                                                           itemChild.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "String_AllowBlank" || itemChild.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Integer_AllowBlank"
                                                                                                                           || itemChild.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "ReadOnly"? "" : "required":"required"}
                                                                                                                       disabled={itemChild.designUploadQuestionSet["questionDataTypeObject"]!=null?itemChild.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "ReadOnly"?true:false:false}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    :itemChild.designUploadQuestionSet.subQuestionConditionObject["codeValue"] == '>0'?
                                                                                                    <div className="col-lg-7 col-md-7 col-sm-6">
                                                                                                        <div className="inner_right">
                                                                                                            <div className="form-group">
                                                                                                                <input type={itemChild.designUploadQuestionSet["questionDataTypeObject"]!=null?
                                                                                                                    itemChild.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Integer" || itemChild.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Float"? "text":'text' :'text'}
                                                                                                                       id={itemChild["technologyQuestionId"]} name="txtDesignNumber"
                                                                                                                       value={this.props.state.selectChildValue[itemChild["technologyQuestionId"]]?this.props.state.selectChildValue[itemChild["technologyQuestionId"]]:''}
                                                                                                                       onChange={(e) => this.props.HandleInputChildChange(e, itemChild)}
                                                                                                                       onBlur={(e) => this.props.HandleChildChange(e, itemChild, '669', "input")}
                                                                                                                       className="form-control"
                                                                                                                       disabled = {(this.props.state.disabledInput)? "disabled" : ""}
                                                                                                                       required = {itemChild.designUploadQuestionSet["questionDataTypeObject"]!=null?
                                                                                                                           itemChild.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "String_AllowBlank" || itemChild.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Integer_AllowBlank"? "" : "required":"required"}/>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    :itemChild.designUploadQuestionSet.subQuestionConditionObject["codeValue"] == "Other"?
                                                                                                        <div className="col-lg-7 col-md-7 col-sm-6">
                                                                                                            <div className="inner_right">
                                                                                                                <div className="form-group">
                                                                                                                    <input type={itemChild.designUploadQuestionSet["questionDataTypeObject"]!=null?
                                                                                                                        itemChild.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Integer" || itemChild.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Float"? "text":'text' :'text'}
                                                                                                                           id={itemChild["technologyQuestionId"]} name="txtDesignNumber"
                                                                                                                           value={this.props.state.selectChildValue[itemChild["technologyQuestionId"]]?this.props.state.selectChildValue[itemChild["technologyQuestionId"]]:''}
                                                                                                                           onChange={(e) => this.props.HandleInputChildChange(e, itemChild)}
                                                                                                                           onBlur={(e) => this.props.HandleChildChange(e, itemChild, '671', "input")}
                                                                                                                           className="form-control" disabled = {(this.props.state.disabledInput)? "disabled" : ""}
                                                                                                                           required = {itemChild[0].designUploadQuestionSet["questionDataTypeObject"]!=null?
                                                                                                                               itemChild.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "String_AllowBlank" || itemChild.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Integer_AllowBlank"? "" : "required":"required"}/>

                                                                                                                    {this.props.state.enterInputError[itemChild["technologyQuestionId"]] == true?
                                                                                                                        <div className="InputFieldError">
                                                                                                                            <label style={{fontStyle: "italic", fontSize: "10px", position: "absolute", bottom: "-6px", color: "red"}}>* Please Enter Input</label>
                                                                                                                        </div>:''
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        :itemChild.designUploadQuestionSet.subQuestionConditionObject["codeValue"] == "File" ?
                                                                                                            <div className="col-lg-7 col-md-7 col-sm-6">

                                                                                                                <div className="inner_right">
                                                                                                                    <div className="form-group" id={"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue+"_group"}
                                                                                                                         style={{display:this.props.state.showSecondStep == "none"?"block":"none"}}>
                                                                                                                        {/*id={itemChild["technologyQuestionId"]}*/}
                                                                                                                        <input type="file"

                                                                                                                               id={"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue}
                                                                                                                               className="fileType"
                                                                                                                               style={{fontSize: '12px'}}
                                                                                                                               onChange={(e) => this.props.HandleFileChange(e, itemChild.designUploadQuestionSet, itemChild)}
                                                                                                                               disabled = {(this.props.state.disabledInput)? "disabled" : ""}
                                                                                                                        />
                                                                                                                        <span id={'question_'+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue.split(" ")[0]} style={{display:"none;"}}></span>

                                                                                                                        <span id={"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue+"_span"} style={{display: 'none'}}>
                                                                                                                    <a id={"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue+"_fileLink"} style={{paddingRight:"2%", cursor: "auto"}}>
                                                                                                                        <span id={"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue+"_documentId"} style={{display: "none"}}></span>
                                                                                                                        <span id={"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue+"_fileName"}></span>
                                                                                                                    </a>

                                                                                                                    <input type="button" id="del_button" className="del_button" value="Delete" onClick={(e) => this.props.deleteFile(e, 'question_'+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue, itemChild.designUploadQuestionSet.additionalMappingObject)}/>


                                                                                                                </span>
                                                                                                                        {this.props.state.enterInputError["question_"+item[0].designUploadQuestionSet.additionalMappingObject.codeValue] == true?
                                                                                                                            <div className="InputFieldError" style={{height: 4}}>
                                                                                                                                <label style={{fontStyle: "italic", fontSize: "10px", position: "relative", bottom: 15, color: "red", marginLeft: "-100px"}}>* Please Select Input</label>
                                                                                                                            </div>:''
                                                                                                                        }

                                                                                                                        {/*<div id={"progress-bar-span_"+"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue} className="progress1" style={{display:"block", fontStyle: "italic", fontSize: "10px", position: "relative", bottom: 15, color: "red", marginLeft: "250px",height: "30px"}}>
                                                                                                                            <div id={"progress-bar_"+"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue} className="progress-bar progress-bar-striped" style={{width:'0%',backgroundColor:'green'}}></div>
                                                                                                                            <label id={"progress-bar-label_"+"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue} style={{display:"none", color:"red"}}>Please stay on this page until the file upload is in progress.</label>
                                                                                                                            <div id={"percent_"+"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue} className="percent" style={{display:"none"}}>0%</div>
                                                                                                                        </div>*/}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>:
                                                                                                        <span></span>
                                                                                            }


                                                                                        </div>
                                                                                        {this.props.state.enterInputError[itemChild["technologyQuestionId"]] == true?
                                                                                            <div className="InputFieldError">
                                                                                                <label style={{fontStyle: "italic", fontSize: "10px", position: "absolute", bottom: "-6px", color: "red"}}>* Please Enter Input</label>
                                                                                            </div>:''
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                            : ''}

                                                                    </div>:''
                                                            )):''

                                                        }
                                                        {/*======================================================DESIGN INFO - END==================================================*/}

                                                        {/*======================================================PACKAGING - START==================================================*/}
                                                        <div class="col-lg-12">
                                                            <div class="header">
                                                                <h3>Packaging </h3>

                                                            </div>
                                                        </div>

                                                        {
                                                            this.props.state.parentArrValuesList.length > 0 && this.props.state.showQuestionAnswers?
                                                                this.props.state.parentArrValuesList.map((item, index) => (
                                                                    item[0].designUploadQuestionSet.questionTypeObject["codeValue"] == "Packaging" ?

                                                                        <div className="col-lg-12">
                                                                            <div className="brd_section">
                                                                                <div className="row new_pos" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                                                                                    <div className="col-lg-5 col-md-5 col-sm-6">
                                                                                        <div className="inner_left" value={item[0]["technologyQuestionId"]}>
                                                                                            <label>{item[0].designUploadQuestionSet["question"]}</label>
                                                                                        </div>
                                                                                    </div>
                                                                                    {item[0].designUploadQuestionSet.subQuestionConditionObject["codeValue"] == "Dropdown" ?
                                                                                        <div className="col-lg-7 col-md-7 col-sm-6">
                                                                                            <div className="inner_right">
                                                                                                <div className="form-group">
                                                                                                    <div className="custom_select">
                                                                                            <span>
                                                                                                <select id={item[0]["technologyQuestionId"]}
                                                                                                        onChange={(e) => {this.props.HandleSelectChange(e, item, '670')}}
                                                                                                        value={this.props.state.selectValue[item[0]["technologyQuestionId"]]?this.props.state.selectValue[item[0]["technologyQuestionId"]]:''}
                                                                                                        disabled = {(this.props.state.disabledInput)? "disabled" : ""}>
                                                                                            <option value=''>Select</option>
                                                                                                    {item[0].technologyAnswerSets.map((item, index) => (
                                                                                                        <option value={item["technologyAnswerId"]} disabled={(item.statusObject!=null && item.statusObject.codeValue == "Inactive")?"disabled":""}>
                                                                                                            {item.designUploadAnswerSet["answerValue"]}
                                                                                                        </option>
                                                                                                    ))
                                                                                                    }
                                                                                                </select>
                                                                                            </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                        item[0].designUploadQuestionSet.subQuestionConditionObject["codeValue"] == "Dropdown_Multiselect" ?
                                                                                            <div className="col-lg-7 col-md-7 col-sm-6">
                                                                                                <div className="inner_right">
                                                                                                    <div className="form-group">
                                                                                                        <MultiSelectComponent
                                                                                                            id={item[0]["technologyQuestionId"]}
                                                                                                            onChange={this.props.onMultiSelectChange}
                                                                                                            questionId={item[0]["technologyQuestionId"]}
                                                                                                            type={'669'}
                                                                                                            selectedValue={this.props.state.selectValue[item[0]["technologyQuestionId"]]?this.props.state.selectValue[item[0]["technologyQuestionId"]]:''}

                                                                                                            isRequired={true}
                                                                                                            multiSelectOptions={item[0]}/>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                        item[0].designUploadQuestionSet.subQuestionConditionObject["codeValue"] == "Other" ?
                                                                                        <div className="col-lg-7 col-md-7 col-sm-6">
                                                                                            <div className="inner_right">
                                                                                                <div className="form-group">
                                                                                                    <div className="custom_select">
                                                                                                        <span>
                                                                                                            <select id={item[0]["technologyQuestionId"]} onChange={(e) => {this.props.HandleSelectChange(e, item, '670')}}
                                                                                                                    name="ddlPackageType" id="ddlPackageType"
                                                                                                                    value={this.props.state.selectValue[item[0]["technologyQuestionId"]]?this.props.state.selectValue[item[0]["technologyQuestionId"]]:''}
                                                                                                                    disabled = {(this.props.state.disabledInput)? "disabled" : ""}>
                                                                                                                    <option value=''>Select</option>
                                                                                                                        {item[0].technologyAnswerSets.map((item, index) => (
                                                                                                                            <option value={item["technologyAnswerId"]}>
                                                                                                                                {item.designUploadAnswerSet["answerValue"]}
                                                                                                                            </option>
                                                                                                                        ))}
                                                                                                            </select>
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>:
                                                                                        item[0].designUploadQuestionSet.subQuestionConditionObject["codeValue"] == "Fill in"?
                                                                                            <div className="col-lg-7 col-md-7 col-sm-6">
                                                                                                <div className="inner_right">
                                                                                                    <div className="form-group">
                                                                                                        <input type={item[0].designUploadQuestionSet["questionDataTypeObject"]!=null?
                                                                                                            item[0].designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Integer" || item[0].designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Float"? "text":'text' :'text'}
                                                                                                               id={item[0]["technologyQuestionId"]} name="txtDesignNumber"
                                                                                                               value={this.props.state.selectValue[item[0]["technologyQuestionId"]]?this.props.state.selectValue[item[0]["technologyQuestionId"]]:''}
                                                                                                               onChange={(e) => this.props.HandleInputChange(e, item)}
                                                                                                               onBlur={(e) => this.props.HandleOnBlur('670', item["child"], this.props.state.selectValue[item[0]["technologyQuestionId"]], item[0]["technologyQuestionId"], item[0], e)}
                                                                                                               className="form-control" disabled = {(this.props.state.disabledInput)? "disabled" : ""}
                                                                                                               required = {item[0].designUploadQuestionSet["questionDataTypeObject"]!=null?
                                                                                                                   item[0].designUploadQuestionSet.questionDataTypeObject["codeValue"] == "String_AllowBlank" || item[0].designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Integer_AllowBlank"
                                                                                                                       || item[0].designUploadQuestionSet.questionDataTypeObject["codeValue"] == "ReadOnly"? "" : "required": "required"}
                                                                                                               disabled={item[0].designUploadQuestionSet["questionDataTypeObject"]!=null?item[0].designUploadQuestionSet.questionDataTypeObject["codeValue"] == "ReadOnly"?true:false:false}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>:
                                                                                            item[0].designUploadQuestionSet.subQuestionConditionObject["codeValue"] == ">0"?
                                                                                                <div className="col-lg-7 col-md-7 col-sm-6">
                                                                                                    <div className="inner_right">
                                                                                                        <div className="form-group">
                                                                                                            <input type={item[0].designUploadQuestionSet["questionDataTypeObject"]!=null?
                                                                                                                item[0].designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Integer" || item[0].designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Float"? "text":'text' :'text'}
                                                                                                                   id={item[0]["technologyQuestionId"]} type="text" id="txtDesignNumber" name="txtDesignNumber"
                                                                                                                   value={this.props.state.selectValue[item[0]["technologyQuestionId"]]?this.props.state.selectValue[item[0]["technologyQuestionId"]]:''}
                                                                                                                   onChange={(e) => this.props.HandleInputChange(e, item)}
                                                                                                                   onBlur={(e) => this.props.HandleOnBlur('670', item["child"], this.props.state.selectValue[item[0]["technologyQuestionId"]], item[0]["technologyQuestionId"], item[0], e)}
                                                                                                                   className="form-control" disabled = {(this.props.state.disabledInput)? "disabled" : ""}
                                                                                                                   required = {item[0].designUploadQuestionSet["questionDataTypeObject"]!=null?
                                                                                                                       item[0].designUploadQuestionSet.questionDataTypeObject["codeValue"] == "String_AllowBlank" || item[0].designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Integer_AllowBlank"? "" : "required": "required"}/>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>:
                                                                                                item[0].designUploadQuestionSet.subQuestionConditionObject["codeValue"] == "Yes/No" ?
                                                                                                    <div className="col-lg-7 col-md-7 col-sm-6">
                                                                                                        <div className="inner_right">
                                                                                                            <div className="form-group">
                                                                                                                <div className="custom_select">
                                                                                                                    <span>
                                                                                                                        <select id={item[0]["technologyQuestionId"]}
                                                                                                                            onChange={(e) => {this.props.HandleSelectChange(e, item, '671')}}
                                                                                                                            value={this.props.state.selectValue[item[0]["technologyQuestionId"]]?this.props.state.selectValue[item[0]["technologyQuestionId"]]:''}
                                                                                                                            disabled = {(this.props.state.disabledInput)? "disabled" : ""}>
                                                                                                                                <option value=''>Select</option>
                                                                                                                            {item[0].technologyAnswerSets.map((item, index) => (
                                                                                                                                <option value={item["technologyAnswerId"]}>
                                                                                                                                    {item.designUploadAnswerSet["answerValue"]}
                                                                                                                                </option>
                                                                                                                            ))
                                                                                                                            }
                                                                                                                        </select>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>:
                                                                                                    item[0].designUploadQuestionSet.subQuestionConditionObject["codeValue"] == "File" ?
                                                                                                    <div className="col-lg-7 col-md-7 col-sm-6">

                                                                                                        <div className="inner_right">
                                                                                                            <div className="form-group" id={"question_"+item[0].designUploadQuestionSet.additionalMappingObject.codeValue+"_group"}
                                                                                                                 style={{display:this.props.state.showSecondStep == "none"?"block":"none"}}>
                                                                                                                {/*id={itemChild["technologyQuestionId"]}*/}
                                                                                                                <input type="file"

                                                                                                                       id={"question_"+item[0].designUploadQuestionSet.additionalMappingObject.codeValue}
                                                                                                                       className="fileType"
                                                                                                                       style={{fontSize: '12px'}}
                                                                                                                       onChange={(e) => this.props.HandleFileChange(e, item[0].designUploadQuestionSet, item[0])}
                                                                                                                       disabled = {(this.props.state.disabledInput)? "disabled" : ""}
                                                                                                                />
                                                                                                                <span id={'question_'+item[0].designUploadQuestionSet.additionalMappingObject.codeValue.split(" ")[0]} style={{display:"none;"}}></span>

                                                                                                                <span id={"question_"+item[0].designUploadQuestionSet.additionalMappingObject.codeValue+"_span"} style={{display: 'none'}}>
                                                                                                                    <a id={"question_"+item[0].designUploadQuestionSet.additionalMappingObject.codeValue+"_fileLink"} style={{paddingRight:"2%", cursor: "auto"}}>
                                                                                                                        <span id={"question_"+item[0].designUploadQuestionSet.additionalMappingObject.codeValue+"_documentId"} style={{display: "none"}}></span>
                                                                                                                        <span id={"question_"+item[0].designUploadQuestionSet.additionalMappingObject.codeValue+"_fileName"}></span>
                                                                                                                    </a>

                                                                                                                    <input type="button" id="del_button" className="del_button" value="Delete" onClick={(e) => this.props.deleteFile(e, 'question_'+item[0].designUploadQuestionSet.additionalMappingObject.codeValue, item[0].designUploadQuestionSet.additionalMappingObject)}/>


                                                                                                                </span>
                                                                                                                {this.props.state.enterInputError["question_"+item[0].designUploadQuestionSet.additionalMappingObject.codeValue] == true?
                                                                                                                    <div className="InputFieldError" style={{height: 4}}>
                                                                                                                        <label style={{fontStyle: "italic", fontSize: "10px", position: "relative", bottom: 15, color: "red", marginLeft: "-100px"}}>* Please Select Input</label>
                                                                                                                    </div>:''
                                                                                                                }

                                                                                                                {/*<div id={"progress-bar-span_"+"question_"+item[0].designUploadQuestionSet.additionalMappingObject.codeValue} className="progress1" style={{display:"block", fontStyle: "italic", fontSize: "10px", position: "relative", bottom: 15, color: "red", marginLeft: "250px",height: "30px"}}>
                                                                                                                    <div id={"progress-bar_"+"question_"+item[0].designUploadQuestionSet.additionalMappingObject.codeValue} className="progress-bar progress-bar-striped" style={{width:'0%',backgroundColor:'green'}}></div>
                                                                                                                    <label id={"progress-bar-label_"+"question_"+item[0].designUploadQuestionSet.additionalMappingObject.codeValue} style={{display:"none", color:"red"}}>Please stay on this page until the file upload is in progress.</label>
                                                                                                                    <div id={"percent_"+"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue} className="percent" style={{display:"none"}}>0%</div>
                                                                                                                </div>*/}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>:''
                                                                                    }
                                                                                </div>
                                                                                {this.props.state.enterInputError[item[0]["technologyQuestionId"]] == true?
                                                                                    <div className="InputFieldError">
                                                                                        <label style={{fontStyle: "italic", fontSize: "10px", position: "absolute", bottom: "-6px", color: "red"}}>* Please Enter Input</label>
                                                                                    </div>:''
                                                                                }
                                                                            </div>
                                                                            {
                                                                                item[0].technologyAnswerSets.length > 0 && (JSON.stringify(item[0].technologyAnswerSets)) !== '{}'?
                                                                                    item[0].technologyAnswerSets.map((item, index) => (
                                                                                        (item.childAnswers.length>0 && item.designUploadAnswerSet["childQuestionLabel"] != null)?
                                                                                            item != null?

                                                                                                <div className="col-lg-7 col-md-7 col-sm-6" style={(this.props.state.showQuestionOnSelect[item["technologyAnswerId"]]===true )? {} : { display: 'none' }}>
                                                                                                    <div className="col-lg-5 col-md-5 col-sm-6">
                                                                                                        <div className="inner_left" value={item["technologyQuestionId"]}>
                                                                                                            <label>{item.designUploadAnswerSet["childQuestionLabel"]}</label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="inner_right">
                                                                                                        <div className="form-group">
                                                                                                            <div className="custom_select">
                                                                                                                <select id={"child-"+item["technologyQuestionId"]+"-"+index} onChange={(e) => this.props.HandleChildChange(e, item, '670', "select")}
                                                                                                                        value={this.props.state.selectChildValue[item["technologyQuestionId"]]?this.props.state.selectChildValue[item["technologyQuestionId"]]:''}
                                                                                                                        className={"selectChild"+item.designUploadAnswerSet["answerId"]} disabled = {(this.props.state.disabledInput)? "disabled" : ""}>
                                                                                                                    <option value=''>Select</option>

                                                                                                                    {
                                                                                                                        item.childAnswers.map((item, index)=>(
                                                                                                                            <option value={item["technologyAnswerId"]}>
                                                                                                                                {item.designUploadAnswerSet["answerValue"]}
                                                                                                                            </option>
                                                                                                                        ))

                                                                                                                    }


                                                                                                                </select>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {this.props.state.enterInputError["child-"+item["technologyQuestionId"]+"-"+index] == true?
                                                                                                        <div className="InputFieldError">
                                                                                                            <label style={{fontStyle: "italic", fontSize: "10px", position: "absolute", bottom: "-6px", color: "red"}}>* Please Enter Input</label>
                                                                                                        </div>:''
                                                                                                    }
                                                                                                </div>
                                                                                                :''
                                                                                            :''


                                                                                    )):''
                                                                            }
                                                                            {/*&& this.props.state.showChildConditionalQuestion[item["technologyQuestionId"]] == 'true'*/}
                                                                            {item["child"] ?
                                                                                item["child"].map((itemChild, index) => (
                                                                                    <div class="col-lg-13"
                                                                                         style={(this.props.state.showMyChildComponent[itemChild.designUploadQuestionSet["parentQuestionId"]] == itemChild.designUploadQuestionSet["parentQuestionId"]
                                                                                             && this.props.state.showChildConditionalQuestion[itemChild.designUploadQuestionSet["parentQuestionId"]][itemChild["technologyQuestionId"]]=="true"
                                                                                             && $("#"+item[0]["technologyQuestionId"]).children("option:selected").text() != "No")? {} : { display: 'none' }}>
                                                                                        <div class="brd_section">
                                                                                            <div class="row new_pos" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                                                                                                <div class="col-lg-5 col-md-5 col-sm-6">
                                                                                                    <div class="inner_left" value={itemChild["technologyQuestionId"]}>
                                                                                                        <label className="left-padding">{itemChild.designUploadQuestionSet["question"]}

                                                                                                        </label>
                                                                                                    </div>
                                                                                                </div>



                                                                                                {itemChild.designUploadQuestionSet.subQuestionConditionObject["codeValue"] == "File" ?
                                                                                                    <div className="col-lg-7 col-md-7 col-sm-6">

                                                                                                        <div className="inner_right">
                                                                                                            <div className="form-group" id={"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue+"_group"}
                                                                                                                 style={{display:this.props.state.showSecondStep == "none"?"block":"none"}}>
                                                                                                                {/*id={itemChild["technologyQuestionId"]}*/}
                                                                                                                <input type="file"

                                                                                                                       id={"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue}
                                                                                                                       className="fileType"
                                                                                                                       style={{fontSize: '12px'}}
                                                                                                                       onChange={(e) => this.props.HandleFileChange(e, itemChild.designUploadQuestionSet, itemChild)}
                                                                                                                    disabled = {(this.props.state.disabledInput)? "disabled" : ""}
                                                                                                                />

                                                                                                                <span id={"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue+"_span"} style={{display: 'none'}}>
                                                                                                                    <a id={"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue+"_fileLink"} style={{paddingRight:"2%", cursor: "auto"}}>
                                                                                                                        <span id={"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue+"_documentId"} style={{display: "none"}}></span>
                                                                                                                        <span id={"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue+"_fileName"}></span>
                                                                                                                    </a>

                                                                                                                    <input type="button" id="del_button" className="del_button" value="Delete" onClick={(e) => this.props.deleteFile(e, 'question_'+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue, itemChild.designUploadQuestionSet.additionalMappingObject)}/>


                                                                                                                </span>
                                                                                                                {this.props.state.enterInputError["question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue] == true?
                                                                                                                    <div className="InputFieldError" style={{height: 4}}>
                                                                                                                        <label style={{fontStyle: "italic", fontSize: "10px", position: "relative", bottom: 15, color: "red", marginLeft: "-100px"}}>* Please Select Input</label>
                                                                                                                    </div>:''
                                                                                                                }

                                                                                                                {/*<div id={"progress-bar-span_"+"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue} className="progress1" style={{display:"block", fontStyle: "italic", fontSize: "10px", position: "relative", bottom: 15, color: "red", marginLeft: "250px",height: "30px"}}>
                                                                                                                    <div id={"progress-bar_"+"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue} className="progress-bar progress-bar-striped" style={{width:'0%',backgroundColor:'green'}}></div>
                                                                                                                    <label id={"progress-bar-label_"+"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue} style={{display:"none", color:"red"}}>Please stay on this page until the file upload is in progress.</label>
                                                                                                                    <div id={"percent_"+"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue} className="percent" style={{display:"none"}}>0%</div>
                                                                                                                </div>*/}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>



                                                                                                    :
                                                                                                    itemChild.designUploadQuestionSet.subQuestionConditionObject["codeValue"] == "Dropdown" ?
                                                                                                    <div className="col-lg-7 col-md-7 col-sm-6">
                                                                                                        <div className="inner_right">
                                                                                                            <div className="form-group">
                                                                                                                <div className="custom_select">
                                                                                                            <span>
                                                                                                        <select onChange={(e) => this.props.HandleChildChange(e, itemChild, '670', "select")} name="ddlPackageType" id={itemChild["technologyQuestionId"]}
                                                                                                                value={this.props.state.selectChildValue[itemChild["technologyQuestionId"]]? this.props.state.selectChildValue[itemChild["technologyQuestionId"]] : ''}
                                                                                                                disabled = {(this.props.state.disabledInput)? "disabled" : ""}>
                                                                                                            <option value=''>Select</option>
                                                                                                            {itemChild.technologyAnswerSets.map((itemChild, index) => (
                                                                                                                <option value={itemChild["technologyAnswerId"]} disabled={(itemChild.statusObject!=null && itemChild.statusObject.codeValue == "Inactive")?"disabled":""}>
                                                                                                                    {itemChild.designUploadAnswerSet["answerValue"]}
                                                                                                                </option>
                                                                                                            ))
                                                                                                            }
                                                                                                        </select>
                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    :
                                                                                                    itemChild.designUploadQuestionSet.subQuestionConditionObject["codeValue"] == "Fill in"?
                                                                                                        <div className="col-lg-7 col-md-7 col-sm-6">
                                                                                                            <div className="inner_right">
                                                                                                                <div className="form-group">
                                                                                                                    <input type={itemChild.designUploadQuestionSet["questionDataTypeObject"]!=null?
                                                                                                                        itemChild.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Integer" || itemChild.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Float"? "text":'text' :'text'}
                                                                                                                           id={itemChild["technologyQuestionId"]} name="txtDesignNumber"
                                                                                                                           value={this.props.state.selectChildValue[itemChild["technologyQuestionId"]]?this.props.state.selectChildValue[itemChild["technologyQuestionId"]]:''}
                                                                                                                           onChange={(e) => this.props.HandleInputChildChange(e, itemChild)}
                                                                                                                           onBlur={(e) => this.props.HandleChildChange(e, itemChild, '670', "input")}
                                                                                                                           className="form-control" disabled = {(this.props.state.disabledInput)? "disabled" : ""}
                                                                                                                           required = {itemChild.designUploadQuestionSet["questionDataTypeObject"]!=null?
                                                                                                                               itemChild.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "String_AllowBlank" || itemChild.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Integer_AllowBlank"
                                                                                                                               || itemChild.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "ReadOnly"? "" : "required": "required"}
                                                                                                                           disabled={itemChild.designUploadQuestionSet["questionDataTypeObject"]!=null?itemChild.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "ReadOnly"?true:false:false}
                                                                                                                    />

                                                                                                                    {/*{this.props.state.enterInputError[itemChild["technologyQuestionId"]] == true?
                                                                                                                        <div className="InputFieldError">
                                                                                                                            <label style={{fontStyle: "italic", fontSize: "10px", position: "absolute", color: "red", top: 20, left:0}}>* Please Enter Input</label>
                                                                                                                        </div>:''
                                                                                                                    }*/}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        :(itemChild.designUploadQuestionSet.subQuestionConditionObject["codeValue"] == ">0")?
                                                                                                            (itemChild.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "File")?
                                                                                                                <div className="col-lg-7 col-md-7 col-sm-6">

                                                                                                                    <div className="inner_right">
                                                                                                                        <div className="form-group" id={"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue+"_group"}
                                                                                                                             style={{display:this.props.state.showSecondStep == "none"?"block":"none"}}>
                                                                                                                            {/*id={itemChild["technologyQuestionId"]}*/}
                                                                                                                            <input type="file"

                                                                                                                                   id={"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue}
                                                                                                                                   className="fileType"
                                                                                                                                   style={{fontSize: '12px'}}
                                                                                                                                   onChange={(e) => this.props.HandleFileChange(e, itemChild.designUploadQuestionSet, itemChild)}
                                                                                                                                   disabled = {(this.props.state.disabledInput)? "disabled" : ""}
                                                                                                                            />



                                                                                                                            <span id={"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue+"_span"} style={{display: 'none'}}>
                                                                                                                        <a  id={"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue+"_fileLink"} style={{paddingRight:"2%", cursor: "auto"}}>
                                                                                                                            <span id={"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue+"_documentId"} style={{display: "none"}}></span>
                                                                                                                            <span id={"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue+"_fileName"}></span>
                                                                                                                        </a>

                                                                                                                        <input type="button" id="del_button" className="del_button" value="Delete" onClick={(e) => this.props.deleteFile(e, 'question_'+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue, itemChild.designUploadQuestionSet.additionalMappingObject)}/>


                                                                                                                    </span>
                                                                                                                            {this.props.state.enterInputError["question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue] == true?
                                                                                                                                <div className="InputFieldError" style={{height: 4}}>
                                                                                                                                    <label style={{fontStyle: "italic", fontSize: "10px", position: "relative", bottom: 15, color: "red", marginLeft: "-100px"}}>* Please Select Input</label>
                                                                                                                                </div>:''
                                                                                                                            }

{/*                                                                                                                            <div id="progress" style={{display:"none"}}>
                                                                                                                                <div className="bar"></div>
                                                                                                                                <div id={"percent_"+"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue} className="percent">0%</div>
                                                                                                                            </div>*/}

                                                                                                                            {/*<div id={"progress-bar-span_"+"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue} className="progress1" style={{display:"block", fontStyle: "italic", fontSize: "10px", position: "relative", bottom: 15, color: "red", marginLeft: "250px",height: "30px"}}>
                                                                                                                                <div id={"progress-bar_"+"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue} className="progress-bar progress-bar-striped" style={{width:'0%',backgroundColor:'green'}}></div>
                                                                                                                                <label id={"progress-bar-label_"+"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue} style={{display:"none", color:"red"}}>Please stay on this page until the file upload is in progress.</label>
                                                                                                                                <div id={"percent_"+"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue} className="percent" style={{display:"none"}}>0%</div>
                                                                                                                            </div>*/}
                                                                                                                        </div>
                                                                                                                    </div>


                                                                                                                </div>
                                                                                                                :
                                                                                                                <div className="col-lg-7 col-md-7 col-sm-6">
                                                                                                                    <div className="inner_right">
                                                                                                                        <div className="form-group">
                                                                                                                            <div className="custom_select">
                                                                                                                                <select onChange={(e) => this.props.HandleChildChange(e, itemChild, '670',"select")} name="ddlPackageType" id={itemChild["technologyQuestionId"]}
                                                                                                                                        value={this.props.state.selectChildValue[itemChild["technologyQuestionId"]] ? this.props.state.selectChildValue[itemChild["technologyQuestionId"]] : ''}
                                                                                                                                        disabled = {(this.props.state.disabledInput)? "disabled" : ""}>
                                                                                                                                    <option value=''>Select</option>
                                                                                                                                    {itemChild.technologyAnswerSets.map((itemChild, index) => (
                                                                                                                                        <option value={itemChild["technologyAnswerId"]}>
                                                                                                                                            {itemChild.designUploadAnswerSet["answerValue"]}
                                                                                                                                        </option>
                                                                                                                                    ))
                                                                                                                                    }
                                                                                                                                </select>
                                                                                                                                {/*{this.props.state.enterInputError[itemChild["technologyQuestionId"]] == true?
                                                                                                                                    <div className="InputFieldError">
                                                                                                                                        <label style={{fontStyle: "italic", fontSize: "10px", position: "absolute", color: "red", top: 20, left:0}}>* Please Enter Input</label>
                                                                                                                                    </div>:''
                                                                                                                                }*/}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                        :itemChild.designUploadQuestionSet.subQuestionConditionObject["codeValue"] == "Other"?
                                                                                                            <div className="col-lg-7 col-md-7 col-sm-6">
                                                                                                                <div className="inner_right">
                                                                                                                    <div className="form-group">
                                                                                                                        <input type={itemChild.designUploadQuestionSet["questionDataTypeObject"]!=null?
                                                                                                                            itemChild.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Integer" || itemChild.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Float"? "text":'text' :'text'}
                                                                                                                               id={itemChild["technologyQuestionId"]} name="txtDesignNumber"
                                                                                                                               value={this.props.state.selectChildValue[itemChild["technologyQuestionId"]]?this.props.state.selectChildValue[itemChild["technologyQuestionId"]]:''}
                                                                                                                               onChange={(e) => this.props.HandleInputChildChange(e, itemChild)}
                                                                                                                               onBlur={(e) => this.props.HandleChildChange(e, itemChild, '671', "input")}
                                                                                                                               className="form-control" disabled = {(this.props.state.disabledInput)? "disabled" : ""}
                                                                                                                               required = {itemChild.designUploadQuestionSet["questionDataTypeObject"]!=null?
                                                                                                                                   itemChild.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "String_AllowBlank" || itemChild.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Integer_AllowBlank"? "" : "required": "required"}/>

                                                                                                                        {/*{this.props.state.enterInputError[itemChild["technologyQuestionId"]] == true?
                                                                                                                            <div className="InputFieldError">
                                                                                                                                <label style={{fontStyle: "italic", fontSize: "10px", position: "absolute", color: "red", top: 20, left:0}}>* Please Enter Input</label>
                                                                                                                            </div>:''
                                                                                                                        }*/}

                                                                                                                    </div>

                                                                                                                </div>
                                                                                                            </div>
                                                                                                            :
                                                                                                            <span></span>
                                                                                                }
                                                                                            </div>
                                                                                            {this.props.state.enterInputError[itemChild["technologyQuestionId"]] == true?
                                                                                                <div className="InputFieldError" style={{height: 4}}>
                                                                                                    <label style={{fontStyle: "italic", fontSize: "10px", position: "relative", bottom: 15, color: "red"}}>* Please Enter Input</label>
                                                                                                </div>:''
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                                : ''}
                                                                        </div>

                                                                        :<span></span>







                                                                )):''
                                                        }
                                                        {/*======================================================PACKAGING - END==================================================*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                {this.props.state.enterAllQAErrorFirstScreen == true?
                                    <div ><label style={{fontStyle: "italic", fontSize: "13px", position: "absolute", bottom: "1%", color: "red"}}>* Please Enter All Answers</label></div>:''
                                }

                                {this.props.state.designNumberListDisabled == true?
                                    <div><label style={{color: "red", fontStyle: "italic"}}>No Design Numbers Found</label></div>:''
                                }
                                {this.props.state.showQuantityOfPackagedError == true?
                                    <div ><label style={{fontStyle: "italic", fontSize: "10px", position: "absolute", bottom: "3%", color: "red"}}>* Quantity of packaged chips cannot be greater than Quantity Ordered</label></div>:''
                                }
                                <div class="text-center" style={(this.props.state.showNextButton === true )? {} : { display: 'none' }}>
                                    <button type="button" class="dash_submit_btn" id="nextPageBtn" onClick={()=>this.props.ShowHideSteps('showsecond')}>Next</button>
                                </div>

                            </div>

                            <div class="step-2" style={{ display: this.props.state.showSecondStep }}>
                                <div class="account">
                                    <div class="header" id="designDataHeader">
                                        <h3>Design Data  </h3>
                                        <span href="#top"></span>
                                    </div>
                                    <div class="accoutn_form">
                                        <form action="" method="">
                                            <div class="dashboard_form">
                                                <div class="inner-dashoard_form">
                                                    <div class="row">
                                                        {
                                                            this.props.state.parentArrValuesList.length > 0  && this.props.state.showQuestionAnswers?
                                                                this.props.state.parentArrValuesList.map((item, index) => (
                                                                    item[0].designUploadQuestionSet.questionTypeObject["codeValue"] == "Design Data" ?

                                                                        <div className="col-lg-12">
                                                                            <div className="brd_section">
                                                                                <div className="row new_pos" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                                                                                    <div className="col-lg-5 col-md-5 col-sm-6">
                                                                                        <div className="inner_left" value={item[0]["technologyQuestionId"]}>
                                                                                            <label>{item[0].designUploadQuestionSet["question"]}</label>
                                                                                        </div>
                                                                                    </div>
                                                                                    {item[0].designUploadQuestionSet.subQuestionConditionObject["codeValue"] == "Dropdown" ?
                                                                                        <div className="col-lg-7 col-md-7 col-sm-6">
                                                                                            <div className="inner_right">
                                                                                                <div className="form-group">
                                                                                                    <div className="custom_select">
                                                                                            <span>
                                                                                                <select id={item[0]["technologyQuestionId"]}
                                                                                                    onChange={(e) => {this.props.HandleSelectChange(e, item, '671')}}
                                                                                                    value={this.props.state.selectValue[item[0]["technologyQuestionId"]]?this.props.state.selectValue[item[0]["technologyQuestionId"]]:''}
                                                                                                    disabled = {(this.props.state.disabledInput)? "disabled" : ""}>
                                                                                            <option value=''>Select</option>
                                                                                                    {item[0].technologyAnswerSets.map((item, index) => (
                                                                                                        <option value={item["technologyAnswerId"]} disabled={(item.statusObject!=null && item.statusObject.codeValue == "Inactive")?"disabled":""}>
                                                                                                            {item.designUploadAnswerSet["answerValue"]}
                                                                                                        </option>
                                                                                                    ))
                                                                                                    }
                                                                                                </select>
                                                                                            </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                        item[0].designUploadQuestionSet.subQuestionConditionObject["codeValue"] == "Dropdown_Multiselect" ?
                                                                                            <div className="col-lg-7 col-md-7 col-sm-6">
                                                                                                <div className="inner_right">
                                                                                                    <div className="form-group">
                                                                                                        <MultiSelectComponent
                                                                                                            id={item[0]["technologyQuestionId"]}
                                                                                                            onChange={this.props.onMultiSelectChange}
                                                                                                            questionId={item[0]["technologyQuestionId"]}
                                                                                                            type={'669'}
                                                                                                            selectedValue={this.props.state.selectValue[item[0]["technologyQuestionId"]]?this.props.state.selectValue[item[0]["technologyQuestionId"]]:''}

                                                                                                            isRequired={true}
                                                                                                            multiSelectOptions={item[0]}/>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                        item[0].designUploadQuestionSet.subQuestionConditionObject["codeValue"] == "Fill in"?
                                                                                            <div className="col-lg-7 col-md-7 col-sm-6">
                                                                                                <div className="inner_right">
                                                                                                    <div className="form-group">
                                                                                                        <input type={item[0].designUploadQuestionSet["questionDataTypeObject"]!=null?
                                                                                                            item[0].designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Integer" || item[0].designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Float"? "text":'text' :'text'}
                                                                                                               id={item[0]["technologyQuestionId"]} name="txtDesignNumber"
                                                                                                               value={this.props.state.selectValue[item[0]["technologyQuestionId"]] !=null?this.props.state.selectValue[item[0]["technologyQuestionId"]]:''}
                                                                                                               onChange={(e) => this.props.HandleInputChange(e, item)}
                                                                                                               onBlur={(e) => this.props.HandleOnBlur('671', 'txtDesignNumber', this.props.state.selectValue[item[0]["technologyQuestionId"]], item[0]["technologyQuestionId"], item[0], e)}
                                                                                                               className="form-control" disabled = {(this.props.state.disabledInput)? "disabled" : ""}
                                                                                                               required = {item[0].designUploadQuestionSet["questionDataTypeObject"]!=null?
                                                                                                                   item[0].designUploadQuestionSet.questionDataTypeObject["codeValue"] == "String_AllowBlank" || item[0].designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Integer_AllowBlank"
                                                                                                                   || item[0].designUploadQuestionSet.questionDataTypeObject["codeValue"] == "ReadOnly"? "" : "required": "required"}
                                                                                                               disabled={item[0].designUploadQuestionSet["questionDataTypeObject"]!=null?item[0].designUploadQuestionSet.questionDataTypeObject["codeValue"] == "ReadOnly"?true:false:false}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>:
                                                                                            item[0].designUploadQuestionSet.subQuestionConditionObject["codeValue"] == ">0"?
                                                                                                <div className="col-lg-7 col-md-7 col-sm-6">
                                                                                                    <div className="inner_right">
                                                                                                        <div className="form-group">
                                                                                                            <input type={item[0].designUploadQuestionSet["questionDataTypeObject"]!=null?
                                                                                                                item[0].designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Integer" || item[0].designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Float"? "text":'text' :'text'}
                                                                                                                   id={item[0]["technologyQuestionId"]} name="txtDesignNumber"
                                                                                                                   value={this.props.state.selectValue[item[0]["technologyQuestionId"]] !=null?this.props.state.selectValue[item[0]["technologyQuestionId"]]:''}
                                                                                                                   onChange={(e) => this.props.HandleInputChange(e, item)}
                                                                                                                   onBlur={(e) => this.props.HandleOnBlur('671', 'txtDesignNumber', this.props.state.selectValue[item[0]["technologyQuestionId"]], item[0]["technologyQuestionId"], item[0], e)}
                                                                                                                   className="form-control" disabled = {(this.props.state.disabledInput)? "disabled" : ""}
                                                                                                                   required = {item[0].designUploadQuestionSet["questionDataTypeObject"]!=null?
                                                                                                                       item[0].designUploadQuestionSet.questionDataTypeObject["codeValue"] == "String_AllowBlank" || item[0].designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Integer_AllowBlank"? "" : "required": "required"}/>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>:
                                                                                                item[0].designUploadQuestionSet.subQuestionConditionObject["codeValue"] == "Yes/No" ?
                                                                                                    <div className="col-lg-7 col-md-7 col-sm-6">
                                                                                                        <div className="inner_right">
                                                                                                            <div className="form-group">
                                                                                                                <div className="custom_select">
                                                                                                                    <span>
                                                                                                                        <select
                                                                                                                            id={item[0]["technologyQuestionId"]}
                                                                                                                            onChange={(e) => {this.props.HandleSelectChange(e, item, '671')}}
                                                                                                                            value={this.props.state.selectValue[item[0]["technologyQuestionId"]]?this.props.state.selectValue[item[0]["technologyQuestionId"]]:''}
                                                                                                                            disabled = {(this.props.state.disabledInput)? "disabled" : ""}>
                                                                                                                                <option value=''>Select</option>
                                                                                                                                        {item[0].technologyAnswerSets.map((item, index) => (
                                                                                                                                            <option value={item["technologyAnswerId"]}>
                                                                                                                                                {item.designUploadAnswerSet["answerValue"]}
                                                                                                                                            </option>
                                                                                                                                        ))
                                                                                                                                        }
                                                                                                                        </select>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>:
                                                                                                    item[0].designUploadQuestionSet.subQuestionConditionObject["codeValue"] == "File" ?
                                                                                                    <div className="col-lg-7 col-md-7 col-sm-6">

                                                                                                        <div className="inner_right">
                                                                                                            <div className="form-group" id={"question_"+item[0].designUploadQuestionSet.additionalMappingObject.codeValue+"_group"}
                                                                                                            style={{display:this.props.state.showSecondStep == "none"?"none":"block"}}>
                                                                                                                {/*id={itemChild["technologyQuestionId"]}*/}
                                                                                                                <input type="file"

                                                                                                                       id={"question_"+item[0].designUploadQuestionSet.additionalMappingObject.codeValue}
                                                                                                                       className="fileType"
                                                                                                                       style={{fontSize: '12px'}}
                                                                                                                       onChange={(e) => this.props.HandleFileChange(e, item[0].designUploadQuestionSet, item[0])}
                                                                                                                       disabled = {(this.props.state.disabledInput)? "disabled" : ""}
                                                                                                                />
                                                                                                                <span id={'question_'+item[0].designUploadQuestionSet.additionalMappingObject.codeValue.split(" ")[0]} style={{display:"none;"}}></span>

                                                                                                                <span id={"question_"+item[0].designUploadQuestionSet.additionalMappingObject.codeValue+"_span"} style={{display: 'none'}}>
                                                                                                                    <a id={"question_"+item[0].designUploadQuestionSet.additionalMappingObject.codeValue+"_fileLink"} style={{paddingRight:"2%", cursor: "auto"}}>
                                                                                                                        <span id={"question_"+item[0].designUploadQuestionSet.additionalMappingObject.codeValue+"_documentId"} style={{display: "none"}}></span>
                                                                                                                        <span id={"question_"+item[0].designUploadQuestionSet.additionalMappingObject.codeValue+"_fileName"}></span>
                                                                                                                    </a>

                                                                                                                    <input type="button" id="del_button" className="del_button" value="Delete" onClick={(e) => this.props.deleteFile(e, 'question_'+item[0].designUploadQuestionSet.additionalMappingObject.codeValue, item[0].designUploadQuestionSet.additionalMappingObject)}/>


                                                                                                                </span>
                                                                                                                {this.props.state.enterInputError["question_"+item[0].designUploadQuestionSet.additionalMappingObject.codeValue] == true?
                                                                                                                    <div className="InputFieldError" style={{height: 4}}>
                                                                                                                        <label style={{fontStyle: "italic", fontSize: "10px", position: "relative", bottom: 15, color: "red", marginLeft: "-100px"}}>* Please Select Input</label>
                                                                                                                    </div>:''
                                                                                                                }

                                                                                                                {/*<div id={"progress-bar-span_"+"question_"+item[0].designUploadQuestionSet.additionalMappingObject.codeValue} className="progress1" style={{display:"block", fontStyle: "italic", fontSize: "10px", position: "relative", bottom: 15, color: "red", marginLeft: "250px",height: "30px"}}>
                                                                                                                    <div id={"progress-bar_"+"question_"+item[0].designUploadQuestionSet.additionalMappingObject.codeValue} className="progress-bar progress-bar-striped" style={{width:'0%',backgroundColor:'green'}}></div>
                                                                                                                    <label id={"progress-bar-label_"+"question_"+item[0].designUploadQuestionSet.additionalMappingObject.codeValue} style={{display:"none", color:"red"}}>Please stay on this page until the file upload is in progress.</label>
                                                                                                                    <div id={"percent_"+"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue} className="percent" style={{display:"none"}}>0%</div>
                                                                                                                </div>*/}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>:''
                                                                                    }

                                                                                </div>
                                                                                {this.props.state.enterInputError[item[0]["technologyQuestionId"]] == true?
                                                                                    <div className="InputFieldError">
                                                                                        <label style={{fontStyle: "italic", fontSize: "10px", position: "absolute", bottom: "-6px", color: "red"}}>* Please Enter Input</label>
                                                                                    </div>:''
                                                                                }
                                                                            </div>
                                                                                {
                                                                                    item[0].technologyAnswerSets.length > 0 && (JSON.stringify(item[0].technologyAnswerSets)) !== '{}'?
                                                                                    item[0].technologyAnswerSets.map((item, index) => (
                                                                                        (item.childAnswers.length>0 && item.designUploadAnswerSet["childQuestionLabel"] != null)?
                                                                                            item != null?

                                                                                                <div className="col-lg-13"
                                                                                                     /*style={(this.props.state.showQuestionOnSelect == item["technologyAnswerId"] )? {} : { display: 'none' }}*/
                                                                                                     style={(this.props.state.showQuestionOnSelect[item["technologyAnswerId"]]===true )? {} : { display: 'none' }}
                                                                                                >
                                                                                                    <div className="brd_section">
                                                                                                        <div className="row new_pos" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>

                                                                                                                <div className="col-lg-5 col-md-5 col-sm-6" style={{paddingLeft:"5%"}}>
                                                                                                                    <div className="inner_left" value={item["technologyQuestionId"]}>
                                                                                                                        <label>{item.designUploadAnswerSet["childQuestionLabel"]}</label>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-lg-7 col-md-7 col-sm-6">
                                                                                                                <div className="inner_right">
                                                                                                                    <div className="form-group">
                                                                                                                        <div className="custom_select">
                                                                                                                            <select id={"child-"+item["technologyQuestionId"]+"-"+index}
                                                                                                                                    onChange={(e) => {this.props.HandleChildChange(e, item, '671', "select")}}
                                                                                                                                    value={this.props.state.selectChildValue[item["technologyQuestionId"]]?this.props.state.selectChildValue[item["technologyQuestionId"]]:''}
                                                                                                                                className={"selectChild"+item.designUploadAnswerSet["answerId"]} disabled = {(this.props.state.disabledInput)? "disabled" : ""}>
                                                                                                                                <option value=''>Select</option>
                                                                                                                                    {
                                                                                                                                        item.childAnswers.map((item, index)=>(
                                                                                                                                            <option value={item["technologyAnswerId"]}>
                                                                                                                                                {item.designUploadAnswerSet["answerValue"]}
                                                                                                                                            </option>
                                                                                                                                        ))

                                                                                                                                    }
                                                                                                                            </select>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                </div>



                                                                                                        </div>
                                                                                                        {
                                                                                                            this.props.state.enterInputError["child-"+item["technologyQuestionId"]+"-"+index] == true?
                                                                                                                <div className="InputFieldError">
                                                                                                                    <label style={{fontStyle: "italic", fontSize: "10px", position: "absolute", bottom: "-6px", color: "red"}}>* Please Enter Input</label>
                                                                                                                </div>:''
                                                                                                        }

                                                                                                    </div>
                                                                                                </div>
                                                                                             :''
                                                                                            :''


                                                                                    )):''
                                                                                }


                                                                            {item["child"] ?
                                                                                item["child"].map((itemChild, index) => (


                                                                                    <div class="col-lg-13"
                                                                                         style={(this.props.state.showMyChildComponent[itemChild.designUploadQuestionSet["parentQuestionId"]] == itemChild.designUploadQuestionSet["parentQuestionId"]
                                                                                             && this.props.state.showChildConditionalQuestion[itemChild.designUploadQuestionSet["parentQuestionId"]][itemChild["technologyQuestionId"]]=="true"
                                                                                             && ($("#"+item[0]["technologyQuestionId"]).children("option:selected").text() != "No"))? {} : { display: 'none' }
                                                                                         }
                                                                                    >
                                                                                        {(itemChild.designUploadQuestionSet.subQuestionConditionObject["codeValue"] == "Other" && $("#"+item[0]["technologyQuestionId"]).children("option:selected").text() == "Other") ||
                                                                                        (itemChild.designUploadQuestionSet.subQuestionConditionObject["codeValue"] != "Other") ?
                                                                                        <div class="brd_section">
                                                                                            <div class="row new_pos" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                                                                                                <div class="col-lg-5 col-md-5 col-sm-6" style={{paddingLeft: "5%"}}>
                                                                                                    <div class="inner_left" value={itemChild["technologyQuestionId"]}>
                                                                                                        <label className="left-padding">{itemChild.designUploadQuestionSet["question"]}</label>
                                                                                                    </div>
                                                                                                </div>

                                                                                                {itemChild.designUploadQuestionSet.subQuestionConditionObject["codeValue"] == "Yes/No" ?
                                                                                                    <div className="col-lg-7 col-md-7 col-sm-6">
                                                                                                        <div className="inner_right">
                                                                                                            <div className="form-group">
                                                                                                                <div className="custom_select">
                                                                                                                    <span>
                                                                                                                        <select id={itemChild["technologyQuestionId"]} onChange={(e) => this.props.HandleChildChange(e, itemChild, '671', "select")} name="ddlPackageType"
                                                                                                                                value={this.props.state.selectChildValue[itemChild["technologyQuestionId"]]?this.props.state.selectChildValue[itemChild["technologyQuestionId"]]:''}
                                                                                                                                disabled = {(this.props.state.disabledInput)? "disabled" : ""}>
                                                                                                                            <option value=''>Select</option>
                                                                                                                            {itemChild.technologyAnswerSets.map((itemChild, index) => (
                                                                                                                                <option value={itemChild["technologyAnswerId"]}>
                                                                                                                                    {itemChild.designUploadAnswerSet["answerValue"]}
                                                                                                                                </option>
                                                                                                                            ))
                                                                                                                            }
                                                                                                                        </select>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    :
                                                                                                    itemChild.designUploadQuestionSet.subQuestionConditionObject["codeValue"] == "Dropdown" ?
                                                                                                    <div className="col-lg-7 col-md-7 col-sm-6">
                                                                                                        <div className="inner_right">
                                                                                                            <div className="form-group">
                                                                                                                <div className="custom_select">
                                                                                                                    <span>
                                                                                                                        <select id={itemChild["technologyQuestionId"]} onChange={(e) => this.props.HandleChildChange(e, itemChild, '671', "select")} name="ddlPackageType"
                                                                                                                                value={this.props.state.selectChildValue[itemChild["technologyQuestionId"]]?this.props.state.selectChildValue[itemChild["technologyQuestionId"]]:''}
                                                                                                                                disabled = {(this.props.state.disabledInput)? "disabled" : ""}>
                                                                                                                            <option value=''>Select</option>
                                                                                                                            {itemChild.technologyAnswerSets.map((itemChild, index) => (
                                                                                                                                <option value={itemChild["technologyAnswerId"]} disabled={(itemChild.statusObject!=null && itemChild.statusObject.codeValue == "Inactive")?"disabled":""}>
                                                                                                                                    {itemChild.designUploadAnswerSet["answerValue"]}
                                                                                                                                </option>
                                                                                                                            ))
                                                                                                                            }
                                                                                                                        </select>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    :
                                                                                                    itemChild.designUploadQuestionSet.subQuestionConditionObject["codeValue"] == "Fill in"?
                                                                                                        <div className="col-lg-7 col-md-7 col-sm-6">
                                                                                                            <div className="inner_right">
                                                                                                                <div className="form-group">
                                                                                                                    <input type={itemChild.designUploadQuestionSet["questionDataTypeObject"]!=null?
                                                                                                                        itemChild.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Integer" || itemChild.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Float"? "text":'text' :'text'}
                                                                                                                           id={itemChild["technologyQuestionId"]} name="txtDesignNumber"
                                                                                                                           value={this.props.state.selectChildValue[itemChild["technologyQuestionId"]]?this.props.state.selectChildValue[itemChild["technologyQuestionId"]]:''}
                                                                                                                           onChange={(e) => this.props.HandleInputChildChange(e, itemChild)}
                                                                                                                           onBlur={(e) => this.props.HandleChildChange(e, itemChild, '671', "input")}
                                                                                                                           className="form-control" disabled = {(this.props.state.disabledInput)? "disabled" : ""}
                                                                                                                           required = {itemChild.designUploadQuestionSet["questionDataTypeObject"]!=null?
                                                                                                                               itemChild.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "String_AllowBlank" || itemChild.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Integer_AllowBlank"
                                                                                                                               || itemChild.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "ReadOnly"? "" : "required": "required"}
                                                                                                                           disabled={itemChild.designUploadQuestionSet["questionDataTypeObject"]!=null?itemChild.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "ReadOnly"?true:false:false}
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        :itemChild.designUploadQuestionSet.subQuestionConditionObject["codeValue"] == ">0"?
                                                                                                        (itemChild.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "File")?
                                                                                                            <div className="col-lg-7 col-md-7 col-sm-6">

                                                                                                                <div className="inner_right">
                                                                                                                    <div className="form-group" id={"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue+"_group"}
                                                                                                                         style={{display:this.props.state.showSecondStep == "none"?"block":"none"}}>
                                                                                                                        {/*id={itemChild["technologyQuestionId"]}*/}
                                                                                                                        <input type="file"

                                                                                                                               id={"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue}
                                                                                                                               className="fileType"
                                                                                                                               style={{fontSize: '12px'}}
                                                                                                                               onChange={(e) => this.props.HandleFileChange(e, itemChild.designUploadQuestionSet, itemChild)}
                                                                                                                               disabled = {(this.props.state.disabledInput)? "disabled" : ""}
                                                                                                                        />

                                                                                                                        <span id={"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue+"_span"} style={{display: 'none'}}>
                                                                                                                        <a id={"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue+"_fileLink"} style={{paddingRight:"2%", cursor: "auto"}}>
                                                                                                                            <span id={"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue+"_documentId"} style={{display: "none"}}></span>
                                                                                                                            <span id={"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue+"_fileName"}></span>
                                                                                                                        </a>

                                                                                                                        <input type="button" id="del_button" className="del_button" value="Delete" onClick={(e) => this.props.deleteFile(e, 'question_'+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue, itemChild.designUploadQuestionSet.additionalMappingObject)}/>


                                                                                                                    </span>
                                                                                                                        {this.props.state.enterInputError["question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue] == true?
                                                                                                                            <div className="InputFieldError" style={{height: 4}}>
                                                                                                                                <label style={{fontStyle: "italic", fontSize: "10px", position: "relative", bottom: 15, color: "red", marginLeft: "-100px"}}>* Please Select Input</label>
                                                                                                                            </div>:''
                                                                                                                        }

                                                                                                                        {/*<div id={"progress-bar-span_"+"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue} className="progress1" style={{display:"block", fontStyle: "italic", fontSize: "10px", position: "relative", bottom: 15, color: "red", marginLeft: "250px",height: "30px"}}>
                                                                                                                            <div id={"progress-bar_"+"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue} className="progress-bar progress-bar-striped" style={{width:'0%',backgroundColor:'green'}}></div>
                                                                                                                            <label id={"progress-bar-label_"+"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue} style={{display:"none", color:"red"}}>Please stay on this page until the file upload is in progress.</label>
                                                                                                                            <div id={"percent_"+"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue} className="percent" style={{display:"none"}}>0%</div>
                                                                                                                        </div>*/}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>:
                                                                                                            (itemChild.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Other")?
                                                                                                                <div className="col-lg-7 col-md-7 col-sm-6">
                                                                                                                    <div className="inner_right">
                                                                                                                        <div className="form-group">
                                                                                                                            <div className="custom_select">
                                                                                                                                <select onChange={(e) => this.props.HandleChildChange(e, itemChild, '670',"select")} name="ddlPackageType" id={itemChild["technologyQuestionId"]}
                                                                                                                                        value={this.props.state.selectChildValue[itemChild["technologyQuestionId"]] ? this.props.state.selectChildValue[itemChild["technologyQuestionId"]] : ''}
                                                                                                                                        disabled = {(this.props.state.disabledInput)? "disabled" : ""}>
                                                                                                                                    <option value=''>Select</option>
                                                                                                                                    {itemChild.technologyAnswerSets.map((itemChild, index) => (
                                                                                                                                        <option value={itemChild["technologyAnswerId"]}>
                                                                                                                                            {itemChild.designUploadAnswerSet["answerValue"]}
                                                                                                                                        </option>
                                                                                                                                    ))
                                                                                                                                    }
                                                                                                                                </select>
                                                                                                                                {/*{this.props.state.enterInputError[itemChild["technologyQuestionId"]] == true?
                                                                                                                                    <div className="InputFieldError">
                                                                                                                                        <label style={{fontStyle: "italic", fontSize: "10px", position: "absolute", color: "red", top: 20, left:0}}>* Please Enter Input</label>
                                                                                                                                    </div>:''
                                                                                                                                }*/}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>:
                                                                                                                <div className="col-lg-7 col-md-7 col-sm-6">
                                                                                                                    <div className="inner_right">
                                                                                                                        <div className="form-group">
                                                                                                                            <input type={itemChild.designUploadQuestionSet["questionDataTypeObject"]!=null?
                                                                                                                                itemChild.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Integer" || itemChild.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Float"? "text":'text' :'text'}
                                                                                                                                   id={itemChild["technologyQuestionId"]} name="txtDesignNumber"
                                                                                                                                   value={this.props.state.selectChildValue[itemChild["technologyQuestionId"]]?this.props.state.selectChildValue[itemChild["technologyQuestionId"]]:''}
                                                                                                                                   onChange={(e) => this.props.HandleInputChildChange(e, itemChild)}
                                                                                                                                   onBlur={(e) => this.props.HandleChildChange(e, itemChild, '671', "input")}
                                                                                                                                   className="form-control" disabled = {(this.props.state.disabledInput)? "disabled" : ""}
                                                                                                                                   required = {itemChild.designUploadQuestionSet["questionDataTypeObject"]!=null?
                                                                                                                                       itemChild.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "String_AllowBlank" || itemChild.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Integer_AllowBlank"? "" : "required": "required"}/>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                        :itemChild.designUploadQuestionSet.subQuestionConditionObject["codeValue"] == "Other" && $("#"+item[0]["technologyQuestionId"]).children("option:selected").text() == "Other"?
                                                                                                            <div className="col-lg-7 col-md-7 col-sm-6">
                                                                                                                <div className="inner_right">
                                                                                                                    <div className="form-group">
                                                                                                                        <input type={itemChild.designUploadQuestionSet["questionDataTypeObject"]!=null?
                                                                                                                            itemChild.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Integer" || itemChild.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Float"? "text":'text' :'text'}
                                                                                                                               id={itemChild["technologyQuestionId"]} name="txtDesignNumber"
                                                                                                                               value={this.props.state.selectChildValue[itemChild["technologyQuestionId"]]?this.props.state.selectChildValue[itemChild["technologyQuestionId"]]:''}
                                                                                                                               onChange={(e) => this.props.HandleInputChildChange(e, itemChild)}
                                                                                                                               onBlur={(e) => this.props.HandleChildChange(e, itemChild, '671', "input")}
                                                                                                                               className="form-control" disabled = {(this.props.state.disabledInput)? "disabled" : ""}
                                                                                                                               required = {itemChild.designUploadQuestionSet["questionDataTypeObject"]!=null?
                                                                                                                                   itemChild.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "String_AllowBlank" || itemChild.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Integer_AllowBlank"? "" : "required": "required"}/>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            :itemChild.designUploadQuestionSet.subQuestionConditionObject["codeValue"] == "File" ?
                                                                                                                <div className="col-lg-7 col-md-7 col-sm-6">

                                                                                                                    <div className="inner_right">
                                                                                                                        <div className="form-group" id={"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue+"_group"}
                                                                                                                             style={{display:this.props.state.showSecondStep == "none"?"none":"block"}}>
                                                                                                                            {/*id={itemChild["technologyQuestionId"]}*/}
                                                                                                                            <input type="file"

                                                                                                                                   id={"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue}
                                                                                                                                   className="fileType"
                                                                                                                                   style={{fontSize: '12px'}}
                                                                                                                                   onChange={(e) => this.props.HandleFileChange(e, itemChild.designUploadQuestionSet, itemChild)}
                                                                                                                                   disabled = {(this.props.state.disabledInput)? "disabled" : ""}
                                                                                                                            />
                                                                                                                            <span id={'question_'+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue.split(" ")[0]} style={{display:"none;"}}></span>

                                                                                                                            <span id={"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue+"_span"} style={{display: 'none'}}>
                                                                                                                    <a id={"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue+"_fileLink"} style={{paddingRight:"2%", cursor: "auto"}}>
                                                                                                                        <span id={"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue+"_documentId"} style={{display: "none"}}></span>
                                                                                                                        <span id={"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue+"_fileName"}></span>
                                                                                                                    </a>

                                                                                                                    <input type="button" id="del_button" className="del_button" value="Delete" onClick={(e) => this.props.deleteFile(e, 'question_'+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue, itemChild.designUploadQuestionSet.additionalMappingObject)}/>


                                                                                                                </span>
                                                                                                                            {this.props.state.enterInputError["question_"+item[0].designUploadQuestionSet.additionalMappingObject.codeValue] == true?
                                                                                                                    <div className="InputFieldError" style={{height: 4}}>
                                                                                                                        <label style={{fontStyle: "italic", fontSize: "10px", position: "relative", bottom: 15, color: "red", marginLeft: "-100px"}}>* Please Select Input</label>
                                                                                                                    </div>:''
                                                                                                                }

                                                                                                                            {/*<div id={"progress-bar-span_"+"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue} className="progress1" style={{display:"block", fontStyle: "italic", fontSize: "10px", position: "relative", bottom: 15, color: "red", marginLeft: "250px",height: "30px"}}>
                                                                                                                                <div id={"progress-bar_"+"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue} className="progress-bar progress-bar-striped" style={{width:'0%',backgroundColor:'green'}}></div>
                                                                                                                                <label id={"progress-bar-label_"+"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue} style={{display:"none", color:"red"}}>Please stay on this page until the file upload is in progress.</label>
                                                                                                                                <div id={"percent_"+"question_"+itemChild.designUploadQuestionSet.additionalMappingObject.codeValue} className="percent" style={{display:"none"}}>0%</div>
                                                                                                                            </div>*/}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>:
                                                                                                            <span></span>
                                                                                                }

                                                                                            </div>
                                                                                            {this.props.state.enterInputError[itemChild["technologyQuestionId"]] == true?
                                                                                                <div className="InputFieldError">
                                                                                                    <label style={{fontStyle: "italic", fontSize: "10px", position: "absolute", bottom: "-6px", color: "red"}}>* Please Enter Input</label>
                                                                                                </div>:''
                                                                                            }
                                                                                        </div>
                                                                                        :''}
                                                                                    </div>
                                                                                )):''
                                                                            }

                                                                        </div>

                                                                        :<span></span>







                                                                )):''
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="header">
                                        <h3>File Size </h3>
                                    </div>


                                    {this.props.state.parentArrValuesList.length > 0 ?
                                    <div className="accoutn_form">
                                        <form action="" method="">
                                            <div className="dashboard_form">
                                                <div className="inner-dashoard_form">
                                                    <div className="row">


                                                        <div className="col-lg-12">
                                                            <div className="brd_section">
                                                                <div className="row new_pos" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                                                                    <div className="col-lg-5 col-md-5 col-sm-6">
                                                                        <div className="inner_left" >
                                                                            <label>Is the design file larger than {this.props.state.fileSizeLimit.codeValue}</label>
                                                                        </div>
                                                                    </div>

                                                                            <div className="col-lg-5 col-md-8 col-sm-6">
                                                                                <div className="inner_right">
                                                                                    <div className="form-group">
                                                                                        <div style={{paddingLeft:"3%"}}>
                                                                                            <input style={{marginLeft: "0.5em"}} type="radio" id="designChoiceNo" name="designChoice" value="NO"
                                                                                                   checked={this.props.state.isFileSizeSelected === "false"}
                                                                                                   onChange={this.props.HandleFileSizeSelection}/>
                                                                                            <label htmlFor="designChoiceNo" style={{marginLeft: "2.5em", fontWeight:"bold"}}><span style={{marginLeft: "0.5em"}}>NO</span></label>

                                                                                            <input style={{marginLeft: "0.5em"}} type="radio" id="designChoiceYes" name="designChoice" value="YES"
                                                                                                   checked={this.props.state.isFileSizeSelected === "true"}
                                                                                                   onChange={this.props.HandleFileSizeSelection} />
                                                                                            <label style={{marginLeft: "2em", fontWeight:"bold"}} for="designChoiceYes"><span style={{marginLeft: "0.5em"}}>YES</span></label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                    {this.props.state.showFileSizeSelectionError == true?
                                                                        <div ><label style={{color: "red", fontStyle: "italic"}}>* Please Select Value</label></div>:''
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/*{ this.props.state.showFilNameInput == true?

                                                            <div className="col-lg-12">
                                                                <div className="brd_section">
                                                                    <div className="row new_pos">
                                                                        <div className="col-lg-10 col-md-4 col-sm-6">
                                                                            <div className="inner_left" >
                                                                                <label>Please upload the design file using SFTP command line or any SFTP client.</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>:<span></span>}*/}
                                    { this.props.state.showFilNameInput == true?

                                            <div className="col-lg-12">
                                                <div className="brd_section">
                                                    <div className="row new_pos" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                                                        <div className="col-lg-6 col-md-5 col-sm-6">
                                                            <div className="inner_left" >
                                                                <label>File Name (Please make sure the file that will be uploaded through SFTP has the exact same name and file type as it is mentioned here, or the SFTP upload will fail.)</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-8 col-sm-6">
                                                            <div className="inner_right">
                                                                <div className="form-group">
                                                                    <input type="text" id="fileNameInput" name="fileNameInput"
                                                                           value={this.props.state.fileNameInput}
                                                                           onChange={(e) => this.props.HandleFileNameAndSizeChange(e, "name")}
                                                                           className="form-control"/>

                                                                    <a onClick={this.props.ShowHideSftpInstructionModal} style={{color: "#990000", fontWeight: "bold", fontSize: "13px", paddingLeft: "3px"}}>
                                                                        Click to view SFTP Upload Instructions
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {this.props.state.showFileNameError == true?
                                                            <div ><label style={{color: "red", fontStyle: "italic", fontSize: "10px", position: "absolute", right: "23%", bottom: "10px"}}>* Please Enter FileName</label></div>:''
                                                        }
                                                    </div>
                                                </div>
                                            </div>:<span></span>}

                                            { this.props.state.showFilNameInput == true?
                                            <div className="col-lg-12">
                                                <div className="brd_section">
                                                    <div className="row new_pos" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                                                        <div className="col-lg-6 col-md-5 col-sm-6">
                                                            <div className="inner_left" >
                                                                <label>File Size (in Gigabytes)</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-8 col-sm-6">
                                                            <div className="inner_right">
                                                                <div className="form-group">
                                                                    <input type="number" id="fileSizeInput" name="fileSizeInput"
                                                                           value={this.props.state.fileSizeInput}
                                                                           onChange={(e) => this.props.HandleFileNameAndSizeChange(e, "size")}
                                                                           onBlur={this.props.HandleSizeOnBlur}
                                                                           className="form-control"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {this.props.state.showFileSizeError == true && this.props.state.showInvalidFileSizeError == false?
                                                            <div><label style={{color: "red", fontStyle: "italic", fontSize: "10px", position: "absolute", bottom: "-10px", right: "23%"}}>* Please Enter FileSize</label></div>:''
                                                        }

                                                    </div>
                                                    {this.props.state.showInvalidFileSizeError == true?
                                                        <div className="InputFieldError2"><label style={{fontStyle: "italic", fontSize: "10px", position: "absolute", bottom: "-10px", color: "red"}}>* Please Enter FileSize greater than {this.props.state.fileSizeLimit.codeValue}</label></div>:''
                                                    }
                                                </div>
                                            </div>:<span></span>}




                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>:''
                                    }



                                </div>
                                <div class="text-center">
                                    {this.props.state.selectQuestionAnswers == true?
                                        <div ><label style={{color: "red", fontStyle: "italic"}}>* Please enter atleast 1 Answer</label></div>:''
                                    }
                                    {this.props.state.enterAllQAError == true?
                                        <div ><label style={{fontStyle: "italic", fontSize: "13px", position: "absolute", bottom: "-5px", color: "red", left: "25px"}}>{this.props.state.allQAErrorContent}</label></div>:''
                                    }
                                    <div className="text-center">
                                        <button type="button" className="dash_submit_btn" id="back-btn-1" onClick={() => this.props.ShowHideSteps('showfirst')}
                                                style={{marginLeft: 10, backgroundColor: '#dfdfdf', color: '#990000'}} disabled = {(this.props.state.disabledFormButtonsOnSubmit)? "disabled" : ""}>Back
                                        </button>
                                        <button type="submit" className="dash_submit_btn" onClick={() => this.props.SubmitClick()} disabled = {(this.props.state.disabledFormButtonsOnSubmit)? "disabled" : ""} >
                                            Submit
                                        </button>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                { this.props.state.showEditSelection == true?
                    <div>
                    <label>Do you want to use the previous question answer for this revision ?</label>
                        <br/>
                        <div style={{paddingLeft:"3%", marginLeft: "2.5em"}}>
                            <label>USE</label>
                            <input style={{marginLeft: "0.5em"}} type="radio" id="designChoice" name="designChoice" value="USE" onChange={this.props.HandleEditSelection}/>


                            <label style={{marginLeft: "2.5em"}}>UPDATE</label>
                            <input style={{marginLeft: "0.5em"}} type="radio" id="designChoice" name="designChoice" value="UPDATE" onChange={this.props.HandleEditSelection}/>

                        </div>
                    </div>:<span></span>}
                { this.props.state.showPopup && this.props.state.foundryAndTechClass.length>0?
                    <table style={{width: '50%', textAlign:"center", marginLeft:"auto", marginRight:"auto"}}>
                        <tbody>

                        <tr>
                            <th>Design Number</th>
                            <th>Rev #</th>
                            <th>Upload Date</th>
                            <th>Foundry</th>
                            <th>Technology Node</th>
                            <th>Select</th>
                        </tr>
                        {/*<tr>
                                    <td>{item.designNumberInfo["designNumber"]}</td>
                                    <td>{item.customerDesignUpload["revisionNumber"]}</td>
                                    <td>{item.designNumberInfo["createdDate"]}</td>
                                    <td>{item.foundry["codeValue"]}</td>
                                    <td>{item.techNode["codeValue"]}</td>
                                    <input type="radio" id="designChoice" name="designChoice" value={item.technologyClass["codeId"]}
                                           onChange={this.props.HandleChange}
                                    />

                                </tr>*/}
                     {this.props.state.foundryAndTechClass.length>0?
                            this.props.state.foundryAndTechClass.map((item, index) => (

                         <tr>
                             <td>{item.designNumber}</td>
                             <td>{item.revisionNumber}</td>
                             <td>{item.uploadDate}</td>
                             <td>{item.foundry}</td>
                             <td>{item.technologyNode}</td>
                             <input type="radio" id="designChoice" name="designChoice" value={item.technologyNodeId}
                                    onChange={this.props.HandleChange}
                             />

                         </tr>
                            )):''
                        }

                        </tbody>
                    </table>
                    :<span></span>}

            </div>




        )
    }
}
