import React, { Component } from 'react';

import LogoutComponent from './logout';

export default class LogoutCtrl extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
            <LogoutComponent {...this.props} />
        </div>
    )
    }
}