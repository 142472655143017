import React, { Component } from 'react';
import $ from 'jquery';
export default class FooterComponent extends Component {
    constructor(props) {
        super(props);
        this.route = ['registration', 'foundry'];
    }

    componentDidMount = () => {
        // && !(window.location.href).includes('foundry')
        if (!(window.location.href).includes('registration') && !(window.location.href).includes('contact')){
            $('#footerSectionId').css('position', 'absolute')
            $('#footerSectionId').css('bottom', '0')
        }
        else{
            $('#footerSectionId').css('position', 'relative')
        }
    }
    componentDidUpdate = () => {
        // && !(window.location.href).includes('foundry')
        if (!(window.location.href).includes('registration') && !(window.location.href).includes('contact')){
            $('#footerSectionId').css('position', 'absolute')
            $('#footerSectionId').css('bottom', '0')
        }
        else{
            $('#footerSectionId').css('position', 'relative')
        }
    }
    render() {
        return (
            <section id="footerSectionId" className="footer">
                <div className="footer_content">© The MOSIS Service - USC Information Sciences Institute</div>
            </section>
        )
    }
}