import React, { Component } from 'react';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import $ from 'jquery';
import closeImg from '../../../assets/images/close.svg'
import { PrConnect } from '../../../library/pr-miniredux';
import DesignFileUpload from './DesignFileUpload'


import { BrowserRouter, Route } from 'react-router-dom'
import { SaveOtherDesignUploadList } from '../../../services/otherDesignUploadService'


import {
    GetFoundriesList,
    GetTechListByFoundry,
    GetScheduleByFoundryAndTech,
    GetMilestoneByFoundryAndShipDate,
    GetCountryList,
    GetRunTypeList,
    GetOrgTypesList,
    UserRegistration
} from '../../../services/users';
import PrValidate from "../../../library/pr-controls/pr-validation-full";
import {PrModalDialog} from "../../../library/pr-modal/pr-modal-dialog";
import RegisterComponent from "../../Register/register";
import FoundaryService from "../Foundry Services/foundaryservice";
import { Redirect } from "react-router-dom";

import PropTypes from "prop-types";
import { withRouter } from "react-router";
import {getCustomerDetails} from "../../../services/customerService";




export class designFileUploadCtrl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numberOfDesignUploadDivs: [],
            totalRows:1,
            txtFileName:'',
            txtFileSize:'',
            txtFileType:'',
            txtFileUploadPurpose:'',
            txtFileUploadDescription:'',
            otherFileUploadData:{},
            saveOtherFileUploadData: false,
            validationCheck: false
        }

    }


    UNSAFE_componentWillMount = () => {

        console.log("Inside UNSAFE_componentWillMount")
        console.log(JSON.parse(localStorage.getItem('okta-token-storage')))
        /*this.state.fetchCustomerDetails = true
        if (JSON.stringify(this.state.foundriesList === '{}')) {
            let that = this;
            that.setState({
                fetchFoundries: true
            });
        }*/


    }

    HandleChange = (e, i, key = "") => {
        //console.log("e.target.name: "+e.target.name)
        console.log("e.target.value: "+e.target.value)
        /*this.setState({
            [e.target.name]: e.target.value
        })*/

        var numberOfDesignUploadDivs = Array.from(this.state.numberOfDesignUploadDivs);
        const re = /^[0-9\b]+$/;


        let txtInputDieSize = PrValidate.ForceEntry((e.target.value).replace(/[^0-9]/g, ''), 'txtInputDieSize', 'a die size');

        if(e.target.value != null){
            if(i!=null){
                if(key == "txtFileSize") {
                    if (e.target.value.match(/^(?:\d{1,6}|(?!.{7})\d+\.\d+)$/g) || e.target.value.match(/^(?:\d{1,6}|(?!.{7})\d+\.)$/g) || e.target.value == "") {
                        numberOfDesignUploadDivs[i][key] = e.target.value;
                        this.setState({ numberOfDesignUploadDivs: numberOfDesignUploadDivs });
                        this.CheckEmptyValidationsOnChange(key+"_"+i, e.target.value)
                    }
                }else{
                    numberOfDesignUploadDivs[i][key] = e.target.value;
                    this.setState({ numberOfDesignUploadDivs: numberOfDesignUploadDivs });
                    this.CheckEmptyValidationsOnChange(key+"_"+i, e.target.value)
                }
                console.log("KEY=======================")
                console.log(key+"_"+i)

            }else{
                if(key == "txtFileSize") {
                    if (e.target.value.match(/^(?:\d{1,6}|(?!.{7})\d+\.\d+)$/g) || e.target.value.match(/^(?:\d{1,6}|(?!.{7})\d+\.)$/g) || e.target.value == "") {
                        this.setState({
                            [e.target.name]: e.target.value
                        })
                        this.CheckEmptyValidationsOnChange(key, e.target.value)
                    }
                }else{
                    if(key == "txtFileType"){
                        this.state.txtFileType = e.target.value
                    }
                    this.setState({
                        [e.target.name]: e.target.value
                    })
                    this.CheckEmptyValidationsOnChange(key, e.target.value)
                }

            }
        }else{
            console.log("Incorrect value");
        }

        //this.CheckEmptyValidations("onChange")
        //}
        
        //PrValidate.ForceEntry(e.target.value, e.target.name, 'Input')
    }

    onBlurFileSize = (e, i, key = "") => {
        let val = "";
        if(e.target.value.length > 0 && this._isNumber(e.target.value)){
            val = e.target.value;
            val = this.roundOff(val, 1);
        }

        e.target.value = val;
        console.log("Inside onChangeInputDieSize: "+e.target.value)



        if(key != null){
            var numberOfDesignUploadDivs = Array.from(this.state.numberOfDesignUploadDivs);
            numberOfDesignUploadDivs[i][key] = e.target.value;
            this.setState({ numberOfDesignUploadDivs: numberOfDesignUploadDivs });
        }else{
            this.setState({
                [e.target.name]: e.target.value,
            })
        }

    }

    _isNumber(input) {
        return !(typeof input == 'undefined' || isNaN(input));
    }

    roundOff(num, toDecimals) {
        if (!this._isNumber(num))
            throw num;

        return parseFloat(String(num)).toFixed(this.normalizeNumber(toDecimals));
    }

    normalizeNumber(num) {
        return this._isNumber(num) ? Number(num) : 0;
    }



    addFileUploadClick = () => {
        let values = [];
        for (let k = 0; k < this.state.totalRows; k++) {
            if(document.getElementById("fileUploadDiv_"+k) == null && k<4){
                console.log("inside if")
                let id="fileUploadDiv_"+k
                //this.state.numberOfDesignUploadDivs.push({ id: "fileUploadDiv_"+k})
                
                this.state.numberOfDesignUploadDivs.push({ ['txtFileName']: '', ['txtFileSize']: '', ['txtFileType']: '', ['txtFileUploadPurpose']: '', ['txtFileUploadDescription']:''})
            }
            //datas.push({ ['txtDesignFirstName_' + k]: '', ['txtDesignLastName_' + k]: '', ['txtDesignTitle_' + k]: '', ['txtDesignEmail_' + k]: '', ['txtDesignTelNo_' + k]: '' })
        }

        console.log("this.state.numberOfDesignUploadDivs: "+JSON.stringify(this.state.numberOfDesignUploadDivs))
        this.setState({
            addData: true,
            numberOfDesignUploadDivs: this.state.numberOfDesignUploadDivs,
            totalRows: Number(this.state.totalRows + 1)
        })


       /*this.setState({
           numberOfDesignUploadDivs: Number(this.state.numberOfDesignUploadDivs)+1
       })*/
     
    }

    deleteFileUploadClick = (id) => {
        console.log("id: "+id)
        var rows = this.state.numberOfDesignUploadDivs;
        rows.splice(id, 1);
        let remain_row = (this.state.totalRows) - 1;
        this.setState({
            numberOfDesignUploadDivs: rows,
            totalRows: remain_row});

       /* console.log("id: "+id)
        let remain_row = (this.state.totalRows) - 1;
        let values = [...this.state.numberOfDesignUploadDivs];
        values.splice(id, 1);
        //this.setState({ values, totalRows: remain_row });
        console.log("ID inside deleteFileUploadClick: "+id)
        console.log("this.state.values: "+JSON.stringify(this.state.values))
        this.setState({
            numberOfDesignUploadDivs:values,
            totalRows: remain_row
            //numberOfDesignUploadDivs: Number(this.state.numberOfDesignUploadDivs)-1
        })*/

        console.log("vals=========: "+$("#txtFileName_"+Number(id+1)).val())
        console.log("vals=========: "+$("#txtFileSize_"+Number(id+1)).val())
        console.log("vals=========: "+$("#txtFileType_"+Number(id+1)).val())
        console.log("vals=========: "+$("#txtFileUploadPurpose_"+Number(id+1)).val())
        console.log("vals=========: "+$("#txtFileUploadDescription_"+Number(id+1)).val())


        $("#err_txtFileName_"+Number(id+1)).length? this.CheckEmptyValidationsOnChange("txtFileName_"+id, $("#txtFileName_"+Number(id+1)).val()):
            this.CheckEmptyValidationsOnChange("txtFileName_"+id, "test")
        $("#err_txtFileSize_"+Number(id+1)).length?this.CheckEmptyValidationsOnChange("txtFileSize_"+id, $("#txtFileSize_"+Number(id+1)).val()):
            this.CheckEmptyValidationsOnChange("txtFileSize_"+id, "123")
        $("#err_txtFileType_"+Number(id+1)).length?this.CheckEmptyValidationsOnChange("txtFileType_"+id, $("#txtFileType_"+Number(id+1)).val()):
            this.CheckEmptyValidationsOnChange("txtFileType_"+id, "test")
        $("#err_txtFileUploadPurpose_"+Number(id+1)).length?this.CheckEmptyValidationsOnChange("txtFileUploadPurpose_"+id, $("#txtFileUploadPurpose_"+Number(id+1)).val()):
            this.CheckEmptyValidationsOnChange("txtFileUploadPurpose_"+id, "test")
        $("#err_txtFileUploadDescription_"+Number(id+1)).length?this.CheckEmptyValidationsOnChange("txtFileUploadDescription_"+id, $("#txtFileUploadDescription_"+Number(id+1)).val()):
            this.CheckEmptyValidationsOnChange("txtFileUploadDescription_"+id, "test")
        
    }

    removeClick = (i) => {
        console.log("I: "+i)
        console.log(this.state.totalRows)
        let remain_row = (this.state.totalRows) - 1;
        let values = [...this.state.values];
        values.splice(i, 1);
        this.setState({ values, totalRows: remain_row });
    }

    successHandlerSaveOtherDesignUploadList = (res) => {
        console.log("INSIDE successHandlerSaveOtherDesignUploadList")
        console.log("res: "+JSON.stringify(res))
        ToastsStore.success("Successfully Saved.");

        this.setState({
            saveOtherFileUploadData: false
        })

        setTimeout(function() { //Start the timer
            this.props.componentprops.history.push("/dashboard");
        }.bind(this), 2000)
    }

    CheckEmptyValidationsOnChange = (name, value) => {
        if(name.includes("txtFileUploadPurpose")){
            let txtFileUploadPurpose = PrValidate.ForceEntry(value, name, 'Upload Purpose');
            console.log("txtFileUploadPurpose: "+txtFileUploadPurpose)
            if(txtFileUploadPurpose){
                $('#' + name).attr('style', 'min-height: 100px; max-height: 100px; overflow: auto');
            }else{
                console.log("inside else")
                $('#' + name).attr('style', 'background-color: #F3B83E!important; border-radius: 8px; min-height: 100px; max-height: 100px; overflow: auto');
            }

        }/*else if(name.includes("txtFileUploadDescription")){
            let txtFileUploadDescription = PrValidate.ForceEntry(value, name, 'Upload Description');
        }*/else if(name.includes("txtFileName")){
            let txtFileName = PrValidate.ForceEntry(value, name, "File Name");
        }else if(name.includes("txtFileSize")){
            let txtFileSize = PrValidate.ForceEntry(value, name, "File Size");
        }else if(name.includes("txtFileType")){
            let txtFileType = PrValidate.ForceSelect(value, name, "File Type");
        }
    }

    CheckEmptyValidations = (eventName) => {
        let txtFileUploadPurpose = PrValidate.ForceEntry(this.state.txtFileUploadPurpose, 'txtFileUploadPurpose', 'Upload Purpose');
        //let txtFileUploadDescription = PrValidate.ForceEntry(this.state.txtFileUploadDescription, 'txtFileUploadDescription', 'Upload Description');
        let txtFileName = PrValidate.ForceEntry(this.state.txtFileName, 'txtFileName', "File Name");
        let txtFileSize = PrValidate.ForceEntry(this.state.txtFileSize, 'txtFileSize', "File Size");
        console.log("this.state.txtFileType: "+this.state.txtFileType)
        let txtFileType = PrValidate.ForceSelect(this.state.txtFileType, 'txtFileType', "File Type");
        console.log("txtFileType: "+txtFileType)

        console.log("numberOfDesignUploadDivs: "+JSON.stringify(this.state.numberOfDesignUploadDivs));

        let txtFileUploadPurposeArr = {}
        //let txtFileUploadDescriptionArr = {}
        let txtFileNameArr = {}
        let txtFileSizeArr = {}
        let txtFileTypeArr = {}
        this.state.numberOfDesignUploadDivs.map(function (item, index) {
            txtFileUploadPurposeArr[index] = PrValidate.ForceEntry(item["txtFileUploadPurpose"], 'txtFileUploadPurpose_'+index, 'Upload Purpose');
            //txtFileUploadDescriptionArr[index] = PrValidate.ForceEntry(item["txtFileUploadDescription"], 'txtFileUploadDescription_'+index, 'Upload Description');
            txtFileNameArr[index] = PrValidate.ForceEntry(item["txtFileName"], 'txtFileName_'+index, "File Name");
            txtFileSizeArr[index] = PrValidate.ForceEntry(item["txtFileSize"], 'txtFileSize_'+index, "File Size");
            txtFileTypeArr[index] = PrValidate.ForceSelect(item["txtFileType"], 'txtFileType_'+index, "File Type");
        })

        let err_arr = [txtFileUploadPurpose, txtFileName, txtFileSize, txtFileType];
        let itemToScroll = ["txtFileUploadPurpose", "txtFileName", "txtFileSize", "txtFileType"];

        this.state.numberOfDesignUploadDivs.map(function (item, index) {
            err_arr.push(txtFileUploadPurposeArr[index], txtFileNameArr[index], txtFileSizeArr[index], txtFileTypeArr[index])
            itemToScroll.push('txtFileUploadPurpose_'+index, 'txtFileName_'+index, 'txtFileSize_'+index, 'txtFileType_'+index);
        })

        let chk_false = 0;
        let breakCondition = false;
        err_arr.forEach(function (element, index) {
            if (!element && !breakCondition) {
                chk_false++;
                console.log("itemToScrollIndex: " + itemToScroll[index])

                if(eventName == "onSubmit"){$('html, body').animate({
                    scrollTop: $("#" + itemToScroll[index]).offset().top - 100
                }, 1000);}
                breakCondition = true
            }
        })

        this.setState({
            validationCheck: !breakCondition
        })
        this.state.validationCheck = !breakCondition
        console.log("BREAK CONDITION============================: "+breakCondition)

    }

    SubmitOtherDesignUpload = () => {
        console.log("numberOfDesignUploadDivs: "+JSON.stringify(this.state.numberOfDesignUploadDivs))
        this.CheckEmptyValidations("onSubmit");

        let otherFileUploadDetailsList = []
console.log("this.state.validationCheck: "+this.state.validationCheck)
        if(this.state.validationCheck == true){
            otherFileUploadDetailsList.push({
                "uploadPurpose": this.state.txtFileUploadPurpose,
                "uploadDescription": this.state.txtFileUploadDescription,
                "fileName": this.state.txtFileName,
                "fileSize": this.state.txtFileSize,
                "fileSizeType": this.state.txtFileType
            })
            this.state.numberOfDesignUploadDivs.map(function (item, index) {
                otherFileUploadDetailsList.push({
                    "uploadPurpose": item["txtFileUploadPurpose"],
                    "uploadDescription": item["txtFileUploadDescription"],
                    "fileName": item["txtFileName"],
                    "fileSize": item["txtFileSize"],
                    "fileSizeType": item["txtFileType"]
                })


            })
            console.log("otherFileUploadDetailsList: "+JSON.stringify(otherFileUploadDetailsList))
            this.state.otherFileUploadData["otherFileUploadDetails"] = otherFileUploadDetailsList;


            console.log("this.state.otherFileUploadData: "+JSON.stringify(this.state.otherFileUploadData))
            this.setState({
                otherFileUploadData: this.state.otherFileUploadData,
                saveOtherFileUploadData: true
            })
        }else{
            console.log("There are some errors");
        }
        
        console.log("otherFileUploadData final data: "+JSON.stringify(this.state.otherFileUploadData))
    }


/*{
    "otherFileUploadDetailsList":[	{
        "customerId":502,
        "uploadPurpose":"fgjgfjsfj",
        "fileName":"gfgsghsdg",
        "fileSize":62611,
        "fileSizeType":"KB",
        "actualFileSize":1213,
        "uploadToS3Status":1
    },
        {
            "customerId":502,
            "uploadPurpose":"fasfasf",
            "fileName":"rqrqrqwr",
            "fileSize":646,
            "fileSizeType":"MB",
            "actualFileSize":959,
            "uploadToS3Status":1
        }
    ]
}*/






    render() {

        return (
            <div>
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_CENTER} />
                <DesignFileUpload {...this.props} state={this.state} HandleChange={this.HandleChange}
                                  foundries={this.state.foundriesList} techNodes={this.state.techNodesList}
                                  runType={this.state.runTypeList} packageType={this.state.packageTypeList}
                                  SubmitDesign={this.SubmitDesign} HandleOnBlur={this.HandleOnBlur}
                                  HandlePreliminaryDesignUploadDateChange={this.HandlePreliminaryDesignUploadDateChange}
                                  HandleProjectedFinalDesignUploadDateChange={this.HandleProjectedFinalDesignUploadDateChange}
                                  highlightText={this.highlightText}
                                  onOkClick={this.onOkClick}
                                  addFileUploadClick={this.addFileUploadClick}
                                  deleteFileUploadClick={this.deleteFileUploadClick}
                                  SubmitOtherDesignUpload={this.SubmitOtherDesignUpload}
                                  onBlurFileSize={this.onBlurFileSize}/>

                {this.state.saveOtherFileUploadData === true ? SaveOtherDesignUploadList(this.props.PrStore, this.state.otherFileUploadData, this.successHandlerSaveOtherDesignUploadList) : ''}
            </div>
        )
    }
}


export const DesignFileUploadCtrl = PrConnect(designFileUploadCtrl, '');
