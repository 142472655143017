import React, { Component } from 'react';
import $ from "jquery";

export default class ExportControlComponent extends Component {

    ClearMouse = () => {
    $('.inner_table').css('display', 'none');
}
render() {
    return (
        <div id="page-wrapper">
        <div id="page-inner" style={{ background: "#f8f4e9" }}>
<div className="page_head">
        <h1 className="page-header">Export Control Questionnare</h1>
    <div className="row" style={{width:"75%", marginLeft: "auto", marginRight: "auto"}}>
        <div className="col-md-12">
        <div className="panel">
        <div className="panel-body" style={{ padding: "0px" }}>
<div className="table-responsive" style={{ overflowX: "auto" }}>

    <table className="table table-striped table-hover" id="dataTables-example">
        <thead>
        <tr>

                <th key={0} column={0} onClick={this.props.handleSort} style={{fontSize: "18px"}}>
            Design Numbers
            </th>
            <th key={1} column={1} onClick={this.props.handleSort} style={{fontSize: "18px"}}>
                Production Id
            </th>
            <th class="downloadLabel">
            </th>



    </tr>
    </thead>
        <tbody>
        {this.props.state.customerDesigns.length > 0?
        this.props.state.customerDesigns.map((item, index) =>
            item.deignNumberList.length>0 || item.productionIdList.length>0?
                <tr>
                    {item["deignNumberList"]!=null?
                    <td style={{paddingLeft: "2%"}}>
                    {item.deignNumberList.map((deignNumberItem, deignNumberIndex) =>
                        <span>{deignNumberItem.designNumberInfo["designNumber"]}
                            {deignNumberIndex != item.deignNumberList.length-1?
                                <span>, </span>:''
                            }
                        </span>
                    )}
                    </td>:''}
                    {item["productionIdList"]!=null?
                        <td style={{paddingLeft: "2%"}}>
                            {item.productionIdList.map((productionIdItem, productionIdIndex) =>
                                <span>{productionIdItem.productionIdInfo["productionId"]}
                                    {productionIdIndex != item.productionIdList.length-1?
                                        <span>, </span>:''
                                    }
                                </span>
                            )}
                        </td>:''}
                    <td>
                        {item.deignNumberList.length>0 || item.productionIdList.length>0?
                            <a width="30" style={{
                                cursor: "pointer",
                                fontWeight: "bold",
                                color:"#990100"
                            }} src={require("../../../assets/images/doc_add.png")} onClick={()=>this.props.redirectToQuestionnare(item)} alt="delete logo" >
                                Edit<img style={{width: "22px", marginLeft: "3%"}} src={require('../../../assets/images/doc_add.png')} />
                            </a>:''
                        }
                    </td>
                </tr>:''

        ):''}
        {/*onClick={this.props.deleteFileUploadClick.bind(this, i)}*/}
            </tbody>
        </table>
</div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
)
}
}