import React, { Component } from 'react';
import $ from 'jquery';
export default class QuickContactComponent extends Component {
    constructor(){
        super();
    }


    ToogglesideMenu = () => {
        $('#form_horizental_demo').addClass('show_getintouch');
    }
    ClosesideMenu = () => {
        $('#form_horizental_demo').removeClass('show_getintouch');
    }

    render() {
        return (
            <section className="">
                {/* <div className="icon-bar"  onClick={this.ToogglesideMenu}>
                    <span className="side_logo">
                    <h6>Quick Contact</h6>
                    </span> 
                </div> */}
                <div className="form-bg">
                    <div className="container">
                    <div className="row">
                        <div className="col-md-offset-4 col-md-4 col-sm-offset-3 col-sm-6">
                        <form className="form-horizontal" id="form_horizental_demo">
                            <div className="form_close" onClick={this.ClosesideMenu}>
                                <img src={require('../../assets/images/close.svg')} alt="logo" />
                            </div>
                            <h5 className="text-center" style={{color: '#fff', paddingBottom: '15px'}}>Quick Contact</h5>
                            <div className="form-group">
                            <input className="form-control" type="name" placeholder="Name" />
                            </div>
                            <div className="form-group">
                            <input className="form-control" type="email" placeholder="Email" />
                            </div>
                            <div className="form-group">
                            <textarea placeholder="Message" defaultValue={""} />
                            </div>
                            <button type="submit" className="btn btn-default">Submit</button>
                        </form>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        )
    }
}
