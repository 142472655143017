import React, { Component } from 'react';
import $ from 'jquery';
export default class Popup extends Component {
    ClearMouse = () => {
        $('.inner_table').css('display', 'none');
    }
    render() {
        return (
            <div id="page-wrapper">
                <div id="page-inner" style={{ background: '#fff' }}>
                    {/* <div className="page_head col-lg-12" style={{ marginLeft: 17 + '%' }}> */}
                    <div className="page_head col-lg-20">
                            <div class="row" style={(this.props.state.showFileUploadMessage===false )? {} : { display: 'none' }}>
                                <div class="col-lg-25 col-md-25 mx-auto">
                                    <div>
                                        <label>Do you want to use the previous question answer for this revision ?</label>
                                        <br/>
                                        <div style={{paddingLeft:"3%", marginLeft: "2.5em"}}>
                                            <label>USE</label>
                                            <input style={{marginLeft: "0.5em"}} type="radio" id="designChoice" name="designChoice" value="USE" onChange={this.props.handleRadioBoxChange}/>


                                            <label style={{marginLeft: "2.5em"}}>UPDATE</label>
                                            <input style={{marginLeft: "0.5em"}} type="radio" id="designChoice" name="designChoice" value="UPDATE" onChange={this.props.handleRadioBoxChange}/>

                                        </div>
                                    </div>

                                </div>
                            </div>


                        <div className="row" style={(this.props.state.showFileUploadMessage===true || this.props.state.showFileUploadSelection===true )? {} : { display: 'none' }}>
                            <div className="col-lg-25 col-md-25 mx-auto">
                                <div>
                                    <label>You have already answered the questions for the design number , Do you want to upload the design files ?</label>
                                    <br/>
                                    <div style={{paddingLeft: "3%", marginLeft: "2.5em"}}>
                                        <label>YES</label>
                                        <input style={{marginLeft: "0.5em"}} type="radio" id="designChoice" name="designChoice" value="YES" onChange={this.props.handleFileUploadSelection}/>


                                        <label style={{marginLeft: "2.5em"}}>NO</label>
                                        <input style={{marginLeft: "0.5em"}} type="radio" id="designChoice" name="designChoice" value="NO" onChange={this.props.handleFileUploadSelection}/>

                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </div>
        )
    }
}