import React, { Component } from 'react';
import LoginComponent from './login';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';

export default class LoginCtrl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            txtLoginEmail: '',
            txtLoginPassword: '',
            isChecked: true
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    HandleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSubmit(e) {
        e.preventDefault();
        this.oktaAuth.signIn({
            username: this.state.username,
            password: this.state.password
        })
            .then(res => this.setState({
                sessionToken: res.sessionToken
            }))
            .catch(err => console.log('Found an error', err));
    }

    LoginUser = () => {
        if (this.state.txtLoginEmail !== '' && this.state.txtLoginPassword !== '') {
            ToastsStore.success("Call Ajax For Login")
        }
        else
            ToastsStore.error("All Fields are Mandatory")
    }

    HandleChecked = () => {
        this.setState({isChecked: !this.state.isChecked});
      }

    render() {
        return (
            <div>
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_CENTER} />
                <LoginComponent {...this.props} state={this.state} HandleChecked={this.HandleChecked}
                    HandleChange={this.HandleChange} LoginUser={this.LoginUser} />
            </div>
        )
    }
}