import React, { Component } from 'react';
import TestService from './testservice';
import DesignUpload from './DesignUpload';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import $ from 'jquery';
import closeImg from '../../../assets/images/close.svg'
import { PrConnect } from '../../../library/pr-miniredux';
import { getDesignUploadQuestionGroup, getQuestionsByTechClass, getFoundryAndTechClass, getFoundryAndTechClassByCustomerId ,submitQuestionAnswers, getQuestionAnswersByDesignUploadId,
    getFileSizeLimit} from '../../../services/designUploadQuestion';
import { TableDesignCtrl } from '../TableDesign';
import { PopupCtrl } from '../Test/Popup';
import {ConfirmationPopupCtrl} from '../Test/ConfirmationPopup';
import {UploadPopupCtrl} from '../Test/UploadPopup';
import { BrowserRouter, Route } from 'react-router-dom'
import {ServicesDesignFileUploadComponentCtrl} from '../../ServicesDesignFileUpload/ServicesDesignFileUpload'
import {DesignUploadFTPGuideComponentCtrl} from './DesignUploadFTPGuide'
import SFTPInstructionPopup from './SFTPInstructionPopup/sftpInstructionPopup'
import S3 from "react-aws-s3"
import AWS from 'aws-sdk'

/*import {AWS} from 'https://sdk.amazonaws.com/js/aws-sdk-SDK_VERSION_NUMBER.min.js'*/
import RNS3 from 'react-native-upload-aws-s3';

import {
    GetFoundriesList,
    GetTechListByFoundry,
    GetScheduleByFoundryAndTech,
    GetMilestoneByFoundryAndShipDate,
    GetCountryList,
    GetRunTypeList,
    GetOrgTypesList,
    UserRegistration
} from '../../../services/users';
import PrValidate from "../../../library/pr-controls/pr-validation-full";
import {PrModalDialog} from "../../../library/pr-modal/pr-modal-dialog";
import RegisterComponent from "../../Register/register";
import FoundaryService from "../Foundry Services/foundaryservice";
import { Redirect } from "react-router-dom";

import PropTypes from "prop-types";
import { withRouter } from "react-router";
import {getCustomerDetails} from "../../../services/customerService";
import {GetUploadFileList} from "../../../services/designUploadQuestion";




export class testServicesCtrl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetchFoundries: false,
            foundriesList: {},
            fetchTechNode: false,
            techNodesList: {},
            fetchSchedule: false,
            scheduleList: {},
            fetchMilestone: false,
            milestoneList: {},
            milestoneDate: '',
            milestoneId: '',
            ddlTechNode: '',
            ddlFoundry: '',
            fabScheduleId: '',
            foundryName: '',
            techNodeName: '',
            td_width: '25%',

            fetchUploadQuestionList: false,
            uploadQuestionList: '',
            showQuestionDiv: false,

            fetchQuestionByTypeAndTechClass: false,
            questionByTypeAndTechClass: {},
            questionType: '',
            techClass: '',

            foundryValue: '',
            techNodeValue: '',
            questionTypeValue: '',
            questionByTypeAndTechClassList: {},
            groupArr: {},
            childQuestionList: {},

            parentArr: {},
            parentArrList: {},
            parentArrValuesList: {},
            arrayFilled: '',

            showPopup: false,
            fetchFoundryAndTechClass: false,
            foundryAndTechClass: {},
            txtDesignNumber: '',

            txtFoundry: '',
            txtTechNode: '',


            fetchRunType: true,
            runTypeList: {},
            fetchPackageType: true,
            packageTypeList: {},
            postDesignData: false,


            showFirstStep: 'block',
            showSecondStep: 'none',
            showThirdStep: 'none',

            chipsQty: '',

            txtDesignProjectName: '',
            txtDesignSize: '',
            txtQtyOrdered: '',
            txtChipsQty: '',

            ddlPackageType: '',
            txtPreferredPackageVendor: '',
            ddlDieRotation: '',
            ddlBondingSrc: '',
            ddlDownloadLocations: '',

            txtNoOfPads: '',

            txtTopcellDesignName: '',
            ddlRequestDieThicknessMils: '',
            ddlBondPadOrBumpDesign: '',
            ddlProcessModeOfDesign: '',
            ddlProcessCoreOfDesign: '',
            ddlVoltageDeviceOfDesign: '',
            ddlTopMetalInDesign: '',
            ddlMetalStack: '',
            txtThicknesUMMetalDesign: '',
            txtDeviceVTsDesign: '',

            ddlAPThicknessUMofDesign: '',
            ddlMIMCapsUsedInDesign: '',
            ddlMOMIpForDesign: '',
            ddlDesignUseRingOscillators: '',
            ddlDesignUseOTPip: '',
            ddlDesignUseSRAM: '',
            ddlPolyTypeFuseDesign: '',
            txtOperationalFrequencyDesign: '',

            designInfoAnswerValue: {},
            packagingAnswerValue: '',
            designDataAnswerValue: '',

            designQuestionAnswerValue: {},
            packagingQuestionAnswerValue: {},
            designDataQuestionAnswerValue: {},

            designInfoQuestionSelectValue: {},
            packagingQuestionSelectValue: {},
            designDataQuestionSelectValue: {},


            designUploadAnswers: [],
            designUploadRequestAnswersResponse: {},
            showMyChildComponent: {},

            showQuestionOnSelect: {},
            //selectValue:'',
            showChildQuestion: [],
            showChildConditionalQuestion: {},

            selectValue: {},

            designUploadQuestionAnswers: [],


            designUploadDesingDataAnswers:[],
            designUploadPackagingAnswers:[],
            designUploadDesignInfoAnswers:[],

            designUploadChildAnswers:[],
            designUploadChildQuestionAnswers:{},

            customerId:'254',
            saveQuestionAnswers:false,
            submitQuestionAnswersResponse:'',

            inputAnswerValue:{},
            selectChildValue:{},

            showEditSelection:false,

            fetchSavedQuestionAnswers: false,
            savedQuestionAnswers: {},


            showFilNameInput:false,
            fileNameInput:'',
            showFileNameError:false,
            fileSizeSelectionValue:'',
            showFileSizeSelectionError:false,
            selectQuestionAnswers:false,

            fetchFileSizeLimit:false,
            fileSizeLimit:{},
            fileSizeInput:'',
            designId:'',
            idAndTechClassMap:{},
            idAndDesignUploadIdMap:{},
            idAndRevisionNumberMap:{},
            isFileSizeSelected:'',

            fileUploadRedirect: "/services-design-file-upload",
            redirectNow:false,
            redirectToFTPGuide:false,
            revisionNumber:'',
            designUploadId:'',

            designFileSize:'',
            idAndFileSizeMap:{},

            idAndTusIdMap:{},
            fileTusId:'',
            disabledInput:false,

            designNumberValue:'',
            disabledBasicDetailsInput:false,
            allFieldsFilled:true,
            enterAllQAError:false,
            showConfirmationPopup:false,
            showUploadPopup: false,
            submitConfirmation: false,

            enterInputError:{},
            showInvalidFileSizeError:false,
            enterAllQAErrorFirstScreen:false,
            showNextButton: false,
            disabledFormButtonsOnSubmit: false,
            selectedDesignObjectMap: {},
            selectedDesignNumberDetails:{},
            firstErrorReferences:[],
            firstErrorReferencesForFile:[],
            fetchCustomerDetails:false,
            allQAErrorContent: "* Please Enter All Answers",
            designNumberListDisabled: false,

            quantityOrdered:'',
            quantityOfPackagedChips:'',
            showQuantityOfPackagedError:false,
            quantityOfPackagedChipsQuestionId:'',
            todayDate:'',

            fileObjList: [],
            fileList: [],
            fileTypeList: [],
            formDataResponse:'',
            fetchUploadFileList: false,
            uploadedFileObj: {},
            deletedFileItemsDocumentNumbers: [],
            apiUrl: props.PrStore.GetData('api').baseurl,
            filesToUpload: [],
            deletedFileTypes: [],

            //for changing unit if foundry - GF
            isFoundryGForIntel:false,
            showQuestionAnswers: false,

            //to store id of multiplication field of X and Y dimension
            dimensionMultiplicationQuestionId: [],
            dimensionMultiplicationAnswerFieldId: '',

        };

        this.handleChange = this.handleChange.bind(this);
        this.showFileUpload = this.showFileUpload.bind(this);

    }


    UNSAFE_componentWillMount = () => {

        console.log("Inside UNSAFE_componentWillMount")
        console.log(JSON.parse(localStorage.getItem('okta-token-storage')))
        this.state.fetchCustomerDetails = true
        if (JSON.stringify(this.state.foundriesList === '{}')) {
            let that = this;
            that.setState({
                fetchFoundries: true
            });
        }
        if (JSON.stringify(this.state.uploadQuestionList === '{}')) {
            let that = this;
            that.setState({
                fetchUploadQuestionList: true
            });
        }
        if (JSON.stringify(this.state.questionByTypeAndTechClass === '{}')) {
            let that = this;
            that.setState({
                fetchQuestionByTypeAndTechClass: false
            });
        }
        if (JSON.stringify(this.state.foundryAndTechClass === '{}')) {
            let that = this;
            that.setState({
                fetchFoundryAndTechClass: true,
                customerId: this.state.customerId
            });
        }
        if(JSON.stringify(this.state.fileSizeLimit === '')){
            this.setState({
                fetchFileSizeLimit: true
            })
        }

        this.getCurrentDate()
    }


//============================================EVENT HANDLER FUNCTIONS - START===================================================
    HandleEditSelectionByValue = (val) =>{
        console.log("VALUE: "+val)
        console.log("VALUE: "+this.state.foundryAndTechClass[0].customerDesignUpload["designUploadId"])
        console.log("idAndDesignUploadIdMap: "+JSON.stringify(this.state.idAndDesignUploadIdMap))


        if(val == "UPDATE"){
            this.setState({
                designUploadId: this.state.idAndDesignUploadIdMap[this.state.designId],
                fetchSavedQuestionAnswers: true,
                showEditSelection: false,
                disabledInput:false
            })
        }/*else{
            this.setState({
                designUploadId: this.state.idAndDesignUploadIdMap[this.state.designId],
                fetchSavedQuestionAnswers: true,
                showEditSelection: false,
                disabledInput:true
            })
        }*/
    }

    HandleEditSelection = (e) =>{
        console.log("VALUE: "+e.target.value)
        console.log("VALUE: "+this.state.foundryAndTechClass[0].customerDesignUpload["designUploadId"])
        console.log("idAndDesignUploadIdMap: "+JSON.stringify(this.state.idAndDesignUploadIdMap))


        if(e.target.value == "UPDATE"){
            this.setState({
                designUploadId: this.state.idAndDesignUploadIdMap[this.state.designId],
                fetchSavedQuestionAnswers: true,
                showEditSelection: false,
                disabledInput:false
            })
        }else{
            this.setState({
                designUploadId: this.state.idAndDesignUploadIdMap[this.state.designId],
                fetchSavedQuestionAnswers: true,
                showEditSelection: false,
                disabledInput:true
                //designUploadQuestionAnswers: [],
                //redirectNow:true
            })
//            window.location = this.state.fileUploadRedirect;
        }
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            showNextButton: false
        })
        /*if (e.target.name === 'ddlFoundry') {
            let foundrynames = '';
            let value = '';
            (this.state.foundriesList).forEach(function (element, index) {
                // alert(element.codeName);
                if (element.codeId == e.target.value) {
                    foundrynames = element.codeName + " Fab Schedule"
                    console.log("VALUE: "+e.target.value);
                    value = e.target.value
                }
            });
            this.setState({
                fetchTechNode: true,
                foundryName: foundrynames,
                foundryValue: value
            });
        }*/
        if (e.target.name === 'ddlTechNode') {
            let technodenames = '';
            let value = '';
            (this.state.techNodesList).forEach(function (element, index) {
                // alert(element.codeName);
                if (element.codeId == e.target.value) {
                    technodenames = "For " + element.codeValue
                    value = e.target.value
                }
            });
            this.setState({
                fetchSchedule: true,
                techNodeName: technodenames,
                techNodeValue: value
            });
            this.state.showQuestionDiv = true;

            console.log(this.state.foundryValue);
            console.log(this.state.techNodeValue);
            console.log(value);
            this.state.techNodeValue = value
            console.log(this.state.uploadQuestionList[0]);
            this.state.fetchQuestionByTypeAndTechClass = true

            console.log("RESULTS: "+this.state.questionByTypeAndTechClass.length)
        }else{

            this.ResetInputFields()
            this.EmptyAllFieldArrays()

            console.log("inside radio change")

            this.state.designId =  e.target.value
            console.log("this.state.designId: "+this.state.designId)
            console.log("this.state.designUploadId: "+JSON.stringify(this.state.designUploadId))
            let techNodeId = this.state.idAndTechClassMap[this.state.designId]
            console.log(techNodeId)


            this.state.techNodeValue = techNodeId;
            this.state.showQuestionDiv = true;
            this.state.fetchQuestionByTypeAndTechClass = true
            this.state.revisionNumber = this.state.idAndRevisionNumberMap[this.state.designId]
            this.state.designUploadId = this.state.idAndDesignUploadIdMap[this.state.designId]
            this.state.designFileSize = this.state.idAndFileSizeMap[this.state.designId]
            this.state.fileTusId = this.state.idAndTusIdMap[this.state.designId]
            this.state.selectedDesignNumberDetails = this.state.selectedDesignObjectMap[this.state.designId]
            
            console.log("RESULTS: "+this.state.questionByTypeAndTechClass.length)

            let foundryName;
            let foundryFieldName;
            let techNodeName;
            let designNumberValue
            let that= this

            console.log("this.state.foundryAndTechClass: "+JSON.stringify(this.state.foundryAndTechClass))
            this.state.foundryAndTechClass.map(function(item) {
                console.log("item.customerDesignUpload[\"designUploadId\"]: "+JSON.stringify(item))
                if(that.state.designId==item.designNumberInfo["designNumberInfoId"]){
                    foundryName = item.foundry["codeName"]
                    foundryFieldName = item.foundry["field1"]
                    techNodeName = item.technologyClass["codeName"]
                    designNumberValue = item.designNumberInfo["designNumber"]
                }
            })
            console.log(techNodeId)
            console.log("foundryName: "+foundryName)
            console.log("techNodeName: "+techNodeName)
            console.log("designNumberValue: "+designNumberValue)
            this.setState({
                isFoundryGForIntel: foundryFieldName=="mosis-gf" || foundryFieldName=="mosis-intel"?true:false,
                fetchSchedule: true,
                //fetchFoundryAndTechClass: true,
                techNodeValue: techNodeId,
                showQuestionDiv:true,
                fetchQuestionByTypeAndTechClass:true,
                txtTechNode: techNodeName,
                txtFoundry: foundryName,
                designNumberValue: designNumberValue,
                showPopup: false,
                showModal: false,
                showSftpInstructionModal: false,
                designId: this.state.designId,
                revisionNumber: this.state.revisionNumber,
                designFileSize: this.state.designFileSize,
                disabledBasicDetailsInput:true,
                isFileSizeSelected:'',
                fileSizeSelectionValue:'',
                enterAllQAError: false
            });

        }
    }

    ResetInputFields = () => {
        let that = this
        $("input").each(function() {
            var element = $(this);
            var id = $(this).attr("id");
            var req = $(this).attr("required");
            if (element.val() == "" && $(this).is(":visible") && req == "required") {
                //PrValidate.ForceEntryWithoutMessage($(this).attr("value"), id);
                //that.state.allFieldsFilled = false;
                $('#' + id).attr('style', 'background-color: #FFF');
                that.state.enterInputError[id] = false;
            }
        });

        $("select").each(function() {
            var id = $(this).attr("id");
            console.log("select id: "+id)
            console.log("select val: "+$(this).val())
            console.log("is visible: "+ $(this).is(":visible"))
            if ($(this).val() == "" && $(this).is(":visible")){
                //PrValidate.ForceSelectWithoutMessage($(this).val(), id, "Input");
                //that.state.allFieldsFilled = false;
                $('#' + id).attr('style', 'background-color: #FFF');
                that.state.enterInputError[id] = false;
            }
        });
    }

    EmptyAllFieldArrays = () =>{
        this.setState({
            selectValue: {},
            selectChildValue: {},
            showMyChildComponent: {},
            showChildConditionalQuestion: {},
            showQuestionOnSelect:{},
            parentArr:{},
            designInfoAnswerValue:{},
            enterAllQAErrorFirstScreen: false,
            quantityOrdered:'',
            quantityOfPackagedChips:'',
            showQuantityOfPackagedError:false,
            quantityOfPackagedChipsQuestionId:''
        })
    }

    checkNumberOfQuantityOfPackagedChipsValidation = (quantityOrdered,quantityOfPackagedChips) => {
        console.log("quantityOrdered: "+quantityOrdered)
        console.log("quantityOfPackagedChips: "+quantityOfPackagedChips)
        if(Number(quantityOrdered) < Number(quantityOfPackagedChips)){
            this.state.showQuantityOfPackagedError = true
            console.log("inside if")
        }else{
            console.log("inside else")
            this.state.showQuantityOfPackagedError = false
        }
    }

    HandleInputChange = (e, item) => {

    console.log("INSIDE HandleInputChange")
        //console.log("item: "+JSON.stringify(item))
        if(item[0].designUploadQuestionSet["questionDataTypeObject"]!=null){
            if(item[0].designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Float"){
                //this.state.selectValue[item[0]["technologyQuestionId"]] = parseFloat(e.target.value).toFixed(3)
                if ((e.which != 46 || e.target.value.indexOf('.') != -1)&&(e.which < 48 || e.which > 57)) {
                    //alert('hello');
                    if((e.which != 46 || e.target.value.indexOf('.') != -1)){
                        //alert('Multiple Decimals are not allowed');
                    }
                    e.preventDefault();
                }/*else if(e.target.value.indexOf(".")>-1 && (e.target.value.split('.')[1].length > 3))		{
                    //alert('Two numbers only allowed after decimal point');
                    e.preventDefault();
                }*/else{
                    if(e.target.value.match(/^(?:\d{1,6}|(?!.{7})\d+\.\d+)$/g) || e.target.value.match(/^(?:\d{1,6}|(?!.{7})\d+\.)$/g) || e.target.value == "") {
                        console.log("inside IF======")
                        this.state.selectValue[item[0]["technologyQuestionId"]] = e.target.value
                    }
                    //this.state.selectValue[item[0]["technologyQuestionId"]] = e.target.value
                }

            }else if(item[0].designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Integer"){
                if(e.target.value.match(/^[0-9]{0,6}$/g) || e.target.value == "") {
                    console.log("inside IF======")
                    this.state.selectValue[item[0]["technologyQuestionId"]] = e.target.value
                }
            }else{
                this.state.selectValue[item[0]["technologyQuestionId"]] = e.target.value
            }
        }else{
            this.state.selectValue[item[0]["technologyQuestionId"]] = e.target.value
        }

        this.state.enterInputError[item[0]["technologyQuestionId"]] = false


        if(item[0].designUploadQuestionSet["question"] == 'Quantity of packaged chips'){
            this.state.quantityOfPackagedChips = this.state.selectValue[item[0]["technologyQuestionId"]]
                this.checkNumberOfQuantityOfPackagedChipsValidation(this.state.quantityOrdered, this.state.quantityOfPackagedChips)
        }else if(item[0].designUploadQuestionSet["question"] =="Quantity Ordered"){
            this.state.quantityOrdered = this.state.selectValue[item[0]["technologyQuestionId"]]
            this.checkNumberOfQuantityOfPackagedChipsValidation(this.state.quantityOrdered, this.state.quantityOfPackagedChips)
        }

        //console.log("this.state.selectValue[item[0]['technologyQuestionId']]: "+this.state.selectValue[item[0]["technologyQuestionId"]])
        //this.value = parseFloat(this.value).toFixed(2);
        //this.state.selectValue[item[0]["technologyQuestionId"]] = parseFloat(e.target.value).toFixed(2)
        //this.state.selectValue[item[0]["technologyQuestionId"]] = e.target.value
        this.setState(
            {selectValue : this.state.selectValue}
        );
        console.log("Change In Input"+e.target.value)
        if(item["child"] != null){
            this.state.showMyChildComponent[item[0]["questionId"]] = item[0]["questionId"];
            /*if(item[0].designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Integer"){
                console.log("==========================================INSIDE IF=============================================================")
                if(e.target.value.match(/^[0-9]{0,10}$/g) || e.target.value == ""){
                    console.log("==========================================INSIDE IF=============================================================")
                    this.state.showMyChildComponent[item[0]["questionId"]] = item[0]["questionId"];
                }
            }else{
                this.state.showMyChildComponent[item[0]["questionId"]] = item[0]["questionId"];
            }*/
        }
        //console.log("showMyChildComponent"+this.state.showMyChildComponent)

        let that = this
        let tempConditionalQuestionState = {}

        let toRemoveInputs = true;
        let isInteger = false;
        if(item[0].designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Integer"){
            if(e.target.value.match(/^[0-9]{0,6}$/g) || e.target.value == ""){
                isInteger = true
                e.target.value = e.target.value
            }else{
                e.target.value = e.target.value.slice(0,-1)
                toRemoveInputs = false
            }
        }
        console.log("e.target.value: "+e.target.value)

        if(item["child"] != null) {
            item["child"].map(function (item) {

                console.log("Inside conidtional Input")
                let questionId=item["technologyQuestionId"]
                //console.log("questionId: "+questionId)

                if(e.target.value != '' && toRemoveInputs === true){
                        that.RemoveFromAnswers(questionId)
                        that.RemoveFromAnswersFile(questionId, item)
                }

                if (item.designUploadQuestionSet.subQuestionConditionObject["codeValue"] == ">0") {
                    if(e.target.value != ''){
                        console.log("INSIDE IF =============================== "+e.target.value)
                            console.log("INSIDE IF IF=============================== "+e.target.value)
                            if (e.target.value > 0) {
                                console.log("INSIDE IF IF IF=============================== "+e.target.value)
                                tempConditionalQuestionState[questionId] = "true"
                                console.log("tempConditionalQuestionState" + JSON.stringify(tempConditionalQuestionState))
                            } else {
                                console.log("INSIDE IF IF ELSE=============================== "+e.target.value)
                                tempConditionalQuestionState[questionId] = "false"
                                console.log("tempConditionalQuestionState" + JSON.stringify(tempConditionalQuestionState))
                            }
                    }
                }else{
                    if(e.target.value != '') {
                        console.log("inside IF ==")
                        if(isInteger){
                            console.log("inside IF ===")
                            if(e.target.value > 0) {
                                console.log("inside IF ====")
                                tempConditionalQuestionState[questionId] = "true"
                            }else{
                                tempConditionalQuestionState[questionId] = "true"
                            }
                        }else{
                            console.log("inside ELSE ====")
                            tempConditionalQuestionState[questionId] = "true"
                        }
                    }else{
                        console.log("inside ELSE ==")
                        tempConditionalQuestionState[questionId] = "false"

                    }
                }

            })
            that.state.showChildConditionalQuestion[item[0]["questionId"]]=tempConditionalQuestionState
        }
        //console.log("showChildConditionalQuestion"+JSON.stringify(this.state.showChildConditionalQuestion))
        this.setState({
            showChildConditionalQuestion: this.state.showChildConditionalQuestion,
            showMyChildComponent: this.state.showMyChildComponent
        })
    }

    RemoveFromAnswersFile = (questionId, item) => {
        console.log("===================================RemoveFromAnswersFile==================================")
        console.log("item: "+JSON.stringify(item));
        console.log("questionId: "+JSON.stringify(questionId));

        if(item.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "File"){
            //question_Bonding Diagram_span
            console.log("INSIDE File")
            var fileSpanDelButton = document.getElementById("question_"+item.designUploadQuestionSet.additionalMappingObject["codeValue"]+"_span").getElementsByClassName("del_button")[0];
            var fileElem = document.getElementById("question_"+item.designUploadQuestionSet.additionalMappingObject["codeValue"]);
            fileElem.value = '';
            fileSpanDelButton.click();

            let fileUploaddeletedIndex;
            this.state.filesToUpload.map(function (itemDeleted, index) {
                if(itemDeleted["additionalMappingId"] == item.designUploadQuestionSet.additionalMappingObject.codeId){
                    fileUploaddeletedIndex = index;
                }
            })
            if(fileUploaddeletedIndex != null){
                console.log("INSIDE SPLICE ========================================")
                this.state.filesToUpload.splice(fileUploaddeletedIndex,1);
            }

            let indexToDelete;
            this.state.fileObjList.map(function (itemFileObj, index) {
                console.log("item: "+JSON.stringify(item));
                if(item.designUploadQuestionSet.additionalMappingObject.codeId == itemFileObj.additionalMapping){
                    console.log("INSIDE IF")
                    indexToDelete = index
                }
            })
            if(indexToDelete != null){
                this.state.fileObjList.splice(indexToDelete,1);
            }

            this.removeExistingObject(item["technologyQuestionId"], "technologyQuestionId");
        }else{
            console.log("NO File")
        }
        console.log("this.state.filesToUpload: "+JSON.stringify(this.state.filesToUpload))
    }

    RemoveFromAnswers = (id) => {
        console.log("id: "+id)
        console.log("that.state.designUploadQuestionAnswers length: "+this.state.designUploadQuestionAnswers.length)
        console.log("this.state.designUploadQuestionAnswers: "+JSON.stringify(this.state.designUploadQuestionAnswers))

        let indexToDelete = ''
        if(this.state.designUploadQuestionAnswers != null || this.state.designUploadQuestionAnswers != '') {
            let that = this
            this.state.designUploadQuestionAnswers.map(function (item,index) {
                if(item["technologyQuestionId"] == id){
                    console.log("index inside HandleOnBlur: "+index)
                    console.log(item["technologyQuestionId"])
                    indexToDelete = index
                    that.state.designUploadQuestionAnswers.splice(indexToDelete,1)
                    that.state.selectChildValue[id] = ''
                }
            })
        }

        console.log("that.state.designUploadQuestionAnswers length: "+this.state.designUploadQuestionAnswers.length)
        console.log("this.state.designUploadQuestionAnswers: "+JSON.stringify(this.state.designUploadQuestionAnswers))
    }

    HandleInputChildChange = (e, item) => {
        //this.value = parseFloat(this.value).toFixed(2);

        var id = e.target.id;
        console.log("ID: "+id)
        this.state.enterInputError[id] = false




        if(item["designUploadQuestionSet"] != null) {

            if(item.designUploadQuestionSet["questionDataTypeObject"]!=null){
                if(item.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Float"){
                    //this.state.selectValue[item[0]["technologyQuestionId"]] = parseFloat(e.target.value).toFixed(3)
                    if ((e.which != 46 || e.target.value.indexOf('.') != -1)&&(e.which < 48 || e.which > 57)) {
                        //alert('hello');
                        if((e.which != 46 || e.target.value.indexOf('.') != -1)){
                            //alert('Multiple Decimals are not allowed');
                        }
                        e.preventDefault();
                    }/*else if(e.target.value.indexOf(".")>-1 && (e.target.value.split('.')[1].length > 3))		{
                        //alert('Two numbers only allowed after decimal point');
                        e.preventDefault();
                    }*/else{
                        if(e.target.value.match(/^(?:\d{1,6}|(?!.{7})\d+\.\d+)$/g) || e.target.value.match(/^(?:\d{1,6}|(?!.{7})\d+\.)$/g) || e.target.value == "") {
                            console.log("inside IF======")
                            this.state.selectChildValue[item["technologyQuestionId"]] = (e.target.value)
                        }
                        //this.state.selectValue[item[0]["technologyQuestionId"]] = e.target.value
                    }


                }else if(item.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Integer"){
                    if(e.target.value.match(/^[0-9]{0,6}$/g) || e.target.value == "") {
                        console.log("inside IF======")
                        this.state.selectChildValue[item["technologyQuestionId"]] = (e.target.value)
                    }
                }else{
                    this.state.selectChildValue[item["technologyQuestionId"]] = (e.target.value)
                }
            }else{
                this.state.selectChildValue[item["technologyQuestionId"]] = (e.target.value)
            }

        }else{
            this.state.selectChildValue[item["technologyQuestionId"]] = (e.target.value)
        }
        this.setState(
            {selectChildValue : this.state.selectChildValue}
        );
    }


    multiplyDimensionQuestions = (value, id) => {
        let selectValues = this.state.selectValue
        if(this.state.dimensionMultiplicationQuestionId.includes(id)){
            let otherOparandId = this.state.dimensionMultiplicationQuestionId.filter(function(x) { return x !== id; });
            /*console.log("Mulitplication Value: "+value)
            console.log("Mulitplication Value: "+otherOparandId+" "+this.state.selectValue[otherOparandId])
            console.log("Mulitplication Value: "+(value*this.state.selectValue[otherOparandId]))*/
            //selectValues[this.state.dimensionMultiplicationAnswerFieldId] = this.roundOff(value*this.state.selectValue[otherOparandId], 3);
            selectValues[this.state.dimensionMultiplicationAnswerFieldId] = Math.round((value*this.state.selectValue[otherOparandId]) * 1000) / 1000;

            let tempAnswers = {}
            tempAnswers["technologyQuestionId"] = this.state.dimensionMultiplicationAnswerFieldId
            tempAnswers["answerValue"] = selectValues[this.state.dimensionMultiplicationAnswerFieldId]

            let indexToDelete = ''
            if(this.state.designUploadQuestionAnswers != null || this.state.designUploadQuestionAnswers != '') {
                let that = this
                this.state.designUploadQuestionAnswers.map(function (item,index) {
                    if(item["technologyQuestionId"] == that.state.dimensionMultiplicationAnswerFieldId){
                        indexToDelete = index
                        that.state.designUploadQuestionAnswers.splice(indexToDelete,1)
                    }
                })
            }
            //this.state.designUploadQuestionAnswers[type] = this.state.designUploadDesignInfoAnswers
            if(value != ""){
                this.state.designUploadQuestionAnswers.push(tempAnswers)
            }
        }

        return selectValues;
    }

    HandleOnBlur = (type, child, value, id, item,e) => {

        //this.props.state.selectValue
        if(item.designUploadQuestionSet != null) {
            if (item.designUploadQuestionSet.questionDataTypeObject != null) {
                console.log("item.designUploadQuestionSet[\"questionDataTypeObject\"]: " + item.designUploadQuestionSet.questionDataTypeObject["codeValue"])
                if (item.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Float" || item.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Integer") {
                    if(value != null) {
                        if (value.length > 0 && this._isNumber(value)) {
                            let val = value;
                            if (item.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Float"){
                                val = Math.round(val * 1000) / 1000
                                //val = this.roundOff(val, 3);
                            }else if(item.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Integer"){
                                val = this.roundOff(val, 0);
                            }

                            this.setState({
                                [e.target.name]: val,

                            })
                            value = val;
                        }
                    }
                }
            }
        }
        console.log("value: "+value)

        //changes for allow blank input fields
        if(item.designUploadQuestionSet != null) {
            if (item.designUploadQuestionSet.questionDataTypeObject != null) {
                if (item.designUploadQuestionSet.questionDataTypeObject["codeValue"] != "Integer_AllowBlank" && item.designUploadQuestionSet.questionDataTypeObject["codeValue"] != "String_AllowBlank") {
                    PrValidate.ForceSelectWithoutMessage(value, id, "Input");
                }
            }else{
                PrValidate.ForceSelectWithoutMessage(value, id, "Input");
            }
        }else{
            PrValidate.ForceSelectWithoutMessage(value, id, "Input");
        }

        console.log("Inside ON BLUR"+type)
        console.log("Inside ON BLUR"+child)
        console.log("Inside ON BLUR"+value)
        console.log("Inside ON BLUR"+id)

        let selectValue = this.multiplyDimensionQuestions(value, id);

        let tempAnswers = {}

        let indexToDelete = ''
        let that = this


        if(this.state.designUploadQuestionAnswers != null || this.state.designUploadQuestionAnswers != '') {
            let that = this
            this.state.designUploadQuestionAnswers.map(function (item,index) {
                if(item["technologyQuestionId"] == id){
                    console.log("index inside HandleOnBlur: "+index)
                    console.log(item["technologyQuestionId"])
                    indexToDelete = index
                    that.state.designUploadQuestionAnswers.splice(indexToDelete,1)
                }
            })
        }

        if(type == '669'){
            this.state.designUploadDesignInfoAnswers = []
            tempAnswers["technologyQuestionId"] = id
            tempAnswers["answerValue"] = value
            this.state.designUploadDesignInfoAnswers.push(tempAnswers)
            //this.state.designUploadQuestionAnswers[type] = this.state.designUploadDesignInfoAnswers
            if(value != ""){
                this.state.designUploadQuestionAnswers.push(tempAnswers)
            }
        }else if(type == '670'){
            this.state.designUploadPackagingAnswers = []
            tempAnswers["technologyQuestionId"] = id
            tempAnswers["answerValue"] = value
                this.state.packagingQuestionAnswerValue[id] = value;
            this.state.designUploadPackagingAnswers.push(tempAnswers)
            //this.state.designUploadQuestionAnswers[type] = this.state.designUploadPackagingAnswers
            if(value != ""){
                this.state.designUploadQuestionAnswers.push(tempAnswers)
            }

        }else if(type == '671'){
            this.state.designUploadDesingDataAnswers = [];
            tempAnswers["technologyQuestionId"] = id
            tempAnswers["answerValue"] = value
            this.state.designDataQuestionAnswerValue[id] = value;
            this.state.designUploadDesingDataAnswers.push(tempAnswers)
            //this.state.designUploadQuestionAnswers[type] = this.state.designUploadDesingDataAnswers
            if(value != ""){
                this.state.designUploadQuestionAnswers.push(tempAnswers)
            }
        }

        this.state.selectValue = selectValue
        this.state.selectValue[id] = value
        this.setState({
            selectValue: this.state.selectValue
        })
        console.log(JSON.stringify(this.state.designQuestionAnswerValue))
        console.log(JSON.stringify(this.state.packagingQuestionAnswerValue))
        console.log(JSON.stringify(this.state.designDataQuestionAnswerValue))
        console.log(JSON.stringify(this.state.designUploadAnswers))
        console.log(JSON.stringify(this.state.designUploadQuestionAnswers))


    }

    HandleOnClick = () => {
        console.log("Inside ON Click")

        this.setState({
            fetchFoundryAndTechClass: true,
            showPopup:true
        })
    }

    getCommaSeparatedValues = (item, propertyName) => {
        return Object.keys(item).map(function(k){return item[k][propertyName]}).join(",");
    }

    onMultiSelectChange = (item, questionId, type) =>{
        console.log("questionId: "+questionId)
        console.log("type: "+type)
        console.log("inside multiselect change: "+JSON.stringify(item))

        //PrValidate.ForceSelect(e.target.value, e.target.id, '');
        this.state.enterInputError[questionId] = false
        console.log("item"+JSON.stringify(item))
        console.log("INSIDE SELECT CHANGE")
        let tempAnswers = {}
        //let questionId = questionId

        let answerValue = []
        /*if(item.technologyAnswerSets != null){
            answerValue = item.technologyAnswerSets.designUploadAnswerSet["answerValue"]
        }*/
        //var index = e.nativeEvent.target.selectedIndex;
        answerValue = this.getCommaSeparatedValues(item, "value");
        PrValidate.ForceMultiSelectWithoutMessage(answerValue, questionId);//TO_DO

        let indexToDelete = ''
        if(this.state.designUploadQuestionAnswers != null || this.state.designUploadQuestionAnswers != '') {
            let that = this
            this.state.designUploadQuestionAnswers.map(function (item,index) {
                if(item["technologyQuestionId"] == questionId){
                    console.log("index inside HandleOnBlur: "+index)
                    console.log(item["technologyQuestionId"])
                    indexToDelete = index
                    that.state.designUploadQuestionAnswers.splice(indexToDelete,1)
                }
            })
        }


        if(type == '671') {
            this.state.designUploadDesingDataAnswers = [];

            tempAnswers["technologyQuestionId"] = questionId
            tempAnswers["answerValue"] = answerValue;
            this.state.designDataQuestionSelectValue[questionId] = answerValue


            //this.checkForDuplicate(questionId, answerValue)


            this.state.designUploadDesingDataAnswers.push(tempAnswers)
            if(answerValue != ""){
                this.state.designUploadQuestionAnswers.push(tempAnswers)
            }
        }
        else if(type == '670') {
            this.state.designUploadPackagingAnswers = [];

            tempAnswers["technologyQuestionId"] = questionId
            tempAnswers["answerValue"] = answerValue;
            this.state.packagingQuestionSelectValue[questionId] = answerValue


            //this.checkForDuplicate(questionId, answerValue)

            this.state.designUploadPackagingAnswers.push(tempAnswers)
            if(answerValue != ""){
                this.state.designUploadQuestionAnswers.push(tempAnswers)
            }

        }
        else if(type == '669') {
            this.state.designUploadDesignInfoAnswers = [];

            tempAnswers["technologyQuestionId"] = questionId
            tempAnswers["answerValue"] = answerValue;
            this.state.designInfoQuestionSelectValue[questionId] = answerValue


            //this.checkForDuplicate(questionId, answerValue)

            this.state.designUploadDesignInfoAnswers.push(tempAnswers)
            if(answerValue != ""){
                this.state.designUploadQuestionAnswers.push(tempAnswers)
            }
        }

        //this.state.selectValue[type+item.designUploadAnswerSet["answerId"]] = item.designUploadAnswerSet["answerValue"]
        this.state.selectValue[questionId] = answerValue
        if(this.state.selectValue[questionId] == ''){
            console.log('=============empty ====================')
        }
        console.log("this.state.selectValue[questionId]: "+this.state.selectValue[questionId])
        this.setState({
            designUploadQuestionAnswers: this.state.designUploadQuestionAnswers,
            selectValue: this.state.selectValue

        })

    }

    HandleSelectChange=(e, item, type)=>{

        PrValidate.ForceSelectWithoutMessage(e.target.value, e.target.id, "Input");
        //PrValidate.ForceSelect(e.target.value, e.target.id, '');
        this.state.enterInputError[item[0]["technologyQuestionId"]] = false
        console.log("item"+JSON.stringify(item))
        console.log("INSIDE SELECT CHANGE")
        let tempAnswers = {}
        let questionId = item[0]["technologyQuestionId"]

        let answerValue = ''
        /*if(item.technologyAnswerSets != null){
            answerValue = item.technologyAnswerSets.designUploadAnswerSet["answerValue"]
        }*/
        var index = e.nativeEvent.target.selectedIndex;
        answerValue = e.nativeEvent.target[index].text;

        if(type == '671') {
            this.state.designUploadDesingDataAnswers = [];
        console.log(e.target.value)
        console.log(questionId)
        tempAnswers["technologyQuestionId"] = questionId
        tempAnswers["technologyAnswerId"] = Number(e.target.value);
        this.state.designDataQuestionSelectValue[questionId] = e.target.value

            if(questionId == 58){
                console.log("=====================58========================")
                console.log(questionId)
                console.log(e.target.value)
            }
            this.checkForDuplicate(questionId, e.target.value)
            //this.removeExistingObject(questionId, "technologyQuestionId")
            //this.removeExistingObject(e.target.value, "parentTechnologyAnswerId")

            this.state.designUploadDesingDataAnswers.push(tempAnswers)
            console.log("VALUES: "+JSON.stringify(this.state.designUploadDesingDataAnswers))
            if(e.target.value != ""){
                this.state.designUploadQuestionAnswers.push(tempAnswers)
            }
        }
        else if(type == '670') {
            this.state.designUploadPackagingAnswers = [];
            console.log(e.target.value)
            console.log(questionId)
        tempAnswers["technologyQuestionId"] = questionId
        tempAnswers["technologyAnswerId"] = Number(e.target.value);
        this.state.packagingQuestionSelectValue[questionId] = e.target.value

            if(questionId == 58){
                console.log("=====================58========================")
                console.log(questionId)
                console.log(e.target.value)
            }

            this.checkForDuplicate(questionId, e.target.value)
            //this.removeExistingObject(questionId, "technologyQuestionId")
            //this.removeExistingObject(e.target.value, "parentTechnologyAnswerId")
        //this.state.designUploadAnswers.push(tempAnswers)
            this.state.designUploadPackagingAnswers.push(tempAnswers)
            //this.state.designUploadQuestionAnswers[type] = this.state.designUploadPackagingAnswers
            if(e.target.value != ""){
                this.state.designUploadQuestionAnswers.push(tempAnswers)
            }

        }
        else if(type == '669') {
            this.state.designUploadDesignInfoAnswers = [];
            console.log(e.target.value)
            console.log(questionId)
        tempAnswers["technologyQuestionId"] = questionId
        tempAnswers["technologyAnswerId"] = Number(e.target.value);
        this.state.designInfoQuestionSelectValue[questionId] = e.target.value

            if(questionId == 58){
                console.log("=====================58========================")
                console.log(questionId)
                console.log(e.target.value)
            }
            this.checkForDuplicate(questionId, e.target.value)
            //this.removeExistingObject(questionId, "technologyQuestionId")
            //this.removeExistingObject(e.target.value, "parentTechnologyAnswerId")
        //this.state.designUploadAnswers.push(tempAnswers)
            this.state.designUploadDesignInfoAnswers.push(tempAnswers)
            //this.state.designUploadQuestionAnswers[type] = this.state.designUploadDesignInfoAnswers
            if(e.target.value != ""){
                this.state.designUploadQuestionAnswers.push(tempAnswers)
            }
        }

        console.log(JSON.stringify(this.state.designDataQuestionSelectValue))
        console.log(JSON.stringify(this.state.packagingQuestionSelectValue))
        console.log(JSON.stringify(this.state.designInfoQuestionSelectValue))
        console.log(JSON.stringify(this.state.designUploadAnswers))
        console.log(JSON.stringify(this.state.designUploadQuestionAnswers))

        if(item["child"] != null){
            if(answerValue != "No"){
                this.state.showMyChildComponent[item[0]["questionId"]] = item[0].designUploadQuestionSet["questionId"];
            }
        }
        console.log("showMyChildComponent"+this.state.showMyChildComponent)

        let that = this
        if(item[0].technologyAnswerSets != null){
            item[0].technologyAnswerSets.map(function(item) {
                if(item != null){
                    if(item["technologyAnswerId"] == e.target.value){
                        //that.state.showQuestionOnSelect = e.target.value;
                        if(answerValue != "") {
                            if(answerValue == "No"){
                                console.log("INSIDE IF =========================================="+e.target.value)
                                that.state.selectChildValue[questionId] = ''

                                console.log(JSON.stringify(that.state.designUploadQuestionAnswers))
                                that.state.showQuestionOnSelect[e.target.value] = false;
                            }else{
                                console.log("INSIDE IF ELSE =========================================="+e.target.value)
                                that.state.showQuestionOnSelect[e.target.value] = true;
                            }
                        }
                    }else{
                        console.log("INSIDE ELSE =========================================="+e.target.value)
                        that.state.showQuestionOnSelect[item["technologyAnswerId"]] = false;
                    }
                }
            })
        }
        console.log("showQuestionOnSelect"+JSON.stringify(this.state.showQuestionOnSelect))
        //this.state.selectValue[type+item.designUploadAnswerSet["answerId"]] = item.designUploadAnswerSet["answerValue"]
        this.state.selectValue[item[0]["technologyQuestionId"]] = e.target.value
        this.setState({
            showChildQuestion: this.showChildQuestion,
            showQuestionOnSelect: this.state.showQuestionOnSelect,
            selectValue: this.state.selectValue

        })


        let tempConditionalQuestionState = {}

        if(item["child"] != null) {
            let that = this
            item["child"].map(function (item) {

                console.log("Inside conidtional Input")
                let questionId=item["technologyQuestionId"]
                console.log("questionId: "+questionId)
                if (item.designUploadQuestionSet.subQuestionConditionObject["codeValue"] == ">0") {
                    if(e.target.value > 0){
                        tempConditionalQuestionState[questionId] = "true"
                        console.log("tempConditionalQuestionState"+JSON.stringify(tempConditionalQuestionState))
                    }else{
                        tempConditionalQuestionState[questionId] = "false"
                        console.log("tempConditionalQuestionState"+JSON.stringify(tempConditionalQuestionState))
                    }
                }else if(item.designUploadQuestionSet.subQuestionConditionObject["codeValue"] == "Other"){
                   console.log("TEXT: "+ e.nativeEvent.target[index].text)
                    if( e.nativeEvent.target[index].text == "Other"){
                        tempConditionalQuestionState[questionId] = "true"
                        console.log("tempConditionalQuestionState"+JSON.stringify(tempConditionalQuestionState))
                    }else{
                        tempConditionalQuestionState[questionId] = "false"
                        console.log("tempConditionalQuestionState"+JSON.stringify(tempConditionalQuestionState))
                    }
                }else{
                    if(e.target.value != '') {
                        console.log("answerValue: "+answerValue)
                        if(answerValue != "No") {
                            tempConditionalQuestionState[questionId] = "true"
                        }else{
                            tempConditionalQuestionState[questionId] = "false"
                        }
                    }else{
                        tempConditionalQuestionState[questionId] = "false"
                    }
                }
                that.state.selectChildValue[questionId] = ''

                that.state.designUploadQuestionAnswers.map(function (item,index) {
                    if(item["technologyQuestionId"] != null || item["technologyQuestionId"] != ''){
                        if(item["technologyQuestionId"] == questionId){
                            console.log("index: "+index)
                            let indexToDelete = index
                            that.state.designUploadQuestionAnswers.splice(indexToDelete,1)
                        }
                    }
                })
                console.log(JSON.stringify(that.state.designUploadQuestionAnswers))
            })
            that.state.showChildConditionalQuestion[item[0]["questionId"]]=tempConditionalQuestionState
        }
        console.log("showChildConditionalQuestion"+JSON.stringify(this.state.showChildConditionalQuestion))
        this.setState({
            showChildConditionalQuestion: this.state.showChildConditionalQuestion,
            showMyChildComponent: this.state.showMyChildComponent
        })

    }

    checkForDuplicate = (questionId, value)=>{
        let indexToDelete = ''
        let indexToDeleteChild = ''
        if(this.state.designUploadQuestionAnswers != null || this.state.designUploadQuestionAnswers != ''){
            let that = this
            this.state.designUploadQuestionAnswers.map(function (item,index) {
                if(item["technologyQuestionId"] != null || item["technologyQuestionId"] != ''){
                    if(item["technologyQuestionId"] == questionId){
                        console.log("index: "+index)
                        indexToDelete = index
                        that.state.designUploadQuestionAnswers.splice(indexToDelete,1)


                        let AnswerId = item["technologyAnswerId"]

                        //deleting possible child answer
                        let that2 = that
                        that.state.designUploadQuestionAnswers.map(function (item1,index) {
                            if(item1["parentTechnologyAnswerId"] == AnswerId) {
                                console.log("index: " + index)
                                indexToDeleteChild = index
                                that2.state.designUploadQuestionAnswers.splice(indexToDeleteChild,1)
                            }

                        })

                    }
                }

            })
        }
    }

    HandleChildChange=(e, item, type, inputType)=>{

        if(item.designUploadQuestionSet != null) {
            if (item.designUploadQuestionSet.questionDataTypeObject != null) {
                console.log("item.designUploadQuestionSet[\"questionDataTypeObject\"]: " + item.designUploadQuestionSet.questionDataTypeObject["codeValue"])
                if (item.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Float" || item.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Integer") {
                    if (e.target.value.length > 0 && this._isNumber(e.target.value)) {
                        let val = e.target.value;
                        if (item.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Float"){
                            val = this.roundOff(val, 1);
                        }else if(item.designUploadQuestionSet.questionDataTypeObject["codeValue"] == "Integer"){
                            val = this.roundOff(val, 0);
                        }
                        this.setState({
                            [e.target.name]: val,

                        })
                        e.target.value = val;
                    }
                }
            }
        }
        console.log("e.target.value: "+e.target.value)

        if(type == "select"){
            PrValidate.ForceSelectWithoutMessage(e.target.value, e.target.id, "Input");
        }else{
            if(item.designUploadQuestionSet != null) {
                if (item.designUploadQuestionSet.questionDataTypeObject != null) {
                    if (item.designUploadQuestionSet.questionDataTypeObject["codeValue"] != "Integer_AllowBlank" && item.designUploadQuestionSet.questionDataTypeObject["codeValue"] != "String_AllowBlank") {
                        PrValidate.ForceEntryWithoutMessage(e.target.value, e.target.id);
                    }
                }else{
                    PrValidate.ForceEntryWithoutMessage(e.target.value, e.target.id);
                }
            }else{
                PrValidate.ForceEntryWithoutMessage(e.target.value, e.target.id);
            }
        }

        console.log("Inside HandleChildChange")


        console.log(e.target.value)
        console.log("ID: "+e.target.id)
        console.log(item)
        console.log(type)

        let tempAnswers = {}
        let questionId = ''



            if (item["technologyAnswerId"] != null) {
                console.log("INSIDE IF =================")
                questionId = item["technologyAnswerId"]
                tempAnswers["parentTechnologyAnswerId"] = questionId
                this.removeExistingObject(questionId, "parentTechnologyAnswerId")
                this.state.selectChildValue[item["technologyQuestionId"]] = (e.target.value)
                this.state.enterInputError[e.target.id] = false
            } else {
                console.log("INSIDE ELSE =================")
                questionId = item["technologyQuestionId"]
                tempAnswers["technologyQuestionId"] = questionId
                this.removeExistingObject(questionId, "technologyQuestionId")
                this.state.selectChildValue[item["technologyQuestionId"]] = (e.target.value)
                this.state.enterInputError[item["technologyQuestionId"]] = false
            }


        console.log(e.target.value)
        console.log(questionId)

        if(inputType == "input"){

            tempAnswers["answerValue"] = e.target.value
        }else{

            tempAnswers["technologyAnswerId"] = Number(e.target.value);
        }
        //this.state.designUploadChildAnswers.push(tempAnswers)
        console.log("=============================")
        if(e.target.value!= "") {
            console.log("Inside IF")
            this.state.designUploadQuestionAnswers.push(tempAnswers)
        }
        //console.log(JSON.stringify(this.state.designUploadChildAnswers))
        console.log(JSON.stringify(this.state.designUploadQuestionAnswers))

        this.setState({
            selectChildValue: this.state.selectChildValue
        })
    }

    removeExistingObject = (questionId, tagToSearch) => {

        console.log("questionId: "+questionId)
        console.log("this.state.designUploadQuestionAnswers: "+JSON.stringify(this.state.designUploadQuestionAnswers))
        let indexToDelete = ''
        let that = this
        if(this.state.designUploadQuestionAnswers != null || this.state.designUploadQuestionAnswers != ''){
            this.state.designUploadQuestionAnswers.map(function (item,index) {
                if(item[tagToSearch] != null || item[tagToSearch] != ''){
                    if(item[tagToSearch] == questionId){
                        console.log("index: "+index)
                        indexToDelete = index
                        that.state.designUploadQuestionAnswers.splice(indexToDelete,1)
                    }
                }

            })
        }

    }


    HandleFileSizeSelection = (e) =>{
        console.log("VALUE: "+e.target.value)
        //console.log("VALUE: "+this.state.fileSizeSelectionValue)

        if(e.target.value == "YES"){
            console.log("ENTER FILE NAME")
            this.setState({
                isFileSizeSelected: "true",
                showFilNameInput: true
            })
        }else{
            console.log("FILE NAME NOT REQUIRED")
            this.setState({
                isFileSizeSelected: "false",
                showFilNameInput: false,
                fileNameInput: null,
                fileSizeInput: null
            })
        }
        this.state.fileSizeSelectionValue = e.target.value

        console.log("FILENAME: "+this.state.fileNameInput)

        this.setState({
            showFileNameError: false,
            showFileSizeSelectionError: false
        })

    }

    HandleFileNameAndSizeChange = (e, type) =>{
        console.log("FILENAME: "+e.target.value)
        if(type == "name"){
            this.setState({
                fileNameInput:e.target.value,
                showFileNameError: false
            })
        }else{
            if(e.target.value.match(/^(?:\d{1,3}|(?!.{7})\d+\.\d+)$/g) || e.target.value.match(/^(?:\d{1,3}|(?!.{7})\d+\.)$/g) || e.target.value == "") {
                this.setState({
                    [e.target.name]:e.target.value,
                    fileSizeInput:e.target.value,
                    showFileSizeError: false
                })
                if(e.target.value != "") {
                    console.log("this.props.state.fileSizeLimit.codeValue: "+this.state.fileSizeLimit.codeValue.split("GB")[0])
                    if (Number(e.target.value) > Number(this.state.fileSizeLimit.codeValue.split("GB")[0])) {
                        this.state.showInvalidFileSizeError = false
                    } else {
                        this.state.showInvalidFileSizeError = true
                    }
                }else{
                    this.state.showInvalidFileSizeError = false
                }
            }
        }


    }

    HandleSizeOnBlur = (e) =>{
        let val = "";
        if(e.target.value.length > 0 && this._isNumber(e.target.value)){
            val = e.target.value;
            val = this.roundOff(val, 1);
        }

        e.target.value = val;
        console.log("Inside onChangeInputDieSize: "+e.target.value)
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    roundOff(num, toDecimals) {
        if (!this._isNumber(num))
            throw num;

        return parseFloat(String(num)).toFixed(this.normalizeNumber(toDecimals));
    }

    normalizeNumber(num) {
        return this._isNumber(num) ? Number(num) : 0;
    }

    _isNumber(input) {
        return !(typeof input == 'undefined' || isNaN(input));
    }

//============================================EVENT HANDLER FUNCTIONS - START===================================================


//============================================SUCCESS FUNCTIONS - START===================================================


    HandleSuccessGetTechNodesResponse = (res) => {
        this.setState({
            fetchTechNode: false,
            techNodesList: res.data
        })
    }

    HandleSuccessGetFoundryAndTechClass = (res) => {

        console.log("INSIDE HandleSuccessGetFoundryAndTechClass")
        if(res != null) {
            this.setState({
                fetchFoundryAndTechClass: false,
                foundryAndTechClass: res.data,
                designNumberListDisabled: false
            })
            console.log("foundryAndTechClass: " + JSON.stringify(this.state.foundryAndTechClass))

            let that = this
            this.state.foundryAndTechClass.map(function (item, index) {
                console.log(item.designNumberInfo["designNumberInfoId"])
                if (item.technologyClass != null && item.customerDesignUpload != null) {
                    that.state.idAndTechClassMap[item.designNumberInfo["designNumberInfoId"]] = item.technologyClass["codeId"]
                    that.state.idAndDesignUploadIdMap[item.designNumberInfo["designNumberInfoId"]] = item.customerDesignUpload["designUploadId"]
                    that.state.idAndRevisionNumberMap[item.designNumberInfo["designNumberInfoId"]] = item.customerDesignUpload["revisionNumber"]
                    that.state.idAndFileSizeMap[item.designNumberInfo["designNumberInfoId"]] = item.customerDesignUpload["designFileSize"]
                    that.state.idAndTusIdMap[item.designNumberInfo["designNumberInfoId"]] = item.customerDesignUpload["fileTusId"]
                    that.state.selectedDesignObjectMap[item.designNumberInfo["designNumberInfoId"]] = item
                } else {
                    that.state.foundryAndTechClass.splice(index, 1)
                }
            })
            console.log("idAndTechClassMap: " + JSON.stringify(this.state.idAndTechClassMap))
            console.log("idAndDesignUploadIdMap: " + JSON.stringify(this.state.idAndDesignUploadIdMap))

            this.setState({
                foundryAndTechClass: this.state.foundryAndTechClass,
                idAndTechClassMap: this.state.idAndTechClassMap,
                designUploadId: this.state.idAndDesignUploadIdMap
            })
        }else{
            this.setState({
                fetchFoundryAndTechClass: false,
                designNumberListDisabled: true
            })
        }

    }

    getIdsOfMultiplicationQuestions = (questionObj) => {
        if(questionObj.designUploadQuestionSet.question.includes('(X in mm) dimension') || questionObj.designUploadQuestionSet.question.includes('(Y in mm) dimension')){
            console.log("X or Y: "+JSON.stringify(questionObj))
            console.log("X or Y: "+JSON.stringify(questionObj["technologyQuestionId"]))
            let dimensionMultiplicationQuestionIdTemp = this.state.dimensionMultiplicationQuestionId;
            dimensionMultiplicationQuestionIdTemp.push(questionObj["technologyQuestionId"]);

            this.setState({
                dimensionMultiplicationQuestionId: dimensionMultiplicationQuestionIdTemp
            });
        }

        if(questionObj.designUploadQuestionSet.question.includes('"X" by "Y"')){
            console.log("X or Y: "+JSON.stringify(questionObj))
            console.log("X or Y: "+JSON.stringify(questionObj["technologyQuestionId"]))

            this.setState({
                dimensionMultiplicationAnswerFieldId: questionObj["technologyQuestionId"]
            })
            /*let dimensionMultiplicationQuestionIdTemp = this.state.dimensionMultiplicationQuestionId;
            dimensionMultiplicationQuestionIdTemp.push(questionObj["technologyQuestionId"]);

            this.setState({
                dimensionMultiplicationQuestionId: dimensionMultiplicationQuestionIdTemp
            });*/
        }
    }

    HandleSuccessGetQuestionsByQuestionTypeAndTechClass = (res) => {

        console.log("File Size Limit Object: "+JSON.stringify(this.state.fileSizeLimit.codeValue))
        this.setState({
            fetchQuestionByTypeAndTechClass: false,
            questionByTypeAndTechClass: res.data,
            dimensionMultiplicationQuestionId: []
        })
        console.log("Revision Number: "+this.state.idAndRevisionNumberMap[this.state.designId])
        console.log("Design Upload Id: "+this.state.idAndDesignUploadIdMap[this.state.designId])

        /*if(this.state.idAndRevisionNumberMap[this.state.designId] > 0 || this.state.idAndRevisionNumberMap[this.state.designId] == 0)*/
        if(this.state.idAndDesignUploadIdMap[this.state.designId] > 0)
        {
            this.HandleEditSelectionByValue("UPDATE")
            /*this.setState({
                showEditSelection: true
            })*/
        }else{
            this.setState({
                showQuestionAnswers:true
            })
        }

        if (this.state.questionByTypeAndTechClass["status"] != "NOT_FOUND")
        {
            let i;
        let that = this;

        console.log("questionByTypeAndTechClass: "+JSON.stringify(this.state.questionByTypeAndTechClass))


        this.state.questionByTypeAndTechClass.map(function (item) {

            if(item.designUploadQuestionSet.subQuestionConditionObject["codeValue"] == "Dropdown_Multiselect"){
                console.log("multiselect qustion: "+JSON.stringify(item))
            }
            that.getIdsOfMultiplicationQuestions(item);
            if (!item.designUploadQuestionSet.parentQuestionId) {
                let modifiedDesignUploadQuestionSet = item.designUploadQuestionSet
                if(that.state.isFoundryGForIntel){
                    modifiedDesignUploadQuestionSet = that.getModifiedDesignUploadQuestionSet(item.designUploadQuestionSet)
                }

                item.designUploadQuestionSet = modifiedDesignUploadQuestionSet;

                if (!that.state.parentArr['p' + item.questionId])
                    that.state.parentArr['p' + item.questionId] = [];
                that.state.parentArr['p' + item.questionId].push(item)
                console.log("PARENT ARR: ");
                //console.log(that.state.parentArr['p24']);
            }
        });
        console.log("PARENT ARR: ");
        console.log(that.state.parentArr);




        this.state.questionByTypeAndTechClass.map(function (item) {
            if (item.designUploadQuestionSet.parentQuestionId) {
                console.log("item.designUploadQuestionSet.parentQuestionId: "+item.designUploadQuestionSet.parentQuestionId)
                if (!that.state.parentArr['p' + item.designUploadQuestionSet.parentQuestionId]['child'])
                    that.state.parentArr['p' + item.designUploadQuestionSet.parentQuestionId]['child'] = [];


                that.state.parentArr['p' + item.designUploadQuestionSet.parentQuestionId]['child'].push(item);
            }

        });

        console.log(that.state.parentArr);
        //console.log("parentArr" + that.state.parentArr['p27'][0].technologyQuestionId)

        let count = 0;

        that.state.parentArrValuesList = Object.values(that.state.parentArr);
        console.log("that.state.parentArrValuesList: "+JSON.stringify(that.state.parentArrValuesList));
            //let sortedList = that.state.parentArrValuesList.sort(that.GetSortOrder("question"));
            //console.log("that.state.parentArrValuesList Sorted: "+JSON.stringify(sortedList));
        //that.state.arrayFilled = 1


/*            let that2 = that
            that.state.parentArrValuesList.map(function (item, index) {
                if(item["child"] != null){
                    if(item["child"].technologyAnswerSets != null){
                        item["child"].technologyAnswerSets.sort(function(a, b) {
                            return a.name > b.name;
                        });
                        that2.state.parentArrValuesList[index]["child"].technologyAnswerSets = item["child"].technologyAnswerSets
                    }
                }
            })*/

        this.setState({
            parentArrValuesList: that.state.parentArrValuesList,
            showNextButton: true
        })



        console.log(this.state.showQuestionOnSelect)
        }

        /*var select = $('select');
        select.html(select.find('option').sort(function(x, y) {
            // to change to descending order switch "<" for ">"
            return $(x).text() > $(y).text() ? 1 : -1;
        }));*/
    }

    getModifiedDesignUploadQuestionSet(obj){
            if(obj.question.indexOf("Die Thickness")!==-1){
                obj.question = obj.question.replace("(mils)","(um)")
            }
        return obj;
    }

    GetSortOrder = (prop) => {
        return function(a, b) {
            console.log("a: "+JSON.stringify(a))
            console.log("a.designUploadQuestionSet[prop]: "+a[0].designUploadQuestionSet[prop])
            console.log("b.designUploadQuestionSet[prop]: "+b[0].designUploadQuestionSet[prop])
            if (a[0].designUploadQuestionSet[prop] > b[0].designUploadQuestionSet[prop]) {
                return 1;
            } else if (a[0].designUploadQuestionSet[prop] < b[0].designUploadQuestionSet[prop]) {
                return -1;
            }
            return 0;
        }
    }

    HandleSuccessSaveQuestionAnswers = (res) => {
        this.setState({
            saveQuestionAnswers: false,
            submitQuestionAnswersResponse: res.data
        })
        console.log("submitQuestionAnswersResponse: "+JSON.stringify(this.state.submitQuestionAnswersResponse))

        //return <Redirect to={this.state.fileUploadRedirect} />
        //window.location = this.state.fileUploadRedirect;
        this.state.revisionNumber = this.state.submitQuestionAnswersResponse["revisionNumber"]
        if(this.state.isFileSizeSelected == "false"){
            this.setState({
                designUploadId: this.state.submitQuestionAnswersResponse["designUploadId"],
                redirectNow:true
            })
        }else{
            console.log("INSIDE ELSE==========================")
            this.setState({
                //designUploadId: this.state.submitQuestionAnswersResponse["designUploadId"],
                redirectToFTPGuide:true
            })
        }

        console.log("this.state.designId: "+this.state.designId)
        console.log("this.state.designUploadId: "+this.state.designUploadId)
        //designId={this.state.designId} designUploadId={this.state.designUploadId}

    }

    HandleSuccessGetQuestionAnswersByDesignUploadId = (res) =>{
        this.setState({
            fetchSavedQuestionAnswers: false,
            savedQuestionAnswers: res.data,
            fetchUploadFileList: true,
        })

        this.state.selectValue = {}
        this.state.selectChildValue = {}
        this.state.fileNameInput = ''
        this.state.fileSizeInput = ''

        console.log("savedQuestionAnswers: "+JSON.stringify(this.state.savedQuestionAnswers))

        let count = 0;
        let that = this;

        let selectValueTemp = {}
        let selectChildValueTemp = {}
        let showQuestionOnSelectTemp = {}
        let showMyChildComponentTemp = {}
        let showChildConditionalQuestionTemp = {}
        let designUploadQuestionAnswersTemp = []
        var aa=0,b=0;
        var t0 = performance.now();

        this.state.savedQuestionAnswers["customerDesignUploadAnswers"].map(function(item) {
            console.log("INSIDE savedQuestionAnswers")
            let tempAnswers = {}

            if(item["technologyQuestionId"] != null) {
                tempAnswers["technologyQuestionId"] = item["technologyQuestionId"]
                console.log("OUTSIDE: "+item["technologyQuestionId"])
                console.log("OUTSIDE: "+item["technologyAnswerId"])
                if (item["answerValue"] != null) {
                    console.log("PARENT TECH ID: "+item["technologyQuestionId"])
                    console.log("ANSWER TECH ID: "+item["technologyAnswerId"])
                    //that.state.selectValue[item["technologyQuestionId"]] = item["answerValue"]
                    //that.state.selectChildValue[item["technologyQuestionId"]] = item["answerValue"]
                    selectValueTemp[item["technologyQuestionId"]] = item["answerValue"]

                    console.log("PARENT TECH: "+item["parentTechnologyAnswerSet"])


                        console.log("PARENT TECH: "+item["technologyQuestionId"])
                    if(selectChildValueTemp[item["technologyQuestionId"]] == null){
                        console.log("PARENT TECH: "+selectChildValueTemp[item["technologyQuestionId"]])
                        selectChildValueTemp[item["technologyQuestionId"]] = item["answerValue"]
                    }




                    tempAnswers["answerValue"] = item["answerValue"];
                    console.log("INSIDE IF: "+item["technologyQuestionId"])
                    console.log("INSIDE IF: "+item["technologyAnswerId"])
                } else {
                    console.log("PARENT TECH ID: "+item["technologyQuestionId"])
                    console.log("ANSWER TECH ID: "+item["technologyAnswerId"])
                    console.log("INSIDE ELSE: "+item["technologyQuestionId"])
                    console.log("INSIDE ELSE: "+item["technologyAnswerId"])
                    selectValueTemp[item["technologyQuestionId"]] = item["technologyAnswerId"]

                    console.log("PARENT TECH: "+item["parentTechnologyAnswerSet"])

                    if(selectChildValueTemp[item["technologyQuestionId"]] == null){
                        console.log("PARENT TECH: "+selectChildValueTemp[item["technologyQuestionId"]])
                        selectChildValueTemp[item["technologyQuestionId"]] = item["technologyAnswerId"]
                    }




                    tempAnswers["technologyAnswerId"] = Number(item["technologyAnswerId"]);
                }
            }else{
                if(item["parentTechnologyAnswerId"] != null){
                    console.log("INSIDE ELSE PARENT")
                    console.log("PARENT TECH ID: "+item.parentTechnologyAnswerSet["technologyQuestionId"])
                    console.log("ANSWER TECH ID: "+item["technologyAnswerId"])
                    // that.state.selectChildValue[item.parentTechnologyAnswerSet["technologyQuestionId"]] = item["technologyAnswerId"]
                    if(selectChildValueTemp[item.parentTechnologyAnswerSet["technologyQuestionId"]] != null){

                        if(selectChildValueTemp.length >0) {
                            selectChildValueTemp.map(function (item, index) {
                                if (item["technologyQuestionId"] == item.parentTechnologyAnswerSet["technologyQuestionId"]) {
                                    console.log("index inside HandleOnBlur: " + index)
                                    console.log(item["technologyQuestionId"])
                                    let indexToDelete = index
                                    selectChildValueTemp.splice(indexToDelete, 1)
                                }
                            })
                        }

                    }
                    selectChildValueTemp[item.parentTechnologyAnswerSet["technologyQuestionId"]] = item["technologyAnswerId"]

                    console.log("CHILD TEMP: "+JSON.stringify(selectChildValueTemp))
                    tempAnswers["parentTechnologyAnswerId"] = item["parentTechnologyAnswerId"]
                    tempAnswers["technologyAnswerId"] = Number(item["technologyAnswerId"]);
                    showQuestionOnSelectTemp[item["parentTechnologyAnswerId"]] = true
                }
            }

            console.log("that.state.parentArrValuesList: "+JSON.stringify(that.state.parentArrValuesList))
            that.state.parentArrValuesList.map(function(item2) {
                console.log("=====================================Question=================================")
                console.log(item2[0].designUploadQuestionSet["question"])
                console.log("selectValueTemp[item2[0][\"technologyQuestionId\"]]: "+selectValueTemp[item2[0]["technologyQuestionId"]])
                console.log("=====================================Question=================================")

                if(item2[0].designUploadQuestionSet["question"] == 'Quantity of packaged chips'){
                    that.state.quantityOfPackagedChips = selectValueTemp[item2[0]["technologyQuestionId"]]
                    that.state.quantityOfPackagedChipsQuestionId = item2[0]["technologyQuestionId"]
                }else if(item2[0].designUploadQuestionSet["question"] =="Quantity Ordered"){
                    that.state.quantityOrdered = selectValueTemp[item2[0]["technologyQuestionId"]]
                }

                if(item2["child"] != null && item2[0]["technologyQuestionId"] == item["technologyQuestionId"]){
                    showMyChildComponentTemp[item2[0]["questionId"]] = item2[0]["questionId"];
                }

                b++;
                let tempConditionalQuestionState = {}
                if(item2["child"] != null) {
                    console.log("QUESTION: "+item2[0].designUploadQuestionSet["question"])
                    console.log("item.technologyQuestionId: "+item.technologyQuestionId)
                    console.log("item2.technologyQuestionId: "+item2[0].technologyQuestionId)
                    //console.log("item2[\"child\"]: "+JSON.stringify(item2["child"] ))

                    if(item.technologyQuestionId == item2[0].technologyQuestionId) {
                        console.log("item2[\"child\"]: " + JSON.stringify(item2["child"]))
                        item2["child"].map(function (item3) {
                            aa++;


                            console.log("Inside conidtional Input: "+JSON.stringify(item3))
                            let questionId = item3["technologyQuestionId"]
                            console.log("questionId: " + questionId)

                            if (item3.designUploadQuestionSet != null) {
                                console.log("INSIDE IF: " + item3.designUploadQuestionSet.subQuestionConditionObject["codeValue"])
                                if (item3.designUploadQuestionSet.subQuestionConditionObject["codeValue"] == ">0") {
                                    console.log("item[\"answerValue\"]: " + item["answerValue"])
                                    console.log("item: " + JSON.stringify(item))
                                    if (item["answerValue"] != null) {
                                        if (item["answerValue"] > 0) {
                                            tempConditionalQuestionState[questionId] = "true"
                                            console.log("tempConditionalQuestionState" + JSON.stringify(tempConditionalQuestionState))
                                        } else {
                                            tempConditionalQuestionState[questionId] = "false"
                                            console.log("tempConditionalQuestionState" + JSON.stringify(tempConditionalQuestionState))
                                        }
                                    } else {
                                        tempConditionalQuestionState[questionId] = "false"
                                        console.log("tempConditionalQuestionState" + JSON.stringify(tempConditionalQuestionState))
                                    }
                                } else {
                                    if (item["answerValue"] != '') {
                                        tempConditionalQuestionState[questionId] = "true"
                                    } else {
                                        tempConditionalQuestionState[questionId] = "false"
                                    }
                                }
                            }
                        })

                        console.log("tempConditionalQuestionState final: " + JSON.stringify(tempConditionalQuestionState))
                        showChildConditionalQuestionTemp[item2[0]["questionId"]] = tempConditionalQuestionState
                    }
                }
                //console.log("showChildConditionalQuestion"+JSON.stringify(that.state.showChildConditionalQuestion))
            })
            //saving in object again
            designUploadQuestionAnswersTemp.push(tempAnswers)

            console.log(count)
            count++
        })

        that.setState({
            // showChildConditionalQuestion: that.state.showChildConditionalQuestion,
            // showMyChildComponent: that.state.showMyChildComponent
            showChildConditionalQuestion: showChildConditionalQuestionTemp,
            showMyChildComponent: showMyChildComponentTemp
        })
        console.log("selectChildValueTemp: "+JSON.stringify(selectChildValueTemp))
        that.setState({
            selectValue:selectValueTemp,
            selectChildValue:selectChildValueTemp,
            showQuestionOnSelect:showQuestionOnSelectTemp
        })
        that.state.designUploadQuestionAnswers = designUploadQuestionAnswersTemp
        var t1 = performance.now();
        console.log("Call to doSomething took " + (t1 - t0) + " milliseconds.");
        console.log("aa: "+ aa);
        console.log("b: "+ b);
        /*if(this.state.savedQuestionAnswers["designFileName"] != null){
            this.state.isFileSizeSelected = "true"
            this.state.fileNameInput = this.state.savedQuestionAnswers["designFileName"]
            this.state.fileSizeInput = this.state.savedQuestionAnswers["designFileSize"]
            this.state.showFilNameInput = true
        }else{
            this.state.isFileSizeSelected = "false"
            this.state.showFilNameInput = false
        }*/
        this.state.isFileSizeSelected=''
        this.setState({
            selectValue:that.state.selectValue,
            selectChildValue:that.state.selectChildValue,


        },() => {
            console.log("selectChildValueTemp=: "+JSON.stringify(that.state.selectValue))
            that.setState({
                showQuestionAnswers:true
            })
        })
    }

    HandleSuccessGetFileSizeLimit = (res) => {
        this.setState({
            fileSizeLimit: res.data
        })
        console.log("File Size Limit Object: "+JSON.stringify(this.state.fileSizeLimit))
    }

    HandleSuccessGetCustomerDetails = (res) => {
        this.setState({
            customerDetails:res.data,
            fetchCustomerDetails:false
        })
        console.log("customerDetails: "+JSON.stringify(this.state.customerDetails))

        this.state.customerId = res.data["customerId"]
        console.log("customerId: "+this.state.customerId)
    }

//============================================SUCCESS FUNCTIONS - END===================================================

    CallOnMouseHover = (fabScheduleId, date, id) => {
        if ((window.location.href).includes('dashboard')) {
            if (this.state.milestoneId !== id)
                this.setState({
                    milestoneDate: date,
                    milestoneId: id,
                    fetchMilestone: true,
                    fabScheduleId: fabScheduleId
                })
            else
                $('#' + this.state.milestoneId + ' .inner_table').css('display', 'block');
        }
    }

    checkAnswer = () => {
        console.log("inside check answer")
    }

    designNumberClick = () => {
        console.log("inside design number click")
        this.setState({
            customerId:this.state.customerId,
            fetchFoundryAndTechClass: true,
            showPopup:true
        })

    }

    ShowHideSteps = (pos) => {
        let that = this;
        if (pos === 'first' || pos === 'showsecond') {
            that.state.allFieldsFilled = true
            $("input").each(function() {

                console.log("$(this).attr(\"type\"): "+$(this).attr("type"))
                var element = $(this);
                var id = $(this).attr("id");
                var req = $(this).attr("required");
                console.log("req"+req)
                if (element.val() == "" && $(this).is(":visible") && req == "required" && $(this).attr("type") != "file") {
                        PrValidate.ForceEntryWithoutMessage($(this).attr("value"), id);
                        that.state.allFieldsFilled = false;
                        console.log("Empty Element: "+id)
                        that.state.enterInputError[id] = true;
                }/*else if($(this).attr("type") == "file" && $(this).is(":visible")){
                    var spanId = $(this).attr("id")+"_span"
                    that.state.enterInputError[id] = false;
                    if(document.getElementById($(this).attr("id")+"_group").style.display == "block"){
                        if(document.getElementById(spanId).style.display == "none"){
                            if(element.val() == "") {
                                    console.log("inside file empty")
                                    console.log($(this).is(":visible"));
                                    //if(element.val() == "" && $("#"+spanId).is(":hidden")){
                                    console.log("file question id: " + $(this).attr("id"))
                                    PrValidate.ForceEntryWithoutMessage($(this).attr("value"), id);
                                    that.state.allFieldsFilled = false;
                                    console.log("Empty Element: " + id)
                                    that.state.enterInputError[id] = true;
                            }
                        }
                    }
                }*/else{
                    that.state.enterInputError[id] = false;
                }
            });

            console.log("react-multi-select__control: "+$(".react-multi-select-input").length)

            $(".react-multi-select-input").each(function() {
                var element = $(this);
                var id = $(this).attr("id");
                var className = $(this).attr("class");
                console.log("react-multi-select__control: "+id)
                console.log("react-multi-select__control: "+className)

                console.log("that.state.selectValue[id]: "+that.state.selectValue[id])
                if ((that.state.selectValue[id] == "" || that.state.selectValue[id] == undefined || that.state.selectValue[id]==null) && $(this).is(":visible") && className.includes("required")) {
                    //PrValidate.ForceEntryWithoutMessage($(this).attr("value"), id);
                    PrValidate.ForceMultiSelectWithoutMessage(that.state.selectValue[id], id);
                    that.state.allFieldsFilled = false;
                    console.log("Empty Element: "+id)
                    that.state.enterInputError[id] = true;
                }else{
                    that.state.enterInputError[id] = false;
                }


            });



            $("select").each(function() {
                var id = $(this).attr("id");
                console.log("select id: "+id)
                console.log("select val: "+$(this).val())
                console.log("is visible: "+ $(this).is(":visible"))
                // if ($(this).is(':selected')){
                if ($(this).val() == "" && $(this).is(":visible")){
                    PrValidate.ForceSelectWithoutMessage($(this).val(), id, "Input");
                    console.log("INSIDE IF")
                    that.state.allFieldsFilled = false;
                    console.log("Empty Element: "+id)
                    that.state.enterInputError[id] = true;
                }else{
                    that.state.enterInputError[id] = false;
                }

                if(!$(this).is(":visible")){
                    console.log("NOT VISIBLE SELECT: ")
                    console.log("Empty Element: "+id)
                }
            });


            if(this.state.allFieldsFilled == true){
                that.state.enterAllQAErrorFirstScreen = false;
            }else{
                that.state.enterAllQAErrorFirstScreen = true;
            }

            this.setState({
                enterInputError: that.state.enterInputError,
                enterAllQAErrorFirstScreen: that.state.enterAllQAErrorFirstScreen
            })

            if(that.state.allFieldsFilled ==true && that.state.showQuantityOfPackagedError === false){
                that.setState({
                    showFirstStep: 'none',
                    showSecondStep: 'block',
                    showThirdStep: 'none'
                });
            }

        }
        else if (pos === 'showthird') {

            that.setState({
                showFirstStep: 'none',
                showSecondStep: 'none',
                showThirdStep: 'block'
            })
        }
        else if (pos === 'showfirst') {
            that.setState({
                showFirstStep: 'block',
                showSecondStep: 'none',
                showThirdStep: 'none'
            })
        }
    }

    getCurrentDate = () => {
        this.state.todayDate = new Date();
        let dd = this.state.todayDate.getDate();

        let mm = this.state.todayDate.getMonth()+1;
        let yyyy = this.state.todayDate.getFullYear();
        if(dd<10)
        {
            dd='0'+dd;
        }

        if(mm<10)
        {
            mm='0'+mm;
        }
        this.state.todayDate = mm+'-'+dd+'-'+yyyy;
        console.log(this.state.todayDate);
    }

    SubmitClick = () => {
        console.log("on submit click")
        console.log("this.state.fileNameInput: "+this.state.fileNameInput)
        console.log("this.state.fileSizeSelectionValue: "+this.state.fileSizeSelectionValue)

        console.log("this.state.showFilNameInput: "+this.state.showFilNameInput)
        console.log("this.state.fileNameInput: "+this.state.fileNameInput)
        console.log("this.state.fileSizeInput: "+this.state.fileSizeInput)

        if((this.state.showFilNameInput === true && (this.state.fileNameInput != '' && this.state.fileNameInput != null) && (this.state.fileSizeInput != null && this.state.fileSizeInput != ''))
            || (this.state.showFilNameInput === false && this.state.fileSizeSelectionValue != '')){
            if(this.state.designUploadQuestionAnswers != null) {

                console.log("selectedDesignObjectMap: "+JSON.stringify(this.state.selectedDesignObjectMap[this.state.designId]))
                if(this.state.selectedDesignObjectMap[this.state.designId].customerDesignUpload["uploadedOnS3"] === false){
                    this.state.designUploadRequestAnswersResponse["designUploadId"] = this.state.idAndDesignUploadIdMap[this.state.designId]
                }
                this.state.designUploadRequestAnswersResponse["customerId"] = this.state.customerId
                this.state.designUploadRequestAnswersResponse["designNumberInfoId"] = Number(this.state.designId)

                if(this.state.showFilNameInput === true){
                    this.state.designUploadRequestAnswersResponse["fileSizeLimit"] = this.state.fileSizeLimit.codeValue
                    this.state.designUploadRequestAnswersResponse["fileUploadBy"] = JSON.parse(localStorage.getItem('okta-token-storage')).idToken.claims.email
                }else{
                    this.state.designUploadRequestAnswersResponse["fileSizeLimit"] = "NO"
                }
                this.state.designUploadRequestAnswersResponse["designFileName"] = this.state.fileNameInput
                this.state.designUploadRequestAnswersResponse["designFileSize"] = this.state.fileSizeInput*Math.pow(1024,3)

                this.state.designUploadRequestAnswersResponse["customerDesignUploadAnswers"] = this.state.designUploadQuestionAnswers
                this.state.designUploadRequestAnswersResponse["designUploadFileMappingList"] = this.state.fileObjList
                this.state.designUploadRequestAnswersResponse["deletedFileTypeList"] = this.state.deletedFileTypes

                if(this.state.isFileSizeSelected == "false"){
                    console.log("inside false")
                    this.state.designUploadRequestAnswersResponse["isTus"] = "1"
                }else{
                    console.log("inside true")
                    this.state.designUploadRequestAnswersResponse["isTus"] = null
                    this.state.designUploadRequestAnswersResponse["modifiedFileName"] =
                    this.state.fileNameInput+"_"+this.state.designNumberValue+"-"+"<rev_num>"+"-"+this.state.customerDetails["accountNumber"]+"-"+this.state.todayDate
                }
                //this.state.designUploadRequestAnswersResponse["customerDesignUploadChildAnswers"] = this.state.designUploadChildAnswers
                console.log("length of customerDesignUploadAnswers :" + this.state.designUploadRequestAnswersResponse["customerDesignUploadAnswers"].length)

                /*this.state.designUploadRequestAnswersRespose["customerDesingUploadAnswers"] = */
                console.log("designUploadRequestAnswersResponse: "+JSON.stringify(this.state.designUploadRequestAnswersResponse))
                this.checkValidationResponse()
                if(this.state.allFieldsFilled && this.state.showInvalidFileSizeError === false){
                    this.setState({
                        enterAllQAError: false,
                        disabledFormButtonsOnSubmit:true,
                        showConfirmationPopup:true
                    })
                }else{
                    if(this.state.allFieldsFilled === false){
                        this.state.allQAErrorContent = "* Please Enter All Answers"
                    }else{
                        this.state.allQAErrorContent = "* Please Enter Correct File Size"
                    }
                    this.setState({
                        enterAllQAError: true
                    })

                    console.log("Please Enter all question Answers")
                }


            }else{

                this.setState({
                    selectQuestionAnswers: true
                })
                console.log("Please enter question answers")
            }
        }else if(this.state.showFilNameInput === true && (this.state.fileNameInput == '' || this.state.fileNameInput == null) && (this.state.fileSizeInput == '' || this.state.fileSizeInput == null)){
                this.setState({
                    showFileSizeError: true,
                    showFileNameError: true,
                    showFileSizeSelectionError: false,
                    selectQuestionAnswers:false
                })
        }else if(this.state.showFilNameInput === true && (this.state.fileNameInput == '' || this.state.fileNameInput == null)){
                this.setState({
                    showFileSizeError: false,
                    showFileNameError: true,
                    showFileSizeSelectionError: false,
                    selectQuestionAnswers:false
                })
            console.log("No FileName Entered")
        }else if(this.state.showFilNameInput === true && (this.state.fileSizeInput == '' || this.state.fileSizeInput == null)){
            this.setState({
                showFileSizeError: true,
                showFileNameError: false,
                showFileSizeSelectionError: false,
                selectQuestionAnswers:false

            })
        }else{
            this.setState({
                showFileSizeError: false,
                showFileNameError: false,
                showFileSizeSelectionError: true,
                selectQuestionAnswers:false

            })
        }

        console.log("designUploadRequestAnswersResponse: "+JSON.stringify(this.state.designUploadRequestAnswersResponse))
        this.checkValidationResponse()
        if(this.state.designUploadQuestionAnswers == ''){
            console.log("designUploadQuestionAnswers:  "+this.state.designUploadQuestionAnswers)
            this.setState({
                showFileNameError: false,
                showFileSizeSelectionError: false,
                selectQuestionAnswers:true
            })
        }
    }




    HandleConfirmationSelection= (value) => {
        console.log("value: "+value)
        if(value == true){
            if(this.state.fileObjList.length > 0){
                console.log("Inside show popup")
                this.state.showUploadPopup = true;
            }else{
                console.log("================================== INSIDE SAVE (WITHOUT UPLOAD)=================================")
                this.setState({
                    saveQuestionAnswers: true
                })
            }
        }
        this.setState({
            showConfirmationPopup: false,
            disabledFormButtonsOnSubmit: false
        })
    }

//============================================MODAL FUNCTIONS - START===================================================
    ShowHideModalClose = () => {
        console.log("Inside ShowHideModalClose")
        this.setState({
            showModal: false
        });
    }

    ShowHideModal = () => {
        //$(".popupTop").parent('div').addClass('popupbody');
        console.log("INSIDE ShowHideModal "+this.state.showModal)
        this.setState({
            showModal: !this.state.showModal
        });
        //$(".popupTop").parent('div').addClass('popupbody');
    }
//============================================MODAL FUNCTIONS - END===================================================

    //============================================SFTP Instruction MODAL FUNCTIONS - START===================================================
    ShowHideSftpInstructionModal = () => {
        //$(".popupTop").parent('div').addClass('popupbody');
        console.log("INSIDE ShowHideSftpInstructionModal "+this.state.showSftpInstructionModal)
        this.setState({
            showSftpInstructionModal: !this.state.showSftpInstructionModal
        });
        //$(".popupTop").parent('div').addClass('popupbody');
    }
//============================================SFTP Instruction MODAL FUNCTIONS - END===================================================



    _isNumber(input) {
        return !(typeof input == 'undefined' || isNaN(input));
    }

    roundOff(num, toDecimals) {
        if (!this._isNumber(num))
            throw num;

        return parseFloat(String(num)).toFixed(this.normalizeNumber(toDecimals));
    }

    normalizeNumber(num) {
        return this._isNumber(num) ? Number(num) : 0;
    }

checkRedirect = () => {

        this.setState({
            redirectNow:true
        })
        this.state.redirectNow = true
        console.log("Inside Check Redirect"+this.state.fileUploadRedirect)

    console.log("Inside Check Redirect"+this.state.fileUploadRedirect);
}


    showFileUpload = (redirectToFileUpload) =>{
        console.log("showFileUpload")
        this.setState({
           redirectNow:redirectToFileUpload,
            revisionNumber:this.state.revisionNumber,
            designId:this.state.designId,
            showEditSelection:false
        })

    }
    
    checkValidationResponse = () => {
        this.checkValidation()
        console.log(this.state.allFieldsFilled)
        if(this.state.allFieldsFilled == false){
            console.log("firstErrorReferences: "+JSON.stringify(this.state.firstErrorReferences))
            if(this.state.firstErrorReferences[0] != undefined){
                $('html, body').animate({
                    scrollTop: $("#"+this.state.firstErrorReferences[0]).offset().top-100
                }, 1000);
            }
        }
    }



    checkValidation = () => {
        
        console.log("Inside Check Validation")
        console.log("this.state.parentArrValuesList: "+JSON.stringify(this.state.parentArrValuesList))
        console.log("this.state.selectValue: "+JSON.stringify(this.state.selectValue))
        let that = this
        this.state.allFieldsFilled = true;
        if(this.state.parentArrValuesList != '{}'){
            this.state.parentArrValuesList.map(function (item) {
                console.log(item[0].designUploadQuestionSet["question"])
                console.log("item[0][\"technologyQuestionId\"]: "+item[0]["technologyQuestionId"])
                console.log(that.state.selectValue[item[0]["technologyQuestionId"]])
                if(item[0].designUploadQuestionSet.subQuestionConditionObject.codeValue != "File"){
                    if(that.state.selectValue[item[0]["technologyQuestionId"]] == '' || that.state.selectValue[item[0]["technologyQuestionId"]] == null){
                        console.log("INSIDE IF")
                        that.state.allFieldsFilled = false;
                        that.state.enterInputError[item[0]["technologyQuestionId"]] = true;
                    }else{
                        that.state.enterInputError[item[0]["technologyQuestionId"]] = false;
                    }
                }
            })

            this.state.firstErrorReferences = []
            this.state.firstErrorReferencesForFile = []
            $("input").each(function() {
                var element = $(this);
                var id = $(this).attr("id");
                var req = $(this).attr("required");
                console.log("req"+req)
                if (element.val() == "" && $(this).is(":visible") && req == "required") {

                    PrValidate.ForceEntryWithoutMessage($(this).attr("value"), id);
                    that.state.firstErrorReferences.push(id);
                    console.log("INSIDE IF")
                    that.state.allFieldsFilled = false;
                    console.log("Empty Element: "+id)
                    that.state.enterInputError[id] = true;
                }/*else if($(this).attr("type") == "file" && $(this).is(":visible")){
                    var spanId = $(this).attr("id")+"_span"
                    that.state.enterInputError[id] = false;
                    console.log("$(this).attr(\"id\"): "+$(this).attr("id"))
                    if(document.getElementById($(this).attr("id")+"_group").style.display == "block"){
                        if(document.getElementById(spanId).style.display == "none"){
                            if(element.val() == "") {
                                //if(element.val() == "" && $("#"+spanId).is(":hidden")){
                                console.log("file question id: " + $(this).attr("id"))
                                PrValidate.ForceEntryWithoutMessage($(this).attr("value"), id);
                                that.state.allFieldsFilled = false;
                                console.log("Empty Element: " + id)
                                that.state.enterInputError[id] = true;
                                that.state.firstErrorReferences.push(id.split(" ")[0]);
                            }
                        }
                    }
                }*/else{
                    that.state.enterInputError[id] = false;
                }

                /*if(!$(this).is(":visible") && $(this).attr("type") != "file"){
                    console.log("NOT VISIBLE INPUT: ")
                    console.log("Empty Element: "+id)
                }*/
            });


            $("select").each(function() {
                var id = $(this).attr("id");
                console.log("select id: "+id)
                console.log("select val: "+$(this).val())
                console.log("is visible: "+ $(this).is(":visible"))

                // if ($(this).is(':selected')){
                if ($(this).val() == "" && $(this).is(":visible")){
                    PrValidate.ForceSelectWithoutMessage($(this).val(), id, "Input");
                    that.state.firstErrorReferences.push(id);
                    console.log("INSIDE IF")
                    that.state.allFieldsFilled = false;
                    console.log("Empty Element: "+id)
                    that.state.enterInputError[id] = true;
                }else{
                    that.state.enterInputError[id] = false;
                }

        if(!$(this).is(":visible")){
            console.log("NOT VISIBLE: ")
            console.log("Empty Element: "+id)
        }
            });

        }

        console.log("enterInputError: "+JSON.stringify(this.state.enterInputError))
        this.setState({
            enterInputError: this.state.enterInputError,
            allFieldsFilled: this.state.allFieldsFilled
        })

    }

    HandleFileChange = (e, questionItem, item) => {
        let id = e.target.id
        let fileName = e.target.files[0].name

        let deletedIndex;
        console.log(deletedIndex)
        this.state.deletedFileTypes.map(function (itemDeleted, index) {
            if(itemDeleted == item.designUploadQuestionSet.additionalMappingObject.codeId){
                deletedIndex = index;
            }
        })
        if(deletedIndex != null){
            console.log("INSIDE SPLICE ========================================")
            this.state.deletedFileTypes.splice(deletedIndex,1);
        }

        console.log("this.state.deletedFileTypes: "+JSON.stringify(this.state.deletedFileTypes))
        console.log("e.target.files[0].size: "+e.target.files[0].size)

        let selectedFileData = {}
        selectedFileData["fileName"] = e.target.files[0].name
        selectedFileData["modifiedFileName"] = e.target.files[0].name+"_"+this.state.designNumberValue+"-"+this.state.customerDetails["accountNumber"]+"-"+this.state.todayDate
        selectedFileData["type"] = e.target.files[0].type
        selectedFileData["size"] = e.target.files[0].size
        selectedFileData["file"] = e.target.files[0]
        selectedFileData["additionalMappingId"] = item.designUploadQuestionSet.additionalMappingObject.codeId

        let fileUploaddeletedIndex;
        this.state.filesToUpload.map(function (itemDeleted, index) {
            if(itemDeleted["additionalMappingId"] == item.designUploadQuestionSet.additionalMappingObject.codeId){
                fileUploaddeletedIndex = index;
            }
        })
        if(fileUploaddeletedIndex != null){
            console.log("INSIDE SPLICE ========================================")
            this.state.filesToUpload.splice(fileUploaddeletedIndex,1);
        }

        this.state.filesToUpload.push(selectedFileData);
        console.log("filesToUpload: "+JSON.stringify(this.state.filesToUpload));

        this.onFileUploadSuccess(questionItem, item, fileName);

        /*const params = {
            ACL: 'public-read',
            Key: e.target.files[0].name,
            ContentType: e.target.files[0].type,
            Body: e.target.files[0],
        }*/
    }

    onFileUploadSuccess = (questionItem, item, fileName) => {

        let indexToDelete;
        this.state.fileObjList.map(function (item, index) {
            console.log("item: "+JSON.stringify(item));
            if(questionItem.additionalMappingObject.codeId == item.additionalMapping){
                console.log("INSIDE IF")
                indexToDelete = index
            }
        })
        if(indexToDelete != null){
            this.state.fileObjList.splice(indexToDelete,1);
        }

        let tempQuestionAnswers = {}
        tempQuestionAnswers["technologyQuestionId"] = item["technologyQuestionId"]
        tempQuestionAnswers["answerValue"] = questionItem.additionalMappingObject.codeValue
        this.state.selectValue[item["technologyQuestionId"]] = questionItem.additionalMappingObject.codeValue;


        console.log("questionItem: "+JSON.stringify(questionItem))
        console.log("Inside IF")
        this.removeExistingObject(item["technologyQuestionId"], "technologyQuestionId")
        console.log("this.state.questionAnswers: "+JSON.stringify(this.state.designUploadQuestionAnswers))
        this.state.designUploadQuestionAnswers.push(tempQuestionAnswers);
        this.state.enterInputError["question_"+questionItem.additionalMappingObject.codeValue] = false;
        this.state.enterInputError[item["technologyQuestionId"]] = false;

        this.setState({
            designUploadQuestionAnswers: this.state.designUploadQuestionAnswers
        })

        let fileObj = {}
        fileObj["additionalMapping"] = item.designUploadQuestionSet.additionalMappingObject.codeId
        fileObj["fileName"] = fileName
        let modifiedFileName = fileName+"_"+this.state.designNumberValue+"-"+this.state.customerDetails["accountNumber"]+"-"+this.state.todayDate
        fileObj["modifiedFileName"] = modifiedFileName
        this.state.fileObjList.push(fileObj);

        this.setState({
            fileObjList: this.state.fileObjList,
            fileList: this.state.fileList,
            fileTypeList: this.state.fileTypeList
        });

        console.log("this.state.fileObjList: "+JSON.stringify(this.state.fileObjList));
        console.log("this.state.designUploadQuestionAnswers: "+JSON.stringify(this.state.designUploadQuestionAnswers));

    }

    HandleFileUploadCompletion = (value) => {

        console.log("value: "+value)
        if(value == true){
            console.log("=============================== INSIDE SAVE =============================")
            this.state.showUploadPopup = false;
            this.setState({
                saveQuestionAnswers: true
            })
        }
        this.setState({
            showConfirmationPopup: false,
            disabledFormButtonsOnSubmit: false
        })
    }

    HandleSuccessGetUploadFileListResponse = (res) => {
        this.setState({
            uploadedFileObj: res.data,
            fetchUploadFileList: false,

        })
        console.log("res HandleSuccessGetUploadFileListResponse: "+JSON.stringify(this.state.uploadedFileObj))
        this.fillQuestionAnswerObject()

    }

    fillQuestionAnswerObject = () => {
        console.log("FILEOBJ: "+JSON.stringify(this.state.uploadedFileObj));

        let that = this;

        this.state.uploadedFileObj.map(function (item) {
            console.log("Inside File Obj")
            console.log("item: "+JSON.stringify(item))
            //var documentId = item.documentId
            var fileName = item.fileName
            var fileType = item.additionalMappingObject.codeValue
            //var fileLink = item.fileLink
            console.log("fileName: "+fileName)
            console.log("fileType: "+fileType)
            //console.log("fileLink: "+fileLink)
            //var fileLinkTag = "viewFile('<c:out value='fileLink'/>')"

            if(document.getElementById("question_"+fileType+"_fileName")!=null){
                //document.getElementById("question_"+fileType+"_documentId").textContent = documentId;
                document.getElementById("question_"+fileType+"_fileName").textContent = fileName;
                document.getElementById("question_"+fileType+"_span").style.display = "block";
                //document.getElementById("question_"+fileType+"_fileLink").onclick = function() {that.downloadFile(that.state.apiUrl + '/customerFileDownload/downloadFile/' + item["documentId"], item["fileName"])};
                document.getElementById("question_"+fileType).style.display = "none";
            }
        });
        /*this.state.uploadedFileObj.map(function (item) {
            console.log("Inside File Obj")
            console.log("item: "+JSON.stringify(item))
            var documentId = item.documentId
            var fileName = item.fileName
            var fileType = item.fileType
            var fileLink = item.fileLink
            console.log("fileName: "+fileName)
            console.log("fileType: "+fileType)
            console.log("fileLink: "+fileLink)
            //var fileLinkTag = "viewFile('<c:out value='fileLink'/>')"

            if(document.getElementById("question_"+fileType+"_fileName")!=null){
                document.getElementById("question_"+fileType+"_documentId").textContent = documentId;
                document.getElementById("question_"+fileType+"_fileName").textContent = fileName;
                document.getElementById("question_"+fileType+"_span").style.display = "block";
                document.getElementById("question_"+fileType+"_fileLink").onclick = function() {that.downloadFile(that.state.apiUrl + '/customerFileDownload/downloadFile/' + item["documentId"],
                    item["fileName"])};
                document.getElementById("question_"+fileType).style.display = "none";
            }
        });
*/
        console.log("htmlTextArr: "+JSON.stringify(this.state.htmlTextArr));

        this.setState({
            filesDone: true
        })

    }

    deleteFile = (deleteBtn, eleId, additionalMappingObj) => {

        //console.log("eleID: "+eleId)
        console.log("additionalMappingObj: "+JSON.stringify(additionalMappingObj))
        this.state.deletedFileTypes.push(additionalMappingObj.codeId);
        console.log("this.state.deletedFileTypes: "+JSON.stringify(this.state.deletedFileTypes));

        console.log("eleID inside deleteFile: "+eleId)
        var documentId = document.getElementById(eleId+"_documentId").innerHTML;
        console.log("documentId: "+documentId)
        deleteBtn.target.style.display = "none";
        //document.getElementById("span-"+eleId).style.display = "none";
        document.getElementById(eleId).style.display = "block";
        document.getElementById(eleId+"_span").style.display = "none";

        this.state.deletedFileItemsDocumentNumbers.push(documentId);

    }

    downloadFile = (url, fileName) => {
        console.log("downloadFile: "+url)
        console.log("fileName: "+fileName)
        let anchor = document.createElement("a");
        document.body.appendChild(anchor);
        let file = url

        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + JSON.parse(localStorage.getItem('okta-token-storage')).accessToken.accessToken);

        fetch(file, { headers })
            .then(response => response.blob())
            .then(blobby => {
                let objectUrl = window.URL.createObjectURL(blobby);

                anchor.href = objectUrl;
                anchor.download = fileName;
                anchor.click();

                window.URL.revokeObjectURL(objectUrl);
            });
    }





    render() {

        return (
            <div>
            {this.state.redirectNow ?
                    <ServicesDesignFileUploadComponentCtrl {...this.props} state={this.state} 
                    revisionNumber={this.state.revisionNumber} designId={this.state.designId} designUploadId={this.state.designUploadId} selectedDesignNumberDetails={this.state.selectedDesignNumberDetails}
                                                           history={this.props.componentprops.history}
                    />
                
                    : ''
            }

                {this.state.redirectToFTPGuide ?
                    <DesignUploadFTPGuideComponentCtrl {...this.props} state={this.state} history={this.props.componentprops.history}/>
                    : ''
                }



                {this.state.showModal ?
                    <div className="popupbody">
                    <div className="popupTop">
                        <div className="popup_close">
                            <button onClick={this.ShowHideModal} className="btn">
                                <img src={closeImg} alt="logo" />
                            </button>
                        </div>
                        <div className='popup'>
                            <div className='popup_inner'>
                                <TableDesignCtrl onHandleChange={this.handleChange} foundryAndTechClass={this.state.foundryAndTechClass} />
                            </div>
                        </div>
                    </div>
                    </div>
                    : ''
                }

                {this.state.showSftpInstructionModal ?
                    <div className="popupbody">
                        <div className="popupTop" style={{width: "60%", top: "6%"}}>
                            <div className="popup_close">
                                <button onClick={this.ShowHideSftpInstructionModal} className="btn">
                                    <img src={closeImg} alt="logo" />
                                </button>
                            </div>
                            <div className='popup'>
                                <div className='popup_inner'>
                                    <SFTPInstructionPopup></SFTPInstructionPopup>

                                    {/*<TableDesignCtrl onHandleChange={this.handleChange} foundryAndTechClass={this.state.foundryAndTechClass} />*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''
                }

                {this.state.showEditSelection ?
                    <div className="popupTop">
                        <div className='popup'>
                            <div className='popup_inner'>
                                <PopupCtrl HandleEditSelection={this.HandleEditSelection}  showFileUpload={this.showFileUpload} designFileSize={this.state.designFileSize} fileTusId={this.state.fileTusId}/>
                            </div>
                        </div>
                    </div>
                    : ''
                }

                {this.state.showConfirmationPopup ?
                    <div className="popupbody">
                    <div className="popupTop" >
                        <div className='popup'>
                            <div className='popup_inner1'>
                                <ConfirmationPopupCtrl HandleConfirmationSelection={this.HandleConfirmationSelection} />
                            </div>
                        </div>
                    </div>
                    </div>
                    : ''
                }

                {this.state.showUploadPopup ?
                    <div className="popupbody">
                        <div className="popupTop" >
                            <div className='popup'>
                                <div className='popup_inner1'>
                                    <UploadPopupCtrl HandleFileUploadCompletion={this.HandleFileUploadCompletion}
                                                     fileObjList={this.state.fileObjList}
                                                     filesToUpload={this.state.filesToUpload}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''
                }


                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_CENTER} />
                {this.state.redirectNow ==false && this.state.redirectToFTPGuide == false?
                <DesignUpload {...this.props} state={this.state} handleChange={this.handleChange}
                              HandleInputChange={this.HandleInputChange}
                              HandleSelectChange={this.HandleSelectChange}
                             HandleOnBlur={this.HandleOnBlur}
                             HandleOnClick={this.HandleOnClick}
                             foundries={this.state.foundriesList} techNodes={this.state.techNodesList}
                             questionsByTypeAndTechClass={this.state.questionByTypeAndTechClass}
                             checkAnswer={this.checkAnswer}
                             parentArrValuesList={this.state.parentArrValuesList}
                              ShowHideSteps={this.ShowHideSteps}
                              designNumberClick={this.designNumberClick}
                              SubmitClick={this.SubmitClick}
                              showChildQuestion={this.state.showChildQuestion}
                              HandleInputChildChange={this.HandleInputChildChange}
                              showChildConditionalQuestion={this.state.showChildConditionalQuestion}
                              ShowHideModal={this.ShowHideModal}
                              selectValue={this.state.selectValue}
                              HandleChildChange={this.HandleChildChange}
                              showQuestionOnSelect={this.state.showQuestionOnSelect}
                              HandleEditSelection={this.HandleEditSelection}
                              HandleFileSizeSelection={this.HandleFileSizeSelection}
                              fileNameInput={this.state.fileNameInput}
                              HandleFileNameAndSizeChange={this.HandleFileNameAndSizeChange}
                              showFileNameError={this.state.showFileNameError}
                              fileSizeLimit={this.state.fileSizeLimit}
                              fileSizeInput={this.state.fileSizeInput}
                              fileSizeSelectionValue={this.state.fileSizeSelectionValue}
                              isFileSizeSelected={this.state.isFileSizeSelected}
                              checkRedirect={this.checkRedirect}
                              txtTechNode={this.state.txtTechNode}
                              txtFoundry={this.state.txtFoundry}
                              checkValidation={this.checkValidation}
                              checkValidationResponse={this.checkValidationResponse}
                              enterAllQAError={this.state.enterAllQAError}
                              showInvalidFileSizeError={this.state.showInvalidFileSizeError}
                              enterAllQAErrorFirstScreen={this.state.enterAllQAErrorFirstScreen}
                              showNextButton={this.state.showNextButton}
                              disabledFormButtonsOnSubmit={this.state.disabledFormButtonsOnSubmit}
                              HandleSizeOnBlur={this.HandleSizeOnBlur}
                              HandleFileChange={this.HandleFileChange}
                              deleteFile={this.deleteFile}
                              ShowHideSftpInstructionModal={this.ShowHideSftpInstructionModal}
                              isFoundryGForIntel={this.state.isFoundryGForIntel}
                              onMultiSelectChange={this.onMultiSelectChange}
                              showQuestionAnswers={this.state.showQuestionAnswers}

                />
                :''}

                {/*{this.state.fetchFoundries === true ? GetFoundriesList(this.props.PrStore, this.HandleSuccessGetFoundriesResponse) : ''}*/}

                {this.state.fetchTechNode === true ? GetTechListByFoundry(this.props.PrStore, this.state.ddlFoundry, this.HandleSuccessGetTechNodesResponse) : ''}
                {/*{this.state.fetchUploadQuestionList === true ? getDesignUploadQuestionGroup(this.props.PrStore, this.HandleSuccessGetQuestionsResponse) : ''}*/}
                {this.state.fetchQuestionByTypeAndTechClass === true ? getQuestionsByTechClass(this.props.PrStore, this.HandleSuccessGetQuestionsByQuestionTypeAndTechClass, this.state.techNodeValue) : ''}
                {this.state.fetchFoundryAndTechClass === true ? getFoundryAndTechClassByCustomerId(this.props.PrStore, this.HandleSuccessGetFoundryAndTechClass) : ''}

                {/*{this.state.fetchFoundryAndTechClass === true ? getFoundryAndTechClass(this.props.PrStore, this.HandleSuccessGetFoundryAndTechClass) : ''}*/}
                {this.state.saveQuestionAnswers === true? submitQuestionAnswers(this.props.PrStore, this.HandleSuccessSaveQuestionAnswers, this.state.designUploadRequestAnswersResponse):''}
                {this.state.fetchSavedQuestionAnswers === true? getQuestionAnswersByDesignUploadId(this.props.PrStore, this.HandleSuccessGetQuestionAnswersByDesignUploadId, this.state.designUploadId):''}
                {this.state.fetchFileSizeLimit === true? getFileSizeLimit(this.props.PrStore, this.HandleSuccessGetFileSizeLimit):''}
                {this.state.fetchCustomerDetails === true? getCustomerDetails(this.props.PrStore, this.HandleSuccessGetCustomerDetails):''}

                {this.state.fetchUploadFileList === true ? GetUploadFileList(this.props.PrStore, this.state.designUploadId, this.HandleSuccessGetUploadFileListResponse) : ''}

                {/*{this.state.fetchMilestone === true ? GetMilestoneByFoundryAndShipDate(this.props.PrStore, this.state.ddlFoundry, this.state.milestoneDate, this.state.fabScheduleId, this.HandleSuccessGetMilestoneResponse) : ''}*/}
            </div>
        )
    }
}


export const TestServicesCtrl = PrConnect(testServicesCtrl, '');
