import React, { Component} from 'react';
import QuoteMPWComponent from './quotempw';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import { getInitData, submitGfmpwQuoteData} from '../../../services/quoteGfmpwService'
import PrValidate from '../../../library/pr-controls/pr-validation-full';
import $ from "jquery";
import {getCustomerData} from "../../../services/users";
import DashboardCtrl from "../Home";
import menuItems from "../../../common/DashboardHeader/menuItems";
import {PrConnect} from "../../../library/pr-miniredux";
import quoteDedicatedCtrl from "../Quote_Dedicated";

export default class quoteMPWCtrl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            txtCustName: '',
            txtQuoteNumber: '',
            txtEmail: JSON.parse(localStorage.getItem('okta-token-storage')).idToken.claims.email,
            txtDesignNumber: '',
            ddlTechNode: '',
            txtInputDieSize: '',
            txtFoundryMinDesignArea: '',
            ddlAdditionalDieQty: '',
            txtRetailPrice: '',
            txtAdditionalDie: '',
            txtOptions: '',
            txtExpediteFee: '',
            txtExpediteLotFee: '',
            txtItarServices: '',
            txtAdditionalDesignUploadFees: '',
            txtTotalPrice: '',
            txtReticleSpacePrice:'',

            fetchInitData: true,
            saveGfmpwQuoteData: false,

            gfMpwQuoteData:{},
            technologyProcessPricing:{},
            selectedProcess: {},

            isShortLoopSelected: false,
            isShortLoopSelectedPrice: 0,
            mosisMarkUp:'',
            additionalDieQuantity:'',

            processOptions:[],
            processAdders:[],
            isCalulated:'',

            custData: true,
            customerData: {},
            redirectToMainPage: false,
            technologyNodeList:[],
            quotationTncList:{},
            quotationTncListMap:{},
            mpwGfPricingId:'',
            mpwQuotationServiceList:[],
            mpwQuotationServiceListFiltered:[]
        }
    }

    UNSAFE_componentWillMount = () => {
        this.setState({
            fetchInitData: true
        })
    }

    convertNumberToOnePointDecimal = (num) => {
        return Number(num).toFixed(1);
    }

    convertToRoundNumber = (value) => {
        return Math.round(Number(value.toString().replace(/[^0-9.-]+/g,""))).toString();
    }

    convertCurrencyToNumber = (currency) => {
        return Number(currency.replace(/[^0-9.-]+/g,""));
    }

    convertToCurrencyNumber = (value) => {
        var num = Math.round(Number(value.toString().replace(/[^0-9.-]+/g,"")));
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    removeElements = (className) => {
        var elementsToRemove = document.getElementsByClassName(className);
        if(elementsToRemove && elementsToRemove.length>0){
            while (elementsToRemove.length > 0) elementsToRemove[0].remove();
        }
    }

    addTableTdWithTitle = (opt) => {
        /*var td = document.createElement('td');
        td.className = 'notopthin';
        td.width = '50%';
        td.innerHTML = opt.foundryService["serviceName"];*/

        var td1 = document.createElement('label');
        td1.innerHTML = opt.foundryService["serviceName"];

        var td2 = document.createElement('div');
        td2.className = 'inner_left';

        var td3 = document.createElement('div');
        td3.className = 'col-lg-4 col-md-4 col-sm-6';

        td2.appendChild(td1)
        td3.appendChild(td2)


        return td3;
    }

    addTableTdWithYesNoSelect = (opt) => {
        /*var td1 = document.createElement('td');
        td1.className = 'notopleftthin';
        td1.width = '50%';*/

        var td1 = document.createElement('div');
        td1.className = 'custom_select';
        //td1.width = '50%';

        var td2 = document.createElement('div');
        td2.className = 'form-group';
        //td1.width = '50%';

        var td3 = document.createElement('div');
        td3.className = 'inner_right';
        //td1.width = '50%';

        var td4 = document.createElement('div');
        td4.className = 'col-lg-8 col-md-8 col-sm-6';
       // td1.width = '50%';


        var selectList = document.createElement("select");
        selectList.id = "opt_select_"+ opt.foundryService["foundryServiceId"];
        //selectList.style="width: 75px;";
        td1.appendChild(selectList);
        td2.appendChild(td1);
        td3.appendChild(td2);
        td4.appendChild(td3);

        let that = this
        selectList.addEventListener('change', function(){
            that.onFoundryServiceSelection(opt);
        }, false);

        var option1 = document.createElement("option");
        option1.value = 'SELECT';
        option1.text = 'Select';
        selectList.appendChild(option1);

        var option2 = document.createElement("option");
        option2.value = 'YES';
        option2.text = 'Yes';
        selectList.appendChild(option2);

        return td4;
    }

    addTableTdWithCountSelect = (opt, limit) => {
        /*var td1 = document.createElement('td');
        td1.className = 'notopleftthin';
        td1.width = '50%';*/


        var td1 = document.createElement('div');
        td1.className = 'custom_select';
        //td1.width = '50%';

        var td2 = document.createElement('div');
        td2.className = 'form-group';
        //td1.width = '50%';

        var td3 = document.createElement('div');
        td3.className = 'inner_right';
        //td1.width = '50%';

        var td4 = document.createElement('div');
        td4.className = 'col-lg-8 col-md-8 col-sm-6';
        // td1.width = '50%';

        var selectList = document.createElement("select");
        selectList.id = "opt_select_"+ opt.foundryService["foundryServiceId"];
        //selectList.style="width: 75px;";
        td1.appendChild(selectList);
        td2.appendChild(td1);
        td3.appendChild(td2);
        td4.appendChild(td3);

        let that = this
        selectList.addEventListener('change', function(){
            that.onFoundryServiceSelection(opt);
        }, false);

        var option1 = document.createElement("option");
        option1.value = 'SELECT';
        option1.text = 'Select';
        selectList.appendChild(option1);

        for (var i = 1; i <= limit; i++) {
            var option2 = document.createElement("option");
            option2.value = i;
            option2.text = i;
            selectList.appendChild(option2);
        }
        return td4;
    }

    onFoundryServiceSelection = (service) => {
        console.log("service: "+JSON.stringify(service))
        if (service.foundryService["serviceName"] === "Do you want Short Loop Wafers?") {
            if (document.getElementById("opt_select_"+ service.foundryService["foundryServiceId"]).value === "YES") {
                console.log("INSIDE IF")
                console.log("parseInt(service[\"unitPrice\"])"+parseInt(service["unitPrice"]))
                document.getElementById("tr.soiSubstrate").style = "display: row-data;";
                document.getElementById("tr.soiSubstrate").style.height = "100%";
                document.getElementById("tr.soiSubstrate").style.paddingBottom = "1%";
                document.getElementById("tr.soiSubstrate").style.paddingTop = "1%";

                this.state.isShortLoopSelected = true;
                this.state.isShortLoopSelectedPrice = parseInt(service["unitPrice"]);
            } else {
                console.log("INSIDE ELSE")
                document.getElementById("tr.soiSubstrate").style = "display: none;";
                this.state.isShortLoopSelected = false;
                this.state.isShortLoopSelectedPrice = 0;
            }
        }
        this.calculateQuotation();
    }

    generateProcessOptionTableRows = (processName) => {
        this.removeElements('trProcessOption');
        var curProcessOptions = this.state.processOptions[processName];


        if (!!curProcessOptions) {
            var table = document.getElementById('techOptionsTbl');
            let that = this
            curProcessOptions
                .sort(function(op1,op2) { return op1.displayOrder - op2.displayOrder;})
                .forEach(function(opt) {
/*                    var tr = document.createElement('tr');
                    tr.className = 'trBg trProcessOption';*/

                    var tr = document.createElement('div');
                    tr.className = 'row new_pos trProcessOption';
                    tr.style.height = "100%";
                    tr.style.paddingBottom = "1%";
                    tr.style.paddingTop = "1%";

                    tr.appendChild(that.addTableTdWithTitle(opt));
                    tr.appendChild(that.addTableTdWithYesNoSelect(opt));
                    table.appendChild(tr);

                    if (opt.foundryService["serviceName"] === "Do you want a SOI Substrate on Short Loop Wafer?") {
                        console.log("Inside Do you want a SOI Substrate on Short Loop Wafer?")
                        tr.style.display="none";
                        tr.id = "tr.soiSubstrate";
                    } else if (opt.foundryService["serviceName"] === "Do you want Short Loop Wafers?") {
                        tr.id = "tr.shortLoop";
                    }
                });
        }
    }

    generateProcessAdderTableRows = (processName) => {
        this.removeElements('trProcessAdders');
        var curProcessOptions = this.state.processAdders[processName];
        if (!!curProcessOptions) {
            var table = document.getElementById('techAddersTbl');
            let that = this
            curProcessOptions
                .sort(function(op1,op2) { return op1.displayOrder - op2.displayOrder;})
                .forEach(function(opt) {

                    var tr = document.createElement('div');
                    tr.className = 'row new_pos trProcessAdders';
                    tr.style.height = "100%";
                    tr.style.paddingBottom = "1%";
                    tr.style.paddingTop = "1%";

                    tr.appendChild(that.addTableTdWithTitle(opt));

/*                    var tr = document.createElement('tr');
                    tr.className = 'trBg trProcessAdders';
                    tr.appendChild(that.addTableTdWithTitle(opt));*/
                    if (opt.foundryService["serviceName"] === 'Quantity of additional design uploads') {
                        let additionalDesignUploadLimit = 5
                        tr.appendChild(that.addTableTdWithCountSelect(opt, additionalDesignUploadLimit));
                    } else {
                        tr.appendChild(that.addTableTdWithYesNoSelect(opt));
                    }
                    table.appendChild(tr);
                });
        }
    }

    onTechnologyNodeSelection = (e) => {
        this.validateInputOnChange(e.target.name, e.target.value)
        if(e.target.value) {
            this.setState({
                [e.target.name]: e.target.value,
                mpwGfPricingId: this.state.technologyProcessPricing[e.target.value].mpwGfPricingId
            })

            console.log("e.target.value: " + e.target.value)
            console.log("this.state.technologyProcessPricing: " + JSON.stringify(this.state.technologyProcessPricing))
            this.state.selectedProcess = this.state.technologyProcessPricing[e.target.value];
            //document.getElementById("mosisPriceFactor").value = convertNumberToOnePointDecimal(selectedProcess.mosisMarkup);
            //document.getElementById("foundryMinArea").textContent=convertNumberToOnePointDecimal(selectedProcess.minArea);
            this.state.txtFoundryMinDesignArea = this.convertNumberToOnePointDecimal(this.state.selectedProcess["minimumAeraPerDesign"]);
            this.state.isShortLoopSelected = false;
            this.state.isShortLoopSelectedPrice = 0;
            this.generateProcessOptionTableRows(e.target.value);
            this.generateProcessAdderTableRows(e.target.value);
            this.calculateQuotation();
        }else{
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    HandleChange = (e) => {
        this.validateInputOnChange(e.target.name, e.target.value)

        if(e.target.name === "txtInputDieSize"){
            console.log('decimalcheck got called', e.target.value);
            //if(e.target.value.match(/^\d*\.?\d\d?$/g) || e.target.value == "") {
            //if(e.target.value.match(/^(?:\d{1,10}|(?!.{11})\d+\.\d+)$/g) || e.target.value == "") {
            if(e.target.value.match(/^(?:\d{1,6}|(?!.{7})\d+\.\d+)$/g) || e.target.value.match(/^(?:\d{1,6}|(?!.{7})\d+\.)$/g) || e.target.value == "") {
                console.log("inside IF======")
                this.setState({
                    [e.target.name]: e.target.value
                })
            }
        }else{
            this.setState({
                [e.target.name]: e.target.value
            })
        }

    }

    validateInputOnChange = (inputName, inputValue) => {
        console.log("inputName: "+inputName)
        if(inputName === "ddlTechNode"){
            let ddlTechNode = PrValidate.ForceSelect(inputValue, 'ddlTechNode', 'a technology node');
        }else if(inputName === "txtDesignNumber"){
            let txtDesignNumber = PrValidate.ForceEntry(inputValue, 'txtDesignNumber', "a design number");
        }else if(inputName === "txtInputDieSize"){
            let txtInputDieSize = PrValidate.ForceEntry(inputValue, 'txtInputDieSize', "a die size");
        }else if(inputName === "txtCustName"){
            let txtCustName = PrValidate.ForceEntry(inputValue, 'txtCustName', "a customer name");
        }
    }

    onAdditionalDieSizeSelection = (e) => {
        console.log("Inside onAdditionalDieSizeSelection: "+e.target.value)
        this.setState({
            [e.target.name]: e.target.value,
        })
        this.state.additionalDieQuantity = e.target.value
        this.calculateQuotation();
    }

    HandleSuccessGetInitDataResponse = (res) => {

        this.setState({
            fetchInitData: false,
            gfMpwQuoteData: res.data,
            quotationTncList: res.data["quotationTncList"],
            customerData: res.data["customer"],
            txtCustName: res.data.customer["customerName"],
            txtQuoteNumber: res.data["quoteNumber"]+"-"+0
        })

        console.log("quotationTncList: "+JSON.stringify(this.state.quotationTncList))
        console.log("quotationTncList length: "+this.state.quotationTncList.length)
        this.createTncMap()

        let that = this
        this.state.gfMpwQuoteData["gfMpwPricingList"].map(function(item) {
            console.log("technologyProcessObject: "+JSON.stringify(item.technologyProcessObject))
            that.state.technologyNodeList.push(item.technologyProcessObject)
        })
        console.log("this.state.technologyNodeList: "+JSON.stringify(this.state.technologyNodeList))
        this.state.technologyNodeList.sort(this.GetSortOrder("processName"));
        console.log("this.state.technologyNodeList Sorted: "+JSON.stringify(this.state.technologyNodeList))

        console.log("Init Data: "+JSON.stringify(this.state.gfMpwQuoteData))
        this.fetchTechnologyProcessPricingList()
        this.fetchProcessOptionsAndAdders()
    }

    createTncMap = () => {
        let that = this
        this.state.quotationTncList.map(function(item) {
            that.state.quotationTncListMap[item["displayOrder"]] = item
            let arrayPoints = item["tcDetails"].split("\r-\t")
            if(arrayPoints.length>1) {
                let liElement = ''
                liElement+=arrayPoints[0];
                for(let i=1; i<arrayPoints.length; i++){
                    liElement+= '<li>'+arrayPoints[i]+'</li>';
                }
                that.state.quotationTncListMap[item["displayOrder"]].tcDetails = liElement
            }else{
                let arrayPoints = item["tcDetails"].split("\t-\t")
                if(arrayPoints.length>1) {
                    let liElement = ''
                    liElement += arrayPoints[0];
                    for (let i = 1; i < arrayPoints.length; i++) {
                        liElement += '<li>' + arrayPoints[i] + '</li>';
                    }
                    that.state.quotationTncListMap[item["displayOrder"]].tcDetails = liElement
                }
            }
            console.log("displayOrder: "+JSON.stringify(that.state.quotationTncListMap[item["displayOrder"]]))
        })


        console.log("quotationTncList: "+JSON.stringify(this.state.quotationTncList))
        console.log("quotationTncList length: "+this.state.quotationTncList.length)
        console.log("quotationTncListMap: "+JSON.stringify(this.state.quotationTncListMap))
    }

    GetSortOrder = (prop) => {
        return function(a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        }
    }

    HandleSuccessSaveGfmpwQuoteData = (res) => {
        ToastsStore.success("You will receive the quote by email shortly.");
        this.setState({
            saveGfmpwQuoteData: false,
            redirectToMainPage: true
            //gfMpwQuoteData: res.data
        })

        setTimeout(function() { //Start the timer
            this.props.componentprops.history.push("/dashboard");
        }.bind(this), 2000)
    }

    fetchTechnologyProcessPricingList = () => {
        let that = this
        console.log("INSIDE fetchTechnologyProcessPricingList=============================")
        console.log("TechnologyProcessPricingList========="+JSON.stringify(this.state.gfMpwQuoteData["gfMpwPricingList"]))
        let count = 0

        that.state.technologyProcessPricing = {}
        this.state.gfMpwQuoteData["gfMpwPricingList"].map(function(item) {
            console.log("********************"+JSON.stringify(item))

            //console.log("========================="+that.state.foundryPriceList.technologyProcessObject["processName"])

                that.state.technologyProcessPricing[item.technologyProcessObject["processName"]] = item
        })
        this.setState({
            technologyProcessPricing: this.state.technologyProcessPricing
        })
        console.log("technologyProcessPricing: "+JSON.stringify(this.state.technologyProcessPricing))
        console.log("LENGTH: "+this.state.technologyProcessPricing.length)
    }

    fetchProcessOptionsAndAdders = () => {

        let that = this
        console.log("INSIDE fetchProcessOptionsAndAdders=============================")
        console.log("optionsAndAdders========="+JSON.stringify(this.state.gfMpwQuoteData["optionsAndAdders"]))
        let count = 0

        that.state.processOptions = {}
        that.state.processAdders = {}
        this.state.gfMpwQuoteData["optionsAndAdders"].map(function(item) {
            console.log("********************"+JSON.stringify(item))

            //console.log("========================="+that.state.foundryPriceList.technologyProcessObject["processName"])
            if (item.foundryService["serviceType"] === "Options") {
                console.log("INSIDE OPTIONS ====================")
                that.state.processOptions[item.technologyProcess["processName"]] = (that.state.processOptions[item.technologyProcess["processName"]] || []).concat(item);
            } else if (item.foundryService["serviceType"] === "Adders"){
                that.state.processAdders[item.technologyProcess["processName"]] = (that.state.processAdders[item.technologyProcess["processName"]] || []).concat(item);
            }
        })

        this.setState({
            processOptions: this.state.processOptions,
            processAdders: this.state.processAdders
        })
        console.log("processOptions: "+JSON.stringify(this.state.processOptions))
        console.log("processAdders: "+JSON.stringify(this.state.processAdders))
    }



    calculateQuotation = () => {
        console.log("this.state.selectedProcess: "+JSON.stringify(this.state.selectedProcess))
        if (Object.keys(this.state.selectedProcess).length !== 0) {

            this.state.mosisMarkUp = this.state.selectedProcess.technologyProcessObject["mosisMarkup"]
            console.log("Markup: " + this.state.mosisMarkUp)

            this.state.mosisMarkUp = isNaN(this.state.mosisMarkUp) || this.state.mosisMarkUp === "" ? 0 : parseFloat(this.state.mosisMarkUp) / 100;
            console.log("Markup: " + this.state.mosisMarkUp)

            let inputDieQuantity = this.state.txtInputDieSize;
            inputDieQuantity = isNaN(inputDieQuantity) || inputDieQuantity === "" ? 0.0 : parseFloat(inputDieQuantity);
            inputDieQuantity = inputDieQuantity < parseFloat(this.state.selectedProcess["minimumAeraPerDesign"]) ? parseFloat(this.state.selectedProcess["minimumAeraPerDesign"]) : inputDieQuantity;
            console.log("inputDieQuantity: " + inputDieQuantity)

            let reticleSpacePrice = this.state.selectedProcess["raticalSpacePrice"] * inputDieQuantity;
            reticleSpacePrice = reticleSpacePrice + (reticleSpacePrice * this.state.mosisMarkUp);
            console.log("reticleSpacePrice: " + reticleSpacePrice)

            //this.state.additionalDieQuantity = this.convertToRoundNumber(this.state.ddlAdditionalDieQty);
            console.log("this.state.additionalDieQuantity: "+this.state.additionalDieQuantity)
            let additionalDiePrice = this.state.selectedProcess["amountPer50Die"] * (Number(this.state.additionalDieQuantity) / 50);
            console.log("additionalDiePrice: " + additionalDiePrice)
            additionalDiePrice = additionalDiePrice + (additionalDiePrice * this.state.mosisMarkUp);
            console.log("additionalDiePrice: " + additionalDiePrice)

            let optionsPrice = 0;
            let curNodeOptions = this.state.processOptions[this.state.selectedProcess.technologyProcessObject["processName"]];
            console.log("curNodeOptions: " + JSON.stringify(curNodeOptions))

            let that = this;
            if (!!curNodeOptions) {

                curNodeOptions.forEach(function (po) {
                    if (document.getElementById('opt_select_' + po.foundryService["foundryServiceId"]).value === 'YES') {
                        if (po.foundryService["serviceName"] === "Do you want a SOI Substrate on Short Loop Wafer?") {
                            if (that.state.isShortLoopSelected) {
                                console.log("INSIDE IF")
                                console.log("po.unitPrice: "+po.unitPrice)
                                let price = parseInt(po.unitPrice, 10) + (parseInt(po.unitPrice, 10) * that.state.mosisMarkUp);
                                optionsPrice -= (that.state.isShortLoopSelectedPrice + (that.state.isShortLoopSelectedPrice * that.state.mosisMarkUp));
                                optionsPrice += price;
                            }
                        } else {
                            console.log("INSIDE ELSE")
                            console.log("po.unitPrice: "+po.unitPrice)
                            var price = parseInt(po.unitPrice, 10) + (parseInt(po.unitPrice, 10) * that.state.mosisMarkUp);
                            optionsPrice += price;
                        }
                    }
                });
            }

            console.log("optionsPrice: "+optionsPrice)

            let expediteFee = 0;
            let additionalUploadFee = 0;
            let expediteLotFee = 0;
            let itarServices = 0;

            let curNodeAdders = this.state.processAdders[this.state.selectedProcess.technologyProcessObject["processName"]];
            console.log("curNodeAdders: " + JSON.stringify(curNodeAdders))
            if (!!curNodeAdders) {
                curNodeAdders.forEach(function (po) {
                    var selectedValue = document.getElementById('opt_select_' + po.foundryService["foundryServiceId"]).value;
                    if (po.isPercent === 'Yes' && po.foundryService["serviceName"].trim() === "Expedite Tapeout Fee (Late design file submission)") {
                        if (selectedValue !== 'SELECT')
                            expediteFee = (reticleSpacePrice / 100) * po.unitPrice;
                    } else if (po.isPercent === 'No' && po.foundryService["serviceName"].trim() === 'Quantity of additional design uploads') {
                        if (selectedValue !== 'SELECT') {
                            additionalUploadFee = Number(selectedValue) * parseFloat(po.unitPrice);
                            additionalUploadFee = additionalUploadFee + (additionalUploadFee * that.state.mosisMarkUp);
                        }
                    } else if (po.foundryService["serviceName"].trim() === "Expedite Lot Fee") {
                        if (selectedValue !== 'SELECT') {
                            // if(Number(additionalDieQuantity) > 0) {
                            //     expediteLotFee = ((50 + Number(additionalDieQuantity)) / 50) * Number(po.unitPrice);
                            //     expediteLotFee = expediteLotFee + (expediteLotFee * mosisMarkUp);
                            // } else {
                            //     expediteLotFee = 0;
                            // }
                            expediteLotFee = ((50 + Number(that.state.additionalDieQuantity)) / 50) * Number(po.unitPrice);
                            expediteLotFee = expediteLotFee + (expediteLotFee * that.state.mosisMarkUp);
                        }
                    } else if (po.foundryService["serviceName"].trim() === "ITAR Services (per design)") {
                        if (selectedValue !== 'SELECT') {
                            itarServices = Number(po.unitPrice);
                            itarServices = itarServices + (itarServices * that.state.mosisMarkUp);
                        }
                    }
                });
            }

            var customElementPrice = 0;
            /*var customElTbl = document.getElementById('table.customElementTable');
            var dataRows = customElTbl.tBodies[0].rows;
            if (dataRows.length > 0) {
                for (var i = 0; i < dataRows.length; i++) {
                    var row = dataRows[i];
                    var p = this.convertCurrencyToNumber(row.cells[4].firstElementChild.value);
                    customElementPrice += p;
                }
            }*/


            var quotationTotalPrice = reticleSpacePrice + additionalDiePrice + optionsPrice + expediteFee +
                additionalUploadFee + customElementPrice + expediteLotFee + itarServices;

            console.log("optionsPrice: "+optionsPrice)
            //document.getElementById('gfQuotationRevision.gfMPWPricingId').value = this.state.selectedProcess["mpwGfPricingId"];
            this.state.txtFoundryMinDesignArea = this.state.selectedProcess["minimumAeraPerDesign"];
            this.state.txtReticleSpacePrice = this.convertToCurrencyNumber(reticleSpacePrice);
            //document.getElementById('gfQuotationRevision.reticleSpacePrice').value = this.convertToRoundNumber(reticleSpacePrice);

            this.state.txtAdditionalDie = this.convertToCurrencyNumber(additionalDiePrice);
            //document.getElementById('additionalDie').textContent = this.convertToCurrencyNumber(additionalDiePrice);
            //document.getElementById('gfQuotationRevision.additionalDieQuantity').value = this.state.additionalDieQuantity;
            //document.getElementById('gfQuotationRevision.additionalDiePrice').value = this.convertToRoundNumber(additionalDiePrice);

            console.log("this.convertToCurrencyNumber(optionsPrice): "+this.convertToCurrencyNumber(optionsPrice))
            this.state.txtOptions = this.convertToCurrencyNumber(optionsPrice);

            // document.getElementById('optionsPriceSummary').textContent = this.convertToCurrencyNumber(optionsPrice);
            // document.getElementById('gfQuotationRevision.optionsPrice').value = this.convertToRoundNumber(optionsPrice);

            this.state.txtExpediteFee = this.convertToCurrencyNumber(expediteFee);
            // document.getElementById('expediteFee').textContent = this.convertToCurrencyNumber(expediteFee);
            // document.getElementById('gfQuotationRevision.expediteFee').value = this.convertToRoundNumber(expediteFee);

            this.state.txtExpediteLotFee = this.convertToCurrencyNumber(expediteLotFee);
            // document.getElementById('expediteLotFee').textContent = this.convertToCurrencyNumber(expediteLotFee);
            // document.getElementById('gfQuotationRevision.expediteLotFee').value = this.convertToRoundNumber(expediteLotFee);

            this.state.txtItarServices = this.convertToCurrencyNumber(itarServices);
            // document.getElementById('itarServices').textContent = this.convertToCurrencyNumber(itarServices);
            // document.getElementById('gfQuotationRevision.itarServices').value = this.convertToRoundNumber(itarServices);

            this.state.txtAdditionalDesignUploadFees = this.convertToCurrencyNumber(additionalUploadFee);
            // document.getElementById('additionalDesignUploadAndDRCfees').textContent = this.convertToCurrencyNumber(additionalUploadFee);
            // document.getElementById('gfQuotationRevision.designUploadDrcFees').value = this.convertToRoundNumber(additionalUploadFee);


            //document.getElementById('customElementPrice').textContent = this.convertToCurrencyNumber(customElementPrice);
            //document.getElementById('gfQuotationRevision.customElementPrice').value = this.convertToRoundNumber(customElementPrice);

            this.state.txtTotalPrice = this.convertToCurrencyNumber(quotationTotalPrice);
            // document.getElementById('totalPrice').textContent = this.convertToCurrencyNumber(quotationTotalPrice);
            // document.getElementById('gfQuotationRevision.totalPrice').value = this.convertToRoundNumber(quotationTotalPrice);
            this.state.isCalulated = true;

            this.setState({
                txtFoundryMinDesignArea: this.state.selectedProcess["minimumAeraPerDesign"],
                txtReticleSpacePrice: '$'+this.convertToCurrencyNumber(reticleSpacePrice),
                txtAdditionalDie: '$'+this.convertToCurrencyNumber(additionalDiePrice),
                txtOptions: '$'+this.convertToCurrencyNumber(optionsPrice),
                txtExpediteFee: '$'+this.convertToCurrencyNumber(expediteFee),
                txtExpediteLotFee: '$'+this.convertToCurrencyNumber(expediteLotFee),
                txtItarServices: '$'+this.convertToCurrencyNumber(itarServices),
                txtAdditionalDesignUploadFees: '$'+this.convertToCurrencyNumber(additionalUploadFee),
                txtTotalPrice: '$'+this.convertToCurrencyNumber(quotationTotalPrice),
                isCalulated: true
            })

        }
        /*if (selectedProcess) {
            mosisMarkUp = document.getElementById('mosisPriceFactor').value;
            mosisMarkUp = isNaN(mosisMarkUp) || mosisMarkUp === "" ? 0 : parseFloat(mosisMarkUp) / 100;
            var inputDieQuantity = document.getElementsByName('gfQuotationRevision.inputDieSize')[0].value;
            inputDieQuantity = isNaN(inputDieQuantity) || inputDieQuantity === "" ? 0.0 : parseFloat(inputDieQuantity);
            inputDieQuantity = inputDieQuantity < parseFloat(selectedProcess.minArea) ? parseFloat(selectedProcess.minArea) : inputDieQuantity;

            var reticleSpacePrice = selectedProcess.reticleSpacePrice * inputDieQuantity;
            reticleSpacePrice = reticleSpacePrice + ( reticleSpacePrice * mosisMarkUp);

            additionalDieQuantity = convertToRoundNumber(document.getElementById('addDieSelector').value);
            var additionalDiePrice = selectedProcess.amountPer50Die * ( Number(additionalDieQuantity) / 50 );
            additionalDiePrice = additionalDiePrice + ( additionalDiePrice * mosisMarkUp );

            var optionsPrice = 0;
            var curNodeOptions = processOptions[selectedProcess.processName];
            if (!!curNodeOptions) {
                curNodeOptions.forEach(function(po) {
                    if (document.getElementById('opt_select_'+po.serviceId).value === 'YES') {
                        if (po.serviceName === "Do you want a SOI Substrate on Short Loop Wafer?") {
                            if (isShortLoopSelected) {
                                var price = parseInt(po.unitPrice, 10) + (parseInt(po.unitPrice, 10) * mosisMarkUp);
                                optionsPrice -= (isShortLoopSelectedPrice + (isShortLoopSelectedPrice * mosisMarkUp));
                                optionsPrice += price;
                            }
                        } else {
                            var price = parseInt(po.unitPrice, 10) + (parseInt(po.unitPrice, 10) * mosisMarkUp);
                            optionsPrice += price;
                        }
                    }
                });
            }

            var expediteFee = 0;
            var additionalUploadFee = 0;
            var expediteLotFee = 0;
            var itarServices = 0;
            var curNodeAdders = processAdders[selectedProcess.processName];
            if (!!curNodeAdders) {
                curNodeAdders.forEach(function(po) {
                    var selectedValue = document.getElementById('opt_select_'+po.serviceId).value;
                    if (po.isPercent === 'Yes' && po.serviceName.trim() === "Expedite Tapeout Fee (Late design file submission)") {
                        if (selectedValue !== 'SELECT')
                            expediteFee = (reticleSpacePrice / 100 ) * po.unitPrice;
                    } else if (po.isPercent === 'No' && po.serviceName.trim() === 'Quantity of additional design uploads') {
                        if (selectedValue !== 'SELECT') {
                            additionalUploadFee = Number(selectedValue) * parseFloat(po.unitPrice);
                            additionalUploadFee = additionalUploadFee + (additionalUploadFee * mosisMarkUp);
                        }
                    } else if (po.serviceName.trim() === "Expedite Lot Fee") {
                        if (selectedValue !== 'SELECT') {
                            // if(Number(additionalDieQuantity) > 0) {
                            //     expediteLotFee = ((50 + Number(additionalDieQuantity)) / 50) * Number(po.unitPrice);
                            //     expediteLotFee = expediteLotFee + (expediteLotFee * mosisMarkUp);
                            // } else {
                            //     expediteLotFee = 0;
                            // }
                            expediteLotFee = ((50 + Number(additionalDieQuantity)) / 50) * Number(po.unitPrice);
                            expediteLotFee = expediteLotFee + (expediteLotFee * mosisMarkUp);
                        }
                    } else if (po.serviceName.trim() === "ITAR Services (per design)") {
                        if (selectedValue !== 'SELECT') {
                            itarServices = Number(po.unitPrice);
                            itarServices = itarServices + (itarServices * mosisMarkUp);
                        }
                    }
                });
            }

            var customElementPrice = 0;
            var customElTbl = document.getElementById('table.customElementTable');
            var dataRows = customElTbl.tBodies[0].rows;
            if (dataRows.length > 0) {
                for (var i = 0; i < dataRows.length; i++) {
                    var row  = dataRows[i];
                    var p = convertCurrencyToNumber(row.cells[4].firstElementChild.value);
                    customElementPrice += p;
                }
            }

            var quotationTotalPrice = reticleSpacePrice + additionalDiePrice + optionsPrice + expediteFee +
                additionalUploadFee + customElementPrice + expediteLotFee + itarServices;

            document.getElementById('gfQuotationRevision.gfMPWPricingId').value = selectedProcess.mpwGfPricingId;
            document.getElementById('gfQuotationRevision.foundryMinimumArea').value = selectedProcess.minArea;
            document.getElementById('reticleSpaceAndBaseDie').textContent = convertToCurrencyNumber(reticleSpacePrice);
            document.getElementById('gfQuotationRevision.reticleSpacePrice').value = convertToRoundNumber(reticleSpacePrice);
            document.getElementById('additionalDie').textContent = convertToCurrencyNumber(additionalDiePrice);
            document.getElementById('gfQuotationRevision.additionalDieQuantity').value = additionalDieQuantity;
            document.getElementById('gfQuotationRevision.additionalDiePrice').value = convertToRoundNumber(additionalDiePrice);
            document.getElementById('optionsPriceSummary').textContent = convertToCurrencyNumber(optionsPrice);
            document.getElementById('gfQuotationRevision.optionsPrice').value = convertToRoundNumber(optionsPrice);
            document.getElementById('expediteFee').textContent = convertToCurrencyNumber(expediteFee);
            document.getElementById('gfQuotationRevision.expediteFee').value = convertToRoundNumber(expediteFee);
            document.getElementById('expediteLotFee').textContent = convertToCurrencyNumber(expediteLotFee);
            document.getElementById('gfQuotationRevision.expediteLotFee').value = convertToRoundNumber(expediteLotFee);
            document.getElementById('itarServices').textContent = convertToCurrencyNumber(itarServices);
            document.getElementById('gfQuotationRevision.itarServices').value = convertToRoundNumber(itarServices);
            document.getElementById('additionalDesignUploadAndDRCfees').textContent = convertToCurrencyNumber(additionalUploadFee);
            document.getElementById('gfQuotationRevision.designUploadDrcFees').value = convertToRoundNumber(additionalUploadFee);
            document.getElementById('customElementPrice').textContent = convertToCurrencyNumber(customElementPrice);
            document.getElementById('gfQuotationRevision.customElementPrice').value = convertToRoundNumber(customElementPrice);
            document.getElementById('totalPrice').textContent = convertToCurrencyNumber(quotationTotalPrice);
            document.getElementById('gfQuotationRevision.totalPrice').value = convertToRoundNumber(quotationTotalPrice);
            isCalulated = true;
        }*/
    }

    HandleSuccessGetCustomer = (res) => {
        this.setState({
            customerData: res.data,
            txtCustName: res.data.customerName
        })
        console.log("customerData: "+JSON.stringify(res.data.customerName))

    }

    createServiceObject = () =>{

        console.log("INSIDE createServiceObject")

        var curProcessOptions = this.state.processOptions[this.state.ddlTechNode];
        console.log("curProcessOptions: "+JSON.stringify(curProcessOptions))
        var optionsArr = document.getElementsByClassName("trProcessOption");
        //console.log(optionsArr)
        //console.log(optionsArr.length)
        let that = this
        if (curProcessOptions != null) {
            curProcessOptions.forEach(function(opt){
                let selectElement = document.getElementById("opt_select_"+opt.foundryServiceId)
                console.log("selectElement: "+JSON.stringify(selectElement))
                var selectedVal = selectElement.value;
                console.log("selectedVal: "+selectedVal)
                var serviceName = opt.foundryService["serviceName"];
                console.log("serviceName: "+serviceName)

                if (selectedVal && selectedVal.length > 0) {
                    console.log("serviceName --> " + serviceName);

                    let serviceObject = {
                        foundryServiceId:opt.foundryService["foundryServiceId"],
                        serviceValue:selectedVal
                    }
                    that.state.mpwQuotationServiceList.push(serviceObject)
                }
            })
        }

        console.log("this.state.mpwQuotationServiceList: "+JSON.stringify(this.state.mpwQuotationServiceList))

        var curProcessAdders = this.state.processAdders[this.state.ddlTechNode];
        console.log("curProcessAdders: "+JSON.stringify(curProcessAdders))
        var optionsArr = document.getElementsByClassName("trProcessAdders");
        console.log(optionsArr)
        console.log(optionsArr.length)
        if (curProcessAdders != null) {
            curProcessAdders.forEach(function(opt){
                let selectElement = document.getElementById("opt_select_"+opt.foundryServiceId)
                console.log("selectElement: "+JSON.stringify(selectElement))
                var selectedVal = selectElement.value;
                console.log("selectedVal: "+selectedVal)
                var serviceName = opt.foundryService["serviceName"];
                console.log("serviceName: "+serviceName)

                if (selectedVal && selectedVal.length > 0) {
                    console.log("serviceName --> " + serviceName);

                    let serviceObject = {
                        foundryServiceId:opt.foundryService["foundryServiceId"],
                        serviceValue:selectedVal
                    }
                    that.state.mpwQuotationServiceList.push(serviceObject)
                }
            })
        }

        console.log("this.state.mpwQuotationServiceList: "+JSON.stringify(this.state.mpwQuotationServiceList));

        this.state.mpwQuotationServiceListFiltered = []
        this.state.mpwQuotationServiceList.map(function(item, index) {
            if(item.serviceValue != "SELECT"){
                that.state.mpwQuotationServiceListFiltered.push(item);
            }
        });

        console.log("this.state.mpwQuotationServiceListFiltered: "+JSON.stringify(this.state.mpwQuotationServiceListFiltered));

    }

    _isNumber(input) {
        return !(typeof input == 'undefined' || isNaN(input));
    }

    highlightText = (e) =>{
        $('#'+e.target.name).select();
    }

    onChangeInputDieSize = (e) => {



        let val = "";
        if(e.target.value.length > 0 && this._isNumber(e.target.value)){
            val = e.target.value;
            val = this.roundOff(val, 1);
        }

        e.target.value = val;
        console.log("Inside onChangeInputDieSize: "+e.target.value)
        this.setState({
            [e.target.name]: e.target.value,
        })
        //this.state.txtInputDieSize = e.target.value
        console.log("this.state.selectedProcess: "+JSON.stringify(this.state.selectedProcess))
        if(Object.keys(this.state.selectedProcess).length > 0){
            this.calculateQuotation();
        }

    }

    roundOff(num, toDecimals) {
        if (!this._isNumber(num))
            throw num;

        return parseFloat(String(num)).toFixed(this.normalizeNumber(toDecimals));
    }

    normalizeNumber(num) {
        return this._isNumber(num) ? Number(num) : 0;
    }

    onSubmit = (e, saveType) => {
        console.log("Inside On Submit")

        console.log("that.state.gfMpwQuoteData[\"foundryServicePricingId\"]: "+this.state.gfMpwQuoteData["foundryServicePricingId"])
        let ddlTechNode = PrValidate.ForceSelect(this.state.ddlTechNode, 'ddlTechNode', 'a technology node');
        let txtDesignNumber = PrValidate.ForceEntry(this.state.txtDesignNumber, 'txtDesignNumber', "a design number");
        let txtInputDieSize = PrValidate.ForceEntry(this.state.txtInputDieSize, 'txtInputDieSize', "a die size");
        if(txtInputDieSize === true){
            txtInputDieSize = this.state.txtInputDieSize == 0? PrValidate.ForceEntry('', 'txtInputDieSize', "a die size greater than 0")
                :PrValidate.ForceEntry(this.state.txtInputDieSize, 'txtInputDieSize', "a die size");
        }

        console.log("txtInputDieSize: "+txtInputDieSize)
        let txtCustName = PrValidate.ForceEntry(this.state.txtCustName, 'txtCustName', "a customer name");

        let err_arr = [txtCustName, txtDesignNumber, ddlTechNode, txtInputDieSize];
        let itemToScroll = ["txtCustName", "txtDesignNumber", "ddlTechNode", "txtInputDieSize"];
        let chk_false = 0;

        let that = this;

        let breakCondition = false;

        console.log("that.state.mpwQuotationServiceList inside save: "+JSON.stringify(this.state.mpwQuotationServiceList))

        console.log("that.state.mpwQuotationServiceListFiltered: "+JSON.stringify(that.state.mpwQuotationServiceListFiltered))
        that.createServiceObject()

        err_arr.forEach(function (element, index) {
            if (!element && !breakCondition){
                chk_false++;
                console.log("itemToScrollIndex: " + itemToScroll[index])
                $('html, body').animate({
                    scrollTop: $("#" + itemToScroll[index]).offset().top - 100
                }, 1000);
                breakCondition = true
            }


            if ((err_arr.length - 1) === index) {
                if (chk_false === 0) {



                    let gfmpwSavedQuoteData = {
                        createdBy: null,
                        createdDate: null,
                        customerId: null,
                        modifiedBy: null,
                        modifiedDate: null,
                        quotationNumber: that.state.gfMpwQuoteData["quoteNumber"],
                        quotationType: null,
                        customer: null,
                        quotationTypeObject: null,
                        gfmpwQuotationRevisions: [
                            {
                                additionalDiePrice: parseFloat(that.state.txtAdditionalDie.replace(/,/g, '').replace(/\$/g, '')),
                                additionalDieQuantity: parseFloat(that.state.additionalDieQuantity.replace(/,/g, '').replace(/\$/g, '')),
                                createdBy: null,
                                createdDate: null,
                                customElementPrice: null,
                                designNumber: that.state.txtDesignNumber,
                                designUploadDrcFees: parseFloat(that.state.txtAdditionalDesignUploadFees.replace(/,/g, '').replace(/\$/g, '')),
                                emailAddress: that.state.txtEmail,
                                expediteFee: parseFloat(that.state.txtExpediteFee.replace(/,/g, '').replace(/\$/g, '')),
                                expediteLotFee: parseFloat(that.state.txtExpediteLotFee.replace(/,/g, '').replace(/\$/g, '')),
                                itarServices: parseFloat(that.state.txtItarServices.replace(/,/g, '').replace(/\$/g, '')),
                                emailSentTime: null,
                                foundryMinimumArea: that.state.selectedProcess["minimumAeraPerDesign"],
                                inputDieSize: that.state.txtInputDieSize,
                                modifiedBy: null,
                                modifiedDate: null,
                                mosisMarkup: (that.state.mosisMarkUp)*100,
                                gfMPWPricingId: that.state.mpwGfPricingId,//that.state.gfMpwQuoteData["foundryServicePricingId"],
                                optionsPrice: parseFloat(that.state.txtOptions.replace(/,/g, '').replace(/\$/g, '')),
                                quotationDate: null,
                                reticleSpacePrice: parseFloat(that.state.txtReticleSpacePrice.replace(/,/g, '').replace(/\$/g, '')),
                                revisionNumber: 0,
                                status: null,
                                technologyNode: that.state.selectedProcess.technologyProcessObject["processName"],
                                totalPrice: parseFloat(that.state.txtTotalPrice.replace(/,/g, '').replace(/\$/g, '')),
                                quotationId: 126,
                                gfMPWPrice: null,
                                gfmpwQuotationRevItems: null,
                                gfmpwQuotationServices: that.state.mpwQuotationServiceListFiltered,
                                customerId: 0,
                                quotationType: "",
                                customerName: "",
                                expired: false,
                                customerAccountNumber: 0,
                                emailSentTimeParsed: ""

                            }
                        ]
                    }


                    console.log("mpwQuotationServiceList: "+JSON.stringify(that.state.mpwQuotationServiceList))
                    //gfmpwSavedQuoteData["gfmpwQuotationServices"] = that.state.mpwQuotationServiceList

                    if(saveType == "saveEmail"){
                        console.log("INSIDE IF")
                        gfmpwSavedQuoteData["sendEmail"] = true
                    }
                    console.log("gfmpwSavedQuoteData: "+JSON.stringify(gfmpwSavedQuoteData))

                    that.setState({
                        saveGfmpwQuoteData: true,
                        gfmpwQuoteDataSubmitObject: gfmpwSavedQuoteData
                    })

                    console.log("gfmpwSavedQuoteData: "+JSON.stringify(gfmpwSavedQuoteData))
                }
            }
        });



    }

    render() {
        return (
            <div>
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_CENTER} />
                {/*{this.state.redirectToMainPage ?
                    <DashboardCtrl {...this.props} state={this.state}/>:''
                }*/}
                {this.state.redirectToMainPage == false?
                    <QuoteMPWComponent {...this.props} state={this.state}
                                       HandleChange={this.HandleChange} onTechnologyNodeSelection={this.onTechnologyNodeSelection}
                                       onAdditionalDieSizeSelection={this.onAdditionalDieSizeSelection} onChangeInputDieSize={this.onChangeInputDieSize}
                                       onSubmit={this.onSubmit} highlightText={this.highlightText}/>:''
                }
                {this.state.fetchInitData === true ? getInitData(this.props.PrStore, this.HandleSuccessGetInitDataResponse) : ''}
                {this.state.saveGfmpwQuoteData === true ? submitGfmpwQuoteData(this.props.PrStore, this.state.gfmpwQuoteDataSubmitObject, this.HandleSuccessSaveGfmpwQuoteData) : ''}
                {/*{this.state.custData === true ? getCustomerData(this.props.PrStore, this.HandleSuccessGetCustomer, 254) : ''}*/}
            </div>


        )
    }
}

export const QuoteMPWCtrl = PrConnect(quoteMPWCtrl, '');