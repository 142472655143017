import React, { Component } from 'react';
// import { prstore } from '../../../library/stores/pr-store';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import { PrConnect } from '../../../library/pr-miniredux';
import DesignStatusComponent from './DesignStatus';
import { GetDocumentCategoryListByCustomerId, GetAllDocumentListDocumentIdOrNodetId, GetAllDocumentCategoryList} from '../../../services/downloadcenter';
import $ from "jquery";
import closeImg from "../../../assets/images/close.svg";
import {TableDesignStatusCtrl} from "./TableDesign";
import { GetAllActiveByDesignNumber} from '../../../services/statsService';
import { GetCodesByCodeId } from '../../../services/codeService'

export class DesignStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            apiUrl: props.PrStore.GetData('api').baseurl,
            customersList: [],
            selected_customer_id: 0,
            fetchDocumentCategory: false,
            documentCategory: [],
            selectedDocument: {},
            fetchDocumentList: false,
            documentLists: [],
            sorting: { column: '', order: 'ASC' },
            fetchCustomerDocumentList:false,
            fieldValues:[],
            milestoneList:{},
            showModal: false,
            designNumberStatusList: {},
            productionIdInfoList: {},
            productionIdInfoMilestoneNames: [{milestoneName:"Work Order Release Date"},{milestoneName:"Tapeout Date"},{milestoneName:"Ship to MOSIS Date"}],
            optionClicked: '',
            fetchCodeByCodeId: false,
            codeObject:{},
            codeStatus:'',
            runId:'',
            productionIdItem: {}
        }
    }

    componentDidMount() {


        this.state.fetchCustomerDocumentList = true
        this.setState({
            customersList: [
                { id: 61, name: 'Phoenix RF, LLC' },
                { id: 201, name: 'Rain Neuromorphics' },
                { id: 220, name: 'AAYUNA, Inc.' },
                { id: 234, name: 'Institute of Microelectronics, A*STAR Research Entities' },
                { id: 246, name: 'Softbit Inc' },
                { id: 256, name: "Spark Photonics Design, Inc." },
                { id: 265, name: "Akronic - Akron Integrated Circuits P.C." },
                { id: 294, name: "Raytheon Company" },
                { id: 414, name: "Rohde & Schwarz GmbH & Co KG" },
                { id: 438, name: "Boise State University" }
            ]
        });

        //Foundry Run ID Assigned
        this.state.documentLists.push({"fieldLabel": "Design Number (Active)"})
        this.state.documentLists.push({"fieldLabel": "Foundry"})
        this.state.documentLists.push({"fieldLabel": "Technology Node"})
        this.state.documentLists.push({"fieldLabel": "Purchase Order Number Received"})
        this.state.documentLists.push({"fieldLabel": "Export Certification Date Completed"})
        this.state.documentLists.push({"fieldLabel": "Production ID Assigned"})
        /*this.state.documentLists.push({"fieldLabel": "Foundry Run ID Assigned"})*/

        this.state.fieldValues.push([{"Id": "1"},{"designNumber": "200001"},{"foundry": "Intel Custom Foundry"},{"technologyNode": "22FFL"},{"PONumber": "PO Number"},{"ECDate": "05/14/2020"},{"pid": "V71T"},{"foundryRunId": "V71T"}])
        this.state.fieldValues.push([{"Id": "2"},{"designNumber": "200002"},{"foundry": "Global Foundries"},{"technologyNode": "110"},{"PONumber": "PO Number"},{"ECDate": "05/14/2020"},{"pid": "V78M"},{"foundryRunId": "V71T"}])
        this.state.fieldValues.push([{"Id": "3"},{"designNumber": "200003"},{"foundry": "ON Semiconductor"},{"technologyNode": "C5"},{"PONumber": "PO Number"},{"ECDate": "05/14/2020"},{"pid": "V81M"},{"foundryRunId": "V71T"}])
        this.state.fieldValues.push([{"Id": "4"},{"designNumber": "200004"},{"foundry": "TSMC"},{"technologyNode": "12LPP"},{"PONumber": "PO Number"},{"ECDate": "05/14/2020"},{"pid": "V91A"},{"foundryRunId": "V71T"}])
        this.state.fieldValues.push([{"Id": "5"},{"designNumber": "200005"},{"foundry": "Global Foundries"},{"technologyNode": "130nm"},{"PONumber": "PO Number"},{"ECDate": "05/14/2020"},{"pid": "V5BV"},{"foundryRunId": "V71T"}])
        this.state.fieldValues.push([{"Id": "6"},{"designNumber": "200006"},{"foundry": "TSMC"},{"technologyNode": "C5"},{"PONumber": "PO Number"},{"ECDate": "05/14/2020"},{"pid": "96C"},{"foundryRunId": "V71T"}])

        /*Purchase Order # Received

        Export Certification Date Completed

        Production ID Assigned

        Foundry Run ID Assigned*/

        this.setState({
            documentLists: this.state.documentLists,
            fieldValues: this.state.fieldValues,
            milestoneList: this.state.milestoneList
        })

        console.log("this.state.documentLists"+JSON.stringify(this.state.documentLists))

    }

    sortDesignMilestones = (designMilestonesList) => {
        let sortedDesignMilestone = designMilestonesList.sort((a, b) => (a.milestone.milestoneSerialNumber > b.milestone.milestoneSerialNumber) ? 1 : ((b.milestone.milestoneSerialNumber > a.milestone.milestoneSerialNumber) ? -1 : 0))
        return sortedDesignMilestone;
    }


    CallOnMouseHover = (designMilestones, productionIdItem, optionClicked) => {
        if(productionIdItem.productionIdStatusObject != null){
            this.state.codeStatus = productionIdItem.productionIdStatusObject["codeValue"]
            this.setState({
                designMilestones: this.sortDesignMilestones(designMilestones),
                productionIdItem: productionIdItem,
                optionClicked: optionClicked,
                codeStatus:productionIdItem.productionIdStatusObject["codeValue"],
                runId:productionIdItem.foundryFabSchedule?productionIdItem.foundryFabSchedule["runId"]:''
            })
        }else{
            this.setState({
                designMilestones: this.sortDesignMilestones(designMilestones),
                optionClicked: optionClicked
            })
        }

        this.state.showModal = true
        console.log("Inside Call On Mouse Hover: "+designMilestones)
        this.state.milestoneList = [
            {
                "milestoneId": 1,
                "milestoneName": "Work Order Release Date",
                "mosisDate": "2020-07-27T07:00:00.000+0000",
                "foundryPlan": '',
                "actualDate": "2020-07-27T07:00:00.000+0000"
            },
            {
                "milestoneId": 12,
                "milestoneName": "Tapeout Date",
                "mosisDate": "2020-07-31T07:00:00.000+0000",
                "foundryPlan": '',
                "actualDate": "2020-07-27T07:00:00.000+0000"
            },
            {
                "milestoneId": 11,
                "milestoneName": "Ship to MOSIS Date",
                "mosisDate": "2020-08-17T07:00:00.000+0000",
                "foundryPlan": '',
                "actualDate": "2020-07-27T07:00:00.000+0000"
            }
            /*{
                "milestoneId": 10,
                "milestoneName": "Export Control Form approved",
                "foundryDate": "2020-08-24T07:00:00.000+0000",
                "foundryDays": -40
            }*/
            /*{
                "milestoneId": 9,
                "milestoneName": "Cancellation Deadline",
                "foundryDate": "2020-09-09T07:00:00.000+0000",
                "foundryDays": -28
            },
            {
                "milestoneId": 8,
                "milestoneName": "Final Clean Design submitted",
                "mosisDate": "2020-09-18T07:00:00.000+0000",
                "mosisDays": -21
            },
            {
                "milestoneId": 7,
                "milestoneName": "Last Day to submit Dry Run data (customer option)",
                "foundryDate": "2020-09-29T07:00:00.000+0000",
                "foundryDays": -14
            },
            {
                "milestoneId": 6,
                "milestoneName": "IP Declaration Form",
                "foundryDate": "2020-09-29T07:00:00.000+0000",
                "foundryDays": -14
            },
            {
                "milestoneId": 5,
                "milestoneName": "Work Order Released",
                "foundryDate": "2020-09-29T07:00:00.000+0000",
                "foundryDays": -14
            },
            {
                "milestoneId": 4,
                "milestoneName": "Final waivers closed",
                "foundryDate": "2020-09-29T07:00:00.000+0000",
                "foundryDays": -14
            },
            {
                "milestoneId": 3,
                "milestoneName": "Tapeout",
                "foundryDate": "2020-06-17T07:00:00.000+0000",
                "foundryDays": -14
            },
            {
                "milestoneId": 2,
                "milestoneName": "Product Shipped to MOSIS ",
                "foundryDate": "2020-10-19T07:00:00.000+0000"
            }*/
        ]

        this.setState({
            milestoneList: this.state.milestoneList
        })
        /*$('#' + id).css('display', 'block');*/
        /*if ((window.location.href).includes('dashboard')) {
            if (this.state.milestoneId !== id)
                this.setState({
                    //milestoneDate: date,
                    milestoneId: id,
                    fetchMilestone: true
                    //fabScheduleId: fabScheduleId
                })
            else
                $('#' + this.state.milestoneId + ' .inner_table').css('display', 'block');
        }*/
    }

    makeDynamicArray = (array) => {
        var result = [];
        if (array.length > 0) {
            for (let index = 0; index < array.length; index++) {
                const element = array[index].dmDocumentDetails;
                var result1 = [];
                if (element.length > 0) {
                    for (let index1 = 0; index1 < element.length; index1++) {
                        const element1 = element[index1];
                        result1.push({ fieldLabel: element1.dmDocTemplateDetail.fieldLabel, fieldValue: element1.fieldValue });
                    }
                }
                result.push({
                    documentId: array[index].documentId, data: result1.sort(function (a, b) {
                        var valueA, valueB;

                        valueA = a.fieldLabel; // Where 1 is your index, from your example
                        valueB = b.fieldLabel;
                        if (valueA < valueB) {
                            return -1;
                        }
                        else if (valueA > valueB) {
                            return 1;
                        } else {
                            return 0;
                        }
                    })
                });
            }
        }
        return result;
    }

    handleDocumentCategoryResponse = (res) => {
        if (res.data !== null && Array.isArray(res.data)) {
            this.setState({ documentCategory: res.data, fetchDocumentCategory: false });
        } else if (typeof res.data === 'object' && res.data !== null) {
            this.setState({ documentCategory: [res.data], fetchDocumentCategory: false });
        }
    }

    handleGetAllDocumentCategoryList = (res) => {
        console.log("res.data: "+JSON.stringify(res.data))
        if (res.data !== null && Array.isArray(res.data)) {
            this.setState({ documentCategory: res.data, fetchCustomerDocumentList: false });
        } else if (typeof res.data === 'object' && res.data !== null) {
            this.setState({ documentCategory: [res.data], fetchCustomerDocumentList: false });
        }
    }

    handleGetAllActiveByDesignNumber = (res) => {
        console.log("designNumberStatusList: "+JSON.stringify(this.state.designNumberStatusList))
        console.log("res.data: "+JSON.stringify(res.data))
        res.data.sort(function (a, b) {
            var valueA, valueB;

            valueA = a.designNumber!=""?a.designNumber:''; // Where 1 is your index, from your example
            valueB = b.designNumber!=""?b.designNumber:'';
            if (valueA < valueB) {
                return -1;
            }
            else if (valueA > valueB) {
                return 1;
            } else {
                return 0;
            }
        })
        this.setState({
            designNumberStatusList: res.data
        })
        console.log("handleGetAllActiveByDesignNumber: "+JSON.stringify(res.data))
        console.log("handleGetAllActiveByDesignNumber: "+JSON.stringify(this.state.designNumberStatusList))

        let that = this

    }

    handleDocumentResponse = (res) => {
        if (res.data !== null && Array.isArray(res.data)) {
            var dt = this.makeDynamicArray(res.data);
            this.setState({ documentLists: dt, fetchDocumentList: false });
        } else if (typeof res.data === 'object' && res.data !== null) {
            var dt = this.makeDynamicArray([res.data]);
            this.setState({ documentLists: dt, fetchDocumentList: false });
        } else {
            this.setState({ documentLists: [], fetchDocumentList: false });
        }


    }

    HandleChange = (e) => {
        if (e.target.name === "customer") {
            var id = e.target.value;
            this.setState({ documentCategory: [], selected_customer_id: 0, documentLists: [] });
            if (id) {
                this.setState({ selected_customer_id: id, fetchDocumentCategory: true });
            }
        } else if (e.target.getAttribute('data-item') === "document_category") {
            var row = e.target.closest(".row");
            row.querySelectorAll("select:not([name='" + e.target.name + "'])").forEach((sel) => {
                sel.value = "{}";
            });
            this.setState({ selectedDocument: {} });
            var data = JSON.parse(e.target.value);
            if (Object.keys(data).length > 0) {
                this.setState({ selectedDocument: data, fetchDocumentList: true });
            }
        }
    }

    handleSort = (event) => {
        // event.persist();
        const { sorting } = this.state;
        var column = event.target.getAttribute('column');
        console.log("column: "+column)

        if (sorting.column === column) {
            sorting.order = (sorting.order === 'asc') ? 'desc' : 'asc';
        } else {
            sorting.order = 'asc';
        }

        sorting.column = column;
        this.setState({ sorting: sorting });
        // this.state.table_data.sort(compareValues(sorting.column, sorting.order));
        this.state.designNumberStatusList.sort(function (index, order) {
            return function (a, b) {
                var valueA, valueB;

                if(column == 0){
                    valueA = a['designNumber']; // Where 1 is your index, from your example
                    valueB = b['designNumber'];
                }else if(column == 1){
                    valueA = a.technologyProcessObj? a.technologyProcessObj.foundryObject['codeValue']:''; // Where 1 is your index, from your example
                    valueB = b.technologyProcessObj? b.technologyProcessObj.foundryObject['codeValue']:'';
                }else if(column == 2){
                    valueA = a.technologyProcessObj? a.technologyProcessObj.technologyClassObject['codeValue']:''
                    valueB = b.technologyProcessObj? b.technologyProcessObj.technologyClassObject['codeValue']:''
                }else if(column == 3){
                    //item.poDesignNumberList[0] != null? item.poDesignNumberList[0].poRevision.purchaseOrder["purchaseOrderNumber"]
                    valueA = a.poDesignNumberList[0]? a.poDesignNumberList[0].poRevision.purchaseOrder["purchaseOrderNumber"]:'' // Where 1 is your index, from your example
                    valueB = b.poDesignNumberList[0]? b.poDesignNumberList[0].poRevision.purchaseOrder["purchaseOrderNumber"]:''
                }
                else if(column == 4){
                    //item.poDesignNumberList[0] != null? item.poDesignNumberList[0].poRevision.purchaseOrder["purchaseOrderNumber"]
                    valueA = a["completionDate"]? a["completionDate"]:'' // Where 1 is your index, from your example
                    valueB = b["completionDate"]? b["completionDate"]:''
                }
                else if(column == 5){
                    //item.poDesignNumberList[0] != null? item.poDesignNumberList[0].poRevision.purchaseOrder["purchaseOrderNumber"]
                    valueA = a.productionIdInfoList.length > 0? a.productionIdInfoList[0]["productionId"]:'' // Where 1 is your index, from your example
                    valueB = b.productionIdInfoList.length > 0? b.productionIdInfoList[0]["productionId"]:''
                }

                let comparison = 0;
                if (valueA < valueB) {
                    comparison = -1;
                }
                else if (valueA > valueB) {
                    comparison = 1;
                }
                return (
                    (order === 'desc') ? (comparison * -1) : comparison
                );
            };
        }(sorting.column, sorting.order));
    }

    ShowHideModal = () => {
        console.log("INSIDE ShowHideModal")
        this.setState({
            showModal: !this.state.showModal
        });
    }

    handleGetCodesByCodeId = (res) => {
        this.setState({
            fetchCodeByCodeId: false,
            codeObject: res.data
        })
        console.log("codeObject: "+JSON.stringify(this.state.codeObject))
    }

    render() {
        return (


            <div>

                {this.state.showModal ?
                    <div className="popupbody">
                        <div className="popupTop">
                            <div className="popup_close">
                                <button onClick={this.ShowHideModal} className="btn">
                                    <img src={closeImg} alt="logo" />
                                </button>
                            </div>
                            <div className='popup'>
                                <div className='popup_inner'>
                                    <TableDesignStatusCtrl onHandleChange={this.handleChange} foundryAndTechClass={this.state.foundryAndTechClass}
                                                           milestoneList={this.state.milestoneList} designMilestones={this.state.designMilestones}
                                                           productionIdInfoMilestoneNames={this.state.productionIdInfoMilestoneNames} optionClicked={this.state.optionClicked}
                                                           codeObject={this.state.codeObject} codeStatus={this.state.codeStatus} runId={this.state.runId} productionIdItem={this.state.productionIdItem}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''
                }
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_CENTER} />
                {this.state.fetchDocumentCategory === true ? GetDocumentCategoryListByCustomerId(this.props.PrStore, this.state.selected_customer_id, this.handleDocumentCategoryResponse) : ''}
                {this.state.fetchDocumentList === true ? GetAllDocumentListDocumentIdOrNodetId(this.props.PrStore, this.state.selected_customer_id, this.state.selectedDocument, this.handleDocumentResponse) : ''}
                {/*{this.state.fetchCustomerDocumentList === true ? GetAllDocumentCategoryList(this.props.PrStore, this.handleGetAllDocumentCategoryList) : ''}*/}
                {this.state.fetchCustomerDocumentList === true ? GetAllActiveByDesignNumber(this.props.PrStore, this.handleGetAllActiveByDesignNumber) : ''}
                {this.state.fetchCodeByCodeId === true ? GetCodesByCodeId(this.props.PrStore, this.state.codeStatusId, this.handleGetCodesByCodeId) : ''}

                <DesignStatusComponent {...this.props} state={this.state} handleChange={this.HandleChange} handleSort={this.handleSort} CallOnMouseHover={this.CallOnMouseHover} />
            </div>
        )
    }
}

export const DesignStatusCtrl = PrConnect(DesignStatus, '');