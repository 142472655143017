import React, { Component } from 'react';
// import { prstore } from '../../../library/stores/pr-store';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import { PrConnect } from '../../../library/pr-miniredux';
import ExportControlComponent from './ExportControl';
import ExportControlQuestionnareComponent from './ExportControlQuestionnare'
import $ from "jquery";
import { GetCustomerDesigns, GetAllQuestions, SaveQuestionAnswers, GetCorporateTypeList, GetUploadFileList} from '../../../services/exportControlNew'

import PrValidate from "../../../library/pr-controls/pr-validation-full";
import {PrLoader} from "../../../library/loader/pr-loader";

export class ExportControl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filesDone: false,
            fetchCustomerDesigns: true,
            customerDesigns: [],
            selectedDesignObj: {},
            questionsList: {},
            questionAnsersMap: {},
            fileTyepMap: {},
            questionAnswers: [],
            savedQuestionAnswersMap: {},
            fileObjMap: {},
            fileObjList: [],
            fileList: [],
            fileTypeList: [],

            ECQuestionnareFinalData: {},
            saveQuestionAnswers: false,
            fetchCorporateType: true,
            fetchUploadFileList: false,

            corporateTypelist: {},
            corporateTypelistMap: {},

            questionsTypesToShow: [],

            uploadedFileObj: {},
            apiUrl: props.PrStore.GetData('api').baseurl,

            shipRows: [],
            payingRows: [],
            endUserRows: [],
            designRows: [],

            shipRowsProdId: [],
            payingRowsProdId: [],
            endUserRowsProdId: [],
            designRowsProdId: [],


            redirectToExportControlQuestionnare: false,
            fetchAllQuestions: false,

            txtAuthName: '',
            txtAuthTitle: '',
            txtAuthEmail: '',
            txtAuthTelNumber: '',
            completeQuestions: '',

            allFieldsFilled: true,
            firstErrorReferences: [],
            sorting: { column: '', order: 'ASC' },

            saveButtonDisabled: false,


            shipToCorporationList: [],
            designerCorporationList: [],
            payingCorporationList: [],
            endUserList: [],

            shipToCorporationListProdId: [],
            designerCorporationListProdId: [],
            payingCorporationListProdId: [],
            endUserListProdId: [],

            addressList:{},
            mailingAddress:{},
            htmlTextArr: [],
            foundryList:{},
            qaIdList: {},

            deletedFileItemsDocumentNumbers: []

        }
    }

    componentDidMount() {



    }




    HandleChange = (e) => {


        this.setState({
            [e.target.name]: e.target.value
        })

        if(e.target.className.includes("reqValidation")){
            PrValidate.ForceEntry(e.target.value, e.target.id, 'Input')
        }

    }

    handleSort = (event) => {

    }


    handleGetCustomerDesignsResponse = (res) => {
    console.log("res.data designs: "+JSON.stringify(res.data))
        let data = this.sortCustomerDesignsList(res.data, "designNumber");
        //let data2 = this.sortCustomerDesignsList(data, "otherNumber");

        this.setState({
            customerDesigns: res.data,
            fetchCustomerDesigns: false
        })

        console.log("customerDesigns: "+JSON.stringify(this.state.customerDesigns));


    }


    handleSort = (event) => {
        // event.persist();
        const { sorting } = this.state;
        var column = event.target.getAttribute('column');
        console.log("column: "+column)

        if (sorting.column === column) {
            sorting.order = (sorting.order === 'asc') ? 'desc' : 'asc';
        } else {
            sorting.order = 'asc';
        }

        sorting.column = column;
        this.setState({ sorting: sorting });
        // this.state.table_data.sort(compareValues(sorting.column, sorting.order));
        this.state.customerDesigns.sort(function (index, order) {
            return function (a, b) {
                var valueA, valueB;

                if(column == 0){
                    valueA = a.deignNumberList.length>0?a.deignNumberList[0].designNumberInfo["designNumber"]:''; // Where 1 is your index, from your example
                    valueB = b.deignNumberList.length>0?b.deignNumberList[0].designNumberInfo["designNumber"]:'';
                }else if(column == 1){
                    valueA = a.productionIdList.length>0?a.productionIdList[0].productionIdInfo["productionId"]:''; // Where 1 is your index, from your example
                    valueB = b.productionIdList.length>0?b.productionIdList[0].productionIdInfo["productionId"]:'';
                }

                let comparison = 0;
                if (valueA < valueB) {
                    comparison = -1;
                }
                else if (valueA > valueB) {
                    comparison = 1;
                }
                return (
                    (order === 'desc') ? (comparison * -1) : comparison
                );
            };
        }(sorting.column, sorting.order));
    }



    sortCustomerDesignsList = (data, sortBy) => {
        /*data.sort(function (a, b) {
            var valueA, valueB;

            if(sortBy == "designNumber"){
                valueA = a.deignNumberList.length>0?a.deignNumberList[0].designNumberInfo["designNumber"]:''; // Where 1 is your index, from your example
                valueB = b.deignNumberList.length>0?b.deignNumberList[0].designNumberInfo["designNumber"]:'';
            }/!*else{
                valueA = a.otherNumberList.length>0?a.otherNumberList[0].designNumber:''; // Where 1 is your index, from your example
                valueB = b.otherNumberList.length>0?b.otherNumberList[0].designNumber:'';
            }*!/

            if(sortBy != "designNumber") {
                if (valueA < valueB) {
                    return -1;
                } else if (valueA > valueB) {
                    return 1;
                } else {
                    return 0;
                }
            }else{
                if (valueA > valueB) {
                    return -1;
                } else if (valueA < valueB) {
                    return 1;
                } else {
                    return 0;
                }
            }
        })*/

        var column = 0

        const { sorting } = this.state;
        if (sorting.column === column) {
            sorting.order = (sorting.order === 'asc') ? 'desc' : 'asc';
        } else {
            sorting.order = 'asc';
        }

        sorting.column = column;
        sorting.order = 'desc';
        this.setState({ sorting: sorting });

            let order = 'desc'
            data.sort(function (index, order) {
            return function (a, b) {
                var valueA, valueB;


                    valueA = a.deignNumberList.length>0?a.deignNumberList[0].designNumberInfo["designNumber"]:''; // Where 1 is your index, from your example
                    valueB = b.deignNumberList.length>0?b.deignNumberList[0].designNumberInfo["designNumber"]:'';


                let comparison = 0;
                if (valueA < valueB) {
                    comparison = -1;
                }
                else if (valueA > valueB) {
                    comparison = 1;
                }
                return (
                    (order === 'desc') ? (comparison * -1) : comparison
                );
            };
        }(sorting.column, sorting.order));




        return data
    }

    fillDesignNumbersInCorporates = () => {
        let that = this

        that.state.selectedDesignObj.designs.map(function (item, index) {
            let MEcDesignNew = {designNumberInfoId:that.state.selectedDesignObj.designs[index]["designNumberInfoId"]}
            let corporateTemplateShipping = {
                ecDesignFabId: "",
                corporateName: "",
                addressLine1: "",
                addressLine2: "",
                city: "",
                stateProvince: "",
                zipCode: "",
                country: "",
                corporateTypeId:that.state.corporateTypelistMap["Shipping Corporation"].codeId,
                ecDesignsNew: MEcDesignNew
            }

            let corporateTemplatePaying = {
                ecDesignFabId: "",
                corporateName: "",
                addressLine1: "",
                addressLine2: "",
                city: "",
                stateProvince: "",
                zipCode: "",
                country: "",
                corporateTypeId:that.state.corporateTypelistMap["Paying Corporation"].codeId,
                ecDesignsNew: MEcDesignNew
            }

            let corporateTemplateEndUser = {
                ecDesignFabId: "",
                corporateName: "",
                addressLine1: "",
                addressLine2: "",
                city: "",
                stateProvince: "",
                zipCode: "",
                country: "",
                corporateTypeId:that.state.corporateTypelistMap["End User Corporation"].codeId,
                ecDesignsNew: MEcDesignNew
            }

            let corporateTemplateDesign = {
                ecDesignFabId: "",
                corporateName: "",
                addressLine1: "",
                addressLine2: "",
                city: "",
                stateProvince: "",
                zipCode: "",
                country: "",
                corporateTypeId:that.state.corporateTypelistMap["Design Corporation"].codeId,
                ecDesignsNew: MEcDesignNew
            }



            if(item["designNumberInfoId"] != null){
                corporateTemplateShipping["ecDesignFabId"] = that.state.selectedDesignObj.designs[index]["ecDesignFabId"]
                //corporateTemplateShipping.ecDesignsNew["designNumberInfoId"] = that.state.selectedDesignObj.designs[index]["designNumberInfoId"]

                corporateTemplatePaying["ecDesignFabId"] = that.state.selectedDesignObj.designs[index]["ecDesignFabId"]
                //corporateTemplatePaying.ecDesignsNew["designNumberInfoId"] = that.state.selectedDesignObj.designs[index]["designNumberInfoId"]

                corporateTemplateEndUser["ecDesignFabId"] = that.state.selectedDesignObj.designs[index]["ecDesignFabId"]
                //corporateTemplateEndUser.ecDesignsNew["designNumberInfoId"] = that.state.selectedDesignObj.designs[index]["designNumberInfoId"]

                corporateTemplateDesign["ecDesignFabId"] = that.state.selectedDesignObj.designs[index]["ecDesignFabId"]
                //corporateTemplateDesign.ecDesignsNew["designNumberInfoId"] = that.state.selectedDesignObj.designs[index]["designNumberInfoId"]

                that.state.shipRows.push(corporateTemplateShipping);
                that.state.payingRows.push(corporateTemplatePaying);
                that.state.endUserRows.push(corporateTemplateEndUser);
                that.state.designRows.push(corporateTemplateDesign);
            }/*else{
                corporateTemplateShipping["ecDesignFabId"] = that.state.selectedDesignObj.designs[index]["ecDesignFabId"]
                corporateTemplatePaying["ecDesignFabId"] = that.state.selectedDesignObj.designs[index]["ecDesignFabId"]
                corporateTemplateEndUser["ecDesignFabId"] = that.state.selectedDesignObj.designs[index]["ecDesignFabId"]
                corporateTemplateDesign["ecDesignFabId"] = that.state.selectedDesignObj.designs[index]["ecDesignFabId"]
                that.state.shipRows.push(corporateTemplateShipping);
                that.state.payingRows.push(corporateTemplatePaying);
                that.state.endUserRows.push(corporateTemplateEndUser);
                that.state.designRows.push(corporateTemplateDesign);
            }*/

        })

        that.state.selectedDesignObj.productionIdList.map(function (item, index) {
            let ecProductionIdNew = {productionIdInfoId:that.state.selectedDesignObj.productionIdList[index]["productionIdInfoId"]}
            let corporateTemplateShippingProdId = {
                ecProductionId: "",
                corporateName: "",
                addressLine1: "",
                addressLine2: "",
                city: "",
                stateProvince: "",
                zipCode: "",
                country: "",
                corporateTypeId:that.state.corporateTypelistMap["Shipping Corporation"].codeId,
                ecProductionIdNew: ecProductionIdNew
            }

            let corporateTemplatePayingProdId = {
                ecProductionId: "",
                corporateName: "",
                addressLine1: "",
                addressLine2: "",
                city: "",
                stateProvince: "",
                zipCode: "",
                country: "",
                corporateTypeId:that.state.corporateTypelistMap["Paying Corporation"].codeId,
                ecProductionIdNew: ecProductionIdNew
            }

            let corporateTemplateEndUserProdId = {
                ecProductionId: "",
                corporateName: "",
                addressLine1: "",
                addressLine2: "",
                city: "",
                stateProvince: "",
                zipCode: "",
                country: "",
                corporateTypeId:that.state.corporateTypelistMap["End User Corporation"].codeId,
                ecProductionIdNew: ecProductionIdNew
            }

            let corporateTemplateDesignProdId = {
                ecProductionId: "",
                corporateName: "",
                addressLine1: "",
                addressLine2: "",
                city: "",
                stateProvince: "",
                zipCode: "",
                country: "",
                corporateTypeId:that.state.corporateTypelistMap["Design Corporation"].codeId,
                ecProductionIdNew: ecProductionIdNew
            }

            if(item["productionIdInfoId"] != null){
                corporateTemplateShippingProdId["ecProductionId"] = that.state.selectedDesignObj.productionIdList[index]["ecProductionId"]
                //corporateTemplateShippingProdId.ecProductionIdNew["productionIdInfoId"] = that.state.selectedDesignObj.productionIdList[index]["productionIdInfoId"]

                corporateTemplatePayingProdId["ecProductionId"] = that.state.selectedDesignObj.productionIdList[index]["ecProductionId"]
                //corporateTemplatePayingProdId.ecProductionIdNew["productionIdInfoId"] = that.state.selectedDesignObj.productionIdList[index]["productionIdInfoId"]

                corporateTemplateEndUserProdId["ecProductionId"] = that.state.selectedDesignObj.productionIdList[index]["ecProductionId"]
                //corporateTemplateEndUserProdId.ecProductionIdNew["productionIdInfoId"] = that.state.selectedDesignObj.productionIdList[index]["productionIdInfoId"]

                corporateTemplateDesignProdId["ecProductionId"] = that.state.selectedDesignObj.productionIdList[index]["ecProductionId"]
                //corporateTemplateDesignProdId.ecProductionIdNew["productionIdInfoId"] = that.state.selectedDesignObj.productionIdList[index]["productionIdInfoId"]

                that.state.shipRowsProdId.push(corporateTemplateShippingProdId);
                that.state.payingRowsProdId.push(corporateTemplatePayingProdId);
                that.state.endUserRowsProdId.push(corporateTemplateEndUserProdId);
                that.state.designRowsProdId.push(corporateTemplateDesignProdId);
            }
        })

        console.log("that.state.shipRows======: "+JSON.stringify(that.state.shipRows))
        if(that.state.questionsList.shipToCorporationList.length > 0){
            console.log("INSIDE shipToCorporationList")
            this.state.shipRows = this.mapAddressListValues(this.state.shipRows, that.state.questionsList.shipToCorporationList, "design")
        }
        if(that.state.questionsList.shipToCorporationProdIdList.length > 0){
            console.log("INSIDE shipToCorporationProdIdList")
            this.state.shipRowsProdId = this.mapAddressListValues(this.state.shipRowsProdId, that.state.questionsList.shipToCorporationProdIdList, "prodId")
        }

        if(that.state.questionsList.payingCorporationList.length > 0){
            console.log("INSIDE payingCorporationList")
            this.state.payingRows = this.mapAddressListValues(this.state.payingRows, that.state.questionsList.payingCorporationList, "design")
        }
        if(that.state.questionsList.payingCorporationProdIdList.length > 0){
            console.log("INSIDE payingCorporationProdIdList")
            this.state.payingRowsProdId = this.mapAddressListValues(this.state.payingRowsProdId, that.state.questionsList.payingCorporationProdIdList, "prodId")
        }

        if(that.state.questionsList.designerCorporationList.length > 0){
            console.log("INSIDE designerCorporationList")
            this.state.designRows = this.mapAddressListValues(this.state.designRows, that.state.questionsList.designerCorporationList, "design")
        }
        if(that.state.questionsList.designerCorporationProdIdList.length > 0){
            console.log("INSIDE designerCorporationList")
            this.state.designRowsProdId = this.mapAddressListValues(this.state.designRowsProdId, that.state.questionsList.designerCorporationProdIdList, "prodId")
        }


        if(that.state.questionsList.endUserList.length > 0){
            console.log("INSIDE endUserList")
            this.state.endUserRows = this.mapAddressListValues(this.state.endUserRows, that.state.questionsList.endUserList, "design")
        }
        if(that.state.questionsList.endUserProdIdList.length > 0){
            console.log("INSIDE endUserList")
            this.state.endUserRowsProdId = this.mapAddressListValues(this.state.endUserRowsProdId, that.state.questionsList.endUserProdIdList, "prodId")
        }

        console.log("this.state.shipRows: "+JSON.stringify(this.state.shipRows))
        console.log("this.state.payingRows: "+JSON.stringify(this.state.payingRows))
        console.log("this.state.designRows: "+JSON.stringify(this.state.designRows))
        console.log("this.state.endUserRows: "+JSON.stringify(this.state.endUserRows))

        console.log("this.state.shipRowsProdId: "+JSON.stringify(this.state.shipRowsProdId))
        console.log("this.state.payingRowsProdId: "+JSON.stringify(this.state.payingRowsProdId))
        console.log("this.state.designRowsProdId: "+JSON.stringify(this.state.designRowsProdId))
        console.log("this.state.endUserRowsProdId: "+JSON.stringify(this.state.endUserRowsProdId))

        //this.InsertCorporateList(this.state.questionsList);
    }

    mapAddressListValues = (addressRow, addressList, addressType) => {
        console.log("addressList: "+JSON.stringify(addressList))
        console.log("addressRow: "+JSON.stringify(addressRow))
        let count = 0
        if(addressType == "design"){
            addressList.map(function (item, index) {
                if(addressRow[count] != undefined){
                    if(item["ecDesignFabId"] != null) {
                        addressRow[count]["corporateId"] = item["corporateId"] != null ? item["corporateId"] : ''
                        addressRow[count]["ecDesignFabId"] = item["ecDesignFabId"] != null ? item["ecDesignFabId"] : ''
                        addressRow[count]["ecProductionId"] = item["ecProductionId"] != null ? item["ecProductionId"] : ''
                        addressRow[count]["corporateName"] = item["corporateName"]
                        addressRow[count]["addressLine1"] = item["addressLine1"]
                        addressRow[count]["addressLine2"] = item["addressLine2"]
                        addressRow[count]["city"] = item["city"]
                        addressRow[count]["stateProvince"] = item["stateProvince"]
                        addressRow[count]["zipCode"] = item["zipCode"]
                        addressRow[count]["country"] = item["country"]
                        addressRow[count]["corporateTypeId"] = item["corporateTypeId"]
                        count++;
                    }
                }

            })
        }else{
            addressList.map(function (item, index) {
                if(addressRow[count] != undefined){
                    if(item["ecDesignFabId"] == null){
                        addressRow[count]["corporateId"] = item["corporateId"] != null ? item["corporateId"] : ''
                        addressRow[count]["ecDesignFabId"] =  item["ecDesignFabId"]!=null?item["ecDesignFabId"]:''
                        addressRow[count]["ecProductionId"] =  item["ecProductionId"]!=null?item["ecProductionId"]:''
                        addressRow[count]["corporateName"] = item["corporateName"]
                        addressRow[count]["addressLine1"] = item["addressLine1"]
                        addressRow[count]["addressLine2"] = item["addressLine2"]
                        addressRow[count]["city"] = item["city"]
                        addressRow[count]["stateProvince"] = item["stateProvince"]
                        addressRow[count]["zipCode"] = item["zipCode"]
                        addressRow[count]["country"] = item["country"]
                        addressRow[count]["corporateTypeId"] = item["corporateTypeId"]
                        count++;
                    }
                }
            })
        }

        return addressRow
    }

    mapFoundryListWithId = (data) => {
        let that = this
        data["foundryList"].map(function (item) {
            that.state.foundryList[item["codeId"]] = item;
        })
    }

    handleGetAllQuestionsResponse = (res) => {
        console.log("res: "+JSON.stringify(res.data))

        let that = this
        if(res.data.questionAnswersList != undefined){
            res.data.questionAnswersList.map(function (item) {
                if(item.answerId != undefined){
                    that.state.savedQuestionAnswersMap[item.questionId] = item.answerId;
                }else{
                    that.state.savedQuestionAnswersMap[item.questionId] = item.answerValue;
                }
                let tempQuestionAnswers = that.createTempQuestionAnswer(item)
                that.state.questionAnswers.push(tempQuestionAnswers);
            })
        }

        console.log("this.state.questionAnswers: "+JSON.stringify(this.state.questionAnswers))


        let USElement = {}
        res.data["countryList"].forEach((element, index) => {
            if(element.codeName == "United States"){
                USElement = element
            }
        })
        res.data["countryList"].sort(function(x,y){ return x == USElement ? -1 : y == USElement ? 1 : 0; });
        this.mapFoundryListWithId(res.data);
        this.setState({
            questionsList: res.data,
            fetchAllQuestions: false,
            redirectToExportControlQuestionnare: true,
            fetchUploadFileList: true,

            txtAuthName: res.data.exportControlNew.authorizedBy,
            txtAuthTitle: res.data.exportControlNew.authorizedByTitle,
            txtAuthEmail: res.data.exportControlNew.authEmailAddress,
            txtAuthTelNumber: res.data.exportControlNew.authPhoneNumber,
            completeQuestions: res.data.exportControlNew.completeQuestions,
            addressList: res.data["customerAddressList"]
        })


        console.log("addressList: "+JSON.stringify(this.state.addressList))
        this.state.addressList.forEach((element, index) => {
            if(element.addressTypeObject["codeValue"] == "Mailing Address"){
                that.state.mailingAddress = element;
            }
        })

        this.fillDesignNumbersInCorporates();
        console.log("this.props.state.questionsList: "+JSON.stringify(this.state.questionsList));
        console.log("questionsList: "+JSON.stringify(this.state.questionsList["documentTreeExportControlChildrenList"]));
        console.log("this.props.state.selectedDesignObj: "+JSON.stringify(this.state.selectedDesignObj));

        this.state.questionsList["questions"].map(function (item) {
            console.log("item Child: "+item.childQuestions.length)
        })



        this.separateDesignCorporates();
        this.separateProdIdCorporates();

        console.log("savedQuestionAnswersMap: "+JSON.stringify(this.state.savedQuestionAnswersMap))
        this.GetQuestionTypeToShow(this.state.selectedDesignObj);
        console.log("this.state.questionsTypesToShow: "+JSON.stringify(this.state.questionsTypesToShow));

    }

    separateDesignCorporates = () => {

        let that = this
        console.log("this.state.questionsList[\"shipToCorporationList\"]: "+JSON.stringify(this.state.questionsList["shipToCorporationList"]))
        this.state.questionsList["shipToCorporationList"].map(function (item) {
            /*if (item.ecDesignsNew == undefined) {
                that.state.shipToCorporationListProdId.push(item)
            }else if(item.ecProductionIdNew == undefined){
                that.state.shipToCorporationList.push(item)
            } else {*/
            if(item.ecDesignsNew != undefined){
                if (item.ecDesignsNew["ecDesignFabId"] == 0 && item.ecProductionIdNew["ecProductionId"] == 0) {
                    //that.state.shipToCorporationListProdId.push(item)
                    that.state.shipToCorporationList.push(item)
                }else if(item.ecDesignsNew["ecDesignFabId"] > 0){
                    that.state.shipToCorporationList.push(item)
                }
            }
        })

        this.state.questionsList["designerCorporationList"].map(function (item) {

            /*if(item.ecDesignsNew == undefined){
                that.state.designerCorporationListProdId.push(item)
            }else if(item.ecProductionIdNew == undefined){
                that.state.designerCorporationList.push(item)
            }else{*/
            if(item.ecDesignsNew != undefined){
                if(item.ecDesignsNew["ecDesignFabId"]==0 && item.ecProductionIdNew["ecProductionId"] ==0){
                    //that.state.designerCorporationListProdId.push(item)
                    that.state.designerCorporationList.push(item)
                }else if(item.ecDesignsNew["ecDesignFabId"] > 0){
                    that.state.designerCorporationList.push(item)
                }
            }
        })

        this.state.questionsList["payingCorporationList"].map(function (item) {
            /*if (item.ecDesignsNew == undefined) {
                that.state.payingCorporationListProdId.push(item)
            }else if (item.ecProductionIdNew == undefined){
                that.state.payingCorporationList.push(item)
            }else{*/
            if(item.ecDesignsNew != undefined){
                if(item.ecDesignsNew["ecDesignFabId"]==0 && item.ecProductionIdNew["ecProductionId"] ==0){
                    //that.state.payingCorporationListProdId.push(item)
                    that.state.payingCorporationList.push(item)
                }else if(item.ecDesignsNew["ecDesignFabId"] > 0){
                    that.state.payingCorporationList.push(item)
                }
            }
        })

        this.state.questionsList["endUserList"].map(function (item) {

            /*if (item.ecDesignsNew == undefined) {
                that.state.endUserListProdId.push(item)
            }else if (item.ecProductionIdNew == undefined){
                that.state.endUserList.push(item)
            }else{*/
            if(item.ecDesignsNew != undefined){
                if(item.ecDesignsNew["ecDesignFabId"]==0 && item.ecProductionIdNew["ecProductionId"] ==0){
                    //that.state.endUserListProdId.push(item)
                    that.state.endUserList.push(item)
                }else if(item.ecDesignsNew["ecDesignFabId"] > 0){
                    that.state.endUserList.push(item)
                }
            }
        })

        let endUserListTemp = []
        let payingCorporationListTemp = []
        let designerCorporationListTemp = []
        let shipToCorporationListTemp = []

        console.log("that.state.endUserList sorting: "+JSON.stringify(that.state.endUserList))
        console.log("that.state.shipToCorporationListProdId sorting: "+JSON.stringify(that.state.shipToCorporationList))
        this.state.selectedDesignObj.designs.map(function (item) {
            that.state.endUserList.map(function (item2) {
                if(item2.ecDesignsNew.ecDesignFabId != 0){
                    if(item2.ecDesignFabId == item.ecDesignFabId){
                        endUserListTemp.push(item2);
                    }
                }else{
                    endUserListTemp = that.state.endUserList
                }

            })

            that.state.payingCorporationList.map(function (item2) {
                if(item2.ecDesignsNew.ecDesignFabId != 0){
                    if(item2.ecDesignFabId == item.ecDesignFabId){
                        payingCorporationListTemp.push(item2);
                    }
                }else{
                    payingCorporationListTemp = that.state.payingCorporationList
                }

            })

            that.state.designerCorporationList.map(function (item2) {
                if(item2.ecDesignsNew.ecDesignFabId != 0){
                    if(item2.ecDesignFabId == item.ecDesignFabId){
                        designerCorporationListTemp.push(item2);
                    }
                }else{
                    designerCorporationListTemp = that.state.designerCorporationList
                }
            })

            that.state.shipToCorporationList.map(function (item2) {
                if(item2.ecDesignsNew.ecDesignFabId != 0){
                    if(item2.ecDesignFabId == item.ecDesignFabId){
                        shipToCorporationListTemp.push(item2);
                    }
                }else{
                    shipToCorporationListTemp = that.state.shipToCorporationList
                }
            })

        })

        that.state.endUserList = endUserListTemp
        that.state.payingCorporationList = payingCorporationListTemp
        that.state.designerCorporationList = designerCorporationListTemp
        that.state.shipToCorporationList = shipToCorporationListTemp
    }


    separateProdIdCorporates = () => {

        let that = this
        console.log("this.state.questionsList[\"shipToCorporationProdIdList\"]: "+JSON.stringify(this.state.questionsList["shipToCorporationProdIdList"]))
        this.state.questionsList["shipToCorporationProdIdList"].map(function (item) {
            /*if (item.ecDesignsNew == undefined) {
                that.state.shipToCorporationListProdId.push(item)
            }else if(item.ecProductionIdNew == undefined){
                that.state.shipToCorporationList.push(item)
            } else {*/
            if(item.ecProductionIdNew!=undefined){
                if(item.ecProductionIdNew["ecProductionId"] > 0){
                    that.state.shipToCorporationListProdId.push(item)
                }
                else if (item.ecDesignsNew["ecDesignFabId"] == 0 && item.ecProductionIdNew["ecProductionId"] == 0) {
                    that.state.shipToCorporationListProdId.push(item)
                    //that.state.shipToCorporationList.push(item)
                }
            }
        })

        this.state.questionsList["designerCorporationProdIdList"].map(function (item) {

            /*if(item.ecDesignsNew == undefined){
                that.state.designerCorporationListProdId.push(item)
            }else if(item.ecProductionIdNew == undefined){
                that.state.designerCorporationList.push(item)
            }else{*/
            if(item.ecProductionIdNew!=undefined){
                if(item.ecProductionIdNew["ecProductionId"] > 0){
                    that.state.designerCorporationListProdId.push(item)
                }else if(item.ecDesignsNew["ecDesignFabId"]==0 && item.ecProductionIdNew["ecProductionId"] ==0){
                    that.state.designerCorporationListProdId.push(item)
                    //that.state.designerCorporationList.push(item)
                }
            }
        })

        this.state.questionsList["payingCorporationProdIdList"].map(function (item) {
            /*if (item.ecDesignsNew == undefined) {
                that.state.payingCorporationListProdId.push(item)
            }else if (item.ecProductionIdNew == undefined){
                that.state.payingCorporationList.push(item)
            }else{*/
            if(item.ecProductionIdNew!=undefined){
                if(item.ecProductionIdNew["ecProductionId"] > 0){
                    that.state.payingCorporationListProdId.push(item)
                }else if(item.ecDesignsNew["ecDesignFabId"]==0 && item.ecProductionIdNew["ecProductionId"] ==0){
                    that.state.payingCorporationListProdId.push(item)
                    //that.state.payingCorporationList.push(item)
                }
            }
        })

        this.state.questionsList["endUserProdIdList"].map(function (item) {

            /*if (item.ecDesignsNew == undefined) {
                that.state.endUserListProdId.push(item)
            }else if (item.ecProductionIdNew == undefined){
                that.state.endUserList.push(item)
            }else{*/
            if(item.ecProductionIdNew!=undefined){
                if(item.ecProductionIdNew["ecProductionId"] > 0){
                    that.state.endUserListProdId.push(item)
                }else if(item.ecDesignsNew["ecDesignFabId"]==0 && item.ecProductionIdNew["ecProductionId"] ==0){
                    that.state.endUserListProdId.push(item)
                    //that.state.endUserList.push(item)
                }
            }
        })

        let endUserListProdIdTemp = []
        let payingCorporationListProdIdTemp = []
        let designerCorporationListProdIdTemp = []
        let shipToCorporationListProdIdTemp = []

        console.log("that.state.endUserListProdId sorting: "+JSON.stringify(that.state.endUserListProdId))
        console.log("that.state.shipToCorporationListProdId sorting: "+JSON.stringify(that.state.shipToCorporationListProdId))
        this.state.selectedDesignObj.productionIdList.map(function (item) {
            that.state.endUserListProdId.map(function (item2) {
                if(item2.ecProductionIdNew.ecProductionId != 0){
                    if(item2.ecProductionId == item.ecProductionId){
                        endUserListProdIdTemp.push(item2);
                    }
                }else{
                    endUserListProdIdTemp = that.state.endUserListProdId
                }

            })

            that.state.payingCorporationListProdId.map(function (item2) {
                if(item2.ecProductionIdNew.ecProductionId != 0){
                    if(item2.ecProductionId == item.ecProductionId){
                        payingCorporationListProdIdTemp.push(item2);
                    }
                }else{
                    payingCorporationListProdIdTemp = that.state.payingCorporationListProdId

                }

            })

            that.state.designerCorporationListProdId.map(function (item2) {
                if(item2.ecProductionIdNew.ecProductionId != 0){
                    if(item2.ecProductionId == item.ecProductionId){
                        designerCorporationListProdIdTemp.push(item2);

                    }
                }else{
                    designerCorporationListProdIdTemp = that.state.designerCorporationListProdId

                }
            })

            that.state.shipToCorporationListProdId.map(function (item2) {
                if(item2.ecProductionIdNew.ecProductionId != 0){
                    if(item2.ecProductionId == item.ecProductionId){
                        shipToCorporationListProdIdTemp.push(item2);

                    }
                }else{
                    shipToCorporationListProdIdTemp = that.state.shipToCorporationListProdId

                }
            })

        })

        that.state.endUserListProdId = endUserListProdIdTemp
        that.state.payingCorporationListProdId = payingCorporationListProdIdTemp
        that.state.designerCorporationListProdId = designerCorporationListProdIdTemp
        that.state.shipToCorporationListProdId = shipToCorporationListProdIdTemp


    }

    HandleSuccessGetUploadFileListResponse = (res) => {
        this.setState({
            uploadedFileObj: res.data,
            fetchUploadFileList: false,

        })
        console.log("res HandleSuccessGetUploadFileListResponse: "+JSON.stringify(this.state.uploadedFileObj))
        this.fillQuestionAnswerObject()

    }


    fillQuestionAnswerObject = () => {
        console.log("FILEOBJ: "+JSON.stringify(this.state.uploadedFileObj));

        let that = this;
        this.state.uploadedFileObj.map(function (item) {
            console.log("Inside File Obj")
            console.log("item: "+JSON.stringify(item))
            var documentId = item.documentId
            var fileName = item.fileName
            var fileType = item.fileType
            var fileLink = item.fileLink
            console.log("fileName: "+fileName)
            console.log("fileType: "+fileType)
            console.log("fileLink: "+fileLink)
            //var fileLinkTag = "viewFile('<c:out value='fileLink'/>')"

            if(document.getElementById("question_"+fileType+"_fileName")!=null){
                document.getElementById("question_"+fileType+"_documentId").textContent = documentId;
                document.getElementById("question_"+fileType+"_fileName").textContent = fileName;
                document.getElementById("question_"+fileType+"_span").style.display = "block";
                document.getElementById("question_"+fileType+"_fileLink").onclick = function() {that.downloadFile(that.state.apiUrl + '/customerFileDownload/downloadFile/' + item["documentId"],
                    item["fileName"])};
                document.getElementById("question_"+fileType).style.display = "none";
            }
        });

        console.log("htmlTextArr: "+JSON.stringify(this.state.htmlTextArr));

/*        var questionAnswersList = [];
    <c:forEach items="${exportControlNewForm.exportControlNew.questionsAnswers}" var="questionAnswers" varStatus="loop">
    console.log(${questionAnswers.questionId})

    var questionId = "<c:out value='${questionAnswers.questionId}'/>"
    var answerId = "<c:out value='${questionAnswers.answerId}'/>"
    var answerValue = "<c:out value='${questionAnswers.answerValue}'/>"

    //questionAnswers = setQuestionAnswers("question_", questionId, answerId, answerValue);
    setQuestionAnswers("question_", questionId, answerId, answerValue);
    //generalQuestionAnswersList.push(questionAnswers)
    </c:forEach>*/
        //console.log("generalQuestionAnswersList: "+JSON.stringify(generalQuestionAnswersList))


        this.setState({
            filesDone: true
        })

    }

    /*viewFile = (fileLink) => {
        console.log("fileLink: "+fileLink);
    }*/

    viewFile = (filePath) => {
        var options = 'toolbar=no,location=no,status=no,scrollbars=no,menubar=no,resizable=yes,width=1000,height=600';
        var url = "<%= request.getContextPath() %>/generateDocument?filePath="+filePath;
        window.open(url, '_blank', options);
    }

    downloadFile = (url, fileName) => {
        console.log("downloadFile: "+url)
        console.log("fileName: "+fileName)
        let anchor = document.createElement("a");
        document.body.appendChild(anchor);
        let file = url

        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + JSON.parse(localStorage.getItem('okta-token-storage')).accessToken.accessToken);

        fetch(file, { headers })
            .then(response => response.blob())
            .then(blobby => {
                let objectUrl = window.URL.createObjectURL(blobby);

                anchor.href = objectUrl;
                anchor.download = fileName;
                anchor.click();

                window.URL.revokeObjectURL(objectUrl);
            });
    }

    createTempQuestionAnswer = (item) => {
        let tempQuestionAnswers = {}
        let qaItem = {};
        if(item["ecQaId"] != null){
            tempQuestionAnswers["ecQaId"] = item["ecQaId"]
            this.state.qaIdList[item["questionId"]] = item["ecQaId"]
        }

        tempQuestionAnswers["questionId"] = item["questionId"]

        if(item.answerId != undefined){
            tempQuestionAnswers["answerId"] = item.answerId;
        }else{
            tempQuestionAnswers["answerValue"] = item.answerValue;
        }
        return tempQuestionAnswers;
    }



    GetQuestionTypeToShow = (selectedDesignObj) => {
        let that = this

        console.log("that.state.foundryList: "+JSON.stringify(that.state.foundryList));
        if(selectedDesignObj.deignNumberList.length > 0){
            selectedDesignObj.deignNumberList.map(function (item) {
                if(!that.state.questionsTypesToShow.includes(item.designNumberInfo.technologyProcessObj.foundryObject.codeValue)){
                    that.state.questionsTypesToShow.push(item.designNumberInfo.technologyProcessObj.foundryObject.codeValue);
                }
            })
        }else if(selectedDesignObj.productionIdList.length > 0){
            selectedDesignObj.productionIdList.map(function (item) {
                if(!that.state.questionsTypesToShow.includes(that.state.foundryList[item.productionIdInfo.foundry].codeValue)){
                    that.state.questionsTypesToShow.push(that.state.foundryList[item.productionIdInfo.foundry].codeValue);
                }
            })
        }else{
            this.state.questionsTypesToShow.push("common")
        }

        console.log("that.state.questionsTypesToShow: "+JSON.stringify(that.state.questionsTypesToShow))

        //this.state.questionsTypesToShow.push("Intel")
    }

    handleSaveQuestionAnswersResponse = (res) => {
        this.setState({
            saveQuestionAnswers: false
        })
    }

    redirectToQuestionnare = (selectedDesignObj) => {
        console.log("Inside redirectToQuestionnare");
        console.log("selectedDesignObj: "+JSON.stringify(selectedDesignObj))
        this.setState({
            selectedDesignObj: selectedDesignObj,
            fetchAllQuestions: true,
        })
    }

    redirectToQuestionnare2 = () => {
        console.log("Inside redirectToQuestionnare=============***********");

    }

    HandleInputChange = (e, item, isChild, inputType, addressType) =>{
        console.log("date: "+e)
        console.log("Inside HandleInputChange");

        if(inputType == "date"){
            if(e != null) {
                console.log("date value: " + e)
                let date = e
                let formattedDate = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear()
                this.state.savedQuestionAnswersMap[item["questionId"]] = formattedDate
                console.log("questionId: "+"question_"+item["questionId"])

                if (e != "") {

                    PrValidate.ForceEntry("123", "question_"+item["questionId"], 'Input')
                    let tempQuestionAnswers = {}
                    if (this.state.qaIdList[item["questionId"]] != null) {
                        tempQuestionAnswers["ecQaId"] = this.state.qaIdList[item["questionId"]]
                    }
                    tempQuestionAnswers["questionId"] = item["questionId"]
                    tempQuestionAnswers["answerValue"] = formattedDate
                    console.log("Inside IF")
                    this.removeExistingObject(item["questionId"])
                    console.log("this.state.questionAnswers: " + JSON.stringify(this.state.questionAnswers))
                    this.state.questionAnswers.push(tempQuestionAnswers);
                }
            }else{
                console.log("date value: " + e)
                console.log("addressType value: " + addressType)
                //let date = e
                //let formattedDate = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear()
                this.state.savedQuestionAnswersMap[item["questionId"]] = null
                if(addressType == "reqValidation"){
                    PrValidate.ForceEntry(null, "question_"+item["questionId"], 'Input')
                }else{
                    let tempQuestionAnswers = {}
                    if (this.state.qaIdList[item["questionId"]] != null) {
                        tempQuestionAnswers["ecQaId"] = this.state.qaIdList[item["questionId"]]
                    }
                    tempQuestionAnswers["questionId"] = item["questionId"]
                    tempQuestionAnswers["answerValue"] = null
                    console.log("Inside IF")
                    this.removeExistingObject(item["questionId"])
                    console.log("this.state.questionAnswers: " + JSON.stringify(this.state.questionAnswers))
                    this.state.questionAnswers.push(tempQuestionAnswers);
                }
            }
        }else{
            this.state.savedQuestionAnswersMap[item["questionId"]] = (e.target.value)
            console.log("e.target.className: "+e.target.className)
            if(e.target.className.includes("reqValidation")){
                PrValidate.ForceEntry(e.target.value, e.target.id, 'Input')
            }

            console.log("this.state.savedQuestionAnswersMap: "+JSON.stringify(this.state.savedQuestionAnswersMap))
        }

        //console.log("e.target.id: "+e.target.id)


        this.setState({
            savedQuestionAnswersMap: this.state.savedQuestionAnswersMap
        })



    }

    HandleDateChange = (date) => {
        this.setState({
            usGovReviewDate: date
        })
        PrValidate.ForceDate(date, 'test', 'Input')

    }


    HandleInputOnBlur=(e, item, isChild, inputType, addressType)=>{

        //console.log("e.target.value: "+e.target.value)

        console.log("item: "+JSON.stringify(item));
        if(inputType == "date"){
            this.state.savedQuestionAnswersMap[item["questionId"]] = e
        }else{
            this.state.savedQuestionAnswersMap[item["questionId"]] = (e.target.value)
            //if(e.target.className.includes("reqValidation")){
            if(addressType == "FillIn" || addressType == "TextArea"){
                if(e.target.className.includes("reqValidation")) {
                    PrValidate.ForceEntry(e.target.value, e.target.id, 'Input')
                }
            }else{
                PrValidate.ForceSelect(e.target.value, e.target.id, 'Input')
            }

            //}

        }

        //console.log("this.state.savedQuestionAnswersMap: "+JSON.stringify(this.state.savedQuestionAnswersMap))

        this.setState({
            savedQuestionAnswersMap: this.state.savedQuestionAnswersMap
        })


        /*if(type == "select"){
            PrValidate.ForceSelectWithoutMessage(e.target.value, e.target.id, "Input");
        }else{
            if(item.designUploadQuestionSet != null) {
                if (item.designUploadQuestionSet.questionDataTypeObject != null) {
                    if (item.designUploadQuestionSet.questionDataTypeObject["codeValue"] != "Integer_AllowBlank" && item.designUploadQuestionSet.questionDataTypeObject["codeValue"] != "String_AllowBlank") {
                        PrValidate.ForceEntryWithoutMessage(e.target.value, e.target.id);
                    }
                }else{
                    PrValidate.ForceEntryWithoutMessage(e.target.value, e.target.id);
                }
            }else{
                PrValidate.ForceEntryWithoutMessage(e.target.value, e.target.id);
            }
        }*/

        console.log("Inside HandleInputOnBlur")


        //console.log(e.target.value)
        //console.log("ID: "+e.target.id)
        console.log(item)
        console.log(inputType)

        let tempAnswers = {}
        let questionId = ''



        let tempQuestionAnswers = {}

        tempQuestionAnswers["questionId"] = item["questionId"]
        if(this.state.qaIdList[item["questionId"]] != null){
            tempQuestionAnswers["ecQaId"] =  this.state.qaIdList[item["questionId"]]
        }
        if(inputType == "input"){
            tempQuestionAnswers["answerValue"] = e.target.value
        }else{
            tempQuestionAnswers["answerId"] = Number(e.target.value);
        }

        if(addressType != null){
            console.log("Inside IF")
            if(addressType == "ship"){
                this.state.questionsList["shippingAddressAnswer"] = tempQuestionAnswers;
            }else if(addressType == "paying"){
                this.state.questionsList["payingOrganizationAnswer"] = tempQuestionAnswers;
            }else if(addressType == "design"){
                this.state.questionsList["designerCorporationAnswer"] = tempQuestionAnswers;
            }else if(addressType == "endUser"){
                this.state.questionsList["multipleIndeterminateAnswer"] = tempQuestionAnswers;
            }
        }


        console.log("this.state.questionAnswers: "+JSON.stringify(this.state.questionAnswers))
        if(e.target.value == "") {
            if(!e.target.className.includes("reqValidation")){
                this.removeExistingObject(item["questionId"])
                console.log("this.state.questionAnswers: "+JSON.stringify(this.state.questionAnswers))
                this.state.questionAnswers.push(tempQuestionAnswers);
            }
        }else{
            console.log("Inside IF")

            this.removeExistingObject(item["questionId"])
            console.log("this.state.questionAnswers: "+JSON.stringify(this.state.questionAnswers))
            this.state.questionAnswers.push(tempQuestionAnswers);
        }
        console.log("this.state.questionAnswers 2: "+JSON.stringify(this.state.questionAnswers))

        this.setState({
            questionAnswers: this.state.questionAnswers
        })
    }

    removeExistingObject = (questionId) => {

        let indexToDelete = ''
        let that = this
        if(this.state.questionAnswers != null || this.state.questionAnswers != ''){
            this.state.questionAnswers.map(function (item,index) {
                if(item["questionId"] != null || item["questionId"] != ''){
                    if(item["questionId"] == questionId){
                        console.log("index: "+index)
                        indexToDelete = index
                        that.state.questionAnswers.splice(indexToDelete,1)
                    }
                }

            })
        }

    }


    checkVisibleQuestions = () => {
        let that = this
        console.log("that.state.questionAnswers: "+JSON.stringify(that.state.questionAnswers));
        if(this.state.questionAnswers != null || this.state.questionAnswers != '') {
            for (var i = this.state.questionAnswers.length - 1; i >= 0; i--) {
                if(this.state.questionAnswers[i]["questionId"] != null || this.state.questionAnswers[i]["questionId"] != ''){
                    var questionId = "question_"+this.state.questionAnswers[i]["questionId"];
                    if(!$("#"+questionId).is(":visible")){
                        console.log("questionId to delete: "+questionId)
                        that.state.questionAnswers.splice(i,1)
                    }
                }
            }
        }
        console.log("that.state.questionAnswers: "+JSON.stringify(that.state.questionAnswers))
    }

    mapFileQuestions = () => {
        if(this.state.fileObjList != null || this.state.fileObjList != '') {
            for (var i = this.state.fileObjList.length - 1; i >= 0; i--) {
                if(this.state.fileObjList[i]["fileType"] != null || this.state.fileObjList[i]["fileType"] != ''){
                    for (var j = this.state.questionAnswers.length - 1; j >= 0; j--) {
                        if(this.state.questionAnswers[j]["questionId"] != null || this.state.questionAnswers[j]["questionId"] != ''){
                            if(this.state.questionAnswers[j]["answerValue"] == this.state.fileObjList[i]["fileType"]){
                                this.state.fileTyepMap[this.state.fileObjList[i]["fileType"]] = this.state.questionAnswers[j]["questionId"]
                            }
                        }
                    }
                }
            }
        }
        console.log("this.state.fileTyepMap: "+JSON.stringify(this.state.fileTyepMap))
    }

    checkVisibleFiles = () => {
        let that = this
        console.log("that.state.fileObjList: "+JSON.stringify(that.state.fileObjList));
        if(this.state.fileObjList != null || this.state.fileObjList != '') {
            for (var i = this.state.fileObjList.length - 1; i >= 0; i--) {
                if(this.state.fileObjList[i]["fileType"] != null || this.state.fileObjList[i]["fileType"] != ''){
                    var questionId = "question_"+this.state.fileTyepMap[this.state.fileObjList[i]["fileType"]]+"-tr";
                    if(!$("#"+questionId).is(":visible")){
                        console.log("file to delete: "+questionId)
                        that.state.fileObjList.splice(i,1);
                    }
                }
            }
        }
        console.log("that.state.fileObjList: "+JSON.stringify(that.state.fileObjList))

    }

    /*CopyFromFirstRow = (addressType) => {
        console.log("addressType: "+addressType)
    }*/

    HandleAddrRowsChange = (e, i, key = "", addressType, productionIdTable) => {

        if(addressType == "ship"){
            console.log("INSIDE ship")
            var shipRows = Array.from(this.state.shipRows);
            var shipRowsProdId = Array.from(this.state.shipRowsProdId);

            if(productionIdTable === true){
                shipRowsProdId[i][key] = e.target.value;
                this.state.shipToCorporationListProdId = shipRowsProdId
                //this.state.questionsList["shipToCorporationList"] = shipRowsProdId
                this.setState({shipRowsProdId: shipRowsProdId});
            }else{
                shipRows[i][key] = e.target.value;
                this.state.shipToCorporationList = shipRows
                //this.state.questionsList["shipToCorporationList"] = shipRows
                this.setState({shipRows: shipRows});
            }

            let mergedShipToCorporationList = this.state.shipToCorporationListProdId.concat(this.state.shipToCorporationList);
            this.state.questionsList["shipToCorporationList"] = mergedShipToCorporationList

            console.log("shipRows: "+JSON.stringify(shipRows));
        }

        if(addressType == "paying"){
            console.log("INSIDE paying")
            var payingRows = Array.from(this.state.payingRows);
            var payingRowsProdId = Array.from(this.state.payingRowsProdId);

            if(productionIdTable === true){
                payingRowsProdId[i][key] = e.target.value;
                this.state.payingCorporationListProdId = payingRowsProdId
                //this.state.questionsList["payingCorporationList"] = payingRowsProdId
                this.setState({ payingRowsProdId: payingRowsProdId });
            }else{
                payingRows[i][key] = e.target.value;
                this.state.payingCorporationList = payingRows
                //this.state.questionsList["payingCorporationList"] = payingRows
                this.setState({ payingRows: payingRows });
            }

            let mergedPayingCorporationList = this.state.payingCorporationListProdId.concat(this.state.payingCorporationList);
            this.state.questionsList["payingCorporationList"] = mergedPayingCorporationList
        }

        if(addressType == "endUser"){
            console.log("INSIDE endUser")
            var endUserRows = Array.from(this.state.endUserRows);
            var endUserRowsProdId = Array.from(this.state.endUserRowsProdId);

            if(productionIdTable === true){
                endUserRowsProdId[i][key] = e.target.value;
                this.state.endUserListProdId = endUserRowsProdId
                //this.state.questionsList["endUserList"] = endUserRowsProdId
                this.setState({ endUserRowsProdId: endUserRowsProdId });
            }else{
                endUserRows[i][key] = e.target.value;
                this.state.endUserList = endUserRows
                //this.state.questionsList["endUserList"] = endUserRows
                this.setState({ endUserRows: endUserRows });
            }

            let mergedEndUserList = this.state.endUserListProdId.concat(this.state.endUserList);
            this.state.questionsList["endUserList"] = mergedEndUserList
        }

        if(addressType == "design"){
            console.log("INSIDE design")
            var designRows = Array.from(this.state.designRows);
            var designRowsProdId = Array.from(this.state.designRowsProdId);

            if(productionIdTable === true){
                designRowsProdId[i][key] = e.target.value;
                this.state.designerCorporationListProdId = designRowsProdId
                //this.state.questionsList["designerCorporationList"] = designRowsProdId
                this.setState({ designRowsProdId: designRowsProdId });
            }else{
                designRows[i][key] = e.target.value;
                this.state.designerCorporationList = designRows
                //this.state.questionsList["designerCorporationList"] = designRows
                this.setState({ designRows: designRows });
            }

            let mergedDesignerCorporationList = this.state.designerCorporationListProdId.concat(this.state.designerCorporationList);
            this.state.questionsList["designerCorporationList"] = mergedDesignerCorporationList
        }


        if(key === 'country'){
            PrValidate.ForceSelect(e.target.value, e.target.name, 'Input');
        }else{
            if(!e.target.name.includes("Addr2")){
                PrValidate.ForceEntry(e.target.value, e.target.name, 'Input');
            }
        }

        console.log("questionAnswers: "+JSON.stringify(this.state.questionAnswers));
        console.log("designRows: "+JSON.stringify(this.state.designRows));
        console.log("endUserRows: "+JSON.stringify(this.state.endUserRows));
        console.log("payingRows: "+JSON.stringify(this.state.payingRows));
        console.log("shipRows: "+JSON.stringify(this.state.shipRows));

    };

    HandleSuccessGetCorporateTypeListResponse = (res) => {
        this.setState({
            fetchCorporateType: false,
            corporateTypelist: res.data
        })

        let that = this
        this.state.corporateTypelist.map(function(item) {
            that.state.corporateTypelistMap[item["codeValue"]] = item
        })
        console.log("address type list: "+JSON.stringify(res.data))
        console.log("addressTypelistMap list: "+JSON.stringify(that.state.corporateTypelistMap))
    }

    InsertCorporateList = (questionsList) => {
        if(questionsList.shipToCorporationList.length > 0){
            this.state.shipRows = this.state.shipToCorporationList
        }else if(questionsList.designerCorporationList.length > 0){
            this.state.payingRows = this.state.payingCorporationList
        }else if(questionsList.designerCorporationList.length > 0){
            this.state.designRows = this.state.designerCorporationList
        }/*else if(this.state.shipToCorporationList.length > 0){
            //this.state.endUserRows = this.state.designerCorporationList
        }*/
    }

    HandleFileChange = (e, questionItem) => {
        console.log("INSIDE HandleFileChange: "+e.target.files[0])

        console.log("this.state.fileObjList: "+JSON.stringify(this.state.fileObjList))
        console.log("this.state.fileList: "+JSON.stringify(this.state.fileList))
        console.log("this.state.fileTypeList: "+JSON.stringify(this.state.fileTypeList))

        let indexToDelete;
        this.state.fileObjList.map(function (item, index) {
            console.log("item: "+JSON.stringify(item));
            if(questionItem.additionalMappingObj.codeValue == item.fileType){
                console.log("INSIDE IF")
                indexToDelete = index
            }
        })
        if(indexToDelete != null){
            this.state.fileObjList.splice(indexToDelete,1);
            this.state.fileList.splice(indexToDelete, 1);
            this.state.fileTypeList.splice(indexToDelete, 1);
        }

        let tempQuestionAnswers = {}
        tempQuestionAnswers["questionId"] = questionItem["questionId"]
        tempQuestionAnswers["answerValue"] = questionItem.additionalMappingObj.codeValue

        if(e.target.files[0]!= "") {
            console.log("Inside IF")
            this.removeExistingObject(questionItem["questionId"])
            console.log("this.state.questionAnswers: "+JSON.stringify(this.state.questionAnswers))
            this.state.questionAnswers.push(tempQuestionAnswers);
        }
        this.setState({
            questionAnswers: this.state.questionAnswers
        })

        console.log("this.state.questionAnswers: "+JSON.stringify(this.state.questionAnswers))


        let fileObj = {
            formFile: e.target.files[0],
            fileType: questionItem.additionalMappingObj.codeValue
        }
        //this.state.fileObjMap[questionItem.questionId] = fileObj



        this.state.fileObjList.push(fileObj)
        this.state.fileList.push(e.target.files[0]);
        this.state.fileTypeList.push(questionItem.additionalMappingObj.codeValue)


        this.setState({
            fileObjList: this.state.fileObjList,
            fileList: this.state.fileList,
            fileTypeList: this.state.fileTypeList
        });
    };


    checkValidation = () => {

        console.log("Inside Check Validation")
        let that = this
        this.state.allFieldsFilled = true;
        /*if(this.state.parentArrValuesList != '{}'){
            this.state.parentArrValuesList.map(function (item) {
                console.log(item[0].designUploadQuestionSet["question"])
                console.log(that.state.selectValue[item[0]["technologyQuestionId"]])
                if(that.state.selectValue[item[0]["technologyQuestionId"]] == '' || that.state.selectValue[item[0]["technologyQuestionId"]] == null){
                    console.log("INSIDE IF")
                    that.state.allFieldsFilled = false;
                    that.state.enterInputError[item[0]["technologyQuestionId"]] = true;
                }else{
                    that.state.enterInputError[item[0]["technologyQuestionId"]] = false;
                }
            })*/

            this.state.firstErrorReferences = []
        console.log("this.state.firstErrorReferences: "+JSON.stringify(this.state.firstErrorReferences))
        $("input").each(function() {
            var element = $(this);
            var id = $(this).attr("id");
            var type = $(this).attr("type");
            var className = $(this).attr("class");
            /*var req = $(this).attr("required");
            console.log("req"+req)*/
            console.log("id: "+id)
            console.log("type: "+type)
            console.log("className: "+className)
            if(type == "date"){
                var name = $(this).attr("name");
                var className = $(this).parent().parent().parent().attr('class');
                var reqValidation = true;
                if(className != undefined){
                    reqValidation = className.includes("reqValidation")
                }
                console.log("className: "+$(this).parent().parent().parent().attr('class'))
                console.log("name: "+name)
                var dateId = name.split("question_")[1];
                var dateValue = that.state.savedQuestionAnswersMap[dateId]
                console.log("dateValue: "+dateValue);
                if(dateValue == null && $(this).is(":visible") && reqValidation){
                    console.log("Inside DATE IF")
                    PrValidate.ForceDate('', name, 'Input')
                    that.state.firstErrorReferences.push(name);
                    that.state.allFieldsFilled = false;
                }else{
                    if(reqValidation == true){
                        if($(this).is(":visible")) {
                            PrValidate.ForceDate(dateValue, name, 'Input')
                        }
                    }
                    //that.state.enterInputError[id] = false;
                }
            }else if(id != undefined && type != 'file'){
                var value = document.getElementById(id).value
                var reqValidation = true;
                if(className != undefined){
                    reqValidation = className.includes("reqValidation")
                }

                console.log("document.getElementById(id): "+id)
                console.log("reqValidation: "+reqValidation)
                if (value == "" && $(this).is(":visible") && reqValidation /*&& req == "required"*/) {
                    if(!id.includes("Addr2_") && !id.includes("Addr2")){
                        //PrValidate.ForceEntryWithoutMessage(value, id);
                        PrValidate.ForceEntry(value, id, 'Input');
                        that.state.firstErrorReferences.push(id);
                        console.log("INSIDE IF")
                        that.state.allFieldsFilled = false;
                        console.log("Empty Element: "+id)
                        //that.state.enterInputError[id] = true;
                    }
                }else{
                    if(!id.includes("Addr2_") && !id.includes("Addr2")) {
                        if(reqValidation == true){
                            if($(this).is(":visible")) {
                                PrValidate.ForceEntry(value, id, 'Input');
                            }
                        }
                    }
                    //that.state.enterInputError[id] = false;
                }
            }


            if(!$(this).is(":visible")){
                console.log("NOT VISIBLE INPUT: ")
                console.log("Empty Element: "+id)
            }
        });

        $("textArea").each(function() {
            var id = $(this).attr("id");
            console.log("textArea id: "+id)
            console.log("textArea val: "+$(this).val())
            console.log("is visible: "+ $(this).is(":visible"))
            var className = $(this).attr("class");

            var reqValidation = true;
            if(className != undefined){
                reqValidation = className.includes("reqValidation")
            }

            // if ($(this).is(':selected')){
            if ($(this).val() == "" && $(this).is(":visible") && reqValidation){
                //PrValidate.ForceSelectWithoutMessage(, , "Input");
                PrValidate.ForceEntry($(this).val(), id, 'Input')
                that.state.firstErrorReferences.push(id);
                console.log("INSIDE IF")
                that.state.allFieldsFilled = false;
                console.log("Empty Element: "+id)
                //that.state.enterInputError[id] = true;
            }else{
                if(reqValidation == true) {
                    if ($(this).is(":visible")) {
                        PrValidate.ForceEntry($(this).val(), id, 'Input')
                    }
                }
                //that.state.enterInputError[id] = false;
            }

            if(!$(this).is(":visible")){
                console.log("NOT VISIBLE: ")
                console.log("Empty Element: "+id)
            }
        });


            $("select").each(function() {
                var id = $(this).attr("id");
                console.log("select id: "+id)
                console.log("select val: "+$(this).val())
                console.log("is visible: "+ $(this).is(":visible"))

                // if ($(this).is(':selected')){
                if ($(this).val() == "" && $(this).is(":visible")){
                    //PrValidate.ForceSelectWithoutMessage(, , "Input");
                    PrValidate.ForceSelect($(this).val(), id, 'Input')
                    that.state.firstErrorReferences.push(id);
                    console.log("INSIDE IF")
                    that.state.allFieldsFilled = false;
                    console.log("Empty Element: "+id)
                    //that.state.enterInputError[id] = true;
                }else{
                    if($(this).is(":visible")){
                        PrValidate.ForceSelect($(this).val(), id, 'Input')
                        console.log("select value: "+$(this).attr("disabled"))
                        if($(this).attr("disabled") == "disabled"){
                            $(this).css('background-color', '#e4e4e4');
                        }
                    }

                    //that.state.enterInputError[id] = false;
                }

                if(!$(this).is(":visible")){
                    console.log("NOT VISIBLE: ")
                    console.log("Empty Element: "+id)
                }
            });


        let nameValid = PrValidate.ForceEntry(this.state.txtAuthName, "txtAuthName", 'Input');
        if(!nameValid){
            this.state.allFieldsFilled = false
            that.state.firstErrorReferences.push("txtAuthName");
        }

        let phoneValid = PrValidate.ForceEntry(this.state.txtAuthTelNumber, "txtAuthTelNumber", 'Input');
        if(!phoneValid){
            this.state.allFieldsFilled = false
            that.state.firstErrorReferences.push("txtAuthTelNumber");
        }

        let titleValid = PrValidate.ForceEntry(this.state.txtAuthTitle, "txtAuthTitle", 'Input');
        if(!titleValid){
            this.state.allFieldsFilled = false
            that.state.firstErrorReferences.push("txtAuthTitle");
        }

         let emailValid = PrValidate.ForceEmail(this.state.txtAuthEmail, "txtAuthEmail");
         if(!emailValid){
             this.state.allFieldsFilled = false
             that.state.firstErrorReferences.push("txtAuthEmail");
         }
        //}

        this.setState({
            //enterInputError: this.state.enterInputError,
            allFieldsFilled: this.state.allFieldsFilled
        })

    }

    checkValidationResponse = () => {
        this.checkValidation()
        console.log(this.state.allFieldsFilled)
        if(this.state.allFieldsFilled == false){
            console.log("firstErrorReferences: "+JSON.stringify(this.state.firstErrorReferences))
            $('html, body').animate({
                scrollTop: $("#"+this.state.firstErrorReferences[0]).offset().top-100
            }, 1000);
        }
    }

    checkDeletedItems = () => {
        let that = this;
        console.log("this.state.deletedFileItemsDocumentNumbers: "+JSON.stringify(this.state.deletedFileItemsDocumentNumbers));
        if(this.state.deletedFileItemsDocumentNumbers != null || this.state.deletedFileItemsDocumentNumbers != '[]') {
            for (var i = this.state.deletedFileItemsDocumentNumbers.length - 1; i >= 0; i--) {
                if(this.state.deletedFileItemsDocumentNumbers[i] == ""){
                        that.state.deletedFileItemsDocumentNumbers.splice(i,1);
                }
            }
        }
        console.log("this.state.deletedFileItemsDocumentNumbers: "+JSON.stringify(this.state.deletedFileItemsDocumentNumbers));
    }

    SubmitExport = () => {

        console.log("deletedFileItemsDocumentNumbers: "+JSON.stringify(this.state.deletedFileItemsDocumentNumbers));

        this.checkDeletedItems();
        this.checkValidationResponse();

        this.mapFileQuestions();
        this.checkVisibleFiles();
        this.checkVisibleQuestions();

        console.log("questionAnswers: "+JSON.stringify(this.state.questionAnswers));


        console.log("shipRows: "+JSON.stringify(this.state.shipRows));
        console.log("designRows: "+JSON.stringify(this.state.designRows));
        console.log("endUserRows: "+JSON.stringify(this.state.endUserRows));
        console.log("payingRows: "+JSON.stringify(this.state.payingRows));

        console.log("shipRowsProdId: "+JSON.stringify(this.state.shipRowsProdId));
        console.log("designRows: "+JSON.stringify(this.state.designRowsProdId));
        console.log("endUserRows: "+JSON.stringify(this.state.endUserRowsProdId));
        console.log("payingRows: "+JSON.stringify(this.state.payingRowsProdId));


        console.log("shippingAddressAnswer: "+JSON.stringify(this.state.questionsList["shippingAddressAnswer"]));
        console.log("payingOrganizationAnswer: "+JSON.stringify(this.state.questionsList["payingOrganizationAnswer"]));
        console.log("designerCorporationAnswer: "+JSON.stringify(this.state.questionsList["designerCorporationAnswer"]));
        console.log("fileObjMap: "+JSON.stringify(this.state.fileObjList));

        let payingCorporationListRows = this.state.payingRows.concat(this.state.payingRowsProdId)
        let shipToCorporationListRows = this.state.shipRows.concat(this.state.shipRowsProdId)
        let designerCorporationListRows = this.state.designRows.concat(this.state.designRowsProdId)
        let endUserListRows = this.state.endUserRows.concat(this.state.endUserRowsProdId)


        this.state.questionsList.exportControlNew.authorizedBy =  this.state.txtAuthName;
        this.state.questionsList.exportControlNew.authorizedByTitle = this.state.txtAuthTitle;
        this.state.questionsList.exportControlNew.authEmailAddress = this.state.txtAuthEmail;
        this.state.questionsList.exportControlNew.authPhoneNumber = this.state.txtAuthTelNumber;

        if(this.state.completeQuestions!=null){
            this.state.questionsList.exportControlNew.completeQuestions = 1;
        }

        console.log("this.state.questionsList[\"exportControlNew\"]: "+JSON.stringify(this.state.questionsList["exportControlNew"]))
        let finalData = {
            payingCorporationList: payingCorporationListRows,
            shipToCorporationList: shipToCorporationListRows,
            designerCorporationList: designerCorporationListRows,
            endUserList: endUserListRows,
            questionsAnswers: this.state.questionAnswers,
            exportControlNew: this.state.questionsList["exportControlNew"],
            shippingAddressAnswer: this.state.questionsList["shippingAddressAnswer"],
            payingOrganizationAnswer: this.state.questionsList["payingOrganizationAnswer"],
            designerCorporationAnswer: this.state.questionsList["designerCorporationAnswer"],
            multipleIndeterminateAnswer: this.state.questionsList["multipleIndeterminateAnswer"],
            designs: this.state.selectedDesignObj.designs,
            productionIdList: this.state.selectedDesignObj.productionIdList,
            fileTypeList: this.state.fileTypeList,
            deletedFileItemsDocumentNumbers: this.state.deletedFileItemsDocumentNumbers
            //fileObjList: this.state.fileObjList

        }
        console.log("finalData: "+JSON.stringify(finalData));

        var formData = new FormData();
        formData.append("model", JSON.stringify(finalData));
        this.state.fileList.map(function (item) {
            formData.append("file", item);
        })
        //formData.append("exportControlNewDto", finalData);
//checking git username issue
        let accessToken = JSON.parse(localStorage.getItem('okta-token-storage')).accessToken.accessToken;
        let headers = {
            'Authorization':'Bearer ' + accessToken
        };

        this.setState({
            ECQuestionnareFinalData: formData,
            //saveQuestionAnswers: true
        })


        let that = this
        let saveObj = false
        if(this.state.allFieldsFilled === true){
            this.setState({
                saveButtonDisabled : true
            })
            //fetch('http://localhost:8080/exportControlNew/save', {
            fetch('../rest/exportControlNew/save', {
                method: 'post',
                body: formData,
                headers: headers
            }).then(function(response) {
                if (response.status !== 200 && response.status !== 201) {
                    console.log("There was an error! inside");
                } else {
                    console.log("Request successful");
                    ToastsStore.success("Successfully Saved.");
                    that.setState({
                        saveButtonDisabled: false
                        //redirectToMainPage: true
                    })

                    setTimeout(function() { //Start the timer
                        that.props.componentprops.history.push("/dashboard");
                    }.bind(that), 2000)
                }
            }).catch(function(err) {
                console.log("There was an error!"+err);
            });
        }else{
            console.log("Please input all fields")
        }

    }


    render() {
        return (


            <div>

             {/*   {this.state.showModal ?
                    <div className="popupbody">
                        <div className="popupTop">
                            <div className="popup_close">
                                <button onClick={this.ShowHideModal} className="btn">
                                    <img src={closeImg} alt="logo" />
                                </button>
                            </div>
                            <div className='popup'>
                                <div className='popup_inner'>
                                    <TableDesignStatusCtrl onHandleChange={this.handleChange} foundryAndTechClass={this.state.foundryAndTechClass}
                                                           milestoneList={this.state.milestoneList} designMilestones={this.state.designMilestones}
                                                           productionIdInfoMilestoneNames={this.state.productionIdInfoMilestoneNames} optionClicked={this.state.optionClicked}
                                                           codeObject={this.state.codeObject} codeStatus={this.state.codeStatus} runId={this.state.runId} productionIdItem={this.state.productionIdItem}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''
                }*/}

                {this.state.saveButtonDisabled === true?
                    <PrLoader size={"small"} />:''
                }
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_CENTER} />


                {this.state.redirectToExportControlQuestionnare == false ?
                    <ExportControlComponent {...this.props} state={this.state} handleChange={this.HandleChange} handleSort={this.handleSort} redirectToQuestionnare={this.redirectToQuestionnare}/> : ''
                }

                {this.state.redirectToExportControlQuestionnare == true?
                    <ExportControlQuestionnareComponent {...this.props} state={this.state} questionsList={this.state.questionsList} HandleChange={this.HandleChange}
                                                        handleSort={this.handleSort}
                                                        redirectToQuestionnare2={this.redirectToQuestionnare2}
                                                        SubmitExport={this.SubmitExport}
                                                        HandleInputChange={this.HandleInputChange}
                                                        HandleAddrRowsChange={this.HandleAddrRowsChange}
                                                        HandleInputOnBlur={this.HandleInputOnBlur}
                                                        HandleFileChange={this.HandleFileChange}
                                                        HandleDateChange={this.HandleDateChange}
                                                        CopyFromFirstRow={this.CopyFromFirstRow}/>:''
                }

                {this.state.fetchCustomerDesigns === true ? GetCustomerDesigns(this.props.PrStore, this.handleGetCustomerDesignsResponse) : ''}
                {this.state.fetchAllQuestions === true ? GetAllQuestions(this.props.PrStore, this.state.selectedDesignObj.exportControlNew["exportControlId"],this.handleGetAllQuestionsResponse) : ''}
                {this.state.saveQuestionAnswers === true ? SaveQuestionAnswers(this.props.PrStore, this.state.ECQuestionnareFinalData,this.handleSaveQuestionAnswersResponse) : ''}
                {this.state.fetchCorporateType === true ? GetCorporateTypeList(this.props.PrStore, this.HandleSuccessGetCorporateTypeListResponse) : ''}
                {this.state.fetchUploadFileList === true ? GetUploadFileList(this.props.PrStore, this.state.selectedDesignObj.exportControlNew["exportControlId"], this.HandleSuccessGetUploadFileListResponse) : ''}

            </div>
        )
    }
}

export const ExportControlCtrl = PrConnect(ExportControl, '');