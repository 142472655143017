import React, { Component } from 'react';
import QuoteDedicatedComponent from './quotededicated';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import { getInitData , submitDedicatedQuoteData} from '../../../services/quoteDedicatedService'
import PrValidate from "../../../library/pr-controls/pr-validation-full";
import $ from 'jquery';
import {getCustomerData} from "../../../services/users";
import {DesignUploadFTPGuideComponentCtrl} from "../Test/DesignUploadFTPGuide";
import DashboardCtrl from "../Home";
import {PrConnect} from "../../../library/pr-miniredux";
import {accManagementCtrl} from "../Account Management";


export default class quoteDedicatedCtrl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            txtCustName: '',
            txtQuoteNumber: '',
            txtEmail: JSON.parse(localStorage.getItem('okta-token-storage')).idToken.claims.email,
            ddlSelectedTech: '',
            txtDesignNumber: '',
            ddlWaferForecastPerYear: '',
            txtEUHNumber: '',
            ddlMaskRespinOrder: '',
            txtAddMaskNumber: '',
            txtExpediteOrder: '',
            ddlRitAbExpedite: '',
            ddlRitBExpedite: '',
            txtProdWafersNumber: '',
            txtNre: '',
            txtFeatureMaskPrice: '',
            txtNreSubtotalVal:'',
            txtBaseWaterPrice: '',
            txtEnggFeatureWaferPrice: '',
            txtEnggWaferPrice: '',
            txtTotalEnggWaferPrice: '',
            txtRespinNre: '',
            txtMaskPrice: '',
            txtRitABExpediteFee: '',
            txtRitBExpediteFee: '',
            txtProdBaseWaferPrice: '',
            txtFeatureWaferPrice: '',
            txtProdPricePerWafer: '',
            txtTotalProdWaferPricetxtTotalPrice: '',
            txtcustomQuoteSummaryVal: '',

            fetchDedicatedQuoteData: true,
            dedicatedQuoteData: {},
            featureTableContent:[],
            
            featureRowSelectValue:[],
            mpriceFactor:'0.5',

            isGreaterThen150:false,
            numberOfEUHWafer:'',
            isMaskRespinOrder:false,
            numberOfAdditionalMask:'',
            isABExpidite:false,
            isBExpidite:false,

            foundryPriceList:{},
            selectedFoundryPrice:'',
            selectedFoundryPriceId:'',
            featurePriceSum:0,
            featureMaskCount:0,
            selectedWaferFeatureIdStr:'',
            customFeatureMaskCount:'',
            waferPriceList:{},
            customFeaturePriceSum:0,
            selectedWaferPriceId:'',
            technologyProcessList:{},
            numberOfProductionWafer:0,
            txtTotalPrice:0,
            txtTotalProdWaferPrice:0,

            dedicatedQuoteDataSubmitObject:{},
            dedWaferPricingId:'',

            saveDedicatedQuoteData:false,
            customerData:{},
            custData:true,
            redirectToMainPage:false,
            quotationTncList:{},
            quotationTncListMap:{}
        }
    }

    UNSAFE_componentWillMount = () => {
        this.setState({
            fetchDedicatedQuoteData: true
        })
        console.log("featureTableContent: "+JSON.stringify(this.state.featureTableContent))


    }

    fetchFoundryPricingList = () => {
        let that = this
        console.log("INSIDE fetchFoundryPricingList=============================")
        console.log("foundryPricingList========="+JSON.stringify(this.state.dedicatedQuoteData["foundryPricingList"]))
        let count = 0

        that.state.foundryPriceList = {}
        this.state.dedicatedQuoteData["foundryPricingList"].map(function(item) {
            console.log("==================="+count+"====================="+item["orderTypeName"])
            console.log("********************"+JSON.stringify(item))
            let tempOrderTypeItem = {}
                tempOrderTypeItem[item["orderTypeName"]] = item

            console.log("========================="+that.state.foundryPriceList[item["technologyProcessName"]])
            if(that.state.foundryPriceList[item["technologyProcessName"]+"1"] != null){
                that.state.foundryPriceList[item["technologyProcessName"]+"2"] = tempOrderTypeItem
            }else{
                that.state.foundryPriceList[item["technologyProcessName"]+"1"] = tempOrderTypeItem
            }

            count = count+1
                //that.state.foundryPriceList[item["technologyProcessName"]][item["orderTypeName"]] = item
        })
        this.setState({
            foundryPriceList: this.state.foundryPriceList
        })
        console.log("foundryPriceList: "+JSON.stringify(this.state.foundryPriceList))
        console.log("LENGTH: "+this.state.foundryPriceList.length)
    }

    fetchWaferPriceList = () => {
        let that = this
        this.state.dedicatedQuoteData["waferPricingList"].map(function(item) {
            if(item['maximumQuantityFcst'] === 0 ){
                item['maximumQuantityFcst'] = 9999999
            }
            console.log("that.state.waferPriceList: "+that.state.waferPriceList[item["technologyProcessName"]])
            if (that.state.waferPriceList[item["technologyProcessName"]] == null){
                console.log("INSIDE WAFER IF =============")
                that.state.waferPriceList[item["technologyProcessName"]] = [];
                that.state.waferPriceList[item["technologyProcessName"]][0] = item
            }else{
                that.state.waferPriceList[item["technologyProcessName"]][1] = item;
            }

            console.log("that.state.waferPriceList: "+that.state.waferPriceList)
            /*let waferPriceTemp = {}
            waferPriceTemp[item["technologyProcessName"]] = item
            that.state.waferPriceList.push(waferPriceTemp)*/
            that.setState({
                waferPriceList: that.state.waferPriceList
            })
        })

        console.log("waferPriceList: "+JSON.stringify(this.state.waferPriceList))
    }

    fetchTechnologyProcessList = () => {
        let that = this
        this.state.dedicatedQuoteData["technologyProcessList"].map(function(item) {
            that.state.technologyProcessList[item["processName"]] = item
        })
        this.setState({
            technologyProcessList: this.state.technologyProcessList
        })
        console.log("technologyProcessList: "+JSON.stringify(this.state.technologyProcessList))
    }

    HandleChange = (e) => {
        if(e.target.name === "txtEUHNumber" || e.target.name === "txtAddMaskNumber" || e.target.name === "txtProdWafersNumber"){
            console.log('decimalcheck got called', e.target.value);
            //if(e.target.value.match(/^\d*\.?\d\d?$/g) || e.target.value == "") {
            //|| e.target.value.match(/^(?:\d{1,10}|(?!.{11})\d+\.)$/g)
            //if(e.target.value.match(/^[1-9]\d*$/g) || e.target.value == "") {
            if(e.target.value.match(/^[0-9]{0,6}$/g) || e.target.value == "") {

            //if(e.target.value != "") {
                console.log("inside IF======")
                this.setState({
                    [e.target.name]: e.target.value
                })
            }
        }else{
            this.setState({
                [e.target.name]: e.target.value
            })
        }

        if(e.target.name === 'txtDesignNumber'){
            let txtDesignNumber = PrValidate.ForceEntry(e.target.value, 'txtDesignNumber', "a design number");
        }else if(e.target.name === 'txtCustName'){
            let txtCustName = PrValidate.ForceEntry(e.target.value,'txtCustName', "a customer name");
        }
    }

    HandleTechnologyChange = (e) => {
        console.log(e.target.value)
        let ddlSelectedTech = PrValidate.ForceSelect(e.target.value, 'ddlSelectedTech', 'a technology node');
        this.state.featureTableContent = []
        let that = this
        this.state.dedicatedQuoteData["waferAddonPricingList"].map(function(item) {
            if(item["technologyProcessName"] == e.target.value){
                if(item["requiredMask"]){
                    
                    item["requiredMask"] = item["requiredMask"].split(",").join(" ,");
                    console.log("===================================================")
                    console.log("item[\"requiredMask\"]: "+JSON.stringify(item["requiredMask"]))
                }

                if(item["partNumber"]){
                    item["partNumber"] = item["partNumber"].split("/").join("/ ");
                    item["partNumber"] = item["partNumber"].split("+").join("+ ");
                    console.log("===================================================")
                    console.log("item[\"partNumber\"]: "+JSON.stringify(item["partNumber"]))
                }

                if(item["feature"]){
                    item["feature"] = item["feature"].split("/").join("/ ");
                    console.log("===================================================")
                    console.log("item[\"feature\"]: "+JSON.stringify(item["feature"]))
                }

                that.state.featureTableContent.push(item)
            }
        })
        this.setState({
            [e.target.name]: e.target.value,
            ddlSelectedTech: e.target.value,
            featureTableContent: this.state.featureTableContent
        })
        console.log(JSON.stringify(this.state.featureTableContent))
        this.state.ddlSelectedTech = e.target.value
        console.log("ddlSelectedTech: "+this.state.ddlSelectedTech)
        if(e.target.value != ''){
            this.updateForcastQuantityLabel(this.state.ddlSelectedTech)
            this.calculateQuotation(this.state.ddlSelectedTech)
        }


    }

    updateForcastQuantityLabel = (processName) => {
        let techProcessNode = this.state.technologyProcessList[processName];
        console.log("techProcessNode: "+JSON.stringify(techProcessNode))
        let lblTxt = "Is wafer forecast greater than 150 wafers per year?";
        if (techProcessNode["waferSize"] === 200)
            lblTxt = "Is wafer forecast greater than 300 wafers per year?";
        document.getElementById("forcastQuantity").innerText = lblTxt;
        console.log("mpriceFactor: "+this.convertNumberToOnePointDecimal(techProcessNode["mosisMarkup"]))
        this.state.mpriceFactor = this.convertNumberToOnePointDecimal(techProcessNode["mosisMarkup"]);
    }

    HandleSuccessGetInitDataResponse = (res) => {
        this.setState({
            dedicatedQuoteData: res.data,
            quotationTncList: res.data["quotationTncList"],
            fetchDedicatedQuoteData: false,
            customerData: res.data["customer"],
            txtCustName: res.data.customer["customerName"],
            txtQuoteNumber: res.data["quoteNumber"]+"-0",
            //txtCustName: JSON.parse(localStorage.getItem('okta-token-storage')).idToken.claims.name
        })

        this.createTncMap()
        console.log("this.state.dedicatedQuoteData: "+JSON.stringify(this.state.dedicatedQuoteData))
        this.fetchFoundryPricingList()
        this.fetchWaferPriceList()
        this.fetchTechnologyProcessList()
    }

    createTncMap = () => {
        let that = this
        this.state.quotationTncList.map(function(item) {
            that.state.quotationTncListMap[item["displayOrder"]] = item
            let arrayPoints = item["tcDetails"].split("\r-\t")
            if(arrayPoints.length>1) {
                let liElement = ''
                liElement+=arrayPoints[0];
                for(let i=1; i<arrayPoints.length; i++){
                    liElement+= '<li>'+arrayPoints[i]+'</li>';
                }
                that.state.quotationTncListMap[item["displayOrder"]].tcDetails = liElement
            }else{
                let arrayPoints = item["tcDetails"].split("\t-\t")
                if(arrayPoints.length>1) {
                    let liElement = ''
                    liElement += arrayPoints[0];
                    for (let i = 1; i < arrayPoints.length; i++) {
                        liElement += '<li>' + arrayPoints[i] + '</li>';
                    }
                    that.state.quotationTncListMap[item["displayOrder"]].tcDetails = liElement
                }
            }
            console.log("displayOrder: "+JSON.stringify(that.state.quotationTncListMap[item["displayOrder"]]))
        })


        console.log("quotationTncList: "+JSON.stringify(this.state.quotationTncList))
        console.log("quotationTncList length: "+this.state.quotationTncList.length)
        console.log("quotationTncListMap: "+JSON.stringify(this.state.quotationTncListMap))
    }


    HandleSuccessSaveDedicatedQuoteData = (res) => {
        ToastsStore.success("You will receive the quote by email shortly.");
        this.setState({
            saveDedicatedQuoteData: false,
            redirectToMainPage: true
        })

        let that = this
        console.log("that.props.componentprops.history.: "+JSON.stringify(this.props.componentprops.history))
        setTimeout(function() { //Start the timer
            that.props.componentprops.history.push("/dashboard");
        }.bind(that), 2000)
        console.log("Response: "+res.data)
    }


    inputValueChanged = (e) => {
        this.setState({
            mpriceFactor: e.target.value
        })
    }

    //=================================UTILITY FUNCTIONS - START===============================
    convertNumberToOnePointDecimal = (num) => {
        return Number(num).toFixed(1);
    }

    convertToRoundNumber = (value) => {
        return Math.round(Number(value.toString().replace(/[^0-9.-]+/g,""))).toString();
    }
    //=================================UTILITY FUNCTIONS - END=================================


    //=================================ONCHANGE FUNCTIONS - START==============================
    onMosisMarkupValueChanged = (e) => {
        console.log("this.state.ddlSelectedTech: "+this.state.ddlSelectedTech)
       let mmv = this.convertNumberToOnePointDecimal(e.target.value);
        this.setState({
            mpriceFactor: mmv
        })
        let mosisMarkUp = mmv/100;
        console.log("mosisMarkUp: "+mosisMarkUp)
        this.calculateQuotation(this.state.ddlSelectedTech);
    }

    onWaferForecastSelectionChanged = (e) => {
        this.setState({
            ddlWaferForecastPerYear:e.target.value
        })
        this.state.isGreaterThen150 = e.target.value.length  > 0 && e.target.value === 'Yes';
        console.log("this.state.isGreaterThen150: "+this.state.isGreaterThen150)
        this.calculateQuotation(this.state.ddlSelectedTech);
    }

    normalizeNumber(num) {
        return this._isNumber(num) ? Number(num) : 0;
    }

    _isNumber(input) {
        return !(typeof input == 'undefined' || isNaN(input));
    }

    roundOff(num, toDecimals) {
        if (!this._isNumber(num))
            throw num;

        return parseFloat(String(num)).toFixed(this.normalizeNumber(toDecimals));
    }

    onNumberOfEUHWaferChange = (e) => {

        console.log("INSIDE onNumberOfEUHWaferChange"+e.target.value)
        //let val = Number(e.target.value) < 0 ? 0 : Number(e.target.value);
        let val = e.target.value;
        if (val.length > 0 && this._isNumber(val)) {
            console.log("inside IF===========================")
            val = this.roundOff(val, 0);
            console.log("inside val: "+val)
            this.setState({
                [e.target.name]: val
            })
            this.state.txtEUHNumber = val

        }
        /*console.log("val: "+val)
        this.setState({
            txtEUHNumber: val,

        })*/
        console.log("val: "+val)

        this.state.numberOfEUHWafer = val
        this.calculateQuotation(this.state.ddlSelectedTech);
    }

    onMaskRespinOrderSelectionChanged = (e) => {
        console.log("e.target.value: "+e.target.value)
        this.setState({
            ddlMaskRespinOrder:e.target.value
        })
        if (e.target.value === 'Yes'){
            //document.getElementById('txtAddMaskNumber').disabled = false;
            document.getElementById('txtAddMaskNumber').removeAttribute('readonly');
            document.getElementById('txtAddMaskNumber').value = 0;
            this.state.isMaskRespinOrder = true;
        }else {
            //document.getElementById('txtAddMaskNumber').disabled = true;
            document.getElementById('txtAddMaskNumber').setAttribute('readonly','readonly');
            document.getElementById('txtAddMaskNumber').value = "";
            this.state.isMaskRespinOrder = false;
        }
        this.calculateQuotation(this.state.ddlSelectedTech);
    }

    onNumberOfAdditionMaskChange = (e) => {
        console.log("onNumberOfAdditionMaskChange: "+e.target.value)
        let val = this.convertToRoundNumber(e.target.value);
        val = e.target.value;
        if (val.length > 0 && this._isNumber(val)) {
            console.log("inside IF===========================")
            val = this.roundOff(val, 0);
            console.log("inside val: "+val)
            this.setState({
                [e.target.name]: val
            })
        }
        if (val < 0) {
            e.target.value = 0;
            val = 0;
        }
        this.state.numberOfAdditionalMask = val;
        this.calculateQuotation(this.state.ddlSelectedTech);
    }

    onExpiditeABSelectionChanged = (e) => {
        this.setState({
            ddlRitAbExpedite: e.target.value
        })
        this.state.isABExpidite = e.target.value.length  > 0 && e.target.value === 'Yes';
        console.log("onExpiditeABSelectionChanged: "+this.state.isABExpidite)
        this.calculateQuotation(this.state.ddlSelectedTech);
    }

    onExpiditeBSelectionChanged = (e) => {

        this.setState({
            ddlRitBExpedite: e.target.value
        })
        this.state.isBExpidite = e.target.value.length  > 0 && e.target.value === 'Yes';
        console.log("onExpiditeBSelectionChanged: "+this.state.isBExpidite)
        this.calculateQuotation(this.state.ddlSelectedTech);
    }

    numberOfProductionWaferChange = (e) => {
        console.log("numberOfProductionWaferChange: "+e.target.value)
        let val = this.convertToRoundNumber(e.target.value);
        val = e.target.value;
        if (val.length > 0 && this._isNumber(val)) {
            console.log("inside IF===========================")
            val = this.roundOff(val, 0);
            console.log("inside val: "+val)
            this.setState({
                [e.target.name]: val
            })
        }
        if (val < 0) {
            e.target.value = 0;
            val = 0;
        }

        if (val == "") {
            e.target.value = 0;
            val = 0;
        }
        console.log("VAL: "+val)
        this.setState({
            numberOfProductionWafer: val
        })
        this.state.numberOfProductionWafer = val
        this.calculateQuotation(this.state.ddlSelectedTech);
    }

    onFeatureItemSelectionChanged = (e, fsItem) => {
        console.log("value: "+e.target.value)
        this.state.featureRowSelectValue[fsItem["dedWaferAddonPricingId"]] = e.target.value
        this.setState({
            featureRowSelectValue: this.state.featureRowSelectValue
        })

        console.log("waferCharge: "+fsItem["waferCharge"])
        if (e.target.value === 'Yes') {
            console.log("INSIDE YES = IF")
            this.state.selectedWaferFeatureIdStr = this.state.selectedWaferFeatureIdStr.concat(fsItem.id+",")
            this.state.featurePriceSum += Number(fsItem["waferCharge"]);
            this.state.featureMaskCount += Number(fsItem["quoteMaskQuantity"]);
        } else {
            console.log("INSIDE SELECT = ELSE")
            this.state.selectedWaferFeatureIdStr = this.state.selectedWaferFeatureIdStr.replace(fsItem.id+",", "")
            this.state.featurePriceSum -= Number(fsItem["waferCharge"]);
            this.state.featureMaskCount -= Number(fsItem["quoteMaskQuantity"]);

        }
        console.log("this.state.featurePriceSum: "+this.state.featurePriceSum)
        console.log("this.state.featureMaskCount: "+this.state.featureMaskCount)
        this.calculateQuotation(this.state.ddlSelectedTech);
    }
    //=================================ONCHANGE FUNCTIONS - END==============================


    calculateQuotation(processName) {
        console.log("INSIDE calculateQuotation")
        console.log("ddlSelectedTech: "+processName)
        if (processName === "" || processName === "Technology Process") {
            this.setState({
                txtNre: 0,
                txtFeatureMaskPrice: 0,
                txtNreSubtotalVal: 0,

                txtBaseWaterPrice: 0,
                txtEnggFeatureWaferPrice: 0,
                txtEnggWaferPrice: 0,
                txtTotalEnggWaferPrice: 0,

                txtRespinNre: 0,
                txtMaskPrice: 0,

                txtRitABExpediteFee: 0,
                txtRitBExpediteFee: 0,

                txtProdBaseWaferPrice: 0,
                txtFeatureWaferPrice: 0,
                txtProdPricePerWafer: 0,
                txtTotalProdWaferPrice: 0,

                txtcustomQuoteSummaryVal: 0,
                txtTotalPrice: 0
            })
        }else {
            console.log("INSIDE ELSE"+processName+"1")
            const fsPrice_dedicated = this.state.foundryPriceList[processName+"1"];
            const fsPrice_Mask = this.state.foundryPriceList[processName+"2"];
            console.log("fsPrice: "+JSON.stringify(fsPrice_Mask))
            console.log("fsPrice: "+JSON.stringify(fsPrice_dedicated))
            console.log("this.state.isMaskRespinOrder: "+this.state.isMaskRespinOrder)
            //this.state.selectedFoundryPrice = this.state.isMaskRespinOrder ? fsPrice['Mask Respin'] : fsPrice['Dedicated'];
            this.state.selectedFoundryPrice = this.state.isMaskRespinOrder ? fsPrice_Mask['Mask Respin'] : fsPrice_dedicated['Dedicated'];
            console.log("selectedFoundryPrice: "+JSON.stringify(this.state.selectedFoundryPrice))
            if (!!this.state.selectedFoundryPrice) {
                console.log("INSIDE IF====")
                this.state.selectedFoundryPriceId = this.state.selectedFoundryPrice["foundryPricingId"];
                const nreMultiplier = this.state.selectedFoundryPrice["nreMultiplier"];
                console.log("nreMultiplier: "+nreMultiplier)
                let baseMarkupTemp = ''
                if(fsPrice_dedicated != null){
                    //let fsPriceDedicated = fsPrice["Dedicated"]
                    let fsPriceDedicated = fsPrice_dedicated['Dedicated'];
                    baseMarkupTemp = fsPriceDedicated["baseWaferMarkup"];
                }else{
                    baseMarkupTemp = 0;
                }
                const baseMarkup = baseMarkupTemp
                console.log("baseMarkup: "+baseMarkup)

                if (this.state.isMaskRespinOrder) {
                    this.state.txtNre = 0;
                    this.state.txtFeatureMaskPrice = 0;
                    this.state.txtNreSubtotalVal = 0;
                    this.state.txtRespinNre = this.state.numberOfAdditionalMask <= 2 ? this.state.selectedFoundryPrice.engChargeBelowMask : this.state.selectedFoundryPrice.engChargeAboveMask;
                    this.state.txtRespinNre = this.state.isGreaterThen150 ? this.state.txtRespinNre : this.state.txtRespinNre * nreMultiplier;
                    this.state.txtMaskPrice =  this.state.selectedFoundryPrice.pricePerMask * this.state.numberOfAdditionalMask;
                    this.state.txtMaskPrice = this.state.isGreaterThen150 ? this.state.txtMaskPrice : this.state.txtMaskPrice * nreMultiplier;
                } else {
                    this.state.txtRespinNre = 0;
                    this.state.txtMaskPrice = 0;
                    this.state.txtNre = this.state.isGreaterThen150 ? this.state.selectedFoundryPrice["nrePrice"] : this.state.selectedFoundryPrice["nrePrice"] * nreMultiplier;
                    console.log("nrePrice: "+this.state.selectedFoundryPrice["nrePrice"])
                    console.log("txtNre: "+this.state.txtNre)
                    this.state.txtFeatureMaskPrice = (this.state.featureMaskCount + this.state.customFeatureMaskCount) * this.state.selectedFoundryPrice["pricePerMask"];
                    this.state.txtFeatureMaskPrice = this.state.isGreaterThen150 ? this.state.txtFeatureMaskPrice : this.state.txtFeatureMaskPrice * nreMultiplier;
                    this.state.txtNreSubtotalVal = this.state.txtNre + this.state.txtFeatureMaskPrice;
                }


                console.log("waferPrice: "+JSON.stringify(this.state.waferPriceList))
                let waferPrice = this.state.waferPriceList[processName];
                console.log("waferPrice: "+JSON.stringify(waferPrice))

                waferPrice.sort(function (wp1, wp2) {
                    return wp1.minimumQuantityFcst - wp2.minimumQuantityFcst;
                });
                console.log("waferPrice: "+JSON.stringify(waferPrice))

                console.log("this.state.numberOfProductionWafer: "+this.state.numberOfProductionWafer)
                let that = this
                let curWaferPrice = this.state.numberOfProductionWafer === 0 ?
                    waferPrice[0] :
                    waferPrice.filter(function (wp) {
                        return wp.maximumQuantityFcst >= that.state.numberOfProductionWafer &&
                            wp.minimumQuantityFcst <= that.state.numberOfProductionWafer;
                    })[0];
                //let curWaferPrice = waferPrice
                console.log("curWaferPrice: "+JSON.stringify(curWaferPrice))

                //this.state.dedWaferPricingId = curWaferPrice["dedWaferPricingId"]

                this.state.txtTotalEnggWaferPrice = 0;
                if (!!curWaferPrice) {
                    console.log("INSIDE wafer price IF========================================================")
                    console.log("isGreaterThen150: "+this.state.isGreaterThen150)
                    console.log("featurePriceSum: "+this.state.featurePriceSum)
                    console.log("customFeaturePriceSum: "+this.state.customFeaturePriceSum)
                    console.log("txtBaseWaterPrice: "+this.state.txtBaseWaterPrice)
                    console.log("baseMarkup: "+baseMarkup)

                    this.state.txtBaseWaterPrice = curWaferPrice["waferPrice"];
                    this.state.txtBaseWaterPrice = this.state.isGreaterThen150 ? this.state.txtBaseWaterPrice : this.state.txtBaseWaterPrice * baseMarkup;
                    this.state.txtEnggFeatureWaferPrice = Number(this.state.featurePriceSum) + Number(this.state.customFeaturePriceSum);
                    this.state.txtEnggFeatureWaferPrice = this.state.isGreaterThen150 ? this.state.txtEnggFeatureWaferPrice : this.state.txtEnggFeatureWaferPrice * baseMarkup;

                    console.log("txtEnggFeatureWaferPrice: "+this.state.txtEnggFeatureWaferPrice)
                    console.log("numberOfEUHWafer: "+this.state.numberOfEUHWafer)
                    this.state.txtEnggWaferPrice = this.state.txtBaseWaterPrice + this.state.txtEnggFeatureWaferPrice;
                    console.log("txtEnggWaferPrice: "+this.state.txtEnggWaferPrice)
                    this.state.txtTotalEnggWaferPrice = this.state.txtEnggWaferPrice * this.state.numberOfEUHWafer;

                    console.log("txtTotalEnggWaferPrice: "+this.state.txtTotalEnggWaferPrice)
                }

                this.state.txtRitABExpediteFee = this.state.isABExpidite ? this.state.selectedFoundryPrice.ritAbExpediteCharge : 0;
                this.state.txtRitBExpediteFee =  this.state.isBExpidite ? this.state.selectedFoundryPrice.ritBExpediteCharge : 0;

                if (!!curWaferPrice) {
                    this.state.selectedWaferPriceId = curWaferPrice["dedWaferPricingId"];
                    this.state.txtProdBaseWaferPrice = curWaferPrice["waferPrice"];
                } else {
                    this.state.selectedWaferPriceId = 0;
                    this.state.txtProdBaseWaferPrice = 0;
                }
                this.state.txtFeatureWaferPrice = this.state.numberOfProductionWafer > 0 ? Number(this.state.featurePriceSum) + Number(this.state.customFeaturePriceSum) : 0;
                this.state.txtProdPricePerWafer = Number(this.state.txtProdBaseWaferPrice) + Number(this.state.txtFeatureWaferPrice);
                console.log("txtProdPricePerWafer: "+this.state.txtProdPricePerWafer)
                console.log("this.state.numberOfProductionWafer: "+this.state.numberOfProductionWafer)
                this.state.txtTotalProdWaferPrice = Number(this.state.txtProdPricePerWafer) * Number(this.state.numberOfProductionWafer);
            }
        }

        console.log("txtNreSubtotalVal: "+this.state.txtNreSubtotalVal +
            "txtTotalEnggWaferPrice: "+this.state.txtTotalEnggWaferPrice +
            "txtRespinNre: "+this.state.txtRespinNre +
            "txtMaskPrice: "+this.state.txtMaskPrice +
            "txtRitABExpediteFee: "+this.state.txtRitABExpediteFee +
            "txtRitBExpediteFee: "+this.state.txtRitBExpediteFee +
            "txtTotalProdWaferPrice: "+this.state.txtTotalProdWaferPrice +
            "txtcustomQuoteSummaryVal: "+this.state.txtcustomQuoteSummaryVal)

        this.state.txtTotalPrice = 0;
        this.state.txtTotalPrice = Number(this.state.txtNreSubtotalVal) + Number(this.state.txtTotalEnggWaferPrice) + Number(this.state.txtRespinNre) + Number(this.state.txtMaskPrice) +
            Number(this.state.txtRitABExpediteFee) + Number(this.state.txtRitBExpediteFee) + Number(this.state.txtTotalProdWaferPrice);
            //+ Number(this.state.txtcustomQuoteSummaryVal.replace('$', ''));

        console.log("Total Price: "+this.state.txtTotalPrice)
        this.updateQuotePricesOnPageUI();
    }

    addMosisMarkupValueToPrice = (price) => {
        console.log("this.state.mpriceFactor: "+this.state.mpriceFactor);
        return Math.round(price + ( price * (this.state.mpriceFactor)/100 ));
    }

    convertToCurrencyNumber = (value) => {
        var num = Math.round(Number(value.toString().replace(/[^0-9.-]+/g,"")));
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    updateQuotePricesOnPageUI = () => {

        this.setState({
            txtNre: '$'+this.convertToCurrencyNumber(this.addMosisMarkupValueToPrice(this.state.txtNre)),
            txtFeatureMaskPrice: '$'+this.convertToCurrencyNumber(this.addMosisMarkupValueToPrice(this.state.txtFeatureMaskPrice)),
            txtNreSubtotalVal: '$'+this.convertToCurrencyNumber(this.addMosisMarkupValueToPrice(this.state.txtNreSubtotalVal)),

            txtBaseWaterPrice: '$'+this.convertToCurrencyNumber(this.addMosisMarkupValueToPrice(this.state.txtBaseWaterPrice)),
            txtEnggFeatureWaferPrice: '$'+this.convertToCurrencyNumber(this.addMosisMarkupValueToPrice(this.state.txtEnggFeatureWaferPrice)),
            txtEnggWaferPrice: '$'+this.convertToCurrencyNumber(this.addMosisMarkupValueToPrice(this.state.txtEnggWaferPrice)),
            txtTotalEnggWaferPrice: '$'+this.convertToCurrencyNumber(this.addMosisMarkupValueToPrice(this.state.txtTotalEnggWaferPrice)),

            txtRespinNre: '$'+this.convertToCurrencyNumber(this.addMosisMarkupValueToPrice(this.state.txtRespinNre)),
            txtMaskPrice: '$'+this.convertToCurrencyNumber(this.addMosisMarkupValueToPrice(this.state.txtMaskPrice)),

            txtRitABExpediteFee: '$'+this.convertToCurrencyNumber(this.addMosisMarkupValueToPrice(this.state.txtRitABExpediteFee)),
            txtRitBExpediteFee: '$'+this.convertToCurrencyNumber(this.addMosisMarkupValueToPrice(this.state.txtRitBExpediteFee)),

            txtProdBaseWaferPrice: '$'+this.convertToCurrencyNumber(this.addMosisMarkupValueToPrice(this.state.txtProdBaseWaferPrice)),
            txtFeatureWaferPrice: '$'+this.convertToCurrencyNumber(this.addMosisMarkupValueToPrice(this.state.txtFeatureWaferPrice)),
            txtProdPricePerWafer: '$'+this.convertToCurrencyNumber(this.addMosisMarkupValueToPrice(this.state.txtProdPricePerWafer)),
            txtTotalProdWaferPrice: '$'+this.convertToCurrencyNumber(this.addMosisMarkupValueToPrice(this.state.txtTotalProdWaferPrice)),

            txtcustomQuoteSummaryVal: '$'+this.convertToCurrencyNumber(this.addMosisMarkupValueToPrice(this.state.txtcustomQuoteSummaryVal)),
            txtTotalPrice: '$'+this.convertToCurrencyNumber(this.addMosisMarkupValueToPrice(this.state.txtTotalPrice))

        })
    }
        /*if (selectedProcess === "" || selectedProcess === "Technology Process") {
                    nrePriceVal = 0;
                    featurMaskPriceVal = 0;
                    nreSubtotalVal = 0;

                    baseWaferPriceVal = 0;
                    engFeatureWaferPriceVal = 0;
                    engineeringWaferPriceVal = 0;
                    totalEngineeringWaferPriceVal = 0;

                    raspinNreFeeVal = 0;
                    maskPriceVal = 0;

                    isRitAbExpediteVal = 0;
                    isRitBExpediteVal = 0;

                    productionBaseWaferFeeVal = 0;
                    expFeatureWaferPriceVal = 0;
                    productionPricePerWaferVal = 0;
                    totalProductionWaferPriceVal = 0;

                    customQuoteSummaryVal = 0;
                    totalPriceVal = 0;
                } else {
            const fsPrice = foundryPriceList[selectedProcess];
            selectedFoundryPrice = isMaskRespinOrder ? fsPrice['Mask Respin'] : fsPrice['Dedicated'];
            if (!!selectedFoundryPrice) {
                selectedFoundryPriceId = selectedFoundryPrice.id;
                const nreMultiplier = selectedFoundryPrice.nreMultiplier;
                const baseMarkup = fsPrice['Dedicated'].baseWaferMarkup;

                if (isMaskRespinOrder) {
                    nrePriceVal = 0;
                    featurMaskPriceVal = 0;
                    nreSubtotalVal = 0;
                    raspinNreFeeVal = numberOfAdditionalMask <= 2 ? selectedFoundryPrice.engChargeBelowMask : selectedFoundryPrice.engChargeAboveMask;
                    raspinNreFeeVal = isGreaterThen150 ? raspinNreFeeVal : raspinNreFeeVal * nreMultiplier;
                    maskPriceVal =  selectedFoundryPrice.pricePerMask * numberOfAdditionalMask;
                    maskPriceVal = isGreaterThen150 ? maskPriceVal : maskPriceVal * nreMultiplier;
                } else {
                    raspinNreFeeVal = 0;
                    maskPriceVal = 0;
                    nrePriceVal = isGreaterThen150 ? selectedFoundryPrice.nrePrice : selectedFoundryPrice.nrePrice * nreMultiplier;
                    featurMaskPriceVal = (featureMaskCount + customFeatureMaskCount) * selectedFoundryPrice.pricePerMask;
                    featurMaskPriceVal = isGreaterThen150 ? featurMaskPriceVal : featurMaskPriceVal * nreMultiplier;
                    nreSubtotalVal = nrePriceVal + featurMaskPriceVal;
                }

                var waferPrice = waferPriceList[selectedProcess];
                waferPrice.sort(function (wp1, wp2) {
                    return wp1.minimumQuantityFcst - wp2.minimumQuantityFcst;
                });
                var curWaferPrice = numberOfProductionWafer === 0 ?
                    waferPrice[0] :
                    waferPrice.filter(function (wp) {
                        return wp.maximumQuantityFcst >= numberOfProductionWafer &&
                            wp.minimumQuantityFcst <= numberOfProductionWafer;
                    })[0];
                if (!!curWaferPrice) {
                    baseWaferPriceVal = curWaferPrice.waferPrice;
                    baseWaferPriceVal = isGreaterThen150 ? baseWaferPriceVal : baseWaferPriceVal * baseMarkup;
                    engFeatureWaferPriceVal = featurePriceSum + customFeaturePriceSum;
                    engFeatureWaferPriceVal = isGreaterThen150 ? engFeatureWaferPriceVal : engFeatureWaferPriceVal * baseMarkup;
                    engineeringWaferPriceVal = baseWaferPriceVal + engFeatureWaferPriceVal;
                    totalEngineeringWaferPriceVal = engineeringWaferPriceVal * numberOfEUHWafer;
                }

                isRitAbExpediteVal = isABExpidite ? selectedFoundryPrice.ritAbExpediteCharge : 0;
                isRitBExpediteVal =  isBExpidite ? selectedFoundryPrice.ritBExpediteCharge : 0;

                if (!!curWaferPrice) {
                    selectedWaferPriceId = curWaferPrice.id;
                    productionBaseWaferFeeVal = curWaferPrice.waferPrice;
                } else {
                    selectedWaferPriceId = 0;
                    productionBaseWaferFeeVal = 0;
                }
                expFeatureWaferPriceVal = numberOfProductionWafer > 0 ? featurePriceSum + customFeaturePriceSum : 0;
                productionPricePerWaferVal = productionBaseWaferFeeVal + expFeatureWaferPriceVal;
                totalProductionWaferPriceVal = productionPricePerWaferVal * numberOfProductionWafer;
            }
        }

        totalPriceVal = nreSubtotalVal + totalEngineeringWaferPriceVal + raspinNreFeeVal + maskPriceVal +
            isRitAbExpediteVal + isRitBExpediteVal + totalProductionWaferPriceVal + customQuoteSummaryVal;

        updateQuotePricesOnPageUI();
    }*/

    HandleSuccessGetCustomer = (res) => {
        this.setState({
            customerData: res.data,
            txtCustName: res.data.customerName
        })
        console.log("customerData: "+JSON.stringify(res.data.customerName))

    }

    highlightText = (e) =>{
        console.log("inside highlightText")
        $('#'+e.target.name).select();
    }

    onSubmit = (e, saveType) =>{


        let ddlSelectedTech = PrValidate.ForceSelect(this.state.ddlSelectedTech, 'ddlSelectedTech', 'a technology node');
        let txtDesignNumber = PrValidate.ForceEntry(this.state.txtDesignNumber, 'txtDesignNumber', "a design number");
        let txtCustName = PrValidate.ForceEntry(this.state.txtCustName, 'txtCustName', "a customer name");

        let err_arr = [txtCustName, ddlSelectedTech, txtDesignNumber];
        let itemToScroll = ["txtCustName", "ddlSelectedTech", "txtDesignNumber"];
        let chk_false = 0;

        let that = this;

        let breakCondition = false;

        err_arr.forEach(function (element, index) {
            if (!element && !breakCondition){
                chk_false++;
                console.log("itemToScrollIndex: " + itemToScroll[index])
                $('html, body').animate({
                    scrollTop: $("#" + itemToScroll[index]).offset().top - 100
                }, 1000);
                breakCondition = true
            }

            if ((err_arr.length - 1) === index) {
                if (chk_false === 0) {
                    console.log("INNER TEXT: "+document.getElementById("tncHeader-1").innerText)

                    /*let quoteTnc = []
                    for(let i=1; i<=8; i++){
                        let tncObject={}
                        tncObject["tcType"] = document.getElementById("tncHeader-"+i).innerText
                        tncObject["tcDetails"] = document.getElementById("tncLabel-"+i).innerText
                        console.log("tncObject: "+JSON.stringify(tncObject))

                        quoteTnc.push(tncObject)
                    }
                    console.log("quoteTnc: "+JSON.stringify(quoteTnc))*/

                    console.log("inside on submit")

                    let quotationNumber = that.state.txtQuoteNumber.split("-")[0]

                    var $list = $('#defaultFeatureSummaryTable .featureSummaryOptions');
                    console.log("$list.length: "+$list.length)

                    let mGfdedFeaturePricings = []
                    for(let i=0; i<$list.length; i++){
                        //featureSummaryOptions
                        if($list[i].tagName == 'SELECT') {
                            console.log($list[i].value)
                            if($list[i].value == "Yes"){
                                let item = {
                                    pricingId: Number($list[i].id.split("-")[1])
                                }
                                mGfdedFeaturePricings.push(item)
                            }
                        }
                    }
                    console.log("mGfdedFeaturePricings: "+JSON.stringify(mGfdedFeaturePricings))

                    console.log("that.state.txtTotalPrice: "+that.state.txtTotalPrice.replace(/,/g, '').replace(/\$/g, ''))
                    let dedicatedQuoteRevision = {
                        quotation: {quotationNumber: quotationNumber},
                        additionalMaskQuantity: that.state.txtAddMaskNumber == ""? null :  parseFloat(that.state.txtAddMaskNumber),
                        nrePrice: parseFloat(that.state.txtNre.replace(/,/g, '').replace(/\$/g, '')),
                        featurMaskPrice: parseFloat(that.state.txtFeatureMaskPrice.replace(/,/g, '').replace(/\$/g, '')),
                        nreSubtotal: parseFloat(that.state.txtNreSubtotalVal.replace(/,/g, '').replace(/\$/g, '')),
                        baseWaferPrice: parseFloat(that.state.txtBaseWaterPrice.replace(/,/g, '').replace(/\$/g, '')),
                        engFeatureWaferPrice: parseFloat(that.state.txtEnggFeatureWaferPrice.replace(/,/g, '').replace(/\$/g, '')),
                        engineeringWaferPrice: parseFloat(that.state.txtEnggWaferPrice.replace(/,/g, '').replace(/\$/g, '')),
                        totalEngineeringWaferPrice: parseFloat(that.state.txtTotalEnggWaferPrice.replace(/,/g, '').replace(/\$/g, '')),
                        raspinNreFee: parseFloat(that.state.txtRespinNre.replace(/,/g, '').replace(/\$/g, '')),
                        maskPrice: parseFloat(that.state.txtMaskPrice.replace(/,/g, '').replace(/\$/g, '')),
                        ritAbExpediteFees: parseFloat(that.state.txtRitABExpediteFee.replace(/,/g, '').replace(/\$/g, '')),
                        ritBExpediteFees: parseFloat(that.state.txtRitBExpediteFee.replace(/,/g, '').replace(/\$/g, '')),
                        productionBaseWaferFee: parseFloat(that.state.txtProdBaseWaferPrice.replace(/,/g, '').replace(/\$/g, '')),
                        expFeatureWaferPrice: parseFloat(that.state.txtFeatureWaferPrice.replace(/,/g, '').replace(/\$/g, '')),
                        productionPricePerWafer: parseFloat(that.state.txtProdPricePerWafer.replace(/,/g, '').replace(/\$/g, '')),
                        totalProductionWaferPrice: parseFloat(that.state.txtTotalProdWaferPrice.replace(/,/g, '').replace(/\$/g, '')),
                        customQuoteSummary: parseFloat(that.state.txtcustomQuoteSummaryVal.replace(/,/g, '').replace(/\$/g, '')),
                        totalPrice: parseFloat(that.state.txtTotalPrice.replace(/,/g, '').replace(/\$/g, '')),
                        technologyNode: that.state.ddlSelectedTech,
                        isMaskRespinOrder: that.state.ddlMaskRespinOrder == "" ? null : that.state.ddlMaskRespinOrder,
                        isRitAbExpedite: that.state.ddlRitAbExpedite == "" ? null : that.state.ddlRitAbExpedite,
                        isRitBExpedite: that.state.ddlRitBExpedite == "" ? null : that.state.ddlRitBExpedite,
                        waferForecast: that.state.ddlWaferForecastPerYear == "" ? null : that.state.ddlWaferForecastPerYear,
                        foundryPricingId: that.state.selectedFoundryPriceId,
                        dedWaferPricingId: that.state.selectedWaferPriceId,
                        emailAddress: that.state.txtEmail,
                        mosisMarkup: parseFloat(that.state.mpriceFactor.replace(/,/g, '').replace(/\$/g, '')),
                        designNumber: that.state.txtDesignNumber,
                        euhPrototypeWafer: that.state.txtEUHNumber == "" ? 0 : that.state.txtEUHNumber,
                        productionWaferQuantity: that.state.txtProdWafersNumber == "" ? 0 : parseFloat(that.state.txtProdWafersNumber.replace(/,/g, '').replace(/\$/g, '')),
                        revisionNumber: 0,
                        featurePricingList: mGfdedFeaturePricings
                    }
                    if(saveType == "saveEmail"){
                        console.log("INSIDE IF")
                        dedicatedQuoteRevision.quotation["sendEmail"] = true
                    }





                    that.state.dedicatedQuoteDataSubmitObject["dedicatedQuoteRevision"] = dedicatedQuoteRevision
                    //that.state.dedicatedQuoteDataSubmitObject["quotationTncList"] = quoteTnc


                    console.log("raspinNreFee: " + that.state.txtRespinNre)
                    console.log("maskPrice: " + that.state.txtMaskPrice)
                    console.log("ritAbExpediteFees: " + that.state.txtRitABExpediteFee)
                    console.log("ritBExpediteFees: " + that.state.txtRitBExpediteFee)
                    console.log("productionBaseWaferFee: " + parseFloat(that.state.txtProdBaseWaferPrice.replace(/,/g, '').replace(/\$/g, '')))
                    console.log("expFeatureWaferPrice: " + that.state.txtFeatureWaferPrice)
                    console.log("productionPricePerWafer: " + that.state.txtProdPricePerWafer)
                    console.log("totalProductionWaferPrice: " + that.state.txtTotalProdWaferPrice)


                    console.log("customQuoteSummary: " + that.state.txtcustomQuoteSummaryVal)
                    console.log("totalPrice: " + parseFloat(that.state.txtTotalPrice.replace(/,/g, '').replace(/\$/g, '')))
                    console.log("technologyNode: " + that.state.ddlSelectedTech)
                    console.log("isMaskRespinOrder: " + that.state.ddlMaskRespinOrder)
                    console.log("isRitAbExpedite: " + that.state.ddlRitAbExpedite)
                    console.log("isRitBExpedite: " + that.state.ddlRitBExpedite)

                    console.log("waferForecast: " + that.state.ddlWaferForecastPerYear)
                    console.log("foundryPricingId: " + that.state.selectedFoundryPriceId)
                    console.log("dedWaferPricingId: " + that.state.selectedWaferPriceId)
                    console.log("emailAddress: " + that.state.txtEmail)
                    console.log("mosisMarkup: " + that.state.mpriceFactor)
                    console.log("designNumber: " + that.state.txtDesignNumber)

                    console.log("euhPrototypeWafer: " + that.state.txtEUHNumber)
                    console.log("productionWaferQuantity: " + that.state.txtProdWafersNumber)

                    console.log("that.state.dedicatedQuoteDataSubmitObject: " + JSON.stringify(that.state.dedicatedQuoteDataSubmitObject))





                    that.setState({
                        saveDedicatedQuoteData: true,
                        dedicatedQuoteDataSubmitObject: that.state.dedicatedQuoteDataSubmitObject
                    })


                }
            }
        })

       /*
        err_arr.forEach(function (element, index) {

            if (!element && !breakCondition) {
                console.log("itemToScrollIndex: " + itemToScroll[index])
                $('html, body').animate({
                    scrollTop: $("#" + itemToScroll[index]).offset().top - 100
                }, 1000);
                breakCondition = true;
            }

        })*/

        /*this.state.dedicatedQuoteDataSubmitObject = {
            foundry: that.state.ddlFoundry,
            techNode: that.state.ddlTechNode,
            userForFabricationDesignCheck: that.state.ddlUserForFabricationDesignCheck,
            expectedDesignSubmissionDate: that.state.expectedDesignSubmissionDate,
            expectedDieQuantity: (that.state.txtExpectedDieQuantity).replace(/[^0-9]/g, ''),
            earlyAccessReason: that.state.txtEarlyPdkReason,
            fileType: that.state.ddlRequestedFileTypes
        }
        that.setState({
            postDesignData: true
        })*/
        /*let ddlTechNode = PrValidate.ForceSelect(this.state.ddlTechNode, 'ddlTechNode', 'a technology node');
        let ddlFoundry = PrValidate.ForceSelect(this.state.ddlFoundry, 'ddlFoundry', 'a foundry');
        let ddlUserForFabricationDesignCheck = PrValidate.ForceSelect(this.state.ddlUserForFabricationDesignCheck, 'ddlUserForFabricationDesignCheck', 'a Fabrication Design Check');
        let expectedDesignSubmissionDate = PrValidate.ForceDate(this.state.expectedDesignSubmissionDate, 'expectedDesignSubmissionDate', "Expected Design Submission Date");
        let txtExpectedDieQuantity = PrValidate.ForceRNumber((this.state.txtExpectedDieQuantity).replace(/[^0-9]/g, ''), 'txtExpectedDieQuantity');
        let txtEarlyPdkReason = PrValidate.ForceTextArea(this.state.txtEarlyPdkReason, 'txtEarlyPdkReason', 'Early Pdk Reason');

        let ddlRequestedFileTypes = PrValidate.ForceSelect(this.state.ddlRequestedFileTypes, 'ddlRequestedFileTypes', 'a Fabrication Design Check');


        let err_arr = [ddlTechNode, ddlFoundry, ddlUserForFabricationDesignCheck, expectedDesignSubmissionDate, txtExpectedDieQuantity, txtEarlyPdkReason, ddlRequestedFileTypes];
        let chk_false = 0;

        let that = this;

        err_arr.forEach(function (element, index) {
            if (!element)
                chk_false++;
            if ((err_arr.length - 1) === index) {
                if (chk_false === 0) {
                    that.design_data = {
                        foundry: that.state.ddlFoundry,
                        techNode: that.state.ddlTechNode,
                        userForFabricationDesignCheck: that.state.ddlUserForFabricationDesignCheck,
                        expectedDesignSubmissionDate: that.state.expectedDesignSubmissionDate,
                        expectedDieQuantity: (that.state.txtExpectedDieQuantity).replace(/[^0-9]/g, ''),
                        earlyAccessReason: that.state.txtEarlyPdkReason,
                        fileType: that.state.ddlRequestedFileTypes
                    }
                    that.setState({
                        postDesignData: true
                    })
                }
            }
        });*/
    }

    render() {
        return (
            <div>
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_CENTER} />
                {/*{this.state.redirectToMainPage ?
                    <DashboardCtrl {...this.props} state={this.state}/>:''
                }*/}
                {/*this.state.fetchDedicatedQuoteData == false &&*/}
                { this.state.redirectToMainPage == false?
                    <QuoteDedicatedComponent {...this.props} state={this.state}
                                             HandleChange={this.HandleChange} dedicatedQuoteData={this.state.dedicatedQuoteData} featureTableContent={this.state.featureTableContent}
                                             HandleTechnologyChange={this.HandleTechnologyChange} onMosisMarkupValueChanged={this.onMosisMarkupValueChanged} inputValueChanged={this.inputValueChanged}
                                             onWaferForecastSelectionChanged={this.onWaferForecastSelectionChanged} onNumberOfEUHWaferChange={this.onNumberOfEUHWaferChange}
                                             onMaskRespinOrderSelectionChanged={this.onMaskRespinOrderSelectionChanged} onNumberOfAdditionMaskChange={this.onNumberOfAdditionMaskChange}
                                             onExpiditeABSelectionChanged={this.onExpiditeABSelectionChanged} onExpiditeBSelectionChanged={this.onExpiditeBSelectionChanged}
                                             numberOfProductionWaferChange={this.numberOfProductionWaferChange} onFeatureItemSelectionChanged={this.onFeatureItemSelectionChanged}
                                             onSubmit={this.onSubmit} highlightText={this.highlightText}/>:''
                }
                {this.state.fetchDedicatedQuoteData === true ? getInitData(this.props.PrStore, this.HandleSuccessGetInitDataResponse) : ''}
                {this.state.saveDedicatedQuoteData === true ? submitDedicatedQuoteData(this.props.PrStore, this.state.dedicatedQuoteDataSubmitObject, this.HandleSuccessSaveDedicatedQuoteData):''}
                {/*{this.state.custData === true ? getCustomerData(this.props.PrStore, this.HandleSuccessGetCustomer, 254) : ''}*/}
            </div>


        )
    }
}

export const QuoteDedicatedCtrl = PrConnect(quoteDedicatedCtrl, '');