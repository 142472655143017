import React, { Component } from 'react';
import $ from 'jquery';
import { Document, Page, pdfjs } from "react-pdf";
//import spieFile from "../../../../assets/docs/PressReleaseDocs/116140J.pdf"
import closeImg from "../../../../assets/images/close.svg";
import backImg from "../../../../assets/images/back.svg";
import nextImg from "../../../../assets/images/next.svg"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default class PdfViewer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            numPages: null,
            setNumPages: null,
            pageNumber: 1,
            setPageNumber: 1,
        }



    }
    ClearMouse = () => {
    $('.inner_table').css('display', 'none');
}


    onDocumentLoadSuccess = ( numPages ) => {
        console.log(numPages);
        console.log("numPages._pdfInfo.numPages: "+numPages._pdfInfo.numPages)
        this.state.numPages = numPages._pdfInfo.numPages;
        console.log("this.props.state.pdfFileToShow: "+this.props.state.pdfFileToShow)
        console.log("this.props.state.windowHeight: "+this.props.state.windowHeight)
        console.log("this.props.state.windowWidth: "+this.props.state.windowWidth)
    };


    nextPage = () => {
        console.log("INSIDE nextPage")
        if (this.state.pageNumber < this.state.numPages) {
            this.state.pageNumber = this.state.pageNumber + 1;
        }

        this.setState({
            pageNumber: this.state.pageNumber
        })
        console.log("this.state.pageNumber: "+this.state.pageNumber)
    };

    prevPage = () => {
        console.log("INSIDE prevPage")
        if (this.state.pageNumber > 1) {
            this.state.pageNumber = this.state.pageNumber - 1;
        }

        this.setState({
            pageNumber: this.state.pageNumber
        })
    };

    closePopup= () => {
        this.props.componentprops.ShowHideModal();
    }

    /*ShowHideModal = () => {
        //$(".popupTop").parent('div').addClass('popupbody');
        console.log("INSIDE ShowHideModal "+this.state.showPdfFile)
        this.setState({
            showPdfFile: !this.state.showPdfFile
        });
        //$(".popupTop").parent('div').addClass('popupbody');
    }*/
render() {
    const x = -50;
    const y = -50;
    const translateStyles = {
        transform: `translate(${x}%, ${y}%)`,
        position: "fixed",
        top: "50%",
        left: "50%",
    };
    return (
        <div id="page-wrapper1" style={translateStyles}>

            <div id="page-inner1" style={{ background: '#fff' }}>

                <div className="controls" style={{display: "flex", backgroundColor: "#990100"}}>

                        <button onClick={this.closePopup} style={{ backgroundColor: "#990100", border:"none"}}>
                            <img src={closeImg} alt="logo"  width= "30px !important" height="30px !important" style={{ backgroundColor: "#990100"}}/>
                        </button>
                    <div style={{ background: '#fff', marginLeft: "auto",
                        marginRight: "auto",
                        paddingRight: "5%" , backgroundColor: "#990100"}}>
                        <button onClick={this.prevPage} disabled={this.state.pageNumber === 1} style={{backgroundColor: "#F3B93F"}}>
                            <img src={backImg} alt="logo" width= "24px !important" height="24px !important"/>
                        </button>
                        <button onClick={this.nextPage} disabled={this.state.pageNumber === this.state.numPages} style={{backgroundColor: "#F3B93F"}}>
                            <img src={nextImg} alt="logo" width= "24px !important" height="24px !important"/>
                        </button>
                    </div>
                </div>
               {/* <div style={{marginTop: "-3%"}} className="page_head col-lg-20">*/}
                <div className="page_head col-lg-20">
                    <Document file={this.props.state.pdfFileToShow}
                              onLoadSuccess={this.onDocumentLoadSuccess}
                              onContextMenu={(e) => e.preventDefault()}>
                        <Page height={this.props.state.windowHeight!=''?this.props.state.windowHeight:'670'}
                              width={this.props.state.windowWidth!=''?this.props.state.windowWidth:'670'} pageNumber={this.state.pageNumber} />


                    </Document>
                </div>
            </div>
        </div>
)
}
}