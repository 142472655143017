import React, { Component } from 'react';
import { withAuth } from '@okta/okta-react';

// Basic component with logout button
export default class LogoutComponent extends Component {
    constructor(props) {
        super(props);
        this.props.auth.logout('/');

        // this.logout = this.logout.bind(this);
    }


    render() {
        return (
            <a onClick={this.login}>Logout</a>
    );
    }
}

// withAuth() makes Okta "Auth" object available as "this.props.auth"
LogoutComponent = withAuth(LogoutComponent);