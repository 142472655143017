import React, { Component } from 'react';
import DatePicker from 'react-date-picker';
export default class ExportQuestionireInputComponent extends Component {
    render() {
        return (
            <div>
                <div id="page-wrapper">
                    <div id="page-inner" style={{ background: '#fff' }}>
                        <div className="page_head col-xl-10 col-md-11 mx-auto col-xl-8 ">
                            <h1 className="page-header"> MOSIS Export Control</h1>
                            <div className="account">
                                <div className="accoutn_form">
                                    <form action method>
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section border-none">
                                                            <div className="row">
                                                                <div className="col-lg-5 col-md-5 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Customer</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-7 col-sm-6" style={{marginLeft: "-20%"}}>
                                                                    <div className="inner_right">
                                                                        <div className="form-group">
                                                                            <input type="text" className="form-control" id="txtCustName" name="txtCustName"
                                                                                   value={this.props.state.txtCustName} onChange={this.props.HandleChange} disabled={true}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="account">
                                <div className="header">
                                    <h3>Mailing Address</h3>
                                </div>
                                <div className="accoutn_form">
                                    <form action method>
                                        <table className="adding_table_row" style={{ width: '100%' }}>
                                            <tbody><tr>
                                                <th>Address Line 1</th>
                                                <th>Address Line 2</th>
                                                <th>City</th>
                                                <th>State/Province</th>
                                                <th>Zip Code</th>
                                                <th>Country</th>
                                            </tr>
                                            <tr>
                                                <td className="bg-darkgray" style={{ paddingLeft: '10px' }}>
                                                    <div className="form-group">
                                                        <input type="text" className="form-control" id="txtAddr1" name="txtAddr1" value={this.props.state.txtAddr1} onChange={this.props.HandleChange} />
                                                    </div>
                                                </td>
                                                <td className="bg-darkgray">
                                                    <div className="form-group">
                                                        <input type="text" className="form-control" id="txtAddr2" name="txtAddr2" value={this.props.state.txtAddr2} onChange={this.props.HandleChange} />
                                                    </div>
                                                </td>
                                                <td className="bg-darkgray">
                                                    <div className="form-group">
                                                        <input type="text" className="form-control" id="txtAddrCity" name="txtAddrCity" value={this.props.state.txtAddrCity} onChange={this.props.HandleChange} />
                                                    </div>
                                                </td>
                                                <td className="bg-darkgray">
                                                    <div className="form-group">
                                                        <input type="text" className="form-control" id="txtAddrState" name="txtAddrState" value={this.props.state.txtAddrState} onChange={this.props.HandleChange} />
                                                    </div>
                                                </td>
                                                <td className="bg-darkgray">
                                                    <div className="form-group">
                                                        <input type="text" className="form-control" id="txtAddrZip" name="txtAddrZip" value={this.props.state.txtAddrZip} onChange={this.props.HandleChange} />
                                                    </div>
                                                </td>
                                                <td className="bg-darkgray">
                                                    <div className="form-group">
                                                        <input type="text" className="form-control" id="txtAddrCountry" name="txtAddrCountry" value={this.props.state.txtAddrCountry} onChange={this.props.HandleChange} />
                                                    </div>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </form>
                                </div>
                            </div>
                            <div className="account">
                                <div className="header">
                                    <h3>This Export Control Questionnaire is being completed for the following MOSIS design number(s)</h3>
                                </div>
                                <div className="accoutn_form">
                                    <form action method>
                                        <table className="adding_table_row" width="100%">
                                            {/*style={{borderCollapse: "separate"}}*/}
                                            <tbody>
                                            <tr>
                                                <th>Other Items</th>
                                                <th>
                                                    <span className="float-right" onClick={e => this.props.AddDesignRow(e)}><img src={require('../../../assets/img/plus-rd.svg')} alt="plus logo" /></span>
                                                    {/* <span className="clr-yellow">Add</span> */}
                                                </th>
                                            </tr>
                                            {this.props.state.designRows.map((data, index) => (
                                                    <tr>
                                                        <td className="col-sm-8 col-md-4">
                                                            <div className="form-group new_pos" style={{ textAlign: 'center' }}>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id={"txtDesignNumber_"+index}
                                                                    name={"txtDesignNumber_"+index}
                                                                    value={data.txtDesignNumber}
                                                                    placeholder="Other Items"
                                                                    onChange={e =>
                                                                        this.props.HandleDesignNumberChange(
                                                                            e,
                                                                            index,
                                                                            "txtDesignNumber"
                                                                        )
                                                                    }
                                                                    onBlur={() => this.props.HandleOnBlur('ForceEntry', "txtDesignNumber_"+index, data.txtDesignNumber  , 'Input')}
                                                                />
                                                                
                                                                
                                                                {/* <input type="text" className="form-control" name="name" /> */}
                                                            </div>


                                                        </td>
                                                        <td className="" style={{ paddingRight: '10px' }}>
                                                            <div className="form-group">
                                                                <span onClick={e => this.props.DeleteDesignRow(e, index)}>
                                                                    <img src={require('../../../assets/img/delete.svg')} alt="delete logo" />
                                                                </span>
                                                                {/* <span className="clr-rd"> Delete</span> */}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                            </tbody>
                                        <tbody id="designNumberDropDownTable">
                                            <tr>
                                                <th>Design Number</th>
                                                <th>
                                                    {/*<span className="float-right" onClick={e => this.props.AddDesignRow(e)}><img src={require('../../../assets/img/plus-rd.svg')} alt="plus logo" /></span>*/}
                                                     {/*<span className="clr-yellow">Add</span>*/}
                                                </th>
                                            </tr>
                                            <tr>
                                                <td className="col-sm-8 col-md-4">
                                            <div className="col-lg-8 col-md-8 col-sm-6">
                                                <div className="inner_right">
                                                    <div className="form-group">
                                                        <div className="custom_select">
                                                            <select onChange={this.props.HandleDesignNumberDropDownChange} name="selectedDesignNumber" id="selectedDesignNumber" value={this.props.state.selectedDesignNumber} >
                                                                <option value=''>Select</option>
                                                                {(JSON.stringify(this.props.state.foundryAndTechClass) !== '{}' && this.props.state.foundryAndTechClass.length > 0) ? this.props.state.foundryAndTechClass.map(item =>
                                                                    <option value={item["designNumber"]}>{item["designNumber"]}</option>
                                                                ) : ''}
                                                            </select>
                                                        </div>
                                                        {this.props.state.designNumberListDisabled == true?
                                                            <div><label style={{fontStyle: "italic", fontSize: "10px", position: "absolute", bottom: "-10px", color: "red"}}>No Design Numbers Found</label></div>:''
                                                        }
                                                    </div>

                                                </div>

                                            </div>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </form>
                                </div>
                            </div>
                            <div className="account">
                                <div className="header">
                                    <h3>Shipping Address</h3>
                                </div>
                                <div className="accoutn_form">
                                    <form action method>
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section border-none">
                                                            <div className="row">
                                                                <div className="col-lg-5 col-md-5 col-sm-6 mt-1">
                                                                    <div className="inner_left">
                                                                        {/*<label>Is the shipping address different than the mailing address listed above ?</label>*/}
                                                                        <label>{this.props.state.shippingAddressQuestion["question"]}</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3 col-md-3 col-sm-6 mt-1">
                                                                    <div className="inner_right">
                                                                        <div className="form-group">
                                                                            <div className="custom_select">

                                                                                {this.props.state.shippingAddressQuestion["question"] != null?
                                                                                    <select id="ddlShippingAddr"
                                                                                            name="ddlShippingAddr"
                                                                                            value={this.props.state.ddlShippingAddr} onChange={this.props.HandleChange}
                                                                                            onBlur={() => this.props.HandleOnBlur('ForceSelect', "ddlShippingAddr", this.props.state.ddlShippingAddr, 'Input')}>
                                                                                        <option value="">Select</option>
                                                                                        {this.props.state.shippingAddressQuestion["ecAnswerSets"].map((item, index) => (
                                                                                            <option value={item["answerId"]}>{item["answerValue"]}</option>
                                                                                        ))}
                                                                                    </select>:''
                                                                                }
                                                                                {/*<select id="ddlShippingAddr" name="ddlShippingAddr" value={this.props.state.ddlShippingAddr} onChange={this.props.HandleChange}>
                                                                                    <option value="">Select</option>
                                                                                    <option value="98">Yes</option>
                                                                                    <option value="99">No</option>
                                                                                </select>*/}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                {this.props.state.answerIdHashMap[this.props.state.ddlShippingAddr] === "Yes" ?
                                    <div className="accoutn_form" id="show_hide_div">
                                        <form action method>
                                            <div>
                                                <table className="adding_table_row" style={{ width: '100%' }}>
                                                    <tbody>
                                                    <tr className="row mt-1">
                                                        <th className="col">Design Number</th>
                                                        <th className="col">Corporate Name</th>
                                                        <th className="col">Address Line 1</th>
                                                        <th className="col">Address Line 2</th>
                                                        <th className="col">City </th>
                                                        <th className="col">State / Province</th>
                                                        <th className="col"> Zip Code / Postal Code</th>
                                                        <th className="col">Country</th>
                                                    </tr>
                                                    {this.props.state.shipAddrrows.map((data, index) => (
                                                            <tr className="row mt-1" id={"shipAddrr_"+index}>
                                                                <td className="bg-darkgray col">
                                                                    <div className="form-group new_pos">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"

                                                                            id={"txtShipDesignNumber_"+index}
                                                                            name={"txtShipDesignNumber_"+index}
                                                                            value={this.props.state.designRows[index]===undefined?
                                                                                this.props.state.designDropDownRows[index-(Number(this.props.state.designRows.length)-1)]["txtDesignNumber"]:this.props.state.designRows[index]["txtDesignNumber"]}
                                                                            placeholder={this.props.state.designRows[index]===undefined?"Design Number":"Other Items"}
                                                                            onChange={e =>
                                                                                this.props.HandleShipAddrRowsChange(
                                                                                    e,
                                                                                    index,
                                                                                    "txtShipDesignNumber"
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="col">
                                                                    <div className="form-group new_pos">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"

                                                                            id={"txtShipCorpName_"+index}
                                                                            name={"txtShipCorpName_"+index}
                                                                            value={data.txtShipCorpName}
                                                                            placeholder="Corporate Name"
                                                                            onChange={e =>
                                                                                this.props.HandleShipAddrRowsChange(
                                                                                    e,
                                                                                    index,
                                                                                    "txtShipCorpName"
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="col">
                                                                    <div className="form-group new_pos">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"

                                                                            id={"txtShipAddr1_"+index}
                                                                            name={"txtShipAddr1_"+index}
                                                                            value={data.txtShipAddr1}
                                                                            placeholder="Address Line 1"
                                                                            onChange={e =>
                                                                                this.props.HandleShipAddrRowsChange(
                                                                                    e,
                                                                                    index,
                                                                                    "txtShipAddr1"
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="col">
                                                                    <div className="form-group new_pos">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"

                                                                            id={"txtShipAddr2_"+index}
                                                                            name={"txtShipAddr2_"+index}
                                                                            value={data.txtShipAddr2}
                                                                            placeholder="Address Line 2"
                                                                            onChange={e =>
                                                                                this.props.HandleShipAddrRowsChange(
                                                                                    e,
                                                                                    index,
                                                                                    "txtShipAddr2"
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="col">
                                                                    <div className="form-group new_pos">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"

                                                                            id={"txtShipCity_"+index}
                                                                            name={"txtShipCity_"+index}
                                                                            value={data.txtShipCity}
                                                                            placeholder="City"
                                                                            onChange={e =>
                                                                                this.props.HandleShipAddrRowsChange(
                                                                                    e,
                                                                                    index,
                                                                                    "txtShipCity"
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="col">
                                                                    <div className="form-group new_pos">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"

                                                                            id={"txtShipState_"+index}
                                                                            name={"txtShipState_"+index}
                                                                            value={data.txtShipState}
                                                                            placeholder="State"
                                                                            onChange={e =>
                                                                                this.props.HandleShipAddrRowsChange(
                                                                                    e,
                                                                                    index,
                                                                                    "txtShipState"
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="col">
                                                                    <div className="form-group new_pos">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"

                                                                            id={"txtShipZip_"+index}
                                                                            name={"txtShipZip_"+index}
                                                                            value={data.txtShipZip}
                                                                            placeholder="Zip"
                                                                            onChange={e =>
                                                                                this.props.HandleShipAddrRowsChange(
                                                                                    e,
                                                                                    index,
                                                                                    "txtShipZip"
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="col">
                                                                    <div className="form-group new_pos">
                                                                        <div className="custom_select">
                                                                            <select

                                                                                id={"txtShipCountry_"+index}
                                                                                name={"txtShipCountry_"+index}
                                                                                value={data.ddlShipCountry}
                                                                                onChange={e =>
                                                                                    this.props.HandleShipAddrRowsChange(
                                                                                        e,
                                                                                        index,
                                                                                        "ddlShipCountry"
                                                                                    )
                                                                                }
                                                                            >
                                                                                <option value="">Select</option>
                                                                                {this.props.state.countrieslist.length > 0 && this.props.state.countrieslist.map((sel, selindex) => (
                                                                                        <option key={`selShip-${index}-${selindex}`} value={sel.codeId} >{sel.codeName}</option>
                                                                                    )
                                                                                )}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                    <tr className="row mt-1">
                                                        {/* <td className="col text-left mx-auto">
                                                                <button className="btn_copy_from_customer">Copy address from Customer</button>
                                                            </td> */}
                                                        {this.props.state.designRows.length > 1 ?
                                                            <td className="col text-right mx-auto">
                                                                <button className="btn_copy_from_first_row" type="button" onClick={() => this.props.CopyFromFirstRow('ship')}>Copy address from first row</button>
                                                            </td>
                                                            : ''}
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </form>
                                    </div>
                                    : ''
                                }
                            </div>
                            <div className="account">
                                <div className="header">
                                    <h3>Design Company Identification</h3>
                                </div>
                                <div className="accoutn_form">
                                    <form action method>
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section border-none">
                                                            <div className="row">
                                                                <div className="col-lg-5 col-md-5 col-sm-6 mt-1">
                                                                    <div className="inner_left">
                                                                        {/*<label>Is the Designer Corporation a different entity ?</label>*/}
                                                                        <label>{this.props.state.designerCorporationQuestion["question"]}</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3 col-md-3 col-sm-6 mt-1">
                                                                    <div className="inner_right">
                                                                        <div className="form-group new_pos">
                                                                            <div className="custom_select">

                                                                                {this.props.state.designerCorporationQuestion["question"] != null?
                                                                                    <select id="ddlDesignCompIdentity"
                                                                                            name="ddlDesignCompIdentity"
                                                                                            value={this.props.state.ddlDesignCompIdentity} onChange={this.props.HandleChange}>
                                                                                        <option value="">Select</option>
                                                                                        {this.props.state.designerCorporationQuestion["ecAnswerSets"].map((item, index) => (
                                                                                            <option value={item["answerId"]}>{item["answerValue"]}</option>
                                                                                        ))}
                                                                                    </select>:''
                                                                                }
                                                                                {/*<select id="ddlDesignCompIdentity" name="ddlDesignCompIdentity" value={this.props.state.ddlDesignCompIdentity} onChange={this.props.HandleChange}>
                                                                                    <option value="">Select</option>
                                                                                    <option value="102">Yes</option>
                                                                                    <option value="103">No</option>
                                                                                </select>*/}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                {this.props.state.answerIdHashMap[this.props.state.ddlDesignCompIdentity] === "Yes" ?
                                    <div className="accoutn_form" id="show_hide_div">
                                        <form action method>
                                            <div>
                                                <table className="adding_table_row" style={{ width: '100%' }}>
                                                    <tbody>
                                                    <tr className="row mt-1">
                                                        <th className="col">Design Number</th>
                                                        <th className="col">Corporate Name</th>
                                                        <th className="col">Address Line 1</th>
                                                        <th className="col">Address Line 2</th>
                                                        <th className="col">City </th>
                                                        <th className="col">State / Province</th>
                                                        <th className="col"> Zip Code / Postal Code</th>
                                                        <th className="col">Country</th>
                                                    </tr>
                                                    {this.props.state.designCompIdenti.map((data, index) => (
                                                            <tr className="row mt-1" id={"designCompIdenti_"+index}>
                                                                <td className="bg-darkgray col">
                                                                    <div className="form-group new_pos">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"

                                                                            id={"txtDesignCompDesignNumber_"+index}
                                                                            name={"txtDesignCompDesignNumber_"+index}
                                                                            //value={this.props.state.designRows[index]["txtDesignNumber"]}
                                                                            value={this.props.state.designRows[index]===undefined?
                                                                                this.props.state.designDropDownRows[index-(Number(this.props.state.designRows.length)-1)]["txtDesignNumber"]:this.props.state.designRows[index]["txtDesignNumber"]}
                                                                            placeholder={this.props.state.designRows[index]===undefined?"Design Number":"Other Items"}
                                                                            onChange={e =>
                                                                                this.props.HandleDesignCompRowsChange(
                                                                                    e,
                                                                                    index,
                                                                                    "txtDesignCompDesignNumber"
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="col">
                                                                    <div className="form-group new_pos">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"

                                                                            id={"txtDesignCompCorpName_"+index}
                                                                            name={"txtDesignCompCorpName_"+index}
                                                                            value={data.txtDesignCompCorpName}
                                                                            placeholder="Corporate Name"
                                                                            onChange={e =>
                                                                                this.props.HandleDesignCompRowsChange(
                                                                                    e,
                                                                                    index,
                                                                                    "txtDesignCompCorpName"
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="col">
                                                                    <div className="form-group new_pos">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"

                                                                            id={"txtDesignCompAddr1_"+index}
                                                                            name={"txtDesignCompAddr1_"+index}
                                                                            value={data.txtDesignCompAddr1}
                                                                            placeholder="Address Line 1"
                                                                            onChange={e =>
                                                                                this.props.HandleDesignCompRowsChange(
                                                                                    e,
                                                                                    index,
                                                                                    "txtDesignCompAddr1"
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="col">
                                                                    <div className="form-group new_pos">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"

                                                                            id={"txtDesignCompAddr2_"+index}
                                                                            name={"txtDesignCompAddr2_"+index}
                                                                            value={data.txtDesignCompAddr2}
                                                                            placeholder="Address Line 2"
                                                                            onChange={e =>
                                                                                this.props.HandleDesignCompRowsChange(
                                                                                    e,
                                                                                    index,
                                                                                    "txtDesignCompAddr2"
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="col">
                                                                    <div className="form-group new_pos">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"

                                                                            id={"txtDesignCompCity_"+index}
                                                                            name={"txtDesignCompCity_"+index}
                                                                            value={data.txtDesignCompCity}
                                                                            placeholder="City"
                                                                            onChange={e =>
                                                                                this.props.HandleDesignCompRowsChange(
                                                                                    e,
                                                                                    index,
                                                                                    "txtDesignCompCity"
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="col">
                                                                    <div className="form-group new_pos">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"

                                                                            id={"txtDesignCompState_"+index}
                                                                            name={"txtDesignCompState_"+index}
                                                                            value={data.txtDesignCompState}
                                                                            placeholder="State"
                                                                            onChange={e =>
                                                                                this.props.HandleDesignCompRowsChange(
                                                                                    e,
                                                                                    index,
                                                                                    "txtDesignCompState"
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="col">
                                                                    <div className="form-group new_pos">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"

                                                                            id={"txtDesignCompZip_"+index}
                                                                            name={"txtDesignCompZip_"+index}
                                                                            value={data.txtDesignCompZip}
                                                                            placeholder="Zip"
                                                                            onChange={e =>
                                                                                this.props.HandleDesignCompRowsChange(
                                                                                    e,
                                                                                    index,
                                                                                    "txtDesignCompZip"
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="col">
                                                                    <div className="form-group new_pos">
                                                                        <div className="custom_select">
                                                                            <select

                                                                                id={"txtDesignCompCountry_"+index}
                                                                                name={"txtDesignCompCountry_"+index}
                                                                                value={data.ddlDesignCompCountry}
                                                                                onChange={e =>
                                                                                    this.props.HandleDesignCompRowsChange(
                                                                                        e,
                                                                                        index,
                                                                                        "ddlDesignCompCountry"
                                                                                    )
                                                                                }
                                                                            >
                                                                                <option value="">Select</option>
                                                                                {this.props.state.countrieslist.length > 0 && this.props.state.countrieslist.map((sel, selindex) => (
                                                                                        <option key={`selDesign-${index}-${selindex}`} value={sel.codeId} >{sel.codeName}</option>
                                                                                    )
                                                                                )}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                    <tr className="row mt-1">
                                                        {/* <td className="col text-left mx-auto">
                                                                <button className="btn_copy_from_customer">Copy address from Customer</button>
                                                            </td> */}
                                                        {this.props.state.designCompIdenti.length > 1 ?
                                                            <td className="col text-right mx-auto">
                                                                <button className="btn_copy_from_first_row" type="button" onClick={() => this.props.CopyFromFirstRow('design')}>Copy address from first row</button>
                                                            </td>
                                                            : ''}
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </form>
                                    </div>
                                    : ''}
                            </div>
                            <div className="account">
                                <div className="header">
                                    <h3>Paying Corporation &nbsp;
                                        {/* <span>
                                            <img src={require('../../../assets/img/plus.svg')} alt="plus logo" />
                                        </span> */}
                                    </h3>
                                </div>
                                <div className="accoutn_form">
                                    <form action method>
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section border-none">
                                                            <div className="row">
                                                                <div className="col-lg-5 col-md-5 col-sm-6 mt-1">
                                                                    <div className="inner_left">
                                                                        {/*<label>Is the Paying Corporation a different entity ?</label>*/}
                                                                        <label>{this.props.state.payingOrganizationQuestion["question"]}</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3 col-md-3 col-sm-6 mt-1">
                                                                    <div className="inner_right">
                                                                        <div className="form-group new_pos">
                                                                            <div className="custom_select">

                                                                                {this.props.state.payingOrganizationQuestion["question"] != null?
                                                                                    <select id="ddlPayingCorpDiffEntity"
                                                                                            name="ddlPayingCorpDiffEntity"
                                                                                            value={this.props.state.ddlPayingCorpDiffEntity} onChange={this.props.HandleChange}>
                                                                                        <option value="">Select</option>
                                                                                        {this.props.state.payingOrganizationQuestion["ecAnswerSets"].map((item, index) => (
                                                                                            <option value={item["answerId"]}>{item["answerValue"]}</option>
                                                                                        ))}
                                                                                    </select>:''
                                                                                }
                                                                                {/*<select id="ddlPayingCorpDiffEntity" name="ddlPayingCorpDiffEntity" value={this.props.state.ddlPayingCorpDiffEntity} onChange={this.props.HandleChange}>
                                                                                    <option value="">Select</option>
                                                                                    <option value="100">Yes</option>
                                                                                    <option value="101">No</option>
                                                                                </select>*/}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                {this.props.state.answerIdHashMap[this.props.state.ddlPayingCorpDiffEntity] === "Yes" ?
                                    <div className="accoutn_form" id="show_hide_div" style={{ width: '100%' }}>
                                        <form action method>
                                            <div>
                                                <table className="adding_table_row">
                                                    <tbody>
                                                    <tr className="row mt-1">
                                                        <th className="col">Design Number</th>
                                                        <th className="col">Corporate Name</th>
                                                        <th className="col">Address Line 1</th>
                                                        <th className="col">Address Line 2</th>
                                                        <th className="col">City </th>
                                                        <th className="col">State / Province</th>
                                                        <th className="col"> Zip Code / Postal Code</th>
                                                        <th className="col">Country</th>
                                                    </tr>
                                                    {this.props.state.payingCompRows.map((data, index) => (
                                                            <tr className="row mt-1" id={"payingCompRow_"+index}>
                                                                <td className="bg-darkgray col">
                                                                    <div className="form-group new_pos">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id={"txtPayingCompDesignNumber_"+index}
                                                                            name={"txtPayingCompDesignNumber_"+index}
                                                                            //value={this.props.state.designRows[index]["txtDesignNumber"]}
                                                                            value={this.props.state.designRows[index]===undefined?
                                                                                this.props.state.designDropDownRows[index-(Number(this.props.state.designRows.length)-1)]["txtDesignNumber"]:this.props.state.designRows[index]["txtDesignNumber"]}
                                                                            placeholder={this.props.state.designRows[index]===undefined?"Design Number":"Other Items"}
                                                                            onChange={e =>
                                                                                this.props.HandlePayingCompRowsChange(
                                                                                    e,
                                                                                    index,
                                                                                    "txtPayingCompDesignNumber"
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="col">
                                                                    <div className="form-group new_pos">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id={"txtPayingCompCorpName_"+index}
                                                                            name={"txtPayingCompCorpName_"+index}
                                                                            value={data.txtPayingCompCorpName}
                                                                            placeholder="Corporate Name"
                                                                            onChange={e =>
                                                                                this.props.HandlePayingCompRowsChange(
                                                                                    e,
                                                                                    index,
                                                                                    "txtPayingCompCorpName"
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="col">
                                                                    <div className="form-group new_pos">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id={"txtPayingCompAddr1_"+index}
                                                                            name={"txtPayingCompAddr1_"+index}
                                                                            value={data.txtPayingCompAddr1}
                                                                            placeholder="Address Line 1"
                                                                            onChange={e =>
                                                                                this.props.HandlePayingCompRowsChange(
                                                                                    e,
                                                                                    index,
                                                                                    "txtPayingCompAddr1"
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="col">
                                                                    <div className="form-group new_pos">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id={"txtPayingCompAddr2_"+index}
                                                                            name={"txtPayingCompAddr2_"+index}
                                                                            value={data.txtPayingCompAddr2}
                                                                            placeholder="Address Line 2"
                                                                            onChange={e =>
                                                                                this.props.HandlePayingCompRowsChange(
                                                                                    e,
                                                                                    index,
                                                                                    "txtPayingCompAddr2"
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="col">
                                                                    <div className="form-group new_pos">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id={"txtPayingCompCity_"+index}
                                                                            name={"txtPayingCompCity_"+index}
                                                                            value={data.txtPayingCompCity}
                                                                            placeholder="City"
                                                                            onChange={e =>
                                                                                this.props.HandlePayingCompRowsChange(
                                                                                    e,
                                                                                    index,
                                                                                    "txtPayingCompCity"
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="col">
                                                                    <div className="form-group new_pos">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id={"txtPayingCompState_"+index}
                                                                            name={"txtPayingCompState_"+index}
                                                                            value={data.txtPayingCompState}
                                                                            placeholder="State"
                                                                            onChange={e =>
                                                                                this.props.HandlePayingCompRowsChange(
                                                                                    e,
                                                                                    index,
                                                                                    "txtPayingCompState"
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="col">
                                                                    <div className="form-group new_pos">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id={"txtPayingCompZip_"+index}
                                                                            name={"txtPayingCompZip_"+index}
                                                                            value={data.txtPayingCompZip}
                                                                            placeholder="Zip"
                                                                            onChange={e =>
                                                                                this.props.HandlePayingCompRowsChange(
                                                                                    e,
                                                                                    index,
                                                                                    "txtPayingCompZip"
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="col">
                                                                    <div className="form-group new_pos">
                                                                        <div className="custom_select">
                                                                            <select
                                                                                id={"txtPayingCompCountry_"+index}
                                                                                name={"txtPayingCompCountry_"+index}
                                                                                value={data.ddlPayingCompCountry}
                                                                                onChange={e =>
                                                                                    this.props.HandlePayingCompRowsChange(
                                                                                        e,
                                                                                        index,
                                                                                        "ddlPayingCompCountry"
                                                                                    )
                                                                                }
                                                                            >
                                                                                <option value="">Select</option>
                                                                                {this.props.state.countrieslist.length > 0 && this.props.state.countrieslist.map((sel, selindex) => (
                                                                                        <option key={`selPaying-${index}-${selindex}`} value={sel.codeId} >{sel.codeName}</option>
                                                                                    )
                                                                                )}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                    <tr className="row mt-1">
                                                        <td className="col text-left mx-auto">
                                                            <button className="btn_copy_from_customer" type="button" onClick={() => this.props.CopyAddrFromCustomer('paying')}>Copy address from Customer</button>
                                                        </td>
                                                        {this.props.state.payingCompRows.length > 1 ?
                                                            <td className="col text-right mx-auto">
                                                                <button className="btn_copy_from_first_row" type="button" onClick={() => this.props.CopyFromFirstRow('paying')}>Copy address from first row</button>
                                                            </td>
                                                            : ''}
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </form>
                                    </div>
                                    : ''}
                            </div>
                            <div className="account">
                                <div className="accoutn_form">
                                    <form action method>
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section border-none">
                                                            <div className="row">
                                                                <div className="col-lg-5 col-md-5 col-sm-6 mt-1">
                                                                    <div className="inner_left">
                                                                        <label>Provide the US Harmonized Tariff Schedule Number (HTSN) for the finished product (i.e. wafer, die, module, etc.) that MOSIS will be delivering</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-7 col-md-7 col-sm-6 mt-1">
                                                                    <div className="inner_right">
                                                                        <div className="form-group">
                                                                            <div className="custom_select">
                                                                                <select id="ddlHTSNCodes" name="ddlHTSNCodes" value={this.props.state.ddlHTSNCodes} onChange={this.props.HandleChange}>
                                                                                    <option value="">Select</option>
                                                                                    {this.props.HTSNCodelist.length > 0 ? this.props.HTSNCodelist.map(elem =>
                                                                                        <option value={elem.codeId}>{(elem.codeName).slice(0, 90)}</option>
                                                                                    ) : ''}
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="account" style={{ marginTop: '-16px' }}>
                                <div className="header">
                                    <h3 style={{ fontSize: '12px' }}><strong>NOTE:</strong> The U.S. Department of Commerce, Bureau of Industry and Security (BIS) Export Administration Regulations (EAR) define Export Control Classification Number (ECCN). The selected ECCN must reflect the basic electronic function and intended application for the device</h3>
                                </div>
                                <div className="accoutn_form">
                                    <form action method>
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section border-none">
                                                            <div className="row">
                                                                <div className="col-lg-5 col-md-5 col-sm-6 mt-1">
                                                                    <div className="inner_left">
                                                                        <label>Final End Use (Provide Brief Description and final end use for the product)</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-7 col-md-7 col-sm-6 mt-1">
                                                                    <div className="inner_right">
                                                                        <div className="form-group" style={{paddingBottom: "2%"}}>
                                                                            <textarea  maxLength="4000" type="text" className="form-control" defaultValue={""} id="endUseDescription" name="endUseDescription"
                                                                            value={this.props.state.endUseDescription} onChange={this.props.HandleChange}/>
                                                                        </div>

                                                                            {/*<label id="designErrorDefault" style={{fontStyle: "italic", color: "red", fontSize: "12px"}}>Please Enter Correct Email</label>*/}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="account">
                                <div className="header">
                                    <h3>End User</h3>
                                </div>
                                <div className="accoutn_form">
                                    <form action method>
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section border-none">
                                                            <div className="row">
                                                                <div className="col-lg-5 col-md-5 col-sm-6 mt-1">
                                                                    <div className="inner_left">
                                                                        {/*<label>Is the product (i.e. wafer, die, module) a stocked item for multiple indeterminate customers?</label>*/}
                                                                        <label>{this.props.state.multipleIndeterminateQuestion["question"]}</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3 col-md-3 col-sm-6 mt-1">
                                                                    <div className="inner_right">
                                                                        <div className="form-group">
                                                                            <div className="custom_select">
                                                                                {this.props.state.multipleIndeterminateQuestion["question"] != null?
                                                                                    <select id="ddlMultipleIntermediate"
                                                                                            name="ddlMultipleIntermediate"
                                                                                            value={this.props.state.ddlMultipleIntermediate} onChange={this.props.HandleChange}>
                                                                                    <option value="">Select</option>
                                                                                        {this.props.state.multipleIndeterminateQuestion["ecAnswerSets"].map((item, index) => (
                                                                                            <option value={item["answerId"]}>{item["answerValue"]}</option>
                                                                                    ))}
                                                                                    </select>:''
                                                                                }



                                                                                {/*<select id="ddlMultipleIntermediate" name="ddlMultipleIntermediate" value={this.props.state.ddlMultipleIntermediate} onChange={this.props.HandleChange}>
                                                                                    <option value="">Select</option>
                                                                                    <option value="104">Yes</option>
                                                                                    <option value="105">No</option>
                                                                                </select>*/}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                {this.props.state.answerIdHashMap[this.props.state.ddlMultipleIntermediate] === "No" ?
                                    <div className="accoutn_form" id="show_hide_div">
                                        <form action method>
                                            <div>
                                                <table className="adding_table_row" style={{ width: '100%' }}>
                                                    <tbody>
                                                    <tr className="row mt-1">
                                                        <th className="col">Design Number</th>
                                                        <th className="col">Corporate Name</th>
                                                        <th className="col">Address Line 1</th>
                                                        <th className="col">Address Line 2</th>
                                                        <th className="col">City </th>
                                                        <th className="col">State / Province</th>
                                                        <th className="col"> Zip Code / Postal Code</th>
                                                        <th className="col">Country</th>
                                                    </tr>
                                                    {this.props.state.endUserRows.map((data, index) => (
                                                            <tr className="row mt-1" id={"endUserRow_"+index}>
                                                                <td className="bg-darkgray col">
                                                                    <div className="form-group new_pos">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id={"txtEndUserDesignNumber_"+index}
                                                                            name={"txtEndUserDesignNumber_"+index}
                                                                            //value={this.props.state.designRows[index]["txtDesignNumber"]}
                                                                            value={this.props.state.designRows[index]===undefined?
                                                                                this.props.state.designDropDownRows[index-(Number(this.props.state.designRows.length)-1)]["txtDesignNumber"]:this.props.state.designRows[index]["txtDesignNumber"]}
                                                                            placeholder={this.props.state.designRows[index]===undefined?"Design Number":"Other Items"}
                                                                            onChange={e =>
                                                                                this.props.HandleEndUserRowsChange(
                                                                                    e,
                                                                                    index,
                                                                                    "txtEndUserDesignNumber"
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="col">
                                                                    <div className="form-group new_pos">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id={"txtEndUserCorpName_"+index}
                                                                            name={"txtEndUserCorpName_"+index}
                                                                            value={data.txtEndUserCorpName}
                                                                            placeholder="Corporate Name"
                                                                            onChange={e =>
                                                                                this.props.HandleEndUserRowsChange(
                                                                                    e,
                                                                                    index,
                                                                                    "txtEndUserCorpName"
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="col">
                                                                    <div className="form-group new_pos">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id={"txtEndUserAddr1_"+index}
                                                                            name={"txtEndUserAddr1_"+index}
                                                                            value={data.txtEndUserAddr1}
                                                                            placeholder="Address Line 1"
                                                                            onChange={e =>
                                                                                this.props.HandleEndUserRowsChange(
                                                                                    e,
                                                                                    index,
                                                                                    "txtEndUserAddr1"
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="col">
                                                                    <div className="form-group new_pos">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id={"txtEndUserAddr2_"+index}
                                                                            name={"txtEndUserAddr2_"+index}
                                                                            value={data.txtEndUserAddr2}
                                                                            placeholder="Address Line 2"
                                                                            onChange={e =>
                                                                                this.props.HandleEndUserRowsChange(
                                                                                    e,
                                                                                    index,
                                                                                    "txtEndUserAddr2"
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="col">
                                                                    <div className="form-group new_pos">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id={"txtEndUserCity_"+index}
                                                                            name={"txtEndUserCity_"+index}
                                                                            value={data.txtEndUserCity}
                                                                            placeholder="City"
                                                                            onChange={e =>
                                                                                this.props.HandleEndUserRowsChange(
                                                                                    e,
                                                                                    index,
                                                                                    "txtEndUserCity"
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="col">
                                                                    <div className="form-group new_pos">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id={"txtEndUserState_"+index}
                                                                            name={"txtEndUserState_"+index}
                                                                            value={data.txtEndUserState}
                                                                            placeholder="State"
                                                                            onChange={e =>
                                                                                this.props.HandleEndUserRowsChange(
                                                                                    e,
                                                                                    index,
                                                                                    "txtEndUserState"
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="col">
                                                                    <div className="form-group new_pos">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id={"txtEndUserZip_"+index}
                                                                            name={"txtEndUserZip_"+index}
                                                                            value={data.txtEndUserZip}
                                                                            placeholder="Zip"
                                                                            onChange={e =>
                                                                                this.props.HandleEndUserRowsChange(
                                                                                    e,
                                                                                    index,
                                                                                    "txtEndUserZip"
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="col">
                                                                    <div className="form-group new_pos">
                                                                        <div className="custom_select">
                                                                            <select
                                                                                id={"txtEndUserCountry_"+index}
                                                                                name={"txtEndUserCountry_"+index}
                                                                                value={data.ddlEndUserCountry}
                                                                                onChange={e =>
                                                                                    this.props.HandleEndUserRowsChange(
                                                                                        e,
                                                                                        index,
                                                                                        "ddlEndUserCountry"
                                                                                    )
                                                                                }
                                                                            >
                                                                                <option value="">Select</option>
                                                                                {this.props.state.countrieslist.length > 0 && this.props.state.countrieslist.map((sel, selindex) => (
                                                                                        <option key={`selEndUser-${index}-${selindex}`} value={sel.codeId} >{sel.codeName}</option>
                                                                                    )
                                                                                )}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                    <tr className="row mt-1">
                                                        <td className="col text-left mx-auto">
                                                            <button className="btn_copy_from_customer" type="button" onClick={() => this.props.CopyAddrFromCustomer('endUser')}>Copy address from Customer</button>
                                                        </td>
                                                        {this.props.state.endUserRows.length > 1 ?
                                                            <td className="col text-right mx-auto">
                                                                <button className="btn_copy_from_first_row" type="button" onClick={() => this.props.CopyFromFirstRow('endUser')}>Copy address from first row</button>
                                                            </td>
                                                            : ''}
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </form>
                                    </div>
                                    : ''}
                            </div>
                            <div className="account">
                                <div className="accoutn_form">
                                    <form action method>
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section border-none">
                                                            <div className="row">
                                                                <div className="col-lg-9 col-md-9 col-sm-6 mt-1">
                                                                    <div className="inner_left">
                                                                        {/*<label>Is the Product to be manufactured intended or likely to be used, wholly, or in part, for or in connection with the development, production, handling, operation, maintenance, storage, detection, identification, or dissemination of any nuclear, chemical, or biological weapon; or the development, production, maintenance or storage of missiles or unmanned aerial vehicles which are capable of delivering any such weapons?</label>*/}
                                                                        <label>{this.props.state.weaponsQuestion["question"]}</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3 col-md-3 col-sm-6 mt-1">
                                                                    <div className="inner_right">
                                                                        <div className="form-group">
                                                                            <div className="custom_select">

                                                                                {this.props.state.weaponsQuestion["question"] != null?
                                                                                    <select id="ddlManufacturedIntendedProduct"
                                                                                            name="ddlManufacturedIntendedProduct"
                                                                                            value={this.props.state.ddlManufacturedIntendedProduct} onChange={this.props.HandleChange}>
                                                                                        <option value="">Select</option>
                                                                                        {this.props.state.weaponsQuestion["ecAnswerSets"].map((item, index) => (
                                                                                            <option value={item["answerId"]}>{item["answerValue"]}</option>
                                                                                        ))}
                                                                                    </select>:''
                                                                                }

                                                                                {/*<select id="ddlManufacturedIntendedProduct" name="ddlManufacturedIntendedProduct" value={this.props.state.ddlManufacturedIntendedProduct} onChange={this.props.HandleChange}>
                                                                                    <option value="">Select</option>
                                                                                    <option value="106">Yes</option>
                                                                                    <option value="107">No</option>
                                                                                </select>*/}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                {this.props.state.ddlManufacturedIntendedProduct === "45" ?
                                    < div className="accoutn_form" id="show_hide_div">
                                        <form action method>
                                            <div className="dashboard_form">
                                                <div className="inner-dashoard_form">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="brd_section border-none">
                                                                <div className="row">
                                                                    <div className="col-lg-5 col-md-5 col-sm-6 mt-1">
                                                                        <div className="inner_left">
                                                                            <label>Please provide explanation</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-7 col-md-7 col-sm-6 mt-1">
                                                                        <div className="inner_right">
                                                                            <div className="form-group" style={{paddingBottom: "2%"}}>
                                                                                <textarea maxLength="2000" type="text" className="form-control" name="txtManufacturedIntendedProduct" id="txtManufacturedIntendedProduct" value={this.props.state.txtManufacturedIntendedProduct} onChange={this.props.HandleChange} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    : ''}
                            </div>
                            <div className="account">
                            <div className="accoutn_form">
                                <form action method>
                                    <div className="dashboard_form">
                                        <div className="inner-dashoard_form">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="brd_section border-none">
                                                        <div className="row">
                                                            <div className="col-lg-9 col-md-9 col-sm-6 mt-1">
                                                                <div className="inner_left">
                                                                    {/*<label>Is this product specially designed, modified, configured or adapted for a military, intelligence, or space related application?</label>*/}
                                                                    <label>{this.props.state.militarySpaceQuestion["question"]}</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 col-md-3 col-sm-6 mt-1">
                                                                <div className="inner_right">
                                                                    <div className="form-group new_pos">
                                                                        <div className="custom_select">

                                                                            {this.props.state.militarySpaceQuestion["question"] != null?
                                                                                <select id="ddlProductSpeciallyDesigned"
                                                                                        name="ddlProductSpeciallyDesigned"
                                                                                        value={this.props.state.ddlProductSpeciallyDesigned} onChange={this.props.HandleChange}>
                                                                                    <option value="">Select</option>
                                                                                    {this.props.state.militarySpaceQuestion["ecAnswerSets"].map((item, index) => (
                                                                                        <option value={item["answerId"]}>{item["answerValue"]}</option>
                                                                                    ))}
                                                                                </select>:''
                                                                            }


                                                                            {/*<select id="ddlProductSpeciallyDesigned" name="ddlProductSpeciallyDesigned" value={this.props.state.ddlProductSpeciallyDesigned} onChange={this.props.HandleChange}>
                                                                                    <option value="">Select</option>
                                                                                    <option value="108">Yes</option>
                                                                                    <option value="109">No</option>
                                                                                </select>*/}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            {this.props.state.ddlProductSpeciallyDesigned === "47" ?
                                <div className="accoutn_form" id="show_hide_div">
                                    <form action method>
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section border-none">
                                                            <div className="row">
                                                                <div className="col-lg-5 col-md-5 col-sm-6 mt-1">
                                                                    <div className="inner_left">
                                                                        <label>Please provide brief description of application</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-7 col-md-7 col-sm-6 mt-1">
                                                                    <div className="inner_right">
                                                                        <div className="form-group" style={{paddingBottom: "2%"}}>
                                                                            <textarea maxLength="2000" type="text" className="form-control" name="txtProductSpeciallyDesignedDesc" id="txtProductSpeciallyDesignedDesc" value={this.props.state.txtProductSpeciallyDesignedDesc} onChange={this.props.HandleChange} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                : ''}
                        </div>

                            <div className="account">
                                <div className="accoutn_form">
                                    <form action method>
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section border-none">
                                                            <div className="row">
                                                                <div className="col-lg-9 col-md-9 col-sm-6 mt-1">
                                                                    <div className="inner_left">
                                                                        {/*<label>Is this product specially designed, modified, configured or adapted for a military, intelligence, or space related application?</label>*/}
                                                                        <label>{this.props.state.crvEndUseQuestion["question"]}</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3 col-md-3 col-sm-6 mt-1">
                                                                    <div className="inner_right">
                                                                        <div className="form-group new_pos">
                                                                            <div className="custom_select">

                                                                                {this.props.state.crvEndUseQuestion["question"] != null?
                                                                                    <select id="ddlCrvEndUse"
                                                                                            name="ddlCrvEndUse"
                                                                                            value={this.props.state.ddlCrvEndUse} onChange={this.props.HandleChange}>
                                                                                        <option value="">Select</option>
                                                                                        {this.props.state.crvEndUseQuestion["ecAnswerSets"].map((item, index) => (
                                                                                            <option value={item["answerId"]}>{item["answerValue"]}</option>
                                                                                        ))}
                                                                                    </select>:''
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="account">
                                <div className="accoutn_form">
                                    <form action method>
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section border-none">
                                                            <div className="row">
                                                                <div className="col-lg-9 col-md-9 col-sm-6 mt-1">
                                                                    <div className="inner_left">
                                                                        {/*<label>Is this product specially designed, modified, configured or adapted for a military, intelligence, or space related application?</label>*/}
                                                                        {/*<label>{this.props.state.reExportFlagQuestion["question"]}</label>*/}
                                                                        <label>{this.props.state.reExportFlagQuestion["question"]?
                                                                            this.props.state.reExportFlagQuestion["question"].split("<br>").map((item, i) => <p key={i} style={{fontSize: "12px", lineHeight: "normal", paddingTop: "0"}}>{item}</p>):''}</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3 col-md-3 col-sm-6 mt-1">
                                                                    <div className="inner_right">
                                                                        <div className="form-group new_pos">
                                                                            <div className="custom_select">

                                                                                {this.props.state.reExportFlagQuestion["question"] != null?
                                                                                    <select id="ddlReExportFlag"
                                                                                            name="ddlReExportFlag"
                                                                                            value={this.props.state.ddlReExportFlag} onChange={this.props.HandleChange}>
                                                                                        <option value="">Select</option>
                                                                                        {this.props.state.reExportFlagQuestion["ecAnswerSets"].map((item, index) => (
                                                                                            <option value={item["answerId"]}>{item["answerValue"]}</option>
                                                                                        ))}
                                                                                    </select>:''
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="account">
                                <div className="header">
                                    <h3>EAR Questions</h3>
                                </div>
                                <div className="accoutn_form">
                                    <form action method>
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-9 col-md-9 col-sm-6 mt-1">
                                                                    <div className="inner_left">
                                                                        {/*<label>Is the product (i.e. wafer, die, module) or the associated technical data (I.e. integrated circuit design data) subject to the U.S. Export Administration Regulations (15 CFR Part 730-774)?</label>*/}


                                                                                <label>{this.props.state.exportAdminQuestion["question"]}</label>

                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3 col-md-3 col-sm-6 mt-1">
                                                                    <div className="inner_right">
                                                                        <div className="form-group">
                                                                            <div className="custom_select">

                                                                                {this.props.state.exportAdminQuestion["question"] != null?
                                                                                    <select id="ddlSubjectToTheUSExportAdministration"
                                                                                            name="ddlSubjectToTheUSExportAdministration"
                                                                                            value={this.props.state.ddlSubjectToTheUSExportAdministration} onChange={this.props.HandleChange}>
                                                                                        <option value="">Select</option>
                                                                                        {this.props.state.exportAdminQuestion["ecAnswerSets"].map((item, index) => (
                                                                                            <option value={item["answerId"]}>{item["answerValue"]}</option>
                                                                                        ))}
                                                                                    </select>:''
                                                                                }
                                                                                {/*<select id="ddlSubjectToTheUSExportAdministration" name="ddlSubjectToTheUSExportAdministration" value={this.props.state.ddlSubjectToTheUSExportAdministration} onChange={this.props.HandleChange}>
                                                                                    <option value="">Select</option>
                                                                                    <option value="73">Yes</option>
                                                                                    <option value="74">No</option>
                                                                                </select>*/}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {this.props.state.ddlSubjectToTheUSExportAdministration === "73" ?
                                                                <div id="show_hide_div">
                                                                    <form action method>
                                                                        <table className="adding_table_row" style={{ width: '100%' }}>
                                                                            <tbody>
                                                                            <tr className="row">
                                                                                <td className="col-6 mt-1">
                                                                                    <div className="inner_left">
                                                                                        <label>Provide a copy of the Commodity Classification (Form BXA-6002L) issued by the Department of Commerce or the CCATS Number if available.</label>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="col-6">
                                                                                    <tr className="row mt-1">
                                                                                        <td className="col">
                                                                                            {/*<input type="file" id="commodityFile" style={{ fontSize: '12px', display: 'block', width: '100%' }}
                                                                                                   onInput={(event)=> {
                                                                                                       this.props.fileChanges(event)
                                                                                                   }}
                                                                                                   onClick={(event)=> {
                                                                                                       event.target.value = null
                                                                                                   }}/>*/}
                                                                                            <input type="file" id="commodityFile" name="commodityFile" ref={this.props.fileInput} style={{ fontSize: '12px' }} />
                                                                                            {/*<input type="file" id="commodityFile" style={{height: '22px', top: '19%', position: 'relative'}}
                                                                                                   ref={this.props.fileInput}
                                                                                                   onChange={(event)=> {
                                                                                                       this.props.fileChanges(event)
                                                                                                   }}/>*/}
                                                                                            {/*<input type="file"  id="commodityFile" onChange={this.props.fileChanges}/>*/}
                                                                                            {/*<img src={this.props.state.selectedFile}/>*/}
                                                                                        </td>
                                                                                    </tr>

                                                                                    <tr className="row mt-1 new_pos">
                                                                                        <td className="col-6">
                                                                                            <input type="text" class="form-control" id="txtCCATSNumber" name="txtCCATSNumber" value={this.props.state.txtCCATSNumber} onChange={this.props.HandleChange}
                                                                                                   maxlength="50"/>
                                                                                        </td>
                                                                                        <td className="col-6">
                                                                                            <div className="inner_left">
                                                                                                <label>CCATS Number</label>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </td>
                                                                            </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </form>
                                                                </div>
                                                                : ''}
                                                        </div>
                                                    </div>




                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-8 col-md-8 col-sm-6 mt-1">
                                                                    <div className="inner_left">
                                                                        <label>Provide the ECCN for the <b> design technology </b>(i.e. integrated circuit design data)</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-sm-6 mt-1">
                                                                    <div className="inner_right">
                                                                        <div className="form-group new_pos">
                                                                            <input type="text" className="form-control" name="txtECCNForDesignTechnology" id="txtECCNForDesignTechnology" value={this.props.state.txtECCNForDesignTechnology} onChange={this.props.HandleChange} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-8 col-md-4 col-sm-6 mt-1">
                                                                    <div className="inner_left">
                                                                        <label>Provide the ECCN for the <b>finished good</b>, i.e. wafer, die, module.</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-sm-6 mt-1">
                                                                    <div className="inner_right">
                                                                        <div className="form-group new_pos">
                                                                            <input type="text" className="form-control" name="txtECCNForFinishedGood" id="txtECCNForFinishedGood" value={this.props.state.txtECCNForFinishedGood} onChange={this.props.HandleChange} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-8 col-md-8 col-sm-6 mt-1">
                                                                    <div className="inner_left">
                                                                        {/*<label>Is the product (i.e. wafer, die, module) or its associated technical data (i.e. integrated circuit design data) subject to export controls in other (non-US) jurisdictions?</label>*/}
                                                                        <label>{this.props.state.exportNonUSQuestion["question"]}</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-sm-6 mt-1">
                                                                    <div className="inner_right">
                                                                        <div className="form-group">
                                                                            <div className="custom_select">

                                                                                {this.props.state.exportNonUSQuestion["question"] != null?
                                                                                    <select id="ddlSubjectToExportControlsNonUs"
                                                                                            name="ddlSubjectToExportControlsNonUs"
                                                                                            value={this.props.state.ddlSubjectToExportControlsNonUs} onChange={this.props.HandleChange}>
                                                                                        <option value="">Select</option>
                                                                                        {this.props.state.exportNonUSQuestion["ecAnswerSets"].map((item, index) => (
                                                                                            <option value={item["answerId"]}>{item["answerValue"]}</option>
                                                                                        ))}
                                                                                    </select>:''
                                                                                }
                                                                                {/*<select id="ddlSubjectToExportControlsNonUs" name="ddlSubjectToExportControlsNonUs" value={this.props.state.ddlSubjectToExportControlsNonUs} onChange={this.props.HandleChange}>
                                                                                    <option value="">Select</option>
                                                                                    <option value="75">Yes</option>
                                                                                    <option value="76">No</option>
                                                                                </select>*/}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {this.props.state.ddlSubjectToExportControlsNonUs === "75" ?
                                                                <div id="show_hide_div">
                                                                    <div className="row">
                                                                        <div className="col-8 col-md-8 col-sm-6 mt-1">
                                                                            <div className="inner_left">
                                                                                <label>a. Name of the jurisdiction</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-4 col-md-4 col-sm-6 mt-1">
                                                                            <div className="inner_right">
                                                                                <div className="form-group new_pos">
                                                                                    <input type="text" className="form-control" id="txtJurisdictionName" name="txtJurisdictionName" value={this.props.state.txtJurisdictionName} onChange={this.props.HandleChange} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-8 col-md-8 col-sm-6 mt-1">
                                                                            <div className="inner_left">
                                                                                <label>b. ECCN for the design</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-4 col-md-4 col-sm-6 mt-1">
                                                                            <div className="inner_right">
                                                                                <div className="form-group new_pos">
                                                                                    <input type="text" className="form-control" id="txtDesignECCN" name="txtDesignECCN" value={this.props.state.txtDesignECCN} onChange={this.props.HandleChange} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-8 col-md-8 col-sm-6 mt-1">
                                                                            <div className="inner_left">
                                                                                <label>c. ECCN for the finished wafers, if applicable.</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-4 col-md-4 col-sm-6 mt-1">
                                                                            <div className="inner_right">
                                                                                <div className="form-group new_pos">
                                                                                    <input type="text" className="form-control" id="txtFinishedWafersECCN" name="txtFinishedWafersECCN" value={this.props.state.txtFinishedWafersECCN} onChange={this.props.HandleChange} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : ''}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section border-none">
                                                            <div className="row">
                                                                <div className="col-lg-8 col-md-8 col-sm-6 mt-1">
                                                                    <div className="inner_left">
                                                                        {/*<label>Is this product a millimeter wave (mwWave) or "Monolithic Integrated Circuit" ("MMIC") amplifier with or without any type of transmitter,receiver and transceiver?</label>*/}
                                                                        <label>{this.props.state.mmicQuestion["question"]}</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-sm-6 mt-1">
                                                                    <div className="inner_right">
                                                                        <div className="form-group">
                                                                            <div className="custom_select">

                                                                                {this.props.state.mmicQuestion["question"] != null?
                                                                                    <select id="ddlMwWaveProductMMIC"
                                                                                            name="ddlMwWaveProductMMIC"
                                                                                            value={this.props.state.ddlMwWaveProductMMIC} onChange={this.props.HandleChange}>
                                                                                        <option value="">Select</option>
                                                                                        {this.props.state.mmicQuestion["ecAnswerSets"].map((item, index) => (
                                                                                            <option value={item["answerId"]}>{item["answerValue"]}</option>
                                                                                        ))}
                                                                                    </select>:''
                                                                                }
                                                                                {/*<select id="ddlMwWaveProductMMIC" name="ddlMwWaveProductMMIC" value={this.props.state.ddlMwWaveProductMMIC} onChange={this.props.HandleChange}>
                                                                                    <option value="">Select</option>
                                                                                    <option value="77">Yes</option>
                                                                                    <option value="78">No</option>
                                                                                </select>*/}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {this.props.state.ddlMwWaveProductMMIC === '77' ?
                                                                <div id="show_hide_div">
                                                                    <div className="row">
                                                                        <div className="col-8 col-md-8 col-sm-6 mt-1">
                                                                            <div className="inner_left">
                                                                                <label>a. Provide the operating frequency of the device</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-4 col-md-4 col-sm-6 mt-1">
                                                                            <div className="inner_right">
                                                                                <div className="form-group new_pos">
                                                                                    <input type="text" className="form-control" name="operatingFrequency" id="operatingFrequency"
                                                                                    value={this.props.state.operatingFrequency} onChange={this.props.HandleChange}/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row align-items-center">
                                                                        <div className="col-8 col-md-8 col-sm-6 mt-1">
                                                                            <div className="inner_left">
                                                                                <label>If the frequency is: (a) 27 to 31.8 GHz; (b) 37 to 43 GHz; or (c) 75 to 90 GHz, PLEASE provide a graph showing the x-axis (frequency) and y-axis (peak saturated power output)</label>
                                                                            </div>
                                                                            <div className="inner_left">
                                                                                <label>If the graph does not confirm a steep drop in performance when the amplifier is performing near the: (a) 31.8-37 GHz; or (b) 90 GHz ranges, PLEASE provide instead a graph with x-axis (frequency) and y-axis (power gain). (This is sometimes referred to as a "S21 plot.")</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-4 col-md-4 col-sm-6 mt-1">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <input type="file" className="" id="graphFile" name="graphFile" style={{ fontSize: '12px' }} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-8 col-md-8 col-sm-6 mt-1">
                                                                            <div className="inner_left">
                                                                                <label>b. Provide the peak saturated power output of the device</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-4 col-md-4 col-sm-6 mt-1">
                                                                            <div className="inner_right">
                                                                                <div className="form-group new_pos">
                                                                                    <input type="text" className="form-control" name="peakSaturatedPower" id="peakSaturatedPower"
                                                                                    value={this.props.state.peakSaturatedPower} onChange={this.props.HandleChange}
                                                                                           maxlength="30"/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-8 col-md-8 col-sm-6 mt-1">
                                                                            <div className="inner_left">
                                                                                <label>c. Is there a phase shifter integrated inside the design?</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-3 col-md-3 col-sm-6 mt-1">
                                                                            <div className="inner_right">
                                                                                <div className="form-group new_pos">
                                                                                    <div className="custom_select">
                                                                                        <select id="ddlPhaseShifterIntegrated" name="ddlPhaseShifterIntegrated" value={this.props.state.ddlPhaseShifterIntegrated} onChange={this.props.HandleChange}>
                                                                                            <option value="">Select</option>
                                                                                            <option value="1">Yes</option>
                                                                                            <option value="0">No</option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-8 col-md-8 col-sm-6 mt-1">
                                                                            <div className="inner_left">
                                                                                <label>d. Is this product "Specially Designed" for each other applications e.g., telecommunications, radar, automobile ?</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-3 col-md-3 col-sm-6 mt-1">
                                                                            <div className="inner_right">
                                                                                <div className="form-group new_pos">
                                                                                    <div className="custom_select">
                                                                                        <select id="ddlSpeciallyDesignedProduct" name="ddlSpeciallyDesignedProduct" value={this.props.state.ddlSpeciallyDesignedProduct} onChange={this.props.HandleChange}>
                                                                                            <option value="">Select</option>
                                                                                            <option value="1">Yes</option>
                                                                                            <option value="0">No</option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {this.props.state.ddlSpeciallyDesignedProduct === "1" ?
                                                                            <div className="col-12">
                                                                                <div id="show_hide_div">
                                                                                    <div className="row">
                                                                                        <div className="col mt-1">
                                                                                            <div className="inner_left">
                                                                                                <label>Describe the other application</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col mt-1">
                                                                                            <div className="inner_right">
                                                                                                <div className="form-group" style={{paddingBottom: "2%"}}>
                                                                                                    <textarea maxLength="2000" type="text" className="form-control" name="specialDesignApplication" id="specialDesignApplication"
                                                                                                    value={this.props.state.specialDesignApplication} onChange={this.props.HandleChange}></textarea>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            : ''}
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-8 col-md-8 col-sm-6 mt-1">
                                                                            <div className="inner_left">
                                                                                <label>e. Is the device controlled under the U.S. Munitions List XI (c) (4), XV(e)(14), ECCN 5E001.d, 3A001.b.2 or b.12?</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-3 col-md-3 col-sm-6 mt-1">
                                                                            <div className="inner_right">
                                                                                <div className="form-group new_pos">
                                                                                    <div className="custom_select">
                                                                                        <select id="ddlDeviceCtrlUnderUSMunitions" name="ddlDeviceCtrlUnderUSMunitions" value={this.props.state.ddlDeviceCtrlUnderUSMunitions} onChange={this.props.HandleChange}>
                                                                                            <option value="">Select</option>
                                                                                            <option value="1">Yes</option>
                                                                                            <option value="0">No</option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="account">
                                <div className="header">
                                    <h3>ITAR- International Traffic In Arms</h3>
                                </div>
                                <div className="accoutn_form">
                                    <form action method>
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section border-none">
                                                            <div className="row">
                                                                <div className="col-lg-9 col-md-9 col-sm-6 mt-1">
                                                                    <div className="inner_left">
                                                                        {/*<label>Is the product (i.e. wafer, die, module) and the associated technology/technical data (i.e. integrated circuit design data) subject to the International Traffic in Arms Regulations (CFR 22 Part 120-130)?</label>*/}
                                                                        <label>{this.props.state.internationalTrafficArmsQuestion["question"]}</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3 col-md-3 col-sm-6 mt-1">
                                                                    <div className="inner_right">
                                                                        <div className="form-group">
                                                                            <div className="custom_select mt-1">

                                                                                {this.props.state.internationalTrafficArmsQuestion["question"] != null?
                                                                                    <select id="ddlITARArms" name="ddlITARArms"
                                                                                            value={this.props.state.ddlITARArms} onChange={this.props.HandleChange}>
                                                                                        <option value="">Select</option>
                                                                                        {this.props.state.internationalTrafficArmsQuestion["ecAnswerSets"].map((item, index) => (
                                                                                            <option value={item["answerId"]}>{item["answerValue"]}</option>
                                                                                        ))}
                                                                                    </select>:''
                                                                                }
                                                                                {/*<select id="ddlITARArms" name="ddlITARArms" value={this.props.state.ddlITARArms} onChange={this.props.HandleChange}>
                                                                                    <option value="">Select</option>
                                                                                    <option value="79">Yes</option>
                                                                                    <option value="80">No</option>
                                                                                </select>*/}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {this.props.state.ddlITARArms === "79" ?
                                                                <div id="show_hide_div">
                                                                    <div className="row">
                                                                        <div className="col-8 col-md-8 col-sm-6 mt-1">
                                                                            <div className="inner_left">
                                                                                <label>Provide a copy of the Commodity Jurisdiction (CJ) determination issued by the Department of State, if available)</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-4 col-md-4 col-sm-6 mt-1">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <input type="file" id="commodityJurisdictionFile" name="commodityJurisdictionFile" className=""  style={{ fontSize: '12px' }} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row align-items-center">
                                                                        <div className="col-8 col-md-8 col-sm-6 mt-1">
                                                                            <div className="inner_left">
                                                                                <label>Select the overall ITAR Category that this product is subject to</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-4 col-md-4 col-sm-6 mt-1">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <div className="custom_select">
                                                                                        <select id="ddlITARCategory" name="ddlITARCategory" value={this.props.state.ddlITARCategory} onChange={this.props.HandleITARChange}>
                                                                                            <option value="">Select</option>
                                                                                            {this.props.ITARCatlist.length > 0 ? this.props.ITARCatlist.map(elem =>
                                                                                                <option value={elem.codeId}>{elem.codeValue + " " + ((elem.codeName).slice(0, 90))}</option>
                                                                                            ) : ''}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row new_pos">
                                                                        <div className="col-8 col-md-8 col-sm-6 mt-1">
                                                                            <div className="inner_left">
                                                                                <label>Enter the fully extended suffix that applies to the technical data (i.e., the IC design data):e.g. XV(f), XI(d)</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-4 col-md-4 col-sm-6 mt-1">
                                                                            <div className="form-group new_pos">
                                                                                <input type="text" className="form-control" name="technicalDataSuffix" id="technicalDataSuffix"
                                                                                value={this.props.state.technicalDataSuffix} onChange={this.props.HandleChange}
                                                                                       maxlength="50"/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row new_pos">
                                                                        <div className="col-8 col-md-8 col-sm-6 mt-1">
                                                                            <div className="inner_left">
                                                                                <label>Enter the fully extended suffix that applies to integrated circuits (i.e., wafer, die, module): e.g. XV(e)(14)(i),</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-4 col-md-4 col-sm-6 mt-1">
                                                                            <div className="form-group new_pos">
                                                                                <input type="text" className="form-control" name="icSuffix" id="icSuffix" value={this.props.state.icSuffix} onChange={this.props.HandleChange}
                                                                                       maxlength="50"/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-8 col-md-8 col-sm-6 mt-1">
                                                                            <div className="inner_left">
                                                                                <label>Enter the fully extended suffix that applies to the next higher assembly of the integrated circuit that is being fabricated</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-4 col-md-4 col-sm-6 mt-1">
                                                                            <div className="form-group new_pos">
                                                                                <input type="text" className="form-control" name="fullyExtendedSuffix" id="fullyExtendedSuffix"
                                                                                value={this.props.state.fullyExtendedSuffix} onChange={this.props.HandleChange} maxlength="50"/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-8 col-md-8 col-sm-6 mt-1">
                                                                            <div className="inner_left">
                                                                                <label>Enter the fully extended suffix that applies to the end use equipment; e.g. XV(a)(7)(i), XI(a)(1)(iii)</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-4 col-md-4 col-sm-6 mt-1">
                                                                            <div className="form-group new_pos">
                                                                                <input type="text" className="form-control" name="endUseSuffix" id="endUseSuffix" value={this.props.state.endUseSuffix} onChange={this.props.HandleChange}
                                                                                       maxlength="50"/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-8 col-md-8 col-sm-6 mt-1">
                                                                            <div className="inner_left">
                                                                                {/*<label>Is the product, being fabricated by the foundry, designed or developed using classified information.</label>*/}
                                                                                <label>{this.props.state.classifiedInformationQuestion["question"]}</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-3 col-md-3 col-sm-6 mt-1">
                                                                            <div className="inner_right">
                                                                                <div className="form-group new_pos">
                                                                                    <div className="custom_select">

                                                                                        {this.props.state.classifiedInformationQuestion["question"] != null?
                                                                                            <select id="ddlProductFabricatedByFoundry" name="ddlProductFabricatedByFoundry"
                                                                                                    value={this.props.state.ddlProductFabricatedByFoundry} onChange={this.props.HandleChange}>
                                                                                                <option value="">Select</option>
                                                                                                {this.props.state.classifiedInformationQuestion["ecAnswerSets"].map((item, index) => (
                                                                                                    <option value={item["answerId"]}>{item["answerValue"]}</option>
                                                                                                ))}
                                                                                            </select>:''
                                                                                        }
                                                                                        {/*<select id="ddlProductFabricatedByFoundry" name="ddlProductFabricatedByFoundry" value={this.props.state.ddlProductFabricatedByFoundry} onChange={this.props.HandleChange}>
                                                                                            <option value="">Select</option>
                                                                                            <option value="81">Yes</option>
                                                                                            <option value="82">No</option>
                                                                                        </select>*/}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* PRABHASH */}
                                                                    {this.props.state.ddlITARCategory != ''?
                                                                        <div>
                                                                        {this.props.state.ITARCatListMap[this.props.state.ddlITARCategory].includes('XV(a)')?
                                                                        <div className="row">
                                                                            <div className="col-8 col-md-8 col-sm-6 mt-1">
                                                                                <div className="inner_left">
                                                                                    {/*<label>Is this end use equipment considered a commercial communications satellite that has no other type of payload?</label>*/}
                                                                                    <label>{this.props.state.typeOfPayloadQuestion["question"]}</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-3 col-md-3 col-sm-6 mt-1">
                                                                                <div className="inner_right">
                                                                                    <div className="form-group">
                                                                                        <div className="custom_select">


                                                                                            {this.props.state.typeOfPayloadQuestion["question"] != null?
                                                                                                <select id="ddlEquipmentConsideredPayload" name="ddlEquipmentConsideredPayload"
                                                                                                        value={this.props.state.ddlEquipmentConsideredPayload} onChange={this.props.HandleChange}>
                                                                                                    <option value="">Select</option>
                                                                                                    {this.props.state.typeOfPayloadQuestion["ecAnswerSets"].map((item, index) => (
                                                                                                        <option value={item["answerId"]}>{item["answerValue"]}</option>
                                                                                                    ))}
                                                                                                </select>:''
                                                                                            }
                                                                                            <select id="ddlEquipmentConsideredPayload" name="ddlEquipmentConsideredPayload" value={this.props.state.ddlEquipmentConsideredPayload} onChange={this.props.HandleChange}>
                                                                                                <option value="">Select</option>
                                                                                                <option value="83">Yes</option>
                                                                                                <option value="84">No</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        : ''}
                                                                    {this.props.state.ITARCatListMap[this.props.state.ddlITARCategory].includes('XV(c)') ?
                                                                        <div className="row">
                                                                            <div className="col-8 col-md-8 col-sm-6 mt-1">
                                                                                <div className="inner_left">
                                                                                    {/*<label>Are the end-items intended exclusively for end-use by the Federal Government of Canada that will be exported directly or indirectly through a Canadian-registered person?</label>*/}
                                                                                    <label>{this.props.state.canadianRegisteredQuestion["question"]}</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-3 col-md-3 col-sm-6 mt-1">
                                                                                <div className="inner_right">
                                                                                    <div className="form-group">
                                                                                        <div className="custom_select">


                                                                                            {this.props.state.canadianRegisteredQuestion["question"] != null?
                                                                                                <select id="ddlEndItemsIntendedExclusivelyCanadian" name="ddlEndItemsIntendedExclusivelyCanadian"
                                                                                                        value={this.props.state.ddlEndItemsIntendedExclusivelyCanadian} onChange={this.props.HandleChange}>
                                                                                                    <option value="">Select</option>
                                                                                                    {this.props.state.canadianRegisteredQuestion["ecAnswerSets"].map((item, index) => (
                                                                                                        <option value={item["answerId"]}>{item["answerValue"]}</option>
                                                                                                    ))}
                                                                                                </select>:''
                                                                                            }
                                                                                            <select id="ddlEndItemsIntendedExclusivelyCanadian" name="ddlEndItemsIntendedExclusivelyCanadian" value={this.props.state.ddlEndItemsIntendedExclusivelyCanadian} onChange={this.props.HandleChange}>
                                                                                                <option value="">Select</option>
                                                                                                <option value="85">Yes</option>
                                                                                                <option value="86">No</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        : ''}
                                                                    {this.props.state.ITARCatListMap[this.props.state.ddlITARCategory].includes('XV(e)') ?
                                                                        <div>
                                                                            <div className="row">
                                                                                <div className="col-8 col-md-8 col-sm-6 mt-1">
                                                                                    <div className="inner_left">
                                                                                        {/*<label>Is this program (or its related defense articles) for an anti-jam system with the ability to respond to incoming interference by adaptively reducing antenna gain (nulling) in the direction of the interference?</label>*/}
                                                                                        <label>{this.props.state.antiJamQuestion["question"]}</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-3 col-md-3 col-sm-6 mt-1">
                                                                                    <div className="inner_right">
                                                                                        <div className="form-group">
                                                                                            <div className="custom_select">

                                                                                                {this.props.state.antiJamQuestion["question"] != null?
                                                                                                    <select id="ddlAntiJamSystem" name="ddlAntiJamSystem"
                                                                                                            value={this.props.state.ddlAntiJamSystem} onChange={this.props.HandleChange}>
                                                                                                        <option value="">Select</option>
                                                                                                        {this.props.state.antiJamQuestion["ecAnswerSets"].map((item, index) => (
                                                                                                            <option value={item["answerId"]}>{item["answerValue"]}</option>
                                                                                                        ))}
                                                                                                    </select>:''
                                                                                                }
                                                                                                <select id="ddlAntiJamSystem" name="ddlAntiJamSystem" value={this.props.state.ddlAntiJamSystem} onChange={this.props.HandleChange}>
                                                                                                    <option value="">Select</option>
                                                                                                    <option value="87">Yes</option>
                                                                                                    <option value="88">No</option>
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-8 col-md-8 col-sm-6 mt-1">
                                                                                    <div className="inner_left">
                                                                                        {/*<label>Is this program (or its related defense articles) for antennas having any of the following? <br/>a. Aperture (overall dimension of the radiating portions of the antenna) greater than 30 feet;<br/>
                                                                                            b. All sidelobes less than or equal to âˆ’35 dB relative to the peak of the main beam; or<br/>
                                                                                            c. Designed, modified, or configured to provide coverage area on the surface of the earth less than 200 nautical miles in diameter, where "coverage area" is defined as that area on the surface of the earth that is illuminated by the main beam width of the antenna (which is the angular distance between half power points of the beam)</label>*/}
                                                                                        <label>{this.props.state.anyOfFollowingQuestion["question"]}<br/>a. Aperture (overall dimension of the radiating portions of the antenna) greater than 30 feet;<br/>
                                                                                            b. All sidelobes less than or equal to âˆ’35 dB relative to the peak of the main beam; or<br/>
                                                                                            c. Designed, modified, or configured to provide coverage area on the surface of the earth less than 200 nautical miles in diameter, where "coverage area" is defined as that area on the surface of the earth that is illuminated by the main beam width of the antenna (which is the angular distance between half power points of the beam)</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-3 col-md-3 col-sm-6 mt-1">
                                                                                    <div className="inner_right">
                                                                                        <div className="form-group">
                                                                                            <div className="custom_select">

                                                                                                {this.props.state.anyOfFollowingQuestion["question"] != null?
                                                                                                    <select id="ddlAnyOfTheFollowing" name="ddlAnyOfTheFollowing"
                                                                                                            value={this.props.state.ddlAnyOfTheFollowing} onChange={this.props.HandleChange}>
                                                                                                        <option value="">Select</option>
                                                                                                        {this.props.state.anyOfFollowingQuestion["ecAnswerSets"].map((item, index) => (
                                                                                                            <option value={item["answerId"]}>{item["answerValue"]}</option>
                                                                                                        ))}
                                                                                                    </select>:''
                                                                                                }
                                                                                                <select id="ddlAnyOfTheFollowing" name="ddlAnyOfTheFollowing" value={this.props.state.ddlAnyOfTheFollowing} onChange={this.props.HandleChange}>
                                                                                                    <option value="">Select</option>
                                                                                                    <option value="89">Yes</option>
                                                                                                    <option value="90">No</option>
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-8 col-md-8 col-sm-6 mt-1">
                                                                                    <div className="inner_left">
                                                                                        {/*<label>Is this program (or its related defense articles) for attitude determination and control systems designed to provide spacecraft pointing determination and control or payload pointing system control better than 0.02 degrees per axis?</label>*/}
                                                                                        <label>{this.props.state.spacecraftPointingQuestion["question"]}</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-3 col-md-3 col-sm-6 mt-1">
                                                                                    <div className="inner_right">
                                                                                        <div className="form-group">
                                                                                            <div className="custom_select">

                                                                                                {this.props.state.spacecraftPointingQuestion["question"] != null?
                                                                                                    <select id="ddlSpacecraftPointingDetermination" name="ddlSpacecraftPointingDetermination"
                                                                                                            value={this.props.state.ddlSpacecraftPointingDetermination} onChange={this.props.HandleChange}>
                                                                                                        <option value="">Select</option>
                                                                                                        {this.props.state.spacecraftPointingQuestion["ecAnswerSets"].map((item, index) => (
                                                                                                            <option value={item["answerId"]}>{item["answerValue"]}</option>
                                                                                                        ))}
                                                                                                    </select>:''
                                                                                                }
                                                                                                <select id="ddlSpacecraftPointingDetermination" name="ddlSpacecraftPointingDetermination" value={this.props.state.ddlSpacecraftPointingDetermination} onChange={this.props.HandleChange}>
                                                                                                    <option value="">Select</option>
                                                                                                    <option value="91">Yes</option>
                                                                                                    <option value="92">No</option>
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-8 col-md-8 col-sm-6 mt-1">
                                                                                    <div className="inner_left">
                                                                                        {/*<label>Is this program (or its related defense articles) for propulsion systems which permit acceleration of the satellite on-orbit (i.e., after mission orbit injection) at rates greater than 0.1 g?</label>*/}
                                                                                        <label>{this.props.state.propulsionSystemsQuestion["question"]}</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-3 col-md-3 col-sm-6 mt-1">
                                                                                    <div className="inner_right">
                                                                                        <div className="form-group">
                                                                                            <div className="custom_select">

                                                                                                {this.props.state.propulsionSystemsQuestion["question"] != null?
                                                                                                    <select id="ddlPropulsionSystems" name="ddlPropulsionSystems"
                                                                                                            value={this.props.state.ddlPropulsionSystems} onChange={this.props.HandleChange}>
                                                                                                        <option value="">Select</option>
                                                                                                        {this.props.state.propulsionSystemsQuestion["ecAnswerSets"].map((item, index) => (
                                                                                                            <option value={item["answerId"]}>{item["answerValue"]}</option>
                                                                                                        ))}
                                                                                                    </select>:''
                                                                                                }
                                                                                                <select id="ddlPropulsionSystems" name="ddlPropulsionSystems" value={this.props.state.ddlPropulsionSystems} onChange={this.props.HandleChange}>
                                                                                                    <option value="">Select</option>
                                                                                                    <option value="93">Yes</option>
                                                                                                    <option value="94">No</option>
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        : ''}
                                                                        </div>: ''}
                                                                </div>
                                                                : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="account">
                                <div className="header">
                                    <h3>US Encryption Controls</h3>
                                </div>
                                <div className="accoutn_form">
                                    <form action method>
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section border-none">
                                                            <div className="row">
                                                                <div className="col-lg-8 col-md-8 col-sm-6 mt-1">
                                                                    <div className="inner_left">
                                                                        {/*<label>Is the product (i.e. wafer, die, module) and associated technical data (i.e. integrated circuit design data) subject to U.S. encryption controls?</label>*/}
                                                                        <label>{this.props.state.USEncryptionControlQuestion["question"]}</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-sm-6 mt-1">
                                                                    <div className="inner_right">
                                                                        <div className="form-group">
                                                                            <div className="custom_select">

                                                                                {this.props.state.USEncryptionControlQuestion["question"] != null?
                                                                                    <select id="ddlUSEncryptionControls" name="ddlUSEncryptionControls"
                                                                                            value={this.props.state.ddlUSEncryptionControls} onChange={this.props.HandleChange}>
                                                                                        <option value="">Select</option>
                                                                                        {this.props.state.USEncryptionControlQuestion["ecAnswerSets"].map((item, index) => (
                                                                                            <option value={item["answerId"]}>{item["answerValue"]}</option>
                                                                                        ))}
                                                                                    </select>:''
                                                                                }
                                                                                {/*<select id="ddlUSEncryptionControls" name="ddlUSEncryptionControls" value={this.props.state.ddlUSEncryptionControls} onChange={this.props.HandleChange}>
                                                                                    <option value="">Select</option>
                                                                                    <option value="71">Yes</option>
                                                                                    <option value="72">No</option>
                                                                                </select>*/}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {this.props.state.ddlUSEncryptionControls === "71" ?
                                                                <div id="show_hide_div">
                                                                    <div className="row">
                                                                        <div className="col-8 col-md-8 col-sm-6 mt-1">
                                                                            <div className="inner_left">
                                                                                <label>Provide the product (i.e. wafer, die, module) ECCN from the CCATS document</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-4 col-md-4 col-sm-6 mt-1">
                                                                            <div className="inner_right">
                                                                                <div className="form-group new_pos">
                                                                                    <input type="text" className="form-control" name="usecCcatsEccn" id="usecCcatsEccn"
                                                                                    value={this.props.state.usecCcatsEccn} onChange={this.props.HandleChange}/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row align-items-center">
                                                                        <div className="col-8 col-md-8 col-sm-6 mt-1">
                                                                            <div className="inner_left">
                                                                                <label>Provide the CCATS #</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-4 col-md-4 col-sm-6 mt-1">
                                                                            <div className="inner_right">
                                                                                <div className="form-group new_pos">
                                                                                    <input type="text" className="form-control" name="usecCcatsNumber" id="usecCcatsNumber"
                                                                                    value={this.props.state.usecCcatsNumber}  onChange={this.props.HandleChange}/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-8 col-md-8 col-sm-6 mt-1">
                                                                            <div className="inner_left">
                                                                                <label>US Government Review Date</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-4 col-md-4 col-sm-6 mt-1">
                                                                            <div className="form-group new_pos">
                                                                                <div className="dateParentDiv" id="test" name="test">
                                                                                    <DatePicker format="MM/dd/y" calendarIcon={<img src={require('../../../assets/images/calendar.svg')} alt="calendar" width={14} />}
                                                                                    id="usGovReviewDate" name="usGovReviewDate" value={this.props.state.usGovReviewDate} onChange={this.props.HandleDateChange}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-8 col-md-8 col-sm-6 mt-1">
                                                                            <div className="inner_left">
                                                                                <label>Provide the CCATS document</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-4 col-md-4 col-sm-6 mt-1">
                                                                            <input type="file" id="ccatsDocument" name="ccatsDocument" className="" name="name" style={{ fontSize: '12px' }} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-6 mt-1">
                                                                            <div className="inner_left">
                                                                                <label>740.17 sub paragraph for MOSIS deliverable</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6 mt-1" style={{paddingBottom: "13px"}}>
                                                                            <textarea maxLength="2000" type="text" className="form-control" name="mosisDeliverable" id="mosisDeliverable"
                                                                            value={this.props.state.mosisDeliverable} onChange={this.props.HandleChange}></textarea>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-6 mt-1">
                                                                            <div className="inner_left">
                                                                                <label>For deemed export purposes, provide the technology ECCN that relates to the performance/function control for this product that are not associated with its encryption controls.</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6 mt-1" style={{paddingBottom: "13px"}}>
                                                                            <textarea maxLength="2000" type="text" className="form-control" name="eccnTechnology" id="eccnTechnology"
                                                                            value={this.props.state.eccnTechnology} onChange={this.props.HandleChange}></textarea>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="account">
                                <div className="header">
                                    <h3>Authorized Official</h3>
                                </div>
                                <div className="accoutn_form">
                                    <form action method>
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-5 col-md-5 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>First Name / Last Name</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-7 col-md-7 col-sm-6">
                                                                    <div className="inner_right new_pos">
                                                                        <div className="form-group">
                                                                            <input type="text" className="form-control" name="txtAuthName" id="txtAuthName" value={this.props.state.txtAuthName} onChange={this.props.HandleChange}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-5 col-md-5 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Title</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-7 col-md-7 col-sm-6">
                                                                    <div className="inner_right new_pos">
                                                                        <div className="form-group">
                                                                            <input type="text" className="form-control" name="txtAuthTitle" id="txtAuthTitle" value={this.props.state.txtAuthTitle} onChange={this.props.HandleChange}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-5 col-md-5 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Email</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-7 col-md-7 col-sm-6">
                                                                    <div className="inner_right new_pos">
                                                                        <div className="form-group">
                                                                            <input type="email" className="form-control" name="txtAuthEmail" id="txtAuthEmail" value={this.props.state.txtAuthEmail} onChange={this.props.HandleChange}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section border-none">
                                                            <div className="row">
                                                                <div className="col-lg-5 col-md-5 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Telephone Number (ex: 123-456-7890)</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-7 col-md-7 col-sm-6">
                                                                    <div className="inner_right new_pos">
                                                                        <div className="form-group">
                                                                            <input className="form-control" name="txtAuthTelNumber" id="txtAuthTelNumber" value={this.props.state.txtAuthTelNumber} onChange={this.props.HandleChange}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="text-center">
                            <button type="button" className="dash_submit_btn" onClick={this.props.SubmitExport} disabled={this.props.state.saveButtonDisabled}>Submit</button>
                        </div>
                    </div>
                </div>

            </div >
        )
    }
}
