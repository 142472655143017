import React, { Component } from 'react';
import ExportQuestionireInputComponent from './ExportQuestionire'
import { PrConnect } from '../../../library/pr-miniredux';

import { GetCustomerData, GetAllQuestions, GetHTSNCodes, GetITARCat, GetAllQuestionTypes, GetQuestionByTypeId, SaveExportControlData } from '../../../services/export';
import { GetCountryList, getCustomerData} from '../../../services/users';
import { getCustomerDetails } from '../../../services/customerService';

import $ from 'jquery';
import DashboardCtrl from "../Home";
import {ToastsContainer, ToastsContainerPosition, ToastsStore} from "react-toasts";
import PrValidate from "../../../library/pr-controls/pr-validation-full";
import { PrLoader } from "../../../library/loader/pr-loader";
import {getDesignNumberList} from "../../../services/designNumberInfoService"

export class exportQuestionireCtrl extends Component {
    constructor(props) {
        super(props);
        this.fileInput = React.createRef();
        console.log(JSON.parse(localStorage.getItem('okta-token-storage')))
        this.state = {
            answerCodeValueMap:{},
            fetchCustDetails: false,
            custDetails: {},
            fetchCountries: true,
            countrieslist: {},
            country_code: 276,
            fetchQuestions: true,
            questionlist: {},
            fetchHTSNCodes: true,
            HTSNCodelist: {},
            fetchItarCat: true,
            ITARCatlist: {},
            ITARCatListMap: {},
            corp_name: '',
            fetchAllQuestionTypes: true,
            typeId: '',
            fetchQuestionsByTypeId: false,
            fetchCustomerDetails: false,
            postExportControlData:false,

            earQuestionss:[],
            itarQuestions:[],
            USEncryptionQuestions:[],
            endUserQuestions:[],

            //GENERAL QUESTIONS
            shippingAddressQuestion:{},
            payingOrganizationQuestion:{},
            designerCorporationQuestion:{},
            multipleIndeterminateQuestion:{},
            weaponsQuestion:{},
            militarySpaceQuestion:{},

            //new questions
            crvEndUseQuestion:{},
            reExportFlagQuestion:{},

            //EAR QUESTIONS:
            exportAdminQuestion:{},
            exportNonUSQuestion:{},
            mmicQuestion:{},

            //ITAR QUESTIONS:
            internationalTrafficArmsQuestion:{},
            classifiedInformationQuestion:{},
            typeOfPayloadQuestion:{},
            canadianRegisteredQuestion:{},
            antiJamQuestion:{},
            anyOfFollowingQuestion:{},
            spacecraftPointingQuestion:{},
            propulsionSystemsQuestion:{},
            answerIdHashMap:{},

            //US ENCRYPTION CONTROLS QUESTIONS:
            USEncryptionControlQuestion:{},

            txtCustName: '',
            txtAddr1: '',
            txtAddr2: '',
            txtAddrCity: '',
            txtAddrState: '',
            txtAddrZip: '',
            txtAddrCountry: '',
            designRows: [
                {
                    txtDesignNumber: ''
                }
            ],
            designDropDownRows: [
                {
                    txtDesignNumber: ''
                }
            ],
            ddlShippingAddr: '',
            ddlPayingCorpDiffEntity:'',
            shipAddrrows: [
                {
                    txtShipDesignNumber: "",
                    txtShipCorpName: "",
                    txtShipAddr1: "",
                    txtShipAddr2: "",
                    txtShipCity: "",
                    txtShipState: "",
                    txtShipZip: "",
                    ddlShipCountry: "",
                }
            ],
            ddlDesignCompIdentity: '',
            designCompIdenti: [
                {
                    txtDesignCompDesignNumber: "",
                    txtDesignCompCorpName: "",
                    txtDesignCompAddr1: "",
                    txtDesignCompAddr2: "",
                    txtDesignCompCity: "",
                    txtDesignCompState: "",
                    txtDesignCompZip: "",
                    ddlDesignCompCountry: "",
                }
            ],
            payingCompRows: [
                {
                    txtPayingCompDesignNumber: "",
                    txtPayingCompCorpName: "",
                    txtPayingCompAddr1: "",
                    txtPayingCompAddr2: "",
                    txtPayingCompCity: "",
                    txtPayingCompState: "",
                    txtPayingCompZip: "",
                    ddlPayingCompCountry: ""
                }
            ],
            ddlEndUser: '',
            endUserRows: [
                {
                    txtEndUserDesignNumber: "",
                    txtEndUserCorpName: "",
                    txtEndUserAddr1: "",
                    txtEndUserAddr2: "",
                    txtEndUserCity: "",
                    txtEndUserState: "",
                    txtEndUserZip: "",
                    ddlEndUserCountry: ""
                }
            ],
            ddlManufacturedIntendedProduct: '',
            txtManufacturedIntendedProduct: '',
            ddlProductSpeciallyDesigned: '',

            //mew questions
            ddlCrvEndUse: '',
            ddlReExportFlag: '',

            ddlSubjectToTheUSExportAdministration:'',
            txtProductSpeciallyDesignedDesc: '',

            txtCCATSNumber: '',
            txtECCNForDesignTechnology: '',
            txtECCNForFinishedGood: '',
            ddlSubjectToExportControlsNonUs: '',
            txtJurisdictionName: '',
            txtDesignECCN: '',
            txtFinishedWafersECCN: '',
            ddlMwWaveProductMMIC: '',
            ddlPhaseShifterIntegrated: '',
            ddlSpeciallyDesignedProduct: '',
            ddlDeviceCtrlUnderUSMunitions: '',
            ddlITARArms: '',
            ddlUSEncryptionControls: '',

            ddlITARCategory: '',
            ddlProductFabricatedByFoundry:'',
            ddlMultipleIntermediate:'',
            ddlEquipmentConsideredPayload:'',
            ddlEndItemsIntendedExclusivelyCanadian:'',
            ddlAntiJamSystem:'',
            ddlAnyOfTheFollowing:'',
            ddlSpacecraftPointingDetermination:'',
            ddlPropulsionSystems:'',
            specialDesignApplication:'',
            fullyExtendedSuffix:'',

            technicalDataSuffix: '',
            usGovReviewDate: '',
            usecCcatsEccn: '',
            usecCcatsNumber: '',
            eccnTechnology:'',
            endUseDescription:'',
            endUseSuffix:'',
            ddlHTSNCodes:'',
            icSuffix:'',
            mosisDeliverable:'',
            peakSaturatedPower:'',
            operatingFrequency:'',

            txtAuthName:'',
            txtAuthTitle:'',
            txtAuthEmail:'',
            txtAuthTelNumber:'',


            ccatsDocumentFormData:null,
            commodityJurisdictionFileFormData:null,
            graphFileFormData:null,
            commodityFormData:null,
            commodityFile:'',
            selectedFile:null,

            saveButtonDisabled: false,
            redirectToMainPage: false,
            saveExportControlFormData: false,
            fetchDesignNumberList: true,
            designNumberListDisabled: true,
            foundryAndTechClass:{},
            designNumberDropDownCount:0,
            selectedDesignNumbersList:[],

            finalData: {}
        }
    }
    HandleDateChange = (date) => {
        this.setState({
            usGovReviewDate: date
        })
        PrValidate.ForceDate(date, 'test', 'Input')

    }

    HandleChange = (e) => {
        console.log("inside handle change")
        //console.log(e.target.type)
        this.setState({
            [e.target.name]: e.target.value
        })

        if(e.target.type.includes("select")){
            //console.log("Inside yes")
            PrValidate.ForceSelect(e.target.value, e.target.name, 'input');
        }else if(e.target.type.includes("text")){
            //console.log("Inside yes")
            if (e.target.name === 'txtAuthTelNumber') {
                //console.log("Inside phone: "+e.target.value.split("-").join(""))
                /*if(e.target.value.match(/^[\+\d]?(?:[\d-.\s()]*)$/g)){
                    console.log("true")
                }else{
                    console.log("false")
                }*/
                PrValidate.ForceRPhone(e.target.value.split("-").join(""), e.target.name, 'Phone');
                //PrValidate.ForceRPhone(value, field, msg);
            }else{
                PrValidate.ForceEntry(e.target.value, e.target.name, 'input');
            }
        }else if(e.target.type.includes("email")) {
            if (e.target.name === 'txtAuthEmail') {
                //console.log("inside email")
                PrValidate.ForceEmail(e.target.value, e.target.name, '');
            }
        }

    }

    HandleDesignNumberDropDownChange = (e) => {

        console.log("designRows: "+this.state.designRows.length)
        console.log("designDropDownRows: "+this.state.designDropDownRows.length)
        console.log("selectedDesignNumber: "+this.state.selectedDesignNumber)
        this.setState({
            [e.target.name]: e.target.value
        })


        this.state.selectedDesignNumbersList.push(e.target.value)
        console.log("selectedDesignNumbersList: "+JSON.stringify(this.state.selectedDesignNumbersList))
        /*$("#selectedDesignNumber").each(function() {
            if ( $(this).val() == e.target.value) {
                $(this).remove();
            }
        });*/

        var tr = document.createElement('tr');
        var td = document.createElement('td');

        this.state.designNumberDropDownCount = this.state.designNumberDropDownCount+1
        td.appendChild(this.addTableTdWithDesignNumberSelect(this.state.foundryAndTechClass, this.state.selectedDesignNumbersList,this.state.designNumberDropDownCount))
        tr.appendChild(td)

        let tbody = document.getElementById("designNumberDropDownTable")
        tbody.appendChild(tr)


        {/*<tr>
            <td className="col-sm-8 col-md-4">*/}
            let designNumbers

        let designNumberDropDownId = this.state.designNumberDropDownCount-1 == 0?"selectedDesignNumber":"selectedDesignNumber_"+(this.state.designNumberDropDownCount-1)
        console.log("designNumberDropDownId: "+designNumberDropDownId)
        $("#"+designNumberDropDownId).attr("disabled","true")

        console.log("designNumberDropDownId+1: "+"selectedDesignNumber_"+this.state.designNumberDropDownCount)
        let that = this
        this.state.selectedDesignNumbersList.map(function (item) {
            $("#"+("selectedDesignNumber_"+that.state.designNumberDropDownCount)+" option").each(function() {
                if ( $(this).val() == item) {
                    $(this).remove();
                }
            });
        })
        this.AddressRowsAddition(e)
        /*let indexToRemove = ''
        console.log("foundryAndTechClass length: "+this.state.foundryAndTechClass.length)
        let changedFoundryAndTechClass = this.state.foundryAndTechClass
        changedFoundryAndTechClass.map(function (item, index) {
            if (item["designNumber"] == e.target.value) {
                indexToRemove = index;
                $("#"+i+" option").each(function() {
                    if ( $(this).val() == e.target.value) {
                        $(this).remove();
                    }
                });
            }
        })
        this.state.foundryAndTechClass.splice(indexToRemove,1)
        console.log("foundryAndTechClass: "+JSON.stringify(this.state.foundryAndTechClass))
        console.log("foundryAndTechClass length: "+this.state.foundryAndTechClass.length)
        */


        console.log("selectedDesignNumber: "+this.state.selectedDesignNumber)
    }


    addTableTdWithDesignNumberSelect = (designNumbers, selectedDesignNumbersList, number) => {

        
        var td1 = document.createElement('div');
        td1.className = 'custom_select';
        //td1.width = '50%';

        var td2 = document.createElement('div');
        td2.className = 'form-group';
        //td1.width = '50%';

        var td3 = document.createElement('div');
        td3.className = 'inner_right';
        //td1.width = '50%';

        var td4 = document.createElement('div');
        td4.className = 'col-lg-8 col-md-8 col-sm-6';
        // td1.width = '50%';


        var selectList = document.createElement("select");
        //selectList.id = "opt_select_"+ opt.foundryService["foundryServiceId"];
        selectList.id = "selectedDesignNumber_"+ number;
        //selectList.style="width: 75px;";
        td1.appendChild(selectList);
        td2.appendChild(td1);
        td3.appendChild(td2);
        td4.appendChild(td3);

        let that = this
        selectList.addEventListener('change', function(e){
            that.HandleDesignNumberDropDownChange(e);
        }, false);

        var option1 = document.createElement("option");
        option1.value = 'SELECT';
        option1.text = 'Select';
        selectList.appendChild(option1);

        designNumbers.map(function (item) {
            var option1 = document.createElement("option");
            option1.value = item["designNumber"];
            option1.text = item['designNumber'];
            selectList.appendChild(option1);
        })



        return td4;
    }

    HandleITARChange = (e) => {

        this.state.ddlITARCategory = e.target.value
        console.log("Inside HandleITARChange: "+this.state.ddlITARCategory)
        console.log("Value: "+this.state.ITARCatListMap[e.target.value])
        this.setState({
            [e.target.name]: e.target.value
        })
        PrValidate.ForceSelect(e.target.value, e.target.name, 'Input');
    }

    HandleDesignNumberChange = (e, i, key = "") => {
        var designRows = Array.from(this.state.designRows);
        const re = /^[0-9\b]+$/;
        // if value is not blank, then test the regex
        let val = e.target.value.replace(/[^0-9]/g, "");
        if (val === "" || re.test(val)) {
            val = val.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1");
            designRows[i][key] = val;
        }
        this.setState({ designRows: designRows });
        PrValidate.ForceEntry(e.target.value, e.target.name, 'Input')
    };

    HandleShipAddrRowsChange = (e, i, key = "") => {
        var shipAddrrows = Array.from(this.state.shipAddrrows);
        // const re = /^[0-9\b]+$/;
        // // if value is not blank, then test the regex
        // let val = e.target.value.replace(/[^0-9]/g, "");
        // if (val === "" || re.test(val)) {
        //     val = val.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        //     shipAddrrows[i][key] = val;
        // }
        shipAddrrows[i][key] = e.target.value;
        this.setState({ shipAddrrows: shipAddrrows });

        if(key === 'ddlShipCountry'){
            PrValidate.ForceSelect(e.target.value, e.target.name, 'Input');    
        }else{
            PrValidate.ForceEntry(e.target.value, e.target.name, 'Input');
        }
        
    };

    HandleDesignCompRowsChange = (e, i, key = "") => {
        var designCompIdenti = Array.from(this.state.designCompIdenti);
        // const re = /^[0-9\b]+$/;
        // // if value is not blank, then test the regex
        // let val = e.target.value.replace(/[^0-9]/g, "");
        // if (val === "" || re.test(val)) {
        //     val = val.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        //     designCompIdenti[i][key] = val;
        // }
        designCompIdenti[i][key] = e.target.value
        this.setState({ designCompIdenti: designCompIdenti });
        if(key === 'ddlDesignCompCountry'){
            PrValidate.ForceSelect(e.target.value, e.target.name, 'Input');
        }else{
            PrValidate.ForceEntry(e.target.value, e.target.name, 'Input');
        }
    };

    HandlePayingCompRowsChange = (e, i, key = "") => {
        var payingCompRows = Array.from(this.state.payingCompRows);
        // const re = /^[0-9\b]+$/;
        // // if value is not blank, then test the regex
        // let val = e.target.value.replace(/[^0-9]/g, "");
        // if (val === "" || re.test(val)) {
        //     val = val.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        //     payingCompRows[i][key] = val;
        // }
        payingCompRows[i][key] = e.target.value
        this.setState({ payingCompRows: payingCompRows });
        if(key === 'ddlPayingCompCountry'){
            PrValidate.ForceSelect(e.target.value, e.target.name, 'Input');
        }else{
            PrValidate.ForceEntry(e.target.value, e.target.name, 'Input');
        }
    };

    HandleEndUserRowsChange = (e, i, key = "") => {
        var endUserRows = Array.from(this.state.endUserRows);
        // const re = /^[0-9\b]+$/;
        // // if value is not blank, then test the regex
        // let val = e.target.value.replace(/[^0-9]/g, "");
        // if (val === "" || re.test(val)) {
        //     val = val.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        //     endUserRows[i][key] = val;
        // }
        endUserRows[i][key] = e.target.value;
        this.setState({ endUserRows: endUserRows });
            if(key === 'ddlEndUserCountry'){
                PrValidate.ForceSelect(e.target.value, e.target.name, 'Input');
            }else{
                PrValidate.ForceEntry(e.target.value, e.target.name, 'Input');
            }

    };

    HandleDynamicChange = (e, i, key = "") => {
        var rows = Array.from(this.state.rows);
        if (key === "number" || key === "numberWithSign") {
            const re = /^[0-9\b]+$/;
            // if value is not blank, then test the regex
            let val = e.target.value.replace(/[^0-9]/g, "");
            if (val === "" || re.test(val)) {
                val = val.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
                if (key === "numberWithSign") {
                    if (val !== "") {
                        val = "$" + val;
                    }
                }
                rows[i][key] = val;
            }
        }
        else if (key === "date") {
            rows[i][key] = e;
        }
        else if (key === "search") {
            this.props.PrStore.SetData('generalPurposeSearchData', e.target.value)
            rows[i][key] = e.target.value;
        }
        else {
            rows[i][key] = e.target.value;
        }
        this.setState({ rows: rows });
    };

    AddressRowsAddition = e => {
        if (this.state.designDropDownRows.length <= 10) {
            var rows = this.state.designDropDownRows.concat({
                txtDesignNumber: e.target.value
            });
            this.setState({ designDropDownRows: rows });
        }
        /*if (this.state.designRows.length <= 10) {
            var rows = this.state.designRows.concat({
                txtDesignNumber: ""
            });
            this.setState({ designRows: rows });
        }*/
        //console.log("txtDesignNumber: "+this.props.state.designRows[2]["txtDesignNumber"])
        if (this.state.shipAddrrows.length <= 10) {
            var rows = this.state.shipAddrrows.concat({
                txtShipDesignNumber: "",
                txtShipCorpName: "",
                txtShipAddr1: "",
                txtShipAddr2: "",
                txtShipCity: "",
                txtShipState: "",
                txtShipZip: "",
                ddlShipCountry: "",
            });
            this.setState({ shipAddrrows: rows });
        }
        if (this.state.designCompIdenti.length <= 10) {
            var rows = this.state.designCompIdenti.concat({
                txtDesignCompDesignNumber: "",
                txtDesignCompCorpName: "",
                txtDesignCompAddr1: "",
                txtDesignCompAddr2: "",
                txtDesignCompCity: "",
                txtDesignCompState: "",
                txtDesignCompZip: "",
                ddlDesignCompCountry: "",
            });
            this.setState({ designCompIdenti: rows });
        }
        if (this.state.payingCompRows.length <= 10) {
            var rows = this.state.payingCompRows.concat({
                txtPayingCompDesignNumber: "",
                txtPayingCompCorpName: "",
                txtPayingCompAddr1: "",
                txtPayingCompAddr2: "",
                txtPayingCompCity: "",
                txtPayingCompState: "",
                txtPayingCompZip: "",
                ddlPayingCompCountry: "",
            });
            this.setState({ payingCompRows: rows });
        }
        if (this.state.endUserRows.length <= 10) {
            var rows = this.state.endUserRows.concat({
                txtEndUserDesignNumber: "",
                txtEndUserCorpName: "",
                txtEndUserAddr1: "",
                txtEndUserAddr2: "",
                txtEndUserCity: "",
                txtEndUserState: "",
                txtEndUserZip: "",
                ddlEndUserCountry: ""
            });
            this.setState({ endUserRows: rows });
        }
    };

    AddDesignRow = e => {
        if (this.state.designRows.length <= 10) {
            var rows = this.state.designRows.concat({
                txtDesignNumber: ""
            });
            this.setState({ designRows: rows });
        }
        if (this.state.shipAddrrows.length <= 10) {
            var rows = this.state.shipAddrrows.concat({
                txtShipDesignNumber: "",
                txtShipCorpName: "",
                txtShipAddr1: "",
                txtShipAddr2: "",
                txtShipCity: "",
                txtShipState: "",
                txtShipZip: "",
                ddlShipCountry: "",
            });
            this.setState({ shipAddrrows: rows });
        }
        if (this.state.designCompIdenti.length <= 10) {
            var rows = this.state.designCompIdenti.concat({
                txtDesignCompDesignNumber: "",
                txtDesignCompCorpName: "",
                txtDesignCompAddr1: "",
                txtDesignCompAddr2: "",
                txtDesignCompCity: "",
                txtDesignCompState: "",
                txtDesignCompZip: "",
                ddlDesignCompCountry: "",
            });
            this.setState({ designCompIdenti: rows });
        }
        if (this.state.payingCompRows.length <= 10) {
            var rows = this.state.payingCompRows.concat({
                txtPayingCompDesignNumber: "",
                txtPayingCompCorpName: "",
                txtPayingCompAddr1: "",
                txtPayingCompAddr2: "",
                txtPayingCompCity: "",
                txtPayingCompState: "",
                txtPayingCompZip: "",
                ddlPayingCompCountry: "",
            });
            this.setState({ payingCompRows: rows });
        }
        if (this.state.endUserRows.length <= 10) {
            var rows = this.state.endUserRows.concat({
                txtEndUserDesignNumber: "",
                txtEndUserCorpName: "",
                txtEndUserAddr1: "",
                txtEndUserAddr2: "",
                txtEndUserCity: "",
                txtEndUserState: "",
                txtEndUserZip: "",
                ddlEndUserCountry: ""
            });
            this.setState({ endUserRows: rows });
        }
    };
    DeleteDesignRow = (e, i) => {
        if (this.state.designRows.length > 1) {
            var rows = this.state.designRows;
            rows.splice(i, 1);
            this.setState({ designRows: rows });
        }
        if (this.state.shipAddrrows.length > 1) {
            var rows = this.state.shipAddrrows;
            rows.splice(i, 1);
            this.setState({ shipAddrrows: rows });
        }
        if (this.state.designCompIdenti.length > 1) {
            var rows = this.state.designCompIdenti;
            rows.splice(i, 1);
            this.setState({ designCompIdenti: rows });
        }
        if (this.state.payingCompRows.length > 1) {
            var rows = this.state.payingCompRows;
            rows.splice(i, 1);
            this.setState({ payingCompRows: rows });
        }
        if (this.state.endUserRows.length > 1) {
            var rows = this.state.endUserRows;
            rows.splice(i, 1);
            this.setState({ endUserRows: rows });
        }
    };

    HandleSuccessCustDetailsResponse = (res) => {
        this.setState({
            fetchCustDetails: false,
            custDetails: res.data,
        })
        this.SetDefaultData();
        console.log("res.data: "+JSON.stringify(res.data))
        console.log("customerName: "+this.state.custDetails.customerName)
    }

 /*   SetDefaultData = () => {
        let country_name = '';
        let ecCorporates = this.state.custDetails[0]['ecCorporates'][0];
        (this.state.countrieslist).forEach(element => {
            if (element.codeId === ecCorporates['country'])
                country_name = element.codeName
        });
        this.setState({
            txtAddr1: ecCorporates['addressLine1'],
            txtAddr2: ecCorporates['addressLine2'],
            txtAddrCity: ecCorporates['city'],
            txtAddrState: ecCorporates['stateProvince'],
            txtAddrZip: ecCorporates['zipCode'],
            txtAddrCountry: country_name,
            country_code: this.state.custDetails[0]['ecCorporates'][0]['country'],
            corp_name: this.state.custDetails[0]['ecCorporates'][0]['corporateName'],
            txtCustName: this.state.custDetails[0].customer["customerName"]
        })

    }*/

    SetDefaultData = (custDetails) => {
        console.log("Inside Set Default ==============="+JSON.stringify(this.state.countrieslist))
        console.log("this.state.custDetails: "+JSON.stringify(custDetails))
        let country_name = '';
        let ecCorporates = {}
        let cetifyingOfficer = {}
        this.state.custDetails.customerAddresses.forEach(element => {
            if(element.addressTypeObject["codeValue"].includes("Mailing Address")){
                ecCorporates = element
            }
        });

        this.state.custDetails.customerContacts.forEach(element => {
            if(element.contactTypeObject["codeValue"].includes("Certifying Officer")){
                cetifyingOfficer = element
            }
        });

        (this.state.countrieslist).forEach(element => {
            if (element.codeId === ecCorporates['country'])
                country_name = element.codeName
        });
        this.setState({
            txtAddr1: ecCorporates['addressLine1'],
            txtAddr2: ecCorporates['addressLine2'],
            txtAddrCity: ecCorporates['city'],
            txtAddrState: ecCorporates['state'],
            txtAddrZip: ecCorporates['zip'],
            txtAddrCountry: country_name,
            country_code: ecCorporates["country"],
            corp_name: this.state.custDetails.customerName,
            txtCustName: this.state.custDetails.customerName,

            txtAuthName: cetifyingOfficer["firstName"]? cetifyingOfficer["firstName"]:''+" "+cetifyingOfficer["lastName"]? cetifyingOfficer["lastName"]:'',
            txtAuthTitle: cetifyingOfficer["title"]? cetifyingOfficer["title"]:'',
            txtAuthEmail: cetifyingOfficer["email"]? cetifyingOfficer["email"]:'',
            txtAuthTelNumber: cetifyingOfficer["phoneNumber"]? cetifyingOfficer["phoneNumber"]:''
        })
    }

    HandleSuccessGetCountriesResponse = (res) => {
        console.log("HandleSuccessGetCountriesResponse: "+JSON.stringify(res.data))

        let USElement = {}
        res.data.forEach((element, index) => {
            if(element.codeName == "United States"){
                USElement = element
            }
        })
        res.data.sort(function(x,y){ return x == USElement ? -1 : y == USElement ? 1 : 0; });

        console.log("res.data: "+JSON.stringify(res.data))
        this.setState({
            //fetchCustDetails: true,
            fetchCountries: false,
            countrieslist: res.data,
            fetchCustomerDetails: true
        })
    }

    CopyAddrFromCustomer = (copyAt) => {
        // alert(JSON.stringify(this.state.payingCompRows));
        if (copyAt === 'paying') {
            let data = this.state.payingCompRows[0];
            data.txtPayingCompDesignNumber = this.state.designRows[0].txtDesignNumber;
            data.txtPayingCompCorpName = this.state.corp_name;
            data.txtPayingCompAddr1 = this.state.txtAddr1;
            data.txtPayingCompAddr2 = this.state.txtAddr2;
            data.txtPayingCompCity = this.state.txtAddrCity;
            data.txtPayingCompState = this.state.txtAddrState;
            data.txtPayingCompZip = this.state.txtAddrZip;
            data.ddlPayingCompCountry = this.state.country_code;
        }
        else if (copyAt === 'endUser') {
            let data = this.state.endUserRows[0];
            data.txtEndUserDesignNumber = this.state.designRows[0].txtDesignNumber;
            data.txtEndUserCorpName = this.state.corp_name;
            data.txtEndUserAddr1 = this.state.txtAddr1;
            data.txtEndUserAddr2 = this.state.txtAddr2;
            data.txtEndUserCity = this.state.txtAddrCity;
            data.txtEndUserState = this.state.txtAddrState;
            data.txtEndUserZip = this.state.txtAddrZip;
            data.ddlEndUserCountry = this.state.country_code;
        }

        this.setState({

        })
    }

    CopyFromFirstRow = (fieldName) => {
        if (fieldName === 'paying') {
            (this.state.payingCompRows).forEach((element, index) => {
                if (index > 0) {
                    let copy_data = this.state.payingCompRows[0];
                    let data = this.state.payingCompRows[index];
                    data.txtPayingCompDesignNumber = this.state.designRows[index].txtDesignNumber;
                    data.txtPayingCompCorpName = copy_data.txtPayingCompCorpName;
                    data.txtPayingCompAddr1 = copy_data.txtPayingCompAddr1;
                    data.txtPayingCompAddr2 = copy_data.txtPayingCompAddr2;
                    data.txtPayingCompCity = copy_data.txtPayingCompCity;
                    data.txtPayingCompState = copy_data.txtPayingCompState;
                    data.txtPayingCompZip = copy_data.txtPayingCompZip;
                    data.ddlPayingCompCountry = copy_data.ddlPayingCompCountry;
                }
            });
        }
        else if (fieldName === 'endUser') {
            (this.state.endUserRows).forEach((element, index) => {
                if (index > 0) {
                    let copy_data = this.state.endUserRows[0];
                    let data = this.state.endUserRows[index];
                    data.txtEndUserDesignNumber = this.state.designRows[index].txtDesignNumber;;
                    data.txtEndUserCorpName = copy_data.txtEndUserCorpName;
                    data.txtEndUserAddr1 = copy_data.txtEndUserAddr1;
                    data.txtEndUserAddr2 = copy_data.txtEndUserAddr2;
                    data.txtEndUserCity = copy_data.txtEndUserCity;
                    data.txtEndUserState = copy_data.txtEndUserState;
                    data.txtEndUserZip = copy_data.txtEndUserZip;
                    data.ddlEndUserCountry = copy_data.ddlEndUserCountry;
                }
            });
        }
        else if (fieldName === 'ship') {
            (this.state.shipAddrrows).forEach((element, index) => {
                if (index > 0) {
                    let copy_data = this.state.shipAddrrows[0];
                    let data = this.state.shipAddrrows[index];
                    data.txtShipDesignNumber = this.state.designRows[index].txtDesignNumber;;
                    data.txtShipCorpName = copy_data.txtShipCorpName;
                    data.txtShipAddr1 = copy_data.txtShipAddr1;
                    data.txtShipAddr2 = copy_data.txtShipAddr2;
                    data.txtShipCity = copy_data.txtShipCity;
                    data.txtShipState = copy_data.txtShipState;
                    data.txtShipZip = copy_data.txtShipZip;
                    data.ddlShipCountry = copy_data.ddlShipCountry;
                }
            });
        }
        else if (fieldName === 'design') {
            (this.state.designCompIdenti).forEach((element, index) => {
                if (index > 0) {
                    let copy_data = this.state.designCompIdenti[0];
                    let data = this.state.designCompIdenti[index];
                    data.txtDesignCompDesignNumber = this.state.designRows[index].txtDesignNumber;;
                    data.txtDesignCompCorpName = copy_data.txtDesignCompCorpName;
                    data.txtDesignCompAddr1 = copy_data.txtDesignCompAddr1;
                    data.txtDesignCompAddr2 = copy_data.txtDesignCompAddr2;
                    data.txtDesignCompCity = copy_data.txtDesignCompCity;
                    data.txtDesignCompState = copy_data.txtDesignCompState;
                    data.txtDesignCompZip = copy_data.txtDesignCompZip;
                    data.ddlDesignCompCountry = copy_data.ddlDesignCompCountry;
                }
            });
        }

        this.setState({})
    }

    HandleSuccessGetQuestionResponse = (res) => {
        console.log("RESPONSE HandleSuccessGetQuestionResponse: "+JSON.stringify(res.data))

        let that = this
/*        res.data.map(function (item) {
            let ecAnswerSets = []
            item.map(function (item1) {
                if(item1.ecAnswerSets["answerValue"] === "Yes"){
                    let filteredItem = item1
                }
            })

            item.map(function (item1) {
                if(item1.ecAnswerSets["answerValue"] === "Yes"){
                    let filteredItem = item1
                }
            })

                return obj.ecAnswerSets["answerValue"] === "Yes";
            });
            console.log("filteredData: "+JSON.stringify(filteredData))
        })*/
    let modifiedResponse = []
    res.data.map(function (item) {
        let answerSets = []
        var filteredData = item.ecAnswerSets.filter(function (obj, index) {
            return obj["answerValue"] === "Yes";
        });

        let that = this
        if(filteredData != null && filteredData.length > 0) {
            answerSets.push(filteredData[0])
            item.ecAnswerSets.forEach(function (element, index) {
                if (element["answerValue"] != "Yes") {
                    answerSets.push(element);
                }
            })
        }
        console.log("answerSets: "+JSON.stringify(answerSets))
        item.ecAnswerSets = null
        item.ecAnswerSets = answerSets
        modifiedResponse.push(item)
    })

        console.log("modifiedResponse: "+JSON.stringify(modifiedResponse))

        modifiedResponse.map(function (item) {
            if(item.ecAnswerSets){
                item.ecAnswerSets.map(function(item){
                    that.state.answerIdHashMap[item["answerId"]] = item["answerValue"]
                })
            }
        })

        console.log("this.state.answerIdHashMap: "+JSON.stringify(this.state.answerIdHashMap))



    /*let that = this
    let ecAnswerSets = []
    ecAnswerSets.push(filteredData)
    res.data.forEach(function (element, index) {
        if(obj.ecAnswerSets["answerValue"] != filteredData[0]["codeId"]){
            that.state.requestedFileTypes.push(element);
        }
    })

    if(filteredData != null && filteredData.length > 0){
        this.state.requestedFileTypes.push(filteredData[0]);
    }
    console.log("filteredData: "+JSON.stringify(filteredData))

    this.setState({
        requestedFileTypes: this.state.requestedFileTypes
    })
    console.log("HandeSuccessGetCodesByCodeType: "+JSON.stringify(this.state.requestedFileTypes))
*/

    console.log("modifiedResponse: "+JSON.stringify(modifiedResponse))
        modifiedResponse.map(function (item) {
            that.fillQuestions(item)
            that.mapAnswers(item)
          if(item.ecQuestionType["questionTypeName"] == "General Questions"){
              that.state.endUserQuestions.push(item)
          }else if(item.ecQuestionType["questionTypeName"] == "EAR Questions"){
              that.state.earQuestionss.push(item)
          }else if(item.ecQuestionType["questionTypeName"] == "ITAR Questions"){
              that.state.itarQuestions.push(item)
          }else if(item.ecQuestionType["questionTypeName"] == "US Encryption Control Questions"){
              that.state.USEncryptionQuestions.push(item)
          }
        })

        console.log("endUserQuestions: "+JSON.stringify(this.state.endUserQuestions))
        console.log("===============================================")
        console.log("earQuestionss: "+JSON.stringify(this.state.earQuestionss))
        console.log("===============================================")
        console.log("itarQuestions: "+JSON.stringify(this.state.itarQuestions))
        console.log("===============================================")
        console.log("USEncryptionQuestions: "+JSON.stringify(this.state.USEncryptionQuestions))
        console.log("===============================================")

        console.log("this.state.exportAdminQuestion: "+JSON.stringify(this.state.exportAdminQuestion))
        console.log("this.state.USEncryptionControlQuestion: "+JSON.stringify(this.state.USEncryptionControlQuestion))
        console.log("multipleIndeterminateQuestion: "+JSON.stringify(this.state.multipleIndeterminateQuestion))
        console.log("this.state.answerCodeValueMap: "+JSON.stringify(this.state.answerCodeValueMap))


        console.log("this.state.shippingAddressQuestion: "+JSON.stringify(this.state.shippingAddressQuestion["ecAnswerSets"]))
        console.log("this.state.designerCorporationQuestion: "+JSON.stringify(this.state.designerCorporationQuestion["ecAnswerSets"]))
        console.log("this.state.payingOrganizationQuestion: "+JSON.stringify(this.state.payingOrganizationQuestion["ecAnswerSets"]))
        console.log("this.state.multipleIndeterminateQuestion: "+JSON.stringify(this.state.multipleIndeterminateQuestion["ecAnswerSets"]))



    }

    mapAnswers = (obj) => {
        let that = this
        obj.ecAnswerSets.map(function (item) {
            that.state.answerCodeValueMap[item["answerId"]] = item["answerValue"];
        })
    }

    fillQuestions = (obj) => {
        console.log("INSIDE fillQuestions: "+obj.question)

        //GENERAL QUESTIONS
        if(obj.question.includes("Is the shipping address different than the mailing address listed above")){
            console.log("INSIDE ELSE IF 1")
            this.state.shippingAddressQuestion = obj
        }else if(obj.question.includes("Is the Paying Corporation a different entity")){
            console.log("INSIDE ELSE IF 2")
            this.state.payingOrganizationQuestion = obj
        }else if(obj.question.includes("Is the Designer Corporation a different entity")){
            console.log("INSIDE ELSE IF 3")
            this.state.designerCorporationQuestion = obj
        }else if(obj.question.includes("Is the product (i.e. wafer, die, module) a stocked item for multiple indeterminate customers")){
            console.log("INSIDE ELSE IF 4")
            this.state.multipleIndeterminateQuestion = obj
        }else if(obj.question.includes("Is the Product to be manufactured intended or likely to be used, wholly, or in part, for or in")){
            //general question
            console.log("INSIDE ELSE IF 5")
            this.state.weaponsQuestion = obj
        }else if(obj.question.includes("Is this product specially designed, modified, configured or ad")){
            //general question
            console.log("INSIDE ELSE IF 6")
            this.state.militarySpaceQuestion = obj
        //}else if(obj.question.includes("Is the product to be delivered by GF intended for an end user or end use that is located in China, Russia, Venezuela")){
        }else if(obj.question.includes("Is the product to be delivered by the foundry intended for an end user or end use that is located in China, Russia, Venezuela")){
            //general question
            //NEW QUESTIONS - CRV question
            this.state.crvEndUseQuestion = obj
        }else if(obj.question.includes("foundry will be exported outside the United States, re-exported or transferred either")){
            //general question
            //NEW QUESTIONS - Reexported question
            this.state.reExportFlagQuestion = obj
        }else if(obj.question.includes("U.S. Export Administration Regulations")){
            console.log("INSIDE ELSE IF 7")
            this.state.exportAdminQuestion = obj
        }else if(obj.question.includes("Is the product (i.e. wafer, die, module) or its associated technical data (i.e. integrated circuit design data) subject to export controls")){
            console.log("INSIDE ELSE IF 8")
            this.state.exportNonUSQuestion = obj
        }else if(obj.question.includes("Is this product a millimeter wave (mwWave) or")){
            console.log("INSIDE ELSE IF 9")
            this.state.mmicQuestion = obj
        }

        //ITAR QUESTIONS:
        else if(obj.question.includes("Is the product (i.e. wafer, die, module) and the associated technology/technical data (i.e. integrated circuit design data) subject to the International Traffic in Arms")){
            console.log("INSIDE ELSE IF 10")
            this.state.internationalTrafficArmsQuestion = obj
        }else if(obj.question.includes("Is the product, being fabricated by the foundry, designed or developed using classified information")){
            console.log("INSIDE ELSE IF 11")
            this.state.classifiedInformationQuestion = obj
        }
        else if(obj.question.includes("Is this end use equipment considered a commercial communications satellite that has no other type of payload")){
            console.log("INSIDE ELSE IF 12")
            this.state.typeOfPayloadQuestion = obj
        }else if(obj.question.includes("Are the end-items intended exclusively for end-use by the Federal Government of Canada that will be exported")){
            console.log("INSIDE ELSE IF 13")
            this.state.canadianRegisteredQuestion = obj
        }else if(obj.question.includes("Is this program (or its related defense articles) for an anti-jam system with the ability to respond to incoming interference by adaptively reducing antenna gain")){
            console.log("INSIDE ELSE IF 14")
            this.state.antiJamQuestion = obj
        }else if(obj.question.includes("Is this program (or its related defense articles) for antennas having any of the following")){
            console.log("INSIDE ELSE IF 15")
            this.state.anyOfFollowingQuestion = obj
        }else if(obj.question.includes("Is this program (or its related defense articles) for attitude determination and control systems designed to provide spacecraft pointing determination and control or payload pointing")){
            console.log("INSIDE ELSE IF 16")
            this.state.spacecraftPointingQuestion = obj
        }else if(obj.question.includes("Is this program (or its related defense articles) for propulsion systems which permit acceleration of the satellite on-orbit (i.e., after mission orbit injection) at rates greater than 0.1 g")){
            console.log("INSIDE ELSE IF 17")
            this.state.propulsionSystemsQuestion = obj
        }

        //US ENCRYPTION CONTROLS QUESTIONS:
        else if(obj.question.includes("Is the product (i.e. wafer, die, module) and associated technical data (i.e. integrated circuit design data) subject to U.S. encryption controls")){
            console.log("INSIDE ELSE IF 18")
            this.state.USEncryptionControlQuestion = obj
        }

        /*MosisCodesDelegate mosisCodesDelegate = new MosisCodesDelegate();
        form.setHtsnList(new ArrayList<>(Arrays.asList(mosisCodesDelegate.codesFindAllByCodeType("M_HTSN_Codes"))));
        form.setItarCategories(getSortedItarCategories());

        if (form.getItarCategories() != null) {
            form.getItarCategories().forEach(o -> {
                if (o != null) {
                    o.setCodeName(o.getCodeValue() + " " + o.getCodeName());
                }
            });
        }
        form.setCountryList(new ArrayList<>(Arrays.asList(mosisCodesDelegate.codesFindAllByCodeType("M_COUNTRY"))));*/

    }

    HandleSuccessGetHTSNCodesResponse = (res) => {
        this.setState({
            HTSNCodelist: res.data,
            fetchHTSNCodes: false
        })
        console.log("RESPONSE HandleSuccessGetHTSNCodesResponse: "+JSON.stringify(res.data))
    }

    HandleSuccessGetITARCatResponse = (res) => {
        (res.data).sort((a, b) => (a.codeId > b.codeId) ? 1 : ((b.codeId > a.codeId) ? -1 : 0));

        let that = this
        res.data.map(function (item) {
            that.state.ITARCatListMap[item["codeId"]] = item["codeValue"]
        })
        this.setState({
            ITARCatlist: res.data,
            fetchItarCat: false,
            ITARCatListMap: this.state.ITARCatListMap
        })

        console.log("RES: "+JSON.stringify(res.data))
    }

    HandleSuccessGetAllQuestionTypesResponse = (res) => {
        console.log("RESPONSE HandleSuccessGetAllQuestionTypesResponse: "+JSON.stringify(res.data))

        for(let i=1; i<=4; i++){
            this.state.typeId = i
            this.setState({
                typeId: i,
                fetchQuestionsByTypeId: true
            })
        }
    }

    HandleSuccessGetQuestionByTypeIdResponse = (res) => {
        console.log("RESPONSE HandleSuccessGetQuestionByTypeIdResponse: "+JSON.stringify(res.data))
    }

    HandleSuccessGetCustomerDetailsResponse = (res) => {
        console.log("Customer Details: "+JSON.stringify(res.data))
        this.setState({
            fetchCustomerDetails: false,
            custDetails: res.data,
        })
        this.SetDefaultData();
        console.log("res.data: "+JSON.stringify(res.data))
        console.log("customerName: "+this.state.custDetails.customerName)
    }

    validateInputFields = () => {

        let ddlDesignCompIdentity = PrValidate.ForceSelect(this.state.ddlDesignCompIdentity, 'ddlDesignCompIdentity', 'a design company identification');
        let ddlPayingCorpDiffEntity = PrValidate.ForceSelect(this.state.ddlPayingCorpDiffEntity, 'ddlPayingCorpDiffEntity', 'a paying corporation');
        let ddlShippingAddr = PrValidate.ForceSelect(this.state.ddlShippingAddr, 'ddlShippingAddr', 'Input');
        let ddlHTSNCodes = PrValidate.ForceSelect(this.state.ddlHTSNCodes, 'ddlHTSNCodes', 'Input');
        let endUseDescription = PrValidate.ForceEntry(this.state.endUseDescription, 'endUseDescription', 'Input');
        let ddlMultipleIntermediate = PrValidate.ForceSelect(this.state.ddlMultipleIntermediate, 'ddlMultipleIntermediate', 'Input');
        let ddlManufacturedIntendedProduct = PrValidate.ForceSelect(this.state.ddlManufacturedIntendedProduct, 'ddlManufacturedIntendedProduct', 'Input');
        let ddlProductSpeciallyDesigned = PrValidate.ForceSelect(this.state.ddlProductSpeciallyDesigned, 'ddlProductSpeciallyDesigned', 'Input');

        //new questions
        let ddlCrvEndUse = PrValidate.ForceSelect(this.state.ddlCrvEndUse, 'ddlCrvEndUse', 'Input');
        let ddlReExportFlag = PrValidate.ForceSelect(this.state.ddlReExportFlag, 'ddlReExportFlag', 'Input');


        let ddlSubjectToTheUSExportAdministration = PrValidate.ForceSelect(this.state.ddlSubjectToTheUSExportAdministration, 'ddlSubjectToTheUSExportAdministration', 'Input');
        let txtECCNForDesignTechnology = PrValidate.ForceEntry(this.state.txtECCNForDesignTechnology, 'txtECCNForDesignTechnology', 'Input');
        let txtECCNForFinishedGood = PrValidate.ForceEntry(this.state.txtECCNForFinishedGood, 'txtECCNForFinishedGood', 'Input');
        let ddlSubjectToExportControlsNonUs = PrValidate.ForceSelect(this.state.ddlSubjectToExportControlsNonUs, 'ddlSubjectToExportControlsNonUs', 'Input');
        let ddlMwWaveProductMMIC = PrValidate.ForceSelect(this.state.ddlMwWaveProductMMIC, 'ddlMwWaveProductMMIC', 'Input');
        let ddlITARArms = PrValidate.ForceSelect(this.state.ddlITARArms, 'ddlITARArms', 'Input');
        let ddlUSEncryptionControls = PrValidate.ForceSelect(this.state.ddlUSEncryptionControls, 'ddlUSEncryptionControls', 'Input');
        let txtAuthName = PrValidate.ForceEntry(this.state.txtAuthName, 'txtAuthName', 'Input');
        let txtAuthTitle = PrValidate.ForceEntry(this.state.txtAuthTitle, 'txtAuthTitle', 'Input');
        let txtAuthEmail = PrValidate.ForceEmail(this.state.txtAuthEmail, 'txtAuthEmail', '');
        let txtAuthTelNumber = PrValidate.ForceRPhone(this.state.txtAuthTelNumber.split("-").join(""), 'txtAuthTelNumber', 'Input');

        let txtManufacturedIntendedProduct = document.getElementById("txtManufacturedIntendedProduct")!=null?
            PrValidate.ForceEntry(document.getElementById("txtManufacturedIntendedProduct").value, 'txtManufacturedIntendedProduct', 'Input'):true;
        let txtProductSpeciallyDesignedDesc = document.getElementById("txtProductSpeciallyDesignedDesc")!=null?
            PrValidate.ForceEntry(document.getElementById("txtProductSpeciallyDesignedDesc").value, 'txtProductSpeciallyDesignedDesc', 'Input'):true;
        let txtCCATSNumber = document.getElementById("txtCCATSNumber")!=null?
            PrValidate.ForceEntry(document.getElementById("txtCCATSNumber").value, 'txtCCATSNumber', 'Input'):true;
        let txtJurisdictionName = document.getElementById("txtJurisdictionName")!=null?
            PrValidate.ForceEntry(document.getElementById("txtJurisdictionName").value, 'txtJurisdictionName', 'Input'):true;
        let txtDesignECCN = document.getElementById("txtDesignECCN")!=null?
            PrValidate.ForceEntry(document.getElementById("txtDesignECCN").value, 'txtDesignECCN', 'Input'):true;
        let txtFinishedWafersECCN = document.getElementById("txtFinishedWafersECCN")!=null?
            PrValidate.ForceEntry(document.getElementById("txtFinishedWafersECCN").value, 'txtFinishedWafersECCN', 'Input'):true;
        let operatingFrequency = document.getElementById("operatingFrequency")!=null?
            PrValidate.ForceEntry(document.getElementById("operatingFrequency").value, 'operatingFrequency', 'Input'):true;
        let peakSaturatedPower = document.getElementById("peakSaturatedPower")!=null?
            PrValidate.ForceEntry(document.getElementById("peakSaturatedPower").value, 'peakSaturatedPower', 'Input'):true;
        let ddlPhaseShifterIntegrated = document.getElementById("ddlPhaseShifterIntegrated")!=null?
            PrValidate.ForceSelect(document.getElementById("ddlPhaseShifterIntegrated").value, 'ddlPhaseShifterIntegrated', 'Input'):true;
        let ddlSpeciallyDesignedProduct = document.getElementById("ddlSpeciallyDesignedProduct")!=null?
            PrValidate.ForceSelect(document.getElementById("ddlSpeciallyDesignedProduct").value, 'ddlSpeciallyDesignedProduct', 'Input'):true;
        let specialDesignApplication = document.getElementById("specialDesignApplication")!=null?
            PrValidate.ForceEntry(document.getElementById("specialDesignApplication").value, 'specialDesignApplication', 'Input'):true;
        let ddlDeviceCtrlUnderUSMunitions = document.getElementById("ddlDeviceCtrlUnderUSMunitions")!=null?
            PrValidate.ForceSelect(document.getElementById("ddlDeviceCtrlUnderUSMunitions").value, 'ddlDeviceCtrlUnderUSMunitions', 'Input'):true;
        let ddlITARCategory = document.getElementById("ddlITARCategory")!=null?
            PrValidate.ForceSelect(document.getElementById("ddlITARCategory").value, 'ddlITARCategory', 'Input'):true;
        let technicalDataSuffix = document.getElementById("technicalDataSuffix")!=null?
            PrValidate.ForceEntry(document.getElementById("technicalDataSuffix").value, 'technicalDataSuffix', 'Input'):true;
        let icSuffix = document.getElementById("icSuffix")!=null?
            PrValidate.ForceEntry(document.getElementById("icSuffix").value, 'icSuffix', 'Input'):true;
        let fullyExtendedSuffix = document.getElementById("fullyExtendedSuffix")!=null?
            PrValidate.ForceEntry(document.getElementById("fullyExtendedSuffix").value, 'fullyExtendedSuffix', 'Input'):true;
        let endUseSuffix = document.getElementById("endUseSuffix")!=null?
            PrValidate.ForceEntry(document.getElementById("endUseSuffix").value, 'endUseSuffix', 'Input'):true;
        let ddlProductFabricatedByFoundry = document.getElementById("ddlProductFabricatedByFoundry")!=null?
            PrValidate.ForceSelect(document.getElementById("ddlProductFabricatedByFoundry").value, 'ddlProductFabricatedByFoundry', 'Input'):true;
        let usecCcatsEccn = document.getElementById("usecCcatsEccn")!=null?
            PrValidate.ForceEntry(document.getElementById("usecCcatsEccn").value, 'usecCcatsEccn', 'Input'):true;
        let usecCcatsNumber = document.getElementById("usecCcatsNumber")!=null?
            PrValidate.ForceEntry(document.getElementById("usecCcatsNumber").value, 'usecCcatsNumber', 'Input'):true;
        let test = document.getElementById("test")!=null?
            PrValidate.ForceDate(this.state.usGovReviewDate, 'test', 'Input'): true;
        let mosisDeliverable = document.getElementById("mosisDeliverable")!=null?
            PrValidate.ForceEntry(document.getElementById("mosisDeliverable").value, 'mosisDeliverable', 'Input'): true;
        let eccnTechnology = document.getElementById("eccnTechnology")!=null?
            (PrValidate.ForceEntry(document.getElementById("eccnTechnology").value, 'eccnTechnology', 'Input')):true

        console.log("Test: "+test)

        let err_arr = [ddlDesignCompIdentity, ddlPayingCorpDiffEntity, ddlShippingAddr,ddlHTSNCodes, endUseDescription, ddlMultipleIntermediate, ddlManufacturedIntendedProduct,
            ddlProductSpeciallyDesigned, ddlSubjectToTheUSExportAdministration, txtECCNForDesignTechnology, txtECCNForFinishedGood, ddlSubjectToExportControlsNonUs, ddlMwWaveProductMMIC,
            ddlITARArms, ddlUSEncryptionControls, txtAuthName, txtAuthTitle, txtAuthEmail, txtAuthTelNumber,txtManufacturedIntendedProduct, txtProductSpeciallyDesignedDesc, txtCCATSNumber,
            txtJurisdictionName, txtDesignECCN, txtFinishedWafersECCN, operatingFrequency, peakSaturatedPower, ddlPhaseShifterIntegrated, ddlSpeciallyDesignedProduct, specialDesignApplication,
            ddlDeviceCtrlUnderUSMunitions, ddlITARCategory, technicalDataSuffix, icSuffix, fullyExtendedSuffix, endUseSuffix, ddlProductFabricatedByFoundry, usecCcatsEccn, usecCcatsNumber,
            test, mosisDeliverable, eccnTechnology];
        let itemToScroll = ["ddlDesignCompIdentity", "ddlPayingCorpDiffEntity", "ddlShippingAddr", "ddlHTSNCodes", "endUseDescription", "ddlMultipleIntermediate", "ddlManufacturedIntendedProduct",
            "ddlProductSpeciallyDesigned", "ddlSubjectToTheUSExportAdministration", "txtECCNForDesignTechnology", "txtECCNForFinishedGood", "ddlSubjectToExportControlsNonUs", "ddlMwWaveProductMMIC",
            "ddlITARArms", "ddlUSEncryptionControls", "txtAuthName", "txtAuthTitle", "txtAuthEmail", "txtAuthTelNumber","txtManufacturedIntendedProduct", "txtProductSpeciallyDesignedDesc", "txtCCATSNumber",
            "txtJurisdictionName", "txtDesignECCN", "txtFinishedWafersECCN", "operatingFrequency", "peakSaturatedPower", "ddlPhaseShifterIntegrated", "ddlSpeciallyDesignedProduct", "specialDesignApplication",
            "ddlDeviceCtrlUnderUSMunitions", "ddlITARCategory", "technicalDataSuffix", "icSuffix", "fullyExtendedSuffix", "endUseSuffix", "ddlProductFabricatedByFoundry", "usecCcatsEccn", "usecCcatsNumber",
            "test", "mosisDeliverable", "eccnTechnology"];

        let chk_false = 0;

        let that = this;
        let breakCondition = false;

        that.state.saveExportControlFormData = false
        err_arr.forEach(function (element, index) {
            if (!element && !breakCondition){
                chk_false++;
                console.log("itemToScrollIndex: " + itemToScroll[index])
                $('html, body').animate({
                    scrollTop: $("#" + itemToScroll[index]).offset().top - 100
                }, 1000);
                breakCondition = true
            }

            if ((err_arr.length - 1) === index) {
                if (chk_false === 0) {
                    that.state.saveExportControlFormData = true
                }
            }
        });

        let txtDesignNumber = {}
        let txtEndUserAddrr = {}
        let txtPayingCompAddrr = {}
        let txtDesignCompAddr = {}
        let txtShipAddrr = {}

        let chk_false_designNumber = 0;
        this.state.designRows.forEach(function (element, index) {
            txtDesignNumber["txtDesignNumber_"+index] = PrValidate.ForceEntry(document.getElementById("txtDesignNumber_"+index)!=null?document.getElementById("txtDesignNumber_"+index).value:'',
                'txtDesignNumber_'+index, 'Input');

            txtEndUserAddrr["txtEndUserAddrr_"+index] = document.getElementById("endUserRow_0")? that.validateAddressRow("txtEndUser", index):true;
            txtPayingCompAddrr["txtPayingCorpAddrr_"+index] = document.getElementById("payingCompRow_0")? that.validateAddressRow("txtPayingComp", index):true;
            txtDesignCompAddr["txtDesignCompAddr_"+index] = document.getElementById("designCompIdenti_0")? that.validateAddressRow("txtDesignComp", index):true;
            txtShipAddrr["txtShipAddrr_"+index] = document.getElementById("shipAddrr_0")? that.validateAddressRow("txtShip", index):true;

            console.log("txtEndUserAddrr"+index+": "+txtEndUserAddrr["txtEndUserAddrr_"+index])
            console.log("txtPayingCorpAddrr_"+index+": "+txtPayingCompAddrr["txtPayingCorpAddrr_"+index])
            console.log("txtDesignCompAddr_"+index+": "+txtDesignCompAddr["txtDesignCompAddr_"+index])
            console.log("txtShipAddrr_"+index+": "+txtShipAddrr["txtShipAddrr_"+index])


            if ((!txtDesignNumber["txtDesignNumber_"+index] && !breakCondition)
            || (!txtEndUserAddrr["txtEndUserAddrr_"+index] && !breakCondition)
            || (!txtPayingCompAddrr["txtPayingCorpAddrr_"+index] && !breakCondition)
            || (!txtDesignCompAddr["txtDesignCompAddr_"+index] && !breakCondition)
            || (!txtShipAddrr["txtShipAddrr_"+index] && !breakCondition)){
                console.log("inside false condition")
                chk_false_designNumber++;
                console.log("itemToScrollIndex: " + index)

                let itemToScroll = ''
                if(txtDesignNumber["txtDesignNumber_"+index] === false) {
                    itemToScroll = "txtDesignNumber_"+index
                }else if(txtEndUserAddrr["txtEndUserAddrr_"+index] === false) {
                    itemToScroll = "txtEndUserDesignNumber_"+index
                }else if(txtPayingCompAddrr["txtPayingCorpAddrr_"+index] === false) {
                    itemToScroll = "txtPayingCompDesignNumber_"+index
                }else if(txtDesignCompAddr["txtDesignCompAddr_"+index] === false) {
                    itemToScroll = "txtDesignCompDesignNumber_"+index
                }else if(txtShipAddrr["txtShipAddrr_"+index] === false) {
                    itemToScroll = "txtShipDesignNumber_"+index
                }
                console.log("itemToScroll: "+itemToScroll)

                if(itemToScroll != ''){
                    $('html, body').animate({
                        scrollTop: $("#"+itemToScroll).offset().top - 100
                    }, 1000);
                }

                breakCondition = true
            }



            if ((that.state.designRows.length - 1) === index && that.state.saveExportControlFormData === true) {
                if (chk_false_designNumber === 0) {
                    that.state.saveExportControlFormData = true
                }else{
                    that.state.saveExportControlFormData = false
                }
            }
        });



        console.log("EndUser: "+document.getElementById("endUserRow_1"))
        console.log("paying: "+document.getElementById("payingCompRow_1"))
        console.log("design: "+document.getElementById("designCompIdenti_1"))
        console.log("shippAddrr: "+document.getElementById("shipAddrr_1"))

    }

    validateAddressRow = (id, index) => {
        /*let designNumber = PrValidate.ForceEntry(document.getElementById(id+"DesignNumber_"+index)!=null?document.getElementById(id+"DesignNumber_"+index).value:'',
            id+"DesignNumber_"+index, 'Input');*/

        let name = PrValidate.ForceEntry(document.getElementById(id+"CorpName_"+index)!=null?document.getElementById(id+"CorpName_"+index).value:'',
            id+"CorpName_"+index, 'Input');

        let addr1 = PrValidate.ForceEntry(document.getElementById(id+"Addr1_"+index)!=null?document.getElementById(id+"Addr1_"+index).value:'',
            id+"Addr1_"+index, 'Input');

        /*let addr2 = PrValidate.ForceEntry(document.getElementById(id+"Addr2_"+index)!=null?document.getElementById(id+"Addr2_"+index).value:'',
            id+"Addr2_"+index, 'Input');*/

        let city = PrValidate.ForceEntry(document.getElementById(id+"City_"+index)!=null?document.getElementById(id+"City_"+index).value:'',
            id+"City_"+index, 'Input');

        let state = PrValidate.ForceEntry(document.getElementById(id+"State_"+index)!=null?document.getElementById(id+"State_"+index).value:'',
            id+"State_"+index, 'Input');

        let zipCode = PrValidate.ForceEntry(document.getElementById(id+"Zip_"+index)!=null?document.getElementById(id+"Zip_"+index).value:'',
            id+"Zip_"+index, 'Input');

        let country = PrValidate.ForceSelect(document.getElementById(id+"Country_"+index)!=null?document.getElementById(id+"Country_"+index).value:'',
            id+"Country_"+index, 'Input');

        if((name && addr1 && city && state && zipCode && country) === true){
            return true
        }else{
            return false
        }
    }


    SubmitExport = () => {

        this.validateInputFields();
        console.log(this.state.shippingAddressQuestion["questionId"])
        console.log(this.state.payingOrganizationQuestion["questionId"])
        console.log(this.state.designerCorporationQuestion["questionId"])
        console.log(this.state.multipleIndeterminateQuestion["questionId"])
        console.log(this.state.weaponsQuestion["questionId"])
        console.log(this.state.militarySpaceQuestion["questionId"])
        //new questions
        console.log(this.state.crvEndUseQuestion["questionId"])
        console.log(this.state.reExportFlagQuestion["questionId"])

        console.log(this.state.exportAdminQuestion["questionId"])
        console.log(this.state.exportNonUSQuestion["questionId"])
        console.log(this.state.mmicQuestion["questionId"])
        console.log(this.state.internationalTrafficArmsQuestion["questionId"])
        console.log(this.state.classifiedInformationQuestion["questionId"])
        console.log(this.state.typeOfPayloadQuestion["questionId"])
        console.log(this.state.canadianRegisteredQuestion["questionId"])
        console.log(this.state.antiJamQuestion["questionId"])
        console.log(this.state.anyOfFollowingQuestion["questionId"])
        console.log(this.state.spacecraftPointingQuestion["questionId"])
        console.log(this.state.propulsionSystemsQuestion["questionId"])
        console.log(this.state.USEncryptionControlQuestion["questionId"])

        this.post_data = {

                "exportControl": {
                "applicationDescription": this.state.txtProductSpeciallyDesignedDesc,
                "authEmailAddress": this.state.txtAuthEmail,
                "authPhoneNumber": this.state.txtAuthTelNumber,
                "authorizedBy": this.state.txtAuthName,
                "authorizedByTitle": this.state.txtAuthTitle,
                "ccatsNumber": this.state.txtCCATSNumber,
                "certificationDate": null,
                "certifiedBy": null,
                "classifiedInformation": this.state.answerCodeValueMap[this.state.ddlProductFabricatedByFoundry],
                "completionDate": new Date(),
                "createdBy": null,
                "createdDate": null,
                "customerId": null,

                "designTechnologyEccn": this.state.txtECCNForDesignTechnology,
                "finishedGoodEccn": this.state.txtECCNForFinishedGood,
                "eccnTechnology": this.state.eccnTechnology,
                "finishedEcEccn": this.state.txtFinishedWafersECCN,
                "endUseDescription": this.state.endUseDescription,
                "endUseSuffix": this.state.endUseSuffix,
                "htsnNumber": this.state.ddlHTSNCodes,
                "icSuffix": this.state.icSuffix,
                "isEarSelected": this.state.answerCodeValueMap[this.state.ddlManufacturedIntendedProduct],
                "isItarSelected": this.state.answerCodeValueMap[this.state.ddlManufacturedIntendedProduct],
                "isUsecSelected": this.state.answerCodeValueMap[this.state.ddlManufacturedIntendedProduct],
                "itarSelection": this.state.ddlITARCategory,//this.state.answerCodeValueMap[this.state.ddlITARCategory],
                "modifiedBy": null,
                "modifiedDate": null,
                "mosisDeliverable": this.state.mosisDeliverable,
                "multipleEndUser": null,
                "operatingFrequency": this.state.operatingFrequency,
                "peakSaturatedPower": this.state.peakSaturatedPower,
                "phaseShifter": this.state.ddlPhaseShifterIntegrated == 1?"Yes":"No",
                "usMunitions": this.state.ddlDeviceCtrlUnderUSMunitions == 1?"Yes":"No",
                "productEcEccn": this.state.txtDesignECCN,
                "productEcJurisdiction": this.state.txtJurisdictionName,
                "reviewedBy": null,
                "reviewedDate": null,
                "signatureInitiated": null,
                "technicalDataSuffix": this.state.technicalDataSuffix,
                "usGovReviewDate": this.state.usGovReviewDate,
                "usecCcatsEccn": this.state.usecCcatsEccn,
                "usecCcatsNumber": this.state.usecCcatsNumber,
                "weaponsDescription": this.state.txtManufacturedIntendedProduct,
                "shipAddrFlag": this.state.answerCodeValueMap[this.state.ddlManufacturedIntendedProduct],
                "designCorpFlag": this.state.answerCodeValueMap[this.state.ddlManufacturedIntendedProduct],
                "payingCorpFlag": this.state.answerCodeValueMap[this.state.ddlManufacturedIntendedProduct],
                "weaponsFlag": this.state.answerCodeValueMap[this.state.ddlManufacturedIntendedProduct],
                "spaceApplicationFlag": this.state.answerCodeValueMap[this.state.ddlManufacturedIntendedProduct],
                "speciallyDesigned": this.state.ddlSpeciallyDesignedProduct == 1?"Yes":"No",
                "specialDesignApplication": this.state.specialDesignApplication,
                "fullyExtendedSuffix": this.state.fullyExtendedSuffix,
                "crvEndUseFlag": this.state.answerCodeValueMap[this.state.ddlCrvEndUse],
                "reExportFlag": this.state.answerCodeValueMap[this.state.ddlReExportFlag],
            },
            "shippingAddressAnswer": {
                "questionId": this.state.shippingAddressQuestion["questionId"],
                "answerId": this.state.ddlShippingAddr
            },
            "payingOrganizationAnswer": {
                "questionId": this.state.payingOrganizationQuestion["questionId"],
                "answerId": this.state.ddlPayingCorpDiffEntity
            },
            "designerCorporationAnswer": {
                "questionId": this.state.designerCorporationQuestion["questionId"],
                "answerId": this.state.ddlDesignCompIdentity
            },
            "multipleIndeterminateAnswer": {
                "questionId": this.state.multipleIndeterminateQuestion["questionId"],
                "answerId": this.state.ddlMultipleIntermediate
            },
            "weaponsAnswer": {
                "questionId": this.state.weaponsQuestion["questionId"],
                "answerId": this.state.ddlManufacturedIntendedProduct
            },
            "militarySpaceAnswer": {
                "questionId": this.state.militarySpaceQuestion["questionId"],
                "answerId": this.state.ddlProductSpeciallyDesigned
            },

            "crvEndUseAnswer": {
                "questionId": this.state.crvEndUseQuestion["questionId"],
                "answerId": this.state.ddlCrvEndUse
            },
            "reExportFlagAnswer": {
                "questionId": this.state.reExportFlagQuestion["questionId"],
                "answerId": this.state.ddlReExportFlag
            },

            "exportAdminAnswer": {
                "questionId": this.state.exportAdminQuestion["questionId"],
                "answerId": this.state.ddlSubjectToTheUSExportAdministration
            },
            "exportNonUSAnswer": {
                "questionId": this.state.exportNonUSQuestion["questionId"],
                "answerId": this.state.ddlSubjectToExportControlsNonUs
            },
            "mmicAnswer": {
                "questionId": this.state.mmicQuestion["questionId"],
                "answerId": this.state.ddlMwWaveProductMMIC
            },
            "munitionsAnswer": {
                "questionId": "",
                "answerId": this.state.ddlDeviceCtrlUnderUSMunitions
            },
            "internationalTrafficArmsAnswer": {
                "questionId": this.state.internationalTrafficArmsQuestion["questionId"],
                "answerId": this.state.ddlITARArms
            },
            "itarCategoryAnswer": {
                "questionId": "",
                "answerId": this.state.ddlITARCategory
            },
            "classifiedInformationAnswer": {
                "questionId": this.state.classifiedInformationQuestion["questionId"],
                "answerId": this.state.ddlProductFabricatedByFoundry
            },
            "typeOfPayloadAnswer": {
                "questionId": this.state.typeOfPayloadQuestion["questionId"],
                "answerId": this.state.ddlEquipmentConsideredPayload
            },
            "canadianRegisteredAnswer": {
                "questionId": this.state.canadianRegisteredQuestion["questionId"],
                "answerId": this.state.ddlEndItemsIntendedExclusivelyCanadian
            },
            "antiJamAnswer": {
                "questionId": this.state.antiJamQuestion["questionId"],
                "answerId": this.state.ddlAntiJamSystem
            },
            "anyOfFollowingAnswer": {
                "questionId": this.state.anyOfFollowingQuestion["questionId"],
                "answerId": this.state.ddlAnyOfTheFollowing
            },
            "spacecraftPointingAnswer": {
                "questionId": this.state.spacecraftPointingQuestion["questionId"],
                "answerId": this.state.ddlSpacecraftPointingDetermination
            },
            "propulsionSystemsAnswer": {
                "questionId": this.state.propulsionSystemsQuestion["questionId"],
                "answerId": this.state.ddlPropulsionSystems
            },
            "usEncryptionControlAnswer": {
                "questionId": this.state.USEncryptionControlQuestion["questionId"],
                "answerId": this.state.ddlUSEncryptionControls
            },
            /*"commodityClassificationFile": fileData,
            "graphFile": document.querySelector("#graphFile")!=null? document.querySelector("#graphFile").files[0]:null,
            "commodityJurisdictionFile": document.querySelector("#commodityJurisdictionFile")!=null? document.querySelector("#commodityJurisdictionFile").files[0]:null,
            "ccatsDocument": document.querySelector("#ccatsDocument")!=null? document.querySelector("#ccatsDocument").files[0]:null,*/
            /*"graphFile": this.state.graphFileFormData,
            "commodityJurisdictionFile": this.state.commodityJurisdictionFileFormData,
            "ccatsDocument": this.state.ccatsDocumentFormData,*/
            "designsList": [
                {
                    "designNumber": "1"
                }
            ],
            "payingCorporationList": [
                {
                    "ecDesign": {
                        "designNumber": "1"
                    },
                    "corporateName": "",
                    "addressLine1": "",
                    "addressLine2": "",
                    "city": "",
                    "stateProvince": "",
                    "zipCode": "",
                    "country": ""
                }
            ],
            "shipToCorporationList": [
                {
                    "ecDesign": {
                        "designNumber": "1"
                    },
                    "corporateName": "",
                    "addressLine1": "",
                    "addressLine2": "",
                    "city": "",
                    "stateProvince": "",
                    "zipCode": "",
                    "country": ""
                }
            ],
            "endUserList": [
                {
                    "ecDesign": {
                        "designNumber": "1"
                    },
                    "corporateName": "",
                    "addressLine1": "",
                    "addressLine2": "",
                    "city": "",
                    "stateProvince": "",
                    "zipCode": "",
                    "country": ""
                }
            ]
        }


        let designList = []
        this.post_data["designsList"] = []
        for(let i=0; i<this.state.designRows.length; i++){
            this.post_data["designsList"].push({"designNumber": document.getElementById("txtDesignNumber_"+i).value})
        }
        //this.post_data["designsList"].push(designList[0])
        console.log("designsList: "+JSON.stringify(this.post_data["designsList"]))


        let shippingAddressList = this.getAddresses("txtShip")
        let designCompanyAddressList = this.getAddresses("txtDesignComp")
        let payingCompanyAddressList = this.getAddresses("txtPayingComp")
        let endUserAddressList = this.getAddresses("txtEndUser")

        this.post_data["payingCorporationList"] = payingCompanyAddressList
        this.post_data["shipToCorporationList"] = shippingAddressList
        this.post_data["designerCorporationList"] = designCompanyAddressList
        this.post_data["endUserList"] = endUserAddressList

        console.log("shipToCorporationList: "+JSON.stringify(shippingAddressList))
        console.log("designerCorporationList: "+JSON.stringify(designCompanyAddressList))
        console.log("payingCompanyAddressList: "+JSON.stringify(payingCompanyAddressList))
        console.log("endUserList: "+JSON.stringify(endUserAddressList))

        if(endUserAddressList.length>1){
            this.post_data.exportControl["multipleEndUser"] = true
        }

        let finalData = {}
        finalData["exportControlForm"] = this.post_data
        finalData["commodityClassificationFile"] = null
        finalData["graphFile"] = null
        finalData["commodityJurisdictionFile"] = null
        finalData["ccatsDocument"] = null

        console.log("this.post_data: "+JSON.stringify(this.post_data))


        console.log("this.postData: "+this.post_data)
        var formData = new FormData();
        formData.append("commodityClassificationFile", document.getElementById("commodityFile")!=null?document.getElementById("commodityFile").files[0]:null);
        formData.append("graphFile", document.getElementById("graphFile")!=null? document.getElementById("graphFile").files[0]:null);
        formData.append("ccatsDocument", document.getElementById("ccatsDocument")!=null? document.getElementById("ccatsDocument").files[0]:null);
        formData.append("commodityJurisdictionFile", document.getElementById("commodityJurisdictionFile")!=null? document.getElementById("commodityJurisdictionFile").files[0]:null);
        formData.append("exportControlFormVo", JSON.stringify(this.post_data))


        let accessToken = JSON.parse(localStorage.getItem('okta-token-storage')).accessToken.accessToken;
        let headers = {
            'Authorization':'Bearer ' + accessToken
        };



        let that = this
        if(this.state.saveExportControlFormData === true){
            this.setState({
                saveButtonDisabled : true
            })
            //fetch('http://localhost:8080/exportControl/save', {
            fetch('../rest/exportControl/save', {
                method: 'post',
                body: formData,
                headers: headers
            }).then(function(response) {
                if (response.status !== 200 && response.status !== 201) {
                    console.log("There was an error! inside");
                } else {
                    console.log("Request successful");
                    ToastsStore.success("Successfully Saved.");
                    that.setState({
                        saveButtonDisabled: false,
                        redirectToMainPage: true
                    })

                    setTimeout(function() { //Start the timer
                        that.props.componentprops.history.push("/dashboard");
                    }.bind(that), 2000)
                }
            }).catch(function(err) {
                console.log("There was an error!"+err);
            });
        }else{
            console.log("Please input all fields")
        }




    }

    HandleOnBlur = (type, field, value, msg) => {
        if (type === 'ForceSelect')
            PrValidate.ForceSelect(value, field, msg);
        else if (type === 'ForceEntry')
            PrValidate.ForceEntry(value, field, msg);
    }

    getAddresses = (label) => {
        let addressObjectsList = []
        console.log("label: "+label)
        for(let i=0; i<this.state.designRows.length; i++){
            if(document.getElementById(label+"DesignNumber_"+i) != null) {
                console.log(document.getElementById(label + "DesignNumber_" + i).value)
                console.log(document.getElementById(label + "CorpName_" + i).value)
                console.log(document.getElementById(label + "Addr1_" + i).value)
                console.log(document.getElementById(label + "Addr2_" + i).value)
                console.log(document.getElementById(label + "City_" + i).value)
                console.log(document.getElementById(label + "State_" + i).value)
                console.log(document.getElementById(label + "Zip_" + i).value)
                console.log(document.getElementById(label + "Country_" + i).value)

                let addressObject = {
                    "ecDesign": {
                        "designNumber": document.getElementById(label + "DesignNumber_" + i).value
                    },
                    "corporateName": document.getElementById(label + "CorpName_" + i).value,
                    "addressLine1": document.getElementById(label + "Addr1_" + i).value,
                    "addressLine2": document.getElementById(label + "Addr2_" + i).value,
                    "city": document.getElementById(label + "City_" + i).value,
                    "stateProvince": document.getElementById(label + "State_" + i).value,
                    "zipCode": document.getElementById(label + "Zip_" + i).value,
                    "country": document.getElementById(label + "Country_" + i).value
                }

                addressObjectsList.push(addressObject)
            }
        }
        console.log("===================================================")
        return addressObjectsList
    }

    HandleSuccessSaveExportControlDataResponse = (res) => {
        console.log("RESPONSE: "+res.data)
        this.setState({
            postExportControlData:false
        })
    }

    HandleSuccessGetCustomerDetails = (res) => {
        console.log("res:===="+JSON.stringify(res))
    }

    HandleSuccessGetDesignNumberList = (res) => {
        console.log("res.data HandleSuccessGetDesignNumberList: "+JSON.stringify(res.data))
        if(res != null) {
            this.state.foundryAndTechClass = res.data
            console.log("this.state.foundryAndTechClass: " + JSON.stringify(this.state.foundryAndTechClass))

            /*let that = this
            this.state.foundryAndTechClass.map(function (item, index) {
                that.state.designNumberInfoIdMap[item["designNumber"]] = item["designNumberInfoId"]
                /!*if(item.foundry == null){
                    that.state.foundryAndTechClass.splice(index,1)
                }*!/
            })*/

            this.setState({
                fetchFoundryAndTechClass: false,
                foundryAndTechClass: this.state.foundryAndTechClass,
                designNumberListDisabled: false
            })


            console.log("foundryAndTechClass: " + JSON.stringify(this.state.foundryAndTechClass))
            console.log("foundryAndTechClass: " + this.state.foundryAndTechClass.length)
        }else{
            this.setState({
                fetchFoundryAndTechClass: false,
                designNumberListDisabled: true
            })
        }
    }



    render() {
        // console.log(this.state.ddlDesignCompIdentity+"---");
        // console.log(JSON.stringify(this.state.designRows.length))
        return (
            <div>
                {this.state.saveButtonDisabled === true?
                    <PrLoader size={"small"} />:''
                }

                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_CENTER} />
                {/*{this.state.redirectToMainPage === true  ?
                    <DashboardCtrl {...this.props} state={this.state}/>:''
                }*/}
                {this.state.redirectToMainPage === false ?
                    <ExportQuestionireInputComponent {...this.props} state={this.state}
                                                     HandleDynamicChange={this.HandleDynamicChange} HandleChange={this.HandleChange}
                                                     AddDesignRow={this.AddDesignRow} DeleteDesignRow={this.DeleteDesignRow}
                                                     HandleDesignNumberChange={this.HandleDesignNumberChange}
                                                     HandleShipAddrRowsChange={this.HandleShipAddrRowsChange}
                                                     HandleDesignCompRowsChange={this.HandleDesignCompRowsChange}
                                                     HandlePayingCompRowsChange={this.HandlePayingCompRowsChange}
                                                     HandleEndUserRowsChange={this.HandleEndUserRowsChange}
                                                     custDetails={this.state.custDetails}
                                                     CopyAddrFromCustomer={this.CopyAddrFromCustomer}
                                                     CopyFromFirstRow={this.CopyFromFirstRow}
                                                     HTSNCodelist={this.state.HTSNCodelist}
                                                     ITARCatlist={this.state.ITARCatlist}
                                                     SubmitExport={this.SubmitExport}
                                                     handleFiles={this.handleFiles}
                                                     fileInput={React.createRef()}
                                                     HandleDateChange={this.HandleDateChange}
                                                     HandleITARChange={this.HandleITARChange}
                                                     HandleOnBlur={this.HandleOnBlur}
                                                     HandleDesignNumberDropDownChange={this.HandleDesignNumberDropDownChange}/>:''
                }

                {this.state.fetchCustDetails === true ? GetCustomerData(this.props.PrStore, this.HandleSuccessCustDetailsResponse) : ''}
                {this.state.fetchCountries === true ? GetCountryList(this.props.PrStore, this.HandleSuccessGetCountriesResponse) : ''}
                {this.state.fetchQuestions === true ? GetAllQuestions(this.props.PrStore, this.HandleSuccessGetQuestionResponse) : ''}
                {this.state.fetchHTSNCodes === true ? GetHTSNCodes(this.props.PrStore, this.HandleSuccessGetHTSNCodesResponse) : ''}
                {this.state.fetchItarCat === true ? GetITARCat(this.props.PrStore, this.HandleSuccessGetITARCatResponse) : ''}
                {/*{this.state.fetchAllQuestionTypes === true ? GetAllQuestionTypes(this.props.PrStore, this.HandleSuccessGetAllQuestionTypesResponse) : ''}*/}
                {this.state.fetchQuestionsByTypeId === true ? GetQuestionByTypeId(this.props.PrStore, this.state.typeId, this.HandleSuccessGetQuestionByTypeIdResponse) : ''}
                {this.state.fetchCustomerDetails === true ? getCustomerData(this.props.PrStore, this.HandleSuccessGetCustomerDetailsResponse, this.state.typeId) : ''}
                {this.state.postExportControlData === true ? SaveExportControlData(this.props.PrStore, this.state.finalData, this.HandleSuccessSaveExportControlDataResponse) : ''}
                {this.state.fetchDesignNumberList === true ? getDesignNumberList(this.props.PrStore, this.HandleSuccessGetDesignNumberList) : ''}


            </div>
        )
    }
}
export const ExportQuestionireCtrl = PrConnect(exportQuestionireCtrl, '');
