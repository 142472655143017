import React, { Component } from 'react';
import $ from 'jquery';
import {NavLink} from "react-router-dom";
export default class SFTPInstructionPopup extends Component {
    ClearMouse = () => {
        $('.inner_table').css('display', 'none');
    }
    render() {
        return (
            <div id="" style={{paddingTop: "1%"}}>

                <div>
                    <section className="about_bg" style={{height: '100%'}}>
                        <div className="container-fluid">
                            <div className="">
                                <h2 className="page-header" style={{padding:'0'}}>The MOSIS Service SFTP File upload guidelines</h2>
                            </div>

                            <div className="mosis_hdng">
                                <p className="text-center">Please follow the instruction below to upload the design file using SFTP</p>
                            </div>
                            <div className="row" style={{paddingTop:"1%"}}>

                                <div className="col-lg-12 mx-auto">
                                    <div className="">
                                        <ol className="order_list_margin">
                                            <li style={{paddingBottom:"1%"}}>We recommend a network speed at least of <b>800 Mbps(100MBps)</b> for the file transfers. Please check your connection speed at <a href="https://www.speedtest.net" target="_blank">https://www.speedtest.net</a></li>
                                            <li style={{paddingBottom:"1%"}}>You can use any SFTP client ( we recommend FileZilla from https://filezilla-project.org/) SFTP client or command line to access The MOSIS Service SFTP server “sftp.themosisservice.com”, with <b>port 2121</b> as shown in the image below. For these transfer to work through the network, <b>port 2121</b> needs to allow connection themosisservice.com domains.</li>
                                            <li style={{paddingBottom:"1%"}}>After connecting to the SFTP server, please upload the file to "design_files" sub folder under your home folder. Please keep the file name including file extension “identical” to the file name provide in the “Design Upload Form”. If the filenames are <b>not identical,</b> the file will be <b>immediately deleted.</b></li>
                                        </ol>
                                    </div>

                                </div>


                            </div>
                            <img src={require('../../../../assets/images/SFTP_image_6.jpg')} width="500" height="300" alt="delete logo" style={{display:"block", marginLeft: "auto", marginRight: "auto"}}/>
                        </div>



                    </section>
                </div>
                {/*<div className="text-center" style={{ paddingTop:"2%"}}>
                    <button type="button" onClick={this.onOkClick} style={{backgroundColor: '#dfdfdf', color: '#990000', width:'25%'}} className="dash_submit_btn">OK</button>
                </div>*/}
            </div>
        )
    }
}