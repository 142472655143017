import React, { Component } from 'react';
import HeaderComponent from '../common/Header';
import FooterComponent from '../common/Footer';
import QuickContactComponent from '../common/quick-contact';
import {prstore} from "../library/stores/pr-store";

export default class SinglePageLayout extends Component {
    constructor(props) {

        /*if(prstore.api.baseurl.includes("http://localhost")){
            console = console || {};
            console.log = function(){};
        }*/
        super(props);
        this.state = {
            open: false,
            currentModalAction: {},
        };
        this.comp = '';
        this.comp_size = 'md';
        this.routeArray = ['/user/new', '/users', '/leads'];
        this.prev_url = 'init'
    }

    render() {
        return (
            <div>
                {/* <div className="cbp-spmenu-push"> */}
                {/* <div className="main-content"> */}
                {/* <SideBar {...this.props} /> */}
                <HeaderComponent {...this.props} />

                <div id="page-wrapper">
                    <this.props.children {...this.props} />

                    {/*{access_route ?*/}
                    {/*<this.props.children OpenModal={this.handleClickOpen} {...this.props} />*/}
                    {/*: 'You are Not Authorized to access this route.'*/}
                    {/*}*/}
                </div>
                <div>
                    <FooterComponent />
                </div>

                <div>
                    <QuickContactComponent />
                </div>
            </div>
            // <div>
            //   {/* <div className="container" style={{ background: "url('')  no-repeat fixed center center / cover" }}> */}
            //   <div className="container-fluid">
            //     <div className="row">
            //       <div className="row"><Header {...this.props} /></div>
            //       <div className="row">
            //         <div className="pull-left"><SideBar/></div>
            //         <div className="pull-left"></div>
            //       {this.props.componentprops.children}</div>
            //       {/* <div className="col-sm-2"><SideBar {...this.props}/></div>
            //         <div className="col-sm-10">
            //           <div className="row"><Header {...this.props}/></div>
            //           <div className="row">{this.props.componentprops.children}</div>
            //         </div> */}
            //     </div>
            //   </div>
            //   {/* <div  style={{marginTop: -90 }} className="divBackground"> */}
            //   {/* </div> */}
            //   <div>
            //     <Footer />
            //   </div>
            //   {/* <HomePageLayout contentAreaSearch={<Search {...this.props} SearchTypeValue={this.state.ddlSearchType} SetSearchType={this.GetSearchType} GetPropertySearchData={this.GetProperty} />} contentArea={<div><Ads {...this.props} /><Insight /></div>}  {...this.props} /> */}
            // </div>
        );
    }
}