import React, { Component } from 'react';
import NewTableDesign from './NewTableDesign';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import { PrConnect } from '../../../library/pr-miniredux';

import { GetDataForTable } from '../../../services/general';

export default class tableDesignCtrl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetchTableData: false,
            headers: {},
            TableDataList: [],
            sorting: { column: '', order: 'ASC' }
        }

        // this.handleCLickedRow = this.handleCLickedRow.bind(this);

    }

    UNSAFE_componentWillMount = () => {
        let headers = Object.keys(this.props.componentprops.data[0])
        this.setState({
            fetchTableData: true,
            TableDataList: this.props.componentprops.data,
            headers: headers
        });
    }

    // componentDidUpdate = () => {
    //     console.log(this.props.componentprops.data)
    //     if (this.state.fetchTableData === false && (this.props.componentprops.data !== this.state.data))
    //         this.setState({
    //             fetchTableData: true,
    //             data: this.props.componentprops.data
    //         });
    // }

    HandleSuccessGetTableDataResponse = (res) => {
        this.setState({
            fetchTableData: false,
            tableDataList: res.data
        })
    }

    SortData = (event, key) => {
        // event.persist();
        const { sorting } = this.state;
        var column = event.target.getAttribute('column');

        // alert(sorting.column + "---" + column+"---"+key.item)

        if (sorting.column === column) {
            sorting.order = (sorting.order === 'asc') ? 'desc' : 'asc';
        } else {
            sorting.order = 'asc';
        }

        sorting.column = column;
        this.setState({ sorting: sorting });
        // this.state.table_data.sort(compareValues(sorting.column, sorting.order));
        this.state.TableDataList.sort(function (index, order) {
            return function (a, b) {
                var valueA, valueB;
                valueA = a[key.item]; // Where 1 is your index, from your example
                valueB = b[key.item];
                // valueA = a.data[index]['fieldValue']; // Where 1 is your index, from your example
                // valueB = b.data[index]['fieldValue'];
                let comparison = 0;
                if (valueA < valueB) {
                    comparison = -1;
                }
                else if (valueA > valueB) {
                    comparison = 1;
                }
                return (
                    (order === 'desc') ? (comparison * -1) : comparison
                );
            };
        }(sorting.column, sorting.order));
    }

    render() {
        return (
            <div>
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_CENTER} />
                {this.state.fetchTableData === true ? <NewTableDesign state={this.state} {...this.props} SortData={this.SortData} HandleClickRow={this.props.componentprops.HandleTableClick} TableDataList={this.state.TableDataList} /> : ''}
                {/* {this.state.fetchTableData === true?<NewTableDesign state={this.state} {...this.props} SortData={this.SortData} handleCLickedRow={this.handleCLickedRow} TableDataList={this.state.data} />:''} */}

                {/* {this.state.fetchTableData === true ? GetDataForTable(this.props.PrStore, this.HandleSuccessGetTableDataResponse) : ''} */}
            </div>
        )
    }
}
export const TableDesignCtrlNew = PrConnect(tableDesignCtrl, 'textUpload');