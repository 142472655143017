import React, {Component, useState} from 'react';
import FloatingLabelInput from 'react-floating-label-input';

// import $ from 'jquery';
import DatePicker from 'react-date-picker';

import ReCAPTCHA from "react-google-recaptcha";

//import Calendar from 'react-calendar';
// 6Lea4_EUAAAAAJoeoa-MCu0Kos2l55VKUWJ6xB-z
// 6Lf_CNsUAAAAAKLL93De5E2Cx9OUPQ_WBbAAQUVl
export default class RegisterComponent extends Component {
    render() {
        return (
            <div className="registration_top">
                <div className="reg align-self-top">
                    <div className="list-reg">
                        <h3 className="">Registration Information Step</h3>
                        <ul className="">
                            <li> <span></span> The potential customer submits the customer registration request.</li>
                            <li> <span></span> The MOSIS Service reviews the request</li>
                            <li> <span></span> The potential customer completes a MOSIS Customer Data Form</li>
                            <li> <span></span> The MOSIS Service Customer Agreement (MCA) is executed by both parties</li>
                            <li> <span></span> The MOSIS Service invoices the Registration Fee</li>


                            <li style={{listStyleImage:"none", listStyle: "none"}}> <span></span> &emsp;&emsp;There is a $10,000 Registration fee.</li>
                            <li style={{listStyleImage:"none", listStyle: "none"}}> <span></span> &emsp;&emsp;This fee will be applied to the first order placed within 12 months of registering.</li>

                            <li> <span></span> The Registration Fee is paid by Customer</li>
                            <li> <span></span> 100% of the Customer Registration fee will be applied the first customer order placed within 12 month of the MCA Effective Date</li>
                        </ul>
                        <h4 className="">Please note the minimum fabrication order is $25,000</h4>
                    </div>
                </div>
                {this.props.state.showSuccessModal === false ?
                    <div className="registration_form align-self-center">
                        <form action="" method="" autoComplete="off">
                            <h2 className="animated bounceInDowreg">New Customer Registration Request</h2>
                            <div className="animated zoomIn first_step" style={{ display: this.props.state.showFirstStep }}>
                                <div className="registration_inner_form information" style={{ paddingTop: 45 }}>
                                    <span className="step">Step 1 of 3</span>
                                    <h4>Organizational Information <sup className="star">&#9733;</sup> </h4>
                                    <div className="form_registration">
                                        <div className="form-group">
                                            <div className="floatingInnerDiv">
                                                <FloatingLabelInput autoComplete="off" className="floatingText" id="txtOrgName" name="txtOrgName" label="Organization Name" placeholder="Organization Name (Full Legal Name)" onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtOrgName', this.props.state.txtOrgName, 'organization name')} onChange={this.props.HandleChange} value={this.props.state.txtOrgName} />
                                            </div>
                                            {/* <FloatingLabel id="txtOrgName" name="txtOrgName" placeholder="Your email" type="email" value={this.props.state.txtOrgName} onChange={this.props.HandleChange} />; */}

                                            {/* <input type="text" className="form-control" name="txtOrgName" value={this.props.state.txtOrgName} onChange={this.props.HandleChange} placeholder="Organization Name (Full Legal Name)" /> */}
                                        </div>
                                        <div className="form-group">
                                            <div className="custom_select">
                                                <label className="custom_select_label">Country Name</label>
                                                <select onChange={this.props.HandleChange} name="ddlCountry" id="ddlCountry" value={this.props.state.ddlCountry} >
                                                    <option value=''>Select</option>
                                                    {(JSON.stringify(this.props.countries) !== '{}' && this.props.countries.length > 0) ? this.props.countries.map(item =>
                                                        <option value={item.codeId}>{item.codeName}</option>
                                                    ) : ''}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="custom_select">
                                                <label className="custom_select_label">Organization Type</label>
                                                <select onChange={this.props.HandleChange} name="ddlOrgType" id="ddlOrgType" value={this.props.state.ddlOrgType} >
                                                    <option value="">Select</option>
                                                    {(JSON.stringify(this.props.orgTypeList) !== '{}' && this.props.orgTypeList.length > 0) ? this.props.orgTypeList.map(item =>
                                                        <option value={item.codeId}>{item.codeName}</option>
                                                    ) : ''}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="floatingInnerDiv">
                                                <FloatingLabelInput autoComplete="off" className="floatingText" id="txtUrl" name="txtUrl" label="Organization Website URL" placeholder="Organization Website URL" onBlur={() => this.props.HandleOnBlur('ForceRWebsite', 'txtUrl', this.props.state.txtUrl, 'company website URL')} onChange={this.props.HandleChange} value={this.props.state.txtUrl} />
                                                {/* <input type="text" className="form-control" name="Url" placeholder="Organization Wbsite URL" /> */}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="floatingInnerDiv">
                                                <FloatingLabelInput maxLength="16" autoComplete="off" className="floatingText" id="txtRevenue" name="txtRevenue" label="Annual Revenue (US$)" placeholder="Annual Revenue (US$)" onBlur={() => this.props.HandleOnBlur('ForceNumber', 'txtRevenue', (this.props.state.txtRevenue).replace(/[^0-9]/g, ''), '')} onChange={this.props.HandleChange} value={this.props.state.txtRevenue} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="floatingInnerDiv">
                                                <FloatingLabelInput autoComplete="off" className="floatingText" id="txtEmployee" name="txtEmployee" label="Number Of Full time employees" placeholder="Number Of Full time employees" onBlur={() => this.props.HandleOnBlur('ForceNumber', 'txtEmployee', this.props.state.txtEmployee, '')} onChange={this.props.HandleChange} value={this.props.state.txtEmployee} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="mandatory"><sup className="star">&#9733;</sup> All fields are mandatory</span>
                                <div className="reg_btn_next">
                                    <a href="javascript:void(0)" className="next_btn" id="form_step_first" onClick={() => this.props.ShowHideSteps('first')}>Next</a>
                                </div>
                            </div>
                            <div className="animated zoomIn second_step" style={{ display: this.props.state.showSecondStep }}>
                                <div className="registration_inner_form contact_txt" style={{ paddingTop: 45 }}>
                                    <span className="step">Step 2 of 3</span>
                                    <h4>Contact Info <sup className="star">&#9733;</sup></h4>
                                    <div className="form_registration">
                                        <div className="form-group">
                                            <div className="floatingInnerDiv">
                                                <FloatingLabelInput autoComplete="off" className="floatingText" id="txtContact" name="txtContact" label="Contact Name (FN, LN)" placeholder="Contact Name (FN, LN)" onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtContact', this.props.state.txtContact, 'Contact Name')} onChange={this.props.HandleChange} value={this.props.state.txtContact} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="floatingInnerDiv">
                                                <FloatingLabelInput autoComplete="off" className="floatingText" id="txtContactOrgTitle" name="txtContactOrgTitle" label="Organization Title" placeholder="Organization Title" onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtContactOrgTitle', this.props.state.txtContactOrgTitle, 'Org Title')} onChange={this.props.HandleChange} value={this.props.state.txtContactOrgTitle} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="floatingInnerDiv">
                                                <FloatingLabelInput autoComplete="off" className="floatingText" id="txtEmail" name="txtEmail" label="Contact E-mail" placeholder="Contact E-mail" onBlur={() => this.props.HandleOnBlur('ForceEmail', 'txtEmail', this.props.state.txtEmail, '')} onChange={this.props.HandleChange} value={this.props.state.txtEmail} />
                                                {/* <input type="email" className="form-control" name="email" placeholder="Contact E-mail" /> */}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="floatingInnerDiv">
                                                <FloatingLabelInput autoComplete="off" className="floatingText" id="txtPhone" name="txtPhone" label="Contact Phone Number (ex: 123-456-7890)" placeholder="Contact Phone Number (ex: 123-456-7890)" onBlur={() => this.props.HandleOnBlur('ForceRPhone', 'txtPhone', this.props.state.txtPhone, 'Phone No.')} onChange={this.props.HandleChange} value={this.props.state.txtPhone} />
                                                {/* <input type="text" className="form-control" name="Number" placeholder="Contact Phone Number" /> */}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="floatingInnerDiv">
                                                <FloatingLabelInput autoComplete="off" className="floatingText" id="txtInterest" name="txtInterest" label="Services of interest" placeholder="Services of interest" onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtInterest', this.props.state.txtInterest, 'Service of Interest')} onChange={this.props.HandleChange} value={this.props.state.txtInterest} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="mandatory"><sup className="star">&#9733;</sup>All fields are mandatory</span>
                                <div className="reg_btn_next">
                                    <a href="javascript:void(0)" className="back_btn" id="first_step_back" onClick={() => this.props.ShowHideSteps('showfirst')}>Back</a>
                                    <a href="javascript:void(0)" className="next_btn" id="form_step_second" onClick={() => this.props.ShowHideSteps('second')}>Next</a>
                                </div>
                            </div>
                            <div className="animated zoomIn third_step" style={{ display: this.props.state.showThirdStep }}>
                                <div className="registration_inner_form planned_txt" style={{ paddingTop: 45 }}>
                                    <span className="step">Step 3 of 3</span>
                                    <h4>Planned Design Fabrication Information<sup className="star">&#9733;</sup></h4>
                                    <div className="form_registration">
                                        <div className="form-group">
                                            <div className="custom_select">
                                                <label className="custom_select_label">Foundry</label>
                                                <select onChange={this.props.HandleChange} name="ddlFoundry" id="ddlFoundry" value={this.props.state.ddlFoundry} >
                                                    <option value=''>Select</option>
                                                    {(JSON.stringify(this.props.foundries) !== '{}' && this.props.foundries.length > 0) ? this.props.foundries.map(item =>
                                                        <option value={item.codeId}>{item.codeName}</option>
                                                    ) : ''}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="custom_select">
                                                <label className="custom_select_label">Technology Node</label>
                                                <select onChange={this.props.HandleChange} name="ddlTechNode" id="ddlTechNode" value={this.props.state.ddlTechNode} >
                                                    <option value=''>Select</option>
                                                    {(JSON.stringify(this.props.techNodes) !== '{}' && this.props.techNodes.length > 0) ? this.props.techNodes.map(item =>
                                                        <option value={item.codeId}>{item.codeName}</option>
                                                    ) : ''}
                                                    {/* <option value='23CMOS 45RFSOI'>23CMOS 45RFSOI</option> */}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="custom_select">
                                                <label className="custom_select_label">Run Type</label>
                                                <select onChange={this.props.HandleChange} name="ddlRunType" id="ddlRunType" value={this.props.state.ddlRunType} >
                                                    <option value=''>Select</option>
                                                    {(JSON.stringify(this.props.runTypeList) !== '{}' && this.props.runTypeList.length > 0) ? this.props.runTypeList.map(item =>
                                                        <option value={item.codeId}>{item.codeName}</option>
                                                    ) : ''}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group" style={{ marginTop: -25 }}>
                                            <label className="custom_label">Submission Date</label>
                                            <div className="floatingInnerDiv" id="txtSubmissionDate">
                                                <DatePicker name="txtSubmissionDate"
                                                    format="MM/dd/y"
                                                            minDate={new Date()}
                                                    onChange={this.props.HandleDateChange}
                                                    onBlur={() => this.props.HandleOnBlur('ForceDate', 'txtSubmissionDate', this.props.state.txtSubmissionDate, 'Submission Date')}
                                                    value={this.props.state.txtSubmissionDate} clearIcon={null}
                                                    calendarIcon={<img src={require('../../assets/images/calendar.svg')} alt="calendar" width={20} />}
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="form-group"> */}
                                        {/* <input type="date" id="start" name="trip-start" value="2018-07-22" min="2018-01-01" max="2018-12-31" /> */}
                                        {/* <div className="floatingInnerDiv"> */}
                                        {/* <FloatingLabelInput className="floatingText" id="txtOrgTitle" name="txtOrgTitle" label="Organization Title" placeholder="Organization Title" onChange={this.props.HandleChange} value={this.props.state.txtOrgTitle} />
                                                <span className="input-group-addon calendar_icon" style={{right: 20, top: -27, position: 'relative', height:0}}><span className="glyphicon glyphicon-calendar">
                                                    <img src={require('../../assets/images/calendar.svg')} alt="calendar" />
                                                </span></span> */}
                                        {/* </div> */}

                                        {/* <div className="input-group date form_date" data-date="" data-date-format="dd MM yyyy" data-link-field="dtp_input2" data-link-format="yyyy-mm-dd"> */}
                                        {/* <div className="input-group date form_date">
                                                <input className="form-control dt" type="date" placeholder="Expected Design submission date" id="txtSubmissionDate" name="txtSubmissionDate" onChange={this.props.HandleChange} value={this.props.state.txtSubmissionDate} style={{ paddingTop: 9, paddingBottom: 30 }} />
                                                <span className="input-group-addon calendar_icon" style={{ right: 16, backgroundColor: '#FFFFFF', top: 45 + "%" }}><span className="glyphicon glyphicon-calendar">
                                                    <img src={require('../../assets/images/calendar.svg')} alt="calendar" />
                                                </span></span>
                                            </div>
                                            <input type="hidden" id="dtp_input2" value="" /> */}
                                        {/* </div> */}
                                        {/* <div className="form-group">
                                            <div className="floatingInnerDiv">
                                                <FloatingLabelInput className="floatingText" id="txtOrgTitle" name="txtOrgTitle" label="Organization Title" placeholder="Organization Title" onChange={this.props.HandleChange} value={this.props.state.txtOrgTitle} />
                                            </div>
                                        </div> */}
                                        <div className="form-group">
                                            <div className="floatingInnerDiv">
                                                <FloatingLabelInput autoComplete="off" className="floatingText" id="txtExpectedDie" name="txtExpectedDie" label="Expected Number of Die" placeholder="Expected Number of Die to be fabricated" onBlur={() => this.props.HandleOnBlur('ForceNumber', 'txtExpectedDie', this.props.state.txtExpectedDie, '')} onChange={this.props.HandleChange} value={this.props.state.txtExpectedDie} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="floatingInnerDiv">
                                                <FloatingLabelInput maxLength="16" autoComplete="off" className="floatingText" id="txtOrderValue" name="txtOrderValue" label="Projected Order Value (in $)" placeholder="Projected Order Value (in $)" onBlur={() => this.props.HandleOnBlur('ForceNumber', 'txtOrderValue', (this.props.state.txtOrderValue).replace(/[^0-9]/g, ''), '')} onChange={this.props.HandleChange} value={this.props.state.txtOrderValue} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="floatingInnerDiv">
                                                <FloatingLabelInput maxLength="16" autoComplete="off" className="floatingText" id="txtOrderValueInYear" name="txtOrderValueInYear" label="Projected Value of orders in the next 12 months (in $)" placeholder="Projected Value of orders in the next 12 months (in $)" onBlur={() => this.props.HandleOnBlur('ForceNumber', 'txtOrderValueInYear', (this.props.state.txtOrderValueInYear).replace(/[^0-9]/g, ''), '')} onChange={this.props.HandleChange} value={this.props.state.txtOrderValueInYear} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="mandatory"><sup className="star">&#9733;</sup>All fields are mandatory</span>
                                <ReCAPTCHA
                                    // onExpired={this.onCaptchaExpire}
                                    //sitekey="6Lea4_EUAAAAAJoeoa-MCu0Kos2l55VKUWJ6xB-z"
                                    sitekey="6Lddyq4ZAAAAAOB7eDu7HKKi2K6kg45yE1zQrBsh" 
                                    onChange={this.props.onCaptchaChange}
                                />
                                <div className="reg_btn_next">
                                    <a href="javascript:void(0)" className="back_btn" id="second_step_back" onClick={() => this.props.ShowHideSteps('showsecond')}>Back</a>
                                    {this.props.state.showHideSubmitBtn ?
                                        <a href="javascript:void(0)" className="submit_btn" onClick={this.props.RegisterUser} >Submit</a>
                                        : ''}
                                </div>
                            </div>
                        </form>
                    </div>
                    :
                    <div className="registration_form align-self-center">
                        <form action="" method="">
                            <h2 className="animated bounceInDowreg"></h2>
                            <div className="animated zoomIn first_step">
                                <div className="" style={{ paddingTop: 45 }}>
                                    <span className="step"></span>
                                    <h4><sup className="star"></sup> </h4>
                                    <div className="form_registration">
                                        <div className="form-group">
                                            <br />
                                            <br />
                                        </div>
                                        <div className="form-group">
                                            <br />
                                            <br />
                                        </div>
                                        <div className="form-group">
                                            <br />
                                            <br />
                                        </div>
                                        <div className="form-group">
                                            <br />
                                            <br />
                                            <br />
                                        </div>
                                        <div className="form-group">
                                            <br />
                                            <br />
                                            <br />
                                        </div>
                                        <div className="form-group">
                                            <br />
                                            <br />
                                            <br />
                                        </div>
                                    </div>
                                </div>
                                <div className="reg_btn_next">
                                    <br />
                                    <br />
                                    <br />
                                </div>
                            </div>
                        </form>
                    </div>
                }
            </div>
        )
    }
}