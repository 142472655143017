import React, { Component } from 'react';


import AccessDeniedComponent from './AccessDenied';
import $ from "jquery";
import {NavLink} from "react-router-dom";
//import { NavLink } from 'react-router-dom';

export default class AccessDeniedCtrl extends Component {
    GoToLearnMore = (page) => {
        this.props.history.push("learn-more-" + page);
    }

    componentDidMount() {
        $('.container-fluid').css('display', 'none');
        $('.side_logo').css('display', 'none');
        $('#page-wrapper').css({ 'margin-left': '0px' });

    }

    render() {
        return (

        <body >



        <div className="holidayNotice" style={{marginLeft: '-3px'}}>
            <div className="col-4 mx-auto" >
                <div className="site-logo text-center mb-5">
                    <NavLink to="/" style={{position: 'relative', zIndex: '2000'}}>
                        <img style={{marginTop:'60px'}} src={require('../../../assets/images/logo.png')} alt="logo"/>
                    </NavLink>
                </div>
            </div>
            <div className="inner-home" style={{top: "45%"}}>
                <h1 style={{color:"#990000", fontSize: '80px', textAlign: 'center'}}>Access Denied</h1>
                <h2 style={{color:"#990000"}}>You do not have permission to access the required page.</h2>
                {/*<p>Since 1981, A pioneer in Multi Project Wafer (MPW) fabrication services.</p>*/}
                {/*<div className="read_more text-center">
                    <a href="" onClick={()=>this.props.GoToLearnMore(1)} className="read_more_btn">Learn More</a>
                </div>*/}
            </div>
        </div>

        </body>

        )
    }
}