import { PrAjax } from '../library/ajax/pr-ajax';

export function GetFoundriesList(store, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('codes/getFoundariesWithActiveTechClasses').AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function GetTechListByFoundry(store, foundry_id, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('codes/activeCodesByCodeTypeAndCodeParentId/M_Technology_class/' + foundry_id).AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function PostDesignData(store, data, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Post('pdkaccess/save').ExtraData(data).AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function GetTechProcessFoundry(store, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('designmilestone/getProcessNameFoundryTechClassStatus').AttachLoader(loader).OnSuccess(successFunc).Do();
}

