import React, { Component } from 'react';

import { NavLink } from 'react-router-dom';
export default class LearnMoreComponent extends Component {
    componentWillMount = () => {
        this.setState({
            page: this.props.match.params.page
        })
    }
    render() {
        return (
            <div>
                {this.state.page === '1' ?
                    <section class="learn_more_bg1 learn_more_bg">
                        <div class="container-fluid">
                            <div className="row">
                                <div className="col-4 mb-5 mx-auto">
                                    <div className="site-logo text-center">
                                        <NavLink to="/" style={{position:'relative', zIndex:'9999999'}}>
                                            <img src={require('../../assets/images/logo.png')} alt="logo" />
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="container-fluid" style={{ marginBottom: 50 }}>
                            <div class="lrn_more row">
                                <div class="col-lg-10 mx-auto">
                                    <div class="lrn_more_left">
                                        <p>The University of Southern California’s Information Sciences Institute “ISI”, an organized research unit within the Viterbi School of Engineering received a series of contracts from DARPA beginning in 1981 to provide Integrated Circuit (IC) fabrication services for the US Government, primarily DARPA. ISI did pioneering work with developing what will become known as multi project wafer (MPW) capabilities. By June 1985, 1706 designs had been fabricated over the prior twelve months. In 1985, a joint DARPA and NSF program was initiated to provide VLSI fabrication access to Universities teaching VLSI design courses. In the fall of 1987, DARPA requested USC/ISI establish a commercial service so that USC could accept design fabrication orders from 3rd parties, which were expected to be primarily US Government contractors and Universities. USC did establish The MOSIS Service as a silicon prototyping and low volume production service for custom and semicustom ICs. MOSIS was an acronym of Metal Oxide Silicon Implementation System. They accepted customer designs in CIF, CALMA GDSII or MEBES format and delivered packaged parts. They were working with 13 mask fabrication houses and 7 different foundries in 1987. In addition to MPW services, MOSIS had a major focus providing wafer level reliability testing services to the design community.</p>
                                        <p>MOSIS was one of the earliest and successful implementations of electronic commerce via the Internet (pre WWW) receiving designs via electronic mail and then providing access via the “Web” and “MOSAIC” commencing in 1993.</p>
                                        <p>In the almost 40 years of service, The MOSIS Service has had over 50 US Government laboratories and agencies, 800 domestic and foreign colleges and Universities, and over 100 commercial companies submit designs for fabrication.</p>
                                        <p>The University of Southern California through The MOSIS Service is committed to providing value add services to the microelectronics community into the future.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    :
                    this.state.page === '2' ?
                        <section class="learn_more_bg2 learn_more_bg">
                            <div class="container-fluid">
                                <div className="row">
                                    <div className="col-4 mb-5 mx-auto">
                                        <div className="site-logo text-center">
                                            <NavLink to="/" style={{position:'relative', zIndex:'9999999'}}>
                                                <img src={require('../../assets/images/logo.png')} alt="logo" />
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="container-fluid" style={{ marginBottom: 50 }}>
                                <div class="lrn_more row">
                                    <div class="col-lg-10 mx-auto">
                                        <div class="lrn_more_left">
                                            <p>During the time that The MOSIS Service has been offering fabrication services there have been many changes in the microelectronics ecosystem involving mask fabrication houses and foundries. There has been a reduction in the number of foundries primarily due to the capital costs of producing chips at finer geometries. For the last decade MOSIS while working with a number of foundries, the majority of fabrication occurred with Global Foundries, TMSC and On Semiconductor. Of these only TSMC is offering a technology roadmap for the future. MOSIS has undertaken a strategic goal of establishing and maintaining access to foundries with a commitment to offering advanced technology. This involves a greater focus on work with TSMC, and our recently announced collaboration with Intel Custom Foundry and Samsung. MOSIS currently has access to TSMC 12nm and larger technology nodes and maintains access to all technology nodes offered by Global Foundries. MOSIS will be supporting 22FFL at Intel Custom Foundry and MOSIS will be offering Samsung 28nm FD-SOI, eMRAM based on 28nm FD-SOI, 65nm eFlash Bulk CMOS, and 130nm CMOS semiconductor processes. MOSIS will serve as a foundry representative to MPW customers, for both Intel Custom Foundry and Samsung. The MOSIS Service will handle customers’ design flow enablement, support, and sign-off tasks from PDK facilitation to tapeout.</p>
                                            <p>The MOSIS Service covers SiGe, planar CMOS, FD-SOI and FinFET processes. Our service covers digital, analog, RF, and BCD designs. We are currently working on creating cloud hosted services which will offer the necessary security and compute resources. The MOSIS Service intends to offer finer geometries as they become available. The finer geometries place a greater need to consider Design to Manufacturing (DFM) considerations. To meet these needs the current MOSIS Director, Dr Lifu Chang, is a renowned expert in DFM and we are actively seeking to augment our technical staff with expertise in this area.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        :
                        this.state.page === '3' ?
                            <section class="learn_more_bg3 learn_more_bg">
                                <div class="container-fluid">
                                    <div className="row">
                                        <div className="col-4 mb-5 mx-auto">
                                            <div className="site-logo text-center">
                                                <NavLink to="/" style={{position:'relative', zIndex:'9999999'}}>
                                                    <img src={require('../../assets/images/logo.png')} alt="logo" />
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="container-fluid" style={{ marginBottom: 50 }}>
                                    <div class="lrn_more row">
                                        <div class="col-lg-10 mx-auto">
                                            <div class="lrn_more_left">
                                                <p>Multi Project Wafers (MPW) offers cost effective fabrication alternatives today just as it has in the past. The basic technology and business characteristics of IC fabrication remain unchanged over time. MPW runs are regularly scheduled by the foundries. MPWs orders typically provide either 40 die (200mm wafer) or 100 die (300mm wafer). For MPWs there is a foundry limited reorder window and mask respins are not supported. There are minimum design size requirements per foundry per technology which currently range from 4 square mm to 25 square mm. The MOSIS Service offers dicing, bumping and packaging services either from the foundry or 3rd party vendors.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            :
                            this.state.page === '4' ?
                                <section class="learn_more_bg4 learn_more_bg">
                                    <div class="container-fluid">
                                        <div className="row">
                                            <div className="col-4 mb-5 mx-auto">
                                                <div className="site-logo text-center">
                                                    <NavLink to="/" style={{position:'relative', zIndex:'9999999'}}>
                                                        <img src={require('../../assets/images/logo.png')} alt="logo" />
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="container-fluid" style={{ marginBottom: 50 }}>
                                        <div class="lrn_more row">
                                            <div class="col-lg-10 mx-auto">
                                                <div class="lrn_more_left">
                                                    <p>Dedicated Runs provide customers with an alternative to MPWs which may better support their objectives. Dedicated runs are typically used for design iteration, prototyping, and low volume production. With a dedicated run there is only a single customer. This means the customer owns the mask set which provides the capability to order a respin, changes incorporated into the mask set and another round of fabrication. The customer over time can order and reorder a number of wafers. The customer can include more than one design, and depending on the number of designs and the relevant foundry MPW prices it can be cost effective to order a dedicated run, sometimes referred to as a private mpw. With a dedicated run the customer can in coordination with the foundry schedule the run to support their design timeline.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                :
                                this.state.page === '5' ?
                                    <section class="learn_more_bg5 learn_more_bg">
                                        <div class="container-fluid">
                                            <div className="row">
                                                <div className="col-4 mb-5 mx-auto">
                                                    <div className="site-logo text-center">
                                                    <NavLink to="/" style={{position:'relative', zIndex:'9999999'}}>
                                                        <img src={require('../../assets/images/logo.png')} alt="logo" />
                                                    </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="container-fluid" style={{ marginBottom: 50 }}>
                                            <div class="lrn_more row">
                                                <div class="col-lg-10 mx-auto">
                                                    <div class="lrn_more_left">
                                                        <p>The MOSIS Service as an organization within the USC Viterbi School of Engineering, a top ranked engineering program, has always supported the commitment to engineering students. The MOSIS Service has provided support to numerous universities over the decades. The support has consisted of providing on a select basis free fabrication to domestic University professors and students. Over the years there have been a variety of other organizations which also supported this objective. In the past the NSF supported VLSI training. Later various industry organizations, and microelectronic industry corporations provided support. For the last several years all student fabrications costs have been covered solely by The MOSIS Service. Our recent support consisted of sponsoring fabrication with ON Semiconductor B5 0.5 micron process, formerly C5, for undergraduate designs.</p>
                                                        <p>The University Support Program is being reformulated with an objective of providing more impactful support for advanced technology nodes. We are no longer sponsoring fabrication with On Semiconductor. We look forward to further announcements on this subject.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    :
                                    ''}
            </div>
        )
    }
}