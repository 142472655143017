import { PrAjax } from '../library/ajax/pr-ajax';

export function GetOtherDesignUploadList(store, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('otherFileUpload/getOtherFileUploadList').AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function SaveOtherDesignUploadList(store, data, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Post('otherFileUpload/save').ExtraData(data).AttachLoader(loader).OnSuccess(successFunc).Do();
}