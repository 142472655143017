import React, { Component } from 'react';

import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
//import { GetTechProcessFoundry } from '../../../services/foundrypdk';

import { getDesignUploadQuestionGroup, getQuestionsByTechClass, getFoundryAndTechClass, getFoundryAndTechClassByCustomerId } from '../../../../services/designUploadQuestion';
import { PrConnect } from '../../../../library/pr-miniredux';
import {ServicesDesignFileUploadComponentCtrl} from '../../../ServicesDesignFileUpload/ServicesDesignFileUpload'
import ConfirmationPopup from './confirmationPopup'

export class ConfirmationPopupCtrl extends Component {
    constructor(props) {
        super(props);
        console.log("props");
        console.log(props);
        this.state = {
          

        };
        this.handleRadioBoxChange = this.handleRadioBoxChange.bind(this);
    }


    UNSAFE_componentWillMount = () => {

    };

    handleRadioBoxChange = (e) => {
        console.log("inside radio change: "+e.target.value);
  
        if(e.target.value == "CONFIRM"){
            console.log("INSIDE CONFIRM")
            this.props.componentprops.HandleConfirmationSelection(true);
        }else{
            console.log("INSIDE CANCEL")
            this.props.componentprops.HandleConfirmationSelection(false);
        }
        


    }



    render() {

        return (


            <div>
                    <ConfirmationPopup {...this.props} state={this.state} handleRadioBoxChange={this.handleRadioBoxChange}
    HandleConfirmationSelection={this.HandleConfirmationSelection}
                           />
                
            </div>

        )
    }
}
export default ConfirmationPopupCtrl = PrConnect(ConfirmationPopupCtrl, '');