import { PrAjax } from '../library/ajax/pr-ajax';

export function UserRegistration(store, userObj, successFunc) {
    //code for further validations
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Post('customerRegistration/save').ExtraData(userObj).AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function GetCountryList(store, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('codes/codesByCodeType/M_COUNTRY').AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function GetContactTypeList(store, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('codes/codesByCodeType/M_CONTACT_TYPE').AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function GetAddressTypeList(store, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('codes/codesByCodeType/M_ADDRESS_TYPE').AttachLoader(loader).OnSuccess(successFunc).Do();
}


export function GetOrgTypesList(store, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('codes/codesByCodeType/M_Organizational_Type').AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function GetFoundriesList(store, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('foundryfabschedule/getDistinctFoundries').AttachLoader(loader).OnSuccess(successFunc).Do();
    // return PrAjax(store).Get('codes/codesByCodeType/M_Foundry').AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function GetRunTypeList(store, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('codes/codesByCodeType/M_Run_Type').AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function GetTechListByFoundry(store, foundry_id, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('foundryfabschedule/getTechnologyClasses/' + foundry_id).AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function GetMilestoneByFoundryAndShipDate(store, foundry_id, ship_date, fabScheduleId, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    // return PrAjax(store).Get('foundryfabschedule/getMilesStoneByfoundryId?foundryId=23&shipDate=2020-05-02&foundryFabScheduleId=130').AttachLoader(loader).OnSuccess(successFunc).Do();
    return PrAjax(store).Get('foundryfabschedule/getMilesStoneByfoundryId?foundryId=' + foundry_id + "&shipDate=" + ship_date + "&foundryFabScheduleId=" + fabScheduleId).AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function GetScheduleByFoundryAndTech(store, foundry_id, tech_id, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    // return PrAjax(store).Get('foundryfabschedule/findScheduleByFoundryIdAndTechClassId?foundryId=25&techClassId=130').AttachLoader(loader).OnSuccess(successFunc).Do();
    return PrAjax(store).Get('foundryfabschedule/findScheduleByFoundryIdAndTechClassId?foundryId='+foundry_id+'&techClassId='+tech_id).AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function GetAllowedRoutes(store, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };

    let email = JSON.parse(localStorage.getItem('okta-token-storage')).idToken.claims.email;
    return PrAjax(store).Get('authority/getPrivilegesByEmail/' + email).AttachLoader(loader).OnSuccess(successFunc).Do();

}

export function GetAccountTypes(store, successFunc) {
    let loader = { isLoader: false, containerId: 'testpr', size: 'small' };
    // if (!(store.GetData('citylist').length > 0)) {
    return PrAjax(store).Get('codes/codesByCodeType/M_Account_Type').AttachLoader(loader).OnSuccess(successFunc).Do();
    // }
    // else return successFunc(store.GetData('citylist'), store);
}

export function getCustomerData(store, successFunc, id) {
    let loader = { isLoader: false, containerId: 'testpr', size: 'small' };
    //return PrAjax(store).Get('customer/id/'+id).AttachLoader(loader).OnSuccess(successFunc).Do();
    return PrAjax(store).Get('customer/get').AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function GetPriority(store, successFunc) {
    let loader = { isLoader: false, containerId: 'testpr', size: 'small' };
    // if (!(store.GetData('citylist').length > 0)) {
    return PrAjax(store).Get('codes/codesByCodeType/M_Customer_Priority').AttachLoader(loader).OnSuccess(successFunc).Do();
    // }
    // else return successFunc(store.GetData('citylist'), store);
}

export function GetInvoicingMethod(store, successFunc) {
    let loader = { isLoader: false, containerId: 'testpr', size: 'small' };
    // if (!(store.GetData('citylist').length > 0)) {
    return PrAjax(store).Get('codes/codesByCodeType/M_Invoicing_Method').AttachLoader(loader).OnSuccess(successFunc).Do();
    // }
    // else return successFunc(store.GetData('citylist'), store);
}

export function PostDesignData(store, data, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Post('customer/save').ExtraData(data).AttachLoader(loader).OnSuccess(successFunc).Do();
}