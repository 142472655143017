import React, { Component } from 'react';
import $ from 'jquery';

export default class UploadPopup extends Component {
    ClearMouse = () => {
    $('.inner_table').css('display', 'none');
}
render() {
    return (
        <div id="page-wrapper1">
            <div id="page-inner1" style={{ background: '#fff' }}>
                <div className="page_head col-lg-20">
                    <h3>
                        Uploading Bonding file
                        {/*Uploading files {"("+this.props.state.currentCount+"/"+this.props.state.totalCount+")"}*/}
                    </h3>
                    {/*<div className="row">
                        <div className="col-lg-12 mx-auto">*/}
                            <div className="ServicesDesign_card" style={{display: this.props.state.showSuccessMessage }}>
                                File Successfully uploaded
                            </div>
                            <div className="text-center" style={{display: this.props.state.showSuccessMessage , paddingTop:"2%"}}>
                                <button type="button" onClick={this.onOkClick} style={{backgroundColor: '#dfdfdf', color: '#990000', width:'25%'}} className="dash_submit_btn">OK</button>
                            </div>
                            <div className="ServicesDesign_card" style={{display: this.props.state.showUploadSection, paddingTop: "5%", paddingBottom: "5%" }}>
                                <form>
                                    <div className="row align-items-end justify-content-center m-0 p-0">
                                        <div className="col-progress">
                                            <h6><a>File Name: {this.props.state.currentFileName}</a></h6>
                                        </div>
                                        <div className="col-progress" style={{display:'none'}}>
                                            <small>Upload Status</small>
                                            <div className="progress" style={{height:'22px'}}>
                                                <div className="progress-bar progress-bar-striped progress-bar-animated" style={{width:'0%',backgroundColor:'green'}}></div>
                                            </div>
                                        </div>


                                    </div>
                                </form>
                                <label style={{ display: this.props.state.showWaitForFileUpload , color:"red", fontSize: 15, fontWeight: 700, paddingTop: 8}}>Please wait until file upload is in progress.</label>
                            </div>
                        {/*</div>
                    </div>*/}
                </div>
            </div>
        </div>
)
}
}