import { PrAjax } from '../library/ajax/pr-ajax';

export function GetAllActiveByDesignNumber(store, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('designstatus/findActiveDesignNumber').AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function GetReOrderDesignNumbers(store, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('designstatus/findReOrderDesignNumbers').AttachLoader(loader).OnSuccess(successFunc).Do();
}


