import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class AboutComponent extends Component{
    render(){
        return(
            <div>
                <section className="about_bg">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-4 mx-auto">
                                    <div className="site-logo text-center mb-5">
                                        <NavLink to="/" style={{position:'relative', zIndex:'2000'}}>
                                            <img src={require('../../assets/images/logo.png')} alt="logo" />
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className="mosis_hdng">
                                <h2 className="text-center ">The MOSIS Service</h2>
                                <p className="text-center">Since 1981, A pioneer in Multi Project Wafer (MPW) fabrication services.</p>
                            </div>
                            
                            <div className="row">
                                <div className="col-lg-10 mx-auto">
                                    <div className="text-left">
                                        <p>The University of Southern California’s Information Sciences Institute “ISI”, an organized research unit within the Viterbi School of Engineering received a series of contracts from DARPA beginning in 1980 to provide Integrated Circuit (IC) fabrication services for the US Government, primarily DARPA. ISI did pioneering work with developing what will become known as multi project wafer (MPW) capabilities. By June 1985, 1706 designs had been fabricated over the prior twelve months. In 1985, a joint DARPA and NSF program was initiated to provide VLSI fabrication access to Universities teaching VLSI design courses. In the fall of 1987, DARPA requested USC/ISI establish a commercial service so that USC could accept design fabrication orders from 3rd parties, which were expected to be primarily US Government contractors and Universities. USC did establish The MOSIS Service as a silicon prototyping and low volume production service for custom and semicustom ICs. MOSIS was an acronym of Metal Oxide Semiconductor Implementation System. They accepted customer designs in CIF, CALMA GDSII or MEBES format and delivered packaged parts. They were working with 13 mask fabrication houses and 7 different foundries in 1987. In addition to MPW services, MOSIS had a major focus providing wafer level reliability testing services to the design community.</p>
                                        <p>MOSIS was one of the earliest and successful implementations of electronic commerce via the Internet (pre WWW) receiving designs via electronic mail and then providing access via the “Web” and “MOSAIC” commencing in 1993.</p>
                                        <p>In the almost 40 years of service, The MOSIS Service has had over 50 US Government laboratories and agencies, 800 domestic and foreign colleges and Universities, and over 100 commercial companies submit designs for fabrication.</p>
                                        <p>The University of Southern California through The MOSIS Service is committed to providing value add services to the microelectronics community into the future.</p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="container-fluid">
                            <div className="mosis_hdng">
                                <h2 className="text-center ">Advanced Foundry Access</h2>
                                <p className="text-center">Access to domestic and foreign foundries</p>
                            </div>
                            <div className="row">
                                <div className="col-lg-10 mx-auto">
                                    <div className="text-left">
                                        <p>During the time that The MOSIS Service has been offering fabrication services there have been many changes in the microelectronics ecosystem involving mask fabrication houses and foundries. There has been a reduction in the number of foundries primarily due to the capital costs of producing chips at finer geometries. For the last decade MOSIS while working with a number of foundries, the majority of fabrication occurred with Global Foundries, TSMC and ON Semiconductor. Of these only TSMC is offering a technology roadmap for the future. MOSIS has undertaken a strategic goal of establishing and maintaining access to foundries with a commitment to offering advanced technology. This involves a greater focus on work with TSMC, and our recently announced collaboration with Intel Foundry Services, Samsung Foundry, SkyWater Technology, and WIN Semiconductors Corp. MOSIS currently has access to TSMC 12nm and larger technology nodes, and Intel 22FFL and 16nm. MOSIS is offering Samsung Foundry 28nm FD-SOI and eMRAM based on 28nm FD-SOI. Our most recent collaboration with SkyWater to offer multi-project wafer (MPW) shuttle access to S90 and S130 mixed-signal CMOS technologies. MOSIS will serve as a foundry representative to MPW customers. The MOSIS Service will handle customers’ design flow enablement, support, and sign-off tasks from PDK facilitation to tapeout.</p>
                                        <p>The MOSIS Service covers planar CMOS, FD-SOI, and FinFET processes. Our service covers digital, analog, RF, and BCD designs. The MOSIS Service intends to offer finer geometries as they become available. The finer geometries place a greater need to consider Design to Manufacturing (DFM) considerations. To meet these needs the current MOSIS Director, Dr Lifu Chang, is a renowned expert in DFM and we are actively seeking to augment our capabilities in this area.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container-fluid">
                            <div className="mosis_hdng">
                                <h2 className="text-center ">MPW projects on Gallium Nitride and Gallium Arsenide Processes</h2>
                            </div>
                            <div className="row">
                                <div className="col-lg-10 mx-auto">
                                    <div className="text-left">
                                        <p>MOSIS is offering MPW projects on Gallium Nitride (GaN) NP15-00 and Gallium Arsenide (GaAs) PQH1-0P processes fabricated by WIN Semiconductors Corp. MOSIS organizes and assembles designs in GaN NP15-00 MPW wafers. MOSIS also organizes and assembles designs in GaAs PQH1-0P MPW wafers. The MOSIS Service and WIN Semiconductors will coordinate the MPW tapeout schedules with the customers.  The MOSIS Service provides PDK and other supports to achieve successful tapeouts for the customers.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container-fluid">
                            <div className="mosis_hdng">
                                <h2 className="text-center ">Multi Project Wafer (MPW) Fabrication</h2>
                                <p className="text-center">Cost effective access to various technology nodes</p>
                            </div>
                            <div className="row">
                                <div className="col-lg-10 mx-auto">
                                    <div className="text-left">
                                        <p>Multi Project Wafers (MPW) offers cost effective fabrication alternatives today just as it has in the past. The basic technology and business characteristics of IC fabrication remain unchanged over time. MPW runs are regularly scheduled by the foundries. MPWs orders typically provide 40, 50 or 100 die per lot depending on Foundry and wafer sizes. For MPWs there is a foundry limited reorder window and mask respins are not supported. There are minimum design size requirements per foundry per technology which currently range from 4 square mm to 25 square mm. The MOSIS Service offers dicing, bumping and packaging services either from the foundry or 3rd party vendors.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container-fluid">
                            <div className="mosis_hdng">
                                <h2 className="text-center ">Dedicated Fabrication Services</h2>
                                <p className="text-center">Low volume production and multiple order support</p>
                            </div>
                            <div className="row">
                                <div className="col-lg-10 mx-auto">
                                    <div className="text-left">
                                        <p>Dedicated Runs provide customers with an alternative to MPWs which may better support their objectives. Dedicated runs are typically used for design iteration, prototyping, and low volume production. With a dedicated run there is only a single customer. This means the customer owns the mask set which provides the capability to order a respin, changes incorporated into the mask set and another round of fabrication. The customer over time can order and reorder a number of wafers. The customer can include more than one design, and depending on the number of designs and the relevant foundry MPW prices it can be cost effective to order a dedicated run, sometimes referred to as a private MPW. With a dedicated run the customer can in coordination with the foundry schedule the run to support their design timeline.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container-fluid" style={{marginBottom: 60}}>
                            <div className="mosis_hdng">
                                <h2 className="text-center ">University Support Program- On hold</h2>
                                {/*<p className="text-center">Provides professors and students with access to free fabrication</p>*/}
                            </div>
                            <div className="row">
                                <div className="col-lg-10 mx-auto">
                                    <div className="text-left">
                                        <p>The MOSIS Service as an organization within the USC Viterbi School of Engineering, a top ranked engineering program, has always supported the commitment to engineering students. The MOSIS Service has provided support to numerous universities over the decades. The support has consisted of providing on a select basis free fabrication to domestic University professors and students. Over the years there have been a variety of other organizations which also supported this objective. In the past the NSF supported VLSI training. Later various industry organizations, and microelectronic industry corporations provided support. For the last several years all student fabrications costs have been covered solely by The MOSIS Service.</p>
                                        <p>Given the current state where Foundries now schedule and run all of their MPW shuttles, there is no longer available space on the reticle for MOSIS to offer free fabrication.  We are aware of the value of our University Support Program and remain vigilant to identify future opportunities of again being able to offer it.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
            </section>
            </div>
        )
    }
}