import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { TableDesignCtrlNew } from '../newtable';

export default class GeneralPurposeComponent extends Component {
    render(){
        return(
            <div>
                <div className="page_head col-lg-8 mx-auto">
                    <div>
                        <div className="account">
                            <div className="accoutn_form">
                                <form action="" method="">
                                    <div className="dashboard_form">
                                        <div className="inner-dashoard_form">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="brd_section">
                                                        <div className="row new_pos">
                                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                                <div className="inner_left">
                                                                    <label>This Export Control Questionnaire is being completed for the following MOSIS design number(s)</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="brd_section">
                                                        <div className="row new_pos">
                                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                                <div className="inner_left">
                                                                    <label>Extended Price</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-6">
                                                                <div className="inner_right">
                                                                    <div className="form-group mb-0">
                                                                        <input type="text" className="form-control" name="txtUserName" id="txtUserName" onChange={this.props.HandleChange} />
                                                                        <div className="GeneralPurpose_Popup_table">
                                                                        {(this.props.state.txtUserName !== '' && this.props.state.showPopup === true) && <TableDesignCtrlNew />}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="brd_section">
                                                        <div className="row new_pos">
                                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                                <div className="inner_left">
                                                                    <label>Message</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-6">
                                                                <div className="">
                                                                <div className="form-group mb-0">
                                                                    <textarea style={{minHeight: 'auto'}} type="text" className="form-control" name="" id=""></textarea>
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="brd_section">
                                                        <div className="row new_pos">
                                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                                <div className="inner_left">
                                                                    <label>Upload File	</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-6">
                                                                <div className="">
                                                                <div className="form-group mb-0">
                                                                    <input type="file" className="form-control" name="" id=""  />
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="brd_section">
                                                        <div className="row new_pos">
                                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                                <div className="inner_left">
                                                                    <label>Attachment</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-6">
                                                                <div className="">
                                                                <div className="form-group mb-0">
                                                                    <NavLink className="tbl_link" to='/'>Click Here</NavLink>
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="brd_section">
                                                        <div className="row new_pos">
                                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                                <div className="inner_left">
                                                                    <label>Discription</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-6">
                                                                <div className="inner_left">
                                                                    <label className="ml-0">I have attached an image for your reference.</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="brd_section">
                                                        <div className="row new_pos">
                                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                                <div className="inner_left">
                                                                    <label>Quantity</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-6">
                                                                <div className="inner_right">
                                                                    <div className="form-group mb-0">
                                                                        <input type="text" className="form-control" name="" id="" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="brd_section">
                                                        <div className="row new_pos">
                                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                                <div className="inner_left">
                                                                    <label>Calendar</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-6">
                                                                <div className="inner_right">
                                                                    <div className="form-group mb-0">
                                                                        <input type="text" className="form-control" name="" id="" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="brd_section">
                                                        <div className="row new_pos">
                                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                                <div className="inner_left">
                                                                    <label>Search</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-6">
                                                                <div className="inner_right">
                                                                    <div className="form-group mb-0">
                                                                        <input type="text" className="form-control" name="" id="" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="brd_section">
                                                        <div className="row new_pos">
                                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                                <div className="inner_left">
                                                                    <label>Cost</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-8 col-md-8 col-sm-6">
                                                                <div className="inner_right">
                                                                    <div className="form-group mb-0">
                                                                        <input type="text" className="form-control" name="" id="" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}