import React, { Component } from 'react';
import { PrConnect } from '../../../library/pr-miniredux';
import DashboardHomeComponent from './home';
export default class DashboardCtrl extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <DashboardHomeComponent {...this.props} />
            </div>
        )
    }
}