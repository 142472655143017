import React, { Component } from 'react';
import DesignSubmission from './designsubmission';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import $ from 'jquery';
import PrValidate from '../../../library/pr-controls/pr-validation-full';

import { PrConnect } from '../../../library/pr-miniredux';
import { GetFoundriesList, GetTechListByFoundry, GetRunTypeList, GetPackageTypeList, PostDesignData } from '../../../services/designsubmission';
export class designSubmissionCtrl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetchFoundries: true,
            foundriesList: {},
            fetchTechNode: false,
            techNodesList: {},
            fetchRunType: true,
            runTypeList: {},
            fetchPackageType: true,
            packageTypeList: {},
            postDesignData: false,

            ddlTechNode: '',
            ddlFoundry: '',
            ddlRunType: '',
            preliminaryDesignUploadDate: '',
            projectedFinalDesignUploadDate: '',
            txtProjectedNoOfDesign: '',
            txtXDimension: '',
            txtYDimension: '',
            txtDieSize: '',
            txtNoDieToFabricated: '',
            txtNoDieToPackaged: '',
            ddlPackageType: '',
            min_projected_date: new Date(),
            max_preliminari_date: new Date('01-01-2999'),
            showNoDieToPackagedError: false,
            enableNoDieToPackaged: true,
            showXDimensionError: false,
            projectedFinalDesignUploadMinDate:'',

            result: {}
        }
        this.design_data = {}
    }

    UNSAFE_componentWillMount = () => {
    }

    //changes for date selection
    HandlePreliminaryDesignUploadDateChange = (preliminaryDesignUploadDate) => {
        if (this.state.projectedFinalDesignUploadDate !== '') {
            if ((this.state.projectedFinalDesignUploadDate) >= preliminaryDesignUploadDate){
                this.setState({ preliminaryDesignUploadDate })
            }else{
                this.setState({projectedFinalDesignUploadDate:"" })
                var date = new Date(preliminaryDesignUploadDate)
                date.setDate(preliminaryDesignUploadDate.getDate() + 1)
                console.log("date: "+date)
                this.setState({ preliminaryDesignUploadDate, min_projected_date: date})
            }
        }
        else {
            var date = new Date(preliminaryDesignUploadDate)
            date.setDate(preliminaryDesignUploadDate.getDate() + 1)
            console.log("date: "+date)
            this.setState({ preliminaryDesignUploadDate, min_projected_date: date})
        }
    }
    HandleProjectedFinalDesignUploadDateChange = (projectedFinalDesignUploadDate) => {
        if (this.state.preliminaryDesignUploadDate !== '') {
            if ((this.state.preliminaryDesignUploadDate) <= projectedFinalDesignUploadDate)
                this.setState({ projectedFinalDesignUploadDate })
        }
        else {
            this.setState({ projectedFinalDesignUploadDate, max_preliminari_date: projectedFinalDesignUploadDate })
        }
    }
    // HandlePreliminaryDesignUploadDateChange = preliminaryDesignUploadDate => this.setState({ preliminaryDesignUploadDate })
    // HandleProjectedFinalDesignUploadDateChange = projectedFinalDesignUploadDate => this.setState({ projectedFinalDesignUploadDate })

    HandleChange = (e) => {
        if (e.target.name === 'ddlFoundry') {
            if(e.target.value!=''){
                let foundrynames = '';
                this.setState({
                    fetchTechNode: true,
                    [e.target.name]: e.target.value
                });
            }else{
                this.setState({
                    [e.target.name]: e.target.value,
                    techNodesList:{}
                });

            }
        }
        else if (e.target.name === 'txtProjectedNoOfDesign' || e.target.name === 'txtNoDieToFabricated' || e.target.name === 'txtNoDieToPackaged') {
            /*if(e.target.name != 'txtNoDieToFabricated' && e.target.name != 'txtNoDieToPackaged') {
                const re = /^[0-9\b]+$/;
                // if value is not blank, then test the regex
                // let val = e.target.value;
                let val = e.target.value.replace(/[^0-9]/g, '');
                if (val === '' || re.test(val)) {
                    val = val.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
                    this.setState({
                        [e.target.name]: val
                    })
                }
            }*/

            if(e.target.name === 'txtProjectedNoOfDesign' || e.target.name === 'txtNoDieToFabricated' || e.target.name === 'txtNoDieToPackaged') {
                if (e.target.value.match(/^[0-9]{0,6}$/g) || e.target.value == "") {
                    this.setState({
                        [e.target.name]:e.target.value
                    })
                    console.log("this.state.txtProjectedNoOfDesign: "+this.state.txtProjectedNoOfDesign)
                    console.log("this.state.txtNoDieToPackaged: "+this.state.txtNoDieToPackaged)
                    console.log("this.state.txtNoDieToFabricated: "+this.state.txtNoDieToFabricated)
                    if (e.target.name === 'txtNoDieToFabricated') {
                        if (this.state.txtNoDieToPackaged > 0) {
                            this.checkNumberOfDiePackagedValidation(e.target.value, this.state.txtNoDieToPackaged)
                        }
                    }

                    if (e.target.name === 'txtNoDieToPackaged') {
                        this.checkNumberOfDiePackagedValidation(this.state.txtNoDieToFabricated, e.target.value)
                    }
                    //e.target.value = e.target.value
                }

            }



        }else if (e.target.name === 'txtXDimension' || e.target.name === 'txtYDimension'){
            console.log('decimalcheck got called', e.target.value);
            if(e.target.value.match(/^(?:\d{1,6}|(?!.{7})\d+\.\d+)$/g) || e.target.value.match(/^(?:\d{1,6}|(?!.{7})\d+\.)$/g) || e.target.value == "") {
                console.log("inside IF======")
                this.setState({
                    [e.target.name]: e.target.value
                })

                if(e.target.name == "txtXDimension"){
                    this.checkForZeroOrLess(e.target.name, e.target.value, "showXDimensionError")
                }else if(e.target.name == "txtYDimension"){
                    this.checkForZeroOrLess(e.target.name, e.target.value, "showYDimensionError")
                }
                this.CalculateDieSize(e.target.name, this.roundOff(e.target.value, 3));
            }




        }
        else {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
        if (e.target.name === 'ddlTechNode')
            PrValidate.ForceSelect(e.target.value, 'ddlTechNode', 'a technology node');
        else if (e.target.name === 'ddlFoundry')
            PrValidate.ForceSelect(e.target.value, 'ddlFoundry', 'a foundry');
        else if (e.target.name === 'ddlRunType')
            PrValidate.ForceSelect(e.target.value, 'ddlRunType', 'a run type');
        else if (e.target.name === 'ddlPackageType')
            PrValidate.ForceSelect(e.target.value, 'ddlPackageType', 'a package type');
    }

    highlightText = (e) =>{
        console.log("inside highlightText")
        $('#'+e.target.name).select();
    }

    checkForZeroOrLess = (name, value, errorName) => {
        console.log("value: "+value)
        if(value != ""){
            if(value <= 0){
                this.setState({
                    [errorName]: true
                })
            }else{
                this.setState({
                    [errorName]: false
                })
            }
        }else{
            this.setState({
                [errorName]: false
            })
        }

    }

    checkNumberOfDiePackagedValidation = (NoDieToFabricated,value) => {
        console.log("value: "+value)
        console.log("NoDieToFabricated: "+NoDieToFabricated)
        if(Number(NoDieToFabricated) < Number(value)){
            this.state.showNoDieToPackagedError = true
        }else{
            this.state.showNoDieToPackagedError = false
        }
    }

    onChangeInputDieSize = (e) => {
        let val = "";
        if(e.target.value.length > 0 && this._isNumber(e.target.value)){
            val = e.target.value;
            val = this.roundOff(val, 1);
        }

        e.target.value = val;
        console.log("Inside onChangeInputDieSize: "+e.target.value)
        this.setState({
            [e.target.name]: e.target.value,
        })
        //this.state.txtInputDieSize = e.target.value
        console.log("this.state.selectedProcess: "+JSON.stringify(this.state.selectedProcess))
        if(Object.keys(this.state.selectedProcess).length > 0){
            this.calculateQuotation();
        }

    }

    normalizeNumber(num) {
        return this._isNumber(num) ? Number(num) : 0;
    }

    _isNumber(input) {
        return !(typeof input == 'undefined' || isNaN(input));
    }

    roundOff(num, toDecimals) {
        if (!this._isNumber(num))
            throw num;

        return parseFloat(String(num)).toFixed(this.normalizeNumber(toDecimals));
    }

    HandleOnBlur = (type, field, value, msg) => {
        if (field === 'txtXDimension' || field === 'txtYDimension' || field === 'txtProjectedNoOfDesign' || field === 'txtNoDieToFabricated' || field === 'txtNoDieToPackaged') {
            if (value.length > 0 && this._isNumber(value)) {
                value = value;
                if(field === 'txtProjectedNoOfDesign' || field === 'txtNoDieToFabricated' || field === 'txtNoDieToPackaged'){
                    value = this.roundOff(value, 0);
                }else{
                    //value = this.roundOff(value, 3);
                    value = (Math.round(value * 1000) / 1000).toString();
                }

                this.setState({
                    [field]: value
                })
            }
        }

        if (type === 'ForceSelect')
            PrValidate.ForceSelect(value, field, msg);
        else if (type === 'ForceRNumber')
            PrValidate.ForceRNumber(value.replace(/[^0-9]/g, ''), field, msg);
        if (type === 'ForceDate')
            PrValidate.ForceDate(value, field, msg);
        if (type === 'ForceEntry')
            PrValidate.ForceEntry(value, field, msg);
    }

    CalculateDieSize = (field, data) => {
        if (field === 'txtXDimension' && this.state.txtYDimension !== '') {
            //console.log("this.state.txtYDimension).replace(/[^0-9]/g, '') : "+(this.state.txtYDimension).replace(/[^0-9]/g, '') )
            //console.log(data.replace(/[^0-9]/g, ''))
            let res = (this.state.txtYDimension)  * data
            //res = JSON.stringify(res).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");

            //res = this.roundOff(res,3)
            res = (Math.round(res * 1000) / 1000).toString();

                this.setState({
                txtDieSize: res
            })
        }
        else if (field === 'txtYDimension' && this.state.txtXDimension !== '') {
            //console.log("this.state.txtXDimension).replace(/[^0-9]/g, '') : "+(this.state.txtXDimension).replace(/[^0-9]/g, '') )
            //console.log(data.replace(/[^0-9]/g, ''))
            let res = (this.state.txtXDimension) * data
            //res = JSON.stringify(res).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");

            //res = this.roundOff(res,3)
            res = (Math.round(res * 1000) / 1000).toString();

            this.setState({
                txtDieSize: res
            })
        }
    }

    HandleSuccessGetFoundriesResponse = (res) => {
        this.setState({
            fetchFoundries: false,
            foundriesList: res.data
        })
    }
    HandleSuccessGetTechNodesResponse = (res) => {
        console.log("res.data: "+JSON.stringify(res.data))
        this.setState({
            fetchTechNode: false,
            techNodesList: res.data
        })
    }
    HandleSuccessGetRunTypeResponse = (res) => {
        this.setState({
            fetchRunType: false,
            runTypeList: res.data
        })
    }
    HandleSuccessGetPackageTypeResponse = (res) => {
        this.setState({
            fetchPackageType: false,
            packageTypeList: res.data
        })
    }

    SubmitDesign = () =>
{
    let ddlTechNode = PrValidate.ForceSelect(this.state.ddlTechNode, 'ddlTechNode', 'a technology node');
    let ddlFoundry = PrValidate.ForceSelect(this.state.ddlFoundry, 'ddlFoundry', 'a foundry');
    let ddlRunType = PrValidate.ForceSelect(this.state.ddlRunType, 'ddlRunType', 'a run type');
    let preliminaryDesignUploadDate = PrValidate.ForceDate(this.state.preliminaryDesignUploadDate, 'preliminaryDesignUploadDate', "Preliminary Design Upload Date");
    let projectedFinalDesignUploadDate = PrValidate.ForceDate(this.state.projectedFinalDesignUploadDate, 'projectedFinalDesignUploadDate', "Projected Final Upload Date");
    let txtProjectedNoOfDesign = PrValidate.ForceRNumber((this.state.txtProjectedNoOfDesign).replace(/[^0-9]/g, ''), 'txtProjectedNoOfDesign');
    let txtXDimension = PrValidate.ForceRNumber((this.state.txtXDimension).replace(/[^0-9]/g, ''), 'txtXDimension');
    let txtYDimension = PrValidate.ForceRNumber((this.state.txtYDimension).replace(/[^0-9]/g, ''), 'txtYDimension');
    let txtNoDieToFabricated = PrValidate.ForceRNumber((this.state.txtNoDieToFabricated).replace(/[^0-9]/g, ''), 'txtNoDieToFabricated');
    let txtNoDieToPackaged = PrValidate.ForceRNumberAllowedZero((this.state.txtNoDieToPackaged).replace(/[^0-9]/g, ''), 'txtNoDieToPackaged');
    let ddlPackageType = null;
    if (this.state.txtNoDieToPackaged > 0) {
        ddlPackageType = PrValidate.ForceSelect(this.state.ddlPackageType, 'ddlPackageType', 'a package type');

    } else
    {
        ddlPackageType = PrValidate.ForceSelect(1, 'ddlPackageType', '');
    }
        let txtNoDieToPackagedGttxtNoDieToFabricated = this.state.showNoDieToPackagedError? false:true

        let err_arr = [ddlTechNode, ddlFoundry, ddlRunType, preliminaryDesignUploadDate, projectedFinalDesignUploadDate, txtProjectedNoOfDesign, txtXDimension, txtYDimension,
            txtNoDieToFabricated, txtNoDieToPackaged, ddlPackageType, txtNoDieToPackagedGttxtNoDieToFabricated, !this.state.showXDimensionError, !this.state.showYDimensionError];
        let chk_false = 0;

        let that = this;

        err_arr.forEach(function (element, index) {
            if (!element)
                chk_false++;
            if ((err_arr.length - 1) === index) {
                if (chk_false === 0) {
                    console.log("that.state.txtXDimension): "+that.state.txtXDimension)
                    console.log("that.state.txtYDimension): "+that.state.txtYDimension)
                    that.design_data = {
                        techProcess: that.state.ddlTechNode,
                        foundry: that.state.ddlFoundry,
                        runType: that.state.ddlRunType,
                        projectedDatePreliminaryUpload: that.state.preliminaryDesignUploadDate,
                        projectedDateFinalUpload: that.state.projectedFinalDesignUploadDate,
                        numberofDesignUploads: (that.state.txtProjectedNoOfDesign).replace(/[^0-9]/g, ''),
                        dieSizeX: (that.state.txtXDimension),
                        dieSizeY: (that.state.txtYDimension),
                        dieSizeXY: (that.state.txtDieSize),
                        numberOfDieToFabricate: (that.state.txtNoDieToFabricated).replace(/[^0-9]/g, ''),
                        numberOfDieToPackage: (that.state.txtNoDieToPackaged).replace(/[^0-9]/g, ''),
                        packagingType: that.state.ddlPackageType,
                    }

                    that.setState({
                        postDesignData: true
                    })
                }
            }
        });
    }

    HandleSuccessPostDesignResponse = (res) => {
        //ToastsStore.success("Successfully Added.");
        this.setState({
            result: res.data,
            postDesignData: false,
            ddlTechNode: '',
            ddlFoundry: '',
            ddlRunType: '',
            preliminaryDesignUploadDate: new Date(),
            projectedFinalDesignUploadDate: new Date(),
            txtProjectedNoOfDesign: '',
            txtXDimension: '',
            txtYDimension: '',
            txtDieSize: '',
            txtNoDieToFabricated: '',
            txtNoDieToPackaged: '',
            ddlPackageType: ''
        })
    }

    onOkClick = () => {
        this.props.componentprops.history.push("/dashboard");
    }

    render() {
        return (
            <div>
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_CENTER} />
                <DesignSubmission {...this.props} state={this.state} HandleChange={this.HandleChange}
                    foundries={this.state.foundriesList} techNodes={this.state.techNodesList}
                    runType={this.state.runTypeList} packageType={this.state.packageTypeList}
                    SubmitDesign={this.SubmitDesign} HandleOnBlur={this.HandleOnBlur}
                    HandlePreliminaryDesignUploadDateChange={this.HandlePreliminaryDesignUploadDateChange}
                    HandleProjectedFinalDesignUploadDateChange={this.HandleProjectedFinalDesignUploadDateChange}
                                  highlightText={this.highlightText}
                                  onOkClick={this.onOkClick}/>

                {this.state.fetchFoundries === true ? GetFoundriesList(this.props.PrStore, this.HandleSuccessGetFoundriesResponse) : ''}
                {this.state.fetchTechNode === true ? GetTechListByFoundry(this.props.PrStore, this.state.ddlFoundry, this.HandleSuccessGetTechNodesResponse) : ''}
                {this.state.fetchRunType === true ? GetRunTypeList(this.props.PrStore, this.HandleSuccessGetRunTypeResponse) : ''}
                {this.state.fetchPackageType === true ? GetPackageTypeList(this.props.PrStore, this.HandleSuccessGetPackageTypeResponse) : ''}
                {this.state.postDesignData === true ? PostDesignData(this.props.PrStore, this.design_data, this.HandleSuccessPostDesignResponse) : ''}
            </div>
        )
    }
}
export const DesignSubmissionCtrl = PrConnect(designSubmissionCtrl, '');