import React, { Component } from 'react';
import $ from 'jquery';
export default class NewTableDesign extends Component {
    render() {
        return (
            <div>
                <table className="table table-sm table_sm_default">
                    <thead>
                        {/* <tr>
                            <th style={{ cursor: 'pointer' }} scope="col" onClick={() => this.props.SortData('accountNumber')}>Design Info No
                                                    {this.props.state.sort_by === 'accountNumber' ?
                                    <span className="fa fa-caret-down" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                    :
                                    this.props.state.sort_by_1 === 'accountNumber' ?
                                        <span className="fa fa-caret-up" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                        : ''}
                            </th>
                            <th style={{ cursor: 'pointer' }} scope="col" onClick={() => this.props.SortData('accountStatus')}>Foundry
                                                    {this.props.state.sort_by === 'accountStatus' ?
                                    <span className="fa fa-caret-down" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                    :
                                    this.props.state.sort_by_1 === 'accountStatus' ?
                                        <span className="fa fa-caret-up" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                        : ''}
                            </th>
                            <th style={{ cursor: 'pointer' }} scope="col" onClick={() => this.props.SortData('createdDate')}>Process Name
                                                    {this.props.state.sort_by === 'createdDate' ?
                                    <span className="fa fa-caret-down" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                    :
                                    this.props.state.sort_by_1 === 'createdDate' ?
                                        <span className="fa fa-caret-up" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                        : ''}
                            </th>
                            <th style={{ cursor: 'pointer' }} scope="col" onClick={() => this.props.SortData('foundry')}>Design Number
                                                    {this.props.state.sort_by === 'foundry' ?
                                    <span className="fa fa-caret-down" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                    :
                                    this.props.state.sort_by_1 === 'foundry' ?
                                        <span className="fa fa-caret-up" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                        : ''}
                            </th>
                        </tr> */}
                        <tr>
                            {JSON.stringify(this.props.state.headers) !== '{}' && this.props.state.headers !== '' ? this.props.state.headers.map((item, index) =>
                                <th style={{ cursor: 'pointer', textTransform: 'capitalize' }} scope="col" column={item} onClick={(e) => this.props.SortData(e, { item })}>{item}
                                {/* <th style={{ cursor: 'pointer', textTransform: 'capitalize' }} scope="col" onClick={() => this.props.SortData({ item })}>{item} */}
                                    {/* {this.props.state.sort_by === 'accountNumber' ?
                                        <span className="fa fa-caret-down" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                        :
                                        this.props.state.sort_by_1 === 'accountNumber' ?
                                            <span className="fa fa-caret-up" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                            : ''} */}
                                </th>
                            ) : ''}
                        </tr>
                    </thead>
                    {/* {JSON.stringify(this.props.TableDataList) !== '{}' ? this.props.TableDataList.map(item => */}
                    <tbody>
                        {JSON.stringify(this.props.TableDataList) !== '[]' && this.props.TableDataList !== '' ? this.props.TableDataList.map((item, index) =>

                            <tr style={{ cursor: 'pointer' }} key={index} onClick={() => this.props.HandleClickRow(item.designInfoNo)}>
                                <td>{item.designInfoNo}</td>
                                <td>{item.foundry}</td>
                                <td>{item.processName}</td>
                                {/* <td>{(item.uploadDate).slice(0, 2) + "/" + (item.uploadDate).slice(3, 5) + "/" + (item.uploadDate).slice(6, 10)}</td> */}
                                <td>{item.designNumber}</td>
                            </tr>
                        ) : ''}
                        {/* <tr> */}
                        {/* <td>{item.accountNumber}</td>
                                <td>{item.accountStatus}</td>
                                <td>{(item.createdDate).slice(5, 7) + "/" + (item.createdDate).slice(8, 10) + "/" + (item.createdDate).slice(0, 4)}</td>
                                <td>{item.customerId}</td>
                                <td>{item.accountType}</td> */}
                        {/* </tr> */}
                    </tbody>
                    {/* ) : ''} */}
                </table>

            </div>
        )
    }
}