import React, { Component } from 'react';
import $ from "jquery";

export default class ReorderStatusComponent extends Component {
    ClearMouse = () => {
        $('.inner_table').css('display', 'none');
    }

    render() {
        return (
            <div id="page-wrapper">
                <div id="page-inner" style={{background: "#f8f4e9"}}>
                    <div className="page_head">
                        <h1 className="page-header"> Reorder Status</h1>
                        <div className="dt-home">
                            <div className="row" style={{display: "none"}}>
                                <div className="col-lg-6 mx-auto">
                                    <div className="form-group">
                                        <div className="custom_select">
                                            <select name="customer" onChange={this.props.handleChange}>
                                                <option value=""> -select customer-</option>
                                                {this.props.state.customersList.map((cus, cui) =>
                                                    <option key={cui} value={cus.id}>{cus.name}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*<div className="row" style={{display: "none"}}>
                                {this.props.state.documentCategory.length > 0 && this.props.state.documentCategory.map((dn, mi) =>
                                    <div id={'mi-' + mi} key={'mi-' + mi} className="col-lg-3 col-md-4 col-sm-6">
                                        <div className="dt-control">
                                            <div className="dt-inner">
                                                <span className="dt-logo">
                                                     <img src="/control.png" alt={dn.heading} />
                                                    <img src={require('../../../assets/img/' + dn.heading + '.png')} alt={dn.heading}/>
                                                </span>
                                                <h3>{dn.heading}</h3>
                                            </div>
                                            <form>
                                                <div className="inner_right">
                                                    <div className="form-group">
                                                        <div className="custom_select">
                                                            <select name={dn.heading} data-item="document_category" onChange={this.props.handleChange}>
                                                                <option value="{}"> -select-</option>
                                                                {dn.downloadCenterListDtos.map((dnch, ci) =>
                                                                    <option key={'ci-' + ci} value={JSON.stringify(dnch)}>{dnch.name}</option>
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                )}
                            </div>*/}

                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="panel">
                                    <div className="panel-body" style={{padding: "0px"}}>
                                        <div className="table-responsive" style={{overflowX: "auto"}}>
                                            {this.props.state.documentLists.length > 0 && (
                                                <table className="table table-striped table-hover" id="dataTables-example">
                                                    <thead>
                                                    <tr>
                                                        {this.props.state.documentLists.map((ths, thi) =>
                                                            <th key={thi} column={thi} onClick={this.props.handleSort}>
                                                                {ths.fieldLabel}
                                                            </th>
                                                        )}
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {/*{this.props.state.fieldValues.map((item, index) =>
                                                            <tr style={{ textAlign: "center" }}>
                                                                <td >{item[1]["designNumber"]}</td>
                                                                <td >{item[2]["foundry"]}</td>
                                                                <td >{item[3]["technologyNode"]}</td>
                                                                <td >{item[4]["PONumber"]}</td>
                                                                <td >{item[5]["ECDate"]}</td>
                                                                        <td ><span style={{cursor:"pointer"}} onClick={() => this.props.CallOnMouseHover(item[0]["Id"])}
                                                                                   onMouseLeave={() => this.ClearMouse()}>{item[6]["pid"]}</span></td>
                                                                        <td >{item[7]["foundryRunId"]}</td>
                                                            </tr>
                                                    )}*/}

                                                    {this.props.state.designNumberStatusList.length > 0 ? this.props.state.designNumberStatusList.map((item, index) =>
                                                        item.productionIdInfoList.length > 0 ?
                                                            item.productionIdInfoList.map((productionIdItem, productionIdIndex) =>
                                                                productionIdItem != null && productionIdItem["productionId"].includes("#") && productionIdItem.productionIdStatusObject["codeValue"] != "Completed" && productionIdItem.productionIdStatusObject["codeValue"] != "Cancelled"?
                                                                    <tr>
                                                                        {/*<td style={{paddingLeft: "2%"}}>{productionIdItem.foundryFabSchedule && productionIdItem.foundryFabSchedule["runId"] ?
                                                                            <span style={{cursor: "pointer", color: "#990100", fontWeight: "bold"}}
                                                                                  onClick={() => this.props.CallOnMouseHover(item.designMilestones, productionIdItem, "designNumber")}
                                                                                  onMouseLeave={() => this.ClearMouse()}>{item["designNumber"]}</span> :
                                                                            <span>{item["designNumber"]}</span>}
                                                                        </td>*/}

                                                                        <td style={{paddingLeft: "2%"}}>{productionIdItem.foundryFabSchedule && productionIdItem.foundryFabSchedule["runId"]?
                                                                            item.designMilestones.length > 0?
                                                                                <span style={{cursor:"pointer", color: "#990100", fontWeight:"bold"}}
                                                                                      onClick={() => this.props.CallOnMouseHover(item.designMilestones, productionIdItem, "designNumber")}
                                                                                      onMouseLeave={() => this.ClearMouse()}>{item["designNumber"]}</span>:
                                                                                <span>{item["designNumber"]}</span>:
                                                                            <span>{item["designNumber"]}</span>}
                                                                        </td>
                                                                        <td>{item.technologyProcessObj != null ? item.technologyProcessObj.foundryObject["codeValue"] : ''}</td>
                                                                        <td style={{paddingLeft: "2%"}}>{item.technologyProcessObj != null && item.technologyProcessObj.technologyClassObject != null ? item.technologyProcessObj.technologyClassObject["codeValue"] : ''}</td>
                                                                        <td style={{paddingLeft: "2%"}} className="tb_clr">{productionIdItem?
                                                                            <span style={{cursor: "pointer", color: "#990100", fontWeight: "bold"}}
                                                                                  onClick={() => this.props.CallOnMouseHover(item.designMilestones, productionIdItem, "pid")}
                                                                                  onMouseLeave={() => this.ClearMouse()}>{productionIdItem != null ? productionIdItem["productionId"] : ''}</span> :
                                                                            <span>{productionIdItem != null ? productionIdItem["productionId"] : ''}</span>}
                                                                        </td>
                                                                        <td>{productionIdItem.productionIdStatusObject? productionIdItem.productionIdStatusObject["codeValue"]:''}</td>
                                                                        <td style={{paddingLeft: "2%"}}>{item.poDesignNumberList[0] != null ? item.poDesignNumberList[0].poRevision.purchaseOrder["purchaseOrderNumber"] : ''}</td>
                                                                        {/*{item["completionDate"] ?
                                                                            <td style={{paddingLeft: "2%"}}>{(item["completionDate"]).slice(5, 7) + "/" + (item["completionDate"]).slice(8, 10) + "/" + (item["completionDate"]).slice(0, 4)}</td>
                                                                            : <td style={{paddingLeft: "2%"}}></td>}*/}

                                                                        {/*<td >{item[7]["foundryRunId"]}</td>*/}
                                                                    </tr> : ''
                                                            ) :
                                                            <tr>
                                                                <td style={{paddingLeft: "2%"}}><span>{item["designNumber"]}</span></td>
                                                                <td>{item.technologyProcessObj != null ? item.technologyProcessObj.foundryObject["codeValue"] : ''}</td>
                                                                <td style={{paddingLeft: "2%"}}>{item.technologyProcessObj != null && item.technologyProcessObj.technologyClassObject != null ? item.technologyProcessObj.technologyClassObject["codeValue"] : ''}</td>
                                                                <td style={{paddingLeft: "3%"}}></td>
                                                                <td>{item.productionIdInfoList.productionIdStatusObject? item.productionIdInfoList.productionIdStatusObject["codeValue"]:''}</td>
                                                                <td style={{paddingLeft: "2%"}}>{item.poDesignNumberList[0] != null ? item.poDesignNumberList[0].poRevision.purchaseOrder["purchaseOrderNumber"] : ''}</td>
                                                                {/*{item["completionDate"] ?
                                                                    <td style={{paddingLeft: "3%"}}>{(item["completionDate"]).slice(5, 7) + "/" + (item["completionDate"]).slice(8, 10) + "/" + (item["completionDate"]).slice(0, 4)}</td>
                                                                    : <td style={{paddingLeft: "3%"}}></td>}*/}

                                                                {/*<td >{item[7]["foundryRunId"]}</td>*/}
                                                            </tr>
                                                    ) : <span></span>}

                                                    </tbody>
                                                </table>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}