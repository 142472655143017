import { PrAjax } from '../library/ajax/pr-ajax';

export function getInitData(store, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('dedicatedQuote/init').AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function submitDedicatedQuoteData(store, dedicatedQuoteData,successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Post('dedicatedQuote/save').ExtraData(dedicatedQuoteData).AttachLoader(loader).OnSuccess(successFunc).Do();
}


