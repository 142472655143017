import React, { Component } from 'react';
import $ from 'jquery';
export default class NewTableDesign extends Component {
    render() {
        return (
            <div>
                <table className="table table-sm table_sm_default">
                    <thead>
                        <tr>
                            <th style={{ cursor: 'pointer' }} scope="col" onClick={() => this.props.SortData('accountNumber')}>Account Number
                                                    {this.props.state.sort_by === 'accountNumber' ?
                                    <span className="fa fa-caret-down" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                    :
                                    this.props.state.sort_by_1 === 'accountNumber' ?
                                        <span className="fa fa-caret-up" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                        : ''}
                            </th>
                            <th style={{ cursor: 'pointer' }} scope="col" onClick={() => this.props.SortData('accountStatus')}>Status
                                                    {this.props.state.sort_by === 'accountStatus' ?
                                    <span className="fa fa-caret-down" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                    :
                                    this.props.state.sort_by_1 === 'accountStatus' ?
                                        <span className="fa fa-caret-up" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                        : ''}
                            </th>
                            <th style={{ cursor: 'pointer' }} scope="col" onClick={() => this.props.SortData('createdDate')}>Date
                                                    {this.props.state.sort_by === 'createdDate' ?
                                    <span className="fa fa-caret-down" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                    :
                                    this.props.state.sort_by_1 === 'createdDate' ?
                                        <span className="fa fa-caret-up" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                        : ''}
                            </th>
                            <th style={{ cursor: 'pointer' }} scope="col" onClick={() => this.props.SortData('foundry')}>Foundry
                                                    {this.props.state.sort_by === 'foundry' ?
                                    <span className="fa fa-caret-down" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                    :
                                    this.props.state.sort_by_1 === 'foundry' ?
                                        <span className="fa fa-caret-up" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                        : ''}
                            </th>
                            <th style={{ cursor: 'pointer' }} scope="col" onClick={() => this.props.SortData('accountType')}>Account Type
                                                    {this.props.state.sort_by === 'accountType' ?
                                    <span className="fa fa-caret-down" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                    :
                                    this.props.state.sort_by_1 === 'accountType' ?
                                        <span className="fa fa-caret-up" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                        : ''}
                            </th>
                        </tr>
                    </thead>
                    {JSON.stringify(this.props.TableDataList) !== '{}' ? this.props.TableDataList.map(item =>
                        <tbody>
                            <tr>
                                <td>{item.accountNumber}</td>
                                <td>{item.accountStatus}</td>
                                {/* <td>{item.uploadDate}</td> */}
                                <td>{(item.createdDate).slice(5, 7) + "/" + (item.createdDate).slice(8, 10) + "/" + (item.createdDate).slice(0, 4)}</td>
                                <td>{item.customerId}</td>
                                <td>{item.accountType}</td>
                            </tr>
                        </tbody>
                    ) : ''}
                </table>

            </div>
        )
    }
}