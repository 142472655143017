import React, { Component } from 'react';
import $ from 'jquery';
export default class TableDesign extends Component {
    ClearMouse = () => {
        $('.inner_table').css('display', 'none');
    }
    render() {
        return (
            <div id="page-wrapper">
                <div id="page-inner1" style={{ background: '#fff' }}>
                    <div className="page_head col-lg-12">
                        {/* <div className="page_head col-lg-12"> */}
                        {JSON.stringify(this.props.state.techProcessFoundriesData) !== '{}' && this.props.state.techProcessFoundriesData.length > 0 ?
                            <div class="row">
                                <div class="col-lg-12 col-md-12 mx-auto">
                                    <table className="table table-sm table_sm_default">
                                        <thead>
                                            <tr>
                                                <th style={{ cursor: 'pointer' }} scope="col" onClick={() => this.props.SortData('foundry')}>Foundry
                                                    {this.props.state.sort_by === 'foundry' ?
                                                        <span className="fa fa-caret-down" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                                        :
                                                        this.props.state.sort_by_1 === 'foundry' ?
                                                            <span className="fa fa-caret-up" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                                            : ''}
                                                </th>
                                                <th style={{ cursor: 'pointer' }} scope="col" onClick={() => this.props.SortData('technode')}>Technology Node
                                                    {this.props.state.sort_by === 'technode' ?
                                                        <span className="fa fa-caret-down" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                                        :
                                                        this.props.state.sort_by_1 === 'technode' ?
                                                            <span className="fa fa-caret-up" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                                            : ''}
                                                </th>
                                                <th style={{ cursor: 'pointer' }} scope="col" onClick={() => this.props.SortData('status')}>Status
                                                    {this.props.state.sort_by === 'status' ?
                                                        <span className="fa fa-caret-down" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                                        :
                                                        this.props.state.sort_by_1 === 'status' ?
                                                            <span className="fa fa-caret-up" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                                            : ''}
                                                </th>
                                                <th style={{ cursor: 'pointer' }} scope="col" onClick={() => this.props.SortData('techclass')}>Technology Class
                                                    {this.props.state.sort_by === 'techclass' ?
                                                        <span className="fa fa-caret-down" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                                        :
                                                        this.props.state.sort_by_1 === 'techclass' ?
                                                            <span className="fa fa-caret-up" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                                            : ''}
                                                </th>
                                            </tr>
                                        </thead>
                                        {this.props.state.techProcessFoundriesData.map(item =>
                                            <tbody>
                                                <tr>
                                                    <td>{item.foundry}</td>
                                                    <td>{item.processName}</td>
                                                    <td>{item.status}</td>
                                                    <td>{item.techClass}</td>
                                                </tr>
                                            </tbody>
                                        )}
                                    </table>
                                </div>
                            </div>
                            : ''}
                    </div>
                </div>
            </div>
        )
    }
}