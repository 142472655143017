import React, { Component } from 'react';
import tncfile from '../../../assets/docs/MOSIS Terms and Conditions 01 17 19  Rev 0.pdf';

export default class DownloadCenterComponent extends Component {
    render() {
        return (
            <div id="page-wrapper">
                <div id="page-inner" style={{ background: "#f8f4e9" }}>
                    <div className="page_head">
                        <h1 className="page-header"> Download Documents</h1>
                        <div className="dt-home">
                            <div className="row" style={{justifyContent: "center"}}>
                                {this.props.state.documentCategory.length > 0 && this.props.state.documentCategory.map((dn, mi) =>
                                        <div id={'mi-' + mi} key={'mi-' + mi} className="col-lg-5 col-md-4 col-sm-6">
                                        <div className="dt-control">
                                            <div className="dt-inner">
                                                <span className="dt-logo">
                                                    {/* <img src="/control.png" alt={dn.heading} /> */}
                                                    <img src={require('../../../assets/img/' + dn.heading + '.png')} alt={dn.heading} />
                                                </span>
                                                <h3>{dn.heading}</h3>
                                            </div>
                                            <form>
                                                <div className="inner_right">
                                                    {dn.heading != "Export Control" ?
                                                        <div className="form-group">
                                                            <div className="custom_select">

                                                                <select name={dn.heading} data-item="document_category" onChange={this.props.handleChange}>
                                                                    <option value="{}"> -select-</option>
                                                                    {dn.downloadCenterListDtos.map((dnch, ci) =>
                                                                        <option key={'ci-' + ci} value={JSON.stringify(dnch)}>{dnch.name}</option>
                                                                    )}
                                                                    {dn.heading == "Customer Agreement"?<option value="tnc">Terms & Conditions</option>:''}
                                                                </select>
                                                                        
                                                            </div>
                                                        </div>:
                                                        <div className="form-group" style={{backgroundColor: "white", alignContent: "center", alignText: "center", textAlign: "center"}}>
                                                            <button className="link" type="button" style={{background: "none", border:"none", fontWeight: "bold"}}
                                                                    data-item="document_category"
                                                                    value={dn.heading}
                                                                    onClick={this.props.viewButtonClicked}>View</button>
                                                        </div>

                                                        }
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    )}
                            </div>

                        </div>
                        <div className="row" style={{display: this.props.state.showDocumentTable?"block":"none"}}>

                            <div className="col-md-12">
                                <div className="panel">
                                    <div className="panel-body" style={{ padding: "0px" }}>
                                        <div className="table-responsive" style={{ overflowX: "auto" }}>
                                            {this.props.state.documentLists.length > 0 && (
                                                <table className="table table-striped table-hover" id="dataTables-example">
                                                    <thead>
                                                        <tr style={{ textAlign: "left" }}>
                                                            {this.props.state.teamplateHeadersList.map((ths, thi) =>
                                                                <th key={thi} column={thi} id={ths.templateDetailId} onClick={this.props.handleSort}>
                                                                    {ths.fieldLabel}
                                                                </th>
                                                            )}
                                                            <th class="downloadLabel"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                    {this.props.state.documentLists.map((trs, tri) =>
                                                            <tr key={tri} style={{ textAlign: "left" }}>
                                                                {trs != null && this.props.state.teamplateHeadersList.map((tds, tdi) =>
                                                                    trs[tds["templateDetailId"]]? <td style={{ paddingLeft: "1%" }} key={tds["templateDetailId"]}>{trs[tds["templateDetailId"]].fieldValue}</td>:
                                                                        <td style={{ paddingLeft: "1%" }} key={tds["templateDetailId"]}></td>
                                                                )}
                                                                <td>
                                                                    {/*<span className="view"><a href="#">View </a></span>*/}
                                                                    <span><a  style={{color: "#990100",
                                                                        fontWeight: "600",
                                                                        position: "relative",
                                                                        padding: "0px 10px"}}
                                                                        //href={this.props.state.apiUrl + '/customerFileDownload/downloadFile/' + trs["documentId"]}
                                                                        //      target="_self"
                                                                    onClick={(e) => this.props.downloadFile(this.props.state.apiUrl + '/customerFileDownload/downloadFile/' + trs["documentId"],
                                                                        trs["documentId"])}
                                                                    >Download </a></span>

                                                                </td>

                                                                {/*<a id="downloadButton" style={{display: "none"}}></a>*/}
                                                            </tr>
                                                        )}


                                                    </tbody>
                                                </table>
                                            )}
                                            {this.props.state.documentLists.length == 0?<div style={{paddingTop: "3%",
                                                color: "red",
                                                fontStyle: "italic",
                                                textAlign: "center",
                                                fontWeight: "bold"}}>No {this.props.state.errorValue} document available</div>:''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        )
    }
}