import React from 'react';
import { PrHttp_class } from './pr-http-class';
import { PrLoader } from "../loader/pr-loader";

export class PrHttp extends React.Component {
    constructor(props) {

        super(props);

        //  JSON.stringify(props);
        this.state = {
            //isLoader: false
        };
        //        this.wetherPrResponse = true;
        if (this.props.Loader.isLoader) {
            this.isLoader = true;
        }
        else {
            this.isLoader = false;
        }
        if (this.props.Loader.size) {
            this.size = this.props.Loader.size;
        } else {
            this.size = '';
        }

        if (this.props.As)
            this.As = this.props.As;
        else
            this.As = "json";

        if (this.props.ResponseFormat)
            this.RespFormat = this.props.ResponseFormat;

        if (JSON.stringify(this.props.Headers) !== '{}') {
            this.headers = this.props.Headers
            //alert(JSON.stringify(this.props.Headers))
        }
        else {
            this.headers = { 'X-CallBack-Type': 'AJAXGET' };
        }


        switch (this.props.method) {
            case "get":
                this.headers['X-CallBack-Type'] = 'AJAXGET';
                break;
            case "put":
                this.headers['X-CallBack-Type'] = 'AJAXPUT';
                break;
            case "post":
                this.headers['X-CallBack-Type'] = 'AJAXPOST';
                break;
            case "delete":
                this.headers['X-CallBack-Type'] = 'AJAXDELETE';
                break;
        }

        // this.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        this.headers['Content-Type'] = 'application/json';


        if (localStorage.getItem('okta-token-storage') != null && localStorage.getItem('okta-token-storage') != "{}")
        {
            console.log(localStorage.getItem('okta-token-storage'))
            console.log(localStorage.getItem('okta-token-storage') != "{}")
            let accessToken = JSON.parse(localStorage.getItem('okta-token-storage')).accessToken.accessToken;
            this.headers['Authorization'] = 'Bearer ' + accessToken;
        }

        // alert(JSON.stringify(this.headers, null, 4))

        this.config = { 'headers': this.headers };
    }

    componentDidMount() {
        this.Do();
    }

    TransferBackResult = (res) => {

        this.props.GetResult(res);

    }

    Do = () => {
        switch (this.props.method) {
            case "get": {

                // alert(JSON.stringify(this.headers));
                PrHttp_class.Get(this.props.url, this.props.PrStore, this.config).then(res => {
                    // alert("In pr-http component in " + JSON.stringify(res.data));
                    //  this.setState({isLoader:false});
                    if (this.As != "html") {
                        //alert('hi...');
                        res = this.JsonConvert(res);
                        //alert(res);
                        if (res == "error") {
                            this.isLoader = false;
                            this.forceUpdate();
                            return Promise.reject("Json Parse Error");
                        }
                    }

                    this.isLoader = false;

                    this.TransferBackResult(res);

                    // return res;
                },
                    error => {
                        this.isLoader = false;
                        this.props.PrStore.BroadCast();
                        this.forceUpdate()
                    });

            }
                break;

            case "post": {
                PrHttp_class.Post(this.props.url, this.props.body, this.props.PrStore, this.config).then(res => {
                    // alert("In pr-http component in " + JSON.stringify(res));
                    if (this.As != "html") {
                        //alert('hi...');
                        res = this.JsonConvert(res);
                        //alert(res);
                        if (res == "error") {
                            this.isLoader = false;
                            this.forceUpdate();
                            return Promise.reject("Json Parse Error");
                        }
                    }
                    //  this.setState({isLoader:false});
                    this.isLoader = false;
                    this.TransferBackResult(res);

                    // return res;
                },
                    error => {
                        this.isLoader = false;
                        this.props.PrStore.BroadCast();
                        this.forceUpdate()
                    });
            }
                break;

            case "put": {

                // alert(JSON.stringify(this.props.body, null, 4));

                PrHttp_class.Put(this.props.url, this.props.body, this.props.PrStore, this.config).then(res => {
                    // alert("In pr-http component in " + JSON.stringify(res));
                    //  this.setState({isLoader:false});
                    if (this.As != "html") {
                        //alert('hi...');
                        res = this.JsonConvert(res);
                        //alert(res);
                        if (res == "error") {
                            this.isLoader = false;
                            this.forceUpdate();
                            return Promise.reject("Json Parse Error");
                        }
                    }
                    this.isLoader = false;
                    this.TransferBackResult(res);

                    // return res;
                },
                    error => {
                        this.isLoader = false;
                        this.props.PrStore.BroadCast();
                        this.forceUpdate()
                    });

            }
                break;

            case "delete": {
                PrHttp_class.Delete(this.props.url, this.props.PrStore, this.config).then(res => {
                    //alert( "In pr-http component in " + JSON.stringify(res));
                    if (this.As != "html") {
                        //alert('hi...');
                        res = this.JsonConvert(res);
                        //alert(res);
                        if (res == "error") {
                            this.isLoader = false;
                            this.forceUpdate();
                            return Promise.reject("Json Parse Error");
                        }
                    }
                    //  this.setState({isLoader:false});
                    this.isLoader = false;
                    this.TransferBackResult(res);

                    // return res;
                },
                    error => {
                        this.isLoader = false;
                        this.props.PrStore.BroadCast();
                        this.forceUpdate();
                    });


            }
                break;
        }
    }

    CheckForPrError = (res) => {
        //alert('in check pr error '+JSON.stringify(res, null, 4));
        if (this.props.wetherPrResponse) {
            //alert('true');
            if (res.data.PrResponse) {
                if ((res.data.PrResponse.ServerStatus) && (res.data.PrResponse.ServerStatus.value == "SUCCESS")) {
                    return res;
                }
                else {
                    let msg = 'Oops Server Error. Please try later';
                    if (res.data.PrResponse.FailureReason !== "" || res.data.PrResponse.FailureReason !== undefined)
                        msg = res.data.PrResponse.FailureReason;

                    let myErr = {
                        errorCode: '',
                        errorType: 'Server',
                        errorMessage: 'Server Application Error',
                        prettyMessage: msg
                    };

                    if (this.props.PrStore.GetData('app').mode == "dev")
                        myErr.errorMessage = res.data;
                    else
                        myErr.errorMessage = myErr.prettyMessage;


                    this.props.PrStore.SetData('error', myErr);
                    this.props.PrStore.BroadCast();
                    this.isLoader = false;
                    return false;
                    // return "error";
                    //this.forceUpdate();

                }
            }
            else {

                //another kind of fatal error uncaught by server
                // alert('error');
                let msg = 'Oops Server Error. Please try later';
                if (res.data.PrResponse.FailureReason !== "" || res.data.PrResponse.FailureReason !== undefined)
                    msg = res.data.PrResponse.FailureReason;

                let myErr = {
                    errorCode: '',
                    errorType: 'Server',
                    errorMessage: 'Server Application Error',
                    prettyMessage: msg
                };
                if (this.props.PrStore.GetData('app').mode == "dev")
                    myErr.errorMessage = res.data;
                else
                    myErr.errorMessage = myErr.prettyMessage;

                this.props.PrStore.SetData('error', myErr);
                this.props.PrStore.BroadCast();
                this.isLoader = false;
                return false;
                // return "error";



            }
        }
        else {
            //let go
            alert('getting bypassed');
        }



        return res;

    }

    JsonConvert = (res) => {
        //res = res.data.replace(/<script.*?>.*?<\/script>/igm, '');
        try {
            if (this.props.wetherPrResponse)   // if format is prs
                res = this.CheckForPrError(res);
            else {
                //errors developer has to do himself
            }
        }
        catch (ex) {
            //alert('in catch' +ex);
            this.HandleJsonParseError(res);
            return "error";
        }
        return res;
    }

    HandleJsonParseError = (res) => {
        let msg = 'Oops Server Error. Please try later';
        if (res.data.PrResponse.FailureReason !== "" || res.data.PrResponse.FailureReason !== undefined)
            msg = res.data.PrResponse.FailureReason;

        let myErr = {
            errorCode: '',
            errorType: 'JSONPARSE',
            errorMessage: 'PARSE Error',
            prettyMessage: msg,
            response: res
        };

        if (this.props.PrStore.GetData('app').mode == "dev")
            myErr.errorMessage = res.data;
        else
            myErr.errorMessage = myErr.prettyMessage;

        this.props.PrStore.SetData('error', myErr);
        this.props.PrStore.BroadCast();
        return false;


    }

    render() {
        let loader = '';
        if (this.isLoader) {
            loader = <PrLoader size={this.size} />;
            /*if (this.props.Loader.containerId) {
                let containerId = document.getElementById(this.props.Loader.containerId);
            } else {
                this.isLoader = false;
            }*/
        }
        else {

            loader = "";
        }
        return (
            <div>
                {loader}
            </div>

            /*<div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                zIndex: 2,
                backgroundColor: '#ccccc',
                opacity: 0.6

            }}>
                {loader}
            </div>*/
        );
    }
}