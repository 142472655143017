export const prstore = {
  //  api: { baseurl: 'https://test4.mosis.com/rest' },//server
  //    api: { baseurl: 'https://test5.mosis.com/rest' },//server
    api: { baseurl: '../rest' },//server
    //api: { baseurl: 'http://localhost:8080' },//server
  //  api: { baseurl: 'https://mosis-app-lb-817999316.us-gov-west-1.elb.amazonaws.com/rest' },//server
 //   api: { baseurl: 'https://test4.mosis.com/rest' },//server



    // api: { baseurl: 'http://localhost:8084' },//server
    // api: { baseurl: 'https://mosis-aws.proxy.beeceptor.com' },//ser
   // api: { baseurl: 'http://mosis-aws.mosis.com:8080' },//main server
    user: { name: '', password: '', displayName: '', email: '', role: '' },
    app: { mode: 'prod', token: '', startTime: '', lastLoggedTime: '' },
    error: { errorCode: '', errorType: '', errorMessage: '', prettyMessage: '' },
    ajax: { isFetching: false },
    wetherAuthenticated: false,
    countrieslist: {},
    accounttypes: {},
    allowedRoutes: {},
    textUpload: '',
    pressreleasedata: {},
    generalPurposeSearchData: '',
    name: 'Guest', age: 1000000
};