import React, { Component } from 'react';
// import { TableDesignCtrlNew } from '../../newtable';
import { TableDesignCtrlNew } from '../newtable';

export default class DesignFabricationComponent extends Component {
    constructor() {
        super();
        this.state = {
            displayMenu: false,
        };
        //  this.showDropdownMenu = this.showDropdownMenu.bind(this);
        //  this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    };

    //    showDropdownMenu(event) {
    //        event.preventDefault();
    //        this.setState({ displayMenu: true }, () => {
    //        document.addEventListener('click', this.hideDropdownMenu);
    //        });
    //      }

    //      hideDropdownMenu() {
    //        this.setState({ displayMenu: false }, () => {
    //          document.removeEventListener('click', this.hideDropdownMenu);
    //        });

    //      }

    componentWillMount = () => {

    }

    render() {
        return (
            <div>
                <div id="page-wrapper">
                    <div id="page-inner" style={{ background: '#fff' }}>
                        <div className="row">
                            <div className="page_head col-lg-10 col-md-11 mx-auto col-xl-8 ">
                                <div className="page_head">
                                    <div>
                                        <h1 className="page-header">Design Fabrication Status</h1>
                                        <div className="account">
                                            <div className="accoutn_form">
                                                <form action method>
                                                    <div className="dashboard_form">
                                                        <div className="inner-dashoard_form">
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <div className="brd_section">
                                                                        <div className="row">
                                                                            <div class="col-lg-4 col-md-4 col-sm-6">
                                                                                <div className="inner_left">
                                                                                    <label>Design Status</label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-8 col-md-8 col-sm-6">
                                                                                <div className="inner_right">
                                                                                    <div className="form-group new_pos">
                                                                                        <div className="custom_select">
                                                                                            <select id="codeValueCurrent" name="codeValueCurrent" value={this.props.state.codeValueCurrent} onChange={this.props.HandleChange}>
                                                                                                <option selected hidden>Select</option>
                                                                                                {(JSON.stringify(this.props.codeValues) !== '{}' && this.props.codeValues.length > 0) ? this.props.codeValues.map(item =>
                                                                                                    <option key={item.codeId} value={item.codeValue}>{item.codeName}</option>
                                                                                                ) : ''}
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <div className="brd_section">
                                                                        <div className="row">
                                                                            <div class="col-lg-4 col-md-4 col-sm-6">
                                                                                <div className="custom_dropdown" >
                                                                                    {/* <div className="button" onClick={this.showDropdownMenu}> Design Number</div> */}
                                                                                    <div className="inner_left">
                                                                                        <label onClick={this.showDropdownMenu}> Design Number</label>
                                                                                    </div>

                                                                                    {this.state.displayMenu ? (
                                                                                        <ul>
                                                                                            <li><a className="active" href="#Create Page">Production ID</a></li>
                                                                                            <li><a href="#Manage Pages">Design Number</a></li>
                                                                                        </ul>) : (null)
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-8 col-md-8 col-sm-6">
                                                                                <div className="inner_right">
                                                                                    <div className="form-group new_pos">
                                                                                        <input type="text" disabled={this.props.state.DesignNumberDisabled} id='DesignNumber' className="form-control" name="DesignNumber" value={this.props.state.DesignNumber} onChange={this.props.HandleChange} onFocus={this.props.HandleFocus} />
                                                                                        <div className="GeneralPurpose_Popup_table" style={{ top: "30px" }}>
                                                                                            {/* {(this.props.state.DesignNumber !== "") && <TableDesignCtrlNew data={this.props.state.DesignNumber} />} */}
                                                                                            {/* <TableDesignCtrlNew data={this.props.state.DesignNumber} /> */}
                                                                                            {this.props.state.showNewTable ? <TableDesignCtrlNew HandleTableClick={this.props.HandleTableClick} data={this.props.DesignNumbersVal} /> : ''}
                                                                                        </div>

                                                                                    </div>

                                                                                </div>
                                                                                {this.props.state.showDesignNumberNotAvailableMessage?
                                                                                    <div className="InputFieldError">
                                                                                        <label style={{fontStyle: "italic", fontSize: "10px", position: "absolute", bottom: "-10px", color: "red", left: "2%"}}>* Design Number Not Available</label>
                                                                                    </div>:''
                                                                                    /*<div className="inner_left">
                                                                                        <label style={{color:"red", fontStyle:"Italic"}}> Design Number Not Available</label>
                                                                                    </div>:''*/
                                                                                }

                                                                            </div>

                                                                            {/* {this.props.state.showNewTable ? <TableDesignCtrlNew data={this.props.PrStore.GetData('dataFetchedFromCodeValue')} /> : ''} */}
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                        {(JSON.stringify(this.props.FinalData) !== '{}' && this.props.FinalData.length > 0) ?
                                            <div className="account">
                                                <div className="Mosis_h3_header">
                                                    <div className="row">
                                                        <div className="col-5">
                                                            <h4>Milestone (Due Date)</h4>
                                                        </div>
                                                        <div className="col-7">
                                                            <div className="row">
                                                                <div className="col-4">
                                                                    <h4>Due to MOSIS</h4>
                                                                </div>
                                                                <div className="col-4">
                                                                    <h4>Due to Foundry</h4>
                                                                </div>
                                                                <div className="col-4">
                                                                    <h4>Actuals</h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accoutn_form">
                                                    <form action method>
                                                        <div className="dashboard_form">
                                                            <div className="inner-dashoard_form">
                                                                <div className="row">

                                                                    {(JSON.stringify(this.props.FinalData) !== '{}' && this.props.FinalData.length > 0) ? this.props.FinalData.map(item =>
                                                                        <div className="col-lg-12">
                                                                            <div className="brd_section">
                                                                                <div className="row">
                                                                                    <div class="col-lg-4 col-md-4 col-sm-6">
                                                                                        <div className="inner_left">
                                                                                            <label>{item.milestone.milestoneName}</label>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-8 col-md-8 col-sm-6">
                                                                                        <div className="row">
                                                                                            <div className="col-4">
                                                                                                <div className="inner_right">
                                                                                                    <div className="form-group">
                                                                                                        <input type="text" value={item.mosisDateParsed} className="form-control" disabled />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-4">
                                                                                                <div className="inner_right">
                                                                                                    <div className="form-group">
                                                                                                        <input type="text" className="form-control" value={item.foundryDateParsed} disabled />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-4">
                                                                                                <div className="inner_right">
                                                                                                    <div className="form-group">
                                                                                                        <input type="text" className="form-control" value={item.actualDateParsed} disabled />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ) : ''}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}