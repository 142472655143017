/* global __DEV__ */
import React, { Component } from 'react';
import {DashboardHeaderComponent} from '../common/DashboardHeader';
import FooterComponent from '../common/Footer';
import {prstore} from "../library/stores/pr-store";
import menuItems from "../common/DashboardHeader/menuItems";
import {GetAllowedRoutes} from "../services/users";
import $ from "jquery";
import {PdfViewerCtrl} from "../components/PressRelease/ReadMore/PdfViewer";


export default class DashboardLayout extends Component {
    constructor(props) {
        console.log("Inside DashboardLayout: "+prstore.api.baseurl)
        console.log("process.env.REACT_APP_STAGE: "+process.env.REACT_APP_STAGE)

        /*if(prstore.api.baseurl.includes("http://localhost")){
            console = console || {};
            console.log = function(){};
        }*/

        super(props);
        this.state = {
            open: false,
            currentModalAction: {},
            fetchRoutes: false,
            menuItemsMapping: {},
            itemAllowed: false
        };
        this.comp = '';
        this.comp_size = 'md';
        this.routeArray = ['/user/new', '/users', '/leads'];
        this.prev_url = 'init';
        this.allowedRoutes = [];

        /*console.log("this.allowedRoutes: "+JSON.stringify(this.props.PrStore.GetData('allowedRoutes')));
        console.log("itemAllowed: "+JSON.stringify(this.props.componentprops));*/
    }


    UNSAFE_componentWillMount = () => {

        menuItems["data"].sort(this.GetSortOrder("name")); //Pass the attribute to be sorted on
        for (var item in menuItems["data"]) {
            if(menuItems.data[item]["children"]){
                let children = menuItems.data[item]["children"].sort(this.GetSortOrder("name"));
            }
        }
        console.log("menuItems: "+JSON.stringify(menuItems["data"]))

        let that = this
        menuItems["data"].map(function (item, index) {
            if(item["url"] != null){
                that.state.menuItemsMapping[item["url"]] = item["name"];
            }else{
                if(item["children"] != null){
                    item["children"].map(function (itemChild, indexChild) {
                        that.state.menuItemsMapping[itemChild["url"]] = itemChild["name"];
                    });
                }
            }

        });
        console.log("that.state.menuItemsMapping: "+JSON.stringify(that.state.menuItemsMapping));




            this.setState({
                fetchRoutes: true
            })

        this.setState({
            menuItems: menuItems,
            menuItemsMapping: this.state.menuItemsMapping
        })


    }

    GetSortOrder = (prop) => {
        return function(a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        }
    }

    HandleSuccessGetRoutesResponse = (res) => {
        //this.props.PrStore.SetData('allowedRoutes', res.data);
        this.allowedRoutes = res.data;
        console.log("this.allowedRoutes: "+JSON.stringify(this.allowedRoutes));
        this.checkAllowedUrl();

        this.setState({
            fetchRoutes: false
        })
    }

    checkAllowedUrl = () => {
        require('dotenv').config();

        var actualUrl = "https://www.themosisservice.com/dashboard/export-control-2"
        var testUrl = "themosisservice.com/";
        console.log("testUrl arr: "+JSON.stringify(actualUrl.split(testUrl)))

        var url = window.location.href;
        //url = url.split("http://localhost:4000/"); // Get the hostname
        url = url.split(process.env.REACT_APP_BASE_URLS); // Get the hostname
        console.log("url: "+JSON.stringify(url));

        console.log("url arr: "+JSON.stringify(url[1]));
        console.log("mapping result: "+this.state.menuItemsMapping[url[1]]);
        console.log("mapping result list: "+JSON.stringify(this.state.menuItemsMapping));
        console.log("allowedRoutes: "+JSON.stringify(this.allowedRoutes));

        let itemAllowed = false
        let that = this
        this.allowedRoutes.map(function (item, index) {
            if(item == that.state.menuItemsMapping[url[1]]){
                itemAllowed = true;
            }
        });

        console.log("this.props.history: "+JSON.stringify(this.props.history))
        if(itemAllowed == false){
            let dashboardUrlArr = process.env.REACT_APP_DASHBOARD_URLS.split(",");
            dashboardUrlArr.map(function (item, index) {
                console.log("ITEM: "+item)
                if(item == url[1]){
                    itemAllowed = true;
                }
            })

            if(itemAllowed == false){
                console.log("Inside itemAllowed == false")
                this.props.history.push("/access-denied");
            }
        }

        this.state.itemAllowed = itemAllowed;

        /*var tempUrl = window.location.href;
        tempUrl = tempUrl.split("/")[2]; // Get the hostname
        if(tempUrl == 'localhost:4000' || tempUrl == 'devaws.mosis.com' || tempUrl == 'themosisservice.com' || tempUrl == 'mosis.com'){
        //if(url == 'themosisservice.com' || url == 'mosis.com'){
            console.log("inside matched");
            that.props.history.push("/holiday-notice");
        }*/
    }

    render() {
        return (

            <div>
                {this.state.itemAllowed ?
                    <div id="wrapper1">
                        {/* <div className="cbp-spmenu-push"> */}
                        {/* <div className="main-content"> */}
                        {/* <SideBar {...this.props} /> */}
                        <DashboardHeaderComponent {...this.props} />

                        {/* <div id="wrapper"> */}
                        <this.props.children {...this.props} />

                        {/*{access_route ?*/}
                        {/*<this.props.children OpenModal={this.handleClickOpen} {...this.props} />*/}
                        {/*: 'You are Not Authorized to access this route.'*/}
                        {/*}*/}
                        {/* </div> */}
                        <FooterComponent />
                    </div>
                    : ''
                }
                {this.state.fetchRoutes === true ? GetAllowedRoutes(this.props.PrStore, this.HandleSuccessGetRoutesResponse) : ''}
            </div>


            // <div>
            //   {/* <div className="container" style={{ background: "url('')  no-repeat fixed center center / cover" }}> */}
            //   <div className="container-fluid">
            //     <div className="row">
            //       <div className="row"><Header {...this.props} /></div>
            //       <div className="row">
            //         <div className="pull-left"><SideBar/></div>
            //         <div className="pull-left"></div>
            //       {this.props.componentprops.children}</div>
            //       {/* <div className="col-sm-2"><SideBar {...this.props}/></div>
            //         <div className="col-sm-10">
            //           <div className="row"><Header {...this.props}/></div>
            //           <div className="row">{this.props.componentprops.children}</div>
            //         </div> */}
            //     </div>
            //   </div>
            //   {/* <div  style={{marginTop: -90 }} className="divBackground"> */}
            //   {/* </div> */}
            //   <div>
            //     <Footer />
            //   </div>
            //   {/* <HomePageLayout contentAreaSearch={<Search {...this.props} SearchTypeValue={this.state.ddlSearchType} SetSearchType={this.GetSearchType} GetPropertySearchData={this.GetProperty} />} contentArea={<div><Ads {...this.props} /><Insight /></div>}  {...this.props} /> */}
            // </div>
        );
    }
}