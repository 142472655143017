import React, { Component } from 'react';
import AccManagementComponent from './accountmanagement';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';

import { PrConnect } from '../../../library/pr-miniredux';
import PrValidate from '../../../library/pr-controls/pr-validation-full';

import DashboardLayout from '../../../layouts/dashboardlayout';

import { PostDesignData, GetInvoicingMethod, GetPriority, GetCountryList, GetAccountTypes, getCustomerData, GetContactTypeList, GetAddressTypeList } from '../../../services/users';
import $ from 'jquery';
import DashboardCtrl from "../Home";
import {DashboardHeaderComponent} from "../../../common/DashboardHeader";

export class accManagementCtrl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            txtOrgName: '',
            txtWebUrl: '',
            txtCustAccNo: '',
            txtFederalTaxIdNo: '',
            ddlCustAccType: '',
            ddlPriprity: '',
            txtAccStartDate: '',

            txtFirstName: '',
            txtLastName: '',
            txtTitle: '',
            txtEmail: '',
            txtTelNo: '',

            txtOfficialFirstName: '',
            txtOfficialLastName: '',
            txtOfficialTitle: '',
            txtOfficialEmail: '',
            txtOfficialTelNo: '',

            txtOfficialCertFirstName: '',
            txtOfficialCertLastName: '',
            txtOfficialCertTitle: '',
            txtOfficialCertEmail: '',
            txtOfficialCertTelNo: '',

            txtDesignFirstName: '',
            txtDesignLastName: '',
            txtDesignTitle: '',
            txtDesignEmail: '',
            txtDesignTelNo: '',

            txtPhysicalAddr1: '',
            txtPhysicalAddr2: '',
            txtPhysicalCity: '',
            txtPhysicalState: '',
            txtPhysicalZip: '',
            ddlPhysicalCountry: '',

            ddlMailingAsPhysical: '',
            txtMailingAddr1: '',
            txtMailingAddr2: '',
            txtMailingCity: '',
            txtMailingState: '',
            txtMailingZip: '',
            ddlMailingCountry: '',

            ddlShippingAsPhysical: '',
            txtShippingAddr1: '',
            txtShippingAddr2: '',
            txtShippingCity: '',
            txtShippingState: '',
            txtShippingZip: '',
            ddlShippingCountry: '',

            ddlPayingOrgOption: 'No',
            txtPayingOrgName: '',
            txtPayingWebUrl: '',
            txtPayingAddr1: '',
            txtPayingAddr2: '',
            txtPayingCity: '',
            txtPayingState: '',
            txtPayingZip: '',

            ddlInvoicingMethod: '',
            ddlInvoicingMethodSelected: [],
            txtInvCorpName: '',
            txtInvAddr1: '',
            txtInvAddr2: '',
            txtInvCity: '',
            txtInvState: '',
            txtInvZip: '',
            ddlInvCountry: '',
            txtInvEmail: '',
            txtInvFaxNumber: '',
            txtInvFaxAttentionName: '',
            txtInvSystemName: '',
            txtInvSystemUrl: '',

            // txtDesignFirstName_1: 'hh',

            values: [],
            totalRows: 1,
            addData: false,
            buttonClickCount: 0,
            showPlusButton: true,

            fetchCountries: false,
            fetchContactType: false,
            fetchAddressType: false,
            fetchAccountTypes: false,
            fetchPriority: false,
            fetchInvoicingMethod: false,

            postDesignData: false,
            result: {},

            priority: {},
            invoicingMethod: {},
            countrieslist: {},
            accounttypes: {},
            country: {},
            contactTypelist: {},
            contactTypelistMap: {},
            addressTypelist: {},
            addressTypelistMap: {},
            custData: {},
            customerData: {},

            accountManagerData:{},
            designEngineerData:{},
            additionalDesignEngineerData:{},
            legalOfficerData:{},
            certifyingOfficerData:{},

            shippingAddressData:{},
            mailingAddressData:{},
            physicalAddressData:{},
            invoicingList:{},
            designEngineerDataToShow:[],

            validationMessgae: {},
            redirectToMainPage:false,

            invoicingMethodMap:{},
            invoicingIdMap:{},

            //setting account type as it is
            accountType:'',

            selectStatements: [
                <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-12"></div>
                    <div className="col-lg-8 col-md-8 col-sm-12">
                        <div class="inner_right">
                            <div class="form-group m-0">
                                <div class="custom_select" style={{ marginLeft: 14 }}>
                                    <select id='0' onChange={this.handleSelect} value={this.ddlInvoicingMethodSelected}>
                                        <option defaultValue hidden>Select</option>
                                        <option value='Email'>Email</option>
                                        <option value='System'>System</option>
                                        <option value='Mail'>Mail</option>
                                        <option value='Fax'>Fax</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ]
        }

        this.design_data = {};
        this.SubmitDesign = this.SubmitDesign.bind(this);
    }

    handleSelect = (e) => {
        let ddlInvoicingMethodSelected = this.state.ddlInvoicingMethodSelected;
        // alert(ddlInvoicingMethodSelected)
        // let selectStatements=this.state.selectStatements;
        console.log('e.target.id-', e.target.id)
        ddlInvoicingMethodSelected[e.target.id] = e.target.value;
        // selectStatements.splice(selectStatements[this.state.buttonClickCount],1)
        console.log(ddlInvoicingMethodSelected)
        this.setState({ ddlInvoicingMethodSelected: ddlInvoicingMethodSelected })
        console.log(e.target.id)

        for(let i=0; i<4; i++){
            if(i != e.target.id){
                let selectorVal =
                $("#"+i+" option").each(function() {
                    if ( $(this).val() == e.target.value) {
                        $(this).remove();
                    }
                });
            }
        }

    }

    removeClick1 = (index) => {
        console.log('index--' + index)
        let selectStatements = this.state.selectStatements;
        let ddlInvoicingMethodSelected = this.state.ddlInvoicingMethodSelected;
        // selectStatements.splice(index, 1);

        selectStatements[index] = '';
        ddlInvoicingMethodSelected[index] = '';

        this.setState({ selectStatements: selectStatements, buttonClickCount: this.state.buttonClickCount - 1, showPlusButton: true, ddlInvoicingMethodSelected: ddlInvoicingMethodSelected });
        // console.log(selectStatements,this.state.buttonClickCount,ddlInvoicingMethodSelected);

    }

    HandlePlusButton = () => {

        let selectStatements = this.state.selectStatements;
        let emptyIndex = selectStatements.indexOf('');

        this.state.selectStatements.length === this.state.invoicingMethod.length ?
            selectStatements[emptyIndex] =
            <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-6">
                    <div className="inner_left">
                        <label>Invoicing Method </label>
                    </div>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12">
                    <div className="custom_select" style={{ marginLeft: 14 }}>
                        <select id={emptyIndex} onChange={this.handleSelect} value={this.id}>
                            <option selected hidden>Select</option>
                            {(JSON.stringify(this.state.invoicingMethod) !== '{}' && this.state.invoicingMethod.length > 0) ? this.state.invoicingMethod.filter(optionNotToShow => this.state.ddlInvoicingMethodSelected.indexOf(optionNotToShow['codeValue']) == -1).map(eachOption =>
                                <option key={eachOption.codeId} value={eachOption.codeValue}>{eachOption.codeValue}</option>
                            ) : ''}
                        </select>
                    </div>
                </div>
            </div>
            :
            selectStatements.push(
                <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="inner_left">
                            <label>Invoicing Method </label>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-12">
                        <div className="custom_select" style={{ marginLeft: 14 }}>
                            <select id={this.state.selectStatements.length} onChange={this.handleSelect} value={this.state.ddlInvoicingMethodSelected[this.state.selectStatements.length]}>
                                <option selected hidden>Select</option>
                                {(JSON.stringify(this.state.invoicingMethod) !== '{}' && this.state.invoicingMethod.length > 0) ? this.state.invoicingMethod.filter(optionNotToShow => this.state.ddlInvoicingMethodSelected.indexOf(optionNotToShow['codeValue']) == -1).map(eachOption =>
                                    <option key={eachOption.codeId} value={eachOption.codeValue}>{eachOption.codeValue}</option>
                                ) : ''}
                            </select>
                        </div>
                    </div>
                </div>)


        this.state.buttonClickCount < this.state.invoicingMethod.length - 2 ?
            this.setState({ buttonClickCount: this.state.buttonClickCount + 1, selectStatements: selectStatements })
            :
            this.setState({ buttonClickCount: this.state.buttonClickCount + 1, showPlusButton: false, selectStatements: selectStatements })
    }


    createUI1 = () => {
        return this.state.selectStatements.map((data, index) => (
            data != '' ?
                <table style={{ width: '100%' }}>
                    <tr key={index}>
                        <td style={{ width: '100%' }}>
                            {data}
                            {this.showData(index)}
                        </td>

                        <td>
                            <span className="delete_btn_span float_right" onClick={() => this.removeClick1(index)}>
                                <img src={require('../../../assets/img/delete.svg')} alt="delete logo" />
                            </span>
                        </td>
                    </tr>
                </table> : ''
        ))
    }

    showData = (index) => {

        var data = [<div id="systemInvoice" style={{paddingTop:"1%"}}>
            <div className="accoutn_form">
                <form action="" method="">
                    <div className="dashboard_form">
                        <div className="inner-dashoard_form">
                            {/*<div className="row">*/}
                            <div>
                                <div className="" style={{ marginLeft: 20 }}>
                                    <div className="row new_pos">
                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                            <div className="inner_left">
                                                <label>System Name</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                            <div className="inner_right">
                                                <div className="form-group">
                                                    <input type="email" id="txtInvSystemName" name="txtInvSystemName" value={this.state.txtInvSystemName} onBlur={() => this.HandleOnBlur('ForceEntry', 'txtInvSystemName', this.state.txtInvSystemName, 'system name')} onChange={this.HandleChange} className="form-control form-control-xs" placeholder="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="" style={{ marginLeft: 20 }}>
                                    <div className="row new_pos">
                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                            <div className="inner_left">
                                                <label>System Url</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                            <div className="inner_right">
                                                <div className="form-group">
                                                    <input type="email" id="txtInvSystemUrl" name="txtInvSystemUrl" value={this.state.txtInvSystemUrl} onChange={this.HandleChange} className="form-control form-control-xs" placeholder="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*</div>*/}
                        </div>
                    </div>
                </form>
            </div>
        </div>
            ,
        <div id="mailInvoice" style={{ width: '100%', paddingTop:"1%" }}>
            <div className="" style={{ marginLeft: 20 }}>
                <div className="row new_pos" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="inner_left">
                            <label>Corporate Name</label>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-6">
                        <div className="inner_right">
                            <div className="form-group">
                                <input type="text" id="txtInvCorpName" name="txtInvCorpName" value={this.state.txtInvCorpName} onBlur={() => this.HandleOnBlur('ForceEntry', 'txtInvCorpName', this.state.txtInvCorpName, 'corporate name')} onChange={this.HandleChange} className="form-control form-control-xs" placeholder="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="" style={{ marginLeft: 20 }}>
                <div className="row new_pos" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="inner_left">
                            <label>Address Line 1</label>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-6">
                        <div className="inner_right">
                            <div className="form-group">
                                <input type="text" id="txtInvAddr1" name="txtInvAddr1" value={this.state.txtInvAddr1} onBlur={() => this.HandleOnBlur('ForceEntry', 'txtInvAddr1', this.state.txtInvAddr1, 'an address')} onChange={this.HandleChange} className="form-control form-control-xs" placeholder="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="" style={{ marginLeft: 20 }}>
                <div className="row new_pos" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="inner_left">
                            <label>Address Line 2</label>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-6">
                        <div className="inner_right">
                            <div className="form-group">
                                <input type="text" id="txtInvAddr2" name="txtInvAddr2" value={this.state.txtInvAddr2} onChange={this.HandleChange} className="form-control form-control-xs" placeholder="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="" style={{ marginLeft: 20 }}>
                <div className="row new_pos" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="inner_left">
                            <label>City</label>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-6">
                        <div className="inner_right">
                            <div className="form-group">
                                <input type="text" id="txtInvCity" name="txtInvCity" value={this.state.txtInvCity} onBlur={() => this.HandleOnBlur('ForceEntry', 'txtInvCity', this.state.txtInvCity, 'a city')} onChange={this.HandleChange} className="form-control form-control-xs" placeholder="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="" style={{ marginLeft: 20 }}>
                <div className="row new_pos" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="inner_left">
                            <label>State/Province</label>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-6">
                        <div className="inner_right">
                            <div className="form-group">
                                <input type="text" id="txtInvState" name="txtInvState" value={this.state.txtInvState} onBlur={() => this.HandleOnBlur('ForceEntry', 'txtInvState', this.state.txtInvState, 'state')} onChange={this.HandleChange} className="form-control form-control-xs" placeholder="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="" style={{ marginLeft: 20 }}>
                <div className="row new_pos" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="inner_left">
                            <label>Zip Code/Postal Code</label>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-6">
                        <div className="inner_right">
                            <div className="form-group">
                                <input type="text" id="txtInvZip" name="txtInvZip" value={this.state.txtInvZip} onBlur={() => this.HandleOnBlur('ForceEntry', 'txtInvZip', this.state.txtInvZip, 'zip')} onChange={this.HandleChange} className="form-control form-control-xs" placeholder="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="" style={{ marginLeft: 20 }}>
                <div className=" border-none">
                    <div className="row new_pos">
                        <div className="col-lg-4 col-md-4 col-sm-6">
                            <div className="inner_left">
                                <label>Country</label>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-6">
                            <div className="row" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                                <div className="col-lg-8 col-md-8 col-sm-12">
                                    <div className="inner_right">
                                        <div className="form-group">
                                            <div className="custom_select">
                                                <select id="ddlInvCountry" name="ddlInvCountry" value={this.state.ddlInvCountry} onChange={this.HandleChange}>
                                                    <option value=''>Select</option>
                                                    {(JSON.stringify(this.state.countrieslist) !== '{}' && this.state.countrieslist.length > 0) ? this.state.countrieslist.map((item, index) =>
                                                        <option key={index} value={item.codeId}>{item.codeName}</option>
                                                    ) : ''}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>,

        <div id="emailInvoice" style={{ width: '100%', paddingTop:"1%"}}>
            <div className="" style={{ marginLeft: 20 }}>
                <div className="row new_pos">
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="inner_left">
                            <label>Email Address</label>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-6">
                        <div className="inner_right new_pos">
                            <div className="form-group">
                                <input type="email" id="txtInvEmail" name="txtInvEmail" value={this.state.txtInvEmail} onBlur={() => this.HandleOnBlur('ForceEmail', 'txtInvEmail', this.state.txtInvEmail, 'email')} onChange={this.HandleChange} className="form-control form-control-xs" placeholder="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>,

        <div id="faxInvoice" style={{ width: '100%', paddingTop:"1%" }}>
            <div className="" style={{ marginLeft: 20 }}>
            <div className=" border-none">
                <div className="row new_pos">
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="inner_left">
                            <label>Fax Number</label>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-6">
                        <div className="inner_right">
                            <div className="form-group">
                                <input type="email" id="txtInvFaxNumber" name="txtInvFaxNumber" value={this.state.txtInvFaxNumber} onBlur={() => this.HandleOnBlur('ForceEntry', 'txtInvFaxNumber', this.state.txtInvFaxNumber, 'fax')} onChange={this.HandleChange} className="form-control form-control-xs" placeholder="" />
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="" style={{marginLeft: 20}}>
                {/* <div className="brd_section"> */}
                    <div className="row new_pos">
                        <div className="col-lg-4 col-md-4 col-sm-6">
                            <div className="inner_left">
                                <label>Fax Attention Name</label>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-6">
                            <div className="inner_right">
                                <div className="form-group">
                                    <input type="email" id="txtInvFaxAttentionName" name="txtInvFaxAttentionName" value={this.state.txtInvFaxAttentionName} onBlur={() => this.HandleOnBlur('ForceEntry', 'txtInvFaxAttentionName', this.state.txtInvFaxAttentionName, 'fax attention name')} onChange={this.HandleChange} className="form-control form-control-xs" placeholder="" />
                                </div>
                            </div>
                        </div>
                    </div>
                {/* </div> */}
            </div>
        </div>

        ]


        return this.state.ddlInvoicingMethodSelected[index] === 'System' ?
            data[0] :
            this.state.ddlInvoicingMethodSelected[index] === 'Mail' ?
                data[1] :
                this.state.ddlInvoicingMethodSelected[index] === 'Email' ?
                    data[2] :
                    this.state.ddlInvoicingMethodSelected[index] === 'Fax' ?
                        data[3] : ''
    }


    UNSAFE_componentWillMount = () => {
        if (JSON.stringify(this.state.countrieslist) === '{}')
            this.setState({
                fetchCountries: true
            });
        if (JSON.stringify(this.state.accounttypes) === '{}')
            this.setState({
                fetchAccountTypes: true
            });

        if (JSON.stringify(this.state.priority) === '{}')
            this.setState({
                fetchPriority: true
            });

        if (JSON.stringify(this.state.country) === '{}')
            this.setState({
                fetchCountries: true
            });

        if (JSON.stringify(this.state.contactTypelist) === '{}')
            this.setState({
                fetchContactType: true
            });

        if (JSON.stringify(this.state.addressTypelist) === '{}')
            this.setState({
                fetchAddressType: true
            });

        if (JSON.stringify(this.state.invoicingMethod) === '{}')
            this.setState({
                fetchInvoicingMethod: true
            });

        if (JSON.stringify(this.state.custData) === '{}')
            this.setState({
                custData: true
            });
    }

    MappingShippingAsPhysical = () => {
        console.log('abc')
        if(this.state.ddlShippingAsPhysical === 'Y'){
            this.state.shippingAddressData["addressLine1"] = this.state.txtPhysicalAddr1
            this.state.shippingAddressData["addressLine2"] = this.state.txtPhysicalAddr2
            this.state.shippingAddressData["city"] = this.state.txtPhysicalCity
            this.state.shippingAddressData["state"] = this.state.txtPhysicalState
            this.state.shippingAddressData["zip"] = this.state.txtPhysicalZip
            this.state.shippingAddressData["country"] = this.state.ddlPhysicalCountry
        }
    }

    MappingMailingAsPhysical = () => {
        console.log('abc')
        if(this.state.ddlMailingAsPhysical === 'Y'){
            this.state.mailingAddressData["addressLine1"] = this.state.txtPhysicalAddr1
            this.state.mailingAddressData["addressLine2"] = this.state.txtPhysicalAddr2
            this.state.mailingAddressData["city"] = this.state.txtPhysicalCity
            this.state.mailingAddressData["state"] = this.state.txtPhysicalState
            this.state.mailingAddressData["zip"] = this.state.txtPhysicalZip
            this.state.mailingAddressData["country"] = this.state.ddlPhysicalCountry
        }
    }

    fillObject = (e, obj) => {
        if(e.target.name.search("FirstName") != -1){
            obj["firstName"] = e.target.value
        }else if(e.target.name.search("LastName") != -1){
            obj["lastName"] = e.target.value
        }else if(e.target.name.search("Title") != -1){
            obj["title"] = e.target.value
        }else if(e.target.name.search("Email") != -1){
            obj["email"] = e.target.value
        }else if(e.target.name.search("TelNo") != -1){
            obj["phoneNumber"] = e.target.value
        }
        return obj
    }

    fillAddressObject = (e, obj) => {
        console.log("e.target.name: "+e.target.name)
        console.log("e.target.name: "+e.target.value)
        if(e.target.name.search("Country") != -1){
            console.log("Inside Country")
            obj["country"] = e.target.value
        }else if(e.target.name.search("Zip") != -1){
            console.log("Inside Zip")
            obj["zip"] = e.target.value
        }else if(e.target.name.search("State") != -1){
            console.log("Inside State")
            obj["state"] = e.target.value
        }else if(e.target.name.search("City") != -1){
            console.log("Inside City")
            obj["city"] = e.target.value
        }else if(e.target.name.search("Addr2") != -1){
            console.log("Inside Addr2")
            obj["addressLine2"] = e.target.value
        }else if(e.target.name.search("Addr1") != -1){
            console.log("Inside Addr1")
            obj["addressLine1"] = e.target.value
        }
        console.log("obj: "+JSON.stringify(obj))
        return obj
    }

    HandleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })

        console.log("ID: "+e.target.parentNode.id)

        if(e.target.parentNode.id == "OfficialCert"){
            this.state.certifyingOfficerData = this.fillObject(e,this.state.certifyingOfficerData)
            /*accountManagerData:{},
            designEngineerData:{},
            legalOfficerData:{},
            certifyingOfficerData:{},*/
        }else if(e.target.parentNode.id == "official"){
            this.state.legalOfficerData = this.fillObject(e,this.state.legalOfficerData)
        }else if((e.target.parentNode.id == "manager")){
            this.state.accountManagerData = this.fillObject(e,this.state.accountManagerData)
        }else if((e.target.parentNode.id == "designManager")){
            this.state.designEngineerData = this.fillObject(e,this.state.designEngineerData)
        }


        if(e.target.parentNode.id == "shippingAddress"){
            console.log("INSIDE shippingAddress")
            this.state.shippingAddressData = this.fillAddressObject(e,this.state.shippingAddressData)
        }else if(e.target.parentNode.id == "mailingAddress"){
            console.log("INSIDE mailingAddress")
            this.state.mailingAddressData = this.fillAddressObject(e,this.state.mailingAddressData)
        }else if((e.target.parentNode.id == "physicalAddress")){
            console.log("INSIDE physicalAddress")
            this.state.physicalAddressData = this.fillAddressObject(e,this.state.physicalAddressData)
        }



        console.log("manager: "+JSON.stringify(this.state.accountManagerData))
        console.log("Official: "+JSON.stringify(this.state.legalOfficerData))
        console.log("OfficialCert: "+JSON.stringify(this.state.certifyingOfficerData))
        console.log("designManager: "+JSON.stringify(this.state.designEngineerData))

        console.log("shippingAddress: "+JSON.stringify(this.state.shippingAddressData))
        console.log("mailingAddress: "+JSON.stringify(this.state.mailingAddressData))
        console.log("physicalAddress: "+JSON.stringify(this.state.physicalAddressData))
        // if (e.target.name === 'txtOrgName')
        //     PrValidate.ForceEntry(e.target.value, 'txtOrgName', 'an Organization name');

        // else if (e.target.name === 'txtInvFaxAttentionName')
        //     PrValidate.ForceEntry(this.state.txtInvFaxAttentionName, 'txtInvFaxAttentionName', 'fax attention name');

        // else if (e.target.name === 'txtInvFaxNumber')
        //     PrValidate.ForceEntry(this.state.txtInvFaxNumber, 'txtInvFaxNumber', 'fax');

        // else if (e.target.name === 'txtInvAddr1')
        //     PrValidate.ForceEntry(e.target.value, 'txtInvAddr1', 'address');

        // else if (e.target.name === 'txtInvEmail')
        //     PrValidate.ForceEntry(e.target.value, 'txtInvEmail', 'email');

        // else if (e.target.name === 'txtFederalTaxIdNo')
        //     PrValidate.ForceEntry(e.target.value, 'txtFederalTaxIdNo', 'TIN');

        // else if (e.target.name === 'txtFirstName')
        //     PrValidate.ForceEntry(e.target.value, 'txtFirstName', 'first name');

        // else if (e.target.name === 'txtLastName')
        //     PrValidate.ForceEntry(e.target.value, 'txtLastName', 'last name');

        // else if (e.target.name === 'txtTitle')
        //     PrValidate.ForceEntry(e.target.value, 'txtTitle', 'title');

        // else if (e.target.name === 'txtEmail')
        //     PrValidate.ForceEntry(e.target.value, 'txtEmail', 'email');

        // else if (e.target.name === 'txtTelNo')
        //     PrValidate.ForceEntry(e.target.value, 'txtTelNo', 'telephone number');

        // else if (e.target.name === 'txtOfficialFirstName')
        //     PrValidate.ForceEntry(e.target.value, 'txtOfficialFirstName', 'first name');

        // else if (e.target.name === 'txtOfficialLastName')
        //     PrValidate.ForceEntry(e.target.value, 'txtOfficialLastName', 'last name');

        // else if (e.target.name === 'txtOfficialTitle')
        //     PrValidate.ForceEntry(e.target.value, 'txtOfficialTitle', 'title');

        // else if (e.target.name === 'txtOfficialEmail')
        //     PrValidate.ForceEntry(e.target.value, 'txtOfficialEmail', 'email');

        // else if (e.target.name === 'txtOfficialTelNo')
        //     PrValidate.ForceEntry(e.target.value, 'txtOfficialTelNo', 'telephone number');

        // else if (e.target.name === 'txtOfficialFirstName')
        //     PrValidate.ForceEntry(e.target.value, 'txtOfficialFirstName', 'first name');

        // else if (e.target.name === 'txtOfficialCertLastName')
        //     PrValidate.ForceEntry(e.target.value, 'txtOfficialCertLastName', 'last name');

        // else if (e.target.name === 'txtOfficialCertTitle')
        //     PrValidate.ForceEntry(e.target.value, 'txtOfficialCertTitle', 'title');

        // else if (e.target.name === 'txtOfficialCertEmail')
        //     PrValidate.ForceEntry(e.target.value, 'txtOfficialCertEmail', 'email');

        // else if (e.target.name === 'txtOfficialCertTelNo')
        //     PrValidate.ForceEntry(e.target.value, 'txtOfficialCertTelNo', 'telephone number');

        // else if (e.target.name === 'txtOfficialCertFirstName')
        //     PrValidate.ForceEntry(e.target.value, 'txtOfficialCertFirstName', 'first name');

        // else if (e.target.name === 'txtOfficialCertLastName')
        //     PrValidate.ForceEntry(e.target.value, 'txtOfficialCertLastName', 'last name');

        // else if (e.target.name === 'txtOfficialCertTitle')
        //     PrValidate.ForceEntry(e.target.value, 'txtOfficialCertTitle', 'title');

        // else if (e.target.name === 'txtOfficialCertEmail')
        //     PrValidate.ForceEntry(e.target.value, 'txtOfficialCertEmail', 'email');

        // else if (e.target.name === 'txtOfficialCertTelNo')
        //     PrValidate.ForceEntry(e.target.value, 'txtOfficialCertTelNo', 'telephone number');

        // else if (e.target.name === 'txtPhysicalAddr1')
        //     PrValidate.ForceEntry(e.target.value, 'txtPhysicalAddr1', 'address');

        // else if (e.target.name === 'txtPhysicalCity')
        //     PrValidate.ForceEntry(e.target.value, 'txtPhysicalCity', 'city');

        // else if (e.target.name === 'txtPhysicalState')
        //     PrValidate.ForceEntry(e.target.value, 'txtPhysicalState', 'state');

        // else if (e.target.name === 'txtPhysicalZip')
        //     PrValidate.ForceEntry(e.target.value, 'txtPhysicalZip', 'zip');

        // else if (e.target.name === 'txtMailingAddr1')
        //     PrValidate.ForceEntry(e.target.value, 'txtMailingAddr1', 'address');

        // else if (e.target.name === 'txtMailingCity')
        //     PrValidate.ForceEntry(e.target.value, 'txtMailingCity', 'city');

        // else if (e.target.name === 'txtMailingState')
        //     PrValidate.ForceEntry(e.target.value, 'txtMailingState', 'state');

        // else if (e.target.name === 'txtMailingZip')
        //     PrValidate.ForceEntry(e.target.value, 'txtMailingZip', 'zip');

        // else if (e.target.name === 'txtShippingAddr1')
        //     PrValidate.ForceEntry(e.target.value, 'txtShippingAddr1', 'address');

        // else if (e.target.name === 'txtShippingCity')
        //     PrValidate.ForceEntry(e.target.value, 'txtShippingCity', 'city');

        // else if (e.target.name === 'txtShippingState')
        //     PrValidate.ForceEntry(e.target.value, 'txtShippingState', 'state');

        // else if (e.target.name === 'txtShippingZip')
        //     PrValidate.ForceEntry(e.target.value, 'txtShippingZip', 'zip');

        // else if (e.target.name === 'txtPayingAddr1')
        //     PrValidate.ForceEntry(e.target.value, 'txtPayingAddr1', 'address');

        // else if (e.target.name === 'txtPayingCity')
        //     PrValidate.ForceEntry(e.target.value, 'txtPayingCity', 'city');

        // else if (e.target.name === 'txtPayingState')
        //     PrValidate.ForceEntry(e.target.value, 'txtPayingState', 'state');

        // else if (e.target.name === 'txtPayingZip')
        //     PrValidate.ForceEntry(e.target.value, 'txtPayingZip', 'zip');

        // else if (e.target.name === 'ddlCustAccType')
        if (e.target.name === 'ddlCustAccType')
            PrValidate.ForceSelect(e.target.value, 'ddlCustAccType', 'account type');

        else if (e.target.name === 'ddlPriprity')
            PrValidate.ForceSelect(e.target.value, 'ddlPriprity', 'a priority');

        else if (e.target.name === 'ddlPhysicalCountry')
            PrValidate.ForceSelect(e.target.value, 'ddlPhysicalCountry', 'a country');

        else if (e.target.name === 'ddlMailingCountry')
            PrValidate.ForceEntry(e.target.value, 'ddlMailingCountry', 'a foundry');

        else if (e.target.name === 'ddlShippingCountry')
            PrValidate.ForceSelect(e.target.value, 'ddlShippingCountry', 'a country');

        else if (e.target.name === 'ddlInvoicingMethod')
            PrValidate.ForceSelect(e.target.value, 'ddlInvoicingMethod', 'an invoice');

        // else if (e.target.name === 'txtInvSystemName')
        //     PrValidate.ForceEntry(e.target.value, 'txtInvSystemName', 'system name');

        // else if (e.target.name === 'txtInvSystemUrl')
        //     PrValidate.ForceEntry(e.target.value, 'txtInvSystemUrl', 'system url');

        // else if (e.target.name === 'txtInvCity')
        //     PrValidate.ForceEntry(e.target.value, 'txtInvCity', 'city');

        // else if (e.target.name === 'txtInvState')
        //     PrValidate.ForceEntry(e.target.value, 'txtInvState', 'state');

        // else if (e.target.name === 'txtInvZip')
        //     PrValidate.ForceEntry(e.target.value, 'txtInvZip', 'zip');

        else if (e.target.name === 'ddlInvCountry')
            PrValidate.ForceSelect(e.target.value, 'ddlInvCountry', 'a country');

        else if (e.target.name === 'ddlPayingCountry')
            PrValidate.ForceSelect(e.target.value, 'ddlPayingCountry', 'a country');

        // else if (e.target.name === 'txtPayingOrgName')
        //     PrValidate.ForceEntry(e.target.value, 'txtPayingOrgName', 'organization name');

        // else if (e.target.name === 'txtPayingWebUrl')
        //     PrValidate.ForceEntry(e.target.value, 'txtPayingWebUrl', 'website url');

        else if (e.target.name === 'ddlMailingAsPhysical')
            PrValidate.ForceSelect(e.target.value, 'ddlMailingAsPhysical', 'yes or no');

        else if (e.target.name === 'ddlShippingAsPhysical')
            PrValidate.ForceSelect(e.target.value, 'ddlShippingAsPhysical', 'yes or no');


        else if (e.target.name === 'txtWebUrl')
            PrValidate.ForceRWebsite(e.target.value, 'txtWebUrl', 'website url');

        else if (e.target.name === 'txtPayingWebUrl')
            PrValidate.ForceRWebsite(e.target.value, 'txtPayingWebUrl', 'website url');

    }

    HandleOnBlur = (type, field, value, msg) => {
        if (type === 'ForceSelect')
            PrValidate.ForceSelect(value, field, msg);
        else if (type === 'ForceRNumber')
            PrValidate.ForceRNumber(value.replace(/[^0-9]/g, ''), field, msg);
        else if (type === 'ForceDate')
            PrValidate.ForceDate(value, field, msg);
        else if (type === 'ForceEntry')
            PrValidate.ForceEntry(value, field, msg);
        else if (type === 'ForceTextArea')
            PrValidate.ForceEntry(value, field, msg);
        else if (type === 'ForceEmail')
            PrValidate.ForceEmail(value, field);


        console.log("this.state.validationMessgae[field]: "+this.state.validationMessgae[field])

    }

    SubmitDesign = () => {

        let txtOrgName = PrValidate.ForceEntry(this.state.txtOrgName, 'txtOrgName', 'an Organization name');
        let txtFederalTaxIdNo = PrValidate.ForceEntry(this.state.txtFederalTaxIdNo, 'txtFederalTaxIdNo', 'TIN');
        let txtFirstName = PrValidate.ForceEntry(this.state.txtFirstName, 'txtFirstName', 'first name');
        let txtLastName = PrValidate.ForceEntry(this.state.txtLastName, 'txtLastName', 'last name');
        let txtTitle = PrValidate.ForceEntry(this.state.txtTitle, 'txtTitle', 'title');
        let txtEmail = PrValidate.ForceEmail(this.state.txtEmail, 'txtEmail');
        let txtTelNo = PrValidate.ForceEntry(this.state.txtTelNo, 'txtTelNo', 'telephone number');
        console.log("this.state.txtWebUrl: "+this.state.txtWebUrl)
        let txtWebUrl = PrValidate.ForceRWebsite(this.state.txtWebUrl, 'txtWebUrl', 'website url');

        let err_arr = []
        err_arr = [txtOrgName, txtFederalTaxIdNo, txtFirstName, txtLastName, txtTitle, txtEmail, txtTelNo];
        let itemToScroll = ["txtOrgName", "txtFederalTaxIdNo", "txtFirstName", "txtLastName", "txtTitle", "txtEmail", "txtTelNo"];

        let initialCheck = true
        err_arr.forEach(function (element, index) {
            if (!element) {
                initialCheck = false
            }
        })

        if(initialCheck) {
            let ddlPayingCountry = PrValidate.ForceSelect(this.state.ddlPayingCountry, 'ddlPayingCountry', 'a country');
            let txtPayingOrgName = PrValidate.ForceEntry(this.state.txtPayingOrgName, 'txtPayingOrgName', 'organization name');
            let txtPayingWebUrl = PrValidate.ForceRWebsite(this.state.txtPayingWebUrl, 'txtPayingWebUrl', 'website url');
            let ddlMailingAsPhysical = PrValidate.ForceSelect(this.state.ddlMailingAsPhysical, 'ddlMailingAsPhysical', 'yes or no');
            let ddlShippingAsPhysical = PrValidate.ForceSelect(this.state.ddlShippingAsPhysical, 'ddlShippingAsPhysical', 'yes or no');
            let ddlInvCountry = PrValidate.ForceSelect(this.state.ddlInvCountry, 'ddlInvCountry', 'a country');
            let txtInvCity = PrValidate.ForceEntry(this.state.txtInvCity, 'txtInvCity', 'city');
            let txtInvState = PrValidate.ForceEntry(this.state.txtInvState, 'txtInvState', 'state');
            let txtInvZip = PrValidate.ForceEntry(this.state.txtInvZip, 'txtInvZip', 'zip');
            let txtInvSystemName = PrValidate.ForceEntry(this.state.txtInvSystemName, 'txtInvSystemName', 'system name');
            let txtInvSystemUrl = PrValidate.ForceEntry(this.state.txtInvSystemUrl, 'txtInvSystemUrl', 'system url');
            let txtInvFaxAttentionName = PrValidate.ForceEntry(this.state.txtInvFaxAttentionName, 'txtInvFaxAttentionName', 'fax attention name');
            let txtInvFaxNumber = PrValidate.ForceEntry(this.state.txtInvFaxNumber, 'txtInvFaxNumber', 'fax');
            let txtInvEmail = PrValidate.ForceEmail(this.state.txtInvEmail, 'txtInvEmail');
            let txtInvAddr1 = PrValidate.ForceEntry(this.state.txtInvAddr1, 'txtInvAddr1', 'address');
            let txtInvCorpName = PrValidate.ForceEntry(this.state.txtInvCorpName, 'txtInvCorpName', 'corporate name');
            let ddlInvoicingMethodSelected = PrValidate.ForceSelect(this.state.ddlInvoicingMethodSelected, 'ddlInvoicingMethod', 'an invoice');
            let ddlCustAccType = PrValidate.ForceSelect(this.state.ddlCustAccType, 'ddlCustAccType', 'account type');
            let ddlPriprity = PrValidate.ForceSelect(this.state.ddlPriprity, 'ddlPriprity', 'a priority');
            let ddlPhysicalCountry = PrValidate.ForceSelect(this.state.ddlPhysicalCountry, 'ddlPhysicalCountry', 'a country');
            let ddlMailingCountry = PrValidate.ForceSelect(this.state.ddlMailingCountry, 'ddlMailingCountry', 'a country');
            let ddlShippingCountry = PrValidate.ForceSelect(this.state.ddlShippingCountry, 'ddlShippingCountry', 'a country');



            let txtOfficialFirstName = PrValidate.ForceEntry(this.state.txtOfficialFirstName, 'txtOfficialFirstName', 'first name');
            let txtOfficialLastName = PrValidate.ForceEntry(this.state.txtOfficialLastName, 'txtOfficialLastName', 'last name');
            let txtOfficialTitle = PrValidate.ForceEntry(this.state.txtOfficialTitle, 'txtOfficialTitle', 'title');
            let txtOfficialEmail = PrValidate.ForceEntry(this.state.txtOfficialEmail, 'txtOfficialEmail', 'email');
            let txtOfficialTelNo = PrValidate.ForceEntry(this.state.txtOfficialTelNo, 'txtOfficialTelNo', 'telephone number');
            let txtOfficialCertFirstName = PrValidate.ForceEntry(this.state.txtOfficialCertFirstName, 'txtOfficialCertFirstName', 'first name');
            let txtOfficialCertLastName = PrValidate.ForceEntry(this.state.txtOfficialCertLastName, 'txtOfficialCertLastName', 'last name');
            let txtOfficialCertTitle = PrValidate.ForceEntry(this.state.txtOfficialCertTitle, 'txtOfficialCertTitle', 'title');
            let txtOfficialCertEmail = PrValidate.ForceEntry(this.state.txtOfficialCertEmail, 'txtOfficialCertEmail', 'email');
            let txtOfficialCertTelNo = PrValidate.ForceEntry(this.state.txtOfficialCertTelNo, 'txtOfficialCertTelNo', 'telephone number');
            let txtPhysicalAddr1 = PrValidate.ForceEntry(this.state.txtPhysicalAddr1, 'txtPhysicalAddr1', 'address');
            let txtPhysicalCity = PrValidate.ForceEntry(this.state.txtPhysicalCity, 'txtPhysicalCity', 'city');
            let txtPhysicalState = PrValidate.ForceEntry(this.state.txtPhysicalState, 'txtPhysicalState', 'state');
            let txtPhysicalZip = PrValidate.ForceEntry(this.state.txtPhysicalZip, 'txtPhysicalZip', 'zip');
            let txtMailingAddr1 = PrValidate.ForceEntry(this.state.txtMailingAddr1, 'txtMailingAddr1', 'address');
            let txtMailingCity = PrValidate.ForceEntry(this.state.txtMailingCity, 'txtMailingCity', 'city');
            let txtMailingState = PrValidate.ForceEntry(this.state.txtMailingState, 'txtMailingState', 'state');
            let txtMailingZip = PrValidate.ForceEntry(this.state.txtMailingZip, 'txtMailingZip', 'zip');
            let txtShippingAddr1 = PrValidate.ForceEntry(this.state.txtShippingAddr1, 'txtShippingAddr1', 'address');
            let txtShippingCity = PrValidate.ForceEntry(this.state.txtShippingCity, 'txtShippingCity', 'city');
            let txtShippingState = PrValidate.ForceEntry(this.state.txtShippingState, 'txtShippingState', 'state');
            let txtShippingZip = PrValidate.ForceEntry(this.state.txtShippingZip, 'txtShippingZip', 'zip');
            let txtPayingAddr1 = PrValidate.ForceEntry(this.state.txtPayingAddr1, 'txtPayingAddr1', 'address');
            let txtPayingCity = PrValidate.ForceEntry(this.state.txtPayingCity, 'txtPayingCity', 'city');
            let txtPayingState = PrValidate.ForceEntry(this.state.txtPayingState, 'txtPayingState', 'state');
            let txtPayingZip = PrValidate.ForceEntry(this.state.txtPayingZip, 'txtPayingZip', 'zip');

            if($("#txtInvSystemName").is(":visible")){
                //System Condition
                err_arr.push(txtInvSystemName)
                err_arr.push(txtInvSystemUrl)

                itemToScroll.push("txtInvSystemName")
                itemToScroll.push("txtInvSystemUrl")
            }
            if($("#txtInvAddr1").is(":visible")){
                //Mail Condition
                err_arr.push(txtInvCorpName)
                err_arr.push(txtInvAddr1)
                err_arr.push(ddlInvCountry)
                err_arr.push(txtInvCity)
                err_arr.push(txtInvState)
                err_arr.push(txtInvZip)

                itemToScroll.push("txtInvCorpName")
                itemToScroll.push("txtInvAddr1")
                itemToScroll.push("ddlInvCountry")
                itemToScroll.push("txtInvCity")
                itemToScroll.push("txtInvState")
                itemToScroll.push("txtInvZip")
            }
            if($("#txtInvEmail").is(":visible")){
                //Email Condition
                err_arr.push(txtInvEmail)
                itemToScroll.push("txtInvEmail")
            }
            if($("#txtInvFaxNumber").is(":visible")){
                //Fax Condition
                err_arr.push(txtInvFaxAttentionName)
                err_arr.push(txtInvFaxNumber)
                itemToScroll.push("txtInvFaxAttentionName")
                itemToScroll.push("txtInvFaxNumber")
            }


            console.log("this.state.txtPayingWebUrl: "+this.state.txtPayingWebUrl)
            if(this.state.txtPayingWebUrl != null && this.state.txtPayingWebUrl != ""){
                err_arr.push(txtPayingWebUrl);
                itemToScroll.push("txtPayingWebUrl")
            }
            console.log("length: "+err_arr.length)
            console.log("txtPayingWebUrl: "+txtPayingWebUrl)
        }



        console.log("this.state.txtWebUrl: "+this.state.txtWebUrl)

        if(this.state.txtWebUrl != '' && this.state.txtWebUrl != null){
            err_arr.push(txtWebUrl);
            itemToScroll.push("txtWebUrl")
        }
        console.log("itemToScroll: "+JSON.stringify(itemToScroll))
        let chk_false = 0;

        let that = this;

        let breakCondition = false;
        err_arr.forEach(function (element, index) {
            if (!element && !breakCondition){
                chk_false++;
                $('html, body').animate({
                    scrollTop: $("#" + itemToScroll[index]).offset().top - 100
                }, 1000);
                breakCondition = true
            }

            if ((err_arr.length - 1) === index) {
                if (chk_false === 0) {
                    that.state.customerData["customerId"] = 254
                    that.state.customerData["accountNumber"] = that.state.txtCustAccNo
                    that.state.customerData["accountStartDate"] = that.state.customerData["accountStartDate"]
                    //that.state.customerData["accountType"] = that.state.ddlCustAccType
                    that.state.customerData["accountType"] = that.state.accountType
                    that.state.customerData["customerName"] = that.state.txtOrgName
                        //that.state.txtFirstName + ' ' + that.state.txtLastName
                    that.state.customerData["priority"] = that.state.ddlPriprity
                    that.state.customerData["tinNumber"] = that.state.txtFederalTaxIdNo
                    that.state.customerData["url"] = that.state.txtWebUrl
                    that.state.customerData["isMailingSame"] = that.state.ddlMailingAsPhysical
                    that.state.customerData["isShippingSame"] = that.state.ddlShippingAsPhysical

                    let payingOrganization = {}
                    if(that.state.ddlPayingOrgOption == "Yes"){
                        if(that.state.customerData.customerThirdParty != null){
                            that.state.customerData.customerThirdParty["addressLine1"] = that.state.txtPayingAddr1
                            that.state.customerData.customerThirdParty["addressLine2"] = that.state.txtPayingAddr2
                            that.state.customerData.customerThirdParty["city"] = that.state.txtPayingCity
                            that.state.customerData.customerThirdParty["country"] = that.state.ddlPayingCountry
                            that.state.customerData.customerThirdParty["organizationName"] = that.state.txtPayingOrgName
                            that.state.customerData.customerThirdParty["state"] = that.state.txtPayingState
                            that.state.customerData.customerThirdParty["url"] = that.state.txtPayingWebUrl
                            that.state.customerData.customerThirdParty["zip"] = that.state.txtPayingZip
                        }else{
                            payingOrganization["addressLine1"] = that.state.txtPayingAddr1
                            payingOrganization["addressLine2"] = that.state.txtPayingAddr2
                            payingOrganization["city"] = that.state.txtPayingCity
                            payingOrganization["country"] = that.state.ddlPayingCountry
                            payingOrganization["organizationName"] = that.state.txtPayingOrgName
                            payingOrganization["state"] = that.state.txtPayingState
                            payingOrganization["url"] = that.state.txtPayingWebUrl
                            payingOrganization["zip"] = that.state.txtPayingZip
                            that.state.customerData["customerThirdParty"] = payingOrganization
                        }
                    }else{
                        that.state.customerData["customerThirdParty"] = {}
                    }





                    let customerInvoicingData = {}
                    console.log("that.state.txtInvAddr2: "+that.state.txtInvAddr2)

                    console.log("ddlInvoicingMethodSelected: "+JSON.stringify(that.state.ddlInvoicingMethodSelected))
                    console.log("ddlInvoicingMethodSelected: "+JSON.stringify(that.state.ddlInvoicingMethodSelected.length))
                    let ddlInvoicingMethodSelectedFiltered = that.state.ddlInvoicingMethodSelected.filter(function(e){return e});
                    console.log("ddlInvoicingMethodSelectedFiltered: "+JSON.stringify(ddlInvoicingMethodSelectedFiltered))
                    console.log("ddlInvoicingMethodSelectedFiltered: "+JSON.stringify(ddlInvoicingMethodSelectedFiltered.length))

                    console.log("invoicingMethod: "+JSON.stringify(that.state.invoicingMethod))
                    console.log("that.state.customerData.customerInvoicingList: "+JSON.stringify(that.state.customerData.customerInvoicingList))

                    that.state.customerData.customerInvoicingList = []
                    if(ddlInvoicingMethodSelectedFiltered.length > 0){
                    //if(that.state.customerData.customerInvoicingList.length > 0){
                        console.log("Inside IF customerInvoicingList")

                        ddlInvoicingMethodSelectedFiltered.map(function(item) {

                            //mail
                            if(item == "Mail"){
                                if(that.state.txtInvAddr1 != ''){
                                    let mailObj = {
                                        corporateName: that.state.txtInvCorpName,
                                        addressLine1: that.state.txtInvAddr1,
                                        addressLine2: that.state.txtInvAddr2,
                                        city: that.state.txtInvCity,
                                        stateProvince: that.state.txtInvState,
                                        zip: that.state.txtInvZip,
                                        country: that.state.ddlInvCountry,
                                        invoicingMethod: that.state.invoicingMethodMap["Mail"]
                                    }
                                    if(that.state.invoicingIdMap["Mail"] != null){
                                        mailObj["invoicingId"] = that.state.invoicingIdMap["Mail"]
                                    }
                                    that.state.customerData.customerInvoicingList.push(mailObj)
                                    /*that.state.customerData.customerInvoicingList[0]["addressLine1"] = that.state.txtInvAddr1
                                    that.state.customerData.customerInvoicingList[0]["addressLine2"] = that.state.txtInvAddr2
                                    that.state.customerData.customerInvoicingList[0]["city"] = that.state.txtInvCity
                                    that.state.customerData.customerInvoicingList[0]["stateProvince"] = that.state.txtInvState
                                    that.state.customerData.customerInvoicingList[0]["zip"] = that.state.txtInvZip
                                    that.state.customerData.customerInvoicingList[0]["country"] = that.state.ddlInvCountry*/
                                }
                            }


                            //email
                            if(item == "Email"){
                                if(that.state.txtInvEmail != ''){
                                    let emailAddrObj = {
                                        emailAddress: that.state.txtInvEmail,
                                        invoicingMethod: that.state.invoicingMethodMap["Email"]
                                    }
                                    if(that.state.invoicingIdMap["Email"] != null){
                                        emailAddrObj["invoicingId"] = that.state.invoicingIdMap["Email"]
                                    }
                                    that.state.customerData.customerInvoicingList.push(emailAddrObj)
                                    //that.state.customerData.customerInvoicingList[0]["emailAddress"] = that.state.txtInvEmail
                                }
                            }


                            //fax
                            if(item == "Fax"){
                                if(that.state.txtInvFaxNumber != '') {
                                    let faxObj = {
                                        faxNumber: that.state.txtInvFaxNumber,
                                        attentionName: that.state.txtInvFaxAttentionName,
                                        invoicingMethod: that.state.invoicingMethodMap["Fax"]
                                    }
                                    if(that.state.invoicingIdMap["Fax"] != null){
                                        faxObj["invoicingId"] = that.state.invoicingIdMap["Fax"]
                                    }
                                    that.state.customerData.customerInvoicingList.push(faxObj)
                                    /*that.state.customerData.customerInvoicingList[0]["faxNumber"] = that.state.txtInvFaxNumber
                                    that.state.customerData.customerInvoicingList[0]["attentionName"] = that.state.txtInvFaxAttentionName*/
                                }
                            }


                            //system
                            if(item == "System"){
                                if(that.state.txtInvSystemName != ''){
                                    let systemObj = {
                                        systemName: that.state.txtInvSystemName,
                                        url:that.state.txtInvSystemUrl,
                                        invoicingMethod: that.state.invoicingMethodMap["System"]
                                    }
                                    if(that.state.invoicingIdMap["System"] != null){
                                        systemObj["invoicingId"] = that.state.invoicingIdMap["System"]
                                    }
                                    that.state.customerData.customerInvoicingList.push(systemObj)
                                    /*that.state.customerData.customerInvoicingList[0]["systemName"] = that.state.txtInvSystemName
                                    that.state.customerData.customerInvoicingList[0]["url"] = that.state.txtInvSystemUrl*/
                                }
                            }


                        })




                        //that.state.customerData.customerInvoicingList[0]["invoicingMethod"] = that.state.ddlInvoicingMethod

                    }/*else{
                        console.log("Inside ELSE")
                        customerInvoicingData["addressLine1"] = that.state.txtInvAddr1
                        customerInvoicingData["addressLine2"] = that.state.txtInvAddr2
                        customerInvoicingData["city"] = that.state.txtInvCity
                        customerInvoicingData["country"] = that.state.ddlInvCountry
                        customerInvoicingData["emailAddress"] = that.state.txtInvEmail
                        customerInvoicingData["faxNumber"] = that.state.txtInvFaxNumber
                        customerInvoicingData["invoicingMethod"] = that.state.ddlInvoicingMethod
                        customerInvoicingData["stateProvince"] = that.state.txtInvState
                        customerInvoicingData["systemName"] = that.state.txtInvSystemName
                        customerInvoicingData["url"] = that.state.txtInvSystemUrl
                        customerInvoicingData["zip"] = that.state.txtInvZip
                        that.state.customerData["customerInvoicingList"].push(customerInvoicingData)
                    }*/

                    console.log("that.state.customerData.customerInvoicingList: "+JSON.stringify(that.state.customerData.customerInvoicingList))
                    console.log("that.state.legalOfficerData: "+JSON.stringify(that.state.legalOfficerData))
                    console.log("that.state.certifyingOfficerData: "+JSON.stringify(that.state.certifyingOfficerData))


                    let customerDataList = []
                    if(that.state.accountManagerData["firstName"] && that.state.accountManagerData["email"]){
                        console.log("Inside accountManagerData")
                        that.state.accountManagerData["contactType"] = that.state.contactTypelistMap["Account Manager"].codeId
                        customerDataList.push(that.state.accountManagerData)
                    }

                    if(that.state.legalOfficerData["firstName"] && that.state.legalOfficerData["email"]){
                        console.log("Inside legalOfficerData")
                        that.state.legalOfficerData["contactType"] = that.state.contactTypelistMap["Legal Officer"].codeId
                        customerDataList.push(that.state.legalOfficerData)
                    }

                    if(that.state.certifyingOfficerData["firstName"] && that.state.certifyingOfficerData["email"]){
                        console.log("Inside certifyingOfficerData")
                        that.state.certifyingOfficerData["contactType"] = that.state.contactTypelistMap["Certifying Officer"].codeId
                        customerDataList.push(that.state.certifyingOfficerData)
                    }

                    //customerDataList.push(that.state.designEngineerData)

                    let addressDataList = []
                    console.log("that.state.physicalAddressData: "+JSON.stringify(that.state.physicalAddressData))
                    if(that.state.shippingAddressData["addressLine1"]){
                        console.log("Inside shippingAddressData")
                        if(that.state.ddlShippingAsPhysical  === 'Y'){
                            that.MappingShippingAsPhysical()
                        }
                        that.state.shippingAddressData["addressType"] = that.state.addressTypelistMap["Shipping Address"].codeId
                        addressDataList.push(that.state.shippingAddressData)
                    }

                    if(that.state.mailingAddressData["addressLine1"]){
                        console.log("Inside mailingAddressData")
                        if(that.state.ddlMailingAsPhysical  === 'Y'){
                            that.MappingMailingAsPhysical()
                        }
                        that.state.mailingAddressData["addressType"] = that.state.addressTypelistMap["Mailing Address"].codeId
                        addressDataList.push(that.state.mailingAddressData)
                    }

                    if(that.state.physicalAddressData["addressLine1"] ){
                        console.log("Inside physicalAddressData")
                        that.state.physicalAddressData["addressType"] = that.state.addressTypelistMap["Physical Address"].codeId
                        addressDataList.push(that.state.physicalAddressData)
                    }



                    that.state.customerData["customerAddresses"] = addressDataList

                    let designEngineersDataList = []

                    for(let i=0; i<that.state.designEngineerDataToShow.length; i++){
                        console.log("Inside designEngineerDataToShow")
                        let tempDesignEngineerData = {}

                        tempDesignEngineerData["firstName"] = $("#txtDesignFirstName_default_"+i).val()
                        tempDesignEngineerData["lastName"] = $("#txtDesignLastName_default_"+i).val()
                        tempDesignEngineerData["title"] = $("#txtDesignTitle_default_"+i).val()
                        tempDesignEngineerData["email"] = $("#txtDesignEmail_default_"+i).val()
                        tempDesignEngineerData["phoneNumber"] = $("#txtDesignTelNo_default_"+i).val()
                        //tempDesignEngineerData["contactId"] =  793
                        tempDesignEngineerData["contactType"] = that.state.contactTypelistMap["Design Manager"].codeId
                        tempDesignEngineerData["status"] = 291
                        tempDesignEngineerData["contactTypeObject"] = that.state.designEngineerData["contactTypeObject"]
                        tempDesignEngineerData["contactId"] = that.state.designEngineerDataToShow[i]["contactId"]
                        console.log("tempDesignEngineerData: "+JSON.stringify(tempDesignEngineerData))

                        if(tempDesignEngineerData["firstName"] != '' && tempDesignEngineerData["email"] != ''){
                            console.log("Inside IF 1")
                            customerDataList.push(tempDesignEngineerData)
                        }
                    }

                    for(let i=0; i<that.state.totalRows-1; i++){
                        let tempDesignEngineerData = {}

                        tempDesignEngineerData["firstName"] = $("#txtDesignFirstName_"+i).val()
                        tempDesignEngineerData["lastName"] = $("#txtDesignLastName_"+i).val()
                        tempDesignEngineerData["title"] = $("#txtDesignTitle_"+i).val()
                        tempDesignEngineerData["email"] = $("#txtDesignEmail_"+i).val()
                        tempDesignEngineerData["phoneNumber"] = $("#txtDesignTelNo_"+i).val()
                        //tempDesignEngineerData["contactId"] =  793
                        tempDesignEngineerData["contactType"] = that.state.contactTypelistMap["Design Manager"].codeId
                        tempDesignEngineerData["status"] = 291
                        tempDesignEngineerData["contactTypeObject"] = that.state.designEngineerData["contactTypeObject"]

                        console.log("tempDesignEngineerData: "+JSON.stringify(tempDesignEngineerData))

                        if(tempDesignEngineerData["firstName"] != '' && tempDesignEngineerData["email"] != ''){
                            console.log("Inside IF 2")
                            customerDataList.push(tempDesignEngineerData)
                        }
                    }


                    //let newState = this.state.recipes.slice(); newState.push({title: input, ingredients: setIngredients()}); this.setState({ recipes: newState });

                    //console.log("designEngineersDataList: "+JSON.stringify(designEngineersDataList))
                    console.log("designEngineersDataList: "+JSON.stringify(customerDataList))

                    that.state.customerData["customerContacts"] = customerDataList
                    /*that.state.customerData["customerInvoicingList"] = invoicingList*/


                    console.log("customerData: "+JSON.stringify(that.state.customerData))

                    that.setState({
                        customerData: that.state.customerData,
                        postDesignData: true
                    })
                }
            }
        });

        // this.state.postDesignData?console.log('data submitted'):console.log('data error')
    }

    AddNewRows = () => {
        // this.setState(prevState => ({ values: [...prevState.values, '']}))
        let datas = [];


         for (let k = 0; k < this.state.totalRows; k++) {
             if(document.getElementById("txtDesignFirstName_"+k) == null){
                 this.state.values.push({ ['txtDesignFirstName_' + k]: '', ['txtDesignLastName_' + k]: '', ['txtDesignTitle_' + k]: '', ['txtDesignEmail_' + k]: '', ['txtDesignTelNo_' + k]: '' })
             }
            //datas.push({ ['txtDesignFirstName_' + k]: '', ['txtDesignLastName_' + k]: '', ['txtDesignTitle_' + k]: '', ['txtDesignEmail_' + k]: '', ['txtDesignTelNo_' + k]: '' })
        }
        this.setState({
            addData: true,
            values: this.state.values,
            totalRows: this.state.totalRows + 1
        })
    }

    AddDefaultRows = () => {
        let datas = [];
        for (let k = 0; k < this.state.designEngineerDataToShow; k++) {
            datas.push({ ['txtDesignFirstName_' + k]: '', ['txtDesignLastName_' + k]: '', ['txtDesignTitle_' + k]: '', ['txtDesignEmail_' + k]: '', ['txtDesignTelNo_' + k]: '' })
        }
        this.setState({
            designEngineerDataToShow: datas,
        })
    }

    // HandleMultiChange=(i, event) =>{
    //     alert(i+"---"+event.name)
    //     let values = [...this.state.values];
    //     values[i] = event.target.value;
    //     this.setState({ values });
    // }

    HandleRowValChange = (e) => {
        console.log("INSIDE HandleRowValChange "+e.target.parentNode.id)
        console.log("INSIDE HandleRowValChange "+e.target.id)
        console.log("INSIDE HandleRowValChange "+e.target.value)
        for (var x = 0; x < this.state.values.length; x++) {
            if (this.state.values[x].hasOwnProperty(e.target.name)) {
                this.state.values[x][e.target.name] = e.target.value;
            }
        }

        /*for (var x = 0; x < this.state.designEngineerDataToShow.length; x++) {
            console.log("this.state.designEngineerDataToShow: "+JSON.stringify(this.state.designEngineerDataToShow))
            if (this.state.designEngineerDataToShow[x].hasOwnProperty(e.target.name)) {
                this.state.designEngineerDataToShow[x][e.target.name] = e.target.value;
            }
        }*/


        console.log("NAME: "+e.target.name)
        this.setState({
            [e.target.name]: e.target.value
        })

        if(e.target.id.search("default") != -1){
            this.state.designEngineerDataToShow[e.target.id.substring(e.target.id.length-1)] = this.fillObject(e,this.state.designEngineerDataToShow[e.target.id.substring(e.target.id.length-1)])
        }
        //this.state.designEngineerDataToShow[e.target.id.substring(e.target.id.length-1)]["lastName"] = e.target.value
        //document.getElementById("txtDesignLastName_default_1").value = e.target.value


        if(e.target.parentNode.id == "OfficialCert"){
            this.state.certifyingOfficerData = this.fillObject(e,this.state.certifyingOfficerData)
            this.state.certifyingOfficerData["contactType"] = 84
            this.state.certifyingOfficerData["status"] = 291
            /*accountManagerData:{},
            designEngineerData:{},
            legalOfficerData:{},
            certifyingOfficerData:{},*/
        }else if(e.target.parentNode.id == "official"){
            this.state.legalOfficerData = this.fillObject(e,this.state.legalOfficerData)
            this.state.legalOfficerData["contactType"] = 83
            this.state.legalOfficerData["status"] = 291
        }else if((e.target.parentNode.id == "manager")){
            this.state.accountManagerData = this.fillObject(e,this.state.accountManagerData)
            this.state.accountManagerData["contactType"] = 82
            this.state.accountManagerData["status"] = 291
        }else if((e.target.parentNode.id == "designManager")){
            if(e.target.id.includes("txtDesignEmail") || e.target.id.includes("txtDesignTelNo")){
                let emailFormat = true
                if(e.target.id.includes("txtDesignEmail")){
                    emailFormat = PrValidate.ForceEmail(e.target.value, e.target.name);
                    console.log("emailFormat: "+emailFormat)
                    if(!emailFormat){
                        console.log("INSIDE 1")
                        this.state.validationMessgae[e.target.name] = true
                    }
                }
                if(emailFormat == true){
                    console.log("INSIDE 2")
                    this.phoneAndEmailValidation(e)
                }

            }
        }
        this.setState({

        })

        console.log("this.state.validationMessgae[e.target.name]: "+this.state.validationMessgae[e.target.name])
    }

    phoneAndEmailValidation = (e) => {
        for(let i=0; i< this.state.designEngineerDataToShow.length; i++){
            console.log("=====================")
            if(e.target.id != "txtDesignEmail_default_"+i && e.target.id != "txtDesignTelNo_default_"+i){
                console.log("Email Address: "+document.getElementById("txtDesignEmail_default_"+i).value)
                console.log("Phone Number: "+document.getElementById("txtDesignTelNo_default_"+i).value)
                if(e.target.value == document.getElementById("txtDesignEmail_default_"+i).value){
                    console.log("EMAIL ALREADY EXIST"+e.target.id)
                    this.state.validationMessgae[e.target.id] = true
                    return;
                    //document.getElementById("designError_"+i).innerText = "EMAIL ALREADY EXIST"
                }else if(e.target.value == document.getElementById("txtDesignTelNo_default_"+i).value){
                    console.log("PHONE ALREADY EXIST"+e.target.id)
                    this.state.validationMessgae[e.target.id] = true
                    return;
                    //document.getElementById("designError_"+i).innerText = "PHONE ALREADY EXIST"
                }else{
                    this.state.validationMessgae[e.target.id] = false
                }
            }
        }
        for(let i=0; i< this.state.values.length; i++){
            console.log("=====================")
            if(e.target.id != "txtDesignEmail_"+i && e.target.id != "txtDesignTelNo_"+i){
                console.log("Email Address: "+document.getElementById("txtDesignEmail_"+i).value)
                console.log("Phone Number: "+document.getElementById("txtDesignTelNo_"+i).value)
                if(e.target.value == document.getElementById("txtDesignEmail_"+i).value){
                    console.log("EMAIL ALREADY EXIST"+e.target.id)
                    this.state.validationMessgae[e.target.id] = true
                    return;
                    //document.getElementById("designError_"+i).innerText = "EMAIL ALREADY EXIST"
                }else if(e.target.value == document.getElementById("txtDesignTelNo_"+i).value){
                    console.log("PHONE ALREADY EXIST"+e.target.id)
                    this.state.validationMessgae[e.target.id] = true
                    return;
                    //document.getElementById("designError_"+i).innerText = "PHONE ALREADY EXIST"
                }else{
                    this.state.validationMessgae[e.target.id] = false
                }
            }
        }


    }

    createUI = () => {
        // alert(JSON.stringify(this.state.values))
        // let i=1
        // for(let m=0; m<= 4; m++){

        /*let lastIndex = 0
        if(this.state.designEngineerDataToShow != null){
            return this.state.customerData.customerContacts.map((item, index) =>
                    <tr key={index} id={'trDesignManager_' + index}>
                        {/!* {alert(JSON.stringify(el))} *!/}
                        {/!* {alert(JSON.stringify(el['txtDesignFirstName_'+i]))} *!/}
                        <td>
                            <div className="form-group" id="designManager">
                                {/!* <input type="text" id={'txtDesignFirstName_'+i} name={'txtDesignFirstName_'+i} value={el||''} onChange={this.HandleMultiChange.bind(this, i)} className="form-control form-control-xs" /> *!/}
                                <input type="text" id={'txtDesignFirstName_' + index} name={'txtDesignFirstName_' + index} value={item['txtDesignFirstName_' + index]} onChange={this.HandleRowValChange} className="form-control form-control-xs" />
                            </div>
                        </td>
                        <td>
                            <div className="form-group" id="designManager">
                                {/!* <input type="text" id={'txtDesignLastName_'+i} name={'txtDesignLastName_'+i} onChange={this.HandleMultiChange.bind(this, i)} className="form-control form-control-xs" /> *!/}
                                <input type="text" id={'txtDesignLastName_' + index} name={'txtDesignLastName_' + index} value={item['txtDesignLastName_' + index]} onChange={this.HandleRowValChange} className="form-control form-control-xs" />
                            </div>
                        </td>
                        <td>
                            <div className="form-group" id="designManager">
                                {/!* <input type="text" id={'txtDesignLastName_'+i} name={'txtDesignLastName_'+i} onChange={this.HandleMultiChange.bind(this, i)} className="form-control form-control-xs" /> *!/}
                                <input type="text" id={'txtDesignTitle_' + index} name={'txtDesignTitle_' + index} value={item['txtDesignTitle_' + index]} onChange={this.HandleRowValChange} className="form-control form-control-xs" />
                            </div>
                        </td>
                        <td>
                            <div className="form-group" id="designManager">
                                {/!* <input type="text" id={'txtDesignLastName_'+i} name={'txtDesignLastName_'+i} onChange={this.HandleMultiChange.bind(this, i)} className="form-control form-control-xs" /> *!/}
                                <input type="text" id={'txtDesignEmail_' + index} name={'txtDesignEmail_' + index} value={item['txtDesignEmail_' + index]} onChange={this.HandleRowValChange} className="form-control form-control-xs" />
                            </div>
                        </td>
                        <td>
                            <div className="form-group" id="designManager">
                                {/!* <input type="text" id={'txtDesignLastName_'+i} name={'txtDesignLastName_'+i} onChange={this.HandleMultiChange.bind(this, i)} className="form-control form-control-xs" /> *!/}
                                <input type="text" id={'txtDesignTelNo_' + index} name={'txtDesignTelNo_' + index} value={item['txtDesignTelNo_' + index]} onChange={this.HandleRowValChange} className="form-control form-control-xs" />
                            </div>
                        </td>
                        <td>
                            <div className="form-group">
                        <span>
                            <img src={require("../../../assets/img/delete.svg")} alt="delete logo" onClick={this.removeClick.bind(this, i)} />
                        </span>
                                {/!* <input type='button' value='remove' onClick={this.removeClick.bind(this, i)}/> *!/}
                            </div>
                        </td>
                    </tr>
            )

            lastIndex = this.state.designEngineerDataToShow.length
            this.state.designEngineerDataToShow = {}
        }*/

        let lastIndex = 0
        return this.state.values.map((el, i) =>
            // <tr key={i} id={'trDesignManager_'+i}>
            <tr key={i+lastIndex} id={'trDesignManager_' + (i+lastIndex)}>
                {/* {alert(JSON.stringify(el))} */}
                {/* {alert(JSON.stringify(el['txtDesignFirstName_'+i]))} */}
                <td>
                    <div className="form-group" id="designManager">
                        {/* <input type="text" id={'txtDesignFirstName_'+i} name={'txtDesignFirstName_'+i} value={el||''} onChange={this.HandleMultiChange.bind(this, i)} className="form-control form-control-xs" /> */}
                        <input type="text" id={'txtDesignFirstName_' + (i+lastIndex)} name={'txtDesignFirstName_' + (i+lastIndex)} value={el['txtDesignFirstName_' + (i+lastIndex)]} onChange={this.HandleRowValChange} className="form-control form-control-xs" />
                    </div>
                </td>
                <td>
                    <div className="form-group" id="designManager">
                        {/* <input type="text" id={'txtDesignLastName_'+i} name={'txtDesignLastName_'+i} onChange={this.HandleMultiChange.bind(this, i)} className="form-control form-control-xs" /> */}
                        <input type="text" id={'txtDesignLastName_' + (i+lastIndex)} name={'txtDesignLastName_' + (i+lastIndex)} value={el['txtDesignLastName_' + (i+lastIndex)]} onChange={this.HandleRowValChange} className="form-control form-control-xs" />
                    </div>
                </td>
                <td>
                    <div className="form-group" id="designManager">
                        {/* <input type="text" id={'txtDesignLastName_'+i} name={'txtDesignLastName_'+i} onChange={this.HandleMultiChange.bind(this, i)} className="form-control form-control-xs" /> */}
                        <input type="text" id={'txtDesignTitle_' + (i+lastIndex)} name={'txtDesignTitle_' + (i+lastIndex)} value={el['txtDesignTitle_' + (i+lastIndex)]} onChange={this.HandleRowValChange} className="form-control form-control-xs" />
                    </div>
                </td>
                <td>
                    <div className="form-group" id="designManager">
                        {/* <input type="text" id={'txtDesignLastName_'+i} name={'txtDesignLastName_'+i} onChange={this.HandleMultiChange.bind(this, i)} className="form-control form-control-xs" /> */}
                        <input type="text" id={'txtDesignEmail_' + (i+lastIndex)} name={'txtDesignEmail_' + (i+lastIndex)} value={el['txtDesignEmail_' + (i+lastIndex)]} onChange={this.HandleRowValChange}
                               onBlur={() => this.HandleOnBlur('ForceEmail', 'txtDesignEmail_' + (i+lastIndex), el['txtDesignEmail_' + (i+lastIndex)], 'email')} className="form-control form-control-xs" />
                    </div>

                    {this.state.validationMessgae["txtDesignEmail_"+i] == true?
                        <label id={"designError_"+i} style={{fontStyle: "italic", color: "red", fontSize: "12px"}}>Please Enter Correct Email</label>:''
                    }
                </td>
                <td>
                    <div className="form-group" id="designManager">
                        {/* <input type="text" id={'txtDesignLastName_'+i} name={'txtDesignLastName_'+i} onChange={this.HandleMultiChange.bind(this, i)} className="form-control form-control-xs" /> */}
                        <input type="text" id={'txtDesignTelNo_' + (i+lastIndex)} name={'txtDesignTelNo_' + (i+lastIndex)} value={el['txtDesignTelNo_' + (i+lastIndex)]} onChange={this.HandleRowValChange} className="form-control form-control-xs" />
                    </div>
                    {this.state.validationMessgae["txtDesignTelNo_"+i] == true?
                        <label id={"designError_"+i} style={{fontStyle: "italic", color: "red", fontSize: "12px"}}>Please Enter Correct Phone</label>:''
                    }
                </td>
                <td>
                    <div className="form-group">
                        <span>
                            <img src={require("../../../assets/img/delete.svg")} alt="delete logo" onClick={this.removeClick.bind(this, i+lastIndex)} />
                        </span>
                        {/* <input type='button' value='remove' onClick={this.removeClick.bind(this, i)}/> */}
                    </div>
                </td>

            </tr>
        )
    }
    // createUI=()=>{
    //     return this.state.values.map((el, i) => 
    //         <tr key={i} id={'trDesignManager_'+i}>
    //            <td>
    //             <div className="form-group">
    //                 {/* <input type="text" id={'txtDesignFirstName_'+i} name={'txtDesignFirstName_'+i} value={el||''} onChange={this.HandleMultiChange.bind(this, i)} className="form-control form-control-xs" /> */}
    //                 <input type="text" id={'txtDesignFirstName_'+i} name={'txtDesignFirstName_'+i} value={el||''} onChange={this.HandleMultiChange.bind(this, i)} className="form-control form-control-xs" />
    //             </div>
    //            </td>
    //            <td>
    //             <div className="form-group">
    //                 <input type="text" id={'txtDesignLastName_'+i} name={'txtDesignLastName_'+i} value={el||''} onChange={this.HandleMultiChange.bind(this, i)} className="form-control form-control-xs" />
    //             </div>
    //            </td>
    //            <td>
    //             <div className="form-group">
    //                 <input type='button' value='remove' onClick={this.removeClick.bind(this, i)}/>
    //             </div>
    //            </td>
    //         </tr>          
    //     )
    // }

    removeClick = (i) => {
        console.log("I: "+i)
        console.log(this.state.totalRows)
        let remain_row = (this.state.totalRows) - 1;
        let values = [...this.state.values];
        values.splice(i, 1);
        this.setState({ values, totalRows: remain_row });
    }

    HandleSuccessGetCountriesResponse = (res) => {

        let USElement = {}
        res.data.forEach((element, index) => {
            if(element.codeName == "United States"){
                USElement = element
            }
        })
        res.data.sort(function(x,y){ return x == USElement ? -1 : y == USElement ? 1 : 0; });

        this.setState({
            fetchCountries: false,
            countrieslist: res.data
        })
        console.log("countries list: "+JSON.stringify(res.data))
        // alert(JSON.stringify(res))
    }

    HandleSuccessGetContactTypeListResponse = (res) => {
        this.setState({
            fetchContactType: false,
            contactTypelist: res.data
        })

        let that = this
        this.state.contactTypelist.map(function(item) {
            that.state.contactTypelistMap[item["codeValue"]] = item
         })
        console.log("contact type list: "+JSON.stringify(res.data))
        console.log("contactTypelistMap list: "+JSON.stringify(that.state.contactTypelistMap))
    }

    HandleSuccessGetAddressTypeListResponse = (res) => {
        this.setState({
            fetchAddressType: false,
            addressTypelist: res.data
        })

        let that = this
        this.state.addressTypelist.map(function(item) {
            that.state.addressTypelistMap[item["codeValue"]] = item
        })
        console.log("address type list: "+JSON.stringify(res.data))
        console.log("addressTypelistMap list: "+JSON.stringify(that.state.addressTypelistMap))
    }

    HandleSuccessGetAccountTypesResponse = (res) => {
        this.setState({
            fetchAccountTypes: false,
            accounttypes: res.data
        })
        // alert(JSON.stringify(res))
    }

    HandleSuccessGetPriority = (res) => {
        this.setState({
            fetchPriority: false,
            priority: res.data
        })
        // alert(JSON.stringify(res))
    }

    HandleAccStartDateChange = (txtAccStartDate) => {
        this.setState({ txtAccStartDate })
    }

    HandleSuccessGetInvoicingMethod = (res) => {
        this.setState({
            fetchInvoicingMethod: false,
            invoicingMethod: res.data
        })

        let that = this
        this.state.invoicingMethod.map(function (item) {
            that.state.invoicingMethodMap[item.codeValue] = item.codeId
        })
        // alert(JSON.stringify(res))
    }

    HandleSuccessGetCustomer = (res) => {
        // alert('jjj'+this.state.showPlusButton)
        // console.log('Data Captured')
        this.setState({
            customerData: res.data
        })

        console.log("res: "+JSON.stringify(res.data))


        let that = this
        res.data.customerContacts.map(function(item) {
            if(item.contactTypeObject["codeValue"] === "Account Manager"){
                that.state.accountManagerData = item
            }else if(item.contactTypeObject["codeValue"] === "Design Manager"){
                that.state.designEngineerData = item
                that.state.additionalDesignEngineerData = item
            }else if(item.contactTypeObject["codeValue"] === "Legal Officer"){
                that.state.legalOfficerData = item
            }else if(item.contactTypeObject["codeValue"] === "Certifying Officer"){
                that.state.certifyingOfficerData = item
            }
        })

        res.data.customerContacts.filter((data) => data.contactTypeObject.codeName === "Account Manager").map(data => (
            this.setState({
                txtFirstName: data.firstName,
                txtLastName: data.lastName,
                txtTitle: data.title,
                txtEmail: data.email,
                txtTelNo: data.phoneNumber,
            })
        ))

        let i=0;
        res.data.customerContacts.map(function(item) {
            if(item.contactTypeObject["codeValue"] == "Design Manager"){
                that.state.designEngineerDataToShow.push(item)

                /*if (i == 0) {
                    that.setState({
                        txtDesignFirstName: item.firstName,
                        txtDesignLastName: item.lastName,
                        txtDesignTitle: item.title,
                        txtDesignEmail: item.email,
                        txtDesignTelNo: item.phoneNumber
                    })
                    i = i + 1
                } else {
                    that.state.designEngineerDataToShow.push(item)
                    //that.state.totalRows = that.state.totalRows+1
                }*/
            }
        })

        console.log("that.state.designEngineerDataToShow: "+that.state.designEngineerDataToShow)


        res.data.customerContacts.filter((data) => data.contactTypeObject.codeValue === "Legal Officer").map(data => (
            this.setState({
                txtOfficialFirstName: data.firstName,
                txtOfficialLastName: data.lastName,
                txtOfficialTitle: data.title,
                txtOfficialEmail: data.email,
                txtOfficialTelNo: data.phoneNumber
            })
        ))

        res.data.customerContacts.filter((data) => data.contactTypeObject.codeValue === "Certifying Officer").map(data => (
            this.setState({
                txtOfficialCertFirstName: data.firstName,
                txtOfficialCertLastName: data.lastName,
                txtOfficialCertTitle: data.title,
                txtOfficialCertEmail: data.email,
                txtOfficialCertTelNo: data.phoneNumber
            })
        ))


        res.data.customerAddresses.map(function(item) {
            if(item.addressTypeObject.codeValue === "Physical Address"){
                that.state.physicalAddressData = item
            }else if(item.addressTypeObject.codeValue === "Mailing Address"){
                that.state.mailingAddressData = item
            }else if(item.addressTypeObject.codeValue === "Shipping Address"){
                that.state.shippingAddressData = item
            }
        })




        this.state.invoicingList = res.data.customerInvoicingList[0]

        /*txtPayingOrgName
        txtPayingWebUrl
        txtPayingAddr1
        txtPayingAddr2
        txtPayingCity
        txtPayingState
        txtPayingZip
        ddlPayingCountry
        */



        let inv_arr = [];

        console.log("res.data.customerInvoicingList: "+JSON.stringify(res.data.customerInvoicingList))
        let ddlInvoicingMethodSelected = this.state.ddlInvoicingMethodSelected;
        console.log("invoicingMethodMap: "+JSON.stringify(this.state.invoicingMethodMap))

        if(res.data.customerInvoicingList.length > 0) {
            res.data.customerInvoicingList.map(function (item) {
                //res.data.customerInvoicingList[0].emailAddress != null
                if (item.invoicingMethod == that.state.invoicingMethodMap["Email"]) {
                    if (ddlInvoicingMethodSelected.length > 0)
                        that.HandlePlusButton();
                    inv_arr.push('Email');
                    ddlInvoicingMethodSelected[ddlInvoicingMethodSelected.length] = 'Email';
                    that.state.invoicingIdMap["Email"] = item["invoicingId"];
                    that.setState({
                        txtInvEmail: item["emailAddress"]!=null?item["emailAddress"]:'',

                    })
                }
                if (item.invoicingMethod == that.state.invoicingMethodMap["Fax"]) {
                    if (ddlInvoicingMethodSelected.length > 0)
                        that.HandlePlusButton();
                    inv_arr.push('Fax');
                    ddlInvoicingMethodSelected[ddlInvoicingMethodSelected.length] = 'Fax';
                    that.state.invoicingIdMap["Fax"] = item["invoicingId"];
                    that.setState({
                        txtInvFaxNumber: item["faxNumber"]!=null?item["faxNumber"]:'',
                        txtInvFaxAttentionName: item["attentionName"]!=null?item["attentionName"]:''
                    })
                }
                if (item.invoicingMethod == that.state.invoicingMethodMap["Mail"]) {
                    if (ddlInvoicingMethodSelected.length > 0)
                        that.HandlePlusButton();
                    inv_arr.push('Mail');
                    ddlInvoicingMethodSelected[ddlInvoicingMethodSelected.length] = 'Mail';
                    that.state.invoicingIdMap["Mail"] = item["invoicingId"];
                    that.setState({
                        txtInvCorpName: item["corporateName"]!=null?item["corporateName"]:'',
                        txtInvAddr1: item["addressLine1"]!=null?item["addressLine1"]:'',
                        txtInvAddr2: item["addressLine2"]!=null?item["addressLine2"]:'',
                        txtInvCity: item["city"]!=null?item["city"]:'',
                        txtInvState: item["stateProvince"]!=null?item["stateProvince"]:'',
                        txtInvZip: item["zip"]!=null?item["zip"]:'',
                        ddlInvCountry: item["country"]!=null?item["country"]:''
                    })
                }
                if (item.invoicingMethod == that.state.invoicingMethodMap["System"]) {
                    if (ddlInvoicingMethodSelected.length > 0)
                        that.HandlePlusButton();
                    inv_arr.push('System');
                    ddlInvoicingMethodSelected[ddlInvoicingMethodSelected.length] = 'System';
                    that.state.invoicingIdMap["System"] = item["invoicingId"];
                    that.setState({
                        txtInvSystemName: item["systemName"]!=null?item["systemName"]:'',
                        txtInvSystemUrl: item["url"]!=null?item["url"]:''
                    })
                }
            })
        }



        this.setState({ ddlInvoicingMethodSelected: ddlInvoicingMethodSelected })
        inv_arr.forEach((element, index) => {
            document.getElementById(index).value = element;
        });

        // res.data.customerInvoicingList.filter((data) => data.emailAddress !== null && data.contactTypeObject.codeName === "Legal Officer").map(data => (
        //     this.setState({
        //         txtOfficialFirstName: data.firstName,
        //         txtOfficialLastName: data.lastName,
        //         txtOfficialTitle: data.title,
        //         txtOfficialEmail: data.email,
        //         txtOfficialTelNo: data.phoneNumber
        //     })
        // ))


            //console.log("DATE: "+new Date(res.data.accountStartDate))
        let date = new Date(res.data.accountStartDate);
        let accountStartDate = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear()
        console.log("accountStartDate: "+accountStartDate)
        this.setState({
            txtCustAccNo: res.data.accountNumber,
            txtAccStartDate: accountStartDate!='' && accountStartDate!='0NaN/0NaN/NaN'?accountStartDate:'',
            txtFederalTaxIdNo: res.data.tinNumber,
            txtWebUrl: res.data.url,
            txtOrgName: res.data.customerName,
            ddlMailingAsPhysical: res.data.isMailingSame,
            ddlShippingAsPhysical: res.data.isShippingSame,
            accountType: res.data.accountType,


            txtPayingOrgName: res.data["customerThirdParty"]? res.data.customerThirdParty["organizationName"]:'',
            txtPayingWebUrl: res.data["customerThirdParty"]? res.data.customerThirdParty["url"]:'',
            txtPayingAddr1: res.data["customerThirdParty"]? res.data.customerThirdParty["addressLine1"]:'',
            txtPayingAddr2: res.data["customerThirdParty"]? res.data.customerThirdParty["addressLine2"]:'',
            txtPayingCity: res.data["customerThirdParty"]? res.data.customerThirdParty["city"]:'',
            txtPayingState: res.data["customerThirdParty"]? res.data.customerThirdParty["state"]:'',
            txtPayingZip: res.data["customerThirdParty"]? res.data.customerThirdParty["zip"]:'',
            ddlPayingCountry: res.data["customerThirdParty"]? res.data.customerThirdParty["country"]:'',
            ddlPayingOrgOption: res.data["customerThirdParty"]? "Yes":"No",

                //ddlPhysicalCountry: 'United States',
            /*txtInvAddr1: res.data.customerInvoicingList.length>0? res.data.customerInvoicingList[0].addressLine1:'',
            txtInvAddr2: res.data.customerInvoicingList.length>0? res.data.customerInvoicingList[0].addressLine2:'',
            txtInvCity: res.data.customerInvoicingList.length>0? res.data.customerInvoicingList[0].city:'',
            txtInvEmail: res.data.customerInvoicingList.length>0? res.data.customerInvoicingList[0].emailAddress:'',
            txtInvFaxAttentionName: res.data.customerInvoicingList.length>0? res.data.customerInvoicingList[0].faxNumber:'',
            txtInvState: res.data.customerInvoicingList.length>0? res.data.customerInvoicingList[0].stateProvince:'',
            txtInvSystemName: res.data.customerInvoicingList.length>0? res.data.customerInvoicingList[0].systemName:'',
            txtInvSystemUrl: res.data.customerInvoicingList.length>0? res.data.customerInvoicingList[0].url:'',
            txtInvZip: res.data.customerInvoicingList.length>0? res.data.customerInvoicingList[0].zip:'',
            ddlInvCountry: res.data.customerInvoicingList.length>0? res.data.customerInvoicingList[0].country:'',*/
        })

        //for addresses - shipping, mailing and physical
        res.data.customerAddresses.filter((data) => data.addressTypeObject.codeValue === "Physical Address").map(data => (
            this.setState({
                txtPhysicalAddr1: data.addressLine1,
                txtPhysicalAddr2: data.addressLine2,
                txtPhysicalCity: data.city,
                txtPhysicalState: data.state,
                txtPhysicalZip: data.zip,
                ddlPhysicalCountry: data.country
            })
        ))

        console.log("this.state.ddlMailingAsPhysical: "+this.state.ddlMailingAsPhysical)
        if(this.state.ddlMailingAsPhysical == 'N') {
            res.data.customerAddresses.filter((data) => data.addressTypeObject.codeValue === "Mailing Address").map(data => (
                this.setState({
                    txtMailingAddr1: data.addressLine1,
                    txtMailingAddr2: data.addressLine2,
                    txtMailingCity: data.city,
                    txtMailingState: data.state,
                    txtMailingZip: data.zip,
                    ddlMailingCountry: data.country
                })
            ))
        }

        console.log("this.state.ddlShippingAsPhysical: "+this.state.ddlShippingAsPhysical)
        if(this.state.ddlShippingAsPhysical == 'N') {
            res.data.customerAddresses.filter((data) => data.addressTypeObject.codeValue === "Shipping Address").map(data => (
                this.setState({
                    txtShippingAddr1: data.addressLine1,
                    txtShippingAddr2: data.addressLine2,
                    txtShippingCity: data.city,
                    txtShippingState: data.state,
                    txtShippingZip: data.zip,
                    ddlShippingCountry: data.country
                })
            ))
        }

     /*   if(ddlMailingAsPhysical == "Y"){

        }

        if(ddlShippingAsPhysical == "Y"){

        }*/

        console.log('Data->>', res.data)
    }

    HandleSuccessPostDesignResponse = (res) => {

        this.setState({
            result: res.data,
            postDesignData: false,
            txtCustAccNo: '',
            txtAccStartDate: '',
            ddlCustAccType: '',
            txtFirstName: '',
            txtLastName: '',
            ddlPriprity: '',
            txtFederalTaxIdNo: '',
            txtWebUrl: '',
            ddlMailingAsPhysical: '',
            ddlShippingAsPhysical: '',
            txtPayingAddr1: '',
            txtPayingAddr2: '',
            txtPayingCity: '',
            txtPayingState: '',
            txtPayingZip: '',
            txtInvCorpName:'',
            txtInvAddr1: '',
            txtInvAddr2: '',
            txtInvCity: '',
            ddlInvCountry: '',
            txtInvEmail: '',
            txtInvFaxNumber: '',
            ddlInvoicingMethod: [],
            txtInvState: '',
            txtInvSystemName: '',
            txtInvSystemUrl: '',
            txtInvZip: '',
            redirectToMainPage: true
        })

        ToastsStore.success("Successfully Updated.");
        console.log("componentprops: "+JSON.stringify(this.props.componentprops))

        global.foo = "Successfully Updated.";
        setTimeout(function() { //Start the timer
            this.props.componentprops.history.push("/dashboard");
        }.bind(this), 2000)


    }

    redirectPage = () =>{
        console.log("inside redirectPage==================================")
        this.props.componentprops.history.push("/dashboard");
    }


    render() {
        console.log(this.state.ddlInvoicingMethodSelected)
        return (
            <div>
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_CENTER} />

                {/*{this.state.redirectToMainPage ?
                    <DashboardCtrl {...this.props} state={this.state}/>:''
                }*/}

                {this.state.redirectToMainPage == false ?
                    <AccManagementComponent {...this.props} state={this.state}
                                            HandleChange={this.HandleChange} AddNewRows={this.AddNewRows}
                                            createUI={this.createUI} removeClick={this.removeClick}
                                            countries={this.state.countrieslist}
                                            accounttypes={this.state.accounttypes}
                                            priority={this.state.priority}
                                            invoicingMethod={this.state.invoicingMethod}
                                            HandlePlusButton={this.HandlePlusButton}
                                            HandleOnBlur={this.HandleOnBlur}
                                            HandleAccStartDateChange={this.HandleAccStartDateChange}
                                            MappingShippingAsPhysical={this.MappingShippingAsPhysical}
                                            MappingMailingAsPhysical={this.MappingMailingAsPhysical}
                                            SubmitDesign={this.SubmitDesign}
                                            createUI1={this.createUI1}
                                            removeClick1={this.removeClick}
                                            HandleRowValChange={this.HandleRowValChange}
                                            AddDefaultRows={this.AddDefaultRows}/>:''
                }

                {this.state.fetchInvoicingMethod === true ? GetInvoicingMethod(this.props.PrStore, this.HandleSuccessGetInvoicingMethod) : ''}
                {this.state.fetchCountries === true ? GetCountryList(this.props.PrStore, this.HandleSuccessGetCountriesResponse) : ''}

                {this.state.fetchContactType === true ? GetContactTypeList(this.props.PrStore, this.HandleSuccessGetContactTypeListResponse) : ''}
                {this.state.fetchAddressType === true ? GetAddressTypeList(this.props.PrStore, this.HandleSuccessGetAddressTypeListResponse) : ''}
                {this.state.fetchAccountTypes === true ? GetAccountTypes(this.props.PrStore, this.HandleSuccessGetAccountTypesResponse) : ''}
                {this.state.fetchPriority === true ? GetPriority(this.props.PrStore, this.HandleSuccessGetPriority) : ''}
                {this.state.postDesignData === true ? PostDesignData(this.props.PrStore, this.state.customerData, this.HandleSuccessPostDesignResponse) : ''}
                {this.state.custData === true ? getCustomerData(this.props.PrStore, this.HandleSuccessGetCustomer, 254) : ''}
            </div>
        )
    }
}
export const AccManagementCtrl = PrConnect(accManagementCtrl, '');