import React, { Component } from 'react';
import { PrConnect } from '../../library/pr-miniredux';
import $ from 'jquery';
import { GetAllowedRoutes } from '../../services/users';
import menuItems from './menuItems.json';
// import menus from './menuitems';

export class dashboardHeaderComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetchRoutes: false
        }
        // this.allowedRoutes = ["Account Management Data", "Account Documents", "Foundry Services", "Quote Request", "Global Foundries MPW Quote", "TSMC MPW Quote", "Global Foundries Dedicated Quote Request", "Foundry IP (PDK)", "Foundry File Access Request", "Foundry File Download History", "Design Management", "Design Submission Request", "Design Fabrication Status", "Design Upload", "Design Upload History", "Export Questionnaire - Input", "Export Questionnaire - Certified", "Design Analysis Results", "University Support Program", "USP Design Upload", "USP Design Fabrication status"];
        this.allowedRoutes = [];
    }



    UNSAFE_componentWillMount = () => {

        menuItems["data"].sort(this.GetSortOrder("name")); //Pass the attribute to be sorted on
        for (var item in menuItems["data"]) {
            if(menuItems.data[item]["children"]){
                let children = menuItems.data[item]["children"].sort(this.GetSortOrder("name"));
            }
        }
        console.log("menuItems: "+JSON.stringify(menuItems["data"]))


        if (JSON.stringify(this.props.PrStore.GetData('allowedRoutes')) === '{}')
            this.setState({
                fetchRoutes: true
            })
        else{
            this.allowedRoutes = this.props.PrStore.GetData('allowedRoutes');
            this.setState({
            })
        }

        this.setState({
            menuItems: menuItems
        })


    }

    GetSortOrder = (prop) => {
        return function(a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        }
    }

    HandleSuccessGetRoutesResponse = (res) => {
        this.props.PrStore.SetData('allowedRoutes', res.data);
        this.allowedRoutes = res.data;
        this.setState({
            fetchRoutes: false
        })
    }

    componentDidMount = () => {
        console.log("Inside componentDidMount asad")
        // $('#sideNav').addClass('closed');
        // $('.navbar-side').animate({ left: '-300px' });
        // $('#page-wrapper').animate({ 'margin-left': '0' });

        $('.navbar-header').addClass('dash_nav');

        $('.navbar-header').addClass('top_nav_right_section');
        $('#sideNav').addClass('closed');


        $('.navbar-side').animate({ left: '0' });
        $('#page-wrapper').animate({ 'margin-left': '300px' });
    }

    ToogleMenu = () => {
        console.log("inside togglee")
        if ($('.navbar-header').hasClass('dash_nav')) {
            $('.navbar-header').removeClass('dash_nav');
            $('.mn_text').removeClass('menu_text_hide');
        }
        else {
            $('.navbar-header').addClass('dash_nav');
            $('.mn_text').addClass('menu_text_hide');
        }

        if ($('#sideNav').hasClass('closed')) {
            $('#sideNav').removeClass('closed');
            $('.navbar-side').animate({ left: '-300px' });
            $('#page-wrapper').animate({ 'margin-left': '0' });
            // $('.navbar-side').animate({ left: '0' });
            // $('#sideNav').removeClass('closed');
            // $('#page-wrapper').animate({ 'margin-left': '300px' });

        }
        else {
            $('.navbar-side').animate({ left: '0' });
            $('#sideNav').addClass('closed');
            $('#page-wrapper').animate({ 'margin-left': '300px' });


            // $('#sideNav').addClass('closed');
            // $('.navbar-side').animate({ left: '-300px' });
            // $('#page-wrapper').animate({ 'margin-left': '0' });
        }

        if ($('.navbar-header').hasClass('top_nav_right_section')) {
            $('.navbar-header').removeClass('top_nav_right_section');
        }
        else {
            $('.navbar-header').addClass('top_nav_right_section');
        }
    }

    HandleClick = (route) => {
        /*console.log(JSON.stringify(this.props.componentprops.history))
        this.props.componentprops.history.push("/" + route);
        $('#page-wrapper').animate({ 'margin-left': '300px' });
        $('.navbar-side').animate({ left: '0' });
        $('#sideNav').addClass('closed');*/

        this.props.componentprops.history.push("/" + route);
        $('#page-wrapper').animate({ 'margin-left': '300px' });
        $('.navbar-side').animate({ left: '0' });
        $('#sideNav').addClass('closed');

        /*console.log("ROUTE: "+route)
        console.log("this.props.componentprops.history: "+JSON.stringify(this.props.componentprops.history))

        let refresh = false;
        if(this.props.componentprops.history.location["pathname"] == "/"+route){
            console.log("Inside REFRESH")
            refresh = true;
        }
        this.props.componentprops.history.push("/" + route);
        console.log("this.props.componentprops.history: "+JSON.stringify(this.props.componentprops.history))
        if ($('.navbar-header').hasClass('dash_nav')) {
            $('.navbar-header').removeClass('dash_nav');
            //$('.mn_text').removeClass('menu_text_hide');
        }
        if ($('.navbar-header').hasClass('top_nav_right_section')) {
            $('.navbar-header').removeClass('top_nav_right_section');
        }

        if(refresh == true){
            window.location.reload(false);
        }else {
            $('#sideNav').removeClass('closed');
            $('.navbar-side').animate({left: '-300px'});
            $('#page-wrapper').animate({'margin-left': '0'});
        }*/
    }

    render() {
        return (
            <div>
                <nav className="navbar navbar-default top-navbar" role="navigation">
                    <div className="navbar-header" id="navbar-header">
                        <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".sidebar-collapse">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                        <a className="navbar-brand" onClick={() => this.HandleClick('dashboard')}>
                            <div className="brand_logo">
                                <img src={require('../../assets/img/logo-2.png')} alt="" />
                                <span className="serv_mosis">THE <span className="" style={{ color: '#f3b93e' }}>MOSIS</span> SERVICE</span>
                            </div>
                        </a>
                        {/* <div id="sideNav" href="#" className="closed" className="hamburger closed" onClick={this.ToogleMenu}> */}
                        <div id="sideNav" href="#" className="hamburger" onClick={this.ToogleMenu}>
                            <span></span>
                            <span></span>
                            <span></span>
                            <b className="mn_text menu_text_hide">Menu</b>
                        </div>
                    </div>
                    <ul className="nav navbar-top-links navbar-right" style={{ paddingRight: 20 }}>
                        {/* dropdown */}
                        <li className="dropdown">
                            {/*<a className="dropdown-toggle" data-toggle="dropdown" href="#" aria-expanded="false">
                                <span className="pro">G</span> <span style={{ color: '#000' }}></span>
                            </a>*/}
                            <ul className="dropdown-menu dropdown-user">
                                {/* <li><a href="#"><i className="fa fa-user fa-fw"></i> User Profile</a>
                        </li> */}
                                <li><a href="#"><i className="fa fa-lock" aria-hidden="true"></i> Change Password</a>
                                </li>
                                <li className="divider"></li>
                                <li><a href="/logout"><i className="fa fa-sign-out fa-fw"></i> Logout</a>
                                </li>
                            </ul>
                            {/* dropdown-user */}
                        </li>
                        <li className="dropdown">
                            <a href="/logout" className="logoutButton">
                                <span className="logout_img"><img src={require('../../assets/img/login.png')} /></span> Logout
                    </a>
                        </li>
                        {/* dropdown */}
                    </ul>
                </nav>
                {/* NAV TOP */}
                <nav className="navbar-default navbar-side" role="navigation" id="side_menu_top" style={{ left: -23 + 'vw' }}>
                    <div className="sidebar-collapse">
                        <ul className="nav" id="main-menu">
                            {menuItems.data.map(items =>
                                items.children !== undefined ?
                                    this.allowedRoutes.indexOf(items.name) >= 0 ?
                                        <li>
                                            <a><span>{items.name}</span></a>
                                            <ul className="nav nav-second-level">
                                                {items.children.map(child =>
                                                    this.allowedRoutes.indexOf(child.name) >= 0 ?
                                                        <li onClick={() => this.HandleClick(child.url)}>
                                                            <a>{child.name}</a>
                                                        </li>
                                                        : ''
                                                )}
                                            </ul>
                                        </li>
                                        : ''
                                    :
                                    this.allowedRoutes.indexOf(items.name) >= 0 ?
                                        <li onClick={() => this.HandleClick(items.url)}>
                                            <a>{items.name}</a>
                                        </li>
                                        : ''
                            )}
                        </ul>
                    </div>
                </nav>
                {this.state.fetchRoutes === true ? GetAllowedRoutes(this.props.PrStore, this.HandleSuccessGetRoutesResponse) : ''}
            </div>
        )
    }
}
export const DashboardHeaderComponent = PrConnect(dashboardHeaderComponent, '');


//============ removed ===========
/*{
                    "name": "Design Analysis Results",
                    "url": "dashboard"
                }
                {
                    "name": "Design Upload History",
                    "url": "dashboard"
                }*/

/*{
                    "name": "Design Fabrication Status",
                    "url": "dashboard/design-fabrication-status"
                },*/