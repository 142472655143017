import React, {Component} from 'react';
import { NavLink } from 'react-router-dom';

export default class UniversityComponent extends Component{
    render(){
        return(
            <div>
                <section className="university_bg">
                    <div class="container-fluid">
                        <div className="row">
                            <div className="col-4 mx-auto mb-5">
                                <div className="site-logo text-center">
                                <NavLink to="/" style={{position:'relative', zIndex:'9999999'}}>
                                    <img src={require('../../assets/images/logo.png')} alt="logo" />
                                </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="lrn_more row">
                            <div className="col-lg-10 mx-auto">
                                <div className="lrn_more_left">
                                    <h2 className="text-center">University Support Program</h2>
                                    <p>The MOSIS Service as an organization within the USC Viterbi School of Engineering, a top ranked engineering program, has always supported the commitment to engineering students. The MOSIS Service has provided support to numerous universities over the decades. The support has consisted of providing on a select basis free fabrication to domestic University professors and students. Over the years there have been a variety of other organizations which also supported this objective. In the past the NSF supported VLSI training. Later various industry organizations, and microelectronic industry corporations provided support. For the last several years all student fabrications costs have been covered solely by The MOSIS Service. Our recent support consisted of sponsoring fabrication with ON Semiconductor B5 0.5 micron process, formerly C5, for undergraduate designs.</p>
                                    <p>The University Support Program is being reformulated with an objective of providing more impactful support for advanced technology nodes. We are no longer sponsoring fabrication with ON Semiconductor. We look forward to further announcements on this subject.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}