import React, { Component } from 'react';


import HolidayNoticeComponent from './holidayNotice';
import $ from "jquery";
import {NavLink} from "react-router-dom";
//import { NavLink } from 'react-router-dom';

export default class HolidayNoticeCtrl extends Component {
    GoToLearnMore = (page) => {
        this.props.history.push("learn-more-" + page);
    }

    componentDidMount() {
        $('.container-fluid').css('display', 'none');
        $('.side_logo').css('display', 'none');


    }

    render() {
        return (

        <body >



        <div className="holidayNotice" style={{marginLeft: '-3px'}}>
            <div className="col-4 mx-auto" >
                <div className="site-logo text-center mb-5">
                    <NavLink to="/" style={{position: 'relative', zIndex: '2000'}}>
                        <img  style={{marginTop:'60px'}} src={require('../../assets/images/logo.png')} alt="logo"/>
                    </NavLink>
                </div>
            </div>
            <div className="inner-home">
                {/*<h2 style={{color:"#990000"}}>The MOSIS Service is down for scheduled maintenance right now.</h2>*/}

                <h2 style={{color:"#990000"}}>
                    Thank you for contacting The MOSIS Service. The MOSIS Service operations will be closed during the University's Winter Recess from  December 19, 2022, through January 2, 2023.
                </h2>
                {/*<h2 style={{color:"#990000"}}>The MOSIS Service will be closed during the University of Southern California Winter Recess.

                    The Winter Recess starts on Saturday December 19th and ends on Sunday January 3rd</h2>*/}
                {/*<p>Since 1981, A pioneer in Multi Project Wafer (MPW) fabrication services.</p>*/}
                {/*<div className="read_more text-center">
                    <a href="" onClick={()=>this.props.GoToLearnMore(1)} className="read_more_btn">Learn More</a>
                </div>*/}
            </div>
        </div>

        </body>

        )
    }
}