import React, { Component } from 'react';
import $ from 'jquery';
export default class ConfirmationPopup extends Component {
    ClearMouse = () => {
    $('.inner_table').css('display', 'none');
}
render() {
    return (
        <div id="page-wrapper1">
            <div id="page-inner1" style={{ background: '#fff' }}>
                <div className="page_head col-lg-20">
                    <div class="row" >
                        <div class="col-lg-25 col-md-25 mx-auto">
                                <div>
                                    <label>Are you sure you want to submit these Answers ?</label>
                                    <br/>
                                    <div style={{paddingLeft:"3%", marginLeft: "2.5em"}}>
                                        <label style={{fontWeight:"bold"}}>CONFIRM  </label>
                                        <input style={{marginLeft: "0.5em"}} type="radio" id="designChoice" name="designChoice" value="CONFIRM" onChange={this.props.handleRadioBoxChange}/>
                                        <label style={{marginLeft: "0.5em"}} for={"designChoice"} value="CONFIRM"></label>
                                        <label style={{marginLeft: "2.5em", fontWeight:"bold"}}>CANCEL  </label>
                                        <input style={{marginLeft: "0.5em"}} type="radio" id="designChoiceCancel" name="designChoice" value="CANCEL" onChange={this.props.handleRadioBoxChange}/>
                                        <label style={{marginLeft: "0.5em"}} for={"designChoiceCancel"} value="CANCEL"></label>


                                </div>
                        </div>

                    </div>
                </div>
            </div>
    </div>
    </div>
)
}
}