import React, { Component } from "react";
import FloatingLabelInput from "react-floating-label-input";
import DatePicker from 'react-date-picker';
import { NavLink } from 'react-router-dom';
import { TableDesignCtrlNew } from '../newtable';

export default class DynamicRowComponent extends Component {
  render() {
    return (
      <div>
        <div className="page_head col-lg-12 mx-auto">
          <div>
            <div className="account">
              <div className="accoutn_form">
                <form action="" method="">
                  <div className="dashboard_form">
                    <div className="inner-dashoard_form">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="brd_section">
                            <div className="row new_pos">
                              <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="inner_left">
                                  <label>
                                    This Export Control Questionnaire is being
                                    completed for the following MOSIS design
                                    number(s)
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="brd_section">
                            {this.props.state.rows.map((data, index) => (
                              <div className="row new_pos" key={`row-${index}`}>
                                <div className="col-lg-1 col-md-1 col-sm-3 col-xs-6">
                                  <div className="form-group mb-0">
                                    <input
                                      type="text"
                                      className="form-control"
                                      disabled
                                      name="disabled[]"
                                      value={data.disabled}
                                      placeholder="disabled"
                                      onChange={e =>
                                        this.props.HandleChange(
                                          e,
                                          index,
                                          "disabled"
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-3 col-xs-6">
                                  <div className="form-group mb-0">
                                    <textarea className="scroller_textarea"
                                      style={{
                                        height: "38px",
                                        borderRadius: ".4rem",
                                        overflow: "auto"
                                      }}
                                      name="textarea[]"
                                      value={data.textarea}
                                      placeholder="textarea"
                                      onChange={e =>
                                        this.props.HandleChange(
                                          e,
                                          index,
                                          "textarea"
                                        )
                                      }
                                      rows="2"
                                    ></textarea>
                                  </div>
                                </div>
                                <div className="col-lg-1 col-md-1 col-sm-3 col-xs-6">
                                  <div className="form-group mb-0">
                                    <input
                                      type="file"
                                      className="form-control"
                                      name="file[]"
                                      value={data.file}
                                      onChange={e =>
                                        this.props.HandleChange(
                                          e,
                                          index,
                                          "file"
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-1 col-md-1 col-sm-3 col-xs-6">
                                  <div className="form-group mb-0">
                                    <select
                                      className="form-control"
                                      name="select[]"
                                      value={data.select}
                                      onChange={e =>
                                        this.props.HandleChange(
                                          e,
                                          index,
                                          "select"
                                        )
                                      }
                                    >
                                      <option value=""> -select- </option>
                                      {this.props.state.selectData.length > 0 &&
                                        this.props.state.selectData.map(
                                          (sel, selindex) => (
                                            <option
                                              key={`sel-${index}-${selindex}`}
                                              value={sel.id}
                                            >
                                              {sel.name}
                                            </option>
                                          )
                                        )}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-lg-1 col-md-1 col-sm-3 col-xs-6">
                                  <NavLink className="tbl_link" data-to={data.link} to='/'>Click Here</NavLink>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-3 col-xs-6">
                                  <div className="form-group mb-0">
                                    <div className="dateParentDiv" id={`expectedDesignSubmissionDate-${index}`}>
                                      <DatePicker name="date[]"
                                        format="MM/dd/y"
                                        onChange={e => this.props.HandleChange(e, index, "date")}
                                        value={data.date} clearIcon={null}
                                        calendarIcon={<img src={require('../../assets/images/calendar.svg')} alt="calendar" width={14} />}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-1 col-md-1 col-sm-3 col-xs-6">
                                  <div className="form-group mb-0">
                                    <div
                                      className="floatingInnerDiv"
                                      style={{ height: "28px" }}
                                    >
                                      <FloatingLabelInput
                                        className="floatingText"
                                        id="number"
                                        name="number[]"
                                        value={data.number}
                                        placeholder="number input"
                                        onChange={e =>
                                          this.props.HandleChange(
                                            e,
                                            index,
                                            "number"
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-1 col-md-1 col-sm-3 col-xs-6">
                                  <div className="form-group mb-0">
                                    <div
                                      className="floatingInnerDiv"
                                      style={{ height: "28px" }}
                                    >
                                      <FloatingLabelInput
                                        className="floatingText"
                                        id="numberWithSign"
                                        name="numberWithSign[]"
                                        value={data.numberWithSign}
                                        placeholder="$ number"
                                        onChange={e =>
                                          this.props.HandleChange(
                                            e,
                                            index,
                                            "numberWithSign"
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-1 col-md-1 col-sm-3 col-xs-6">
                                  <div className="form-group mb-0">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="search[]"
                                      value={data.search}
                                      placeholder="search"
                                      onChange={e =>
                                        this.props.HandleChange(
                                          e,
                                          index,
                                          "search"
                                        )
                                      }
                                    />
                                    <div className="GeneralPurpose_Popup_table" style={{ top: "30px", left: "-100%" }}>
                                      {(data.search !== "") && <TableDesignCtrlNew data={data.search} />}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-1 col-md-1 col-sm-3 col-xs-6">
                                  {this.props.state.rows.length > 1 && (
                                    <a
                                      href="#"
                                      onClick={e =>
                                        this.props.deleteRow(e, index)
                                      }
                                    >
                                      <img
                                        src={require("../../assets/images/remove.png")}
                                      />
                                    </a>
                                  )}
                                  &nbsp;
                                  {this.props.state.rows.length === index + 1 &&
                                    this.props.state.rows.length !== 10 && (
                                      <a
                                        href="#"
                                        onClick={e => this.props.addRow(e)}
                                      >
                                        <img
                                          src={require("../../assets/images/plus.png")}
                                        />
                                      </a>
                                    )}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
