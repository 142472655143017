import React, { Component } from 'react';


import HomeComponent from './home';
import { NavLink } from 'react-router-dom';

export default class HomeCtrl extends Component {
    GoToLearnMore = (page) => {
        this.props.history.push("learn-more-" + page);
    }

    render() {
        return (
            <div>
            <div class="container" style={{marginTop:'20px'}}>
                <div className="row">
                    <div className="col-4 mx-auto">
                        <div className="site-logo text-center mb-5">
                            <NavLink to="/" style={{position:'relative', zIndex:'2000'}}>
                                <img src={require('../../assets/images/logo.png')} alt="logo" />
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
                <HomeComponent {...this.props} GoToLearnMore={this.GoToLearnMore} />
            </div>
        )
    }
}