import { PrAjax } from '../library/ajax/pr-ajax';

export function GetDesignStatus(store, successFunc) {
    let loader = { isLoader: false, containerId: 'testpr', size: 'small' };
    // if (!(store.GetData('citylist').length > 0)) {
    return PrAjax(store).Get('codes/codesByCodeType/M_Design_Status').AttachLoader(loader).OnSuccess(successFunc).Do();
    // }
    // else return successFunc(store.GetData('citylist'), store);
}

export function GetDesignNumbers(store, successFunc,status) {
    let loader = { isLoader: false, containerId: 'testpr', size: 'small' };
    // if (!(store.GetData('citylist').length > 0)) {
    return PrAjax(store).Get('designmilestone/getDesignNumbers?status='+status).AttachLoader(loader).OnSuccess(successFunc).Do();
    // }
    // else return successFunc(store.GetData('citylist'), store);
}


export function GetDesignInfoNumbers(store, successFunc,number) {
    let loader = { isLoader: false, containerId: 'testpr', size: 'small' };
    // if (!(store.GetData('citylist').length > 0)) {
    return PrAjax(store).Get('designmilestone/findAllByDesignNumberInfo?designInfoNumber='+number).AttachLoader(loader).OnSuccess(successFunc).Do();
    // }
    // else return successFunc(store.GetData('citylist'), store);
}
