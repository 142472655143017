import React, { Component } from 'react';

import { NavLink } from 'react-router-dom';
import { PrConnect } from '../../../library/pr-miniredux';
import ReactHtmlParser from 'react-html-parser';
import spieFile from '../../../assets/docs/PressReleaseDocs/116140J.pdf'
import snugWorldFile from '../../../assets/docs/PressReleaseDocs/MOSIS SNUG World 2021.pdf'
import $ from "jquery";
import {PdfViewerCtrl} from "./PdfViewer";
/*import {PdfViewerScreenCtrl} from "./PdfViewerScreen";*/
import closeImg from "../../../assets/images/close.svg";
import DesignUpload from "../../Dashboard/Test/DesignUpload";

export class readMorePressRelease extends Component {
    constructor(props) {
        super(props)
        this.state = {
            readMoreData: {},
            showPdfFile: false,
            pdfFileToShow:'',
            windowHeight:'',
            windowWidth:''
        };
    }
    componentWillMount = () => {
        if (JSON.stringify(this.props.PrStore.GetData('pressreleasedata')) !== '{}')
            (this.props.PrStore.GetData('pressreleasedata')).forEach(element => {
                if ((element.pressReleaseDate).includes(this.props.componentprops.match.params.date)) {
                    //  alert(JSON.stringify(element.mpressReleaseDetailsObject[0].pressReleaseDetail));
                    this.setState({
                        readMoreData: element.mpressReleaseDetailsObject
                    })
                }
            });

        else {
            this.GoBack()
        }
    }

    componentDidMount = () => {
        if(document.getElementById("spieFile") != null){
            //document.getElementById("spieFile").href = spieFile+"#toolbar=0"
            $('#toolbar').css('display', 'none');
            //document.getElementById("spieFile").href = spieFile;
            let that = this
            document.getElementById("spieFile").style.color = "#990100"
            document.getElementById("spieFile").onclick = function() {console.log("Inside open link")
                that.setState({
                    pdfFileToShow: spieFile,
                    showPdfFile: true
                })
                console.log("pdfFileToShow: "+that.state.pdfFileToShow)

            };
            //document.getElementById("toolbar").css.display = "none";
        }

        if(document.getElementById("snugWorldFile") != null){
            $('#toolbar').css('display', 'none');
            let that = this
            document.getElementById("snugWorldFile").style.color = "#990100"
            document.getElementById("snugWorldFile").onclick = function() {console.log("Inside open link")
                that.setState({
                    pdfFileToShow: snugWorldFile,
                    windowHeight:1000,
                    windowWidth:1000,
                    showPdfFile: true
                })
                console.log("pdfFileToShow: "+that.state.pdfFileToShow)

            };
        }

        var paraLines = document.getElementsByClassName("paraLines")

        for (var i = 0; i < paraLines.length; i++) {
            paraLines[i].style.paddingTop = "5px";
        }

        document.getElementById("footerSectionId").style.position = "absolute"
    }

    GoBack = () => {
        this.props.componentprops.history.push('/press-release')
    }

    downloadFile = (fileName) => {
        console.log("Inside download File: "+fileName)
    }

    openLink = () => {
        console.log("Inside open link")
        this.setState({
            showPdfFile: true
        })
    }

    ShowHideModal = () => {
        //$(".popupTop").parent('div').addClass('popupbody');
        console.log("INSIDE ShowHideModal "+this.state.showPdfFile)
        this.setState({
            showPdfFile: !this.state.showPdfFile

        });
        //$(".popupTop").parent('div').addClass('popupbody');
    }

    render() {
        // alert(JSON.stringify(this.props));
        // alert(this.state.readMoreData);
        return (
            <div>

                <section className="press-release press_release_bg">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-4 mx-auto">
                                    <div className="site-logo text-center pb-5">
                                        <NavLink to="/" style={{position:'relative', zIndex:'2000'}}>
                                            <img src={require('../../../assets/images/logo.png')} alt="logo" />
                                        </NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-10 mx-auto">
                                    <div className="text-left">
                                        <button type="button" className="ml-1 btn btn-md btn_sm_red" onClick={this.GoBack}>Go Back</button>
                                    </div>
                                </div>
                            </div>

                            <div className="row"  style={{ marginBottom: 200, paddingTop: "2%" }}>
                                {this.state.readMoreData && JSON.stringify(this.state.readMoreData) !== '{}' ? this.state.readMoreData.map(ReleaseData =>
                                    <div className="col-lg-10 mx-auto">
                                        <div className="mosis_hdng">
                                            <p className="text-left ">{ReleaseData.pressReleaseSubHeader}</p>
                                        </div>
                                        <div className="text-left">
                                            {ReleaseData.pressReleaseDetail ? ReleaseData.pressReleaseDetail.split("\n").map((i, key) =>
                                                <p className="paraLines" style={{ textAlign: 'justify', paddingTop: '10px !important' }} key={key}>{ReactHtmlParser (i)}</p>
                                            ) : ''}
                                        </div>
                                    </div>
                                ) : ''}
                            </div>

                            {/* <div className="row">
                                <div className="col-lg-10 mx-auto">
                                    <div className="mosis_hdng">
                                        <p className="text-left ">About the Technology</p>
                                    </div>
                                    <div className="text-left">
                                        <p className="p-0"><span>The Samsung-MOSIS collaboration involves, for now, the 28nm FD-SOI, eMRAM based on 28nm FD-SOI, 65nm eFlash Bulk CMOS, and 130nm CMOS semiconductor manufacturing processes. For each of these technologies, there are available many application-dependent options, mature process development kits, and comprehensive libraries (offered either by Samsung, or 3rd party vendors) for both “soft” (high-level synthesis) and “physical” (back-end) intellectual property modules that designers can use to realize their integrated circuits.</span></p>
                                        <p className="p-0"><span>Whereas 14nm FinFET is a hot topic at the moment, there are other advanced process technologies that designers need, particularly, if the application requires special considerations in the chip design (e.g., ultra-low power). For example, Samsung’s 28nm FD-SOI offers these options. FD-SOI is one of those unique technologies that allows for the continuation of Moore’s Law with an upgrade to traditional planar semiconductor process technology. Samsung’s particular version of FD-SOI delivers a nice balance of higher performance with low power and is well suited for a variety of defense and commercial application areas (RF, mobile, IOT, sensors, automotive, controllers, etc.).</span></p>
                                        <p className="p-0"><span>As eFlash has faced scalability challenges due to a charge storage-based operation, Samsung’s eMRAM is the most promising successor, since its resistance-based operation allows strong scalability. The memory also possesses outstanding technical characteristics, such as non-volatility, random access, and strong endurance. The combination of Samsung’s 28nm FD-SOI with eMRAM offers solutions of unprecedented power and speed advantages with lower cost and better transistor control and leakage minimization. Overall, Samsung’s eMRAM solutions provide differentiated benefits for a variety of applications including microcontroller units, internet of things, artificial intelligence, sensors, etc.; it can be easily integrated into the design of chips using existing design flows.</span></p>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-10 mx-auto">
                                    <div className="mosis_hdng">
                                        <p className="text-left ">About USC Viterbi School of Engineering</p>
                                    </div>
                                    <div className="text-left">
                                        <p className="p-0"><span>Engineering Studies began at the University of Southern California in 1905. Nearly a century later, in 2004, the Viterbi School of Engineering received a naming gift from alumnus Andrew J. Viterbi, inventor of the Viterbi algorithm, now the key to cell phone technology and numerous data applications.</span></p>
                                        <p className="p-0"><span>The school’s guiding principle is Engineering-plus, a coined termed by current Dean Yannis C. Yortsos, to use the power of engineering to address the world’s greatest challenges. USC Viterbi is ranked among the top engineering programs in the world and enrolls more than 6,500 undergraduate and graduate students taught by 185 tenured and tenure-track faculty, with 73 endowed chairs and professorships.</span></p>
                                        <p className="p-0"><span>The USC Viterbi School of Engineering offers executive and continuing education programs, designed for non-degree seeking professionals. Program participants can take advantage of the flexibility and interactivity offered by the Viterbi School, by taking continuing education courses on USC's campus, completely online via DEN@Viterbi or on-site at an organization’s location. http://viterbi.usc.edu/</span></p>
                                    </div>
                                </div>
                            </div> */}
                        </div>

                </section>

                {this.state.showPdfFile ?
                    <div className="popupbody">
                        <div className="popupTop" >
                            <div className='popup'>
                                <div className='popup_inner1'>
                                    <PdfViewerCtrl ShowHideModal={this.ShowHideModal} {...this.props} state={this.state}
                                                   pdfFileToShow={this.state.pdfFileToShow} windowWidth={this.state.windowWidth} windowHeight={this.state.windowHeight}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''
                }

                {/*{this.state.showPdfFile ?
                                    <PdfViewerScreenCtrl ShowHideModal={this.ShowHideModal}/>
                    : ''
                } */}
            </div>


        )
    }
}
export const ReadMorePressRelease = PrConnect(readMorePressRelease, '');