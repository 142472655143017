import { PrAjax } from '../library/ajax/pr-ajax';

export function GetDocumentCategoryListByCustomerId(store, customer_id, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('customerFileDownload/getGroupsForCustomer/').AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function GetAllDocumentListDocumentIdOrNodetId(store, customer_id, selectedData, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    /*if(selectedData.documentId != null){
        return PrAjax(store).Get('customerFileDownload/findByDocumentId/'+selectedData.documentId).AttachLoader(loader).OnSuccess(successFunc).Do();
    }*/
    if(selectedData.nodeId != null){
        return PrAjax(store).Get('customerFileDownload/getDocuments/'+selectedData.nodeId).AttachLoader(loader).OnSuccess(successFunc).Do();
    }else{
        return PrAjax(store).Get('customerFileDownload/getDocuments/'+selectedData.templateDetailList[0].nodeId).AttachLoader(loader).OnSuccess(successFunc).Do();
    }
}

export function GetAllDocumentCategoryList(store, successFunc){
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('customerFileDownload/getGroupsForCustomer').AttachLoader(loader).OnSuccess(successFunc).Do();
}