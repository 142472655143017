import React, { Component } from 'react';
import Popup from './popup';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
//import { GetTechProcessFoundry } from '../../../services/foundrypdk';

import { getDesignUploadQuestionGroup, getQuestionsByTechClass, getFoundryAndTechClass, getFoundryAndTechClassByCustomerId } from '../../../../services/designUploadQuestion';
import { PrConnect } from '../../../../library/pr-miniredux';
import {ServicesDesignFileUploadComponentCtrl} from '../../../ServicesDesignFileUpload/ServicesDesignFileUpload'

export class PopupCtrl extends Component {
    constructor(props) {
        super(props);
        console.log("props");
        console.log(props);
        this.state = {
            radioBoxValue:'',
            fetchQuetionAnswers:false,
            fileUploadRedirect: "/services-design-file-upload",
            designUploadRedirect: "/test-services",
            showFileUploadSelection: false,
            redirectNow:false,
            showFileUploadMessage:false

        };
        this.handleRadioBoxChange = this.handleRadioBoxChange.bind(this);
    }


    UNSAFE_componentWillMount = () => {
        console.log("Inside Popup Class")
        if(this.props.componentprops.designFileSize != null){
            this.setState({
                showFileUploadMessage: false
            })
            console.log(this.props.componentprops.designFileSize)
        }else if(this.props.componentprops.fileTusId != null){
            this.setState({
                showFileUploadMessage: false
            })
        }else{
            this.setState({
                showFileUploadMessage: true
            })
        }
        console.log("showFileUploadMessage: "+this.state.showFileUploadMessage)

    };

    handleRadioBoxChange = (e) => {
        console.log("inside radio change: "+e.target.value);
        /*if(e.target.value == "USE"){
            this.setState({
                showFileUploadSelection:true
            })

        }else{
            this.state.showFileUploadSelection = false;
            this.props.componentprops.HandleEditSelection(e);
        }*/
        //console.log("inside radio change: "+JSON.stringify(this.props));
        this.props.componentprops.HandleEditSelection(e);


    }

    handleFileUploadSelection = (e) => {
        if(e.target.value == "YES"){
            this.props.componentprops.showFileUpload(true);
            
            //window.location = this.state.fileUploadRedirect;
        }else{
            window.location = this.state.designUploadRedirect;
        }
    }


    render() {

        return (


            <div>
                    <Popup {...this.props} state={this.state} handleRadioBoxChange={this.handleRadioBoxChange}
                           HandleEditSelection={this.HandleEditSelection}
                           showFileUploadSelection={this.state.showFileUploadSelection}
                           showFileUpload={this.showFileUpload}
                           handleFileUploadSelection={this.handleFileUploadSelection}
                           showFileUploadMessage={this.state.showFileUploadMessage}/>
                
            </div>

        )
    }
}
export default PopupCtrl = PrConnect(PopupCtrl, '');