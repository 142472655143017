import {PrAjax} from "../library/ajax/pr-ajax";

export function GetCodesByCodeType(store, codeTypeName, successFunc) {
    let loader = { isLoader: false, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('codes/codesByCodeType/'+codeTypeName).AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function GetCodesByCodeId(store, codeId, successFunc) {
    let loader = { isLoader: false, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('codes/id/'+codeId).AttachLoader(loader).OnSuccess(successFunc).Do();
}
