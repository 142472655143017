import React, { Component } from 'react';
import FoundaryService from './foundaryservice';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import $ from 'jquery';

import { PrConnect } from '../../../library/pr-miniredux';
import { GetFoundriesList, GetTechListByFoundry, GetScheduleByFoundryAndTech, GetMilestoneByFoundryAndShipDate } from '../../../services/users';
export class foundryServicesCtrl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetchFoundries: false,
            foundriesList: {},
            fetchTechNode: false,
            techNodesList: {},
            fetchSchedule: false,
            scheduleList: {},
            fetchMilestone: false,
            milestoneList: {},
            milestoneDate: '',
            milestoneId: '',
            ddlTechNode: '',
            ddlFoundry: '',
            fabScheduleId: '',
            foundryName: '',
            techNodeName: '',
            td_width: '25%',
            largestNumberOfMonths: 0
        }
    }

    UNSAFE_componentWillMount = () => {
        if (JSON.stringify(this.state.foundriesList === '{}')) {
            let that = this;
            that.setState({
                fetchFoundries: true
            });
        }
    }

    HandleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.name === 'ddlFoundry') {
            let foundrynames = '';
            (this.state.foundriesList).forEach(function (element, index) {
                // alert(element.codeName);
                if (element.codeId == e.target.value) {
                    foundrynames = element.codeName + " Fab Schedule"
                }
            });
            console.log("this.target.value: "+e.target.value)
            if(e.target.value != ''){
                this.setState({
                    fetchTechNode: true,
                    foundryName: foundrynames,
                    techNodeName: '',
                    scheduleList: {}
                });
            }else{
                this.setState({
                    foundryName: foundrynames,
                    techNodeName: '',
                    scheduleList: {},
                    techNodesList: {}
                });

            }

        }
        else if (e.target.name === 'ddlTechNode') {
            let technodenames = '';
            (this.state.techNodesList).forEach(function (element, index) {
                // alert(element.codeName);
                if (element.codeId == e.target.value) {
                    technodenames = "For " + element.codeValue
                }
            });
            if(e.target.value != ''){
                this.setState({
                    fetchSchedule: true,
                    techNodeName: technodenames,
                    scheduleList: {}
                });
            }else{
                this.setState({
                    techNodeName: technodenames,
                    scheduleList: {}
                });
            }

        }
    }

    HandleSuccessGetFoundriesResponse = (res) => {
        this.setState({
            fetchFoundries: false,
            foundriesList: res.data
        })
    }

    HandleSuccessGetTechNodesResponse = (res) => {
        res.data.sort(this.GetSortOrder("field1"));
        this.setState({
            fetchTechNode: false,
            techNodesList: res.data
        })
        console.log("techNodesList: "+JSON.stringify(this.state.techNodesList))
    }

    GetSortOrder = (prop) => {
        return function(a, b) {
            if (Number(a[prop]) > Number(b[prop])) {
                return 1;
            } else if (Number(a[prop]) < Number(b[prop])) {
                return -1;
            }
            return 0;
        }
    }

    HandleSuccessGetScheduleResponse = (res) => {
        let data_arr = [];
        let dates = [];
        let months = [];
        let years = [];
        let month_name = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

        res.data.forEach(function (element, index) {
            //data_arr.push({ 'foundryFabScheduleId': element.foundryFabScheduleId, 'runId': element.runId, 'shipDate': element.shipToMosisDateParsed });
            console.log("element.tapeoutDateParsed: "+element.tapeoutDateParsed)
            console.log("element.shipToMosisDateParsed: "+element.shipToMosisDateParsed)
            data_arr.push({ 'foundryFabScheduleId': element.foundryFabScheduleId, 'runId': element.runId, 'shipDate': element.tapeoutDateParsed });
            //dates.push(element.shipToMosisDateParsed);
            dates.push(element.tapeoutDateParsed);

            // alert(element.shipToMosisDateParsed)
            /*let mon_no = (element.shipToMosisDateParsed).slice(0, 2);
            let year_no = (element.shipToMosisDateParsed).slice(6, 10);*/

            let mon_no = (element.tapeoutDateParsed).slice(0, 2);
            let year_no = (element.tapeoutDateParsed).slice(6, 10);
            if (!months.includes(mon_no))
                months.push(mon_no);
            if (!years.includes(year_no))
                years.push(year_no);
            // months.push((element.shipToMosisDateParsed).substr(0, (element.shipToMosisDateParsed).indexOf('/')));
        });
        dates.sort();
        months.sort();
        years.sort();
        let f_arr = [];
        let max_data = 0;

        months.forEach(function (element, index) {
            let max_month_data = 0;
            let f_month_arr = [];
            let m_name = month_name[element - 1];
            // alert(m_name + "--" + element);
            data_arr.forEach(function (elem, i) {
                if (element === (elem.shipDate).slice(0, 2)) {
                    max_month_data++;
                }
            });
            if (max_month_data > max_data)
                max_data = max_month_data
        });
        data_arr.sort((a,b) => (a.shipDate > b.shipDate) ? 1 : ((b.shipDate > a.shipDate) ? -1 : 0));
        years.forEach(function (year, i) {
            let f_year_arr = [];
            months.forEach(function (element, index) {
                let max_month_data = 0;
                let f_month_arr = [];
                let m_name = month_name[element - 1];
                // alert(m_name + "--" + element);
                data_arr.forEach(function (elem, i) {
                    let day = (elem.shipDate).slice(0, 2);
                    let mon = (elem.shipDate).slice(3, 5);
                    let years = (elem.shipDate).slice(6, 10);
                    if (year === years) {
                        if (element === (elem.shipDate).slice(0, 2)) {
                            max_month_data++;
                            f_month_arr.push({ 'foundryFabScheduleId': elem.foundryFabScheduleId, 'runId': elem.runId, 'date': (elem.shipDate).slice(3, 5) + " " + m_name, 'days': years + "-" + day + "-" + mon });
                            f_month_arr.push({ 'foundryFabScheduleId': '', 'runId': '', 'date': '', 'days': '' });
                        }
                    }
                });
                // alert(max_month_data + "-----" + max_data)
                if (max_month_data !== 0) {
                    if (max_month_data < max_data) {
                        let diff = (max_data * 2) - (max_month_data * 2);
                        for (let k = 0; k < diff; k++) {
                            f_month_arr.push({ 'foundryFabScheduleId': '', 'runId': '', 'date': '', 'days': '' });
                        }

                        f_year_arr.push({ 'month_name': m_name, 'list': f_month_arr });
                    }
                    else
                        f_year_arr.push({ 'month_name': m_name, 'list': f_month_arr });
                }
            });
            f_arr.push({ 'year': year, 'months': f_year_arr })
        });

        // alert(JSON.stringify(f_arr));

        let td_width = 100 / ((max_data * 2) + 2);
        // alert(td_width);
        //td_width

        // alert(JSON.stringify(f_arr));
        this.findLargestNumberOfMonths(f_arr);

        this.setState({
            fetchSchedule: false,
            scheduleList: f_arr,
            td_width: td_width
        })
        console.log("scheduleList: "+JSON.stringify(this.state.scheduleList))
    }

    findLargestNumberOfMonths = (f_arr) => {
        let that = this
        f_arr.map(function (item) {
            console.log("months: "+JSON.stringify(item))
            console.log("months: "+item.months.length)
            item.months.map(function (months) {
                if(that.state.largestNumberOfMonths < months.list.length){
                    that.state.largestNumberOfMonths = months.list.length
                }
            })


        })
        console.log("largestNumberOfMonths: "+that.state.largestNumberOfMonths)
        console.log("f_arr: "+f_arr.length)
    }

    CallOnMouseHover = (fabScheduleId, date, id) => {
        console.log("fabScheduleId: "+fabScheduleId)
        console.log("date: "+date)
        console.log("id: "+id)
        if ((window.location.href).includes('dashboard')) {
            if (this.state.milestoneId !== id)
                this.setState({
                    milestoneDate: date,
                    milestoneId: id,
                    fetchMilestone: true,
                    fabScheduleId: fabScheduleId
                })
            else
                $('#' + $.escapeSelector(this.state.milestoneId) + ' .inner_table').css('display', 'block');
        }
    }

    HandleSuccessGetMilestoneResponse = (res) => {
        this.setState({
            fetchMilestone: false,
            milestoneList: res.data
        });
        console.log("milestoneList: "+JSON.stringify(this.state.milestoneList))

        $('#' + $.escapeSelector(this.state.milestoneId) + ' .inner_table').css('display', 'block');
    }

    render() {
        return (
            <div>
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_CENTER} />
                <FoundaryService {...this.props} state={this.state} HandleChange={this.HandleChange}
                    foundries={this.state.foundriesList} techNodes={this.state.techNodesList}
                    scheduleList={this.state.scheduleList} CallOnMouseHover={this.CallOnMouseHover}
                    milestoneList={this.state.milestoneList} />

                {this.state.fetchFoundries === true ? GetFoundriesList(this.props.PrStore, this.HandleSuccessGetFoundriesResponse) : ''}
                {this.state.fetchTechNode === true ? GetTechListByFoundry(this.props.PrStore, this.state.ddlFoundry, this.HandleSuccessGetTechNodesResponse) : ''}
                {this.state.fetchSchedule === true ? GetScheduleByFoundryAndTech(this.props.PrStore, this.state.ddlFoundry, this.state.ddlTechNode, this.HandleSuccessGetScheduleResponse) : ''}
                {this.state.fetchMilestone === true ? GetMilestoneByFoundryAndShipDate(this.props.PrStore, this.state.ddlFoundry, this.state.milestoneDate, this.state.fabScheduleId, this.HandleSuccessGetMilestoneResponse) : ''}
            </div>
        )
    }
}
export const FoundryServicesCtrl = PrConnect(foundryServicesCtrl, '');