import { PrAjax } from '../library/ajax/pr-ajax';

export function GetCustomerDesigns(store, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('exportControlNew/findDesignsByCustomerId').AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function GetAllQuestions(store, exportControlId,successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('exportControlNew/getAllQuestions/'+exportControlId).AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function GetSavedQuestionAnswers(store, exportControlId,successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('exportControlNew/getSavedQuestionAnswers/'+exportControlId).AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function SaveQuestionAnswers(store, data, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Post('exportControlNew/save').ExtraData(data).AttachLoader(loader).OnSuccess(successFunc).Do();
    //return PrAjax(store).AddHeader(headerContent).Post('exportControl/save').ExtraData(data).AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function GetCorporateTypeList(store, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('codes/codesByCodeType/M_EC_Corporation_Type').AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function GetUploadFileList(store, exportControlId, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('exportControlNew/getUploadedFiles/'+exportControlId).AttachLoader(loader).OnSuccess(successFunc).Do();
}




