import { PrAjax } from '../library/ajax/pr-ajax';

export function GetCountryList(store, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('codes/codesByCodeType/M_COUNTRY').AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function GetInquiryReasonList(store, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('codes/codesByCodeType/M_Customer_Support_Type').AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function PostContactData(store, userObj, successFunc) {
    //code for further validations
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Post('customersupport/save').ExtraData(userObj).AttachLoader(loader).OnSuccess(successFunc).Do();
}