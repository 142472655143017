import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel'

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './AccessDeniedStyle.css';


export default class AccessDeniedComponent extends Component {
    constructor(){
        super()
        this.state = {
            nextIcon: false,
            prevIcon: false,
            pauseOnHover: false,
            interval:4000,
        }
        // pauseOnHover={this.state.pauseOnHover}
    }


    render() {
        return (
            <div>

                <div>
                    <div className="inner-home">
                        <h2>The MOSIS Service</h2>
                        <p>Since 1981, A pioneer in Multi Project Wafer (MPW) fabrication services.</p>
                        <div className="read_more text-center">
                            <a href="" onClick={()=>this.props.GoToLearnMore(1)} className="read_more_btn">Learn More</a>
                        </div>
                    </div>
                </div>


                {/*<section id="home_slider">
                    <div>
                        <Carousel>
                            <Carousel.Item>
                                <div className="item slide_1">
                                    <div className="inner-home">
                                        <h2>The MOSIS Service</h2>
                                        <p>Since 1981, A pioneer in Multi Project Wafer (MPW) fabrication services.</p>
                                        <div className="read_more text-center">
                                            <a href="" onClick={()=>this.props.GoToLearnMore(1)} className="read_more_btn">Learn More</a>
                                        </div>
                                    </div>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="item slide_2">
                                    <div className="inner-home">
                                        <h2>Advanced Foundry Access</h2>
                                        <p>Access to domestic and foreign foundries </p>
                                        <div className="read_more text-center">
                                            <a href="" onClick={()=>this.props.GoToLearnMore(2)} className="read_more_btn">Learn More</a>
                                        </div>
                                    </div>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="item slide_3">
                                    <div className="inner-home">
                                        <h2>Multi Project Wafer (MPW) Fabrication</h2>
                                        <p>Cost effective access to various technology nodes</p>
                                        <div className="read_more text-center">
                                            <a href="" onClick={()=>this.props.GoToLearnMore(3)} className="read_more_btn">Learn More</a>
                                        </div>
                                    </div>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="item slide_4">
                                    <div className="inner-home">
                                        <h2>Dedicated Fabrication Services</h2>
                                        <p>Low volume production and multiple order support</p>
                                        <div className="read_more text-center">
                                            <a href="" onClick={()=>this.props.GoToLearnMore(4)} className="read_more_btn">Learn More</a>
                                        </div>
                                    </div>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="item slide_5">
                                    <div className="inner-home">
                                        <h2>University Support Program</h2>
                                        <p>Provides professors and students with access to free fabrication</p>
                                        <div className="read_more text-center">
                                            <a href="" onClick={()=>this.props.GoToLearnMore(5)} className="read_more_btn">Learn More</a>
                                        </div>
                                    </div>
                                </div>
                            </Carousel.Item>
                        </Carousel>
                    </div>


                </section>*/}
            </div>
        )
    }
}

