import React, { Component } from 'react';
import DesignFabricationComponent from './designFabricationStatus'
import { GetDesignStatus, GetDesignNumbers, GetDesignInfoNumbers } from '../../../services/fabstatus';
import { PrConnect } from '../../../library/pr-miniredux';
import DashboardCtrl from "../Home";


export class designFabricationCtrl extends Component {

    constructor(props) {
        super(props);
        this.state = {
            codeValueCurrent: '',
            DesignNumber: '',
            DesignNumberDisabled: true,
            showNewTable: false,
            fetchCodeValues: false,
            DesignNumberStatus: true,
            currCodeValues: {},
            designNumbersVal: {},
            finalData: {},
            fetchFinalData: false,
            designStatusChanged: false,
            showDesignNumberNotAvailableMessage: false,
            redirectToMainPage: false
        }
    }

    UNSAFE_componentWillMount = () => {
        if (JSON.stringify(this.state.currCodeValues) === '{}')
            this.setState({
                fetchCodeValues: true
            });
    }

    HandleChange = (e) => {
        console.log(JSON.stringify(this.state.designNumbersVal))
        this.setState({
            [e.target.name]: e.target.value,
            designNumbersVal: {},
            showNewTable: false,
            designStatusChanged: true
        })
        console.log(JSON.stringify(this.state.designNumbersVal))
    }

    HandleFocus = () => {
        console.log(this.state.designNumbersVal)
        if(this.state.designNumbersVal.length > 0){
            console.log("inside showNewTable")
            this.setState({ showNewTable: true })
        }

    }

    HandleTableClick = (design_no) => {
        this.setState({
            DesignNumber: design_no,
            showNewTable: false,
            fetchFinalData: true
        })
    }

    HandleSuccessGetCodeValuesResponse = (res) => {
        this.setState({
            fetchCodeValues: false,
            currCodeValues: res.data,
        })
    }

    HandleActiveCurrentCodeValuesResponse = (res) => {
        console.log("Inside HandleActiveCurrentCodeValuesResponse"+res.data)

        if(res.data == ''){
            console.log("INSIDE IF")
            this.setState({
                DesignNumberDisabled: true,
                showDesignNumberNotAvailableMessage: true
            })
        }else{
            console.log("INSIDE ELSE")
            this.setState({
                DesignNumberDisabled: false,
                showDesignNumberNotAvailableMessage: false
            })
        }
        this.setState({
            designNumbersVal: res.data,
            //DesignNumberDisabled: false,
            designStatusChanged: false
        });

    }

    HandleCurrentDesignNumber = (res) => {
        this.setState({
            finalData: res.data,
            fetchFinalData: false
        })
    }


    render() {
        return (
            <div>
                {/* <DesignFabricationComponent codeValues={this.props.PrStore.GetData('codeValues')}/> */}


                    <DesignFabricationComponent {...this.props} state={this.state}
                                                HandleChange={this.HandleChange}
                                                HandleFocus={this.HandleFocus}
                                                codeValues={this.state.currCodeValues}
                                                showNewTable={this.state.showNewTable}
                                                HandleTableClick={this.HandleTableClick}
                                                DesignNumbersVal={this.state.designNumbersVal}
                                                FinalData={this.state.finalData}/>
                {this.state.fetchCodeValues === true ? GetDesignStatus(this.props.PrStore, this.HandleSuccessGetCodeValuesResponse) : ''}
                {this.state.codeValueCurrent !== '' && this.state.designStatusChanged === true ? GetDesignNumbers(this.props.PrStore, this.HandleActiveCurrentCodeValuesResponse, this.state.codeValueCurrent) : ''}
                {this.state.DesignNumber != '' && this.state.fetchFinalData === true ? GetDesignInfoNumbers(this.props.PrStore, this.HandleCurrentDesignNumber, this.state.DesignNumber) : ''}
            </div>
        )
    }
}

export const DesignFabricationCtrl = PrConnect(designFabricationCtrl, '');