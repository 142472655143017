import React, { Component } from "react";
import ReactDOM from "react-dom";

import { default as ReactSelect } from "react-select";
//import "./styles.css";
import { components } from "react-select";
import {PrConnect} from "../../../library/pr-miniredux";
import {PopupCtrl} from "../../Dashboard/Test/Popup";
import {testServicesCtrl} from "../../Dashboard/Test";

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    id="multiSelectCheckBox"
                    className="multiSelectCheckBox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

export class MultiSelectComponent extends Component {
    constructor(props) {
        super(props);
        console.log("props: "+JSON.stringify(props))
        this.state = {
            optionSelected: null,
            options: props.componentprops.multiSelectOptions,
            questionId: props.componentprops.questionId,
            type: props.componentprops.type,
            isRequired: props.componentprops.isRequired!=undefined?props.componentprops.isRequired:false,
            modifiedOptions: null,
            modifiedSelectedOptions: null,
            selectedValues: props.componentprops.selectedValue!=undefined?props.componentprops.selectedValue.split(','):'',

            showMultiSelect: false
            /*colourOptions: [{ value: "ocean1", label: "Ocean" },
                { value: "blue", label: "Blue" },
                { value: "purple", label: "Purple" },
                { value: "red", label: "Red" },
                { value: "orange", label: "Orange" },
                { value: "yellow", label: "Yellow" },
                { value: "green", label: "Green" },
                { value: "forest", label: "Forest" },
                { value: "slate", label: "Slate" },
                { value: "silver", label: "Silver" }]*/
        };
    }

    UNSAFE_componentWillMount = () => {
        {/*        <option value={item["technologyAnswerId"]}>
            {item.designUploadAnswerSet["answerValue"]}
        </option>*/}

        console.log("selectedValues: "+JSON.stringify(this.state.selectedValues))
        console.log("this.state.options: "+JSON.stringify(this.state.options))
        let selectedValues = this.state.selectedValues
        let modifiedOptionsTemp = []
        let modifiedOptionsSelectedTemp = []
        this.state.options.technologyAnswerSets.map(function (obj, index) {
            let modifiedObject = {
                 value: obj["technologyAnswerId"], label: obj.designUploadAnswerSet["answerValue"]
            }

            if(selectedValues.includes(obj["technologyAnswerId"].toString())){
                modifiedOptionsSelectedTemp.push(modifiedObject)
            }

            modifiedOptionsTemp.push(modifiedObject)

        })

        let that = this
        this.setState({
            modifiedOptions: modifiedOptionsTemp,
            modifiedSelectedOptions: modifiedOptionsSelectedTemp


        },() => {
            console.log("modifiedOptions: "+JSON.stringify(this.state.modifiedOptions))
            console.log("modifiedSelectedOptions: "+JSON.stringify(this.state.modifiedSelectedOptions))

            that.setState({
                showMultiSelect: true
            })
        })

    }

    handleChange = (selected) => {
        let that = this
        this.setState({
            optionSelected: selected
        },()=>{
            that.props.componentprops.onChange(selected, that.state.questionId, that.state.type)
            console.log("selected options: "+JSON.stringify(selected))
        });
    };

    render() {

        /*const customStyles = base => ({
            height: 50,
            width: 250
        });*/

       /* const customStyles = {
            control: base => ({
                ...base,
                height: 20,
                minHeight: 20
            }),

        };*/
        const customStyles = {
            control: base => ({
                ...base,
                minHeight: "fit-content"
            }),
            dropdownIndicator: base => ({
                ...base,
                padding: 1
            }),
            clearIndicator: base => ({
                ...base,
                padding: 1
            }),
            multiValue: base => ({
                ...base,
                height: "19"
            }),
            multiValueLabel: base => ({
                paddingTop: 0,
                fontSize: '80%',
                paddingLeft: 5
            }),
            valueContainer: base => ({
                ...base,
                padding: '0px 6px'
            }),
            input: base => ({
                ...base,
                margin: 0,
                padding: 0
            })
        };


        return (
            <span

                data-toggle="popover"
                data-trigger="focus"
                data-content="Please selecet account(s)"
                style={{width: "100%"}}
            >
                {this.state.showMultiSelect && this.state.modifiedOptions!=null?
                    <ReactSelect
                        className={this.state.isRequired?"react-multi-select-input required":"react-multi-select-input"}
                        classNamePrefix="react-multi-select"
                        id={this.props.componentprops.id}
                        defaultValue={this.state.modifiedSelectedOptions}
                        //defaultValue={[this.state.modifiedOptions[2], this.state.modifiedOptions[3]]}
                        options={this.state.modifiedOptions}
                        isMulti
                        name="colors"
                        /*options={this.state.modifiedOptions}*/
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{
                            Option
                        }}
                        onChange={this.handleChange}
                        allowSelectAll={true}
                        styles={customStyles}
                        //value={this.state.optionSelected}
                        /*className="basic-multi-select"
                        classNamePrefix="select"*/
                    />:""
                }

        {/*<ReactSelect

            height="120px"
            style = {{dropdownIndicator: customStyles}}
            options={this.state.colourOptions}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{
                Option
            }}
            onChange={this.handleChange}
            allowSelectAll={true}
            value={this.state.optionSelected}
        />*/}
      </span>
        );
    }
}

//export default MultiSelectComponent = PrConnect(MultiSelectComponent, '');
export default MultiSelectComponent = PrConnect(MultiSelectComponent, '');