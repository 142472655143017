import React, { Component } from 'react';
import PdkAccessRequest from './pdkaccess';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import $ from 'jquery';
import PrValidate from '../../../library/pr-controls/pr-validation-full';

import { PrConnect } from '../../../library/pr-miniredux';
import { GetFoundriesList, GetTechListByFoundry, PostDesignData } from '../../../services/foundrypdk';
import { GetCodesByCodeType } from '../../../services/codeService'
import DashboardCtrl from "../Home";
import {getDesignNumberList} from "../../../services/designNumberInfoService"

export class pdkAccessRequestCtrl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetchFoundries: true,
            foundriesList: {},
            fetchTechNode: false,
            techNodesList: {},

            postDesignData: false,

            ddlTechNode: '',
            ddlFoundry: '',
            ddlRequestedFileTypes: '',
            ddlUserForFabricationDesignCheck: '',
            expectedDesignSubmissionDate: '',
            txtExpectedDieQuantity: '',
            txtEarlyPdkReason: '',

            codeTypeName:'M_PDK_Request_File_Type',
            fetchCodeValues:true,
            requestedFileTypes:[],
            redirectToMainPage: false,
            showInitialSelectBox: true,
            isExistingDesignNumber: '',
            showInputForm:'',

            fetchFoundryAndTechClass: false,
            customerId:254,
            foundryAndTechClass:{},
            selectedDesignNumber:'',
            foundry:{},
            techClass:{},
            designNumberInfoIdMap:{},
            designNumberListDisabled:false
        }
        this.design_data = {}
    }

    resetForm = () =>{
        this.setState({
                          foundry:{},
                          techClass:{},
                          ddlRequestedFileTypes:'',
                          ddlFoundry:'',
                          ddlTechNode:'',
                          ddlUserForFabricationDesignCheck: '',
                          expectedDesignSubmissionDate: '',
                          txtExpectedDieQuantity: '',
                          txtEarlyPdkReason: '',
                      })

    }

    UNSAFE_componentWillMount = () => {
        console.log("this.state.selectedDesignNumber: "+this.state.selectedDesignNumber)
        this.setState({
            codeTypeName: "M_PDK_Request_File_Type",
            fetchCodeValues: true
        })
    }
    HandleDesignSubmitDateChange = (expectedDesignSubmissionDate) => {
        this.setState({ expectedDesignSubmissionDate })
    }
    HandleChange = (e) => {

        if(e.target.name === 'selectedDesignNumber'){
            let that = this
            this.state.foundryAndTechClass.map(function(item,index) {
                    if(item["designNumber"] == e.target.value){
                        if(item.technologyProcessObj.foundryObject["codeValue"] == "Global Foundries"){
                            console.log("Inside Global Foundry IF")
                            $("#designNumberDetails").hide();
                            $("#globalDesignNumberNote").show();
                            $("#submitButton").hide();
                            //globalDesignNumberNote
                            //designNumberDetails
                        }else{
                            $("#designNumberDetails").show();
                            $("#globalDesignNumberNote").hide();
                            $("#submitButton").show();
                            that.state.foundry = item.technologyProcessObj.foundryObject
                            that.state.techClass = item.technologyProcessObj.technologyClassObject
                            that.setState({
                                foundry: item.technologyProcessObj.foundryObject,
                                techClass: item.technologyProcessObj.technologyClassObject
                            })
                        }
                    }
            })
            console.log("Inside selectedDesignNumber")
            console.log("this.props.state.foundryAndTechClass: "+JSON.stringify(this.state.foundryAndTechClass))

        }

        if (e.target.name === 'isExistingDesignNumber') {
            this.setState({
                showInitialSelectBox: false,
                [e.target.name]: e.target.value,
                fetchFoundryAndTechClass: true
            });
            if(e.target.value == "Yes"){
                this.setState({
                    showInputForm: false,
                    selectedDesignNumber: ''
                })
                this.resetForm();
            }else if(e.target.value == "No"){
                this.setState({
                    showInputForm: true
                })
                this.resetForm();
            }else{
                this.setState({
                    showInputForm: ''
                })
            }
        }

        if (e.target.name === 'ddlFoundry') {
            if(e.target.value != ''){
                console.log("INSIDE IF =============")

                let globalSelected = false;
                let that = this
                this.state.foundriesList.map(function(item,index) {
                    if(item.codeId == e.target.value && item.codeValue == "Global Foundries"){
                        $("#newDesignNumberForm").hide();
                        $("#globalDesignNumberNoteForNewDesign").show();
                        $("#submitButtonExisting").hide();
                        globalSelected = true
                        that.setState({
                            fetchTechNode: false,
                            [e.target.name]: e.target.value
                        });
                    }
                })

                if(globalSelected == false){
                    $("#newDesignNumberForm").show();
                    $("#globalDesignNumberNoteForNewDesign").hide();
                    $("#submitButtonExisting").show();
                    this.setState({
                        fetchTechNode: true,
                        [e.target.name]: e.target.value
                    });
                }


            }else{
                this.setState({
                    [e.target.name]: e.target.value,
                    techNodesList:{}
                });
            }
        }
        else if (e.target.name === 'txtExpectedDieQuantity') {

            if (e.target.value.match(/^[0-9]{0,6}$/g) || e.target.value == "") {
                this.setState({
                    [e.target.name]: e.target.value
                })
            }
           /* const re = /^[0-9\b]+$/;
            // if value is not blank, then test the regex
            // let val = e.target.value;
            let val = e.target.value.replace(/[^0-9]/g, '');
            if (val === '' || re.test(val)) {
                val = val.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
                this.setState({
                    [e.target.name]: val
                })
            }*/
        }
        else {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
        if (e.target.name === 'ddlTechNode')
            PrValidate.ForceSelect(e.target.value, 'ddlTechNode', 'a technology node');
        else if (e.target.name === 'ddlFoundry')
            PrValidate.ForceSelect(e.target.value, 'ddlFoundry', 'a foundry');
        else if (e.target.name === 'ddlUserForFabricationDesignCheck')
            PrValidate.ForceSelect(e.target.value, 'ddlUserForFabricationDesignCheck', 'Fabrication Design Check');
    }

    normalizeNumber(num) {
        return this._isNumber(num) ? Number(num) : 0;
    }

    _isNumber(input) {
        return !(typeof input == 'undefined' || isNaN(input));
    }

    roundOff(num, toDecimals) {
        if (!this._isNumber(num))
            throw num;

        return parseFloat(String(num)).toFixed(this.normalizeNumber(toDecimals));
    }
    
    HandleOnBlur = (type, field, value, msg) => {
        if(field === 'txtExpectedDieQuantity'){
            if (value.length > 0 && this._isNumber(value)) {
                value = value;
                value = this.roundOff(value, 0);
                this.setState({
                    [field]: value
                })
            }
        }
        if (type === 'ForceSelect')
            PrValidate.ForceSelect(value, field, msg);
        else if (type === 'ForceRNumber')
            PrValidate.ForceRNumber(value.replace(/[^0-9]/g, ''), field, msg);
        else if (type === 'ForceDate')
            PrValidate.ForceDate(value, field, msg);
        else if (type === 'ForceEntry')
            PrValidate.ForceEntry(value, field, msg);
        else if (type === 'ForceTextArea')
            PrValidate.ForceEntry(value, field, msg);
    }

    HandleSuccessGetFoundriesResponse = (res) => {
        console.log("Inside HandleSuccessGetFoundriesResponse: "+JSON.stringify(res.data))
        this.setState({
            fetchFoundries: false,
            foundriesList: res.data
        })
    }
    HandleSuccessGetTechNodesResponse = (res) => {
        if(res.data != null){
            res.data.sort(this.GetSortOrder("field1"));
        }
        this.setState({
            fetchTechNode: false,
            techNodesList: res.data
        })
        console.log("techNodesList: "+JSON.stringify(this.state.techNodesList))
    }

    GetSortOrder = (prop) => {
        return function(a, b) {
            if (Number(a[prop]) > Number(b[prop])) {
                return 1;
            } else if (Number(a[prop]) < Number(b[prop])) {
                return -1;
            }
            return 0;
        }
    }

    SubmitDesign = () => {
        let ddlTechNode = PrValidate.ForceSelect(this.state.ddlTechNode, 'ddlTechNode', 'a technology node');
        let ddlFoundry = PrValidate.ForceSelect(this.state.ddlFoundry, 'ddlFoundry', 'a foundry');
        let ddlUserForFabricationDesignCheck = PrValidate.ForceSelect(this.state.ddlUserForFabricationDesignCheck, 'ddlUserForFabricationDesignCheck', 'a Fabrication Design Check');
        let expectedDesignSubmissionDate = PrValidate.ForceDate(this.state.expectedDesignSubmissionDate, 'expectedDesignSubmissionDate', "Expected Design Submission Date");
        let txtExpectedDieQuantity = PrValidate.ForceRNumber((this.state.txtExpectedDieQuantity).replace(/[^0-9]/g, ''), 'txtExpectedDieQuantity');
        let txtEarlyPdkReason = PrValidate.ForceTextArea(this.state.txtEarlyPdkReason, 'txtEarlyPdkReason', 'Early Pdk Reason');

        let ddlRequestedFileTypes = PrValidate.ForceSelect(this.state.ddlRequestedFileTypes, 'ddlRequestedFileTypes', 'a Fabrication Design Check');


        let err_arr = [ddlTechNode, ddlFoundry, ddlUserForFabricationDesignCheck, expectedDesignSubmissionDate, txtExpectedDieQuantity, txtEarlyPdkReason, ddlRequestedFileTypes];
        let chk_false = 0;

        let that = this;

        err_arr.forEach(function (element, index) {
            if (!element)
                chk_false++;
            if ((err_arr.length - 1) === index) {
                if (chk_false === 0) {
                    that.design_data = {
                        foundry: that.state.ddlFoundry,
                        techNode: that.state.ddlTechNode,
                        userForFabricationDesignCheck: that.state.ddlUserForFabricationDesignCheck,
                        expectedDesignSubmissionDate: that.state.expectedDesignSubmissionDate,
                        expectedDieQuantity: (that.state.txtExpectedDieQuantity).replace(/[^0-9]/g, ''),
                        earlyAccessReason: that.state.txtEarlyPdkReason,
                        fileType: that.state.ddlRequestedFileTypes
                    }
                    that.setState({
                        postDesignData: true
                    })
                }
            }
        });
    }

    SubmitOldDesign = () => {
        let ddlTechNode = PrValidate.ForceSelect(this.state.selectedDesignNumber, 'selectedDesignNumber', 'a technology node');
        let ddlRequestedFileTypes = PrValidate.ForceSelect(this.state.ddlRequestedFileTypes, 'ddlRequestedFileTypes', 'a file type');

        let err_arr = [ddlTechNode, ddlRequestedFileTypes];
        let chk_false = 0;

        let that = this;

        err_arr.forEach(function (element, index) {
            if (!element)
                chk_false++;
            if ((err_arr.length - 1) === index) {
                if (chk_false === 0) {
                    that.design_data = {
                        foundry: that.state.foundry["codeId"],
                        techNode: that.state.techClass["codeId"],
                        designNumberInfoId: that.state.designNumberInfoIdMap[that.state.selectedDesignNumber],
                        fileType: that.state.ddlRequestedFileTypes
                    }
                    that.setState({
                        postDesignData: true
                    })
                }
            }
        });

        console.log("that.design_data: "+JSON.stringify(that.design_data))
    }



    HandleSuccessPostDesignResponse = (res) => {
        ToastsStore.success("Successfully Saved.");
        this.setState({
            postDesignData: false,
            ddlFoundry: '',
            ddlTechNode: '',
            ddlUserForFabricationDesignCheck: '',
            expectedDesignSubmissionDate: '',
            txtExpectedDieQuantity: '',
            txtEarlyPdkReason: '',
            ddlRequestedFileTypes:'',
            redirectToMainPage: true
        })

        console.log("that.props.componentprops.history: "+JSON.stringify(this.props.componentprops.history))
        setTimeout(function() { //Start the timer
            this.props.componentprops.history.push("/dashboard");
        }.bind(this), 2000)
    }

    HandeSuccessGetCodesByCodeType = (res) =>{

        var filteredData = res.data.filter(function(obj, index) {
            return obj["codeValue"] === "Both";
        });

        let that = this
        res.data.forEach(function (element, index) {
            if(element["codeId"] != filteredData[0]["codeId"]){
                that.state.requestedFileTypes.push(element);
            }
        })

        if(filteredData != null && filteredData.length > 0){
            this.state.requestedFileTypes.push(filteredData[0]);
        }
        console.log("filteredData: "+JSON.stringify(filteredData))

        this.setState({
            requestedFileTypes: this.state.requestedFileTypes
        })
        console.log("HandeSuccessGetCodesByCodeType: "+JSON.stringify(this.state.requestedFileTypes))
    }

    HandleSuccessGetDesignNumberList = (res) => {
        if(res != null) {
            this.state.foundryAndTechClass = res.data
            console.log("this.state.foundryAndTechClass: " + JSON.stringify(this.state.foundryAndTechClass))

            let that = this
            this.state.foundryAndTechClass.map(function (item, index) {
                that.state.designNumberInfoIdMap[item["designNumber"]] = item["designNumberInfoId"]
                /*if(item.foundry == null){
                    that.state.foundryAndTechClass.splice(index,1)
                }*/
            })

            this.setState({
                fetchFoundryAndTechClass: false,
                foundryAndTechClass: this.state.foundryAndTechClass,
                designNumberListDisabled: false
            })


            console.log("foundryAndTechClass: " + JSON.stringify(this.state.foundryAndTechClass))
            console.log("foundryAndTechClass: " + this.state.foundryAndTechClass.length)
        }else{
            this.setState({
                fetchFoundryAndTechClass: false,
                designNumberListDisabled: true
            })
        }
    }

    render() {
        return (
            <div>
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_CENTER} />

                {/*{this.state.redirectToMainPage ?
                    <DashboardCtrl {...this.props} state={this.state}/>:''
                }*/}

                {this.state.redirectToMainPage == false?
                <PdkAccessRequest {...this.props} state={this.state} HandleChange={this.HandleChange}
                                  foundries={this.state.foundriesList} techNodes={this.state.techNodesList}
                                  SubmitDesign={this.SubmitDesign} SubmitOldDesign={this.SubmitOldDesign} HandleOnBlur={this.HandleOnBlur}
                                  HandleDesignSubmitDateChange={this.HandleDesignSubmitDateChange} requestedFileTypes={this.state.requestedFileTypes}/>:''
                }

                {this.state.fetchFoundries === true ? GetFoundriesList(this.props.PrStore, this.HandleSuccessGetFoundriesResponse) : ''}
                {this.state.fetchTechNode === true ? GetTechListByFoundry(this.props.PrStore, this.state.ddlFoundry, this.HandleSuccessGetTechNodesResponse) : ''}
                {this.state.postDesignData === true ? PostDesignData(this.props.PrStore, this.design_data, this.HandleSuccessPostDesignResponse) : ''}
                {this.state.fetchCodeValues === true ? GetCodesByCodeType(this.props.PrStore, this.state.codeTypeName, this.HandeSuccessGetCodesByCodeType) : ''}
                {this.state.fetchFoundryAndTechClass === true ? getDesignNumberList(this.props.PrStore, this.HandleSuccessGetDesignNumberList) : ''}
            </div>
        )
    }
}
export const PdkAccessRequestCtrl = PrConnect(pdkAccessRequestCtrl, '');