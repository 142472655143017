import React from 'react';
import $ from 'jquery';

export default class PrValidate {
    constructor() {
        //  alert('in constructor');
        this.value = '';
        this.whitespace = " \t\n\r";

    }

    static abc() {
        return 20;
    }

    static ForceEntry = function (value, displayFieldName, msg = "Required Field") {
        $('#err_' + displayFieldName).remove();
        $('#' + displayFieldName).attr('style', 'background-color: transparent');
        //let strField = objField;
        if (this.isWhitespace(value)) {
            /*errorMessage = displayFieldName + " cannot be left blank";
            DisplayError(objField, errorMessage)
            objField.focus();
            objField.select();*/
            // alert(value+"in validate");
            $('#' + displayFieldName).attr('style', 'background-color: #F3B83E !important; margin-left: -6px; border-radius: 5px; height: 100%');
            $('<span id=err_' + displayFieldName + ' style="font-size:10px; position: absolute; bottom: -14px; color: red">Plese enter ' + msg + '</span>').insertAfter('#' + displayFieldName);
            return false;
        }
        //alert(value+"in validate true");
        // document.getElementById(displayFieldName).style.background = 'red';
        // document.getElementById(displayFieldName).style.background = 'red';

        // $('#' + displayFieldName).attr('style', 'background-color: transparent; position: relative;top: 12px');
        $('#err_' + displayFieldName).remove();
        $('#' + displayFieldName).attr('style', 'background-color: transparent');
        return true;

    }

    static ForceTextArea = function (value, displayFieldName, msg = "Required Field") {
        $('#err_' + displayFieldName).remove();
        $('#' + displayFieldName).attr('style', 'background-color: #FFF');
        if (this.isWhitespace(value)) {
            $('#' + displayFieldName).attr('style', 'background-color: #F3B83E !important; margin-left: -6px; border-radius: 8px');
            $('<span id=err_' + displayFieldName + ' style="font-size:10px; position: absolute; bottom: 16px; color: red">Please enter ' + msg + '</span>').insertAfter('#' + displayFieldName);
            return false;
        }
        $('#err_' + displayFieldName).remove();
        $('#' + displayFieldName).attr('style', 'background-color: #FFF');
        return true;

    }


    static ForceImage = function (value, displayFieldName, msg = "Required Field") {
        $('#err_' + displayFieldName).remove();
        $('#' + displayFieldName).attr('style', 'background-color: transparent');
        //let strField = objField;
        if (value.length == 0) {
            /*errorMessage = displayFieldName + " cannot be left blank";
            DisplayError(objField, errorMessage)
            objField.focus();
            objField.select();*/
            $('#' + displayFieldName).attr('style', 'background-color: #F3B83E !important;margin-left: -6px; border-radius: 5px; height: 100%');
            $('<span id=err_' + displayFieldName + ' style="font-size:10px; position: absolute; bottom: -14px; color: red">' + msg + '</span>').insertAfter('#' + displayFieldName);
            return false;
        }
        $('#err_' + displayFieldName).remove();
        $('#' + displayFieldName).attr('style', 'background-color: transparent');
        return true;

    }

    static ForceNumber = function (value, displayFieldName, msg = "") {   //alert("hi");
        $('#err_' + displayFieldName).remove();
        $('#' + displayFieldName).attr('style', 'background-color: transparent');
        if (this.isWhitespace(value)) return true;

        let i = 0;
        for (i = 0; i < value.length; i++)
            if (value.charAt(i) < '0' || value.charAt(i) > '9') {
                // errorMessage = displayFieldName + " must be a valid numeric entry.  Please do not use commas or dollar signs or any non-numeric symbols.";
                // DisplayError(objField, errorMessage)
                // objField.focus();

                $('#' + displayFieldName).attr('style', 'background-color: #F3B83E !important;margin-left: -6px; border-radius: 5px; height: 100%');
                $('<span id=err_' + displayFieldName + ' style="font-size:10px; position: absolute; bottom: -14px; color: red">' + msg + '  cannot be empty, 0 or negative value. Please enter a valid number.</span>').insertAfter('#' + displayFieldName);

                return false;
            }
        $('#err_' + displayFieldName).remove();
        $('#' + displayFieldName).attr('style', 'background-color: transparent');
        return true;

    }

    static isWhitespace = function (s) {
        // alert(s.length);
        let i;
        let whitespace = " \t\n\r";
        // Is s empty?
        if (this.isEmpty(s)) return true;

        // Search through string's characters one by one
        // until we find a non-whitespace character.
        // When we do, return false; if we don't, return true.

        for (i = 0; i < s.length; i++) {
            // Check that current character isn't whitespace.

            // alert(s.charAt(1));

            // if (s.charAt(0) === ' ') return false;
            // alert(s.charAt(0));
            let c = s.charAt(i);
            if (whitespace.indexOf(c) === -1) return false;
        }

        // All characters are whitespace.
        return true;
    }

    static ForceEmail = function (value, displayFieldName, msg = "") {
        $('#err_' + displayFieldName).remove();
        $('#' + displayFieldName).attr('style', 'background-color: transparent');
        if (!(this.isEmail(value))) {

            $('#' + displayFieldName).attr('style', 'background-color: #F3B83E !important;margin-left: -6px; border-radius: 5px; height: 100%');
            $('<span id=err_' + displayFieldName + ' style="font-size:10px; position: absolute; bottom: -14px; color: red">' + msg + ' Not a Valid Email</span>').insertAfter('#' + displayFieldName);

            return false;
        }
        $('#err_' + displayFieldName).remove();
        $('#' + displayFieldName).attr('style', 'background-color: transparent');
        return true;
    }
    static isEmail = function (s) {

        // is s whitespace?
        if (this.isWhitespace(s)) return false;

        // there must be >= 1 character before @, so we
        // start looking at character position 1
        // (i.e. second character)
        let i = 1;
        let sLength = s.length;

        // look for @
        while ((i < sLength) && (s.charAt(i) != "@")) {
            i++
        }

        if ((i >= sLength) || (s.charAt(i) != "@")) return false;
        else i += 2;
        //alert("hi");
        // look for .
        while ((i < sLength) && (s.charAt(i) != ".")) {
            i++
        }

        // there must be at least one character after the .
        if ((i >= sLength - 1) || (s.charAt(i) != ".")) return false;
        else return true;
    }

    static isEmpty = function (s) {
        return ((s == null) || (s.length == 0) || (s == ''))
    }

    static ForceDate = function (value, displayFieldName, msg = "Required Field") {
        $('#err_' + displayFieldName).remove();
        $('#' + displayFieldName).attr('style', 'background-color: #FFF');
        if(value === '' || value === undefined){
            $('#' + displayFieldName).attr('style', 'background-color: #F3B83E !important; border-radius: 8px');
            $('<span id=err_' + displayFieldName + ' style="font-size:10px; position: absolute; color: red">Please enter ' + msg + '</span>').insertAfter('#' + displayFieldName);
            return false;
        }
        $('#err_' + displayFieldName).remove();
        $('#' + displayFieldName).attr('style', 'background-color: #FFF');
        return true;
    }
    static ForceRNumber = function (value, displayFieldName, msg = "") {
        $('#err_' + displayFieldName).remove();
        $('#' + displayFieldName).attr('style', 'background-color: transparent');
        // alert('hiiiiiiii');
        if (this.ForceEntry(value)) {
            if (this.ForceNumber(value)) {
                $('#err_' + displayFieldName).remove();
                $('#' + displayFieldName).attr('style', 'background-color: transparent');
                return true;
            }
            else {
                $('#' + displayFieldName).attr('style', 'background-color: #F3B83E !important;margin-left: -6px; border-radius: 5px; height: 100%');
                $('<span id=err_' + displayFieldName + ' style="font-size:10px; position: absolute; bottom: -14px; color: red">' + msg + ' cannot be empty, 0 or negative value. Please enter a valid number.</span>').insertAfter('#' + displayFieldName);

                return false;
            }
        }
        else {
            $('#' + displayFieldName).attr('style', 'background-color: #F3B83E !important;margin-left: -6px; border-radius: 5px; height: 100%');
            $('<span id=err_' + displayFieldName + ' style="font-size:10px; position: absolute; bottom: -14px; color: red">' + msg + ' cannot be empty, 0 or negative value. Please enter a valid number.</span>').insertAfter('#' + displayFieldName);

            return false;
        }
    }

    static ForceRWebsite = function (value, displayFieldName, msg = "") {
        $('#err_' + displayFieldName).remove();
        $('#' + displayFieldName).attr('style', 'background-color: transparent');
        // alert('hiiiiiiii');
        if (this.ForceEntry(value)) {
            var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + //port
                '(\\?[;&amp;a-z\\d%_.~+=-]*)?' + // query string
                '(\\#[-a-z\\d_]*)?$', 'i');

            //let regexp =  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
            var expression =
                /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
            var regex = new RegExp(expression);
            if (value.match(regex) !== null) {
                // if (regexp.test(value)) {
                $('#err_' + displayFieldName).remove();
                $('#' + displayFieldName).attr('style', 'background-color: transparent');
                return true;
            }
            else {
                $('#' + displayFieldName).attr('style', 'background-color: #F3B83E !important;margin-left: -6px; border-radius: 5px; height: 100%');
                $('<span id=err_' + displayFieldName + ' style="font-size:10px; position: absolute; bottom: -14px; color: red">Please enter  ' + msg + '.</span>').insertAfter('#' + displayFieldName);

                return false;
            }
        }
        else {
            $('#' + displayFieldName).attr('style', 'background-color: #F3B83E !important;margin-left: -6px; border-radius: 5px; height: 100%');
            $('<span id=err_' + displayFieldName + ' style="font-size:10px; position: absolute; bottom: -14px; color: red">Please enter  ' + msg + '.</span>').insertAfter('#' + displayFieldName);

            return false;
        }
    }

    static ForcePassword = function (value, displayFieldName, msg = "Not a Valid Password") {
        $('#err_' + displayFieldName).remove();
        $('#' + displayFieldName).attr('style', 'background-color: transparent');
        //alert("hi");
        let strPassField = value;
        if (this.isWhitespace(strPassField)) {
            /*errorMessage = displayFieldName + " cannot be left blank. Space Not Allowed";
            DisplayError(objField,errorMessage)
            objField.focus();
            objField.select();*/
            return false;
        }
        else {
            let len = strPassField.length;
            if (len >= 6) {
                return true;
            }
            else {
                return false;
            }
        }
    }

    static ForceCheckBox = function (status) {
        if (status === true)
            return true;
        else
            return false;

    }

    static ForceRadio = function (status) {
        if (status === true)
            return true;
        else
            return false;

    }

    static ForceGroupCheckbox = function (objField, displayFieldName) {
        /*//alert(displayFieldName);
        var strField = new String(objField.attr("flxgroup"));
        //alert(strField);
        var flag = false;
        $(":checkbox[flxgroup='" + strField + "']").each(function () {
            //alert($(this).prop("checked"));
            if ($(this).prop("checked") == true) {
                flag = true;
            }
        });
        if (flag==false) {
            // errorMessage = displayFieldName + "Please select atleast one option";
            // DisplayCheckBoxError(objField,errorMessage)
            // objField.focus();
            // objField.select();
            return false;
        }*/
        return true;
    }

    static ForceSelect = function (value, displayFieldName, msg = "", top='-33px') {
        $('#err_' + displayFieldName).remove();
        $('#' + displayFieldName).attr('style', 'background-color: transparent');
        // alert(value);
        if (value == -1 || value == '') {
            $('#' + displayFieldName).attr('style', 'background-color: #F3B83E !important; position: relative; top: '+top+';height: 39px; left: -4px');
            $('<span id=err_' + displayFieldName + ' style="font-size:10px; position: relative; top: -40px; color: red">Please select ' + msg + '.</span>').insertAfter('#' + displayFieldName);
            return false
        }
        else {
            $('#err_' + displayFieldName).remove();
            $('#' + displayFieldName).attr('style', 'background-color: transparent');
            return true;
        }
    }
    static ForceContactSelect = function (value, displayFieldName, msg = "", top='-27px') {
        $('#err_' + displayFieldName).remove();
        $('#' + displayFieldName).attr('style', 'background-color: transparent');
        // alert(value);
        if (value == -1 || value == '') {
            $('#' + displayFieldName).attr('style', 'background-color: #F3B83E !important; position: relative; top: '+top+';height: 39px; left: -4px');
            $('<span id=err_' + displayFieldName + ' style="font-size:10px; position: absolute;left:5px; bottom: -18px; color: red">Please select ' + msg + '.</span>').insertAfter('#' + displayFieldName);
            return false
        }
        else {
            $('#err_' + displayFieldName).remove();
            $('#' + displayFieldName).attr('style', 'background-color: transparent');
            return true;
        }
    }
    static ForceSelectMultiple = function (value, displayFieldName, msg = "Please select alteast one Value") {
        // alert(value);
        let length = value.length;
        if (value == -1 || value == '') {
            return false
        }
        if (length == 0) {
            return false;
        }
        if (length == 1) {
            if (value[0] == '-1') {
                return false;
            }
        }
        return true;
    }

    static ForceMobile = function (value, displayFieldName, msg = "Not a Valid Mobile No.") {
        $('#err_' + displayFieldName).remove();
        $('#' + displayFieldName).attr('style', 'background-color: transparent');
        for (let i = 0; i < value.length; i++) {
            if (value.charAt(i) < '0' || value.charAt(i) > '9') {
                return false;
            }
            else if (/\s/g.test(value)) {
                $('#' + displayFieldName).attr('style', 'background-color: #F3B83E !important;margin-left: -6px; border-radius: 5px; height: 100%');
                $('<span id=err_' + displayFieldName + ' style="font-size:10px; position: absolute; bottom: -14px; color: red">' + msg + '</span>').insertAfter('#' + displayFieldName);
                return false;
            }
            else if (value.length != 10) {
                $('#err_' + displayFieldName).remove();
                $('#' + displayFieldName).attr('style', 'background-color: transparent');
                return false;
            }
        }
        if (parseInt(value) < 6123456789) return false;
        return true;

    }
    static ForceRMobile = function (value, displayFieldName, msg = "Not a Valid Mobile No.") {
        $('#err_' + displayFieldName).remove();
        $('#' + displayFieldName).attr('style', 'background-color: transparent');
        if (this.isWhitespace(value)) {
            /*errorMessage = displayFieldName + " cannot be left blank";
            DisplayError(objField, errorMessage)
            objField.focus();
            objField.select();*/
            // alert(value+"in validate");
            $('#' + displayFieldName).attr('style', 'background-color: #F3B83E !important;margin-left: -6px; border-radius: 5px; height: 100%');
            $('<span id=err_' + displayFieldName + ' style="font-size:10px; position: absolute; bottom: -14px; color: red">' + msg + '</span>').insertAfter('#' + displayFieldName);
            return false;
        } else {
            for (let i = 0; i < value.length; i++) {
                if (value.charAt(i) < '0' || value.charAt(i) > '9') {
                    $('#' + displayFieldName).attr('style', 'background-color: #F3B83E !important;margin-left: -6px; border-radius: 5px; height: 100%');
                    $('<span id=err_' + displayFieldName + ' style="font-size:10px; position: absolute; bottom: -14px; color: red">' + msg + '</span>').insertAfter('#' + displayFieldName);
                    return false;
                }
                else if (/\s/g.test(value)) {
                    $('#' + displayFieldName).attr('style', 'background-color: #F3B83E !important;margin-left: -6px; border-radius: 5px; height: 100%');
                    $('<span id=err_' + displayFieldName + ' style="font-size:10px; position: absolute; bottom: -14px; color: red">' + msg + '</span>').insertAfter('#' + displayFieldName);
                    return false;
                }
                else if (value.length != 10) {
                    $('#' + displayFieldName).attr('style', 'background-color: #F3B83E !important;margin-left: -6px; border-radius: 5px; height: 100%');
                    $('<span id=err_' + displayFieldName + ' style="font-size:10px; position: absolute; bottom: -14px; color: red">' + msg + '</span>').insertAfter('#' + displayFieldName);
                    return false;
                }
            }
            if (parseInt(value) < 6123456789) {
                $('#' + displayFieldName).attr('style', 'background-color: #F3B83E !important;margin-left: -6px; border-radius: 5px; height: 100%');
                $('<span id=err_' + displayFieldName + ' style="font-size:10px; position: absolute; bottom: -14px; color: red">' + msg + '</span>').insertAfter('#' + displayFieldName);
                return false;
            }
            $('#err_' + displayFieldName).remove();
            $('#' + displayFieldName).attr('style', 'background-color: transparent');
            return true;
        }

    }

    static ForceRPhone = function (value, displayFieldName, msg = "Not a Valid Phone No.") {
        $('#err_' + displayFieldName).remove();
        $('#' + displayFieldName).attr('style', 'background-color: transparent');
        if (this.isWhitespace(value)) {
            /*errorMessage = displayFieldName + " cannot be left blank";
            DisplayError(objField, errorMessage)
            objField.focus();
            objField.select();*/
            // alert(value+"in validate");
            $('#' + displayFieldName).attr('style', 'background-color: #F3B83E !important;margin-left: -6px; border-radius: 5px; height: 100%');
            $('<span id=err_' + displayFieldName + ' style="font-size:10px; position: absolute; bottom: -14px; color: red">Please enter a ' + msg + '</span>').insertAfter('#' + displayFieldName);
            return false;
        }
        else {
            if (/^[Extension0-9-()]*$/.test(value) === false) {
                $('#' + displayFieldName).attr('style', 'background-color: #F3B83E !important;margin-left: -6px; border-radius: 5px; height: 100%');
                $('<span id=err_' + displayFieldName + ' style="font-size:10px; position: absolute; bottom: -14px; color: red">' + msg + ' is not valid</span>').insertAfter('#' + displayFieldName);
                return false;
            }
            else {
                $('#err_' + displayFieldName).remove();
                $('#' + displayFieldName).attr('style', 'background-color: transparent');
                return true;
            }
        }

    }

    static ForceFloat = function (strField, displayFieldName, msg = "Not a Valid Mobile No.") {
        $('#err_' + displayFieldName).remove();
        $('#' + displayFieldName).attr('style', 'background-color: transparent');

        let countminus = 0;
        let countplus = 0;
        let countpoint = 0;
        // let strField = new String(objField.val());

        if (this.isWhitespace(strField)) return true;

        let i = 0;

        for (i = 0; i < strField.length; i++) {
            //alert(strField.charAt(i));
            if ((strField.charAt(i) < '0' || strField.charAt(i) > '9') && strField.charAt(i) != '.' && strField.charAt(i) != '-' && strField.charAt(i) != '+') {
                /*errorMessage = displayFieldName + " must be a valid numeric entry. Allowed speciel characters are (+, -, .)";
                DisplayError(objField, errorMessage)
                objField.focus();*/
                $('#' + displayFieldName).attr('style', 'background-color: #F3B83E !important;margin-left: -6px; border-radius: 5px; height: 100%');
                $('<span id=err_' + displayFieldName + ' style="font-size:10px; position: absolute; bottom: -14px; color: red">' + msg + '</span>').insertAfter('#' + displayFieldName);

                return false;
            }
            else if (strField.charAt(i) == '-') {
                countminus = countminus + 1;
            }
            else if (strField.charAt(i) == '+') {
                countplus = countplus + 1;
            }
            else if (strField.charAt(i) == '.') {
                countpoint = countpoint + 1;
            }
        }
        //alert(countminus);
        if (countminus > 1 || countplus > 1 || countpoint > 1) {
            /*errorMessage = "Any Symbol should be used only once";
            DisplayError(objField, errorMessage)
            objField.focus();*/
            $('#' + displayFieldName).attr('style', 'background-color: #F3B83E !important;margin-left: -6px; border-radius: 5px; height: 100%');
            $('<span id=err_' + displayFieldName + ' style="font-size:10px; position: absolute; bottom: -14px; color: red">' + msg + '</span>').insertAfter('#' + displayFieldName);
            return false;
        }
        else if (countminus >= 1 && countplus >= 1) {
            /*errorMessage = "+ and - Symbol should not be used same time.";
            DisplayError(objField, errorMessage)
            objField.focus();*/
            $('#' + displayFieldName).attr('style', 'background-color: #F3B83E !important;margin-left: -6px; border-radius: 5px; height: 100%');
            $('<span id=err_' + displayFieldName + ' style="font-size:10px; position: absolute; bottom: -14px; color: red">' + msg + '</span>').insertAfter('#' + displayFieldName);
            return false;
        }
        else {
            $('#err_' + displayFieldName).remove();
            $('#' + displayFieldName).attr('style', 'background-color: transparent');
            return true;
        }
    }
    static ForceRFloat = function (strField, displayFieldName, msg = "Not a Valid Mobile No.") {
        $('#err_' + displayFieldName).remove();
        $('#' + displayFieldName).attr('style', 'background-color: transparent');

        let countminus = 0;
        let countplus = 0;
        let countpoint = 0;
        // let strField = new String(objField.val());

        if (this.isWhitespace(strField)) {
            /*errorMessage = displayFieldName + " cannot be left blank. Space Not Allowed";
            DisplayError(objField, errorMessage)
            objField.focus();
            objField.select();*/
            $('#' + displayFieldName).attr('style', 'background-color: #F3B83E !important;margin-left: -6px; border-radius: 5px; height: 100%');
            $('<span id=err_' + displayFieldName + ' style="font-size:10px; position: absolute; bottom: -14px; color: red">' + msg + '</span>').insertAfter('#' + displayFieldName);
            return false;
        }

        let i = 0;

        for (i = 0; i < strField.length; i++) {
            //alert(strField.charAt(i));
            if ((strField.charAt(i) < '0' || strField.charAt(i) > '9') && strField.charAt(i) != '.' && strField.charAt(i) != '-' && strField.charAt(i) != '+') {
                /*errorMessage = displayFieldName + " must be a valid numeric entry. Allowed speciel characters are (+, -, .)";
                DisplayError(objField, errorMessage)
                objField.focus();*/
                $('#' + displayFieldName).attr('style', 'background-color: #F3B83E !important;margin-left: -6px; border-radius: 5px; height: 100%');
                $('<span id=err_' + displayFieldName + ' style="font-size:10px; position: absolute; bottom: -14px; color: red">' + msg + '</span>').insertAfter('#' + displayFieldName);
                return false;
            }
            else if (strField.charAt(i) == '-') {
                countminus = countminus + 1;
            }
            else if (strField.charAt(i) == '+') {
                countplus = countplus + 1;
            }
            else if (strField.charAt(i) == '.') {
                countpoint = countpoint + 1;
            }
        }
        //alert(countminus);
        if (countminus > 1 || countplus > 1 || countpoint > 1) {
            /*errorMessage = "Any Symbol should be used only once";
            DisplayError(objField, errorMessage)
            objField.focus();*/
            $('#' + displayFieldName).attr('style', 'background-color: #F3B83E !important;margin-left: -6px; border-radius: 5px; height: 100%');
            $('<span id=err_' + displayFieldName + ' style="font-size:10px; position: absolute; bottom: -14px; color: red">' + msg + '</span>').insertAfter('#' + displayFieldName);
            return false;
        }
        else if (countminus >= 1 && countplus >= 1) {
            /*errorMessage = "+ and - Symbol should not be used same time.";
            DisplayError(objField, errorMessage)
            objField.focus();*/
            $('#' + displayFieldName).attr('style', 'background-color: #F3B83E !important;margin-left: -6px; border-radius: 5px; height: 100%');
            $('<span id=err_' + displayFieldName + ' style="font-size:10px; position: absolute; bottom: -14px; color: red">' + msg + '</span>').insertAfter('#' + displayFieldName);
            return false;
        }
        else {
            $('#err_' + displayFieldName).remove();
            $('#' + displayFieldName).attr('style', 'background-color: transparent');
            return true;
        }
    }

}