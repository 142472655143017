import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
//import * as encoding from 'text-encoding';
//import * as encoding from 'es5-shim';
/*import "react-app-polyfill/shim";*/
import 'webcrypto-shim';


import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import Root from './routes/Routes.dev.js';

import * as serviceWorker from './serviceWorker';

// import 'https://fonts.googleapis.com/css?family=Open+Sans|Roboto|Roboto+Slab&display=swap';

// import './assets/fonts/icomoon/style.css';
import './assets/fonts/flaticon/font/Flaticon.ttf';

import './assets/css/bootstrap.min.css';
// import './assets/css/owl.carousel.min.css';
import './assets/css/animate.css';
// import './assets/fonts/flaticon/font/flaticon.css';

// Main CSS
// import './assets/css/style1.css';
// import './assets/css/font-awesome.css';

import './assets/css/style.css';

import './assets/css/style-pr.css';

// import './assets/css/responsive.css';
import {prstore} from "../src/library/stores/pr-store";
/*import ReactGA from 'react-ga';
//import auth from './auth.ts'; // Sample authentication provider

const trackingId = "UA-171521658-1"; // Replace with your Google Analytics tracking ID
function initializeReactGA() {
    ReactGA.initialize('UA-171521658-1');
    ReactGA.pageview('/dashboard');
}*/

const crypto = require('@trust/webcrypto')
if (typeof window['TextEncoder'] !== 'function') {
    const TextEncodingPolyfill = require('text-encoding');
    window['TextEncoder'] = TextEncodingPolyfill.TextEncoder;
    window['TextDecoder'] = TextEncodingPolyfill.TextDecoder;
}

/*if(prstore.api.baseurl.includes("rest")){
    console = console || {};
    console.log = function(){};
}*/

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
