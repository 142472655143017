import React, { Component } from 'react';
import TableDesign from './tabledesign';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import { GetTechProcessFoundry } from '../../services/foundrypdk';
import { PrConnect } from '../../library/pr-miniredux';
export class tableDesignCtrl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetchTechProcess: false,
            techProcessFoundriesData: {},
            sort_by: ''
        }
    }

    UNSAFE_componentWillMount = () => {
        if (JSON.stringify(this.state.techProcessFoundriesData) === '{}')
            this.setState({
                fetchTechProcess: true
            })
    }

    SortData = (sortby) => {
        let data_arr = this.state.techProcessFoundriesData;
        let asc = '', dsc = '';
        if (sortby === 'foundry') {
            if (this.state.sort_by === '' || this.state.sort_by !== sortby) {
                asc = 1;
                data_arr.sort((a, b) => (a.foundry > b.foundry) ? 1 : ((b.foundry > a.foundry) ? -1 : 0));
            }
            else {
                dsc = 1;
                data_arr.sort((a, b) => (a.foundry < b.foundry) ? 1 : ((b.foundry < a.foundry) ? -1 : 0));
            }
        }
        else if (sortby === 'technode') {
            if (this.state.sort_by === '' || this.state.sort_by !== sortby) {
                asc = 1;
                data_arr.sort((a, b) => (a.processName > b.processName) ? 1 : ((b.processName > a.processName) ? -1 : 0));
            }
            else {
                dsc = 1;
                data_arr.sort((a, b) => (a.processName < b.processName) ? 1 : ((b.processName < a.processName) ? -1 : 0));
            }
        }
        else if (sortby === 'status') {
            if (this.state.sort_by === '' || this.state.sort_by !== sortby) {
                asc = 1;
                data_arr.sort((a, b) => (a.status > b.status) ? 1 : ((b.status > a.status) ? -1 : 0));
            }
            else {
                dsc = 1;
                data_arr.sort((a, b) => (a.status < b.status) ? 1 : ((b.status < a.status) ? -1 : 0));
            }
        }
        else if (sortby === 'techclass') {
            if (this.state.sort_by === '' || this.state.sort_by !== sortby) {
                asc = 1;
                data_arr.sort((a, b) => (a.techClass > b.techClass) ? 1 : ((b.techClass > a.techClass) ? -1 : 0));
            }
            else {
                dsc = 1;
                data_arr.sort((a, b) => (a.techClass < b.techClass) ? 1 : ((b.techClass < a.techClass) ? -1 : 0));
            }
        }
        if (asc === 1) {
            this.setState({
                techProcessFoundriesData: data_arr,
                sort_by: sortby,
                sort_by_1: ''
            })
        }
        else if (dsc === 1) {
            this.setState({
                techProcessFoundriesData: data_arr,
                sort_by_1: sortby,
                sort_by: ''
            })
        }
    }

    HandleSuccessGetTechProcessFoundriesResponse = (res) => {
        this.setState({
            fetchTechProcess: false,
            techProcessFoundriesData: res.data
        })
    }

    render() {
        return (
            <div>
                {/* <div className='popup_inner'> */}
                    {/* <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_CENTER} /> */}
                    <TableDesign {...this.props} state={this.state} SortData={this.SortData} techProcessFoundriesData={this.techProcessFoundriesData} />
                    {this.state.fetchTechProcess === true ? GetTechProcessFoundry(this.props.PrStore, this.HandleSuccessGetTechProcessFoundriesResponse) : ''}
                {/* </div> */}
            </div>
        )
    }
}
export const TableDesignCtrl = PrConnect(tableDesignCtrl, '');