import React, { Component } from 'react';
import FloatingLabelInput from 'react-floating-label-input';
import $ from 'jquery';
import DatePicker from 'react-date-picker'
export default class DesignSubmission extends Component {
    constructor(props) {
        super(props);

    }

    ClearMouse = () => {
        $('.inner_table').css('display', 'none');
    }

    render() {
        return (
            <div>
                <div id="page-wrapper">
                    <div id="page-inner" style={{ background: '#fff' }}>
                        <div className="page_head col-xl-8 mx-auto" style={{ marginLeft: 17 + '%' }}>
                            <h1 className="page-header">Design Submission Request</h1>
                            {JSON.stringify(this.props.state.result) === '{}' ?
                                <div>
                                    <div className="account">
                                        <div className="header">
                                            <h3>Design Fabrication Information</h3>
                                        </div>
                                        <div className="accoutn_form">
                                            <form action="" method="">
                                                <div className="dashboard_form">
                                                    <div className="inner-dashoard_form">
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="brd_section">
                                                                    <div className="row new_pos">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>Select Foundry</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <div className="custom_select">
                                                                                        <select onChange={this.props.HandleChange} name="ddlFoundry" id="ddlFoundry" value={this.props.state.ddlFoundry} >
                                                                                            <option value=''>Select</option>
                                                                                            {(JSON.stringify(this.props.foundries) !== '{}' && this.props.foundries.length > 0) ? this.props.foundries.map(item =>
                                                                                                <option value={item.codeId}>{item.codeName}</option>
                                                                                            ) : ''}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="brd_section">
                                                                    <div className="row new_pos">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>Select Technology Node</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <div className="custom_select">
                                                                                        <select onChange={this.props.HandleChange} name="ddlTechNode" id="ddlTechNode" value={this.props.state.ddlTechNode} >
                                                                                            <option value=''>Select</option>
                                                                                            {(JSON.stringify(this.props.techNodes) !== '{}' && this.props.techNodes.length > 0) ? this.props.techNodes.map(item =>
                                                                                                <option value={item.technologyProcessId}>{item.processName}</option>
                                                                                            ) : ''}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="brd_section">
                                                                    <div className="row new_pos">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>Run Type</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <div className="custom_select">
                                                                                        <select onChange={this.props.HandleChange} name="ddlRunType" id="ddlRunType" value={this.props.state.ddlRunType} >
                                                                                            <option value=''>Select</option>
                                                                                            {(JSON.stringify(this.props.runType) !== '{}' && this.props.runType.length > 0) ? this.props.runType.map(item =>
                                                                                                <option value={item.codeId}>{item.codeName}</option>
                                                                                            ) : ''}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12">
                                                                <div className="brd_section">
                                                                    <div className="row new_pos">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>Projected Date of Preliminary Design Upload</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="form-group">
                                                                                <div className="dateParentDiv" id="preliminaryDesignUploadDate">
                                                                                    <DatePicker name="preliminaryDesignUploadDate"
                                                                                        format="MM/dd/y"
                                                                                                minDate={new Date()}
                                                                                        onChange={this.props.HandlePreliminaryDesignUploadDateChange}
                                                                                        onBlur={() => this.props.HandleOnBlur('ForceDate', 'preliminaryDesignUploadDate', this.props.state.preliminaryDesignUploadDate, 'Preliminary Design Upload Date')}
                                                                                        value={this.props.state.preliminaryDesignUploadDate} clearIcon={null}
                                                                                        maxDate={this.props.state.max_preliminari_date}
                                                                                        calendarIcon={<img src={require('../../../assets/images/calendar.svg')} alt="calendar" width={14} />}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12">
                                                                <div className="brd_section">
                                                                    <div className="row new_pos">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>Projected Date of Final (Clean) Design Upload</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="form-group">
                                                                                <div className="dateParentDiv" id="projectedFinalDesignUploadDate">
                                                                                    <DatePicker name="projectedFinalDesignUploadDate"
                                                                                        format="MM/dd/y"

                                                                                        onChange={this.props.HandleProjectedFinalDesignUploadDateChange}
                                                                                        onBlur={() => this.props.HandleOnBlur('ForceDate', 'projectedFinalDesignUploadDate', this.props.state.projectedFinalDesignUploadDate, 'Projected Final Upload Date')}
                                                                                        value={this.props.state.projectedFinalDesignUploadDate} clearIcon={null}
                                                                                        minDate={this.props.state.min_projected_date}
                                                                                                //minDate={new Date(this.props.state.projectedFinalDesignUploadMinDate)}
                                                                                        calendarIcon={<img src={require('../../../assets/images/calendar.svg')} alt="calendar" width={14} />}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="brd_section border-none">
                                                                    <div className="row new_pos" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>Projected Number of Design uploads</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <input type="text" id="txtProjectedNoOfDesign" name="txtProjectedNoOfDesign" value={this.props.state.txtProjectedNoOfDesign} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceRNumber', 'txtProjectedNoOfDesign', this.props.state.txtProjectedNoOfDesign, '')} className="form-control" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="account">
                                        <div className="header">
                                            <h3>Die size (pre-shrink) square mm</h3>
                                        </div>
                                        <div className="accoutn_form">
                                            <form action="" method="">
                                                <div className="dashboard_form">
                                                    <div className="inner-dashoard_form">
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="brd_section">
                                                                    <div className="row new_pos">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>X dimension (mm)</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <input type="text" id="txtXDimension" name="txtXDimension" onClick={this.props.highlightText} value={this.props.state.txtXDimension} onBlur={() => this.props.HandleOnBlur('ForceRNumber', 'txtXDimension', this.props.state.txtXDimension, '')} onChange={this.props.HandleChange} className="form-control" />
                                                                                </div>
                                                                                <label style={{display: this.props.state.showXDimensionError?"block":"none", fontStyle: "italic", fontSize: "10px", position: "relative", color: "red"}}>Cannot be 0</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12">
                                                                <div className="brd_section">
                                                                    <div className="row new_pos">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>Y dimension (mm)</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <input type="text" id="txtYDimension" name="txtYDimension" onClick={this.props.highlightText} value={this.props.state.txtYDimension} onBlur={() => this.props.HandleOnBlur('ForceRNumber', 'txtYDimension', this.props.state.txtYDimension, '')} onChange={this.props.HandleChange} className="form-control" />
                                                                                </div>
                                                                                <label style={{display: this.props.state.showYDimensionError?"block":"none", fontStyle: "italic", fontSize: "10px", position: "relative", color: "red"}}>Cannot be 0</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12">
                                                                <div className="brd_section">
                                                                    <div className="row new_pos">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>Die size (sq mm)</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <input type="text" disabled="disabled" id="txtDieSize" name="txtDieSize" value={this.props.state.txtDieSize} onBlur={() => this.props.HandleOnBlur('ForceRNumber', 'txtDieSize', this.props.state.txtDieSize, '')} onChange={this.props.HandleChange} className="form-control" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12">
                                                                <div className="brd_section">
                                                                    <div className="row new_pos">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>Number of die to be fabricated </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="form-group">
                                                                                <input type="text" id="txtNoDieToFabricated" name="txtNoDieToFabricated" value={this.props.state.txtNoDieToFabricated} onBlur={() => this.props.HandleOnBlur('ForceRNumber', 'txtNoDieToFabricated', this.props.state.txtNoDieToFabricated, '')} onChange={this.props.HandleChange} className="form-control" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="brd_section">
                                                                    <div className="row new_pos">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>Number of die to be packaged</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="form-group">
                                                                                <input type="text" id="txtNoDieToPackaged" name="txtNoDieToPackaged" value={this.props.state.txtNoDieToPackaged} onBlur={() => this.props.HandleOnBlur('ForceRNumber', 'txtNoDieToPackaged', this.props.state.txtNoDieToPackaged, '')} onChange={this.props.HandleChange} className="form-control" />
                                                                            </div>
                                                                            <label style={{display: this.props.state.showNoDieToPackagedError?"block":"none", fontStyle: "italic", fontSize: "10px", position: "relative", color: "red"}}>Number of die to be packaged cannot be more than number of die to be fabricated</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="brd_section border-none">
                                                                    <div className="row new_pos">
                                                                        <div className="col-lg-4 col-md-4 col-sm-6">
                                                                            <div className="inner_left">
                                                                                <label>Select Type of Packaging</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-8 col-md-8 col-sm-6">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <div className="custom_select">
                                                                                        <select onChange={this.props.HandleChange} name="ddlPackageType" id="ddlPackageType" value={this.props.state.ddlPackageType} >
                                                                                            <option value=''>Select</option>
                                                                                            {(JSON.stringify(this.props.packageType) !== '{}' && this.props.packageType.length > 0) ? this.props.packageType.map(item =>
                                                                                                <option value={item.codeId}>{item.codeName}</option>
                                                                                            ) : ''}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button type="button" onClick={this.props.SubmitDesign} className="dash_submit_btn">Submit</button>
                                    </div>
                                </div>
                                :
                                <div className="design_req">
                                    <table style={{ width: '100%', border: '2px solid #6f6f6f' }}>
                                        <tr>
                                            <th>Your new design number is </th>
                                            <th><span>{this.props.state.result.designNumber}</span></th>
                                        </tr>
                                        <tr>
                                            <td>Foundry</td>
                                            <td>{this.props.state.result.foundryObject.codeValue}</td>
                                        </tr>
                                        <tr>
                                            <td>Technology Node</td>
                                            <td>{this.props.state.result.techProcessObject.processName}</td>
                                        </tr>
                                        <tr>
                                            <td>Run Type</td>
                                            <td>{this.props.state.result.runTypeObject.codeValue}</td>
                                        </tr>
                                        <tr>
                                            <td>Projected Date fo Preliminary Design Upload</td>
                                            <td>{(this.props.state.result.projectedDatePreliminaryUpload).slice(5, 7) + "/" + (this.props.state.result.projectedDatePreliminaryUpload).slice(8, 10) + "/" + (this.props.state.result.projectedDatePreliminaryUpload).slice(0, 4)}</td>
                                        </tr>
                                        <tr>
                                            <td>Pojected Date of Final (clean) Design Upload</td>
                                            <td>{(this.props.state.result.projectedDateFinalUpload).slice(5, 7) + "/" + (this.props.state.result.projectedDateFinalUpload).slice(8, 10) + "/" + (this.props.state.result.projectedDateFinalUpload).slice(0, 4)}</td>
                                        </tr>
                                        <tr>
                                            <td>Projected Number of Design uploads </td>
                                            <td>{this.props.state.result.numberofDesignUploads}</td>
                                        </tr>
                                        <tr>
                                            <td> <span style={{ fontWeight: 600 }}>Die size (pre-shrink) square mm </span></td>
                                            <td>{this.props.state.result.dieSizeXY}</td>
                                        </tr>
                                        <tr>
                                            <td>X dimension (mm)</td>
                                            <td>{this.props.state.result.dieSizeX}</td>
                                        </tr>
                                        <tr>
                                            <td>Y dimension (mm)</td>
                                            <td>{this.props.state.result.dieSizeY}</td>
                                        </tr>

                                        <tr>
                                            <td>Number of die to be fabricated</td>
                                            <td>{this.props.state.result.numberOfDieToFabricate}</td>
                                        </tr>
                                        <tr>
                                            <td>Number of die to be packaged</td>
                                            <td>{this.props.state.result.numberOfDieToPackage}</td>
                                        </tr>
                                        <tr>
                                            <td>Select Type of Packaging</td>
                                            <td>{this.props.state.result.hasOwnProperty('packagingTypeObject') ? this.props.state.result.packagingTypeObject.codeValue : ''}</td>
                                        </tr>
                                    </table>
                                    <div className="text-center" style={{paddingTop:"2%"}}>
                                        <button type="button" onClick={this.props.onOkClick} style={{backgroundColor: '#dfdfdf', color: '#990000'}} className="dash_submit_btn">OK</button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}