import React, { Component } from 'react';

export default class LoginComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <div className="login_top">
                <div className="d-flex align-items-center justify-content-center h-100">
                    <div className="login_inner animated zoomIn">
                        <div className="card-login">
                            <h3 className="" data-text="">Login</h3>
                        </div>
                        <div className="login-body">
                            <form>
                                <div className="input-group form-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><img src={require('../../assets/images/email.svg')} alt="email_logo" /></span>
                                    </div>
                                    <input type="email" id="txtLoginEmail" name="txtLoginEmail" value={this.props.state.txtLoginEmail} onChange={this.props.HandleChange} className="form-control" placeholder="Email" />

                                </div>
                                <div className="input-group form-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><img src={require('../../assets/images/lock.svg')} alt="email_logo" /></span>
                                    </div>
                                    <input type="password" id="txtLoginPassword" name="txtLoginPassword" value={this.props.state.txtLoginPassword} onChange={this.props.HandleChange} className="form-control" placeholder="Password" />
                                </div>
                                <div className="row remember">
                                    <div className="col-md-6 col-sm-6">
                                        <label className="check">
                                            <input type="checkbox" checked={this.props.state.isChecked} onChange={ this.props.HandleChecked }/>Remember Me
                                    <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <a href="" className="forgot">Forgot Password?</a>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <a href="" className="activate">Activate New Account</a>
                                    </div>
                                </div>
                                <div className="form-group clearfix" style={{textAlign: 'center'}}>
                                    <input type="button" value="Login" className="btn login_btn" onClick={this.props.LoginUser} />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}