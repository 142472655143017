import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import MosisProcedureGuidance1116 from '../../../src/assets/docs/MOSIS Procedure Guidance 1116.pdf';
import "../../assets/css/nsf.css"

export default class NSFComponent extends Component{
    render(){
        return(
            <div>
                <section className="about_bg">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-4 mx-auto">
                                <div className="site-logo text-center mb-5">
                                    <NavLink to="/" style={{position:'relative', zIndex:'2000'}}>
                                        <img src={require('../../assets/images/logo.png')} alt="logo" />
                                    </NavLink>
                                </div>
                            </div>
                        </div>



                        <div className="col-lg-10 mx-auto">
                            <h2 className="text-center" style={{fontSize:"41px"}}>Procedure Guidance for Design Projects Under National Science Foundation ACEDFab Program</h2>
                        </div>

                        <br/>
                        <br/>
                        <div className="row">
                            <div className="col-lg-10 mx-auto">
                                <div className="text-left">
                                    Download File: &nbsp;&nbsp;<a className="linkBlueColor" href={MosisProcedureGuidance1116} download="MOSIS Procedure Guidance 1116.pdf">MOSIS Procedure Guidance 1116.pdf</a>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-10 mx-auto">
                                <div className="text-left">
                                    <p>This document provides guidance of task procedures with The MOSIS Service for a design project under National Science Foundation ACEDFab Program. In the following sections, step-by-step, standardized procedures of tasks with The MOSIS Service are listed and explained. The procedures start with new customer registration for MOSIS account, legal documents, and end at the final design rule check before tapeout. The Principal Investigator of the university’s design team will perform the design task procedures</p>
                                    <p>Here is the summary of the procedures. The step-by-step instructions of each procedure are provided in the next sections.</p>
                                    <ul style={{fontWeight:"bold"}}>
                                        <li>New customer registration for MOSIS account</li>
                                        <li>Legal documents</li>
                                        <li>MOSIS website user registration</li>
                                        <li>Design submission request</li>
                                        <li>PDK request</li>
                                        <li>PDK download</li>
                                        <li>Registration for MOSIS ticket system</li>
                                        <li>Filing tickets for design debugging</li>
                                        <li>Design file upload</li>
                                        <li>Design rule check procedures</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <br/>
                        <div className="col-lg-10 mx-auto noLeftPadding">
                            <h2 className="text-left" style={{fontSize:"33px"}}>New customer registration for MOSIS account</h2>
                        </div>
                        <div className="row">
                            <div className="col-lg-10 mx-auto">
                                <div className="text-left">
                                    <p>
                                        If the university already has had an active account with The MOSIS Service, The MOSIS Service will work with the Principal Investigator to add him/her as a Design Manger to the account. The Principal Investigator will be able to login the MOSIS account at <a className="linkBlueColor" href="https://www.themosisservice.com">https://www.themosisservice.com</a>. To create a new MOSIS account:
                                    </p>
                                    <ul>
                                        <li>MOSIS sends the MOSIS Customer Account information form to the university through email</li>
                                        <li>From the email, click on EDIT link to go to the MOSIS Customer Account information form. Enter the following information:
                                            <ul>
                                                <li>Organization Name, website URL, Federal Taxpayer ID Number (TIN),</li>
                                                <li>Account Manager</li>
                                                <li>Authorized Official for Signing Legal Documents</li>
                                                <li>Authorized Official for Certifying Export Documents</li>
                                                <li>Design Manager(s). The Principal Investigator is expected to be the primary Design Manager</li>
                                                <li>Legal Organization Physical Address, Legal Organization Mailing Address, and Legal Organization Shipping Address</li>
                                                <li>Paying Organization</li>
                                                <li>Invoice method</li>
                                            </ul>
                                        </li>
                                        <li>Upon completion of the MOSIS Customer Account information form, MOSIS works with the authorized person of the university to complete the signage of the MOSIS Customer Agreement and then activates the account</li>
                                        <li>A welcome message will be sent to the Account Manager with instructions about account portal access on The MOSIS Service website</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-10 mx-auto noLeftPadding">
                            <h2 className="text-left" style={{fontSize:"33px"}}>Legal documents</h2>
                        </div>
                        <div className="row">
                            <div className="col-lg-10 mx-auto">
                                <div className="text-left">
                                    <p>
                                        The MOSIS Service works with the authorized legal official of the university and TSMC’s legal official to process for execution the required legal documents, including Foundry’s Non-Disclosure Agreement (NDA) and Foundry’s University Master Technology Usage Agreement (MTUA) for the participating entities of the Program.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div className="container-fluid">
                        <div className="col-lg-10 mx-auto noLeftPadding">
                            <h2 className="text-left " style={{fontSize:"33px"}}>MOSIS website user registration</h2>
                        </div>
                        <div className="row">
                            <div className="col-lg-10 mx-auto">
                                <div className="text-left">
                                    <p>
                                        Each member listed on the customer account will register their own login to the account at https://www.themosisservice.com. Depending on their roles, users will have different profiles for accessing the account. The MOSIS Service uses OKTA platform for customer account login and authentication. The following are the steps to register for user’s OKTA login to the account. The same login credentials also apply to the MOSIS ticket system for Principal Investigator to file tickets for resolving design issues (to be elaborated on Pages 3 and 4)
                                    </p>

                                    <ul>
                                        <li>Go to <a className="linkBlueColor" href="https://www.themosisservice.com">https://www.themosisservice.com</a></li>
                                        <li>Click on the Login link on the top right of the page to open the MOSIS Service private-web portal</li>
                                        <li>Click on the Sign Up link at the bottom of the page to open the OKTA form</li>
                                        <li>Enter the email listed on the account, select password, provide Last Name and First Name, and click on Register button</li>
                                        <li>At the Set up Multi-Factor Authentication screen, select method of authentication to complete the setup process.
                                            <ul>
                                                <li>Google Authenticator (Enter single-use code from the mobile application)</li>
                                                <li>SMS Authentication (Enter a single-use code sent to your mobile phone)</li>
                                                <li>Voice Call Authentication (Use a phone to authenticate by following voice instructions)</li>
                                            </ul>
                                        </li>
                                        <li>A confirmation email with instruction to use OKTA authentication will be sent to user’s email upon successful user registration</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div className="container-fluid">
                        <div className="col-lg-10 mx-auto noLeftPadding">
                            <h2 className="text-left " style={{fontSize:"33px"}}>Design submission request</h2>
                        </div>
                        <div className="row">
                            <div className="col-lg-10 mx-auto">
                                <div className="text-left">
                                    <p>
                                        This procedure is for MOSIS to receive and record the essential parameters of the design that the university plans to deliver.
                                    </p>
                                    <ul>
                                        <li>Go to <a className="linkBlueColor" href="https://www.themosisservice.com">https://www.themosisservice.com</a></li>
                                        <li>Click on Login link to sign into to the account with your email and password.</li>
                                        <li>From the left menu list, under Design Management menu, select Design Submission Request.
                                            <ul>
                                                <li>Under the Design Fabrication Information:
                                                    <ul>
                                                        <li>Select Foundry, Technology Node, Run Type from the dropdown menu</li>
                                                        <li>Enter the Projected Date for Preliminary Design Upload</li>
                                                        <li>Enter the Projected Date for Final (clean) Design Upload</li>
                                                        <li>Enter the Projected number of Design upload</li>
                                                    </ul>
                                                </li>
                                                <li>Under Design Size (Pre-shrink) square mm section:
                                                    <ul>
                                                        <li>Enter x-dimension (mm), y-dimension (mm), design size (in sq mm).</li>
                                                        <li>Enter Number of Dies to be fabricated</li>
                                                        <li>Enter Number of Dies to be packaged</li>
                                                        <li>Select Type of packaging from the pull-down menu</li>
                                                        <li>Click on SUBMIT button to request a design submission</li>
                                                        <li>A confirmation email with a new design number and submitted design information will be sent to the submitter</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div className="container-fluid">
                        <div className="col-lg-10 mx-auto noLeftPadding">
                            <h2 className="text-left " style={{fontSize:"33px"}}>PDK request</h2>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-lg-10 mx-auto">
                                <div className="text-left">

                                    <ul>
                                        <li>Go to <a className="linkBlueColor" href="https://www.themosisservice.com">https://www.themosisservice.com</a></li>
                                        <li>Click on Login link to sign into to the account with your email and password</li>
                                        <li>From the left menu list, under Foundry IP (PDK) menu, select Foundry File Access Request</li>
                                        <li>Select answer to the question of whether the PDK request is for the existing design number
                                            <ul>
                                                <li>If yes, select the design number from the pull-down list</li>
                                                <li>If no, enter the Foundry Technology Class</li>
                                            </ul>
                                        </li>
                                        <li>Select the requested file type from the pull-down list</li>
                                        <li>Click on SUBMIT button to complete the request</li>
                                        <li>A confirmation email will be sent to the requestor</li>
                                        <li>MOSIS staff will review the request. Upon the approval, an email with instruction to download the PDK/IP files will be sent to the requestor</li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>

                    <br/>
                    <div className="container-fluid">
                        <div className="col-lg-10 mx-auto noLeftPadding">
                            <h2 className="text-left " style={{fontSize:"33px"}}>PDK download</h2>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-lg-10 mx-auto">
                                <div className="text-left">

                                    <ul>
                                        <li>Upon receiving the email notification that your PDK/IP files are available for download, follow the SFTP download instructions attached to that email to secure your files
                                            <ul>
                                                <li>“ sftp -P 2121 <a className="linkBlueColor" href="mailto:&#123;customer_email&#125;@sftp.themosisservice.com">&#123;customer_email&#125;@sftp.themosisservice.com</a> “ where &#123;customer_email&#125; is your email you use to log in to themosisservice.com (ex. john.doe@usc.edu@sftp.themosisservice.com). You will be prompted to enter your password which is your OKTA password used to sign in to themosisservice.com</li>
                                                <li>“ cd pdk_files/mosis-tsmc/&#123;technology}&#125; “ Example &#123;technology&#125; would be 16nm</li>
                                                <li>“ cd PDK/Documents/ “ and “ cd PDK/Techfiles/ “ to find the technology-related PDK design documents and technology files, respectively. “ cd IP/ “ to find the technology-related IP. Use the sftp “get” command to download these files</li>
                                            </ul>
                                        </li>
                                        <li>Included in the downloaded files is a README spreadsheet. Please review this as it contains the list of the files available, their TSMC document number, the TSMC document title, and the revision number. This can be reviewed to aide in determining exactly what files you might need to complete your design (for example, Calibre DRC decks if you use Calibre; whereas you might not need PVS DRC decks if you do not use PVS in your design flow)</li>
                                        <li>Most download files are compressed using standard compression commands. These could be .zip, .gz, .tar, .tgz, .tar.gz, etc. Please use the corresponding Linux commands to extract these file (ex. ‘tar -zxvf filename.tar.gz’</li>
                                        <li>Please review all documents, release notes, and README files that accompany the various document downloads for installation or use of the downloads</li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>

                    <br/>
                    <div className="container-fluid">
                        <div className="col-lg-10 mx-auto noLeftPadding">
                            <h2 className="text-left " style={{fontSize:"33px"}}>Registration for MOSIS ticket system</h2>
                        </div>
                        <div className="row">
                            <div className="col-lg-10 mx-auto">
                                <div className="text-left">
                                    <p>
                                        Upon the university’s completion of MOSIS customer account and the legal documents, The MOSIS Service’s staff will support registration of the Principal Investigator on MOSIS Help Desk system (https://helpdesk.themosisservice.com). After the registration is complete, the Principal Investigator will be able to login the Help Desk system and file tickets for design debugging. The following section describes the steps of the ticket filing and debugging procedure.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br/>
                    <div className="container-fluid">
                        <div className="col-lg-10 mx-auto noLeftPadding">
                            <h2 className="text-left " style={{fontSize:"33px"}}>Filing tickets for design debugging</h2>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-lg-10 mx-auto">
                                <div className="text-left">

                                    <ul>
                                        <li>Log in to the MOSIS Help Desk – <a className="linkBlueColor" href="https://helpdesk.themosisservice.com">https://helpdesk.themosisservice.com</a> – with your username, password, and the multi-factor authentication</li>
                                        <li>To create a new ticket:
                                            <ul>
                                                <li>Click on the “New ticket” button in the upper right corner</li>
                                                <li>Fill in the Subject for your issue</li>
                                                <li>Provide a detailed description of your issue in the “Ticket details” section</li>
                                                <li>Optionally, you can attach files or capture your screen</li>
                                                <li>Click the “Submit” button</li>
                                            </ul>
                                        </li>

                                        <li>MOSIS engineers will work with you through the ticketing system to resolve your issue
                                            <ul>
                                                <li>You’ll be notified through email of updates to the ticket when updates have been made</li>
                                            </ul>
                                        </li>
                                        <li>To view an existing ticket:
                                            <ul>
                                                <li>Click on the ticket you wish to view</li>
                                                <li>You can then reply to the ticket to provide further information or make further comments</li>
                                            </ul>
                                        </li>
                                        <li>When the ticket has been satisfactorily resolved, please close the ticket. You can do this by selecting the ticket and clicking on the “Close ticket” button at the top of the ticket</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br/>
                    <div className="container-fluid">
                        <div className="col-lg-10 mx-auto noLeftPadding">
                            <h2 className="text-left " style={{fontSize:"33px"}}>Design file upload</h2>
                        </div>
                        <div className="row">
                            <div className="col-lg-10 mx-auto">
                                <div className="text-left">
                                    <p>
                                        This procedure is mainly for the university to upload the design file for the DRC checks and analyses as described in the next section.
                                    </p>

                                    <ul>
                                        <li>Log in to your account at <a className="linkBlueColor" href="https://www.themosisservice.com">https://www.themosisservice.com</a>.</li>
                                        <li>Under the “Design Management” tab on the menu to the left is a selection for “Design Upload”</li>
                                        <li>Fill out the form answering all questions for the “Design Upload Request” (example, topcell name)</li>
                                        <li>There will also be a question asking if your file is greater than 10GB. If less than 10GB, use the upload feature through HTTPS. If your file is greater than 10GB, you will receive instructions for uploading your file through SFTP.</li>
                                        <li>Acceptable file upload formats for GDS or OASIS design files are: .gds, .oas, .gz, .Z, .zip, .bz2, .tar, .tgz</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br/>
                    <div className="container-fluid">
                        <div className="col-lg-10 mx-auto noLeftPadding">
                            <h2 className="text-left " style={{fontSize:"33px"}}>Design rule check procedures</h2>
                        </div>
                        <div className="row">
                            <div className="col-lg-10 mx-auto">
                                <div className="text-left">
                                    <p>
                                        DRC clean is the common and fundamental criterion of tapeout sign-off. A design not meeting DRC clean at the tapeout stage causes a failure. The MOSIS Service performs DRC and provides analyses for up to three different design versions. Please meet the DRC clean criteria as elaborated in the following instructions. DRC waivers are up to the Foundry to approve or reject. The MOSIS Service strongly recommends the Principal Investigators not to plan DRC waivers. Director of The MOSIS Service will track the design status and ensure the Principal Investigator and The MOSIS Service are aligned on the tasks targeting successful tapeout.
                                    </p>

                                    <ul>

                                        <li>Upon receiving a design upload, MOSIS engineering will perform a series of checks, primarily the Design Rule Check (DRC) on the design to ensure expectations are met for manufacturing</li>
                                        <li>MOSIS engineering will provide email feedback communication with the designer to discuss any issues found and help to find a resolution</li>
                                        <li>For the first design submission, it is expected that the design to
                                            <ul>
                                                <li>have the same dimensions as the final submission will be</li>
                                                <li>contain all the design layers that will be found in the final submission</li>
                                                <li>contain all the devices that will be included in the final submission</li>
                                                <li>be at least 20% complete and DRC clean within these blocks</li>
                                                <li>incorporate the Dummy TCD and ICOVL cells as described in the design rule manual</li>
                                            </ul>
                                        </li>

                                        <li>For the second design submission, the design should be
                                            <ul>
                                                <li>at least 80% complete and DRC clean with the included blocks</li>
                                                <li>if there are any DRC waivers that will be requested, it is expected that the waiver requests be submitted at this time</li>
                                            </ul>
                                        </li>
                                        <li>For the third design submission, the design must be 100% complete and DRC clean. This design needs to be ready for submission to the foundry for fabrication. MOSIS engineers will review and provide the feedback verification that the design is ready for tapeout</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>



                </section>
            </div>
        )
    }
}