import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';


export default class PressReleaseComponent extends Component {
    constructor(props) {
        super(props)
        console.log()
    }

    render() {
        return (
            <section className="press-release press_release_bg">
                <div class="container-fluid">
                    <div className="row">
                        <div className="col-4 mb-5 mx-auto">
                            <div className="site-logo text-center pb-5">
                                <NavLink to="/" style={{position:'relative', zIndex:'2000'}}>
                                    <img src={require('../../assets/images/logo.png')} alt="logo" />
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
                    <div className="container">


                        <div className="row">
                            <div className="col-xs-12 ">
                                {/* <nav>
                                    <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                                        {JSON.stringify(this.props.state.years) !== '{}' ? (this.props.state.years).map((year, i) =>
                                            i === 0 ?
                                                <a className="nav-item nav-link active" id={'nav-home-' + year} data-toggle="tab" href={'#id_' + year} role="tab" aria-controls="nav-home" aria-selected="true">{year}</a>
                                                :
                                                <a className="nav-item nav-link" id={'nav-home-' + year} data-toggle="tab" href={'#id_' + year} role="tab" aria-controls="nav-home" aria-selected="false">{year}</a>
                                        ) : ''}
                                    </div>
                                </nav> */}
                                <div className="tab-content" id="nav-tabContent">

                                    <div className="mosis_hdng">
                                        <h2 className="text-center mt-0 mb-5">Press Release</h2>
                                    </div>

                                    {this.props.pReleaseDate.length > 0 ? this.props.pReleaseDate.map((ReleaseData, i) =>
                                        // return (
                                        <div className="tab-pane fade show active" id={'id_' + (ReleaseData.pressReleaseDate).slice(0, 4)} role="tabpanel" aria-labelledby="nav-home-tab">
                                            <div key={ReleaseData.pressReleaseId} className="press-release-home">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="month-name">
                                                            <h4>{(ReleaseData.pressReleaseDate).slice(8, 10)}</h4>
                                                            <p>{(ReleaseData.pressReleaseDate).slice(5, 7) + '/' + (ReleaseData.pressReleaseDate).slice(8, 10) + '/' + (ReleaseData.pressReleaseDate).slice(0, 4)}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className="press-details">
                                                            <h3>{ReleaseData.pressReleaseHeader} </h3>
                                                            <p>
                                                                <span>
                                                                    {ReleaseData.mpressReleaseDetailsObject[0].pressReleaseDetail ? ReleaseData.mpressReleaseDetailsObject[0].pressReleaseDetail.split("\n").map((i, key) =>
                                                                        ReleaseData.pressReleaseHeader != "New Website Launch Announcement"?
                                                                        key === 0 ?
                                                                            <p style={{ textAlign: 'justify' }} key={key}>{i}</p>
                                                                            : ''
                                                                            :key === 0 || key === 1 || key === 2?
                                                                            key === 1?
                                                                            <p style={{ textAlign: 'justify' }} key={key}>&emsp;&emsp;&emsp;{i}</p>:<p style={{ textAlign: 'justify' }} key={key}>{i}</p>
                                                                            : ''
                                                                    ) : ''}
                                                                </span>
                                                                <button className="ml-1 btn btn-sm learn_more_btn" onClick={() => this.props.ReadMorePressRelease((ReleaseData.pressReleaseDate).slice(0, 10))}>Read More</button>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        // )
                                    ) : ''}
                                    <div style={{ marginBottom: 50 }}></div>

                                    {/* <div className="tab-pane fade" id="two" role="tabpanel" aria-labelledby="nav-profile-tab">
                                            
                                            <div className="press-release-home">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="month-name">
                                                            <h4>26</h4>
                                                            <p>December</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className="press-details">
                                                        <h3>Statement From SMIC On False Reporting of Some Korean Media On EUV</h3>
                                                            <p>
                                                                <span>In recent days, some Korean media, such as Busines Korea, published various false newsreport quoting the views o Dr. Zhou Zixue, Chairman</span>
                                                                <button className="ml-1 btn btn-sm learn_more_btn">Read More</button>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="press-release-home">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="month-name">
                                                            <h4>20</h4>
                                                            <p>December</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className="press-details">
                                                        <h3>Statement From SMIC On False Reporting of Some Korean Media On EUV</h3>
                                                            <p>
                                                                <span>In recent days, some Korean media, such as Busines Korea, published various false newsreport quoting the views o Dr. Zhou Zixue, Chairman</span>
                                                                <button className="ml-1 btn btn-sm learn_more_btn">Read More</button>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="press-release-home">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="month-name">
                                                            <h4>15</h4>
                                                            <p>December</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className="press-details">
                                                        <h3>Statement From SMIC On False Reporting of Some Korean Media On EUV</h3>
                                                            <p>
                                                                <span>In recent days, some Korean media, such as Busines Korea, published various false newsreport quoting the views o Dr. Zhou Zixue, Chairman</span>
                                                                <button className="ml-1 btn btn-sm learn_more_btn">Read More</button>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="press-release-home">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="month-name">
                                                            <h4>10</h4>
                                                            <p>December</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className="press-details">
                                                        <h3>Statement From SMIC On False Reporting of Some Korean Media On EUV</h3>
                                                            <p>
                                                                <span>In recent days, some Korean media, such as Busines Korea, published various false newsreport quoting the views o Dr. Zhou Zixue, Chairman</span>
                                                                <button className="ml-1 btn btn-sm learn_more_btn">Read More</button>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}


                                </div>
                            </div>
                        </div>




                    </div>
            </section>
        )
    }
}