import { prstore } from "../stores/pr-store";
import axios from 'axios';

export class PrHttp_class {
    constructor() {
    }
}
PrHttp_class.Get = function (url, prProvider, config) {
    //alert("in http class" +JSON.stringify(config));
    let myurl = prstore.api.baseurl + "/" + url;
    return axios.get(myurl, config)
        .then(res => {
            return res;
        },
            error => {
                let prettyMessage = 'Your session has expired. Please login again';
                let errorMessage = 'Your session has expired. Please login again.';
                let errorCode = '';
                let errorType = '';
                if (error.response) {
                    if (error.response.data.message !== '' && error.response.data.message !== undefined) {

                        console.log("window.location.href: "+window.location.href.split("dashboard")[1])
                        console.log("url: "+url)
                        if((window.location.href.split("dashboard")[1] != "/design-upload" && url != "customerQuestionAnswer/getDesignNumberList") &&
                            (window.location.href.split("dashboard")[1] != "/foundry-file-access-request" && url != "designNumberInfo/getDesignNumberList")){
                            console.log("INSIDE IF")
                            prettyMessage = error.response.data.message
                            sessionStorage.clear();
                            setTimeout(() => {
                                //window.location.reload();
                            }, 3000);
                        }
                    }
                    else {
                        console.log("INSIDE ELSE")
                        console.log("======================================")
                        if (error.response.data !== '' && error.response.status !== '') {
                            console.log("INSIDE ELSE IF")
                            errorCode = error.response.status;
                            prettyMessage = error.response.statusText;
                            errorMessage = error.response.data;
                        }
                    }

                }
                else if (error.request) {
                    console.log('2nd error');
                    console.log(error.request);
                } else {
                    console.log('3rd error');
                    console.log('Error', error.message);
                }
                //alert('in prhttp error');
                console.log("INSIDE CONDITION")
                if (prstore.app.mode === 'dev')
                    prettyMessage = errorMessage


                if((window.location.href.split("dashboard")[1] != "/design-upload" && url != "customerQuestionAnswer/getDesignNumberList") &&
                    (window.location.href.split("dashboard")[1] != "/foundry-file-access-request" && url != "designNumberInfo/getDesignNumberList")) {
                    console.log("inside dev")
                    let myErr = {errorCode: errorCode, errorType: errorType, errorMessage: errorMessage, prettyMessage: prettyMessage}
                    prProvider.SetData('error', myErr);
                    return Promise.reject(error);
                }


                //return error;
            }
        )
}
PrHttp_class.Post = function (url, body, prProvider, config) {
    let myurl = prstore.api.baseurl + "/" + url;
    // alert(typeof body);
    return axios.post(myurl, body, config)
        .then(res => {
            return res;
        },
            error => {
                let prettyMessage = 'Oops Network Error. Please try later';
                if (error.response) {
                    console.log('1st error');
                    console.log(error.response);

                    if (error.response.data.message != '' && error.response.data.message != undefined) {
                        prettyMessage = error.response.data.message
                        sessionStorage.clear();
                        setTimeout(() => {
                            window.location.reload();
                        }, 3000);
                    }
                }
                else if (error.request) {
                    console.log('2nd error');
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('3rd error');
                    console.log('Error', error.message);
                }
                let myErr = { errorCode: '', errorType: '', errorMessage: 'network', prettyMessage: prettyMessage }
                prProvider.SetData('error', myErr);

                return Promise.reject(error);

            }

        )
}

PrHttp_class.Put = function (url, body, prProvider, config) {
    let myurl = prstore.api.baseurl + "/" + url;
    return axios.put(myurl, body, config)
        .then(res => {
            return res;
        },
            error => {
                let prettyMessage = 'Oops Network Error. Please try later';
                if (error.response) {
                    console.log('1st error');
                    console.log(error.response);

                    if (error.response.data.message != '' && error.response.data.message != undefined) {
                        prettyMessage = error.response.data.message
                        sessionStorage.clear();
                        setTimeout(() => {
                            window.location.reload();
                        }, 3000);
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log('2nd error');
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('3rd error');
                    console.log('Error', error.message);
                }
                //alert('in prhttp error');
                let myErr = { errorCode: '', errorType: '', errorMessage: 'network', prettyMessage: prettyMessage }
                prProvider.SetData('error', myErr);

                return Promise.reject(error);

            }

        )
}

PrHttp_class.Delete = function (url, prProvider, config) {
    let myurl = prstore.api.baseurl + "/" + url;
    return axios.delete(myurl, config)
        .then(res => {
            return res;
        },
            error => {
                let prettyMessage = 'Oops Network Error. Please try later';
                if (error.response) {
                    console.log('1st error');
                    console.log(error.response);

                    if (error.response.data.message != '' && error.response.data.message != undefined) {
                        prettyMessage = error.response.data.message
                        //alert(sessionStorage.getItem('current_user_login_token'))
                        sessionStorage.clear();
                        setTimeout(() => {
                            window.location.reload();
                        }, 3000);
                    }

                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log('2nd error');
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('3rd error');
                    console.log('Error', error.message);
                }
                // alert('in prhttp error');
                let myErr = { errorCode: '', errorType: '', errorMessage: 'network', prettyMessage: prettyMessage }
                prProvider.SetData('error', myErr);

                return Promise.reject(error);

            }

        )
}