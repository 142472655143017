import React, { Component } from 'react';
import $ from 'jquery';
export default class TableDesign extends Component {
    ClearMouse = () => {
        $('.inner_table').css('display', 'none');
    }
    render() {
        return (

            <div id="page-wrapper1">
                <div id="page-inner1" style={{ background: '#fff' }}>
                    {/* <div className="page_head col-lg-12" style={{ marginLeft: 17 + '%' }}> */}
                    <div className="page_head col-lg-20">
                        {JSON.stringify(this.props.state.foundryAndTechClass) !== '{}' && this.props.state.foundryAndTechClass.length > 0 ?
                            <div class="row">
                                <div class="col-lg-25 col-md-25 mx-auto">
                                    <table className="table table-sm table_sm_default">
                                        <thead>
                                            <tr style={{ textAlign: "center" }}>
                                                <th style={{cursor: 'pointer'}} scope="col" onClick={() => this.props.SortData('designNumber')}>Design Number
                                                    {this.props.state.sort_by === 'designNumber' ?
                                                        <span className="fa fa-caret-down" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                                        :
                                                        this.props.state.sort_by_1 === 'designNumber' ?
                                                            <span className="fa fa-caret-up" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                                            : ''}
                                                </th>
                                                <th style={{cursor: 'pointer'}} scope="col" onClick={() => this.props.SortData('revision')}>Revision #
                                                    {this.props.state.sort_by === 'revision' ?
                                                        <span className="fa fa-caret-down" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                                        :
                                                        this.props.state.sort_by_1 === 'revision' ?
                                                            <span className="fa fa-caret-up" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                                            : ''}
                                                </th>
                                                <th style={{cursor: 'pointer'}} scope="col" onClick={() => this.props.SortData('date')}>Upload Date
                                                    {this.props.state.sort_by === 'date' ?
                                                        <span className="fa fa-caret-down" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                                        :
                                                        this.props.state.sort_by_1 === 'date' ?
                                                            <span className="fa fa-caret-up" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                                            : ''}
                                                </th>
                                                <th style={{cursor: 'pointer'}} scope="col" onClick={() => this.props.SortData('foundry')}>Foundry
                                                    {this.props.state.sort_by === 'foundry' ?
                                                        <span className="fa fa-caret-down" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                                        :
                                                        this.props.state.sort_by_1 === 'foundry' ?
                                                            <span className="fa fa-caret-up" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                                            : ''}
                                                </th>
                                                <th style={{cursor: 'pointer'}} scope="col" onClick={() => this.props.SortData('techclass')}>Technology Class
                                                    {this.props.state.sort_by === 'techclass' ?
                                                        <span className="fa fa-caret-down" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                                        :
                                                        this.props.state.sort_by_1 === 'techclass' ?
                                                            <span className="fa fa-caret-up" style={{ fontSize: 15, marginLeft: 2, color: 'red' }}></span>
                                                            : ''}
                                                </th>
                                                <th scope="col">Select

                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {this.props.state.foundryAndTechClass.length>0?
                                            this.props.state.foundryAndTechClass.map((item, index) => (

                                                <tr style={{ textAlign: "center" }}>
                                                    <td>{item.designNumberInfo["designNumber"]}</td>
                                                    <td>{item.customerDesignUpload["uploadedOnS3"] === true? item.customerDesignUpload["revisionNumber"]:''}</td>
                                                    <td>{item.customerDesignUpload["sortAbleDate"]}</td>
                                                    <td>{item.foundry!= null? item.foundry["codeValue"]:''}</td>
                                                    <td>{item.technologyClass != null? item.technologyClass["codeName"]:''}</td>
                                                    <td>
                                                        {/*{alert(item.designNumberInfo["designNumberInfoId"])}*/}
                                                        <input type="radio" id={"designChoice"+item.designNumberInfo["designNumberInfoId"]} name="designChoice" value={item.designNumberInfo["designNumberInfoId"]}
                                                           onClick={this.props.handleRadioBoxChange}/>
                                                           <label for={"designChoice"+item.designNumberInfo["designNumberInfoId"]} style={{marginTop: "1px"}}></label>
                                                    </td>
                                                </tr>
                                            )):''
                                        }








                                        {/*{ this.props.state.showPopup && this.props.state.foundryAndTechClass.length>0?
                                            <table style={{width: '50%', textAlign:"center", marginLeft:"auto", marginRight:"auto"}}>
                                                <tbody>

                                                <tr>
                                                    <th>Design Number</th>
                                                    <th>Rev #</th>
                                                    <th>Upload Date</th>
                                                    <th>Foundry</th>
                                                    <th>Technology Node</th>
                                                    <th>Select</th>
                                                </tr>



                                                </tbody>
                                            </table>
                                            :<span></span>}*/}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            : ''}
                    </div>
                </div>
            </div>
        )
    }
}