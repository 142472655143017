import React, { Component } from 'react';
import FloatingLabelInput from 'react-floating-label-input';

export default class QuoteMPWComponent extends Component {
    render() {
        return (
            <div>
                <div id="page-wrapper" >
                    <div id="page-inner" style={{ background: '#fff' }}>
                        <div className="page_head col-xl-8 mx-auto">
                            <h1 className="page-header">Global Foundries MPW Quote</h1>
                            <div className="account">
                                <div className="accoutn_form">
                                    <form action="" method="">

                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section border-none">
                                                            <div className="row new_pos" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                                                                <div className="col-lg-3 col-md-3 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Customer Name</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-9 col-md-9 col-sm-6">
                                                                    <div className="inner_right">
                                                                        <div className="form-group">
                                                                            <input type="text" id="txtCustName" name="txtCustName" value={this.props.state.txtCustName} onChange={this.props.HandleChange} className="form-control bg-darkgray" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="account">
                                <div className="accoutn_form">
                                    <form action="" method="">
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row new_pos" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Quotation Number - Revision Number</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="inner_right">
                                                                        <div className="form-group">
                                                                            <input type="text" id="txtQuoteNumber" name="txtQuoteNumber" value={this.props.state.txtQuoteNumber} onChange={this.props.HandleChange} className="form-control bg-darkgray" placeholder="1138-0" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section border-none">
                                                            <div className="row new_pos" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Email Addresses(es) (separate by comma)</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="inner_right">
                                                                        <div className="form-group">
                                                                            <input type="text" id="txtEmail" name="txtEmail" value={this.props.state.txtEmail} onChange={this.props.HandleChange} className="form-control" placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>


                            <div className="account">
                                <div className="header">
                                    <h3>Quote Details</h3>
                                </div>
                                <div className="accoutn_form">
                                    <form action="" method="">

                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row new_pos" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Design Number</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="inner_right">
                                                                        <div className="form-group">
                                                                            <input type="text" id="txtDesignNumber" name="txtDesignNumber" value={this.props.state.txtDesignNumber} onChange={this.props.HandleChange} className="form-control" placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row new_pos" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Technology Node</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="inner_right">
                                                                        <div className="form-group">
                                                                            <div className="custom_select">
                                                                                <select id="ddlTechNode" name="ddlTechNode" value={this.props.state.ddlTechNode} onChange={this.props.onTechnologyNodeSelection}>
                                                                                    <option value=''>Select</option>
                                                                                    {JSON.stringify(this.props.state.technologyNodeList) != null?
                                                                                        this.props.state.technologyNodeList.map((item, index) => (
                                                                                        <option value={item["processName"]}>
                                                                                            {item["processName"]}
                                                                                        </option>
                                                                                    )):''
                                                                                    }
                                                                                </select>


                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row new_pos" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                                                                <div className="col-lg-4 col-md-4 col-sm-6" >
                                                                    <div className="inner_left">
                                                                        <label>Input Die size in sq mm</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="inner_right">
                                                                        <div className="form-group">
                                                                            <input type="text" id="txtInputDieSize" name="txtInputDieSize" onClick={this.props.highlightText}
                                                                                   value={this.props.state.txtInputDieSize} onChange={this.props.HandleChange} className="form-control" placeholder="" onBlur={this.props.onChangeInputDieSize}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row new_pos" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Foundry minimum area for a design (sq mm)</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="inner_right">
                                                                        <div className="form-group">
                                                                            <input type="text" id="txtFoundryMinDesignArea" name="txtFoundryMinDesignArea" value={this.props.state.txtFoundryMinDesignArea} onChange={this.props.HandleChange} className="form-control bg-darkgray" placeholder="" disabled={true}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section border-none">
                                                            <div className="row" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Additional Die Quantity</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">

                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <div className="custom_select">
                                                                                        <select id="ddlAdditionalDieQty" name="ddlAdditionalDieQty" value={this.props.state.ddlAdditionalDieQty} onChange={this.props.onAdditionalDieSizeSelection}>
                                                                                            <option value=''>Select</option>
                                                                                            {JSON.stringify(this.props.state.gfMpwQuoteData) != '{}'?
                                                                                                this.props.state.gfMpwQuoteData["addDieQuantityList"].map((item, index) => (
                                                                                                <option value={item}>
                                                                                                    {item}
                                                                                                </option>
                                                                                            )):''
                                                                                            }
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            {/*<div className="account">
                                <div className="header" id="techOptionsTbl">
                                    <h3 className="border-none">Features Summary</h3>
                                </div>
                                <tr>
                                    <td colSpan="3" style={{padding:"0px"}}>
                                        <table cellPadding="3" cellSpacing="0" width="100%" id="techOptionsTbl">
                                            <tr className="noBackground">
                                                <td colSpan="2" className="thin"> Options</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </div>*/}

                            <div className="account">
                            <div className="header">
                                <h3>Options</h3>
                            </div>
                            <div className="accoutn_form">
                                <form action="" method="">

                                    <div className="dashboard_form">
                                        <div className="inner-dashoard_form">
                                            <div className="row" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                                                <div className="col-lg-12">
                                                    <div className="brd_section" id="techOptionsTbl">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            </div>


                                {/*<div className="header">
                                    <h3 className="border-none">Adders</h3>
                                </div>*/}
                                {/*<tr>
                                    <td colSpan="3" style={{padding:"0px"}}>
                                        <table cellPadding="3" cellSpacing="0" width="100%" id="techAddersTbl">
                                            <tr className="noBackground">
                                                <td colSpan="2" className="thin"> Adders</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>*/}
                            {/*</div>*/}

                            <div className="account">
                                <div className="header">
                                    <h3>Adders</h3>
                                </div>
                                <div className="accoutn_form">
                                    <form action="" method="">

                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row" style={{height: "100%", paddingBottom: "1%", paddingTop: "1%"}}>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section" id="techAddersTbl">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>



                            <div className="account">
                                <div className="header">
                                    <h3>Price Summary</h3>
                                </div>
                                <div className="accoutn_form">
                                    <form action="" method="">

                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Reticle Space</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="row">
                                                                        <div className="col-lg-5 col-md-5 col-sm-12">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <input type="text" id="txtReticleSpacePrice" name="txtReticleSpacePrice" value={this.props.state.txtReticleSpacePrice} onChange={this.props.HandleChange} className="form-control bg-darkgray" placeholder="$0" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Additional Die</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="row">
                                                                        <div className="col-lg-5 col-md-5 col-sm-12">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <input type="text" id="txtAdditionalDie" name="txtAdditionalDie" value={this.props.state.txtAdditionalDie} onChange={this.props.HandleChange} className="form-control bg-darkgray" placeholder="$0" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Options</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="row">
                                                                        <div className="col-lg-5 col-md-5 col-sm-12">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <input type="text" id="txtOptions" name="txtOptions" value={this.props.state.txtOptions} onChange={this.props.HandleChange} className="form-control bg-darkgray" placeholder="$0" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Adders- Expedite Tapeout Fee</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="row">
                                                                        <div className="col-lg-5 col-md-5 col-sm-12">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <input type="text" id="txtExpediteFee" name="txtExpediteFee" value={this.props.state.txtExpediteFee} onChange={this.props.HandleChange} className="form-control bg-darkgray" placeholder="$0" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Adders- Expedite Lot Fee</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="row">
                                                                        <div className="col-lg-5 col-md-5 col-sm-12">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <input type="text" id="txtExpediteLotFee" name="txtExpediteLotFee" value={this.props.state.txtExpediteLotFee} onChange={this.props.HandleChange} className="form-control bg-darkgray" placeholder="$0" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Adders- ITAR Services</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="row">
                                                                        <div className="col-lg-5 col-md-5 col-sm-12">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <input type="text" id="txtItarServices" name="txtItarServices" value={this.props.state.txtItarServices} onChange={this.props.HandleChange} className="form-control bg-darkgray" placeholder="$0" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="brd_section">
                                                            <div className="row">
                                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                                    <div className="inner_left">
                                                                        <label>Additional Design Upload and DRC fees</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="row">
                                                                        <div className="col-lg-5 col-md-5 col-sm-12">
                                                                            <div className="inner_right">
                                                                                <div className="form-group">
                                                                                    <input type="text" id="txtAdditionalDesignUploadFees" name="txtAdditionalDesignUploadFees" value={this.props.state.txtAdditionalDesignUploadFees} onChange={this.props.HandleChange} className="form-control bg-darkgray" placeholder="$0" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                        <div class="brd_section border-none">
                                                            <div class="row">
                                                                <div class="col-lg-4 col-md-4 col-sm-6">
                                                                    <div class="inner_left">
                                                                        <label><b>Total Price</b></label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-8 col-md-8 col-sm-6">
                                                                    <div className="row">
                                                                        <div className="col-lg-5 col-md-5 col-sm-12">
                                                                            <div class="inner_right">
                                                                                <div class="form-group">
                                                                                    <input type="text" id="txtTotalPrice" name="txtTotalPrice" value={this.props.state.txtTotalPrice} onChange={this.props.HandleChange} className="form-control bg-darkgray" placeholder="$0" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="account">
                                <div className="header">
                                    <h3 style={{ fontSize: 14, borderBottom: 0 }}>Note: If the shipping address is within California then California Sales Tax will be added to the Invoice.</h3>
                                </div>
                            </div>

                            {JSON.stringify(this.props.state.quotationTncListMap) != '{}'?
                                [...Array(this.props.state.quotationTncList.length)].map((i, index) =>

                                    //this.props.state.quotationTncList.map((item, index) => (
                                    <div className="account">
                                        <div className="header">
                                            <h3 id="tncHeader-1">{this.props.state.quotationTncListMap[index+1].tcType}</h3>
                                        </div>
                                        <div className="accoutn_form">
                                            <form action="" method="">
                                                <div className="dashboard_form">
                                                    <div className="inner-dashoard_form">
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="brd_section border-none">
                                                                    <div className="row">
                                                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                                                            <div className="inner_left" id="tncLabel-1">
                                                                                <label>
                                                                                    {this.props.state.quotationTncListMap[index+1].tcDetails.split("<li>")[0]}
                                                                                    {this.props.state.quotationTncListMap[index+1].tcDetails.split("<li>").length>1?
                                                                                        <ul>
                                                                                            {this.props.state.quotationTncListMap[index+1].tcDetails.split("<li>").map((splitItem, index) =>
                                                                                                index != 0?
                                                                                                    <li>{splitItem.replace('</li>','')}</li>:''
                                                                                            )}</ul>
                                                                                        :''}
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                ):''
                            }

                            {/*<div className="account">
                                <div className="header">
                                    <h3>Acceptance</h3>
                                </div>
                                <div className="accoutn_form">
                                    <form action="" method="">
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section border-none">
                                                            <div className="row">
                                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="inner_left">
                                                                        <label>The MOSIS Service issues this quotation on the basis of the MOSIS Terms and Conditions posted on The MOSIS Service website</label>
                                                                        <label>The MOSIS Terms and Conditions shall apply to the Customer's Purchase Order and shall prevail over any conflicting or additional terms in Customer's Purchase Order or other documents or forms issued by the Customer, unless Customer has entered into a written agreement signed by The MOSIS Service.</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="account">
                                <div className="header">
                                    <h3>Delivery / Shipping</h3>
                                </div>
                                <div className="accoutn_form">
                                    <form action="" method="">
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section border-none">
                                                            <div className="row">
                                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="inner_left">
                                                                        <label>Orders will be shipped to the shipping address on file with The MOSIS Service. If an alternate address is to be used it needs to be adequately specified on the Customer Purchase Order.</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="account">
                                <div className="header">
                                    <h3>California Sales Tax</h3>
                                </div>
                                <div className="accoutn_form">
                                    <form action="" method="">
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section border-none">
                                                            <div className="row">
                                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="inner_left">
                                                                        <label>As required by state law, the University of Southern California collects sales tax from our customers for orders shipped within the state of California. To preclude the application of California Sales Tax the customer needs to provide a valid California Resale Certificate, CDTFA-230 rev. 1 (8-17) with their Purchase Order.</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="account">
                                <div className="header">
                                    <h3>Invoicing and Payment Terms</h3>
                                </div>
                                <div className="accoutn_form">
                                    <form action="" method="">
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section border-none">
                                                            <div className="row">
                                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="inner_left">
                                                                        <label>The quoted prices are in US DOLLARS and include domestic Federal Express 2 day shipping; delivery is best efforts.
                                                            The customer will be invoiced in full upon receipt of their Purchase Order. Payment in full must be received prior to the order being processed with the foundry.</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="account">
                                <div className="header">
                                    <h3>Cancellation Policy</h3>
                                </div>
                                <div className="accoutn_form">
                                    <form action="" method="">
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section border-none">
                                                            <div className="row">
                                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="inner_left">
                                                                        <label>
                                                                            TIf the Customer cancels:
                                                                    <ul>
                                                                                <li>After a design has been uploaded and before DRC checks are run there is no cancellation fee.</li>
                                                                                <li>If cancelled after DRC checks and before Tapeout- Cancellation fee is 50% of the lower of standard or quoted Price.</li>
                                                                                <li>If cancelled after Tapeout- Cancellation fee is 100% of the value of the Purchase Order.</li>
                                                                                <li>If The MOSIS Service is unable to complete the order due to US Government Export actions then the order will be treated as a customer cancellation.</li>
                                                                            </ul>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div className="account">
                                <div className="header">
                                    <h3>Design uploads (Design iterations)</h3>
                                </div>
                                <div className="accoutn_form">
                                    <form action="" method="">
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section border-none">
                                                            <div className="row">
                                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="inner_left">
                                                                        <label>
                                                                            The price quoted provides for two design submissions, ie one preliminary and one final design file. If the customer wishes to increase the number of design submissions, ie design iterations, there will be a charge per submission.
                                                                </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>


                            <div className="account">
                                <div className="header">
                                    <h3>Purchase Order Information</h3>
                                </div>
                                <div className="accoutn_form">
                                    <form action="" method="">
                                        <div className="dashboard_form">
                                            <div className="inner-dashoard_form">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="brd_section border-none">
                                                            <div className="row">
                                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="inner_left">
                                                                        <label>
                                                                            The MOSIS Service must receive a valid purchase order before any order can be accepted and processed.<br />
                                                                            Purchase orders should be issued to "University of Southern California, The MOSIS Service." Send your purchase order as an attachment to mosis-po@mosis.com. Adobe PDF is the preferred file format.<br />
                                                                            <span style={{ paddingLeft: 10 }}>The following information is required on customer purchase orders:</span>
                                                                            <ul style={{ paddingLeft: 30 }}>

                                                                                <li>The MOSIS Service Design number(s) [if known]</li>
                                                                                <li>MOSIS Quote ID.</li>
                                                                                <li>Customer Purchase Contact Name, email and telephone number.</li>
                                                                                <li>Shipping Address Contact Name, email and telephone number.</li>
                                                                            </ul>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>*/}
                            <div className="text-center">
                                {/*<button type="button" className="dash_submit_btn" onClick={(e) => this.props.onSubmit(e, "save")} disabled={this.props.state.saveGfmpwQuoteData}>Save</button>*/}
                                <button type="button" className="dash_submit_btn" onClick={(e) => this.props.onSubmit(e, "saveEmail")} disabled={this.props.state.saveGfmpwQuoteData}>Email Quote</button>
                            </div>
                        </div>
                        {/* <div className="text-center">
                            <button type="button" className="dash_submit_btn">Email</button>
                        </div> */}
                    </div>
                </div>
            </div>
        )
    }
}