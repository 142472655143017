import React, { Component } from 'react';
import FloatingLabelInput from 'react-floating-label-input';
import $ from 'jquery';
import DatePicker from 'react-date-picker'
export default class DesignFileUpload extends Component {
    constructor(props) {
        super(props);

    }

    ClearMouse = () => {
        $('.inner_table').css('display', 'none');
    }

    createUI = (el, id) => {
        console.log("asfasfasf")
        console.log("ID: "+id)
        console.log("el: "+JSON.stringify(el))
        return (//key={id!=null?Number(id+1):''}
            <div  id={id!=null?"fileUploadDiv_"+id:"fileUploadDiv"} className="account" style={{width: "90%", float:"left"}}>

                <div className="account_form">
                    <form action="" method="">

                        <div className="dashboard_form">
                            <div className="inner-dashoard_form">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="brd_section">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="brd_section border-none">
                                                        <div className="row">
                                                            <div className="col-lg-5 col-md-5 col-sm-6 mt-1">
                                                                <div className="inner_left">
                                                                    <label>Purpose of File Upload</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-7 col-md-7 col-sm-6 mt-1">
                                                                <div className="inner_right">
                                                                    <div className="form-group" style={{paddingBottom: "2%"}}>
                                                                            <textarea maxlength="1000" type="text" className="form-control" id={'txtFileUploadPurpose_' + (id)} name={'txtFileUploadPurpose_' + (id)}
                                                                                      resize="none"
                                                                                      style={{minHeight: "100px", maxHeight: "100px", overflow:"auto"}}
                                                                                      value={el['txtFileUploadPurpose']} onChange={e =>this.props.HandleChange(e, id, 'txtFileUploadPurpose')}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="brd_section border-none">
                                                        <div className="row">
                                                            <div className="col-lg-5 col-md-5 col-sm-6 mt-1">
                                                                <div className="inner_left">
                                                                    <label>Description</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-7 col-md-7 col-sm-6 mt-1">
                                                                <div className="inner_right">
                                                                    <div className="form-group" style={{paddingBottom: "2%"}}>
                                                                            <textarea maxLength="1000" type="text" className="form-control" id={'txtFileUploadDescription_' + (id)} resize="none"
                                                                                      name={'txtFileUploadDescription_' + (id)} style={{minHeight: "100px", maxHeight: "100px", overflow:"auto"}}

                                                                                      value={el['txtFileUploadDescription']} onChange={e => this.props.HandleChange(e, id, 'txtFileUploadDescription')}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="brd_section border-none">
                                                        <div className="row">
                                                            <div className="col-lg-5 col-md-5 col-sm-6 mt-1">
                                                                <div className="inner_left">
                                                                    <label>File Name (Please make sure the file that will be uploaded through SFTP has the exact same name and file type as it is mentioned here, or the SFTP upload will fail.)</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-7 col-md-7 col-sm-6 mt-1">
                                                                <div className="inner_right">
                                                                    <div className="form-group">
                                                                        <input type="text" id={'txtFileName_' + (id)}  name={'txtFileName_' + (id)} value={el['txtFileName']}
                                                                               onChange={e =>this.props.HandleChange(e, id, 'txtFileName')}
                                                                               /*onBlur={() => this.props.HandleOnBlur('ForceRNumber', 'txtProjectedNoOfDesign', this.props.state.txtProjectedNoOfDesign, '')}*/
                                                                               className="form-control"/>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row" style={{paddingBottom: "1%"}}>
                                                <div className="col-lg-12">
                                                    <div className="brd_section border-none">
                                                        <div className="row">
                                                            <div className="col-lg-5 col-md-5 col-sm-6 mt-1">
                                                                <div className="inner_left">
                                                                    <label>File Size</label>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-2 col-md-7 col-sm-6 mt-1">
                                                                <div className="inner_right">
                                                                    <div className="form-group">
                                                                        <div className="custom_select">
                                                                            <select onChange={e =>this.props.HandleChange(e, id, 'txtFileType')} id={'txtFileType_' + (id)} name={'txtFileType_' + (id)} value={el['txtFileType']} >
                                                                                <option value=''>Select Size Unit</option>
                                                                                <option value='KB'>KB</option>
                                                                                <option value='MB'>MB</option>
                                                                                <option value='GB'>GB</option>
                                                                                {/*{(JSON.stringify(this.props.state.foundryAndTechClass) !== '{}' && this.props.state.foundryAndTechClass.length > 0) ? this.props.state.foundryAndTechClass.map(item =>
                                                                                    <option value={item["designNumber"]}>{item["designNumber"]}</option>
                                                                                ) : ''}*/}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-5 col-md-7 col-sm-6 mt-1">
                                                                <div className="inner_right">
                                                                    <div className="form-group">
                                                                        <input type="text" id={'txtFileSize_' + (id)} name={'txtFileSize_' + (id)} value={el['txtFileSize']}
                                                                               onChange={e =>this.props.HandleChange(e, id, 'txtFileSize')}
                                                                               onBlur={e => this.props.onBlurFileSize(e, id, 'txtFileSize')}
                                                                               className="form-control"
                                                                            disabled={el['txtFileType']!=''?false:true}/>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </form>

                </div>

                
            </div>

        )
    }

    render() {
        return (
            <div>

                <div id="page-wrapper">
                    <div id="page-inner" style={{ background: '#fff' }}>
                        <div className="page_head col-xl-10 col-md-11 mx-auto">
                            <h1 class="page-header">Other File Upload Request</h1>
                            {/*<button onClick={this.props.checkValidationResponse}>afafasfas</button>*/}
                            <ul >
                                <li><a>This webpage provides a manner for you, the customer, to upload files to make them available to the MOSIS engineering staff.</a></li>
                                <li><a style={{color:"red"}}>PLEASE DO NOT upload design files.</a></li>
                                <li><a>Please coordinate with your MOSIS application engineer prior to uploading files</a></li>
                                {/*<li><a>Please make sure the file that will be uploaded though SFTP have the exact same name as it is mentioned here, or the SFTP upload will fail.</a></li>*/}
                            </ul>


                            <div style={{width: "88%"}}>
                                <button style={{float: "right"}} className="ml-1 btn btn-sm btn_sm_red" type="button" onClick={this.props.addFileUploadClick}
                                        disabled={this.props.state.numberOfDesignUploadDivs.length==4}>add upload file
                                </button>
                            </div>
                            <div class="row" style={{width:"100%"}}>

                                <div  id="fileUploadDiv" className="account" style={{width: "90%", float:"left"}}>

                                    <div className="account_form">
                                        <form action="" method="">

                                            <div className="dashboard_form">
                                                <div className="inner-dashoard_form">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="brd_section">
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div className="brd_section border-none">
                                                                            <div className="row">
                                                                                <div className="col-lg-5 col-md-5 col-sm-6 mt-1">
                                                                                    <div className="inner_left">
                                                                                        <label>Purpose of File Upload</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-7 col-md-7 col-sm-6 mt-1">
                                                                                    <div className="inner_right">
                                                                                        <div className="form-group" style={{paddingBottom: "2%"}}>
                                                                            <textarea maxlength="1000" type="text" className="form-control" id="txtFileUploadPurpose" name="txtFileUploadPurpose" resize="none"
                                                                                      style={{minHeight: "100px", maxHeight: "100px", overflow:"auto"}}
                                                                                      value={this.props.state.txtFileUploadPurpose}
                                                                                      onChange={e =>this.props.HandleChange(e, null, 'txtFileUploadPurpose')}/>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div className="brd_section border-none">
                                                                            <div className="row">
                                                                                <div className="col-lg-5 col-md-5 col-sm-6 mt-1">
                                                                                    <div className="inner_left">
                                                                                        <label>Description</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-7 col-md-7 col-sm-6 mt-1">
                                                                                    <div className="inner_right">
                                                                                        <div className="form-group" style={{paddingBottom: "2%"}}>
                                                                            <textarea maxLength="1000" type="text" className="form-control" id="txtFileUploadDescription" name="txtFileUploadDescription" resize="none"
                                                                                      style={{minHeight: "100px", maxHeight: "100px", overflow:"auto"}}
                                                                                      value={this.props.state.txtFileUploadDescription}
                                                                                      onChange={e => this.props.HandleChange(e, null, 'txtFileUploadDescription')}/>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div className="brd_section border-none">
                                                                            <div className="row">
                                                                                <div className="col-lg-5 col-md-5 col-sm-6 mt-1">
                                                                                    <div className="inner_left">
                                                                                        <label>File Name (Please make sure the file that will be uploaded through SFTP has the exact same name and file type as it is mentioned here, or the SFTP upload will fail.)</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-7 col-md-7 col-sm-6 mt-1">
                                                                                    <div className="inner_right">
                                                                                        <div className="form-group">
                                                                                            <input type="text" id="txtFileName" name="txtFileName" value={this.props.state.txtFileName}
                                                                                                   onChange={e =>this.props.HandleChange(e, null, 'txtFileName')}
                                                                                                /*onBlur={() => this.props.HandleOnBlur('ForceRNumber', 'txtProjectedNoOfDesign', this.props.state.txtProjectedNoOfDesign, '')}*/
                                                                                                   className="form-control"/>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row" style={{paddingBottom: "1%"}}>
                                                                    <div className="col-lg-12">
                                                                        <div className="brd_section border-none">
                                                                            <div className="row">
                                                                                <div className="col-lg-5 col-md-5 col-sm-6 mt-1">
                                                                                    <div className="inner_left">
                                                                                        <label>File Size</label>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-lg-2 col-md-7 col-sm-6 mt-1">
                                                                                    <div className="inner_right">
                                                                                        <div className="form-group">
                                                                                            <div className="custom_select">
                                                                                                <select onChange={e =>this.props.HandleChange(e, null, 'txtFileType')} id="txtFileType" name="txtFileType" value={this.props.state.txtFileType} >
                                                                                                    <option value=''>Select Size Unit</option>
                                                                                                    <option value='KB'>KB</option>
                                                                                                    <option value='MB'>MB</option>
                                                                                                    <option value='GB'>GB</option>
                                                                                                    {/*{(JSON.stringify(this.props.state.foundryAndTechClass) !== '{}' && this.props.state.foundryAndTechClass.length > 0) ? this.props.state.foundryAndTechClass.map(item =>
                                                                                    <option value={item["designNumber"]}>{item["designNumber"]}</option>
                                                                                ) : ''}*/}
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-5 col-md-7 col-sm-6 mt-1">
                                                                                    <div className="inner_right">
                                                                                        <div className="form-group">
                                                                                            <input type="text" id="txtFileSize" name="txtFileSize" value={this.props.state.txtFileSize}
                                                                                                   onChange={e =>this.props.HandleChange(e, null, 'txtFileSize')}
                                                                                                   onBlur={e => this.props.onBlurFileSize(e,null, null)}
                                                                                                   className="form-control"
                                                                                            disabled={this.props.state.txtFileType!=''?false:true}/>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>


                                </div>
                                {/*<button style={{
                                    height: "10%",
                                    marginTop: "1%",
                                    marginLeft: "1%",
                                }}>delete</button>*/}


                            </div>
                            {this.props.state.numberOfDesignUploadDivs.length > 0?
                                //[...Array(this.props.state.numberOfDesignUploadDivs)].map((e, i) => <div className="row" style={{width: "100%"}}>{this.createUI(i+1)}
                                this.props.state.numberOfDesignUploadDivs.map((elem, i) =>(<div key={i} className="row" style={{width: "100%"}}>{this.createUI(elem, i)}
                                    <span style={{
                                        float:"right",
                                        marginTop: "1%",
                                        marginLeft: "1%"
                                    }}>

                                    <img width="30" style={{
                                        height: "10%",
                                        marginTop: "1%",
                                        marginLeft: "1%",
                                        cursor: "pointer"
                                    }} src={require("../../../assets/img/delete.svg")} alt="delete logo" onClick={this.props.deleteFileUploadClick.bind(this, i)}/>
                                </span></div> )) :''}


                                {/*<div id="fileUploadDiv" class="account">
                                    <div class="account_form">
                                        <form action="" method="">

                                            <div class="dashboard_form">
                                                <div class="inner-dashoard_form">
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <div class="brd_section">
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div className="brd_section border-none">
                                                                            <div className="row">
                                                                                <div className="col-lg-5 col-md-5 col-sm-6 mt-1">
                                                                                    <div className="inner_left">
                                                                                        <label>Purpose of File Upload</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-7 col-md-7 col-sm-6 mt-1">
                                                                                    <div className="inner_right">
                                                                                        <div className="form-group" style={{paddingBottom: "2%"}}>
                                                                            <textarea type="text" className="form-control" defaultValue={""} id="endUseDescription" name="endUseDescription"
                                                                                      value={this.props.state.endUseDescription} onChange={this.props.HandleChange}/>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div className="brd_section border-none">
                                                                            <div className="row">
                                                                                <div className="col-lg-5 col-md-5 col-sm-6 mt-1">
                                                                                    <div className="inner_left">
                                                                                        <label>Description of File to be Uploaded</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-7 col-md-7 col-sm-6 mt-1">
                                                                                    <div className="inner_right">
                                                                                        <div className="form-group">
                                                                                            <input type="text" id="txtProjectedNoOfDesign" name="txtProjectedNoOfDesign" value={this.props.state.txtProjectedNoOfDesign} onChange={this.props.HandleChange} onBlur={() => this.props.HandleOnBlur('ForceRNumber', 'txtProjectedNoOfDesign', this.props.state.txtProjectedNoOfDesign, '')} className="form-control" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    


                                                       
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    
                                    
                                    
                                    
                                </div>*/}


                            <div className="text-center">
                                <button type="button" onClick={this.props.SubmitOtherDesignUpload} className="dash_submit_btn">Submit</button>
                            </div>

                       
                        </div>
                    </div>
                </div>


            </div>




        )
    }
}
