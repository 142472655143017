import React, { Component } from 'react';
import FloatingLabelInput from 'react-floating-label-input';
import $ from 'jquery';

export default class FoundaryService extends Component {
    constructor(props) {
        super(props);

    }

    ClearMouse = () => {
        $('.inner_table').css('display', 'none');
    }

    render() {
        return (
            <div>
                <div id="page-wrapper">
                    <div id="page-inner" style={{ background: '#fff' }}>
                        <div className="page_head col-lg-8" style={{ marginLeft: 17 + '%' }}>
                            <h1 class="page-header">Foundry Service</h1>
                            <form action="" method="">
                                <div class="row">
                                    <div class="col-xl-12 mx-auto">
                                        <div class="brd_section foundry_serivce" style={{ borderBottom: 0, background: '#FFF' }}>
                                            <div class="row justify-content-center">
                                                <div class="col-xl-4 col-md-6 col-sm-6">
                                                    <div class="inner_right">
                                                        <div class="form-group">
                                                            <div class="custom_select">
                                                                <select onChange={this.props.HandleChange} name="ddlFoundry" id="ddlFoundry" value={this.props.state.ddlFoundry} >
                                                                    <option value=''>Select Foundry</option>
                                                                    {(JSON.stringify(this.props.foundries) !== '{}' && this.props.foundries.length > 0) ? this.props.foundries.map(item =>
                                                                        <option value={item.codeId}>{item.codeName}</option>
                                                                    ) : ''}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-md-6 col-sm-6">
                                                    <div class="inner_right">
                                                        <div class="form-group">
                                                            <div class="custom_select">
                                                                <select onChange={this.props.HandleChange} name="ddlTechNode" id="ddlTechNode" value={this.props.state.ddlTechNode} >
                                                                    <option value=''>Select Technology Node</option>
                                                                    {(JSON.stringify(this.props.techNodes) !== '{}' && this.props.techNodes.length > 0) ? this.props.techNodes.map(item =>
                                                                        <option value={item.codeId}>{item.codeValue}</option>
                                                                    ) : ''}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="row">
                            <div class="col-lg-8 col-lg-offset-2 mx-auto">
                                {this.props.state.foundryName !== '' && this.props.state.techNodeName ?
                                    <span><h1 class="page-header">{this.props.state.foundryName + " " + this.props.state.techNodeName}</h1>
                                    </span>
                                    : <h1 class="page-header"></h1>
                                    }



                                <div class="calendar">
                                    <table class="table table-bordered table-dark" style={{ backgroundColor: '#c8b046' }}>
                                        {(JSON.stringify(this.props.scheduleList) !== '{}' && this.props.scheduleList.length > 0) ? this.props.scheduleList.map((item, index) =>
                                            // <span>
                                            <tbody style={{ border: 'none' }}>
                                                {/* {alert(JSON.stringify(item.months, null, 4))} */}
                                                {/* {index === 0 ? */}
                                                <tr>
                                                    <th colspan={this.props.state.largestNumberOfMonths+2}>{item.year}</th>
                                                </tr>
                                                {/* : ''} */}
                                                {/*borderColor: (localStorage.getItem('okta-token-storage') == null || localStorage.getItem('okta-token-storage') == "{}")? "black":''*/}
                                                {item.months.map(month =>
                                                    <tr>
                                                        <td class="month_name" style={{ width: this.props.state.td_width + "%" }}>{month.month_name}</td>
                                                        <td style={{ borderRight: 0, width: this.props.state.td_width + "%" }}></td>
                                                        {month.list.map(lists =>
                                                            lists.date !== '' ?
                                                                < td class="tb_clr ddd" id={"inner_table_" + lists.runId} style={{ width: this.props.state.td_width + "%",
                                                                    borderColor: (!(window.location.href).includes('dashboard'))? "black":'' }} >
                                                                    <span class="show_tb" onMouseEnter={() => this.props.CallOnMouseHover(lists.foundryFabScheduleId, lists.days, "inner_table_" + lists.runId)}
                                                                          onMouseLeave={() => this.ClearMouse()}
                                                                          style={{color: (!(window.location.href).includes('dashboard'))? "black":''}}>
                                                                        <span class="bold_txt">{lists.date}</span><br />{lists.runId}</span>
                                                                    {/* <div id="inner_table" class=""> */}
                                                                    < div className="inner_table" onMouseEnter={() => this.props.CallOnMouseHover(lists.foundryFabScheduleId, lists.days, "inner_table_" + lists.runId)} onMouseLeave={() => this.ClearMouse()}>
                                                                        <table style={{ position: 'relative', overflowX: 'auto' }}>
                                                                            <div class="table_tooltip"></div>
                                                                            {/* <div class="table_closed_btn">&times;</div> */}
                                                                            <tr>
                                                                                <th style={{ textAlign: 'center' }}>Milestone </th>
                                                                                <th style={{ textAlign: 'center' }}>Due To Mosis</th>
                                                                                <th style={{ textAlign: 'center' }}>Due To Foundry</th>
                                                                            </tr>
                                                                            {(JSON.stringify(this.props.milestoneList) !== '{}' && this.props.milestoneList.length > 0) ? this.props.milestoneList.map(item =>
                                                                                <tr>
                                                                                    <td>{item.milestoneName}</td>
                                                                                    {/* <td>{item.mosisDate}</td> */}
                                                                                    {/* <td>{item.mosisDays}</td> */}
                                                                                    {/* <td>{item.foundryDays}</td> */}
                                                                                    {item.mosisDate?
                                                                                        <td>{(item.mosisDate).slice(5, 7) + "/" + (item.mosisDate).slice(8, 10) + "/" + (item.mosisDate).slice(0, 4)}</td>
                                                                                        :
                                                                                        <td></td>}
                                                                                    {item.foundryDate?
                                                                                        <td>{(item.foundryDate).slice(5, 7) + "/" + (item.foundryDate).slice(8, 10) + "/" + (item.foundryDate).slice(0, 4)}</td>
                                                                                        :
                                                                                        <td></td>}
                                                                                </tr>
                                                                            ) : ''}
                                                                            <tr>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                            </tr>
                                                                            {/* <tr>
                                                                        <td>Product Shipped to MOSIS</td>
                                                                        <td></td>
                                                                        <td>?</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr> */}
                                                                        </table>
                                                                    </div>

                                                                </td>
                                                                :
                                                                < td style={{ width: this.props.state.td_width + "%" }}></td>
                                                        )}
                                                        {/* <td></td> */}
                                                    </tr>
                                                )}

                                            </tbody>
                                            // <tr>
                                            //     <td style={{width: this.props.state.td_width+"%"}}></td>
                                            //     <td style={{width: this.props.state.td_width+"%"}}></td>
                                            //     {item.list.map(lists =>
                                            //         <td style={{width: this.props.state.td_width+"%"}}></td>
                                            //     )}
                                            //     <td style={{width: this.props.state.td_width+"%"}}></td>
                                            // </tr>
                                            // </span>
                                        ) : ''}

                                    </table>
                                    {(JSON.stringify(this.props.scheduleList) !== '{}' && this.props.scheduleList.length > 0)?
                                        <p style={{textAlign:"center"}}>The above dates are Foundry Tapeout Dates</p>
                                        : ''
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div >
            </div >
        )
    }
}