import React, { Component } from 'react';
import RegisterComponent from './register';
import { PrConnect } from '../../library/pr-miniredux';
import { PrModalDialog } from '../../library/pr-modal/pr-modal-dialog';
import PrValidate from '../../library/pr-controls/pr-validation';
import axios from 'axios';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';

import { GetCountryList, GetFoundriesList, GetTechListByFoundry, GetRunTypeList, GetOrgTypesList, UserRegistration } from '../../services/users';



export class registerCtrl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetchCountries: false,
            fetchFoundries: false,
            foundriesList: {},
            fetchTechNode: false,
            techNodesList: {},
            fetchRunType: false,
            runTypeList: {},
            fetchOrgType: false,
            orgTypeList: {},
            registerData: false,
            addr: '',
            ip: '',
            showFirstStep: 'block',
            showSecondStep: 'none',
            showThirdStep: 'none',

            showHideSubmitBtn: false,

            txtOrgName: '',
            ddlCountry: '276',
            ddlOrgType: '',
            txtUrl: '',
            txtRevenue: '',
            txtEmployee: '',

            txtContact: '',
            txtContactOrgTitle: '',
            txtEmail: '',
            txtPhone: '',
            txtInterest: '',

            ddlFoundry: '',
            ddlTechNode: '',
            ddlRunType: '',
            txtSubmissionDate: '',
            txtExpectedDie: '',
            txtOrderValue: '',
            txtOrderValueInYear: '',
            showSuccessModal: false,

        }
        this.reg_data = {};
    }

    UNSAFE_componentWillMount = () => {
        if (JSON.stringify(this.props.PrStore.GetData('countrieslist')) === '{}')
            this.setState({
                fetchCountries: true
            });
        if (JSON.stringify(this.state.foundriesList === '{}')) {
            let that = this;
            that.setState({
                fetchFoundries: true
            });
        }
        if (JSON.stringify(this.state.runTypeList === '{}')) {
            let that = this;
            that.setState({
                fetchRunType: true
            });
        }
        if (JSON.stringify(this.state.orgTypeList === '{}')) {
            let that = this;
            that.setState({
                fetchOrgType: true
            });
        }
    }



    HandleSuccessGetCountriesResponse = (res) => {
        let list = res.data;
        this.props.PrStore.SetData('countrieslist', list);
        this.props.PrStore.BroadCast();
        this.setState({
            fetchCountries: false
        })
    }

    HandleSuccessGetFoundriesResponse = (res) => {
        this.setState({
            fetchFoundries: false,
            foundriesList: res.data
        })
    }

    HandleSuccessGetTechNodesResponse = (res) => {
        this.setState({
            fetchTechNode: false,
            techNodesList: res.data
        })
    }

    HandleSuccessGetRunTypeResponse = (res) => {
        this.setState({
            fetchRunType: false,
            runTypeList: res.data
        })
    }

    HandleSuccessGetOrgTypeResponse = (res) => {
        this.setState({
            fetchOrgType: false,
            orgTypeList: res.data
        })
    }

    HandleDateChange = txtSubmissionDate => this.setState({ txtSubmissionDate })

    HandleChange = (e) => {
        if (e.target.name === 'ddlFoundry') {
            this.setState({
                [e.target.name]: e.target.value,
                fetchTechNode: true
            });
        }
        else if (e.target.name === 'txtEmployee' || e.target.name === 'txtExpectedDie' || e.target.name === 'txtRevenue' || e.target.name === 'txtOrderValue' || e.target.name === 'txtOrderValueInYear') {
            const re = /^[0-9\b]+$/;

            // if value is not blank, then test the regex
            if(e.target.name != 'txtExpectedDie') {
                let val = e.target.value.replace(/[^0-9]/g, '');
                if (val === '' || re.test(val)) {
                    // if (val > 999) {
                    val = val.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
                    // }
                    if (e.target.name !== 'txtEmployee' && e.target.name !== 'txtExpectedDie')
                        if (val !== '')
                            val = '$' + val;
                    this.setState({
                        // [e.target.name]: e.target.value,
                        [e.target.name]: val
                    })
                }
            }else if(e.target.name === 'txtExpectedDie') {
                if (e.target.value.match(/^[0-9]{0,6}$/g) || e.target.value == "") {
                    this.setState({
                        [e.target.name]: e.target.value
                    })
                }
            }
        }
        // else if (e.target.name === 'txtEmployee' || e.target.name === 'txtExpectedDie') {
        //     const re = /^[0-9\b]+$/;

        //     // if value is not blank, then test the regex
        //     let val = e.target.value;
        //     if (val === '' || re.test(val)) {
        //         this.setState({
        //             [e.target.name]: e.target.value
        //         })
        //     }
        // }
        else {
            this.setState({
                [e.target.name]: e.target.value
            })
        }

        if (e.target.name === 'ddlCountry')
            PrValidate.ForceSelect(e.target.value, 'ddlCountry', 'a country');
        else if (e.target.name === 'ddlOrgType')
            PrValidate.ForceSelect(e.target.value, 'ddlOrgType', 'an organization type');
        else if (e.target.name === 'ddlFoundry')
            PrValidate.ForceSelect(e.target.value, 'ddlTechNode', 'a technology node');
        else if (e.target.name === 'ddlRunType')
            PrValidate.ForceSelect(e.target.value, 'ddlRunType', 'a run type');
    }

    normalizeNumber(num) {
        return this._isNumber(num) ? Number(num) : 0;
    }

    _isNumber(input) {
        return !(typeof input == 'undefined' || isNaN(input));
    }

    roundOff(num, toDecimals) {
        if (!this._isNumber(num))
            throw num;

        return parseFloat(String(num)).toFixed(this.normalizeNumber(toDecimals));
    }

    HandleOnBlur = (type, field, value, msg) => {
        if(field === 'txtExpectedDie'){
            if (value.length > 0 && this._isNumber(value)) {
                value = value;
                value = this.roundOff(value, 0);
                this.setState({
                    [field]: value
                })
            }
        }
        // alert(type+"-"+ field+"--"+value+"--"+msg);
        if (type === 'ForceSelect')
            PrValidate.ForceSelect(value, field, msg);
        else if (type === 'ForceRNumber')
            PrValidate.ForceRNumber(value.replace(/[^0-9]/g, ''), field, msg);
        else if (type === 'ForceDate')
            PrValidate.ForceDate(value, field, msg);
        else if (type === 'ForceEntry')
            PrValidate.ForceEntry(value, field, msg);
        else if (type === 'ForceRWebsite')
            PrValidate.ForceRWebsite(value, field, msg);
        else if (type === 'ForceEmail')
            PrValidate.ForceEmail(value, field, msg);
        else if (type === 'ForceRPhone')
            PrValidate.ForceRPhone(value, field, msg);
        else if (type === 'ForceNumber')
            if(value == '0')
                PrValidate.ForceRNumber('-1', field, msg);
            else
                PrValidate.ForceRNumber(value.replace(/[^0-9]/g, ''), field, msg);


    }

    GetGeoInfo = () => {
        if (this.state.addr === '' && this.state.ip === '') {
            // axios.get('https://ipapi.co/json/').then((response) => {
            axios.get('http://ip-api.com/json').then((response) => {
                let data = response.data;
                let city = data.city + "," + data.regionName + "," + data.country + "," + data.zip;
                this.setState({
                    addr: city,
                    ip: data.query
                });
            }).catch((error) => {
                console.log(error);
            });
        }
    }

    onCaptchaChange = (value) => {
        console.log("Captcha value:", value);
        this.setState({
            showHideSubmitBtn: true
        })
    }

    ShowHideSteps = (pos) => {
        let that = this;
        if (pos === 'first' || pos === 'showsecond') {
            let txtOrgName = PrValidate.ForceEntry(that.state.txtOrgName, 'txtOrgName', 'organization name');
            let ddlCountry = PrValidate.ForceSelect(that.state.ddlCountry, 'ddlCountry', 'a country');
            let ddlOrgType = PrValidate.ForceSelect(that.state.ddlOrgType, 'ddlOrgType', 'an organization type');
            let txtUrl = PrValidate.ForceRWebsite(that.state.txtUrl, 'txtUrl', 'company website URL');
            let txtRevenue = PrValidate.ForceRNumber((that.state.txtRevenue).replace(/[^0-9]/g, ''), 'txtRevenue');
            let txtEmployee = PrValidate.ForceRNumber((that.state.txtEmployee).replace(/[^0-9]/g, ''), 'txtEmployee');
            let err_arr = [txtOrgName, ddlCountry, ddlOrgType, txtUrl, txtRevenue, txtEmployee];
            let chk_false = 0;
            err_arr.forEach(function (element, index) {
                if (!element)
                    chk_false++;
                if ((err_arr.length - 1) === index) {
                    if (chk_false === 0) {
                        that.GetGeoInfo();
                        that.setState({
                            showFirstStep: 'none',
                            showSecondStep: 'block',
                            showThirdStep: 'none'
                        });
                    }
                }
            });


            // if (this.state.txtOrgName !== '' && this.state.ddlCountry !== '' && this.state.ddlOrgType !== '' && this.state.txtUrl !== '' && this.state.txtRevenue !== '' && this.state.txtEmployee !== '') {
            // }
            // else
            //     ToastsStore.error("All Fields are Mandatory")
        }
        else if (pos === 'second') {
            let txtContact = PrValidate.ForceEntry(that.state.txtContact, 'txtContact', 'Contact Name');
            let txtContactOrgTitle = PrValidate.ForceEntry(that.state.txtContactOrgTitle, 'txtContactOrgTitle', 'Org Title');
            let txtEmail = PrValidate.ForceEmail(that.state.txtEmail, 'txtEmail');
            let txtPhone = PrValidate.ForceRPhone(that.state.txtPhone, 'txtPhone', 'Phone No.');
            let txtInterest = PrValidate.ForceEntry(that.state.txtInterest, 'txtInterest', 'Service of Interest');
            let err_arr = [txtContact, txtContactOrgTitle, txtEmail, txtPhone, txtInterest];
            let chk_false = 0;
            err_arr.forEach(function (element, index) {
                if (!element)
                    chk_false++;
                if ((err_arr.length - 1) === index) {
                    if (chk_false === 0) {
                        that.setState({
                            showFirstStep: 'none',
                            showSecondStep: 'none',
                            showThirdStep: 'block'
                        })
                    }
                }
            });

            // if (this.state.txtContact !== '' && this.state.txtContactOrgTitle !== '' && this.state.txtEmail !== '' && this.state.txtPhone !== '' && this.state.txtInterest !== '') {
            //     this.setState({
            //         showFirstStep: 'none',
            //         showSecondStep: 'none',
            //         showThirdStep: 'block'
            //     })
            // }
            // else
            //     ToastsStore.error("All Fields are Mandatory")
        }
        else if (pos === 'showfirst') {
            that.setState({
                showFirstStep: 'block',
                showSecondStep: 'none',
                showThirdStep: 'none'
            })
        }
    }

    RegisterUser = () => {
        let that = this;
        // alert(this.state.ddlFoundry+"-----"+this.state.ddlTechNode+"-----"+this.state.ddlRunType+"-----"+this.state.txtSubmissionDate+"-----"+this.state.txtOrgTitle+"-----"+this.state.txtExpectedDie+"-----"+this.state.txtOrderValue+"-----"+this.state.txtOrderValueInYear)
        let ddlFoundry = PrValidate.ForceSelect(that.state.ddlFoundry, 'ddlFoundry', 'a foundry');
        let ddlTechNode = PrValidate.ForceSelect(that.state.ddlTechNode, 'ddlTechNode', 'a technology node');
        let ddlRunType = PrValidate.ForceSelect(that.state.ddlRunType, 'ddlRunType', 'a run type');
        let txtSubmissionDate = PrValidate.ForceDate(that.state.txtSubmissionDate, 'txtSubmissionDate', 'Submission Date');
        let txtExpectedDie = PrValidate.ForceRNumber((that.state.txtExpectedDie).replace(/[^0-9]/g, ''), 'txtExpectedDie');
        let txtOrderValue = PrValidate.ForceRNumber((that.state.txtOrderValue).replace(/[^0-9]/g, ''), 'txtOrderValue');
        let txtOrderValueInYear = PrValidate.ForceRNumber((that.state.txtOrderValueInYear).replace(/[^0-9]/g, ''), 'txtOrderValueInYear');
        let err_arr = [ddlFoundry, ddlTechNode, ddlRunType, txtSubmissionDate, txtExpectedDie, txtOrderValue, txtOrderValueInYear];
        let chk_false = 0;
        err_arr.forEach(function (element, index) {
            if (!element)
                chk_false++;
            if ((err_arr.length - 1) === index) {
                if (chk_false === 0) {
                    that.reg_data = {
                        organizationName: that.state.txtOrgName,
                        country: that.state.ddlCountry,
                        organizationalType: that.state.ddlOrgType,
                        websiteUrl: that.state.txtUrl,
                        annualRevenue: (that.state.txtRevenue).replace(/[^0-9]/g, ''),
                        fullTimeEmployees: (that.state.txtEmployee).replace(/[^0-9]/g, ''),

                        contactName: that.state.txtContact,
                        title: that.state.txtContactOrgTitle,
                        emailAddress: that.state.txtEmail,
                        phoneNumber: that.state.txtPhone,
                        servicesOfInterest: that.state.txtInterest,

                        foundry: that.state.ddlFoundry,
                        technologyNode: that.state.ddlTechNode,
                        runType: that.state.ddlRunType,
                        expDesignSubmissionDate: that.state.txtSubmissionDate,
                        expNumberOfDie: (that.state.txtExpectedDie).replace(/[^0-9]/g, ''),
                        projectedOrderValue: (that.state.txtOrderValue).replace(/[^0-9]/g, ''),
                        projNextYearOrderValue: (that.state.txtOrderValueInYear).replace(/[^0-9]/g, ''),

                        sourceIpAddress: that.state.ip,
                        location: that.state.addr,
                        createdDate: new Date()
                    }


                    that.setState({
                        registerData: true
                    })
                }
            }
        });

        // if (this.state.ddlFoundry !== '' && this.state.ddlTechNode !== '' && this.state.ddlRunType !== '' && this.state.txtSubmissionDate !== '' && this.state.txtExpectedDie !== '' && this.state.txtOrderValue !== '' && this.state.txtOrderValueInYear !== '') {
        //     // ToastsStore.success("Call Ajax For Registration");
        //     this.reg_data = {
        //         organizationName: this.state.txtOrgName,
        //         country: this.state.ddlCountry,
        //         organizationalType: this.state.ddlOrgType,
        //         websiteUrl: this.state.txtUrl,
        //         annualRevenue: this.state.txtRevenue,
        //         fullTimeEmployees: this.state.txtEmployee,

        //         contactName: this.state.txtContact,
        //         title: this.state.txtContactOrgTitle,
        //         emailAddress: this.state.txtEmail,
        //         phoneNumber: this.state.txtPhone,
        //         servicesOfInterest: this.state.txtInterest,

        //         foundry: this.state.ddlFoundry,
        //         technologyNode: this.state.ddlTechNode,
        //         runType: this.state.ddlRunType,
        //         expDesignSubmissionDate: this.state.txtSubmissionDate,
        //         expNumberOfDie: this.state.txtExpectedDie,
        //         projectedOrderValue: this.state.txtOrderValue,
        //         projNextYearOrderValue: this.state.txtOrderValueInYear,

        //         sourceIpAddress: this.state.ip,
        //         location: this.state.addr,
        //         createdDate: new Date()
        //     }


        //     this.setState({
        //         registerData: true
        //     })
        // }
        // else
        //     ToastsStore.error("All Fields are Mandatory")
    }

    HandleSuccessRegisterResponse = (res) => {
        // ToastsStore.success("Successfully Registered. Your Id is " + res.data.registrationId);
        this.setState({
            registerData: false,
            showFirstStep: 'block',
            showSecondStep: 'none',
            showThirdStep: 'none',

            txtOrgName: '',
            ddlCountry: '',
            ddlOrgType: '',
            txtUrl: '',
            txtRevenue: '',
            txtEmployee: '',

            txtContact: '',
            txtContactOrgTitle: '',
            txtEmail: '',
            txtPhone: '',
            txtInterest: '',

            ddlFoundry: '',
            ddlTechNode: '',
            ddlRunType: '',
            txtSubmissionDate: '',
            txtExpectedDie: '',
            txtOrderValue: '',
            txtOrderValueInYear: '',
            showSuccessModal: true
        })
    }

    HandleSuccess = () => {
        this.setState({
            showSuccessModal: false
        });
        this.props.componentprops.history.push("/");
    }

    CloseModal = (modalId) => {
        document.getElementById(modalId).style.display = 'none';
        this.setState({
            showSuccessModal: false
        });
    }

    render() {
        return (
            <div>
                {this.state.showSuccessModal ?
                    <PrModalDialog CallBackOk={this.HandleSuccess} prModalData={''}
                        prHeading=''
                        prTitle=''
                        prMessege='Thank you for expressing an interest in utilizing The MOSIS Service. This request will now be reviewed per the process noted to the left.'
                        prettyMessage='Thank you for expressing an interest in utilizing The MOSIS Service. This request will now be reviewed per the process noted to the left.'
                        prShowModal="prConfirmModal"
                        prAjaxResponse={''} />
                    : ''}
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_CENTER} />
                <RegisterComponent {...this.props} state={this.state} RegisterUser={this.RegisterUser}
                    ShowHideSteps={this.ShowHideSteps} HandleChange={this.HandleChange}
                    countries={this.props.PrStore.GetData('countrieslist')}
                    foundries={this.state.foundriesList} techNodes={this.state.techNodesList}
                    runTypeList={this.state.runTypeList} orgTypeList={this.state.orgTypeList}
                    HandleDateChange={this.HandleDateChange} HandleOnBlur={this.HandleOnBlur}
                    onCaptchaChange={this.onCaptchaChange}
                                   />
                {this.state.fetchCountries === true ? GetCountryList(this.props.PrStore, this.HandleSuccessGetCountriesResponse) : ''}
                {this.state.fetchFoundries === true ? GetFoundriesList(this.props.PrStore, this.HandleSuccessGetFoundriesResponse) : ''}
                {this.state.fetchTechNode === true ? GetTechListByFoundry(this.props.PrStore, this.state.ddlFoundry, this.HandleSuccessGetTechNodesResponse) : ''}
                {this.state.fetchRunType === true ? GetRunTypeList(this.props.PrStore, this.HandleSuccessGetRunTypeResponse) : ''}
                {this.state.fetchOrgType === true ? GetOrgTypesList(this.props.PrStore, this.HandleSuccessGetOrgTypeResponse) : ''}
                {this.state.registerData === true ? UserRegistration(this.props.PrStore, this.reg_data, this.HandleSuccessRegisterResponse) : ''}
            </div>
        )
    }
}
export const RegisterCtrl = PrConnect(registerCtrl, '');