import React, { Component } from 'react';
import {NavLink} from 'react-router-dom';
import {tus, Upload} from '../dist/tus.js'
import {updateS3Details} from '../../services/fileUploadService'
import { PrConnect } from '../../library/pr-miniredux';
import { getCustomerDetails } from '../../services/customerService';
//import {demo} from './demo'
/*import {test1} from './test'*/

//import Uppy from '@uppy/core'
//import Tus from '@uppy/tus'
//import { DragDrop } from '@uppy/react'
//import {uppy} from './UppyClient'

/* global tus */
/* eslint no-console: 0 */
window.$upload = ''
window.$totalProgress = ''
export default class ServicesDesignFileUploadComponent extends Component{
    constructor(props) {

        super(props);
        console.log("props");
        console.log(props);
        console.log(this.props.componentprops.revisionNumber)
        console.log(JSON.stringify(this.props.componentprops.selectedDesignNumberDetails))


        //console.log(this.props.componentprops.designUploadId[this.props.componentprops.designId])
        console.log(this.props.componentprops.designUploadId)
        this.state = {
            //uppyOne : uppy.upload(),
            isToggleOn: false,
            upload: null,
            uploadIsRunning: false,
            input: null,
            tus: tus,
            fileFound: "none",
            file: '',
            saveS3Details: false,
            s3DetailsObject: {},
            customerId: 254,
            s3DetailsResponse: {},
            //showSuccessMessage:false,
            showSuccessMessage:"none",
            showUploadSection:"block",
            desingnUploadId:this.props.componentprops.designUploadId,
            //desingnUploadId:238,
            startUploading:false,
            tusId:'',
            disabledStartButton:true,
            fileSize:'',
            showWaitForFileUpload: "none",
            todayDate:'',
            fetchCustomerDetails:false,
            customerDetails: {},
            designNumberDetails: this.props.componentprops.selectedDesignNumberDetails,
            customerEmail: JSON.parse(localStorage.getItem('okta-token-storage')).idToken.claims.email,
            uploadedFileName: ''
        }
        // This binding is necessary to make `this` work in the callback
        this.handleClick = this.handleClick.bind(this);
      }

    UNSAFE_componentWillMount = () => {

        console.log("desingnUploadId inside constructor: "+this.state.desingnUploadId)
        console.log("customerEmail inside constructor: "+this.state.customerEmail)
    this.getCurrentDate()
    console.log("DATE: "+this.state.todayDate)
        window.$upload = ''
        window.$totalProgress = ''


    this.state.fetchCustomerDetails = true;
    }

    handleClick = () => {

    console.log("customerName: "+this.state.customerDetails["customerName"])
    console.log("accountNumber: "+this.state.customerDetails["accountNumber"])
    console.log("foundry: "+this.state.designNumberDetails.foundry["codeValue"])
    console.log("techProcessName: "+this.state.designNumberDetails.designNumberInfo.technologyProcessObj["processName"])
    console.log("designNumber: "+this.state.designNumberDetails.designNumberInfo["designNumber"])
    console.log("revisionNumber: "+this.props.componentprops.revisionNumber)
    console.log(this.state.customerDetails["customerName"])
        /*this.state.input = document.querySelector("input[type=file]");

        var file = this.state.input.files[0];*/
        this.state.input = document.querySelector("input[type=file]");
        if(this.state.file == ''){
            if(this.state.input.files[0] != null) {
                this.state.file = this.state.input.files[0];
                this.state.fileName = this.state.input.files[0].name;
                this.state.filetype = this.state.input.files[0].type;
                this.state.fileSize = this.state.input.files[0].size;
            }
        }


        this.setState(function(prevState) {
            return {isToggleOn: !prevState.isToggleOn};
        });
        console.log("Inside handle Click")
        console.log("Document: "+document.getElementById("resume"))

        if(window.$upload != '')
        {
            if(this.state.uploadIsRunning == true){
                console.log("INSIDE IF IF")
                console.log("inside abort")
                window.$upload.abort();
                this.state.uploadIsRunning = false;
            }else{
                console.log("INSIDE IF ELSE")
                window.$upload.start();
                this.state.uploadIsRunning = true;
                this.state.fileFound = "none"
            }
        }else{
            if(this.state.file != '')
            {
                console.log("this.state.file: "+this.state.file.name)
                console.log("INSIDE ELSE IF")
                this.state.uploadedFileName = this.state.file.name

                this.state.showWaitForFileUpload = "block"
                console.log("Inside func Start")
                this.state.fileFound = "none"
                console.log("desingnUploadId: "+this.state.desingnUploadId)

                this.state.s3DetailsObject = {}
                this.state.s3DetailsObject["designUploadId"] = this.state.desingnUploadId
                this.state.s3DetailsObject["customerId"] = this.state.customerId

                let codeNameValue = {}
                //let codeNameValueS3 = {}
                codeNameValue["codeName"] = "Started"
                //codeNameValueS3["codeName"] = "Not Started"
                //this.state.s3DetailsObject["uploadToS3StatusObject"] = codeNameValueS3
                this.state.s3DetailsObject["uploadStatusObject"] = codeNameValue
                this.state.s3DetailsObject["designFileName"] =  this.state.file.name
                this.state.s3DetailsObject["modifiedFileName"] = this.state.uploadedFileName+"_"+this.state.designNumberDetails.designNumberInfo["designNumber"]+"-"+this.props.componentprops.revisionNumber+"-"+this.state.customerDetails["accountNumber"]+"-"+this.state.todayDate
                this.state.s3DetailsObject["designFileSize"] = this.state.fileSize
                console.log("s3DetailsObject: "+JSON.stringify(this.state.s3DetailsObject))

                /*this.state.s3DetailsObject = {}
                this.state.s3DetailsObject["designUploadId"] = this.state.desingnUploadId
                this.state.s3DetailsObject["fileTusId"] = "fas5f1saf16asf16as5f16safsa"
                this.state.s3DetailsObject["customerId"] = this.state.customerId
                this.state.s3DetailsObject["revisionNumber"] = this.props.componentprops.revisionNumber
                this.state.s3DetailsObject["designFileName"] = this.props.componentprops.designUploadId+"-"+this.props.componentprops.revisionNumber+"-"+this.state.customerId
                this.state.s3DetailsObject["designFileSize"] = this.state.file.size

                let codeNameValue = {}
                let codeNameValueS3 = {}
                codeNameValue["codeName"] = "Finished"
                codeNameValueS3["codeName"] = "Not Started"
                this.state.s3DetailsObject["uploadToS3StatusObject"] = codeNameValueS3
                this.state.s3DetailsObject["uploadStatusObject"] = codeNameValue
                console.log("s3DetailsObject: "+JSON.stringify(this.state.s3DetailsObject))*/
                this.setState({
                    startUploading: true,
                    s3DetailsObject:this.state.s3DetailsObject,
                    saveS3Details:true
                })


            }else{
                console.log("INSIDE ELSE ELSE")
                console.log("Inside file not found")
                this.state.fileFound = "block"
            }
        }

    }

    
    startUpload = (fileState) => {

        //var endpoint = 'http://.mosis.com:1090/files/';
    //    var endpoint = 'https://test4.mosis.com/tusserver/files/';
        var endpoint = '../tusserver/files/';
        //var endpoint = 'https://mosis-aws.mosis.com/tusserver/files/';
        //var endpoint = 'https://devaws.mosis.com/tusserver/files/';
        //var endpoint = 'https://mosis-app-lb-817999316.us-gov-west-1.elb.amazonaws.com/tusserver/files/';
          //var endpoint = 'https://test4.mosis.com/tusserver/files/';





        var chunkSize = 5000000;
        if (isNaN(chunkSize)) {
            chunkSize = Infinity;
        }

        let that = this
        var options = {
            endpoint: endpoint,
            resume  : true,
            chunkSize: chunkSize,
            retryDelays: [0, 1000, 3000, 5000],
            uploadDataDuringCreation: true,
            metadata: {
                //filename: this.state.fileName,
                filename: this.state.uploadedFileName+"_"+this.state.designNumberDetails.designNumberInfo["designNumber"]+"-"+this.props.componentprops.revisionNumber+"-"+this.state.customerDetails["accountNumber"]+"-"+this.state.todayDate,
                designFilename: this.state.uploadedFileName,
                modifiedFilename: this.state.designNumberDetails.designNumberInfo["designNumber"]+"-"+this.props.componentprops.revisionNumber+"-"+this.state.customerDetails["accountNumber"]+"-"+this.state.todayDate,
                //filename: this.state.designNumberDetails.designNumberInfo["designNumber"]+"-"+this.props.componentprops.revisionNumber+"-"+this.state.customerDetails["accountNumber"]+"-"+this.state.todayDate,
                //filename: this.props.componentprops.designUploadId+"-"+this.props.componentprops.revisionNumber+"-"+this.state.customerId,
                filetype: this.state.filetype,
                customerName: this.state.customerDetails["customerName"],
                accountNumber: this.state.customerDetails["accountNumber"],
                foundry: this.state.designNumberDetails.foundry["codeValue"],
                techProcessName: this.state.designNumberDetails.designNumberInfo.technologyProcessObj["processName"],
                designNumber: this.state.designNumberDetails.designNumberInfo["designNumber"],
                revisionNumber: this.props.componentprops.revisionNumber,
                uploadedBy: this.state.customerEmail,
                designUploadId: this.state.desingnUploadId
            },
            onError : function (error) {
                if (error.originalRequest) {
                    if (window.confirm("Failed because: " + error + "\nDo you want to retry?")) {
                        window.$upload.start();
                        that.state.uploadIsRunning = true;
                        return;
                    }
                } else {
                    window.alert("Failed because: " + error);
                }

                that.reset();
            },
            onProgress: function (bytesUploaded, bytesTotal) {
                var percentage = (bytesUploaded / bytesTotal * 100).toFixed(2);
                var progressBar     = document.querySelector(".progress-bar");
                progressBar.style.width = percentage + "%";
                console.log(bytesUploaded, bytesTotal, percentage + "%");
                progressBar.textContent = percentage
                console.log("window.$totalProgress: "+window.$totalProgress)

            },
            onSuccess: function () {
                // var anchor = document.createElement("a");
                // anchor.textContent = "Download " + this.state.upload.file.name + " (" + this.state.upload.file.size + " bytes)";
                // anchor.href = this.state.upload.url;
                // anchor.className = "btn btn-success";
                // //uploadList.appendChild(anchor);
                that.state.file = ''
                that.reset();


                let url = window.$upload.url
                console.log("URL:   "+url)
                let tusId = url.split('/files/')[1];
                console.log("tusId:   "+tusId)

                that.getCurrentDate()
                that.state.s3DetailsObject = {}
                that.state.s3DetailsObject["designUploadId"] = that.state.desingnUploadId
                that.state.s3DetailsObject["fileTusId"] = tusId
                that.state.s3DetailsObject["customerId"] = that.state.customerId
                that.state.s3DetailsObject["revisionNumber"] = that.props.componentprops.revisionNumber
                //that.state.s3DetailsObject["designFileName"] = that.state.designNumberDetails.designNumberInfo["designNumber"]+"-"+that.props.componentprops.revisionNumber+"-"+that.state.customerDetails["accountNumber"]+"-"+that.state.todayDate
                //that.state.s3DetailsObject["modifiedFileName"] =  that.state.designNumberDetails.designNumberInfo["designNumber"]+"-"+that.props.componentprops.revisionNumber+"-"+that.state.customerDetails["accountNumber"]+"-"+that.state.todayDate
                that.state.s3DetailsObject["designFileSize"] = that.state.fileSize

                let codeNameValue = {}
                let codeNameValueS3 = {}
                codeNameValue["codeName"] = "Finished"
                codeNameValueS3["codeName"] = "Not Started"
                that.state.s3DetailsObject["uploadToS3StatusObject"] = codeNameValueS3
                that.state.s3DetailsObject["uploadStatusObject"] = codeNameValue
                console.log("s3DetailsObject: "+JSON.stringify(that.state.s3DetailsObject))

                that.state.startUploading = false;
                that.state.showSuccessMessage = "block";
                that.state.showUploadSection = "none";
                that.state.isToggleOn = false;
                that.state.s3DetailsObject = that.state.s3DetailsObject;
                that.state.saveS3Details = true;
                that.state.disabledStartButton = true;
                that.state.showWaitForFileUpload = "none";
                that.setState({
                    startUploading:false,
                    showSuccessMessage:"block",
                    showUploadSection:"none",
                    isToggleOn:false,
                    s3DetailsObject:that.state.s3DetailsObject,
                    saveS3Details:true,
                    disabledStartButton:true,
                    showWaitForFileUpload: "none"
                })
            }
        };




        window.$upload = new Upload(this.state.file, options);
        if(fileState == "start"){
            console.log("inside start")
            console.log("FILE SIZE: "+this.state.file.size)



            if(this.state.s3DetailsResponse != null){
                window.$upload.start();
                this.state.uploadIsRunning = true;
            }

        }

    }

getCurrentDate = () => {
    this.state.todayDate = new Date();
    let dd = this.state.todayDate.getDate();

    let mm = this.state.todayDate.getMonth()+1;
    let yyyy = this.state.todayDate.getFullYear();
    if(dd<10)
    {
        dd='0'+dd;
    }

    if(mm<10)
    {
        mm='0'+mm;
    }
    this.state.todayDate = mm+'-'+dd+'-'+yyyy;
    console.log(this.state.todayDate);
    }

    reset() {
        var progressBar     = document.querySelector(".progress-bar");
        progressBar.style.width = 0 + "%";
        this.state.input.value = "";
        //toggleBtn.textContent = "start upload";
        this.state.upload = null;
        this.state.uploadIsRunning = false;
    }

    HandleSuccessUpdateS3Details = (res) => {

        this.setState({
            file:'',
            s3DetailsResponse:res.data,
            saveS3Details:false

        })
    if(this.state.startUploading == true){
        this.state.input = document.querySelector("input[type=file]");
        if(this.state.file == ''){
            if(this.state.input.files[0] != null) {
                this.state.file = this.state.input.files[0];
                this.state.fileName = this.state.input.files[0].name;
                this.state.filetype = this.state.input.files[0].type
            }
        }
        this.startUpload("start")
    }

        console.log("After saving S3 details")
    }

fileUploadChange= () => {

        console.log("inside file upload")
        if(document.querySelector("input[type=file]").files[0] != null) {
            if (document.querySelector("input[type=file]").files[0].name != null) {
                this.setState({
                    disabledStartButton: false
                })
                this.handleClick()

            } else {
                this.setState({
                    disabledStartButton: true
                })

            }


        }

}

HandleSuccessGetCustomerDetails = (res) => {
    this.setState({
        customerDetails:res.data,
        fetchCustomerDetails:false
    })
    console.log("customerDetails: "+JSON.stringify(this.state.customerDetails))

    console.log("Customer Account Number: "+this.state.customerDetails["accountNumber"])
    this.state.customerId = res.data["customerId"]
    console.log("customerId: "+this.state.customerId)
}

    onOkClick = () => {
        this.props.componentprops.history.push("/dashboard");
    }
    
    render(){

        return(
            <div id="page-wrapper" style={{paddingTop: "4%",
                height: "calc(100vh - 50px)"}}>
            <div id="resume"></div>
            <section className="about_bg" style={{height:"100%"}}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-4 mx-auto">
                                <div className="site-logo text-center mb-5">
                                    <NavLink to="/" style={{position:'relative', zIndex:'2000'}}>
                                        <img src={require('../../assets/images/logo.png')} alt="logo" />
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="mosis_hdng">
                            <h2 className="text-center ">The MOSIS Services Design File Upload</h2>

                            <p className="text-center">Please upload the Design file using the upload button.</p>
                        </div>

                        <div className="row">
                            <div className="col-lg-6 mx-auto">
                                <div className="ServicesDesign_card" style={{display: this.state.showSuccessMessage }}>
                                    File Successfully uploaded
                                </div>
                                <div className="text-center" style={{display: this.state.showSuccessMessage , paddingTop:"2%"}}>
                                    <button type="button" onClick={this.onOkClick} style={{backgroundColor: '#dfdfdf', color: '#990000', width:'25%'}} className="dash_submit_btn">OK</button>
                                </div>
                                <div className="ServicesDesign_card" style={{display: this.state.showUploadSection }}>
                                <form>
                                    <ul className="nav justify-content-left">
                                        <li className="nav-item">
                                            <div className="form-group">
                                                <input type="file" className="form-control-file" id="exampleFormControlFile1" onChange={this.fileUploadChange}/>
                                                <label style={{ display: this.state.fileFound , color:"red"}}>Please upload File</label>
                                            </div>

                                        </li>
                                    </ul>
                                    <div className="row align-items-end justify-content-center m-0 p-0">
                                        <div className="col-progress">
                                            <small>Upload Status</small>
                                            <div className="progress" style={{height:'22px'}}>
                                                <div className="progress-bar progress-bar-striped progress-bar-animated" style={{width:'0%',backgroundColor:'green'}}></div>
                                            </div>
                                        </div>


                                    </div>
                                </form>
                                <label style={{ display: this.state.showWaitForFileUpload , color:"red", fontSize: 15, fontWeight: 700, paddingTop: 8}}>Please stay on this page until you receive the notification the file upload process has completed.</label>
                                </div>
                            </div>
                        </div>
                    </div>


      </section>
        {this.state.saveS3Details === true? updateS3Details(this.props.PrStore, this.HandleSuccessUpdateS3Details, this.state.s3DetailsObject):''}
        {this.state.fetchCustomerDetails === true? getCustomerDetails(this.props.PrStore, this.HandleSuccessGetCustomerDetails):''}

            </div>



        )


    }

/*    const uppy = Uppy({
        meta: { type: 'avatar' },
        restrictions: { maxNumberOfFiles: 1 },
        autoProceed: true
    })

    uppy.use(Tus, { endpoint: '/upload' })

    uppy.on('complete', (result) => {
    const url = result.successful[0].uploadURL
    store.dispatch({
                       type: 'SET_USER_AVATAR_URL',
                       payload: { url: url }
                   })
})

const AvatarPicker = ({ currentAvatar }) => {
    return (
        <div>
        <img src={currentAvatar} alt="Current Avatar" />
        <DragDrop
    uppy={uppy}
    locale={{
        strings: {
            // Text to show on the droppable area.
            // `%{browse}` is replaced with a link that opens the system file selection dialog.
            dropHereOr: 'Drop here or %{browse}',
                // Used as the label for the link that opens the system file selection dialog.
                browse: 'browse'
        }
    }}
    />
    </div>
)
}*/


/*<div className="col-progress-btn text-right" style={{display:"none"}}>
                                            <input type="button" className="btn btn-sm btn-warning m-0" onClick={this.handleClick} value={this.state.isToggleOn ? 'Pause Upload' : 'Start Upload'}
                                            disabled = {(this.state.disabledStartButton)? "disabled" : ""}/>
                                        </div>*/
}


export const ServicesDesignFileUploadComponentCtrl = PrConnect(ServicesDesignFileUploadComponent, '');