import React, { Component } from 'react';
import TableDesign from './tabledesign';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
//import { GetTechProcessFoundry } from '../../../services/foundrypdk';
import $ from 'jquery';
import { getDesignUploadQuestionGroup, getQuestionsByTechClass, getFoundryAndTechClass, getFoundryAndTechClassByCustomerId } from '../../../services/designUploadQuestion';
import { PrConnect } from '../../../library/pr-miniredux';


export class TableDesignCtrl extends Component {
    constructor(props) {
        super(props);
        console.log("props");
        console.log(props);
        console.log("foundryAndTechClass props: "+JSON.stringify(this.props.componentprops.foundryAndTechClass))
        //$(".popupTop").parent('div').addClass('popupbody');
        this.state = {
            fetchTechProcess: false,
            techProcessFoundriesData: {},
            sort_by: '',

            foundryAndTechClass: this.props.componentprops.foundryAndTechClass,
            fetchFoundryAndTechClass:false,
            customerId:'',

            radioBoxValue:'',
            fetchQuetionAnswers:false,

            idAndTechClassMap: {},
            idToDateMap:{},
            dateArray:{}

        };
        this.handleRadioBoxChange = this.handleRadioBoxChange.bind(this);
    }


    UNSAFE_componentWillMount = () => {


        if (JSON.stringify(this.state.techProcessFoundriesData) === '{}')
            this.setState({
                fetchTechProcess: true
            })

        if (JSON.stringify(this.state.foundryAndTechClass) === '{}')
            this.setState({
                fetchFoundryAndTechClass: true,
                customerId:254
            })

    };

    handleRadioBoxChange = (e) => {
        console.log("inside radio change: "+JSON.stringify(this.props));
        console.log("VAL: "+e.target.value)
        this.props.componentprops.onHandleChange(e);


    }

    SortData = (sortby) => {




        let data_arr = this.state.foundryAndTechClass;
        let asc = '', dsc = '';

        if (sortby === 'foundry') {
            if (this.state.sort_by === '' || this.state.sort_by !== sortby) {
                asc = 1;
                data_arr.sort((a, b) => (a.foundry["codeValue"] > b.foundry["codeValue"]) ? 1 : ((b.foundry["codeValue"] > a.foundry["codeValue"]) ? -1 : 0));
            }
            else {
                dsc = 1;
                data_arr.sort((a, b) => (a.foundry["codeValue"] < b.foundry["codeValue"]) ? 1 : ((b.foundry["codeValue"] < a.foundry["codeValue"]) ? -1 : 0));
            }
        }
        else if (sortby === 'designNumber') {
            if (this.state.sort_by === '' || this.state.sort_by !== sortby) {
                asc = 1;
                data_arr.sort((a, b) => (a.designNumberInfo["designNumber"] > b.designNumberInfo["designNumber"]) ? 1 : ((b.designNumberInfo["designNumber"] > a.designNumberInfo["designNumber"]) ? -1 : 0));
            }
            else {
                dsc = 1;
                data_arr.sort((a, b) => (a.designNumberInfo["designNumber"] < b.designNumberInfo["designNumber"]) ? 1 : ((b.designNumberInfo["designNumber"] < a.designNumberInfo["designNumber"]) ? -1 : 0));
            }
        }
        else if (sortby === 'techclass') {
            if (this.state.sort_by === '' || this.state.sort_by !== sortby) {
                asc = 1;
                data_arr.sort((a, b) => (a.techNode["codeValue"] > b.techNode["codeValue"]) ? 1 : ((b.techNode["codeValue"] > a.techNode["codeValue"]) ? -1 : 0));
            }
            else {
                dsc = 1;
                data_arr.sort((a, b) => (a.techNode["codeValue"] < b.techNode["codeValue"]) ? 1 : ((b.techNode["codeValue"] < a.techNode["codeValue"]) ? -1 : 0));
            }
        }
        else if (sortby === 'date') {
            if (this.state.sort_by === '' || this.state.sort_by !== sortby) {
                asc = 1;
                data_arr.sort((a, b) => (a.customerDesignUpload["sortAbleDate"] > b.customerDesignUpload["sortAbleDate"]) ? 1 : ((b.customerDesignUpload["sortAbleDate"] > a.customerDesignUpload["sortAbleDate"]) ? -1 : 0));
            }
            else {
                dsc = 1;
                data_arr.sort((a, b) => (a.customerDesignUpload["sortAbleDate"] < b.customerDesignUpload["sortAbleDate"]) ? 1 : ((b.customerDesignUpload["sortAbleDate"] < a.customerDesignUpload["sortAbleDate"]) ? -1 : 0));
            }
        }
        else if (sortby === 'revision') {
            if (this.state.sort_by === '' || this.state.sort_by !== sortby) {
                asc = 1;
                data_arr.sort((a, b) => (a.customerDesignUpload["revisionNumber"] > b.customerDesignUpload["revisionNumber"]) ? 1 : ((b.customerDesignUpload["revisionNumber"] > a.customerDesignUpload["revisionNumber"]) ? -1 : 0));
            }
            else {
                dsc = 1;
                data_arr.sort((a, b) => (a.customerDesignUpload["revisionNumber"] < b.customerDesignUpload["revisionNumber"]) ? 1 : ((b.customerDesignUpload["revisionNumber"] < a.customerDesignUpload["revisionNumber"]) ? -1 : 0));
            }
        }
        if (asc === 1) {
            this.setState({
                techProcessFoundriesData: data_arr,
                sort_by: sortby,
                sort_by_1: ''
            })
        }
        else if (dsc === 1) {
            this.setState({
                techProcessFoundriesData: data_arr,
                sort_by_1: sortby,
                sort_by: ''
            })
        }
    }

    HandleSuccessGetFoundryAndTechClass = (res) => {
        //$(".popupTop").parent('div').addClass('popupbody');
        this.state.foundryAndTechClass= res.data

        let that = this
        this.state.foundryAndTechClass.map(function(item,index) {
            if(item.foundry == null){
                that.state.foundryAndTechClass.splice(index,1)
            }
        })

        this.setState({
            fetchFoundryAndTechClass: false,
            foundryAndTechClass: this.state.foundryAndTechClass
        })
    }


    render() {
        return (
            <div>
                {/* <div className='popup_inner'> */}
                    {/* <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_CENTER} /> */}
                    <TableDesign {...this.props} state={this.state} SortData={this.SortData} foundryAndTechClass={this.foundryAndTechClass} handleRadioBoxChange={this.handleRadioBoxChange}
                                 onHandleChange={this.onHandleChange}   idToDateMap={this.state.idToDateMap} />
                    {/*{this.state.fetchTechProcess === true ? getFoundryAndTechClass(this.props.PrStore, this.HandleSuccessGetTechProcessFoundriesResponse) : ''}*/}
                {this.state.fetchFoundryAndTechClass === true ? getFoundryAndTechClassByCustomerId(this.props.PrStore, this.HandleSuccessGetFoundryAndTechClass) : ''}

                {/*{this.state.fetchFoundryAndTechClass === true ? getFoundryAndTechClass(this.props.PrStore, this.HandleSuccessGetFoundryAndTechClass) : ''}*/}
                {/* </div> */}
            </div>
        )
    }
}
export default TableDesignCtrl = PrConnect(TableDesignCtrl, '');