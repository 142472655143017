import { PrAjax } from '../library/ajax/pr-ajax';

export function GetQuoteNumber(store, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('tsmcquote/generateTSMCQuoteNumber').AttachLoader(loader).OnSuccess(successFunc).Do();
}
export function GetTechNodes(store, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('tsmcquote/findMTsmcMpwPricing').AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function PostTechData(store, data, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Post('codes/getFoundariesFromTechProcessTable').ExtraData(data).AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function GetFoundryServicePricing(store, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('tsmcquote/findMFoundryServicePricing').AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function GetFoundryDieWaferQuantity(store, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('tsmcquote/findMFoundryDieWaferQuantity').AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function saveQuoteData(store, data, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Post('tsmcquote/saveQuote2').ExtraData(data).AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function GetTncList(store, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('tsmcquote/quotationTncList').AttachLoader(loader).OnSuccess(successFunc).Do();
}





