import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import FloatingLabelInput from 'react-floating-label-input';
import ReCAPTCHA from "react-google-recaptcha";
export default class ContactComponent extends Component {
    render() {
        return (
            <div>
                <section id="contact-us" className="align-self-center">
                        <div class="container">
                            <div className="row">
                                <div className="col-4 mb-5 mx-auto">
                                    <div className="site-logo text-center pb-5">
                                        <NavLink to="/" style={{position:'relative', zIndex:'9999999'}}>
                                            <img src={require('../../assets/images/logo.png')} alt="logo" />
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="container">
                            {this.props.state.showSuccessModal === false ?
                                <div class="inner-contact" >
                                    <div class="row">
                                        <div class="col-md-9 mx-auto">
                                            <h1 class="page-header pt-0" style={{ textAlign: 'center' }}>Let's get in touch</h1>
                                            <div class="row form_registration">
                                                <div class="col-md-6 col-sm-6">
                                                    <div class="form-group">
                                                        <div className="floatingInnerDiv">
                                                            <FloatingLabelInput autoComplete="off" tabIndex="0" className="floatingText" id="txtFirstName" name="txtFirstName" label="First Name" placeholder="First Name" onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtFirstName', this.props.state.txtFirstName, 'first name')} onChange={this.props.HandleChange} value={this.props.state.txtFirstName} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-6">
                                                    <div class="form-group">
                                                        <div className="floatingInnerDiv">
                                                            <FloatingLabelInput autoComplete="off" className="floatingText" id="txtLastName" name="txtLastName" label="Last Name" placeholder="Last Name" onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtLastName', this.props.state.txtLastName, 'last name')} onChange={this.props.HandleChange} value={this.props.state.txtLastName} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-6">
                                                    <div class="form-group">
                                                        <div className="floatingInnerDiv">
                                                            <FloatingLabelInput autoComplete="off" className="floatingText" id="txtCompanyName" name="txtCompanyName" label="Company Name" placeholder="Company Name" onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtCompanyName', this.props.state.txtCompanyName, 'company name')} onChange={this.props.HandleChange} value={this.props.state.txtCompanyName} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-6">
                                                    <div class="form-group">
                                                        <div className="floatingInnerDiv">
                                                            <FloatingLabelInput autoComplete="off" className="floatingText" id="txtContactEmail" name="txtContactEmail" label="Email" placeholder="Email" onBlur={() => this.props.HandleOnBlur('ForceEmail', 'txtContactEmail', this.props.state.txtContactEmail)} onChange={this.props.HandleChange} value={this.props.state.txtContactEmail} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-6">
                                                    <div class="form-group">
                                                        <div className="floatingInnerDiv">
                                                            <FloatingLabelInput autoComplete="off" className="floatingText" id="txtContactState" name="txtContactState" label="State/Province" placeholder="State/Province" onBlur={() => this.props.HandleOnBlur('ForceEntry', 'txtContactState', this.props.state.txtContactState, 'state')} onChange={this.props.HandleChange} value={this.props.state.txtContactState} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-6">
                                                    <div class="form-group">
                                                        <div className="custom_select">
                                                            <label className="custom_select_label">Country</label>
                                                            <select class="nice-select" onChange={this.props.HandleChange} name="ddlCountry" id="ddlCountry" value={this.props.state.ddlCountry}>
                                                                <option class="hidden" selected disabled value="">Select</option>
                                                                {(JSON.stringify(this.props.countries) !== '{}' && this.props.countries.length > 0) ? this.props.countries.map(item =>
                                                                    <option value={item.codeId}>{item.codeName}</option>
                                                                ) : ''}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-6">
                                                    <div class="form-group">
                                                        <div className="floatingInnerDiv">
                                                            <FloatingLabelInput autoComplete="off" className="floatingText" id="txtEmpPhone" name="txtEmpPhone" label="Phone (ex: 123-456-7890)" placeholder="Phone" onBlur={() => this.props.HandleOnBlur('ForceRPhone', 'txtEmpPhone', this.props.state.txtEmpPhone, 'Phone')} onChange={this.props.HandleChange} value={this.props.state.txtEmpPhone} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-sm-6">
                                                    <div class="form-group">
                                                        <div className="custom_select">
                                                            <label className="custom_select_label">Reason for Inquiry</label>
                                                            <select class="nice-select" onChange={this.props.HandleChange} name="ddlInquiryReason" id="ddlInquiryReason" value={this.props.state.ddlInquiryReason}>
                                                                <option class="hidden" selected disabled value="">Reason for Inquiry</option>
                                                                {(JSON.stringify(this.props.inquiryReason) !== '{}' && this.props.inquiryReason.length > 0) ? this.props.inquiryReason.map(item =>
                                                                    <option value={item.codeId}>{item.codeName}</option>
                                                                ) : ''}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label>Please let us know specifically how we can help you. *</label>
                                                        <textarea class="form-control" maxlength="3800" style={{ height: '150px !important' }} id="txtDescription" name="txtDescription" onBlur={() => this.props.HandleOnBlur('ForceTextArea', 'txtDescription', this.props.state.txtDescription, 'contact description')} onChange={this.props.HandleChange} value={this.props.state.txtDescription} >

                                                        </textarea>
                                                    </div>
                                                </div>
                                                <div class="col-md-12" style={{ paddingBottom: 30 }}>
                                                    <div style={{ float: 'left', marginRight: 30 }}>
                                                        <ReCAPTCHA
                                                            // onExpired={this.onCaptchaExpire}
                                                            //sitekey="6Lea4_EUAAAAAJoeoa-MCu0Kos2l55VKUWJ6xB-z" 
                                                            sitekey="6Lddyq4ZAAAAAOB7eDu7HKKi2K6kg45yE1zQrBsh"
                                                        onChange={this.props.onCaptchaChange}
                                                        />
                                                    </div>
                                                    {this.props.state.showHideSubmitBtn ?
                                                        <div style={{ float: 'left' }}>
                                                            <div class="text-center">
                                                                <a onClick={this.props.ContactPost} class="contact-us">CONTACT US</a>
                                                            </div>
                                                        </div>
                                                        : ''}
                                                </div>
                                                {/* {this.props.state.showHideSubmitBtn ?
                                                    <div class="col-md-12" style={{ paddingBottom: 30 }}>
                                                        <div class="text-center">
                                                            <a onClick={this.props.ContactPost} class="contact-us">CONTACT US</a>
                                                        </div>
                                                    </div>
                                                    : ''} */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ''}
                        </div>
                </section>
            </div>
        )
    }
}