import React, { Component } from 'react';

import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import { PrConnect } from '../../../../library/pr-miniredux';
import PdfViewer from './PdfViewer'
import $ from "jquery";

export class PdfViewerCtrl extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pdfFileToShow: this.props.componentprops.pdfFileToShow,
            windowWidth: this.props.componentprops.windowWidth,
            windowHeight: this.props.componentprops.windowHeight
        };
        //this.HandleFileUploadCompletion = this.HandleFileUploadCompletion.bind(this);
    }

    UNSAFE_componentWillMount = () => {

    };

    async componentDidMount() {
        console.log("pdfFileToShow: "+this.state.pdfFileToShow);
        console.log("this.props.componentprops.pdfFileToShow: "+this.props.componentprops.pdfFileToShow)
    }


    render() {

        return (


            <div>
                    <PdfViewer {...this.props} state={this.state}
                           />
                
            </div>

        )
    }
}
export default PdfViewerCtrl = PrConnect(PdfViewerCtrl, '');