import React, { Component } from 'react';
import PressReleaseComponent from './PressRelease';
import { PrConnect } from '../../library/pr-miniredux';
import { GetPressReleaseList } from '../../services/pressRelease';

export class pressReleaseCtrl extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fetchPressRelease: true,
            years: {},
            pressReleaseData: {},
        };
    }
    PressReleaseResponse = (res) => {
        let data_arr = res.data;
        console.log("data_arr: "+JSON.stringify(data_arr));
        data_arr.sort((a, b) => (a.pressReleaseDate < b.pressReleaseDate) ? 1 : ((b.pressReleaseDate < a.pressReleaseDate) ? -1 : 0));

        let years = [];

        data_arr.forEach(element => {
            if (!years.includes(element.pressReleaseDate.slice(0, 4)))
                years.push(element.pressReleaseDate.slice(0, 4))
            // alert(JSON.stringify(element.pressReleaseDate.slice(0, 4)));
        });
        this.props.PrStore.SetData('pressreleasedata', data_arr);
        // alert(JSON.stringify(data_arr));

        this.setState({
            years: years,
            pressReleaseData: res.data,
            fetchPressRelease: false
        })
        console.log(res.data);
    }

    ReadMorePressRelease = (date) => {
        this.props.componentprops.history.push("press-release/learn-more-" + date);
    }



    render() {
        return (
            <div>
                <PressReleaseComponent {...this.props} state={this.state}
                    pReleaseDate={this.state.pressReleaseData} ReadMorePressRelease={this.ReadMorePressRelease} />
                {this.state.fetchPressRelease === true ? GetPressReleaseList(this.props.PrStore, this.PressReleaseResponse) : ''}
            </div>
        )
    }

}
export const PressReleaseCtrl = PrConnect(pressReleaseCtrl, '');