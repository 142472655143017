import React from 'react'
import PropTypes from 'prop-types'
import { Security, ImplicitCallback,SecureRoute } from '@okta/okta-react';


import {
    BrowserRouter,
    Route,
    Switch
} from 'react-router-dom'

import { PrProvider } from '../library/pr-miniredux';
import { prstore } from "../library/stores/pr-store";
// import { Pnk_Error_Ctrl } from '../pnk-react/errors/error-ctrl'
import { Pr_Error_Ctrl } from '../library/errors/error-ctrl'

import SinglePageLayout from '../layouts/singlepagelayout';

import HomeCtrl from '../components/Home';
import { RegisterCtrl } from '../components/Register';
import AboutComponent from '../components/About';
import NSFComponent from '../components/NSF';
import {ReadMorePressRelease} from '../components/PressRelease/ReadMore';
import {PressReleaseCtrl} from '../components/PressRelease';
import UniversityComponent from '../components/University';
import LearnMoreComponent from '../components/LearnMore';
import { ContactUsCtrl } from '../components/ContactUs';
import LoginCtrl from '../components/Login';
import ServicesDesignFileUploadCtrl from '../components/ServicesDesignFileUpload';
import LogoutCtrl from '../components/Logout';

// Dashboard
import DashboardLayout from '../layouts/dashboardlayout';
import DashboardCtrl from '../components/Dashboard/Home';
import {QuoteDedicatedCtrl} from '../components/Dashboard/Quote_Dedicated';
import {QuoteMPWCtrl} from '../components/Dashboard/Quote_MPW';
import {QuoteTSMCCtrl} from '../components/Dashboard/Quote_TSMC';
import { AccManagementCtrl } from '../components/Dashboard/Account Management';
import { FoundryServicesCtrl } from '../components/Dashboard/Foundry Services';

import { DesignSubmissionCtrl } from '../components/Dashboard/DesignSubmission';
import { DesignUploadCtrl } from '../components/Dashboard/DesignUpload';
import { DesignFabricationCtrl } from '../components/Dashboard/DesignFabricationStatus';
import { DownloadCenterCtrl } from '../components/Dashboard/DownloadCenter';
import { ExportQuestionireCtrl } from '../components/Dashboard/ExportQuestionireInput';

import { TableDesignCtrl } from '../components/TableDesign';

import { PdkAccessRequestCtrl } from '../components/Dashboard/PdkAccessRequest';

import { TestServicesCtrl } from '../components/Dashboard/Test';

import { GeneralPurposeCtrl } from '../components/GeneralPurpose';
import {UppyClient} from '../components/ServicesDesignFileUpload/UppyClient'

import {ServicesDesignFileUploadComponentCtrl} from '../components/ServicesDesignFileUpload/ServicesDesignFileUpload'

import {DesignUploadFTPGuideComponentCtrl} from '../components/Dashboard/Test/DesignUploadFTPGuide'
import {DesignStatusCtrl} from "../components/Dashboard/DesignStatus";
import {ReorderStatusCtrl} from "../components/Dashboard/ReorderStatus";
import {DesignFileUploadCtrl} from "../components/Dashboard/DesignFileUpload"
import {ExportControlCtrl} from "../components/Dashboard/ExportControl"
import HolidayNoticeCtrl from "../components/HolidayNotice"
import AccessDeniedCtrl from "../components/Notice/AccessDenied"

import DocumentTreeSetup from "../components/treePOC/treePOC"

import ReactGA from 'react-ga';

import GoogleAnalytics from "./googleAnalytics";


// OKTA
const config = {
    issuer: 'https://themosisservice.okta.com/oauth2/default',
    //issuer: 'https://themosisservice.oktapreview.com/oauth2/default',
    //issuer: 'https://themosisservice.okta.com/oauth2/ausef7dhanqrYM0h34x6',
    redirectUri: window.location.origin + '/implicit/callback',
    clientId: '0oallelqM0hDabdHD4x5',
    //clientId: '0oarvqopaitALKUn80h7',

    pkce: true
}

const Root = () => (
    <PrProvider store={prstore}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            {/* <BrowserRouter basename='/'> */}
            <div>
                <Pr_Error_Ctrl />
                <Security {...config}>
                    <Route path='/implicit/callback' component={ImplicitCallback}/>
                    <Switch>
                        <Route exact path="/dashboard/table"
                               render={(props) => (<DashboardLayout children={TableDesignCtrl} {...props} />)} />


                        <Route exact path="/"
                               render={(props) => (<SinglePageLayout children={HomeCtrl} {...props} />)} />

                        {/*for holiday notice*/}
                        <Route exact path="/holiday-notice"
                               render={(props) => (<SinglePageLayout children={HolidayNoticeCtrl} {...props} />)} />

                        <Route exact path="/access-denied"
                               render={(props) => (<SinglePageLayout children={AccessDeniedCtrl} {...props} />)} />

                        <Route exact path="/general-purpose"
                               render={(props) => (<SinglePageLayout children={GeneralPurposeCtrl} {...props} />)} />


                        <Route exact path="/learn-more-:page"
                               render={(props) => (<SinglePageLayout children={LearnMoreComponent} {...props} />)} />
                        <Route exact path="/registration"
                               render={(props) => (<SinglePageLayout children={RegisterCtrl} {...props} />)} />
                        <SecureRoute exact path="/login"
                                     render={(props) => (<DashboardLayout children={DashboardCtrl} {...props} />)} />

                        <Route exact path="/press-release"
                               render={(props) => (<SinglePageLayout children={PressReleaseCtrl} {...props} />)} />

                        <Route exact path="/press-release/learn-more-:date"
                               render={(props) => (<SinglePageLayout children={ReadMorePressRelease} {...props} />)} />

                        <Route exact path="/university-support"
                               render={(props) => (<SinglePageLayout children={UniversityComponent} {...props} />)} />

                        <Route exact path="/about"
                               render={(props) => (<SinglePageLayout children={AboutComponent} {...props} />)} />

                        <Route exact path="/login"
                               render={(props) => (<SinglePageLayout children={LoginCtrl} {...props} />)} />
                        <Route exact path="/foundry-services"
                               render={(props) => (<SinglePageLayout children={FoundryServicesCtrl} {...props} />)} />
                        <Route exact path="/contact-us"
                               render={(props) => (<SinglePageLayout children={ContactUsCtrl} {...props} />)} />
                        <Route exact path="/services-design-file-upload"
                               render={(props)=>(<SinglePageLayout children={ServicesDesignFileUploadCtrl} {...props}/>) } />

                        <Route exact path="/logout"
                               render={(props) => (<DashboardLayout children={LogoutCtrl} {...props} />)} />

                        {/*<Route exact path="/dashboard/design-upload"
                        render={(props) => (<DashboardLayout children={DesignUploadCtrl} {...props} />)} />*/}

                        {/* Dashboard */}
                        <SecureRoute exact path="/dashboard"
                                     render={(props) => (<DashboardLayout children={DashboardCtrl} {...props} />)} />
                        <SecureRoute exact path="/dashboard/global-foundries-mpw"
                                     render={(props) => (<DashboardLayout children={QuoteMPWCtrl} {...props} />)} />
                        <SecureRoute exact path="/dashboard/tsmc-mpw"
                                     render={(props) => (<DashboardLayout children={QuoteTSMCCtrl} {...props} />)} />
                        <SecureRoute exact path="/dashboard/global-foundries-dedicated"
                                     render={(props) => (<DashboardLayout children={QuoteDedicatedCtrl} {...props} />)} />
                        <SecureRoute exact path="/dashboard/account-management-data"
                                     render={(props) => (<DashboardLayout children={AccManagementCtrl} {...props} />)} />

                        <SecureRoute exact path="/dashboard/foundry-services"
                                     render={(props) => (<DashboardLayout children={FoundryServicesCtrl} {...props} />)} />

                        <SecureRoute exact path="/dashboard/design-submission"
                                     render={(props) => (<DashboardLayout children={DesignSubmissionCtrl} {...props} />)} />


                        <SecureRoute exact path="/dashboard/design-upload"
                                     render={(props) => (<DashboardLayout children={TestServicesCtrl} {...props} />)} />

                        <SecureRoute exact path="/dashboard/foundry-file-access-request"
                                     render={(props) => (<DashboardLayout children={PdkAccessRequestCtrl} {...props} />)} />

                        <SecureRoute exact path="/dashboard/design-upload"
                                     render={(props) => (<DashboardLayout children={TestServicesCtrl} {...props} />)} />


                        <SecureRoute exact path="/dashboard/design-fabrication-status"
                                     render={(props) => (<DashboardLayout children={DesignFabricationCtrl} {...props} />)} />
                        <SecureRoute exact path="/dashboard/download-center"
                                     render={(props) => (<DashboardLayout children={DownloadCenterCtrl} {...props} />)} />
                        <SecureRoute exact path="/dashboard/export-control"
                                     render={(props) => (<DashboardLayout children={ExportQuestionireCtrl} {...props} />)} />


                        <SecureRoute exact path="/test"
                                     render={(props) => (<DashboardLayout children={ServicesDesignFileUploadComponentCtrl} {...props} />)} />

                        <Route exact path="/test2"
                               render={(props) => (<DashboardLayout children={DesignUploadFTPGuideComponentCtrl} {...props} />)} />

                        <SecureRoute exact path="/dashboard/design-status"
                               render={(props) => (<DashboardLayout children={DesignStatusCtrl} {...props} />)} />

                        <SecureRoute exact path="/dashboard/reorder-status"
                               render={(props) => (<DashboardLayout children={ReorderStatusCtrl} {...props} />)} />

                        <SecureRoute exact path="/dashboard/other-file-upload"
                               render={(props) => (<DashboardLayout children={DesignFileUploadCtrl} {...props} />)} />

                        <SecureRoute exact path="/dashboard/export-control-2"
                               render={(props) => (<DashboardLayout children={ExportControlCtrl} {...props} />)} />


                        <Route exact path="/NSF"
                               render={(props) => (<SinglePageLayout children={NSFComponent} {...props} />)} />

                        {/*<Route exact path="/dashboard/export-control-generic"
                           render={(props) => (<DashboardLayout children={ExportControlCtrl} {...props} />)} />*/}

                        {/*<Route exact path="/treePOC" component={DocumentTreeSetup} />*/}


                        {/* <Route exact path="/" component={LoginContainer} {...this.props} />
                    <Route exact path="/login" component={LoginContainer} {...this.props} /> */}
                        {/******************** 404 Page-+ Route *********************/}
                        {/* <Route exact path="*" component={NotFound}/> */}
                    </Switch>
                </Security>
            </div>
        </BrowserRouter>
    </PrProvider>
)
Root.propTypes = {
    store: PropTypes.object.isRequired,
}
export default Root