import { PrAjax } from '../library/ajax/pr-ajax';

export function getDesignUploadQuestionGroup(store, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
  //  return PrAjax(store).Get('codes/codesByCodeType/M_Design_Upload_Question_Group').ExtraData(userObj).AttachLoader(loader).OnSuccess(successFunc).Do();

    return PrAjax(store).Get('codes/codesByCodeType/M_Design_Upload_Question_Group').AttachLoader(loader).OnSuccess(successFunc).Do();

}

export function getQuestionsByTechClass(store, successFunc, techClass) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('questionAnswer/getByTechnologyClass/'+techClass).AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function getFoundryAndTechClass(store, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('questionAnswer/dummy/designNumberInfo/').AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function getFoundryAndTechClassByCustomerId(store, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('customerQuestionAnswer/getDesignNumberList').AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function submitQuestionAnswers(store, successFunc, designUploadQuestionAnswers) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Post('customerQuestionAnswer/save').ExtraData(designUploadQuestionAnswers).AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function getQuestionAnswersByDesignUploadId(store, successFunc, designUploadId) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('customerQuestionAnswer/findById/'+designUploadId).AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function getFileSizeLimit(store, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('codes/codesByCodeTypeAndCodeName/M_Generic_Parameter/Design_Upload_File_Size').AttachLoader(loader).OnSuccess(successFunc).Do();
}

export function GetUploadFileList(store, designUploadId, successFunc) {
    let loader = { isLoader: true, containerId: 'testpr', size: 'small' };
    return PrAjax(store).Get('customerQuestionAnswer/getUploadedFiles/'+designUploadId).AttachLoader(loader).OnSuccess(successFunc).Do();
}


